import React, { Component } from 'react';

import { connect } from 'react-redux';
import { CircularProgress, TextField, Autocomplete } from '@mui/material';
import { debounce, get, isEmpty } from 'lodash';

class TemplateSearch extends Component {
  constructor(props) {
    super(props);
    this.MIN_CHAR_FOR_SEARCH = 1;
    this.state = {
      isLoadingResults: false,
      templates: [],
      inputText: '',
      selectedTemplate: undefined,
      hasUpdated: false
    };
  }

  componentDidUpdate() {
    if(this.props.isTTForm && this.props.initialSearchValue && !this.hasUpdated){
      const { service } = this.props;
      const queryStr = {search: this.props.initialSearchValue};
      this.hasUpdated = true;

      service(queryStr, templates => {
        const item = templates.find(template => template.identifier === this.props.initialSearchValue);
        if(item) {
          const text = get(item, 'name', '') || get(item, 'identifier', '');
          this.setState({
            templates: templates,
            selectedTemplate: item || undefined,
            inputText: text || queryStr || '',
          });
          if(item)
            this.props.onChange(item);
        }
        else
          this.setState({
            inputText: this.props.initialSearchValue
          })
      });
    }
  }

  handleChange = (event, item) => {
    event.preventDefault();
    const { selectedTemplate } = this.state;
    const text = get(item, 'name', '') || get(item, 'identifier', '');
    if(!item || (item.id !== get(selectedTemplate, 'id')))
      this.setState({
        selectedTemplate: item,
        inputText: text || '',
        isLoadingResults: false,
      }, () => {
        if(item)
          this.props.onChange(item);
      });
  };

  handleInputChange = (event, value) => {
    let cleanValue = (value || event.target.value || '').trim();
    this.setState({inputText: cleanValue, isLoadingResults: Boolean(cleanValue)}, () => {
      if(this.isSearchableInput()){
        this.fetchItems(cleanValue);
      }
    });
    if (isEmpty(cleanValue))
      this.props.onChange(null);
  };

  isSearchableInput() {
    let minLength = this.props.minCharSearch || this.MIN_CHAR_FOR_SEARCH
    return get(this.state.inputText, 'length') >= minLength;
  }

  fetchItems = debounce(searchStr => {
    const { service } = this.props;
    const queryStr = searchStr ? {search: searchStr} : {};
    service(queryStr, templates => {
      this.setState({templates: templates, isLoadingResults: false});
    });
  }, 500);

  render() {
    const { id, placeholder } = this.props;
    const { isLoadingResults, templates, inputText, selectedTemplate } = this.state;
    const isSearchableInput = this.isSearchableInput();
    return (
      <Autocomplete
        id={id || "templateSearch"}
        blurOnSelect
        options={templates || []}
        getOptionLabel={option => get(this.props, 'isTTForm') ? (option?.identifier || inputText) : option?.name}
        loading={isLoadingResults}
        loadingText={isSearchableInput && isLoadingResults ? 'Loading...' : 'Type to search...'}
        noOptionsText={inputText ? "No results" : 'Start typing...'}
        disabled={this.props.disabled}
        renderInput={params => (
          <TextField
            {...params}
            value={inputText}
                  variant="standard"
                  label={this.props.label}
                  placeholder={placeholder}
                  helperText={this.props.helperText}
                  error={!isEmpty(this.props.helperText)}
                  inputRef={this.props.setRef}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingResults ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  fullWidth
          />
        )}
        classes={this.props.classes}
        value={get(this.props, 'isTTForm') ? (selectedTemplate || inputText) : selectedTemplate}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onBlur={(event) => {
          const matchingTemplate = templates.find(
            (template) => template?.identifier === inputText
          );
          if (matchingTemplate)
            this.handleChange(event, matchingTemplate);
          if (this.props.onBlur)
            this.props.onBlur(event);
        }}
        style={this.props.style}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token
  };
};

export default connect(mapStateToProps)(TemplateSearch);

