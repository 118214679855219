import React from 'react';
import {
  Dialog, DialogContent, DialogActions, DialogTitle, Table, TableBody, TableRow, TableHead, TableCell,
  Tooltip, InputAdornment, Divider, Button, IconButton
} from '@mui/material'
import { Info as InfoIcon, Cancel as RemoveIcon } from '@mui/icons-material'
import { map, find, isEmpty, set, orderBy, max, remove, filter, pick, keys, isNumber, isNaN } from 'lodash';
import CommonButton from '../common/CommonButton';
import AddButton from '../common/AddButton';
import CommonTextField from '../common/CommonTextField'
import CurrencyField from '../common/CurrencyField'
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';

const COLUMNS_WITH_PRICE = [
  {label: 'Grade', tooltip: "Grade Name", width: '40%'},
  {label: 'Price', tooltip: 'Grade Price', width: '30%'},
  {label: 'Quantity', tooltip: 'Grade Quantity', width: '30%'},
]

const COLUMNS_WITHOUT_PRICE = [
  {label: 'Grade', tooltip: "Grade Name", width: '50%'},
  {label: 'Quantity', tooltip: 'Grade Quantity', width: '50%'},
]

const BlendedGrades = ({ spread, disabled, commodity, onSave, setRef, errorText, currency, unit, foreignCurrencyAllowed, useSinglePrice }) => {
  const rowStruct = {
    gradeId: '',
    gradeName: '',
    price: '',
    quantity: '',
    order: 0
  }
  const isEdit = Boolean(spread)
  const [open, setOpen] = React.useState(false)
  const [rows, setRows] = React.useState(isEdit ? [...spread] : [{...rowStruct}])
  const toggle = () => setOpen(!open)
  const label = (isEdit || Boolean(find(rows, row => row.gradeId))) ? 'Edit Grades' : 'Add Grades'
  const _onChange = (index, field, value, type) => {
    if(type === 'number' && (!isNumber(value) || isNaN(value)))
      return
    if(!value)
      value = ''
    let newRows = [...rows]
    set(find(newRows, {order: index}), field, value)
    setRows(newRows)
  }
  const addRow = () => setRows([...rows, {...rowStruct, order: (max(map(rows, 'order')) || 0) + 1}])
  const deleteRow = index => {
    let newRows = remove([...rows], row => row.order !== index)
    newRows = map(newRows, row => ({...row, gist: getGist(row)}))
    setRows(newRows)
    onSave(newRows)
  }
  const _onSave = () => {
    const newRows = filter(map(rows, row => pick({...row, gist: getGist(row)}, [...keys(rowStruct), 'gist'])), row => row.gradeId && (isNumber(row.price) || useSinglePrice) && isNumber(row.quantity))
    setRows(newRows)
    onSave(newRows)
    toggle()
  }

  const getGist = row => {
    if (useSinglePrice && (!row.gradeName || !row.quantity))
      return;
    if(!useSinglePrice && (!row.gradeName || !row.price || !row.quantity))
      return;
    let price = row.price ? `${currency}${row.price} - ` : ''
    return `${row.gradeName}: ${price}${row.quantity}${unit}`
  }

  React.useEffect(() => {
    if(!isEmpty(spread))
      setRows([...spread])
  }, [spread])

  const onGradeChange = (row, grade) => {
    _onChange(row.order, 'gradeId', grade?.id || '')
    _onChange(row.order, 'gradeName', grade?.name || '')
  }

  const COLUMNS = useSinglePrice ? [...COLUMNS_WITHOUT_PRICE] : [...COLUMNS_WITH_PRICE];

  return (
    <div className="col-xs-12" ref={setRef}>
      <div className='col-xs-12'>
        {
          orderBy(filter(rows, row => !isEmpty(row.gist)), 'order').map(row => (
            <p key={row.gradeId}>{row.gist}</p>
          ))
        }
      </div>
      <AddButton
        label={label}
        onClick={toggle}
        disabled={disabled}
        style={{float: 'left'}}
      />
      {
        Boolean(errorText) &&
          <span style={{marginTop: '10px', color : '#F46059', display: 'flex', paddingLeft: '10px' }}>
            {errorText}
          </span>
      }
      <Dialog
        open={open}
        onClose={toggle}
        scroll="paper"
        fullWidth
        maxWidth='lg'
      >
        <DialogTitle>
          {label}
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                {
                  COLUMNS.map(header => (
                    <TableCell key={header.label} style={{width: header.width}}>
                      <span style={{display: 'flex', alignItems: 'center'}}>
                        {header.label}
                        <Tooltip title={header.tooltip} arrow>
                          <InfoIcon fontSize='small' style={{fontSize: '1rem', marginLeft: '2px', color: 'rgba(0, 0, 0, 0.5)'}} />
                        </Tooltip>
                      </span>
                    </TableCell>
                  ))
                }
                <TableCell style={{width: '10%'}} />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                map(orderBy(rows, 'order'), row => {
                  const id = `grade-${row.order}`
                  return (
                    <TableRow
                      id={id}
                      key={id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell style={{paddingLeft: '0px'}}>
                        <GradeAutoComplete
                          commodityId={commodity?.id}
                          gradeId={row.gradeId}
                          selectedGradeId={row.gradeId}
                          dependsOnCommodity
                          floatingLabelText={false}
                          onChange={item => onGradeChange(row, item)}
                          disabled={disabled}
                        />
                      </TableCell>
                      {!useSinglePrice &&
                      <TableCell>
                        <CurrencyField
                          value={row.price || ''}
                          onChange={event => _onChange(row.order, 'price', parseFloat(parseFloat(event.target.value).toFixed(2)), 'number')}
                          disabled={disabled}
                          variant="standard"
                          selectedCurrency={currency}
                          fullWidth
                          disabledCurrency={disabled || !foreignCurrencyAllowed}
                        />
                      </TableCell>
                      }
                      <TableCell>
                        <CommonTextField
                          value={row.quantity}
                          type='number'
                          variant='standard'
                          InputProps={{
                            endAdornment: <InputAdornment position='end'>{unit}</InputAdornment>
                          }}
                          onChange={event => _onChange(row.order, 'quantity', parseFloat(parseFloat(event.target.value).toFixed(2)), 'number')}
                        />
                      </TableCell>
                      <TableCell align='right'>
                        <IconButton color='error' onClick={() => deleteRow(row.order)}>
                          <RemoveIcon fontSize='inherit'/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          <Divider />
          <Button variant='text' onClick={addRow} style={{textTransform: 'none', marginTop: '10px'}}>
            Add Grade
          </Button>
        </DialogContent>
        <DialogActions>
          <CommonButton
            variant="contained"
            label="Cancel"
            default
            onClick={toggle}
          />
          <CommonButton
            variant="contained"
            label="Save"
            primary
            onClick={_onSave}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BlendedGrades
