import axios from 'axios';
import alertifyjs from 'alertifyjs';
import isArray from 'lodash/isArray';
import APIService from '../../services/APIService';
import { apiURL, setHeaderText, setSubHeaderText, setBreadcrumbs, loaded, forceStopLoader, isLoading } from '../main';
import has from 'lodash/has';
import get from 'lodash/get';
import compact from 'lodash/compact';
import {
  getZendeskURL, isOrderDetailsPath, openURLInNewTab, isAtGlobalFMs, getPageCache,
  addPageCacheQueryParamsToUrl, formatQueryString
} from '../../common/utils';
import includes from 'lodash/includes';
import { PACK_ORDER_TYPE_ID, REQUEST_ORDER_TYPE_IDS } from '../../common/constants';
import isEmpty from "lodash/isEmpty";
import packageJson from '../../../package.json';
import { isCustomerGradeOrTonnageMissing } from '../../components/freights/utils';
import { canRaiseVoidAndDuplicateRequestForSelectedFreightOrder } from './orders';

const VERSION = packageJson?.version
export const GET_FREIGHT_CONTRACTS_SUCCESS = 'GET_FREIGHT_CONTRACTS_SUCCESS';
export const GET_PAGINATED_FREIGHT_CONTRACTS_SUCCESS = 'GET_PAGINATED_FREIGHT_CONTRACTS_SUCCESS';
export const GET_FIXTURES_SUCCESS = 'GET_FIXTURES_SUCCESS';
export const ADD_CONTRACT_FAILURE = 'ADD_CONTRACT_FAILURE';
export const START_FETCHING_SELECTED_FREIGHT = 'START_FETCHING_SELECTED_FREIGHT';
export const RESET_IS_FETCHING_SELECTED_FREIGHT = 'RESET_IS_FETCHING_SELECTED_FREIGHT';
export const RECEIVE_FREIGHT = 'RECEIVE_FREIGHT';
export const RECEIVE_FREIGHT_CONTRACT = 'RECEIVE_FREIGHT_CONTRACT';
export const IS_FREIGHT_CONTRACTS_FETCHED = 'IS_FREIGHT_CONTRACTS_FETCHED';
export const CONFIRMATION_SUCCESS = 'CONFIRMATION_SUCCESS';
export const REJECTION_SUCCESS = 'REJECTION_SUCCESS';
export const ORDER_CONFIRMATION_SUCCESS = 'ORDER_CONFIRMATION_SUCCESS';
export const ORDER_REJECTION_SUCCESS = 'ORDER_REJECTION_SUCCESS';
export const SET_FREIGHT_MOVEMENT_ID = 'SET_FREIGHT_MOVEMENT_ID';
export const SET_FREIGHT_MOVEMENT = 'SET_FREIGHT_MOVEMENT';
export const CLEAR_FREIGHT_CONTRACT = 'CLEAR_FREIGHT_CONTRACT';
export const FREIGHT_CONTRACT_OPTIONS = 'FREIGHT_CONTRACT_OPTIONS';
export const CAN_MARK_COMPLETE_FOR_SELECTED_MOVEMENT_ID = 'CAN_MARK_COMPLETE_FOR_SELECTED_MOVEMENT_ID';
export const REJECT_LOAD_FOR_MOVEMENT_ID = 'REJECT_LOAD_FOR_MOVEMENT_ID';
export const VOIDABLE_SELECTED_FREIGHT_MOVEMENT_ID = 'VOIDABLE_SELECTED_FREIGHT_MOVEMENT_ID';
export const CAN_VOID_AND_DUPLICATE_FREIGHT_MOVEMENT = 'CAN_VOID_AND_DUPLICATE_FREIGHT_MOVEMENT';
export const VOIDABLE_LOAD  = 'VOIDABLE_LOAD';
export const COMMODITY_CONTRACT_INVOICE_ITEMS_FETCHED = 'COMMODITY_CONTRACT_INVOICE_ITEMS_FETCHED';
export const FREIGHT_CONTRACT_INVOICE_ITEMS_FETCHED = 'FREIGHT_CONTRACT_INVOICE_ITEMS_FETCHED';
export const CAN_ASSIGN_FOR_SELECTED_MOVEMENT_ID = 'CAN_ASSIGN_FOR_SELECTED_MOVEMENT_ID';
export const SHOULD_FETCH_MOVEMENT = 'SHOULD_FETCH_MOVEMENT';
export const CLICKED_OPTION = 'CLICKED_OPTION';

const MESSAGES = {
  CREATE_SUCCESS: 'Successfully created!',
  UPDATE_SUCCESS: 'Contract was successfully updated',
  MOVEMENT_CONFIRMED: 'Movement is accepted',
  MOVEMENT_COMPLETED: 'Movement is completed',
  MOVEMENT_DELIVERED: 'Movement is delivered',
  MOVEMENT_REJECTED: 'Movement is rejected',
  ORDER_CONFIRMED: 'Order is accepted',
  ORDER_REJECTED: 'Order is rejected',
  VOID_REQUEST_RAISED: 'Void request has been raised',
  VENDOR_DEC_REQUEST_RAISED: 'Vendor Declaration request has been raised',
  VOID_CONFIRMED: 'Void request is accepted',
  VOID_REJECTED: 'Void request is rejected',
  AMEND_REQUEST_RAISED: 'Amend request has been raised',
  AMEND_CONFIRMED: 'Amend request is accepted',
  AMEND_REJECTED: 'Amend request is rejected',
  IGNORE_MOVEMENT: 'Movement has been ignored',
  INCLUDE_MOVEMENT: 'Movement has been included'
};
export const addContractFailure = errors => {
  return { type: ADD_CONTRACT_FAILURE, errors };
};
export const getContractsResponse = contracts => {
  return { type: GET_FREIGHT_CONTRACTS_SUCCESS, contracts };
};
export const getPaginatedContractsResponse = paginatedContracts => {
  return { type: GET_PAGINATED_FREIGHT_CONTRACTS_SUCCESS, paginatedContracts };
};
export const getContractFixturesResponse = fixtures => {
  return { type: GET_FIXTURES_SUCCESS, fixtures };
};
export const isFreightContractsFetched = isFetched => {
  return { type: IS_FREIGHT_CONTRACTS_FETCHED, isFetched };
};
export const confirmationResponse = contract => {
  return { type: CONFIRMATION_SUCCESS, contract };
};
export const rejectionResponse = contract => {
  return { type: REJECTION_SUCCESS, contract };
};
export const orderConfirmationResponse = order => {
  return { type: ORDER_CONFIRMATION_SUCCESS, order };
};
export const orderRejectionResponse = order => {
  return { type: ORDER_REJECTION_SUCCESS, order };
};
export const setFreightMovementId = movementId => {
  return { type: SET_FREIGHT_MOVEMENT_ID, movementId };
};
export const setFreightMovement = movement => {
  return { type: SET_FREIGHT_MOVEMENT, movement };
};
export const clearFreightContract = () => ({
  type: CLEAR_FREIGHT_CONTRACT,
});
export const freightContractActionOptions = subItems => {
  return { type: FREIGHT_CONTRACT_OPTIONS, subItems };
};
export const canMarkCompleteForSelectedMovementId = flag => {
  return { type: CAN_MARK_COMPLETE_FOR_SELECTED_MOVEMENT_ID, flag };
};
export const canAssignForSelectedMovementId = flag => {
  return {type: CAN_ASSIGN_FOR_SELECTED_MOVEMENT_ID, flag};
};
export const rejectLoadForMovementId = movementId => {
  return { type: REJECT_LOAD_FOR_MOVEMENT_ID, movementId };
};
export const voidableSelectedFreightMovementId = movementId => {
  return { type: VOIDABLE_SELECTED_FREIGHT_MOVEMENT_ID, movementId };
};

export const canRaiseVoidAndDuplicateRequestForFreightMovement = flag => {
  return { type: CAN_VOID_AND_DUPLICATE_FREIGHT_MOVEMENT, flag };
}

export const voidableLoad = (loadId, loadType) => {
  return { type: VOIDABLE_LOAD, loadId, loadType };
}
export const shouldFetchFreightMovement = flag => ({
  type: SHOULD_FETCH_MOVEMENT,
  flag,
});

export const setClickedOption = option => ({
  type: CLICKED_OPTION,
  option,
});

export const loadInvoiceItems = ({ isFetchedTTs, isFetchedFMs }) => {
  if (includes([true, false], isFetchedTTs) && includes([true, false], isFetchedFMs))
    return { type: COMMODITY_CONTRACT_INVOICE_ITEMS_FETCHED, isFetchedTTs, isFetchedFMs };
  else if (includes([true, false], isFetchedTTs))
    return { type: COMMODITY_CONTRACT_INVOICE_ITEMS_FETCHED, isFetchedTTs };
  else if (includes([true, false], isFetchedFMs))
    return { type: COMMODITY_CONTRACT_INVOICE_ITEMS_FETCHED, isFetchedFMs };
};

export const loadFreightInvoiceItems = isFetchedFreightContracts => {
  return { type: FREIGHT_CONTRACT_INVOICE_ITEMS_FETCHED, isFetchedFreightContracts };
};


export const getContracts = (orderId = '', commodityContractId = '', url = '', _forceStopLoader = false, isPackOrder = false, unit, callback = null) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

      if(unit)
        config.headers['REFERER-UNIT'] = unit
    const cache = getPageCache();
    const cachedOrderBy = get(cache, 'orderBy');

    let contractsListURL = url
      ? orderId
        ? `${url}&order_id=${orderId}`
        : commodityContractId
          ? `${url}&commodity_contract_id=${commodityContractId}`
          : url
      : orderId
        ? `${apiURL}/freights/contracts/web/?order_id=${orderId}`
        : commodityContractId
          ? `${apiURL}/freights/contracts/web/?commodity_contract_id=${commodityContractId}`
          : cachedOrderBy
            ? `${apiURL}/freights/contracts/web/search/`
            : `${apiURL}/freights/contracts/web/`;

    if(!orderId && !commodityContractId && isAtGlobalFMs()) {
      const pageName = isPackOrder ? 'movements/pack' : 'movements/freights'
      contractsListURL = addPageCacheQueryParamsToUrl(contractsListURL, pageName)  
    } else if(has(cache, 'pageSize')) {
      const pageSize = get(cache, 'pageSize');
      let joiner = '?';
      if(contractsListURL.indexOf('?') > -1)
        joiner = '&';
      contractsListURL += `${joiner}page_size=${pageSize}`;
    }

    if (!contractsListURL.includes("is_pack_order=true")) {
      let joiner = '?';
      if(contractsListURL.indexOf('?') > -1)
        joiner = '&';
      contractsListURL += `${joiner}is_pack_order=${isPackOrder}`
    }

    if (contractsListURL.includes("is_pack_order=true")){
      let joiner = '?';
      if(contractsListURL.indexOf('?') > -1)
        joiner = '&';
      contractsListURL += `${joiner}type_id=6`
    }
    else {
      let joiner = '?';
      if(contractsListURL.indexOf('?') > -1)
        joiner = '&';
      contractsListURL += `${joiner}type_id=1&type_id=2`
    }

    axios
      .get(formatQueryString(contractsListURL), config)
      .then(response => {
        if (isOrderDetailsPath()) {
          dispatch(forceStopLoader());
        }
        dispatch(getPaginatedContractsResponse(response.data));
        if (callback)
          callback();
        if (_forceStopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch(error => {
        if (isOrderDetailsPath()) {
          dispatch(forceStopLoader());
        }
        dispatch(getContractsResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const getDetailedContracts = (orderId = '', commodityContractId = '', queryString = null, _forceStopLoader, doNotConvertUnit) => {
  return (dispatch, getState) => {
    dispatch(getContractsResponse([]));
    dispatch(isFreightContractsFetched(false));
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country,
      },
    };
    if(doNotConvertUnit)
      config.headers['REFERER-DO-NOT-CONVERT-UNIT'] = true


    let url = orderId
      ? `${apiURL}/freights/contracts/?order_id=${orderId}`
      : commodityContractId
        ? `${apiURL}/freights/contracts/?commodity_contract_id=${commodityContractId}`
        : `${apiURL}/freights/contracts/`;
    if (queryString) url += queryString;
    axios
      .get(formatQueryString(url), config)
      .then(response => {
        dispatch(getContractsResponse(response.data));
        dispatch(isFreightContractsFetched(true));
        if (_forceStopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch(error => {
        dispatch(getContractsResponse(error.response ? error.response.data : error.message));
        dispatch(isFreightContractsFetched(true));
      });
  };
};

export const receiveFreight = item => ({
  type: RECEIVE_FREIGHT,
  item,
});

export const receiveFreightContract = item => ({
  type: RECEIVE_FREIGHT_CONTRACT,
  item,
});

export const getFixtures = () => {
  return (dispatch, getState) => {
    const currentUser = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${currentUser.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    const URL = `${apiURL}/freights/contracts/fixtures/`;
    axios(URL, config)
      .then(response => {
        dispatch(getContractFixturesResponse(response.data));
      })
      .catch(error => {
        dispatch(getContractFixturesResponse(error.response ? error.response.data : error.message));
      });
  };
};

const startFetchingSelectedFreight = () => ({
  type: START_FETCHING_SELECTED_FREIGHT,
});

export const resetIsFetchingSelectedFreight = () => ({
  type: RESET_IS_FETCHING_SELECTED_FREIGHT,
});

export const getSelectedFreight = (
  movementId,
  actionCreator,
  redirectPage = true,
  inActivatedUserToken,
  isEditingMode = false,
  setHeaderAndBreadcrumbs = true,
  _forceStopLoader = true,
  unit
) => (dispatch, getState) => {
  if (!movementId) {
    return false;
  }
  const token = getState().main.user.token || inActivatedUserToken;
  const { isFetchingSelectedFreight } = getState().companies.freights;
  if (!isFetchingSelectedFreight) {
    dispatch(startFetchingSelectedFreight());

    let headers = {}
    if(unit) {
      headers['REFERER-UNIT'] = unit
    }

    APIService.freights()
      .contracts(movementId)
      .get(token, headers)
      .then(item => {
        dispatch(actionCreator(item));
        if(_forceStopLoader)
          dispatch(forceStopLoader());
        if (setHeaderAndBreadcrumbs) {
          const isPackMovement = item ? item.typeId === PACK_ORDER_TYPE_ID : false;
          var headerText = item ? (isPackMovement ? 'Pack Movement ' : 'Freight Movement ') : '';
          headerText = headerText + item.identifier;
          const subHeaderText = item && item.commodity ? '(' + item.commodity.displayName : '';
          const subHeader2 =
                item && item.plannedTonnage
                ? parseFloat(item.plannedTonnage).toFixed(2) + ' ' + (item.isStrictQuantityBasedCommodity ? item.quantityUnit : item.tonnageUnit || 'MT') + ')'
                : ')';
          const breadcrumbs = [{ text: 'Movements', route: '/movements/freights' }, { text: item && item.identifier ? item.identifier : '' }];
          dispatch(setHeaderText(headerText));
          dispatch(setSubHeaderText(subHeaderText + ' ' + subHeader2));
          dispatch(setBreadcrumbs(breadcrumbs));
        }
        if (redirectPage && isEditingMode) {
          document.location = '#/freights/movements/' + movementId + '/edit';
        } else if (isEditingMode || redirectPage) {
          document.location = '#/freights/movements/' + movementId + '/details';
        }
      })
      .catch(() => {
        dispatch(resetIsFetchingSelectedFreight());
      });
  }
};

export const create = (data, callback, failCallback, failCallbackData, slotUpdateCallback) => (dispatch, getState) => {
  fetch(`${apiURL}/freights/contracts/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country,
    },
  })
    .then(response => response.json())
    .then(json => {
      dispatch(loaded());
      dispatch(forceStopLoader())
      if (isArray(json.errors) && !isEmpty(json.errors)) {
        const errors = '<li>' + json.errors.join('</li><li>');
        alertifyjs.alert(
          'Error:',
          `<div id="complete-dialog-open" className=""><p>Following errors occured:</p><ul>${errors}</ul></div>`,
          () => {},
        );
      }
      else if (isArray(json)) {
        const freightMovement = json[0];
        if (freightMovement.id == null) {
          if (has(freightMovement.errors, '_All_')) {
            alertifyjs.error(freightMovement.errors._All_[0]);
          } else {
            if (failCallback) {
              if (failCallbackData) {
                dispatch(failCallback(failCallbackData));
              } else {
                failCallback();
              }
            }
            dispatch(forceStopLoader());
            dispatch(addContractFailure(freightMovement.errors));
          }
        } else {
          if (callback) {
            callback();
          }
          else {
            let movementsLocation = '#/freights/movements/' + freightMovement.id + '/details';
            if (data.manualComplete) {
              movementsLocation = `#/contracts/${freightMovement.commodityContractId}/contract`;
            } else if (freightMovement.orderId) {
              dispatch(isLoading('movementDetail'));
              movementsLocation = `#/freights/orders/${freightMovement.orderId}/movements?movementId=${freightMovement.id}`;
            } else if (freightMovement.commodityContractId) {
              movementsLocation = `#/contracts/${freightMovement.commodityContractId}/movements?movementId=${freightMovement.id}`;
            }
            document.location = movementsLocation;
            alertifyjs.success(MESSAGES.CREATE_SUCCESS, 1);
          }
        }
      } else if (json?.errors && (json.errors?.outloadSlot || json.errors?.inloadSlot)){
        let outloadSlotError = json.errors?.outloadSlot ? `<b>Outload Slot:</b> ${json.errors.outloadSlot}` : false
        let inloadSlotError = json.errors?.inloadSlot ? `<b>Inload Slot:</b> ${json.errors.inloadSlot}` : false
        let errors = compact([outloadSlotError, inloadSlotError])
        errors = '<li>' + errors.join('</li><li>');
        alertifyjs.alert(
          'Error: Slot Booking',
          `<div id="complete-dialog-open" className=""><p>Following errors occured:</p><ul>${errors}</ul></div>`,
          () => {
            if (slotUpdateCallback) {
              if (inloadSlotError)
                slotUpdateCallback('freightDelivery.consignee', 'inload');
              if (outloadSlotError)
                slotUpdateCallback('freightPickup.consignor', 'outload');
            }
          },
        );
      } else {
        if (failCallback) {
          if (failCallbackData) {
            dispatch(failCallback(failCallbackData));
          } else {
            failCallback();
          }
        }
        dispatch(forceStopLoader());
        if (has(json.errors, 'freightPickup.storageId')) {
          alertifyjs.error(json.errors.freightPickup.storageId[0]);
        } else if (has(json.errors, 'freightPickup.commodityId')) {
          alertifyjs.error(json.errors.freightPickup.commodityId[0]);
        } else if (has(json.errors, 'freightDelivery.storageId')) {
          alertifyjs.error(json.errors.freightDelivery.storageId[0]);
        } else if (has(json.errors, 'freightDelivery.commodityId')) {
          alertifyjs.error(json.errors.freightDelivery.commodityId[0]);
        } else if (has(json.errors, 'freightDelivery.truck.rego')){
          alertifyjs.error(json.errors.freightDelivery.truck.rego[0]);
        } else if (has(json.errors, 'freightPickup.truck.rego')){
          alertifyjs.error(json.errors.freightPickup.truck.rego[0]);
        }else {
          dispatch(addContractFailure(json.errors));
        }
        if (isArray(json.errors) && !isEmpty(json.errors)) {
          const errors = '<li>' + json.errors.join('</li><li>');
          alertifyjs.alert(
            'Error:',
            `<div id="complete-dialog-open" className=""><p>Following errors occured:</p><ul>${errors}</ul></div>`,
            () => {},
          );
        }
      }
    });
};

export const getFarmMovements = (farmId, stopLoader) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    axios
      .get(`${apiURL}/farms/${farmId}/freight-movements/`, config)
      .then(response => {
        if(stopLoader)
          dispatch(forceStopLoader());
        dispatch(getPaginatedContractsResponse(response.data));
      })
      .catch(error => {
        if(stopLoader)
          dispatch(forceStopLoader());
        dispatch(getContractsResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const confirmMovement = (movementId, data, successMsg = MESSAGES.MOVEMENT_CONFIRMED, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .confirm()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
      } else {
        alertifyjs.success(successMsg, 1, () => {
          dispatch(confirmationResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const assignMovement = (movementId, data, successMsg) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .assign()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
      } else {
        alertifyjs.success(successMsg, 1, () => {
          window.location.reload();
        });
      }
    });
};

export const assignParentToMovement = (movementId, data, successMsg, isPackMovement=false) => (dispatch, getState) => {
  const token = getState().main.user.token;
  if (isPackMovement){
    APIService.freights()
    .contracts(movementId)
    .appendToUrl('assign-parent/pack/')
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
      } else {
        alertifyjs.success(successMsg, 1, () => {
          window.location.reload();
        });
      }
    });
  }
  else{
    APIService.freights()
    .contracts(movementId)
    .appendToUrl('assign-parent/')
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
      } else {
        alertifyjs.success(successMsg, 1, () => {
          window.location.reload();
        });
      }
    });
  }
};

export const cannotAssignParentReasons = (movementId, entityId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
  .contracts(movementId)
  .appendToUrl(`assign-parent/?entityId=${entityId}`)
  .get(token)
  .then(response => {
    if (!isEmpty(response, 'reasons')) {
      alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
    }
  });
};

export const completeMovement = (movementId, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .complete()
    .put(null, token)
    .then(response => {
      dispatch(loaded());
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.MOVEMENT_COMPLETED, 1, () => {
          dispatch(confirmationResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const deliverMovement = movementId => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .appendToUrl('deliver/')
    .put(null, token)
    .then(response => {
      dispatch(loaded());
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.MOVEMENT_DELIVERED, 1, () => {
          window.location.reload();
        });
      }
    });
};

export const rejectMovement = (movementId, data, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .reject()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.MOVEMENT_REJECTED, 1, () => {
          dispatch(rejectionResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const canMarkComplete = (contractId, callback, isPackMovement, startLoader = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  if (startLoader) dispatch(isLoading('canMarkComplete'));
  APIService.freights()
    .contracts(contractId)
    .appendToUrl('can-mark-complete/')
    .get(token)
    .then(res => {
      dispatch(forceStopLoader());
      if (res.result) {
        dispatch(canMarkCompleteForSelectedMovementId(true));
        if (callback)
          callback();
      } else {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div id="complete-dialog-open" className=""><p>You do not have permission to Complete this ${isPackMovement ? 'Pack' : 'Freight'} Movement because:</p><ul>${reasons}</ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => {},
        );
      }
    });
};

export const canAssignParentToMovement = (contractId, callback, isPackMovement, startLoader=true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(contractId)
    .appendToUrl('can-assign-parent/')
    .get(token)
    .then(res => {
      if (res.result) {
        dispatch(canAssignForSelectedMovementId(true));
        if(callback)
          callback();
        if (startLoader)
          dispatch(isLoading('canAssignTo'));
      } else {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to Assign this ${isPackMovement ? 'Pack' : 'Freight'} Movement because:</p><ul>${reasons}</ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => {},
        );
      }
    });
};


export const canMarkDelivered = (contractId, callback) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(contractId)
    .appendToUrl('can-mark-deliver/')
    .get(token)
    .then(res => {
      if (res.result) {
        callback();
      } else {
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>${res.reasons}</p></div>`,
          () => { },
        );
      }
    });
};

export const canRejectLoad = (contractId, callback, isPackMovement, startLoader = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(contractId)
    .appendToUrl('can-reject-load/')
    .get(token)
    .then(res => {
      if (res.result) {
        if (callback)
          callback();
        dispatch(rejectLoadForMovementId(contractId));
        if (startLoader)
        dispatch(isLoading('loadRejectionDialog'));
      } else {
        const reasons = '<li>' + res.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div className=""><p>You do not have permission to Reject this ${isPackMovement ? 'Pack' : 'Freight'} Movement because:</p><ul>${reasons}</ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => { },
        );
        dispatch(rejectLoadForMovementId(undefined));
        dispatch(clearFreightContract());
      }
      dispatch(forceStopLoader());
    });
};

export const rejectMovementAfterDelivery = (movementId, data, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  dispatch(isLoading('alertify'));
  APIService.freights()
    .contracts(movementId)
  ['reject-load']()
    .put(data, token)
    .then(response => {
      dispatch(loaded());
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.MOVEMENT_REJECTED, 1, () => {
          dispatch(rejectionResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const raiseVoidRequest = (movementId, data, isDuplicated=false) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .void()
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        if (isArray(get(response, 'errors')) && !isEmpty(get(response, 'errors'))) {
          const errors = '<li>' + get(response, 'errors').join('</li><li>');
          alertifyjs.alert(
            'Error:',
            `<div id="complete-dialog-open" className=""><p>Following errors occured:</p><ul>${errors}</ul></div>`,
            () => {},
          );
        }
        else
          alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_REQUEST_RAISED, 1, () => {
          if(isDuplicated)
            window.location.hash = `#/freights/movements/new?copyFrom=${movementId}`;
          else
            window.location.reload();
        });
      }
    });
};

export const confirmVoid = (movementId, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
  ['void/confirm']()
    .put(null, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_CONFIRMED, 1, () => {
          dispatch(confirmationResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const rejectVoid = (movementId, data, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
  ['void/reject']()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_REJECTED, 1, () => {
          dispatch(rejectionResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const confirmOrder = (orderId, data, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .orders(orderId)
    .confirm()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        dispatch(loaded());
        alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
      } else {
        alertifyjs.success(MESSAGES.ORDER_CONFIRMED, 1, () => {
          dispatch(orderConfirmationResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const rejectOrder = (orderId, data, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .orders(orderId)
    .reject()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        dispatch(loaded());
        alertifyjs.error(get(response, 'errors.0', 'An Error Occurred!'));
      } else {
        alertifyjs.success(MESSAGES.ORDER_REJECTED, 1, () => {
          dispatch(orderRejectionResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const raiseVoidOrderRequest = (orderId, data, isDuplicated=false, typeId=null) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .orders(orderId)
    .void()
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_REQUEST_RAISED, 1, () => {
          if(isDuplicated){
            dispatch(canRaiseVoidAndDuplicateRequestForSelectedFreightOrder(false))
            if(REQUEST_ORDER_TYPE_IDS.includes(typeId))
              document.location.hash = `#/orders/requests/new?copyFrom=${orderId}`;
            else if(typeId == PACK_ORDER_TYPE_ID)
              document.location.hash = `#/orders/pack/new?copyFrom=${orderId}`;
            else
              document.location.hash = `#/freights/orders/new?copyFrom=${orderId}`;
          }
          else
            window.location.reload();
        });
      }
    });
};

export const confirmVoidOrder = (orderId, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .orders(orderId)
  ['void/confirm']()
    .put(null, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_CONFIRMED, 1, () => {
          dispatch(orderConfirmationResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const rejectVoidOrder = (orderId, data, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .orders(orderId)
  ['void/reject']()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VOID_REJECTED, 1, () => {
          dispatch(orderRejectionResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const raiseOrderAmendRequest = (orderId, data, successMessage = MESSAGES.AMEND_REQUEST_RAISED, history = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .orders(orderId)
    .amend()
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
        dispatch(forceStopLoader());
      } else {
        alertifyjs.success(successMessage, 1, () => {
          if (history)
            window.history.back();
          setTimeout(() => window.location.reload(), 100);
        });
      }
    });
};

export const confirmOrderAmend = (orderId, inActivatedUserToken, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  APIService.freights()
    .orders(orderId)
  ['amend/confirm']()
    .put(null, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.AMEND_CONFIRMED, 1, () => {
          dispatch(orderConfirmationResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const rejectOrderAmend = (orderId, data, inActivatedUserToken, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  APIService.freights()
    .orders(orderId)
  ['amend/reject']()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.AMEND_REJECTED, 1, () => {
          dispatch(orderRejectionResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const raiseMovementAmendRequest = (movementId, data, successMessage = MESSAGES.AMEND_REQUEST_RAISED, isMovementPage = true) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .amend()
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        if (isArray(response.errors) && !isEmpty(response.errors)) {
          dispatch(forceStopLoader());
          const errors = '<li>' + response.errors.join('</li><li>');
          alertifyjs.alert(
            'Error:',
            `<div id="complete-dialog-open" className=""><p>Following errors occured:</p><ul>${errors}</ul></div>`,
            () => {},
          );
        }
        else
          alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(successMessage, 1, () => {
          if(isMovementPage) {
            window.history.back();
            setTimeout(() => window.location.reload(), 100);
          }
        });
      }
    });
};

export const confirmMovementAmend = (movementId, inActivatedUserToken, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  APIService.freights()
    .contracts(movementId)
  ['amend/confirm']()
    .put(null, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.AMEND_CONFIRMED, 1, () => {
          dispatch(confirmationResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};

export const rejectMovementAmend = (movementId, data, inActivatedUserToken, reload = true) => (dispatch, getState) => {
  const token = getState().main.user.token || inActivatedUserToken;
  APIService.freights()
    .contracts(movementId)
  ['amend/reject']()
    .put(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.AMEND_REJECTED, 1, () => {
          dispatch(rejectionResponse(response));
          if (reload) window.location.reload();
        });
      }
    });
};


export const getFreightContractOptions = (movementId, type = 'default', isPackMovement = 'false') => (dispatch, getState) => {
  dispatch(freightContractActionOptions(undefined));
  const token = getState().main.user.token;
  const contract = APIService.freights().contracts(movementId);
  contract.appendToUrl(`action-options/?type=${type}&is_pack_movement=${isPackMovement}`);
  contract.get(token).then(subItems => {
    dispatch(freightContractActionOptions(get(subItems, '[0].subItems')));
  });
};


export const canFreightContractBeVoided = (event, movementId, isPackMovement, callback) => (dispatch, getState) => {
  dispatch(isLoading('void-dialog'));
  const token = getState().main.user.token;
  const freightContract = APIService.freights().contracts(movementId);
  freightContract.appendToUrl(`action-options/?type=can-void`);
  freightContract.get(token).then(res => {
      dispatch(forceStopLoader());
      let reasons = get(res[0], 'cannotRaiseVoidRequestReasons', undefined);
      if (!isEmpty(reasons)) {
        dispatch(voidableSelectedFreightMovementId(undefined));
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
            'Permission Denied',
            `<div><p>You cannot void this ${isPackMovement ? 'Pack' : 'Freight'} Movement because:</p><ul>${reasons}</ul></div>`,
            () => {}
          );
        event.preventDefault();
      } else if(isArray(reasons) && isEmpty(reasons)){
         if (callback)
           callback();
         dispatch(voidableSelectedFreightMovementId(movementId));
      }
    });
};

export const canFreightContractBeVoidedAndDuplicated = (event, movementId, isPackMovement, callback) => dispatch => {
  dispatch(isLoading('void-dialog'));
  const freightContract = APIService.freights().contracts(movementId);
  freightContract.appendToUrl(`action-options/?type=can-void`);
  freightContract.get().then(res => {
      dispatch(forceStopLoader());
      let reasons = get(res[0], 'cannotRaiseVoidRequestReasons', undefined);
      if (!isEmpty(reasons)) {
        dispatch(voidableSelectedFreightMovementId(undefined));
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
            'Permission Denied',
            `<div><p>You cannot void this ${isPackMovement ? 'Pack' : 'Freight'} Movement because:</p><ul>${reasons}</ul></div>`,
            () => {}
          );
        event.preventDefault();
      } else if(isArray(reasons) && isEmpty(reasons)){
        alertifyjs
        .confirm(
          'Warning',
          `This will void the current movement and allow you to create a movement with similar details.`,
          () => {
            if (callback)
              callback();
            dispatch(canRaiseVoidAndDuplicateRequestForFreightMovement(true));
            dispatch(voidableSelectedFreightMovementId(movementId));
          },
          () => { },
        )
        .set('labels', { ok: 'Proceed', cancel: 'Cancel' });
      }
    });
};

export const canLoadBeVoided = (movementId, loadId, loadType, callback) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const freightContract = APIService.freights().contracts(movementId);
  freightContract.appendToUrl(`action-options/?type=can-void-load&load_type=${loadType}`);
  freightContract.get(token).then(res => {
      dispatch(forceStopLoader());
      let reasons = get(res[0], 'cannotRaiseVoidRequestReasons', undefined);
      if (!isEmpty(reasons)) {
        dispatch(voidableLoad(undefined));
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
            'Permission Denied',
            `<div><p>You cannot void this ${loadType} because:</p><ul>${reasons}</ul></div>`,
            () => {}
          );
      } else if(isArray(reasons) && isEmpty(reasons)){
          if (callback)
            callback();
          dispatch(voidableLoad(loadId, loadType));
      }
    });
};

export const canFreightContractBeAmended = (event, movementId) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const freightContract = APIService.freights().contracts(movementId);
  freightContract.appendToUrl(`action-options/?type=can-amend`);
  freightContract.get(token).then(res => {
    let reasons = get(res[0], 'cannotRaiseAmendRequestReasons', undefined);
    if (!isEmpty(reasons)) {
      reasons = '<li>' + reasons.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>You cannot amend this Freight Movement because:</p><ul>${reasons}</ul></div>`,
        () => { }
      );
    } else if (isArray(reasons) && isEmpty(reasons)) {
      window.location = `#/freights/movements/${movementId}/edit`;
    }
    event.preventDefault();
  });
};

export const canAddLoads = (event, movementId, callback) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const freightContract = APIService.freights().contracts(movementId);
  freightContract.appendToUrl(`action-options/?type=can-add-loads`);
  freightContract.get(token).then(res => {
      let reasons = get(res[0], 'cannotAddInloadOutloadReasons', undefined);
      if (!isEmpty(reasons)) {
        dispatch(setClickedOption(null));
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
            'Permission Denied',
            `<div><p>You cannot Add/Edit Loads because:</p><ul>${reasons}</ul></div>`,
            () => {}
          );
      } else if(isArray(reasons) && isEmpty(reasons)){
        if(callback) callback();
        dispatch(shouldFetchFreightMovement(true));
        dispatch(setFreightMovementId(movementId));
        dispatch(isLoading('inloadOutloadSideDrawerForm'));
      }
      event.preventDefault();
    });
};

export const canAddLoadsForMovement = (event, movementId, callback) => (dispatch, getState) => {
  const token = getState().main.user.token;
  const freightContract = APIService.freights().contracts(movementId);
  freightContract.appendToUrl(`action-options/?type=can-add-loads`);
  freightContract.get(token).then(res => {
      let reasons = get(res[0], 'cannotAddInloadOutloadReasons', undefined);
      if (!isEmpty(reasons)) {
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
            'Permission Denied',
            `<div><p>You cannot Add/Edit Loads because:</p><ul>${reasons}</ul></div>`,
            () => {}
          );
      } else if(isArray(reasons) && isEmpty(reasons)){
        if(callback) callback();
        dispatch(isLoading('inloadOutloadSideDrawerForm'));
      }
      event.preventDefault();
    });
};

//  eslint-disable-next-line no-unused-vars
export const raiseVendorDecRequest = (orderId, data, requestVendorDecType='grain_commodity') => dispatch =>{
  APIService.vendor_decs().appendToUrl(`${orderId}/request/${requestVendorDecType}/`)
    .post(data)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.VENDOR_DEC_REQUEST_RAISED, 1, () => {
          window.location.reload();
        });
      }
    });
};

export const handleFreightMovementOptionsMapper = (
  event, item, baseEntity, mappingFrom='listing'
) => dispatch => {
  if ((mappingFrom ==='listing')){
    dispatch(clearFreightContract());
  }
  const fromListing = mappingFrom === 'listing';
  const isPackMovement = baseEntity.isPackMovement;
  dispatch(setClickedOption(item));
  dispatch(freightContractActionOptions([]));
  dispatch(setFreightMovementId(baseEntity.id));
  dispatch(setFreightMovement(baseEntity));
  if (item.key === 'view_in_new_tab') {
    openURLInNewTab(`#/freights/movements/${baseEntity.id}/details`);
  } else if (item.key === 'mark_complete') {
    mappingFrom === 'MovementDetailsAction' ?
      dispatch(canMarkComplete(baseEntity.id, undefined, isPackMovement, false)) :
      dispatch(canMarkComplete(baseEntity.id, undefined, isPackMovement, true));
  } if (item.key === 'grain_commodity' || item.key === 'truck_cleanliness') {
    dispatch(setFreightMovementId(baseEntity.id));
    if (fromListing && isCustomerGradeOrTonnageMissing(baseEntity)) {
      return;
    }
    dispatch(shouldFetchFreightMovement(true));
  } else if (item.key === 'vendor_dec_view'){
    dispatch(setFreightMovementId(baseEntity.id));
  } else if (item.key === 'grain_commodity_request'){
    dispatch(setFreightMovementId(baseEntity.id));
    if(fromListing){
      dispatch(shouldFetchFreightMovement(true));
    }
  } else if (item.key === 'truck_cleanliness_request'){
    dispatch(setFreightMovementId(baseEntity.id));
    if(fromListing){
      dispatch(shouldFetchFreightMovement(true));
    }
  } else if (item.key ==='add_edit_inload' || item.key ==='add_edit_outload'){
    fromListing ? dispatch(shouldFetchFreightMovement(true)): "";
  }
  else if (item.key === 'mark_as_delivered') {
    dispatch(canMarkDelivered(baseEntity.id, () => {
      dispatch(canMarkCompleteForSelectedMovementId(true));
    }));
  } else if (item.key === 'load_reject') {
    dispatch(setFreightMovementId(baseEntity.id));
    mappingFrom === 'MovementDetailsAction' ?
      dispatch(canRejectLoad(baseEntity.id, undefined, isPackMovement, false)):
      dispatch(canRejectLoad(baseEntity.id, undefined, isPackMovement, true));
  } else if (item.key === 'void') {
    dispatch(canFreightContractBeVoided(event, baseEntity.id, isPackMovement));
  } else if (item.key === 'duplicate') {
    window.location.hash = `#/freights/movements/new?copyFrom=${baseEntity.id}`;
  } else if (item.key === 'void_and_duplicate') {
    dispatch(canFreightContractBeVoidedAndDuplicated(event, baseEntity.id, isPackMovement));
  } else if (item.key === 'revive') {
    dispatch(reviveMovement(baseEntity.id))
  } else if (item.key === 'amend') {
    dispatch(canFreightContractBeAmended(event, baseEntity.id));
  } else if (item.key === 'download_pdf') {
    dispatch(getFreightContractOptions(baseEntity.id, 'download', baseEntity.typeId == PACK_ORDER_TYPE_ID));
  } else if (item.key === 'download_freight_contract_pdf') {
    openURLInNewTab(item.url);
  } else if (item.key === 'assign_to') {
    dispatch(setFreightMovementId(baseEntity.id));
    if(fromListing)
      dispatch(canAssignParentToMovement(baseEntity.id,() => dispatch(shouldFetchFreightMovement(true)), isPackMovement, false));
    dispatch(canAssignParentToMovement(baseEntity.id, false, isPackMovement, false));
  } else if (item.key === 'review_amendment') {
    if(fromListing)
      dispatch(shouldFetchFreightMovement(true));
    dispatch(isLoading('movementDetail'));
  }
};

export const raiseIgnoreDocketDataRequest = (movementId, data) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .meta()
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.IGNORE_MOVEMENT, 1, () => {
          window.location.reload();
        });
      }
    });
};

export const reviveMovement = movementId => () => {
  APIService.freights().contracts(movementId).appendToUrl('revive/').put().then(() => {
    alertifyjs.success('Successfully Revived. Reloading..', 3, () => {
      window.location.reload()
    })
  })
};

export const raiseIncludeInDocketEntryRequest = (movementId, data) => (dispatch, getState) => {
  const token = getState().main.user.token;
  APIService.freights()
    .contracts(movementId)
    .meta()
    .post(data, token)
    .then(response => {
      if (has(response, 'errors')) {
        alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success(MESSAGES.INCLUDE_MOVEMENT, 1, () => {
          window.location.reload();
        });
      }
    });
};
