import React from 'react';

import { connect } from 'react-redux';
import {
  Dialog, DialogActions, DialogContent, Button, Checkbox, FormControlLabel,
  Table, TableBody, TableHead, TableCell, TableRow, Typography, Chip
} from '@mui/material';
import { EMPTY} from '../../common/constants';
import ColorPicker from '../common/ColorPicker';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { updateSMSettings } from '../../actions/api/company-sites';
import CommonTextField from '../common/CommonTextField';
import CommonSelect from '../common/select/CommonSelect';
import {
  reject, find, map, difference, includes, isEqual, startCase, some, get, set, toInteger, toNumber
} from 'lodash';
import { getCompanySites } from '../../actions/api/company-sites';
import { receiveCompanySites } from '../../actions/companies/company-sites';

class SiteManagementSettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.STATUSES = ['planned', 'booked', 'inProgress', 'completed', 'delayed', 'cancelled'];
    this.TITLE_FIELDS = [
      'start', 'commodity', 'bookingNumber', 'tonnage', 'freightProvider', 'truckOwner', 'truck', 'driver', 'grade', 'pits', 'customer', 'priority', 'type',
      'movementIdentifier', 'deliveryOrderNumber', 'commodityContractNumber', 'season', 'siteOrder'
    ];
    this.TOOLTIP_FIELDS = [
      'start', 'commodity', 'bookingNumber', 'tonnage', 'freightProvider', 'truckOwner', 'truck', 'driver', 'grade', 'pits', 'customer', 'priority', 'type',
      'movementIdentifier', 'deliveryOrderNumber', 'commodityContractNumber', 'season', 'siteOrder'
    ];

    this.CHECKPOINT_ORDER_CREATION_PERMISSIONS = [
      {id: 'site_only', name: 'Site Only'},
      {id: 'grain_owner_and_site_acceptance_from_site', name: 'Grain Owner and Site (Acceptance from Site)'},
      {id: 'grain_owner_and_site', name: 'Grain Owner and Site (Site Acceptance Not Required)'},
    ];

    this.VIEWS_OPTIONS = [
      {id: 'day', name: 'Daily'},
      {id: 'week', name: 'Weekly'},
      {id: 'month', name: 'Monthly'},
    ];
    this.PITS = [
      { id: 'road', name: 'Road' },
      { id: 'rail', name: 'Rail' },
      { id: 'bulk', name: 'Bulk' },
      { id: 'liquid', name: 'Liquid' },
      { id: 'additive', name: 'Additive' },
      { id: 'flour', name: 'Flour' },
      { id: 'animal_nutrition', name: 'Animal Nutrition' },
      { id: 'meal', name: 'Meal' },
    ];

    this.state = {
      settings: null,
      updatedTitleOrder: [],
      updatedTooltipOrder: [],
      updatedPitOptions: [],
      selectedColorPicker: null,
      updatedSiteForTrailerBooking: [],
      colorPickers: {
        planned: false,
        booked: false,
        inProgress: false,
        completed: false,
        delayed: false,
        cancelled: false,
      },
    };

    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLabelChange = this.handleLabelChange.bind(this);
    this.handleStatusLabelChange = this.handleStatusLabelChange.bind(this);
    this.handleFieldShowChange = this.handleFieldShowChange.bind(this);
    this.handleFieldMandatoryChange = this.handleFieldMandatoryChange.bind(this);
    this.closeColorPicker = this.closeColorPicker.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleTooltipChange = this.handleTooltipChange.bind(this);
    this.handlePitOptionsChange = this.handlePitOptionsChange.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleLeftBorderColorFieldByPitsCheckbox = this.handleLeftBorderColorFieldByPitsCheckbox.bind(this);
    this.handleOrderBookingCheckbox = this.handleOrderBookingCheckbox.bind(this);
    this.handleTrailerBookingChange = this.handleTrailerBookingChange.bind(this);
    this.handleMinimumTonnageChange = this.handleMinimumTonnageChange.bind(this);
    this.handleTrailerBookingQuantityChange = this.handleTrailerBookingQuantityChange.bind(this);
  }

  componentDidMount() {
    if(this.props.settings) {
      const { settings } = this.props;
      this.props.getCompanySites(this.props.companyId);
      this.setState({
        settings: settings,
        updatedTitleOrder: settings.titleOrder,
        updatedTooltipOrder: settings.tooltipOrder,
        updatedPitOptions: settings.pits,
        updatedSiteForTrailerBooking: settings.trailerBooking
      });
    }

  }

  componentDidUpdate(prevProps) {
    if(this.props.statusColors && !isEqual(this.props.statusColors, this.state.colors))
      this.setState({colors: this.props.statusColors});
    if(!isEqual(this.props.settings, prevProps.settings) && this.props.settings) {
      const {settings} = this.props;
      this.setState({
        settings: settings,
        updatedTitleOrder: settings.titleOrder,
        updatedTooltipOrder: settings.tooltipOrder,
        updatedPitOptions: settings.pits,
        updatedSiteForTrailerBooking: settings.trailerBooking
      });
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    if (dispatch) {
      dispatch(receiveCompanySites([]));
    }
  }

  onColorPickerClick(status) {
    if(status)
      this.setColorPickerViewable(status, true, true);
  }

  closeColorPicker() {
    this.setColorPickerViewable(this.state.selectedColorPicker, false, false);
  }

  setColorPickerViewable(status, viewable, setSelectedColorPicker) {
    const newState = {...this.state};
    newState.selectedColorPicker = setSelectedColorPicker ? status : null;
    set(newState, `colorPickers[${status}]`, viewable);
    this.setState(newState);
  }

  shouldShowColorPicker() {
    return some(this.state.colorPickers);
  }

  handleColorChange(color) {
    const newState = {...this.state};
    newState.settings.statuses.forEach(status => {
      if(status.id == newState.selectedColorPicker)
        status.color = color;
    });
    this.setState(newState);
  }

  handleLeftBorderColorFieldByPitsCheckbox(event) {
    this.setState({
      settings: {...this.state.settings, leftBorderColorByPits: event.target.checked}
    });
  }

  handleOrderBookingCheckbox(event) {
    this.setState({settings: {...this.state.settings, orderBooking: event.target.checked}});
  }

  handleCheckboxChange = event => this.setState({settings: {...this.state.settings, [event.target.id]: event.target.checked}});

  handleTrailerBookingChange(value) {
    if(value)
      this.setState({updatedSiteForTrailerBooking: [...this.state.updatedSiteForTrailerBooking, value]});
  }

  handleSubmit(event) {
    event.preventDefault()
    const payload = {
      statuses: this.state.settings.statuses,
      fields: this.state.settings.fields,
      titleOrder: this.state.updatedTitleOrder,
      pits: this.state.updatedPitOptions,
      tooltipOrder: this.state.updatedTooltipOrder,
      defaultView: this.state.settings.defaultView,
      leftBorderColorByPits: this.state.settings.leftBorderColorByPits,
      orderBooking: this.state.settings.orderBooking,
      activityLog: this.state.settings.activityLog,
      customerOptional: this.state.settings.customerOptional,
      loadByLoadTransfer: this.state.settings.loadByLoadTransfer,
      trailerBooking: map(this.state.updatedSiteForTrailerBooking, i => toNumber(i)),
      trailerBookingQuantity: this.state.settings.trailerBookingQuantity ? this.state.settings.trailerBookingQuantity : null,
      minimumTonnage: this.state.settings.minimumTonnage,
      pickupOrderNumberRequired: this.state.settings.pickupOrderNumberRequired,
      deliveryOrderNumberRequired: this.state.settings.deliveryOrderNumberRequired,
      checkpointOrderCreationPermission: this.state.settings.checkpointOrderCreationPermission,
    };

    this.props.updateSMSettings(payload, this.props.companyId);

    if(this.props.postSubmit)
      this.props.postSubmit(payload);
  }

  handleClose() {
    if(this.props.handleClose)
      this.props.handleClose();
  }

  allowedTitleFields() {
    return difference(this.TITLE_FIELDS, this.state.updatedTitleOrder);
  }

  allowedPitOptions() {
    return reject(this.PITS, pit => {return includes(this.state.updatedPitOptions, pit.id);});
  }

  allowedSitesOptions() {
    return reject(this.props.sites, site => {return includes(this.state.updatedSiteForTrailerBooking, site.id);});
  }

  allowedTooltipFields() {
    return difference(this.TOOLTIP_FIELDS, this.state.updatedTooltipOrder);
  }

  handleTitleChange(value) {
    if(value)
      this.setState({updatedTitleOrder: [...this.state.updatedTitleOrder, value]});
  }

  handlePitOptionsChange(value) {
    if(value)
      this.setState({updatedPitOptions: [...this.state.updatedPitOptions, value]});
  }

  handleCheckpointOrderCreationPermission = value => this.setState({settings: {...this.state.settings, checkpointOrderCreationPermission: value}});

  handleTooltipChange(value) {
    if(value)
      this.setState({updatedTooltipOrder: [...this.state.updatedTooltipOrder, value]});
  }

  handleDeleteTitleChip = field => {
    this.setState({updatedTitleOrder: difference(this.state.updatedTitleOrder, [field])});
  };

  handleDeleteTooltipChip = field => {
    this.setState({updatedTooltipOrder: difference(this.state.updatedTooltipOrder, [field])});
  };

  handleDeletePitChip = field => {
    this.setState({updatedPitOptions: difference(this.state.updatedPitOptions, [field])});
  };

  handleDeleteSiteChip = field => {
    this.setState({updatedSiteForTrailerBooking: difference(this.state.updatedSiteForTrailerBooking, [field])});
  };

  handleLabelChange(event) {
    const newState = {...this.state};
    newState.settings.fields.forEach(field => {
      if(field.id === event.target.id)
        field.label = event.target.value;
    });

    this.setState(newState);
  }

  handleMinimumTonnageChange(event) {
    const newState = {...this.state};
    newState.settings.minimumTonnage = event.target.value;
    this.setState(newState);
  }

  handleTrailerBookingQuantityChange(event) {
    const newState = {...this.state};
    newState.settings.trailerBookingQuantity = event.target.value;
    this.setState(newState);
  }

  handleStatusLabelChange(event) {
    const newState = {...this.state};
    newState.settings.statuses.forEach(status => {
      if(status.id === event.target.id)
        status.label = event.target.value;
    });

    this.setState(newState);
  }

  handleFieldShowChange(event) {
    const newState = {...this.state};
    newState.settings.fields.forEach(field => {
      if(field.id === event.target.id)
        field.show = event.target.checked;
    });

    this.setState(newState);
  }

  handleFieldMandatoryChange(event) {
    const newState = {...this.state};
    newState.settings.fields.forEach(field => {
      if(field.id === event.target.id)
        field.mandatory = event.target.checked;
    });

    this.setState(newState);
  }

  handleViewChange(value) {
    if(value && value !== get(this.state.settings, 'defaultView')) {
      const newState = {...this.state};
      newState.settings.defaultView = value;

      this.setState(newState);
    }
  }

  render() {
    const { settings } = this.state;
    const allowedTitleFields = this.allowedTitleFields();
    const allowedTooltipFields = this.allowedTooltipFields();
    return(
      settings ? <form noValidate className="sm-settings-form">
        <div className="cardForm cardForm--drawer">
          <div className="cardForm-content row">
            <div className="col-sm-12">
              <div style={{opacity: '0.54'}}>
                <Typography variant="h6" id="tableTitle">
                  Customise Fields
                </Typography>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Label</TableCell>
                    <TableCell>Visible</TableCell>
                    <TableCell>Mandatory</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    map(this.state.settings.fields, field => (
                      <TableRow key={field.id}>
                        <TableCell component="th" scope="row">{field.name}</TableCell>
                        <TableCell>
                          <CommonTextField
                            id={field.id}
                            value={field.label}
                            onChange={this.handleLabelChange}
                            inputProps={{autoComplete: "off"}}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            id={field.id}
                            color="primary"
                            checked={field.show}
                            onChange={this.handleFieldShowChange}
                            disabled={!field.customisable}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            id={field.id}
                            color="primary"
                            checked={field.mandatory}
                            onChange={this.handleFieldMandatoryChange}
                            disabled={!field.customisable}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
              <div style={{opacity: '0.54', marginTop: '25px'}}>
                <Typography variant="h6" id="tableTitle">
                  Customise Colors
                </Typography>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Label</TableCell>
                    <TableCell>Color</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    this.state.settings.statuses.map(status => (
                      <TableRow key={status.id}>
                        <TableCell className="status-name" component="th" scope="row">
                          { startCase(status.name) }
                        </TableCell>
                        <TableCell className="status-label" component="th" scope="row">
                          <CommonTextField
                            id={status.id}
                            value={status.label}
                            onChange={this.handleStatusLabelChange}
                            inputProps={{autoComplete: "off"}}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => this.onColorPickerClick(status.id)}
                          component="th"
                          scope="row"
                          className="color"
                          style={{backgroundColor: status.color}}>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Left Border Color On Pits (by default Inload/Outload)
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="leftBorderColorByPits"
                            color="primary"
                            checked={this.state.settings.leftBorderColorByPits}
                            onChange={this.handleLeftBorderColorFieldByPitsCheckbox}
                      />
                    }
                    label="Pits"
                  />
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Order Booking
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="orderBooking"
                            color="primary"
                            checked={this.state.settings.orderBooking}
                            onChange={this.handleOrderBookingCheckbox}
                      />
                    }
                    label="Order Booking"
                  />
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Activity Log
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="activityLog"
                            color="primary"
                            checked={this.state.settings.activityLog}
                            onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Activity Log"
                  />
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Is Customer Optional In Mobile
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="customerOptional"
                            color="primary"
                            checked={this.state.settings.customerOptional}
                            onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Is Customer Optional In Mobile"
                  />
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Load by Load Transfer
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="loadByLoadTransfer"
                            color="primary"
                            checked={this.state.settings.loadByLoadTransfer}
                            onChange={this.handleCheckboxChange}
                      />
                    }
                    label="Load by Load Transfer"
                  />
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Trailer Booking
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <CommonSelect
                    id="trailerBooking"
                    onChange={this.handleTrailerBookingChange}
                    includeEmptyOption={true}
                    dontAutoselectSingleItem={true}
                    items={this.allowedSitesOptions()}
                  />
                </div>
                <div className="col-sm-12 no-left-padding">
                  { this.state.updatedSiteForTrailerBooking &&
                    this.state.updatedSiteForTrailerBooking.map(field => (
                      <Chip
                        key={field}
                        label={get(find(this.props.sites, {id: toInteger(field)}), 'name')}
                        onDelete={() => this.handleDeleteSiteChip(field)}
                        style={{margin: '5px 5px 5px 0'}}
                      />
                    ))
                  }
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Trailer Booking Quantity
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <CommonTextField
                    id='trailerBookingQuantity'
                    value={this.state.settings.trailerBookingQuantity}
                    onChange={this.handleTrailerBookingQuantityChange}
                    inputProps={{autoComplete: "off"}}
                    type={'number'}
                  />
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Minimum Tonnage
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <CommonTextField
                    id='minimumTonnage'
                    value={this.state.settings.minimumTonnage}
                    onChange={this.handleMinimumTonnageChange}
                    inputProps={{autoComplete: "off"}}
                  />
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Select Pit Options
                  </Typography>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <CommonSelect
                    id="pit-options"
                    floatingLabelText="Pit Options"
                    onChange={this.handlePitOptionsChange}
                    includeEmptyOption={true}
                    dontAutoselectSingleItem={true}
                    items={this.allowedPitOptions()}
                  />
                </div>
                <div className="col-sm-12 no-left-padding">
                  { this.state.updatedPitOptions &&
                    this.state.updatedPitOptions.map(field => (
                      <Chip
                        key={field}
                        label={find(this.PITS, {id: field}).name}
                        onDelete={() => this.handleDeletePitChip(field)}
                        style={{margin: '5px 5px 5px 0'}}
                      />
                    ))
                  }
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Customise Slot Title
                  </Typography>
                  <span style={{fontSize: '11px'}}>
                    <span>
                      (Current Order: {' ' + get(this.state.settings, 'titleOrder', []).map(field => startCase(field)).join(' - ')})
                    </span>
                  </span>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <CommonSelect
                    id="titleOrderUpdate"
                    floatingLabelText="Title Fields"
                    onChange={this.handleTitleChange}
                    includeEmptyOption={true}
                    dontAutoselectSingleItem={true}
                    items={allowedTitleFields.map(field => {return {id: field, name: startCase(field)};})}
                  />
                </div>
                <div className="col-sm-12 no-left-padding">
                  {
                    this.state.updatedTitleOrder.map(field => (
                      <Chip
                        key={field}
                        label={startCase(field)}
                        onDelete={() => this.handleDeleteTitleChip(field)}
                        style={{margin: '5px 5px 5px 0'}}
                      />
                    ))
                  }
                </div>
              </div>
              <div className="col-sm-12 no-left-padding">
                <div style={{marginTop: '25px', opacity: '0.54'}}>
                  <Typography variant="h6" id="tableTitle">
                    Customise Slot Tooltip
                  </Typography>
                  <span style={{fontSize: '11px'}}>
                    <span>
                      (Current Order: {' ' + get(this.state.settings, 'tooltipOrder', []).map(field => startCase(field)).join(' - ')})
                    </span>
                  </span>
                </div>
                <div className="col-sm-12 no-left-padding">
                  <CommonSelect
                    id="tooltipOrderUpdate"
                    floatingLabelText="Tooltip Fields"
                    onChange={this.handleTooltipChange}
                    includeEmptyOption={true}
                    dontAutoselectSingleItem={true}
                    items={allowedTooltipFields.map(field => {return {id: field, name: startCase(field)};})}
                  />
                </div>
                <div className="col-sm-12 no-left-padding">
                  {
                    this.state.updatedTooltipOrder.map(field => (
                      <Chip
                        key={field}
                        label={startCase(field)}
                        onDelete={() => this.handleDeleteTooltipChip(field)}
                        style={{margin: '5px 5px 5px 0'}}
                      />
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div style={{marginTop: '25px', opacity: '0.54'}}>
                <Typography variant="h6" id="tableTitle">
                  Customise Default Calendar View
                </Typography>
              </div>
              <div className="cols-sm-12 no-left-padding">
                <CommonSelect
                  id="defaultView"
                  floatingLabelText="Default View"
                  onChange={this.handleViewChange}
                  dontAutoselectSingleItem={true}
                  items={this.VIEWS_OPTIONS}
                  value={get(this.state.settings, 'defaultView')}
                />
              </div>
            </div>
            {
              <React.Fragment>
                <div className="col-sm-12">
                  <div style={{marginTop: '25px', opacity: '0.54'}}>
                    <Typography variant="h6" id="tableTitle">
                      Pickup/Delivery Order Number
                    </Typography>
                  </div>
                  <div className="col-sm-3 no-side-padding">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="pickupOrderNumberRequired"
                              color="primary"
                              checked={this.state.settings.pickupOrderNumberRequired}
                              onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Mandatory for Pickup"
                    />
                  </div>
                  <div className="col-sm-3 no-left-padding">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="deliveryOrderNumberRequired"
                              color="primary"
                              checked={this.state.settings.deliveryOrderNumberRequired}
                              onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Mandatory for Delivery"
                    />
                  </div>
                  <div className="col-sm-6 no-side-padding">
                    <CommonSelect
                      id="checkpointOrderCreationPermission"
                      floatingLabelText="Pickup/Delivery Order Creation Permission"
                      onChange={this.handleCheckpointOrderCreationPermission}
                      includeEmptyOption
                      dontAutoselectSingleItem
                      items={this.CHECKPOINT_ORDER_CREATION_PERMISSIONS}
                      value={this.state.settings.checkpointOrderCreationPermission}
                    />
                  </div>
                </div>
              </React.Fragment>
            }
            <Dialog
              open={this.shouldShowColorPicker()}
              onClose={this.closeColorPicker}
              aria-labelledby="form-dialog-title">
              <DialogTitleWithCloseIcon>Choose Color</DialogTitleWithCloseIcon>
              <DialogContent>
                <ColorPicker
                  width="100%"
                  height="100%"
                  color={get(find(this.state.settings.statuses, {id: this.state.selectedColorPicker}), 'color')}
                  handleChange={this.handleColorChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.closeColorPicker} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <div
          className="col-sm-12 cardForm-action top15 padding-reset"
          style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={this.handleClose}>
            Cancel
          </Button>
          <Button type="submit" onClick={this.handleSubmit} color="primary">
            Save
          </Button>
        </div>
      </form> : EMPTY
    );
  }
}

const mapStateToProps = state => {
  return {
    sites: state.companies.companySites.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanySites: (companyId) => dispatch(getCompanySites(companyId, receiveCompanySites, null, '?user_properties=is_associated,is_managed_by_user,is_in_user_directory,is_highlighted,is_pending_request,is_pending_request_for_grower')),
    updateSMSettings: (payload, companyId) => dispatch(updateSMSettings(payload, companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteManagementSettingsForm);
