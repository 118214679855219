import React from 'react';
import { InputAdornment } from '@mui/material'
import { getCurrentCountry } from '../../common/utils';
import CommonTextField from '../common/CommonTextField';
import { toPhoneFormat } from '../../common/utils'

const PhoneField = ({id, label, value, placeholder, helperText, onBlur, onChange, disabled, errorStyle, countryCode}) => {
  const config = getCurrentCountry(countryCode)?.config
  const isText = config?.phoneFieldType === 'text'
  const _onChange = event => {
    let val = event.target.value
    if(isText && val)
      val = vanillaValue(val)
    onChange({target: {id: event.target.id, value: val}})
  }

  const vanillaValue = val => val.replaceAll(' ', '').replaceAll('-', '').replaceAll('+', '').replaceAll('(', '').replaceAll(')', '')

  const formattedValue = value ? toPhoneFormat(vanillaValue(value)) : value

  return (
    <CommonTextField
      id={id}
      label={label}
      type={config?.phoneFieldType}
      placeholder={placeholder || "Please enter 10 digit phone/mobile number"}
      value={formattedValue}
      helperText={helperText}
      errorStyle={errorStyle}
      onBlur={event => onBlur({target: {id: event.target.id, value: isText && event.target.value ? vanillaValue(event.target.value) : event.target.value}})}
      onChange={_onChange}
      onInput = {(e) =>{
        e.target.value = isText ? e.target.value : e.target.value.toString().slice(0,10);
      }}
      fullWidth
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start' style={{marginRight: '5px'}}>
            <span style={{fontSize: '14px'}}>{config?.phoneCode}</span>
          </InputAdornment>
        )
      }}
    />
  )
}

export default PhoneField;
