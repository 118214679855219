import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { isEmpty, isNumber, merge, startsWith } from 'lodash';
import { WARNING_ORANGE_CLASSES } from '../../common/constants';
import { decimalFilter, positiveDecimalFilter } from '../../common/input-filters';

const styles = () => ({
  ...WARNING_ORANGE_CLASSES,
});

const NumberField = (props) => {
  const { classes, fieldType = 'float' } = props;

  const [value, setValue] = useState(
    isNumber(props.value) || props.value === "." ? props.value : props.value || ''
  );

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value || '');
    }
  }, [props.value]);

  const handleChange = event => {
    event.persist();
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleBlur = event => {
    let cleanedValue = event.target.value.trim();
    const isFloat = fieldType === 'float';

    cleanedValue = cleanedValue.replace(/[^\d.]/g, '');

    if (isFloat) {
      const parts = cleanedValue.split('.');
      if (parts.length > 2)
        cleanedValue = parts[0] + '.' + parts.slice(1).join('');       // Allow only one decimal point

      if (cleanedValue.startsWith('.'))
        cleanedValue = '0' + cleanedValue;

      if (cleanedValue.endsWith('.'))
        cleanedValue += '0';

      if (cleanedValue === '.' || cleanedValue === '')
        cleanedValue = '';

      const [wholePart, decimalPart] = cleanedValue.split('.');
      if (decimalPart && decimalPart.length > 2)
        cleanedValue = `${wholePart}.${decimalPart.slice(0, 2)}`;

    } else {
      cleanedValue = cleanedValue.replace(/\./g, '');
    }

    // Handle leading zeros for whole number part
    if (isFloat) {
      const parts = cleanedValue.split('.');
      parts[0] = parts[0].replace(/^0+(?=\d)/, '');
      cleanedValue = parts.join('.');
    } else {
      cleanedValue = cleanedValue.replace(/^0+(?=\d)/, '');
    }

    if (props.maxLength && cleanedValue.length > props.maxLength)
      cleanedValue = cleanedValue.slice(0, props.maxLength);

    if (cleanedValue !== '') {
      const numValue = parseFloat(cleanedValue);
      if (props.minValue && numValue < props.minValue)
        cleanedValue = props.minValue.toString();
      else if (props.maxValue && numValue > props.maxValue)
        cleanedValue = props.maxValue.toString();
    }

    setValue(cleanedValue);

    const newEvent = {
      ...event,
      target: {
        ...event.target,
        value: cleanedValue
      },
      currentTarget: event.currentTarget,
      type: event.type,
      preventDefault: event.preventDefault.bind(event),
      stopPropagation: event.stopPropagation.bind(event)
    };

    Object.defineProperties(newEvent.target, {
      value: {
        get: () => cleanedValue,
        enumerable: true
      },
      id: {
        get: () => event.target.id,
        enumerable: true
      },
      name: {
        get: () => event.target.name,
        enumerable: true
      }
    });

    if (props.onBlur)
      props.onBlur(newEvent);
  };

  const handleKeyDown = event => fieldType === 'number' ?
  positiveDecimalFilter(event, 0, props.maxValue, props.minValue) :
  decimalFilter(event, 2, props.maxValue, props.minValue)

  return (
    <div className={props.disabled && props.value ? "relative-pos text-black" : "relative-pos"}>
      <TextField
        error={!isEmpty(props.helperText)}
        id={props.id}
        label={props.label}
        placeholder={props.placeholder}
        value={value}
        fullWidth
        helperText={props.helperText || props.additionalHelperText}
        name={props.name}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={props.disabled}
        type={props.type || 'text'}
        multiline={props.multiline}
        style={props.style}
        rows={props.rows}
        inputRef={props.setRef}
        inputProps={props.inputProps}
        InputProps={merge({
          maxLength: props.maxLength,
          onInput: props.onInput,
          onKeyDown: handleKeyDown,
          accept: props.accept,
          classes: {
            underline: startsWith(props.helperText, 'Warning') ? classes.warningUnderline : null,
          }
        }, props.InputProps || {})}
        className={props.className}
        InputLabelProps={startsWith(props.helperText, 'Warning') ? {
          shrink: true,
          className: classes.warningColor,
        } : {}}
        FormHelperTextProps={{
          classes: {
            error: startsWith(props.helperText, 'Warning') ? classes.warningColor : null,
          }
        }}
        variant={props.variant || "standard"}
        size={props.size || 'medium'}
      />
      {props.disabled && value ? 
        <i className={props.lockIconClassName ? "side-drawer-icon-lock" : 'icon-lock'} 
           style={props.lockIconStyle}></i> 
        : ''
      }
    </div>
  );
};

export default withStyles(styles)(NumberField);