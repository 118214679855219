import axios from 'axios';
import { forceStopLoader, setServerErrorCode, setLinkInvalid } from '../actions/main/index';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import {
  isContractEditPath,
  isOrderEditPath,
  isMovementEditPath,
  isOrderDetailsPath,
  isFarmStocksPath,
  isSiteManagementPath,
  isSiteBookingPath,
  isMovementCreatePath,
} from '../common/utils';
import alertifyjs from 'alertifyjs';

// import {
//   logSuccessResponseInHTML,
//   logErrorResponseInHTML,
// } from "../common/utils";

export default {
  setupInterceptors: store => {
    /*eslint no-process-env: 0*/
    /*eslint no-undef: 0*/
    const NO_UI_LOG = `${process.env.NO_UI_LOG}`;
    let isSearchStrPresent = window.location.hash.includes('?q=');
    axios.interceptors.response.use(
      function(response) {
        if (isEmpty(response.data) || (has(response.data, 'results') && isEmpty(get(response.data, 'results')))) {
          if (
            !isMovementEditPath() &&
            !isOrderEditPath() &&
            !isSiteManagementPath() &&
            !isSiteBookingPath() &&
            !isContractEditPath() &&
            !isOrderDetailsPath() &&
            !isFarmStocksPath() &&
            !isMovementCreatePath() &&
            !includes(window.location.hash, '/minimal/farm-or-site/') &&
            !includes(window.location.hash, '/contracts/new') &&
            !includes(response.request.responseURL, '/profiles/notifications') &&
            !includes(response.request.responseURL, 'profiles/filters') &&
            !includes(window.location.hash, '#/freights/orders/new') &&
              !includes(window.location.hash, '#/freights/grain/new') &&
              !includes(window.location.hash, '#/orders/requests/new') &&
              !includes(window.location.hash, '#/orders/pack/new') &&
            !includes(window.location.hash, '/invoices/new') &&
            !includes(window.location.hash, '/invoices/brokerage/new') &&
            !isSearchStrPresent
          ) {
            store.dispatch(forceStopLoader());
          }
        }
        if (!includes(response.request.responseURL, '/profiles/notifications')) {
          store.dispatch(setServerErrorCode(null));
        }
        if (get(store.getState(), 'main.serverEnv') === 'dev' && NO_UI_LOG !== 'true') {
          //logSuccessResponseInHTML(response);
        }
        return response;
      },
      function(error) {
        const code = get(error, 'response.status');
        if(code === 401 && localStorage.getItem('token') && get(error, 'response.data.isPasswordExpired')) {
          store.dispatch(forceStopLoader())
          localStorage.setItem("isPasswordExpired", true);
          setTimeout(() => window.location.hash = '/reset-user-password', 300)
          return response;
        } else if (code === 401) {
          localStorage.clear();
          if (includes(window.location.hash, 'user-set-password/')) {
            store.dispatch(forceStopLoader())
            alertifyjs.error("This link is expired")
            store.dispatch(setLinkInvalid(false));
            setTimeout(() => window.location.hash = '/forgot-password', 1000)
          }
          else
            window.location = '/';
        } else if (code === 404 && get(error, 'config.method', '').toLowerCase() === 'delete') {
          //ignore
        } else if (get(error, 'config.url').includes('/stocks/') && includes([404, 403], code)) {
          localStorage.removeItem('stocks')
        } else if (error?.config?.method === 'delete' && error?.config?.url?.endsWith('/xero/connections/') && code === 403){
          //ignore
        }
        else if (includes([404, 403], code) && !get(error, 'config.url', '').includes('/checkpoint-details/') && !get(error, 'config.url').includes('/sso-setting/') && !get(error, 'config.url').includes('/loads/identifier/')) {
          store.dispatch(setServerErrorCode(code.toString()));
        }
        if (get(store.getState(), 'main.serverEnv') === 'dev' && NO_UI_LOG !== 'true') {
          //logErrorResponseInHTML(error);
        }
        return Promise.reject(error);
      },
    );
  },
};
