import React from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Cancel from '@mui/icons-material/Close';
import { required } from '../../../common/validators';
import { get, includes, isNull } from 'lodash';
import {
  confirmOrder, confirmVoidOrder, raiseOrderAmendRequest,
  raiseVoidOrderRequest,
  rejectOrder, rejectVoidOrder, setClickedOption,
} from '../../../actions/companies/freights';
import OrderProviderDetailsForm from "./OrderProviderDetailsForm";
import {isLoading} from "../../../actions/main";
import SideDrawer from "../../common/SideDrawer";
import AssignContractToOrderForm from "./AssignContractToOrderForm";
import {RejectionReasonDialog} from "../../rejections/RejectionReasonDialog";
import EditOrderReview from "./EditOrderReview";
import moment from "moment";
import {
  CALL_ON_GRAIN_TYPE_ID, REQUEST_ORDER_TYPE_IDS, ORDER_TYPE_NAMES
} from "../../../common/constants";
import NestedOptionMenu from "../../NestedOptionMenu";
import {
  getFreightOrderActionMenuOptions, getGrainOrderActionMenuOptions, regenerateOrderPDF,
} from '../utils';
import {handleCallOnGrainOrderOptionsMapper, handleFreightOrderOptionsMapper} from "../../../actions/companies/orders";
import FreightCustomEmail from '../../common/FreightCustomEmail';
import FreightOrderActions from '../FreightOrderActions';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../../common/DialogTitleWithCloseIcon';
import { RECEIVER_DELIVERY_SITE, RECEIVER_PICKUP_SITE, SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS } from '../Constants';
import { isCurrentUserBelongsToCompany } from '../../../common/utils';

export class OrderDetailsActions extends React.Component {
  constructor(props){
    super(props);
    this.customerPaymentTermId = get(this.props.order.customer, 'company.paymentTermId');
    this.state = {
      anchorEl: null,
      rejectDialogOpen: false,
      rejectionAction: null,
      rejectionTitle: null,
      acceptDialogOpen: false,
      acceptDrawerOpen: false,
      shouldAddData: this.props.order.shouldAddMissingDetails,
      canCustomerCreateMovements: this.props.order.canCustomerCreateMovements,
      showCustomEmailDialog: false,
      acceptRejectTitle: null,
      acceptRejectSubject: null,
      action: null,
      requestData: null,
      orderNumber: {
        value: get(this.props.order, 'identifier'),
        validators: [required()],
        errors: []
      },
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },
      exceedTonnageWarningDialogOpen: undefined,
      exceedTonnageReviewWarningDialogOpen: undefined,
      dialogContent: undefined,
    };
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.setReasonErrors = this.setReasonErrors.bind(this);
    this.getReasonErrors = this.getReasonErrors.bind(this);
    this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
    this.handleRequestReasonChange = this.handleRequestReasonChange.bind(this);
    this.handleVoidClick = this.handleVoidClick.bind(this);
  }

  clearEntity() {
    this.props.dispatch(setClickedOption(null));
  }

  componentDidMount() {
    setTimeout(this.setActionButtonBoundaries, 100);
  }

  setActionButtonBoundaries() {
    const statusBarEl = document.getElementById('order-details-basic-info-card');
    if(statusBarEl) {
      const top = statusBarEl.offsetTop;
      const height = statusBarEl.offsetHeight;
      if(top && height) {
        const actionsContainer = document.getElementById('nested-actions-menu-container');
        if(actionsContainer) {
          actionsContainer.style.top = (top - 1) + 'px';
          actionsContainer.style.height = (height + 1) + 'px';
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.state.shouldAddData !== this.props.order.shouldAddMissingDetails){
      this.setState({shouldAddData: this.props.order.shouldAddMissingDetails});
    }
    if (this.state.canCustomerCreateMovements !== this.props.order.canCustomerCreateMovements){
      this.setState({canCustomerCreateMovements: this.props.order.canCustomerCreateMovements});
    }
  }

  tonnageWarning(acceptanceType) {
    const { order } = this.props;
    let availableTonnage = parseFloat(get(order, 'availableTonnage'));
    let plannedTonnage = parseFloat(get(order, 'amendDetails.plannedTonnage') || get(order, 'plannedTonnage')) - parseFloat(get(order, 'progressTonnage', 0));
    const isCurrentUserCustomer = isCurrentUserBelongsToCompany(get(order, 'customer.companyId'))
    const isCurrentUserBelongsToPickupSite = isCurrentUserBelongsToCompany(get(order, 'freightPickup.consignor.handler.companyId'))
    const isStocksManagementOnForConsignor = get(order, 'freightPickup.consignor.handler.stocksManagement')
    if (isStocksManagementOnForConsignor && get(order, 'customer.ngr.ngrNumber') && includes(SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS, get(order, 'typeId')) && (isCurrentUserCustomer || isCurrentUserBelongsToPickupSite) && !isNull(availableTonnage) && availableTonnage < plannedTonnage) {
      let remainingTonnage = (availableTonnage - plannedTonnage).toFixed(2);
      let dialogContent = `The tonnage on this order exceeds the stored tonnage for ${get(order, 'customer.company.name', '')} against NGR ${get(order, 'customer.ngr.ngrNumber', '')}.
                           This will leave ${get(order, 'customer.company.name', '')} with ${remainingTonnage} MT.`
      const dialogStateKey = acceptanceType === 'accept' ? 'exceedTonnageWarningDialogOpen' : 'exceedTonnageReviewWarningDialogOpen';
      this.setState({
        [dialogStateKey]: true,
        dialogContent: dialogContent
      });
      return true;
    }
  }

  handleRejectClickOpen = (action) => {
    const newState = { ...this.state };
    newState.rejectDialogOpen = true;
    if(!newState.rejectionReason.value){
      newState.rejectionReason.errors = [];
    }
    newState.rejectionAction = action;
    newState.rejectionTitle = 'Reject ' + (action === 'confirm' ? 'Order' : action === 'void' ? 'Void Request' : '');
    this.setState(newState);
  };
  handleRejectClose = () => {
    this.setState({rejectDialogOpen: false});
  };

  handleAcceptClose = () => {
    this.setState({acceptDialogOpen: false});
  };

  handleAcceptDrawerClose = () => {
    this.setState({acceptDrawerOpen: false});
  };

  handleReview = (event) => {
    event.preventDefault();
    if (!this.tonnageWarning('review'))
      this.setState({anchorEl: null, amendDialogOpen: true});
  };

  handleAmendOpen = () => {
    this.setState({anchorEl: null, amendDialogOpen: true, exceedTonnageReviewWarningDialogOpen: false});
  }

  handleAmendClose = () => {
    this.setState({amendDialogOpen: false, exceedTonnageReviewWarningDialogOpen: false});
  };

  handleAcceptSubmit = data => {
    const { order } = this.props;
    if (!this.props.order.providerId && !data) return;
    let orderType = this.isCallOnGrainOrder() ? 'Grain' : this.isRequestOrder() ? 'Request' : 'Freight'
    this.setState({
      acceptDialogOpen: false,
      acceptDrawerOpen: false,
      showCustomEmailDialog:true,
      acceptRejectTitle: "Accept Order",
      acceptRejectSubject: `[Accepted] ${orderType} Order #${order?.identifier}`,
      action: 'accept',
      requestData: data || null
    });
  };

  acceptOrder(event) {
    const { order } = this.props;
    this.setState({exceedTonnageWarningDialogOpen:false, dialogContent: undefined})
    if (this.state.shouldAddData){
      this.setState({acceptDialogOpen: true});
    }
    else if (get(order, 'typeId') === 1 && get(order, 'isCustomer') && !get(order, 'parentOrderId') && !includes([RECEIVER_PICKUP_SITE, RECEIVER_DELIVERY_SITE], get(order, 'requestReceiver'))) {
      this.setState({acceptDrawerOpen: true});
    } else {
      this.handleAcceptSubmit({updatedAt: moment.utc(get(order, 'updatedAt')).local().valueOf()});
    }
    event.preventDefault();
  }

  handleAcceptClick(event) {
    event.preventDefault();
    if (!this.tonnageWarning('accept'))
      this.acceptOrder(event);
  }

  handleRejectSubmit(event) {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value, updatedAt: moment.utc(get(this.props.order, 'updatedAt')).local().valueOf()};
    if (this.state.rejectionReason.errors.length === 0) {
      let orderType = this.isCallOnGrainOrder() ? 'Grain' : this.isRequestOrder() ? 'Request' : 'Freight'
      this.setState({
        showCustomEmailDialog:true,
        acceptRejectTitle: "Reject Order",
        acceptRejectSubject: `[Rejected] ${orderType} Order #${this.props.order?.identifier}`,
        action: 'reject',
        requestData: data || null,
        rejectDialogOpen: false
      });
    }
    event.preventDefault();
  }

  handleReasonChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  }

  setReasonErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  handleVoidClick() {
    const { dispatch } = this.props;
    const data = { requestReason: this.state.requestReason.value };
    dispatch(raiseVoidOrderRequest(this.props.order.id, data, true));
    this.setState({ anchorEl: null });
  }

  handleRequestReasonChange(event) {
    const value = event.target.value;
    const newState = {...this.state};
    newState.requestReason.value = value;
    this.setState(newState);
  }

  acceptVoid(id) {
    this.props.dispatch(isLoading());
    this.props.dispatch(confirmVoidOrder(id));
  }

  setMovementPrivilegesForCustomer = (order_id) => {
    this.props.dispatch(
      raiseOrderAmendRequest(
        order_id,
        {'canCustomerCreateMovements': !this.state.canCustomerCreateMovements},
        'Successfully Allowed'
      )
    );
  };

  getChangeCustomerMovementPrivilegesMessage = () => {
    return this.state.canCustomerCreateMovements ? 'Disallow Customer to Create Movements' : 'Allow Customer to Create Movements';
  };

  handleOptionClick = (event, item, baseEntity) => {
    const { dispatch } = this.props;

    if(get(item, 'key') === 'regenerate_pdf')
      return regenerateOrderPDF(baseEntity);
    else if (this.isCallOnGrainOrder())
      dispatch(handleCallOnGrainOrderOptionsMapper(event, item, baseEntity, 'details'));
    else if (this.isRequestOrder())
      dispatch(handleCallOnGrainOrderOptionsMapper(event, item, baseEntity, 'details', true));
    else
      dispatch(handleFreightOrderOptionsMapper(event, item, baseEntity, 'details'));

    if (item.key === 'can_or_cannot_customer_create_freight_movements')
      this.setMovementPrivilegesForCustomer(baseEntity.id);
  };

  isCallOnGrainOrder = () => get(this.props.order, 'typeId') === CALL_ON_GRAIN_TYPE_ID;
  isRequestOrder = () => includes(REQUEST_ORDER_TYPE_IDS, get(this.props.order, 'typeId'));


  handleAcceptRejectOrder = communicationData => {
    const { dispatch, order } = this.props;
    let data = this.state.requestData
    if(communicationData) {
      delete communicationData['acceptanceRequired']
      data['communication'] = communicationData;
    }

    if (this.state.action == 'accept') {
      delete data?.rejectionReason;
      dispatch(confirmOrder(order.id, data));
      dispatch(isLoading('alertify'));
    } else {
      if (this.state.rejectionAction === 'confirm')
        dispatch(rejectOrder(order.id, data));
      else if (this.state.rejectionAction === 'void')
        dispatch(rejectVoidOrder(order.id, data));
    }
  }

  closeEmailDialog = (communicationData, justClose, callback) => {
    this.setState({showCustomEmailDialog: false, acceptRejectTitle: null, acceptRejectSubject: null}, () => {
      if (callback && !justClose)
        callback(communicationData);
    });
  }

  render() {
    const { id, confirmable, isAmendRequestPending, voidable, amendable } = this.props.order;
    const { order, subItems, clickedOption } = this.props;
    const orderType = ORDER_TYPE_NAMES.find(orderType => orderType.id == order?.typeId)?.name;
    return (
      <div className='status-actions-container'>
        <FreightOrderActions {...this.props} selectedOrder={this.props.order} selectedOrderId={this.props.order.id}/>
        {amendable && isAmendRequestPending &&
         <div className="status-actions-wrap">
           <h4>Amend Request</h4>
           <div>
             <Button variant="contained" className='btn-green' onClick={this.handleReview}>
               REVIEW
             </Button>
           </div>
         </div>
        }
        <FreightCustomEmail order={this.props.order}
          showCustomEmailDialog={this.state.showCustomEmailDialog}
          closeCustomEmailDialog={this.closeEmailDialog}
          title={this.state.acceptRejectTitle}
          subject={this.state.acceptRejectSubject}
          hideAcceptanceRequired
          onCloseCallback={this.handleAcceptRejectOrder}
        />

        {voidable && !confirmable &&
         <div className="status-actions-wrap">
           <h4>Void Request</h4>
           <div>
             <Button
               style={{margin: '10px'}}
               variant="contained"
               className={'btn-red'}
               onClick={() => this.handleRejectClickOpen('void')}
             ><Cancel />REJECT</Button>
             <Button
               variant="contained"
               className={'btn-green'}
               onClick={() => this.acceptVoid(id)}
             ><Check />ACCEPT</Button>
           </div>
         </div>
        }
        {confirmable &&
         <div className="status-actions-wrap">
           <h4>Confirm Request</h4>
           <div className='confirm-request-actions'>
             <Button
               style={{margin: '10px', height: '30px', width: '70px', fontSize: 'smaller'}}
               variant="contained"
               className={'btn-red'}
               onClick={() => this.handleRejectClickOpen('confirm')}
             >
               REJECT
             </Button>
             <Button
               variant="contained"
               className={'btn-green'}
               style={{height: '30px', width: '70px', fontSize: 'smaller'}}
               onClick={this.handleAcceptClick}
             >
               ACCEPT
             </Button>

             <SideDrawer
               isOpen={this.state.acceptDialogOpen}
               onClose={this.handleAcceptClose}
               title="Enter Freight Details"
               size="big"
               classes={{'paper': 'left-text-align'}}
             >
               <OrderProviderDetailsForm
                 handleCancel={this.handleAcceptClose}
                 handleAccept={this.handleAcceptSubmit}
                 customerCompanyId={this.props.order.customerCompanyId}
                 customerPaymentTermId={this.customerPaymentTermId}
                 order={this.props.order}
                 {...this.props}
               />
             </SideDrawer>

             <SideDrawer
               isOpen={this.state.acceptDrawerOpen}
               onClose={this.handleAcceptDrawerClose}
               title="Enter Freight Details"
               classes={{'paper': 'left-text-align'}}
             >
               <AssignContractToOrderForm handleCancel={this.handleAcceptDrawerClose} handleAccept={this.handleAcceptSubmit} order={this.props.order}/>
             </SideDrawer>
           </div>
         </div>
        }
        {
          ((voidable || confirmable) && this.state.rejectDialogOpen) &&
          <div className="status-actions-wrap">
            <RejectionReasonDialog
              open={this.state.rejectDialogOpen}
              onClose={this.handleRejectClose}
              title={this.state.rejectionTitle}
              value={this.state.rejectionReason.value}
              onChange={this.handleReasonChange}
              helperText={get(this.state, 'rejectionReason.errors[0]', '')}
              onCancel={this.handleRejectClose}
              onReject={this.handleRejectSubmit}
            />
          </div>
        }
        {
            order && this.state.amendDialogOpen &&
           <SideDrawer
            isOpen={this.state.amendDialogOpen}
            onClose={this.handleAmendClose}
            title={`${orderType} Order Amend Request (${get(order, 'identifier', '')})`}
            classes={{'paper' : 'left-text-align'}}
            size="big"
          >
            <EditOrderReview order={order} closeSidebar={this.handleAmendClose}/>
          </SideDrawer>
        }

        {
          order &&
          <div className="status-actions-wrap">
          <NestedOptionMenu
            optionsItems={
              this.isCallOnGrainOrder() ?
                getGrainOrderActionMenuOptions(order, subItems, clickedOption) :
                getFreightOrderActionMenuOptions(order, subItems, clickedOption)
            }
            item={order}
            handleOptionClick={this.handleOptionClick}
            currentUser={this.props.currentUser}
            shouldOptionBeDisabled={this.props.shouldOptionBeDisabled}
            useButton={true}
            useIconButton={false}
            buttonContainerStyle={{float: 'none', marginLeft: 'none'}}
          />
          </div>
        }
        <Dialog
          open={Boolean(this.state.exceedTonnageWarningDialogOpen)}
          onClose={() => this.setState({exceedTonnageWarningDialogOpen:false, dialogContent: undefined})}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({exceedTonnageWarningDialogOpen:false, dialogContent: undefined})}
            id="order-accept-dialog"
          >
            Accept Order
          </DialogTitleWithCloseIcon>
          <DialogContent style={{marginTop: '10px'}}>
            <span>{this.state.dialogContent}</span>
          </DialogContent>
          <DialogActions>
            <Button type='button' onClick={() => this.setState({exceedTonnageWarningDialogOpen:false, dialogContent: undefined})} variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={(event) => this.acceptOrder(event)} color='primary' variant='contained'>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Boolean(this.state.exceedTonnageReviewWarningDialogOpen)}
          onClose={() => this.setState({exceedTonnageReviewWarningDialogOpen:false, dialogContent: undefined})}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({exceedTonnageReviewWarningDialogOpen:false, dialogContent: undefined})}
            id="order-accept-dialog"
          >
            Review Order
          </DialogTitleWithCloseIcon>
          <DialogContent style={{marginTop: '10px'}}>
            <span>{this.state.dialogContent}</span>
          </DialogContent>
          <DialogActions>
            <Button type='button' onClick={() => this.setState({exceedTonnageReviewWarningDialogOpen:false, dialogContent: undefined})} variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={(event) => this.handleAmendOpen(event)} color='primary' variant='contained'>
              Review
            </Button>
          </DialogActions>
        </Dialog>


      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contract: state.companies.contracts.selectedContract,
    currentUser: state.main.user.user,
    subItems: state.companies.orders.subItems,
    clickedOption: state.companies.orders.clickedOption,
    canRaiseVoidRequestForOrder: state.companies.orders.canRaiseVoidRequestForOrder,
    canRaiseVoidAndDuplicateRequestForOrder: state.companies.orders.canRaiseVoidAndDuplicateRequestForOrder,
    canCloseOutForOrder: state.companies.orders.canCloseOutForOrder,
    userToken: state.main.user.token
  };
};

export default connect(mapStateToProps)(OrderDetailsActions);
