import React, { Component } from 'react';
import { connect } from 'react-redux';
import '@babel/polyfill';
import alertifyjs from 'alertifyjs';
import moment from 'moment';
import {
  keys, values, compact, uniq, without, pick, capitalize, merge, sortBy, uniqBy,
  has, get, set, mapValues, forEach, includes, partialRight, intersectionWith, find, every,
  filter, map, isEqual, isNaN, isNumber, isEmpty, cloneDeep, some, max, omit, isObject, isNil,
  orderBy, omitBy, forOwn, isNull, min, toNumber, reject
} from 'lodash';

import CommodityContractParties from './CommodityContractParties';
import {
  TextField, IconButton, Dialog, DialogTitle, DialogActions, DialogContent,  Checkbox, Switch,
  FormControlLabel, Typography, InputAdornment, Grid, Button,
} from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import './movementCard.scss';
import {
  PRIMARY_COLOR_GREEN,
  FIELD,
  INLOAD_EXCESS_NET_WEIGHT_WARNING_MESSAGE,
  FREIGHT_CONTRACT_TYPE,
  BROKER,
  BHC_TYPE_ID,
  FREIGHT_CONTRACT_LOAD_SOURCE,
  PRICE_POINTS,
  WEIGHT_DESTINATION_ID,
  DEFAULT_WEIGHT_ID,
  WEIGHT_ORIGIN_ID,
  GROWER,
  NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE,
  COMMODITIES,
  MT_UNIT,
  CALL_ON_GRAIN_TYPE_ID,
  MAX_YEAR,
  MIN_YEAR, DELIVERY_MANDATORY_PRICE_POINTS,
  TRUCK_CODES,
  REQUEST_ORDER_TYPE,
  PICKUP_REQUEST_ORDER_TYPE_ID,
  SYSTEM_COMPANY_IDS,
  SEARCH_BY_FREIGHT_PROVIDER_REGOS,
  SEARCH_BY_ALL_REGOS,
  SEARCH_BY_TRUCK_OWNER_REGOS,
  NOTICE_NUMBERS,
  DELIVERY_REQUEST_ORDER_TYPE_ID,
  LOAD_CREATE_OR_EDIT_ERROR_MESSAGE
} from '../../common/constants';
import {
  getDateTimeInUTC,
  getDateTimeFromUTC,
  generateIdentifier,
  getZendeskURL,
  getLabelForEmployeeForm,
  getAutoSelectFocusField,
  getSitePhoneNumber,
  getGradeName,
  isCurrentUserBroker,
  isSystemCompany,
  getLoadWarningTableData,
  getCountryFormats,
  getCountryLabel,
  getCountryConfig,
  toPhoneFormat,
  getCountrySystemCompanyId,
  getCountryMovementDisplayUnit,
  isCompanyGrower,
  isVisibleInCountry,
  getCountryCurrency,
  isMoistureSpecMandatory,
  generateUpdatedIdentifier
} from '../../common/utils';
import CurrencyField from '../common/CurrencyField'
import { required, selected, minDate, maxDate, valueAbove, valueBetween, minLength, MESSAGES, maxLength, truckRegoRegex } from '../../common/validators';
import { getFixtures, create, raiseMovementAmendRequest } from '../../actions/companies/freights';
import { getCommoditiesWithVarietiesAndGrades } from '../../actions/api/commodities';
import { getCompanyCompaniesMinimal } from '../../actions/api/companies';
import { getCompanyTrucks } from '../../actions/api/trucks';
import { forceStopLoader, isLoading, loaded, setLoadingText, validateRego } from '../../actions/main';
import {
  clickAddGeneralConditionButton,
  clickAddSpecialConditionButton,
  getGeneralConditions,
  getSpecialConditions,
  receiveGeneralConditions,
  receiveSpecialConditions,
} from '../../actions/companies/conditions';
import { cancelEditTruck, clickAddTruckButton, receiveUserTrucks } from '../../actions/companies/trucks';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import CommonSelectInput from '../common/CommonSelectInput';
import CommonSelect from '../common/select/CommonSelect';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonTimePicker from '../common/CommonTimePicker';
import SeasonSelect from '../common/select/SeasonSelect';
import CompanyAutocomplete from '../common/autocomplete/CompanyAutocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import FileUpload from '../common/FileUpload';
import { INVOICINGS, INVOICINGS_NEW_MOVEMENTS } from './fixtures';
import { positiveDecimalFilter } from '../../common/input-filters';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import SpecParametersValue from '../common/SpecParametersValue';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import APIService from '../../services/APIService';

import MovementPreview from './movement-preview/MovementPreview';
import SideDrawer from '../common/SideDrawer';
import CreateEmployee from '../../containers/CreateEmployee';
import CreateGeneralCondition from '../../containers/CreateGeneralCondition';
import CreateSpecialCondition from '../../containers/CreateSpecialCondition';
import { clickAddEmployeeButton } from '../../actions/company-settings/employees';
import queryString from 'query-string';
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import { clickAddCompanyNgrButton } from '../../actions/companies/company-ngrs';
import { clickAddNgrButton } from '../../actions/companies/ngrs';
import TruckSearchOption from '../common/TruckSearchOption';

import { AddCircleIconWithTooltip } from '../common/icons/AddCircleIconWithTooltip';
import CreateTruck from '../../containers/CreateTruck';
import { createTruck } from '../../actions/api/trucks';
import { addTruck, emptyCreatedTruck } from '../../actions/companies/trucks';
import {
  DELIVERED_STATUS,
  FREIGHT_PROVIDER_REGISTERED_ERROR_MESSAGE,
  NO_LINKED_FARMS_ERROR_MESSAGE, PLANNED_STATUS,
  TRUCKS_ENTITY
} from "./Constants";
import { canCreateEmployee } from "../../actions/api/employees";
import { clickAddFarmEmployeeButton } from "../../actions/companies/farm-employees";
import { canCreateNgr } from "../../actions/api/ngrs";
import Notes from "../common/Notes";
import CustomEmailDialog from '../common/CustomEmailDialog';
import { createFilterOptions } from '@mui/material/Autocomplete';
import CreateCompanySite from '../../containers/CreateCompanySite';

import CounterPartiesInfo from '../vendor-decs/form-components/CounterPartiesInfo';
import { getStoredCommodityDetails } from '../../actions/api/storages';
import CreateCompany from '../../containers/CreateCompany';
import AdditionalMassLimitFields from '../common/AdditionalMassLimitFields';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import UpdateLocation from '../locations/UpdateLocation';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Create from '@mui/icons-material/Create';
import NumberField from '../common/NumberField';

const DEFAULT_SIDE_DRAWERS_STATE = {
  customerSideDrawerIsOpen: false,
  ngrSideDrawerIsOpen: false,
  freightProviderSideDrawerIsOpen: false,
  generalConditionSideDrawerIsOpen: false,
  specialConditionSideDrawerIsOpen: false,
  isTruckSideDrawerOpen: false,
  consignorSideDrawer: false,
  consigneeSideDrawer: false
};
const NO_FILTERED_ENTITIES = 'No Contracts/Orders found for the applied filter';
const autocompleteFilters = createFilterOptions();

class FreightMovementForm extends Component {
  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    this.isDuplicatingMovement = Boolean(queryParams.get('copyFrom')) && window.VOID_AND_DUPLICATE_TOGGLE
    this.copyFromMovementId = queryParams.get('copyFrom')
    this.isEditForm = this.props.match.params.movement_id;
    const query = new URLSearchParams(this.props.location.search);
    this.manualComplete = Boolean(query.get('manualComplete'));
    this.orderId = query.get('orderId');
    let startRaw = query.get('startRaw');
    let endRaw = query.get('endRaw');
    this.slotType = query.get('slotType');
    this.redirectedFromSlots = query.get('redirectedFrom') === 'slots';
    if (startRaw)
      this.slotStart = new Date(startRaw);
    if (endRaw)
      this.slotEnd = new Date(endRaw);

    this.tonnageLabel = getCountryLabel('tonnage')
    this.identifierGenerated = generateIdentifier('freight_movement');
    this.EXCESS_ORDER_TONNAGE_ERROR_MESSAGE = `Cannot be greater than Order ${this.tonnageLabel.toLowerCase()}`;
    this.EXCESS_CONTRACT_TONNAGE_ERROR_MESSAGE = `Cannot be greater than Contract ${this.tonnageLabel.toLowerCase()}`;
    this.LESS_CONTRACT_TONNAGE_ERROR_MESSAGE = `Cannot be less than Contract ${this.tonnageLabel.toLowerCase()}`;
    this.EXCESS_ORDER_QUANTITY_ERROR_MESSAGE = 'Cannot be greater than Order volume';
    this.EXCESS_CONTRACT_QUANTITY_ERROR_MESSAGE = 'Cannot be greater than Contract volume';
    this.LESS_CONTRACT_QUANTITY_ERROR_MESSAGE = 'Cannot be less than Contract volume';

    this.applyingBaseEntity = false;
    this.disableForm = true;
    this.hasFetchedSlotsInEdit = false;
    this.countryTonnageLabel = getCountryLabel('tonnage');
    this.state = {
      sellerAvailableTonnage: undefined,
      sellerAvailableTonnageMessage: undefined,
      countryFormats: getCountryFormats(),
      currency: getCountryCurrency(),
      permits: [],
      categories: [],
      subFreightProvider: {},
      driver: {},
      selectedTruck: null,
      selectedDriver: null,
      selectedProvider: null,
      selectedSubProvider: null,
      subFreightProviders: [],
      applySubFreightProvider: false,
      warningText: '',
      estimatedTimeInSeconds: 0,
      consigneeInputText: '',
      consignorInputText: '',
      isDependent: true,
      partyValidate: false,
      isDefaultGeneralConditionApplied: false,
      isDefaultSpecialConditionApplied: false,
      base64Logo: null,
      didFetchedContracts: false,
      didFetchedOrders: false,
      contracts: [],
      orders: [],
      setFieldErrors: false,
      fileCountPickup: 0,
      fileCountDelivery: 0,
      pickupTerm: 'Pickup',
      deliveryTerm: 'Delivery',
      isCopyFromInload: false,
      isCopyFromOutload: false,
      freightPickupSiteIsSystemStorage: false,
      freightDeliverySiteIsSystemStorage: false,
      queryParamExists: false,
      selectedBaseEntity: null,
      selectedBaseEntityRaw: null,
      selectedConsignor: undefined,
      selectedConsignee: undefined,
      selectedConsignorId: null,
      selectedConsigneeId: null,
      isPickupSiteMandatoryBasedOnPricePoint: false,
      isDeliverySiteMandatoryBasedOnPricePoint: false,
      handlers: [],
      baseEntities: [],
      filteredEntities: [],
      consignors: [],
      consignees: [],
      consignorSites: [],
      consigneeSites: [],
      customerContacts: [],
      customerNgrs: [],
      freightPickupNgrs: [],
      freightDeliveryNgrs: [],
      providerEmployees: [],
      subFreightProviderEmployees: [],
      providerTrucks: [],
      pickupOwnerTrucks: [],
      deliveryOwnerTrucks: [],
      bhcSites: [],
      generalConditionsAll: [],
      specialConditionsAll: [],
      truckOptions: false,
      commoditySpecs: [],
      inloadGradeSpecs: [],
      outloadGradeSpecs: [],
      selectedGeneralConditionText: undefined,
      selectedSpecialConditionText: undefined,
      generalConditionsSelector: undefined,
      specialConditionsSelector: undefined,
      selectedConsignorSite: undefined,
      selectedConsigneeSite: undefined,
      isDeliveryNetWeightGreaterThanRemainingSpace: false,
      isPickupSiteGate: false,
      isDeliverySiteGate: false,
      isCustomerIsSeller: false,
      isCustomerIsBuyer: false,
      filterParty: undefined,
      filterCustomer: undefined,
      filterPartyObject: undefined,
      filterCustomerObject: undefined,
      isTruckSideDrawerOpen: false,
      filterProvider: undefined,
      commissionRate: (0).toFixed(2),
      inloadDeficit: 0,
      newTruckType: '',
      ngrSideDrawerIsOpen: false,
      ngrEntity: undefined,
      outloadStockOwner: undefined,
      inloadStockOwner: undefined,
      minimumTonnage: 0,
      amendParentToTonnage: undefined,
      showAmendTonnagePopup: false,
      openConsigneeFarmForm: false,
      openConsignorFarmForm: false,
      openConsignorStorageForm: false,
      openConsigneeStorageForm: false,
      pickupSMSettings: null,
      deliverySMSettings: null,
      pickupSiteTrailers: {
        trailer1: {
          applied: false,
          slotId: undefined,
        },
        trailer2: {
          applied: false,
          slotId: undefined,
        },
        trailer3: {
          applied: false,
          slotId: undefined,
        }
      },
      deliverySiteTrailers: {
        trailer1: {
          applied: false,
          slotId: undefined,
        },
        trailer2: {
          applied: false,
          slotId: undefined,
        },
        trailer3: {
          applied: false,
          slotId: undefined,
        }
      },
      pickupSlotTrailerErrors: null,
      deliverySlotTrailerErrors: null,
      pickupMassLimitPermit: {
        value: '',
        validators: [required()],
        errors: [],
      },
      pickupGHMS: {
        value: false,
        validators: [],
        errors: [],
      },
      deliveryMassLimitPermit: {
        value: '',
        validators: [required()],
        errors: [],
      },
      deliveryGHMS: {
        value: false,
        validators: [],
        errors: [],
      },
      deliveryCode: {
        value: undefined,
        validators: [],
        errors: [],
      },
      pickupCode: {
        value: undefined,
        validators: [],
        errors: [],
      },
      coil: {
        value: undefined,
        validators: [required()],
        errors: [],
      },
      impu: {
        value: undefined,
        validators: [required()],
        errors: [],
      },
      containerStats: undefined,
      freightPickupPermitChecked: false,
      freightPickupAccreditationNumberChecked: false,
      freightPickupLoadSharing: true,
      freightPickupRestrictedChecked: false,
      freightPickupTruckErrors: {},
      freightDeliveryPermitChecked: false,
      freightDeliveryAccreditationNumberChecked: false,
      freightDeliveryLoadSharing: true,
      freightDeliveryRestrictedChecked: false,
      freightDeliveryTruckErrors: {},
      fields: {
        externalReferenceNumber: {
          value: undefined,
          errors: [],
          validators: [],
        },
        driverId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        subFreightProviderId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        sellerDetails: [],
        buyerDetails: [],
        inloadSlotId: {
          value: query.get('inloadSlotId') || undefined,
          errors: [],
          validators: [],
        },
        outloadSlotId: {
          value: query.get('outloadSlotId') || undefined,
          errors: [],
          validators: [],
        },
        inloadSlotUpdatedAt: {
          value: undefined,
          errors: [],
          validators: [],
        },
        outloadSlotUpdatedAt: {
          value: undefined,
          errors: [],
          validators: [],
        },
        note: {
          description: '',
          attachments: [],
          companyId: this.props.currentUser.companyId,
          errors: []
        },
        manual_complete_notes: {
          value: '',
          validators: [],
          errors: [],
        },
        isSelf: {
          value: false,
          validators: [],
          errors: [],
        },
        status: {
          value: this.manualComplete ? DELIVERED_STATUS : (query.get('status') || undefined),
          validators: [required()],
          errors: [],
        },
        generalConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        specialConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        identifier: {
          value: '',
          errors: [],
          validators: [required()],
        },
        baseEntityId: {
          value: undefined,
          validators: [selected()],
          errors: [],
        },
        commodityId: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        varietyId: {
          value: undefined,
          validators: [],
          errors: [],
        },
        typeId: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        season: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        paymentTermId: {
          value: undefined,
          validators: [],
          errors: [],
        },
        assignToId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        providerId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        invoicing: {
          value: undefined,
          validators: [],
          errors: [],
        },
        rateFreightIn: {
          value: undefined,
          validators: [],
          errors: [],
        },
        rateOvers: {
          value: undefined,
          validators: [],
          errors: [],
        },
        rateFreightOut: {
          value: undefined,
          validators: [],
          errors: [],
        },
        plannedTruckId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        quantity: {
          value: undefined,
          validators: [],
          errors: [],
        },
        plannedTonnage: {
          value: undefined,
          validators: [required(), valueAbove(0)],
          errors: [],
        },
        plannedGradeId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        containerNumber: {
          value: undefined,
          validators: [],
          errors: [],
        },
        freightPickup: {
          date: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          searchOption: undefined,
          timeStart: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          orderNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          bookingNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          netWeight: {
            value: undefined,
            validators: [valueAbove(0)],
            errors: [],
          },
          docketImage: {
            value: [],
            validators: [],
            errors: [],
          },
          instructions: {
            value: undefined,
            validators: [],
            errors: [],
          },
          consignor: {
            handlerId: {
              value: undefined,
              validators: [required(), selected()],
              errors: [],
            },
            site: {
              locationType: {
                value: undefined,
                validators: [required()],
                errors: [],
              },
              locationId: {
                value: undefined,
                validators: [required(), selected()],
                errors: [],
              },
            },
          },
          selectedContainer: undefined,
          outload: {
            specs: {},
            docketNumber: {
              value: undefined,
              validators: [],
              errors: [],
            },
            comment: {
              value: undefined,
              validators: [],
              errors: [],
            },
            containerTareWeight: {
              value: undefined,
              validators: [valueAbove(0)],
              errors: [],
            },
            tareWeight: {
              value: undefined,
              validators: [valueAbove(0)],
              errors: [],
            },
            gradeId: {
              value: undefined,
              validators: [selected()],
              errors: [],
            },
            grossWeight: {
              value: undefined,
              validators: [valueAbove(0)],
              errors: [],
            },
            season: {
              value: null,
              validators: [],
              errors: [],
            },
            truckId: {
              value: undefined,
              validators: [selected(), truckRegoRegex()],
              errors: [],
            },
            truckOwnerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
            truckOwner: {
              value: undefined,
              validators: [],
              errors: [],
            },
            ngrId: {
              value: null,
              validators: [],
              errors: [],
            },
            varietyId: {
              value: null,
              validators: [],
              errors: [],
            },
            quantity: {
              value: undefined,
              validators: [],
              errors: [],
            },
            categoryId: {
              value: undefined,
              validators: [],
              errors: [],
            },
            steer_point_5: {
              value: false,
              validators: [],
              errors: [],
            },
            steer_1_point_1: {
              value: false,
              validators: [],
              errors: [],
            },
            noticeNumber: {
              value: undefined,
              validators: [],
              errors: [],
            },
            accreditationNumber: {
              value: undefined,
              validators: [],
              errors: [],
            },
            restricted: {
              value: undefined,
              validators: [],
              errors: [],
            },
            declaredMassLimit: {
              value: undefined,
              validators: [],
              errors: [],
            },
            permitNumber: {
              value: undefined,
              validators: [],
              errors: [],
            },
          },
        },
        freightDelivery: {
          date: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          searchOption: undefined,
          timeStart: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          orderNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          bookingNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          instructions: {
            value: undefined,
            validators: [],
            errors: [],
          },
          netWeight: {
            value: undefined,
            validators: [valueAbove(0)],
            errors: [],
          },
          docketImage: {
            value: [],
            validators: [],
            errors: [],
          },
          consignee: {
            handlerId: {
              value: undefined,
              validators: [required(), selected()],
              errors: [],
            },
            site: {
              locationType: {
                value: undefined,
                validators: [required()],
                errors: [],
              },
              locationId: {
                value: undefined,
                validators: [required(), selected()],
                errors: [],
              },
            },
          },
          inload: {
            specs: {},
            docketNumber: {
              value: undefined,
              validators: [],
              errors: [],
            },
            comment: {
              value: undefined,
              validators: [],
              errors: [],
            },
            gradeId: {
              value: undefined,
              validators: [selected()],
              errors: [],
            },
            containerTareWeight: {
              value: undefined,
              validators: [valueAbove(0)],
              errors: [],
            },
            tareWeight: {
              value: undefined,
              validators: [valueAbove(0)],
              errors: [],
            },
            grossWeight: {
              value: undefined,
              validators: [valueAbove(0)],
              errors: [],
            },
            truckId: {
              value: undefined,
              validators: [selected(), truckRegoRegex()],
              errors: [],
            },
            truckOwnerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
            truckOwner: {
              value: undefined,
              validators: [],
              errors: [],
            },
            season: {
              value: null,
              validators: [],
              errors: [],
            },
            ngrId: {
              value: null,
              validators: [],
              errors: [],
            },
            varietyId: {
              value: null,
              validators: [],
              errors: [],
            },
            quantity: {
              value: undefined,
              validators: [],
              errors: [],
            },
            categoryId: {
              value: undefined,
              validators: [],
              errors: [],
            },
            steer_point_5: {
              value: false,
              validators: [],
              errors: [],
            },
            steer_1_point_1: {
              value: false,
              validators: [],
              errors: [],
            },
            noticeNumber: {
              value: undefined,
              validators: [],
              errors: [],
            },
            accreditationNumber: {
              value: undefined,
              validators: [],
              errors: [],
            },
            restricted: {
              value: undefined,
              validators: [],
              errors: [],
            },
            declaredMassLimit: {
              value: undefined,
              validators: [],
              errors: [],
            },
            permitNumber: {
              value: undefined,
              validators: [],
              errors: [],
            },
          },
        },
        customer: {
          company: undefined,
          companyId: {
            value: undefined,
            validators: [required(), selected()],
            errors: [],
          },
          contactId: {
            value: undefined,
            validators: [required(), selected()],
            errors: [],
          },
          ngrId: {
            value: undefined,
            validators: [selected()],
            errors: [],
          },
          representedById: {
            value: undefined
          }
        },
      },
      errors: {
        party: false
      },
      isConsignorEditable: true,
      isConsigneeEditable: true,

      // Edit FM Fields
      isFetchingSelectedMovement: false,
      isPopulated: false,
      customerNgr: undefined,
      providerItems: [],
      customerItems: [],
      showEmailDialog: false,
      emailPopupParties: [],
      selectedTrucks: [],
      recommendedDeliverySlotText: [],
      openCompanySideDrawer: false,
      contractParty: '',
      isPickupSiteCompanyHandler: false,
      isDeliverySiteCompanyHandler: false,
      isCustomerOnlyMovement: false
    };

    // Edit FM Vars
    this.editMovementId = get(this.props, 'match.params.movement_id');
    this.editMovement = null;
    this.isEditForm = !!get(this.props, 'match.params.movement_id');
    this.isPopulatedAllFields = false;
    this.isFetchingFreightProviderEmployeesOrTrucks = false;
    this.isFetchingCustomerContactsAndNgrs = false;
    this.resetFreightPickupDetails = true;
    this.disabledTruckRegoField = null;
    this.fieldsDup = cloneDeep(this.state.fields);

    const { dispatch } = this.props;

    Promise.all([
      dispatch(getCompanyCompaniesMinimal(this.props.userCompanyId, { include_parent_company: true })),
      dispatch(getFixtures()),
      dispatch(getCompanyTrucks(this.props.currentUser.companyId, receiveUserTrucks)),
      dispatch(getGeneralConditions('freight')),
      dispatch(getSpecialConditions('freight')),
    ]).then(() => { });

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.handleProviderChange = this.handleProviderChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleSlotChange = this.handleSlotChange.bind(this);
    this.handleStatusFieldChange = this.handleStatusFieldChange.bind(this);
    this.handlePickupSpecsChange = this.handlePickupSpecsChange.bind(this);
    this.handleDeliverySpecsChange = this.handleDeliverySpecsChange.bind(this);
    this.handleCommodityChange = this.handleCommodityChange.bind(this);
    this.handleVarietyChange = this.handleVarietyChange.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleConsignorChange = this.handleConsignorChange.bind(this);
    this.handleConsigneeChange = this.handleConsigneeChange.bind(this);
    this.handleConditionSelector = this.handleConditionSelector.bind(this);
    this.appendGeneralConditions = this.appendGeneralConditions.bind(this);
    this.appendSpecialConditions = this.appendSpecialConditions.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.onPickupDocketImageUpload = this.onPickupDocketImageUpload.bind(this);
    this.onDeliveryDocketImageUpload = this.onDeliveryDocketImageUpload.bind(this);
    this.onPickupDocketImageRemove = this.onPickupDocketImageRemove.bind(this);
    this.onDeliveryDocketImageRemove = this.onDeliveryDocketImageRemove.bind(this);
    this.handlePickupTareWeightChange = this.handlePickupTareWeightChange.bind(this);
    this.handlePickupGrossWeightChange = this.handlePickupGrossWeightChange.bind(this);
    this.handleDeliveryTareWeightChange = this.handleDeliveryTareWeightChange.bind(this);
    this.handleDeliveryGrossWeightChange = this.handleDeliveryGrossWeightChange.bind(this);
    this.handleMovementTypeChange = this.handleMovementTypeChange.bind(this);
    this.handleBaseEntityChange = this.handleBaseEntityChange.bind(this);
    this.handleTonnageFieldChange = this.handleTonnageFieldChange.bind(this);
    this.validatePlannedTonnage = this.validatePlannedTonnage.bind(this);
    this.setSelectedBaseEntity = this.setSelectedBaseEntity.bind(this);
    this.getFreightOrder = this.getFreightOrder.bind(this);
    this.getCommodityContract = this.getCommodityContract.bind(this);
    this.setBaseEntityState = this.setBaseEntityState.bind(this);
    this.setDistance = this.setDistance.bind(this);
    this.setDistanceAndTimeState = this.setDistanceAndTimeState.bind(this);
    this.fetchDistance = this.fetchDistance.bind(this);
    this.getSitesValidity = this.getSitesValidity.bind(this);
    this.handleFreightRateChange = this.handleFreightRateChange.bind(this);
    this.handleRateBlur = this.handleRateBlur.bind(this);
    this.setRateError = this.setRateError.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.setValidatorsForIsSelf = this.setValidatorsForIsSelf.bind(this);
    this.getProviders = this.getProviders.bind(this);
    this.getProvidersForBroker = this.getProvidersForBroker.bind(this);
    this.getProvidersForNonBrokerUsers = this.getProvidersForNonBrokerUsers.bind(this);
    this.getNgrs = this.getNgrs.bind(this);
    this.resetDistanceAndTimeState = this.resetDistanceAndTimeState.bind(this);
    this.getCustomers = this.getCustomers.bind(this);
    this.handleInvoicingFieldChange = this.handleInvoicingFieldChange.bind(this);
    this.handleQuantityFieldChange = this.handleQuantityFieldChange.bind(this);
    this.checkValidityOfPickupSites = this.checkValidityOfPickupSites.bind(this);
    this.closeFreightProviderSideDrawer = this.closeFreightProviderSideDrawer.bind(this);
    this.closeCustomerSideDrawer = this.closeCustomerSideDrawer.bind(this);
    this.openCustomerSideDrawer = this.openCustomerSideDrawer.bind(this);
    this.openFreightProviderSideDrawer = this.openFreightProviderSideDrawer.bind(this);
    this.getCompanyEmployees = this.getCompanyEmployees.bind(this);
    this.getCustomerContacts = this.getCustomerContacts.bind(this);
    this.openGeneralConditionSideDrawer = this.openGeneralConditionSideDrawer.bind(this);
    this.closeSpecialConditionSideDrawer = this.closeSpecialConditionSideDrawer.bind(this);
    this.closeGeneralConditionSideDrawer = this.closeGeneralConditionSideDrawer.bind(this);
    this.openSpecialConditionSideDrawer = this.openSpecialConditionSideDrawer.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.setDeliveryEndDate = this.setDeliveryEndDate.bind(this);
    this.addNewTruck = this.addNewTruck.bind(this);
    this.closeTruckSideDrawer = this.closeTruckSideDrawer.bind(this);
    this.openNgrSideDrawer = this.openNgrSideDrawer.bind(this);
    this.closeNgrSideDrawer = this.closeNgrSideDrawer.bind(this);
    this.syncTrucks = this.syncTrucks.bind(this);
    this.openTruckSideDrawer = this.openTruckSideDrawer.bind(this);
    this.handleSideDrawer = this.handleSideDrawer.bind(this);
    this.addFreightProviderContactButton = this.addFreightProviderContactButton.bind(this);
    this.addCustomerContactButton = this.addCustomerContactButton.bind(this);
    this.addGeneralConditionButton = this.addGeneralConditionButton.bind(this);
    this.addSpecialConditionButton = this.addSpecialConditionButton.bind(this);
    this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
    this.setDefaultSpecialCondition = this.setDefaultSpecialCondition.bind(this);
    this.freightProviderSideDrawer = this.freightProviderSideDrawer.bind(this);
    this.getProviderTrucks = this.getProviderTrucks.bind(this);
    this.getCompanyForFetchingNgr = this.getCompanyForFetchingNgr.bind(this);
    this.getCustomerNgrs = this.getCustomerNgrs.bind(this);
    this.getFreightPickupNgrs = this.getFreightPickupNgrs.bind(this);
    this.getFreightDeliveryNgrs = this.getFreightDeliveryNgrs.bind(this);
    this.isSelectedBaseEntityAnOrder = this.isSelectedBaseEntityAnOrder.bind(this);
    this.isSellerToBuyer = this.isSellerToBuyer.bind(this);
    this.isPlannedTruckRegoFieldDisabled = this.isPlannedTruckRegoFieldDisabled.bind(this);
    this.getContacts = this.getContacts.bind(this);
    this.getPartyNgrs = this.getPartyNgrs.bind(this);
    this.handleAssignToIdFieldChange = this.handleAssignToIdFieldChange.bind(this);
    this.isPlannedCommodityFieldDisabled = this.isPlannedCommodityFieldDisabled.bind(this);
    this.isPlannedVarietyFieldDisabled = this.isPlannedVarietyFieldDisabled.bind(this);
    this.isPlannedGradeFieldDisabled = this.isPlannedGradeFieldDisabled.bind(this);
    this.isPlannedSeasonFieldDisabled = this.isPlannedSeasonFieldDisabled.bind(this);
    this.isEditingAdHocMovement = this.isEditingAdHocMovement.bind(this);
    this.isPlannedFieldsDisabledInEditForm = this.isPlannedFieldsDisabledInEditForm.bind(this);
    this.isEditingAdHocMovementWithoutPlannedSeason = this.isEditingAdHocMovementWithoutPlannedSeason.bind(this);
    this.setDateTimeBoundariesFromQueryParams = this.setDateTimeBoundariesFromQueryParams.bind(this);
    this.isIndependentCheck = this.isIndependentCheck.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.handleReferenceNumberBlur = this.handleReferenceNumberBlur.bind(this);
    this.updateParty = this.updateParty.bind(this);
    this.handleSubFreightProviderCheckboxChange = this.handleSubFreightProviderCheckboxChange.bind(this);
    this.handleSubFreightProviderChange = this.handleSubFreightProviderChange.bind(this);
    this.setCustomerContactAndNgr = this.setCustomerContactAndNgr.bind(this);
    this.handleCopyLoadDetails = this.handleCopyLoadDetails.bind(this);
    this.openCompanySideDrawer = this.openCompanySideDrawer.bind(this);
    this.closeCompanySideDraw = this.closeCompanySideDraw.bind(this);
    this.addNewCompany = this.addNewCompany.bind(this);
    this.handleEmptyData = this.handleEmptyData.bind(this);
    this.validateLoads = this.validateLoads.bind(this);
    this.isLoadDataEmpty = this.isLoadDataEmpty.bind(this);
    this.fillAndVerifyRequiredFields = this.fillAndVerifyRequiredFields.bind(this);
    this.fetchContainerStats = this.fetchContainerStats.bind(this);
    this.handlePickupContainerTareWeightChange = this.handlePickupContainerTareWeightChange.bind(this);
    this.handleDeliveryContainerTareWeightChange = this.handleDeliveryContainerTareWeightChange.bind(this);
    this.handleSearchOptionChange = this.handleSearchOptionChange.bind(this);
    this.handleFreightPickupTruckDetailsSelectValueChange = this.handleFreightPickupTruckDetailsSelectValueChange.bind(this);
    this.handleFreightDeliveryTruckDetailsSelectValueChange = this.handleFreightDeliveryTruckDetailsSelectValueChange.bind(this);
    this.modifySelectedBaseEntityItem = this.modifySelectedBaseEntityItem.bind(this);
    this.setFieldWarnings = this.setFieldWarnings.bind(this);
    this.getFieldWarnings = this.getFieldWarnings.bind(this);

    this.fieldsOrder = ["typeId", "identifier", "status", "baseEntityId", "externalReferenceNumber", 'sellerDetails.companyId', 'sellerDetails.contactId', 'buyerDetails.companyId', 'buyerDetails.contactId', "customer.companyId",
      "customer.contactId", "customer.ngrId", "providerId", "assignToId", "subFreightProviderId", "commodityId", "varietyId", "plannedGradeId", "season",
      "plannedTruckId", 'driverId', "quantity", "plannedTonnage", "containerNumber", "freightPickup.date", "freightPickup.timeStart", "freightPickup.consignor.handlerId",
      "freightPickup.consignor.site.locationId", "freightPickup.orderNumber", "freightPickup.bookingNumber",
      "freightPickup.outload.truckId", "freightPickup.outload.quantity", "freightPickup.outload.ngrId", "freightPickup.outload.tareWeight", "freightPickup.outload.grossWeight", "freightPickup.outload.gradeId",
      "freightPickup.outload.docketNumber",
      "freightPickup.outload.comment",
      "freightPickup.instructions", "freightDelivery.date", "freightDelivery.timeStart",
      "freightDelivery.consignee.handlerId", "freightDelivery.consignee.site.locationId", "freightDelivery.orderNumber",
      "freightDelivery.bookingNumber", "freightDelivery.inload.truckId", "freightDelivery.inload.quantity", "freightDelivery.inload.ngrId",
      "freightDelivery.inload.tareWeight", "freightDelivery.inload.grossWeight", "freightDelivery.inload.gradeId",
      "freightDelivery.inload.docketNumber",
      "freightDelivery.inload.comment",
      "freightDelivery.instructions", "invoicing",
      "paymentTermId", "rateFreightIn", "rateFreightOut", "rateOvers", "generalConditionsSelector",
      "specialConditionsSelector", 'note', 'currency'];

    this.formRef = React.createRef();
    this.fieldRef = {};
    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    this.isFetchingFreightOrder = false;
    this.isFetchingCommodityContract = false;
    this.disableForm = (this.isEditForm || this.isDuplicatingMovement) ? false : true;
  }

  setDistance(response) {
    if (get(response, 'status') === 'OK') {
      const distanceInKms = parseFloat(response['distance'] ? response['distance']['value'] / 1000 : 0).toFixed(2);
      this.setDistanceAndTimeState(distanceInKms, response['duration']['text'], response['duration']['value']);
    } else {
      this.resetDistanceAndTimeState();
    }
  }

  setDistanceAndTimeState(distance, duration, durationValue) {
    setTimeout(() => {
      this.setState({
        totalDistance: distance,
        totalDistanceLabel: distance + ' ' + getCountryConfig()?.distanceUnit,
        estimatedTime: duration ? duration : '0',
        estimatedTimeInSeconds: durationValue,
      });
    }, 300);
  }

  estimatedDeliverySlotStartTime() {
    const { estimatedTimeInSeconds, outloadSlots, fields } = this.state;
    if (estimatedTimeInSeconds && fields.outloadSlotId.value && !isEmpty(outloadSlots)) {
      const outloadSlot = find(outloadSlots, { 'id': fields.outloadSlotId.value });
      if (outloadSlot)
        return moment(outloadSlot.end).add(estimatedTimeInSeconds, 'seconds');
    }
  }

  async fetchSlotsForRecommendation(checkpointType, loadType, estimatedStartTime) {
    const siteId = get(this.state, `fields.${checkpointType}.handlerId.value`);
    let startDateTime = this.timeStampToDateTime(estimatedStartTime);
    let endDate = estimatedStartTime.endOf('day');
    let endDateTime = this.timeStampToDateTime(endDate);
    let recommendedSlot = '';

    if (every([siteId, endDateTime, endDateTime])) {
      const queryParams = { start: startDateTime, end: endDateTime, load_type: loadType, isRecommendation: true };
      if (this.state.fields.providerId.value)
        queryParams['freight_provider_id'] = this.state.fields.providerId.value;
      await APIService
        .company_sites(siteId)
        .appendToUrl('slots/planned/')
        .get(null, null, queryParams)
        .then(slot => {
          if (slot[0])
            recommendedSlot = slot[0].dateTimeDisplayText;
          else if (get(this.state, 'consigneeSites[0].farmId') == get(this.state.fields.freightDelivery, 'consignee.handlerId.value')) {
            if (this.state.consigneeSites[0].companyId == this.props.currentUser.companyId)
              recommendedSlot = 'There are no slots available. Please create planned slots.';
            else
              recommendedSlot = 'There are no slots available. Please contact site manager to open up slots.';
          }
        });
    }
    return recommendedSlot || '';
  }

  getRecommendedDeliverySlotText() {
    const estimatedStartTime = this.estimatedDeliverySlotStartTime();
    if (estimatedStartTime) {
      if (this.shouldFetchSlotsFor('Pickup') && this.shouldFetchSlotsFor('Delivery')) {
        let recommendedPromise = this.fetchSlotsForRecommendation('freightDelivery.consignee', 'inload', cloneDeep(estimatedStartTime));
        recommendedPromise.then(res => {
          this.setState({
            recommendedDeliverySlotText: [`(Estimated Arrival Time: ${estimatedStartTime.format(this.state.countryFormats.datetime)},`,
            `Recommended Slot: ${res})`]
          });
        }
        );
      }
      else
        this.setState({
          recommendedDeliverySlotText: [`Estimated Arrival Time: ${estimatedStartTime.format(this.state.countryFormats.datetime)}`]
        });
    }
    else {
      this.setState({ recommendedDeliverySlotText: [] });
    }
  }

  resetDistanceAndTimeState() {
    setTimeout(() => {
      this.setState({
        totalDistance: '',
        totalDistanceLabel: '',
        estimatedTime: '',
        estimatedTimeInSeconds: 0,
      });
    }, 300);
  }

  handleFarmSideDrawer = (key, bool = false) => {
    this.setState({
      [key]: bool
    });
  };

  handleFarmSubmit = (key, data) => {
    this.handleFarmSideDrawer(key, false);
    this.setState({ inputTextFarm: "" });
    data = pick(data, ['id', 'name', 'ld']);
    if (key === 'consignorSideDrawer') {
      this.handleConsignorChange(null, data);
    }
    else if (key === 'consigneeSideDrawer') {
      this.handleConsigneeChange(null, data);
    }
  };

  async fetchDistance() {
    let consignorAddress = this.state.consignorAddress;
    let consigneeAddress = this.state.consigneeAddress;
    if (!consignorAddress && this.state.selectedConsignor)
      consignorAddress = {value: {latitude: get(this.state.selectedConsignor, 'latitude'), longitude: get(this.state.selectedConsignor, 'longitude')}};
    if (!consigneeAddress && this.state.selectedConsignee)
      consigneeAddress = {value: {latitude: get(this.state.selectedConsignee, 'latitude'), longitude: get(this.state.selectedConsignee, 'longitude')}};
    if (consignorAddress && consigneeAddress) {
      const consignorAddressVal = consignorAddress.value;
      const consigneeAddressVal = consigneeAddress.value;
      if (consignorAddressVal && consigneeAddressVal) {
        const consignorLat = consignorAddressVal.latitude;
        const consigneeLat = consigneeAddressVal.latitude;
        const consignorLng = consignorAddressVal.longitude;
        const consigneeLng = consigneeAddressVal.longitude;
        if (consignorLat && consigneeLat && consignorLng && consigneeLng) {
          let data = {
            'origin_latitude': consignorLat,
            'origin_longitude': consignorLng,
            'destination_latitude': consigneeLat,
            'destination_longitude': consigneeLng
          }
          let response = await APIService.farms().appendToUrl('geo-distance/').post(data);
          this.setDistance(response);
        }
      }
    }
  }


  getDateForCategories() {
    let date = new Date(this.state.fields.freightPickup.date.value || this.state.fields.freightDelivery.date.value);
    if (date)
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  }

  fetchPermits() {
    let date = this.getDateForCategories();
    if (date)
      APIService.trucks().appendToUrl(`categories/permits/?for_date=${date}`).get().then(permits => this.setState({ permits: permits }));
  }

  fetchCategories() {
    let date = this.getDateForCategories();
    if (date)
      APIService.trucks().appendToUrl(`categories/?for_date=${date}`).get().then(categories => this.setState({ categories: categories }));
  }

  async getTruckOwnerTrucks(truckOwnerId, isPickup) {
    const trucks = truckOwnerId
      ? await APIService.companies(truckOwnerId)
        .trucks()
        .get(this.props.userToken)
      : [];
    const field = isPickup ? 'pickupOwnerTrucks' : 'deliveryOwnerTrucks';
    this.setState({ [field]: trucks })
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (has(params, 'commodityContractId') || has(params, 'orderId')) {
      this.props.dispatch(isLoading('noExistingComponent'));
    } else {
      this.props.dispatch(isLoading('nonExistentComponent'));
    }
    this.manualComplete = has(params, 'manualComplete');

    this.getBase64Logo();
    setTimeout(this.setDateTimeBoundariesFromQueryParams, 15000);
    if (isEmpty(this.props.commodities)) {
      this.props.dispatch(getCommoditiesWithVarietiesAndGrades());
    }
    if (this.manualComplete) this.props.dispatch(setHeaderText('Contract Completion Form'));
    else this.props.dispatch(setHeaderText('Freight Movements'));

    let breadcrumbs = [{ text: 'Movements', route: '/movements/freights' }];

    if (this.manualComplete) breadcrumbs = [{ text: 'Contract Completion Form' }];

    if (this.isEditForm) {
      breadcrumbs = breadcrumbs.concat([
        { text: get(this.editMovement, 'displayName'), route: `/freights/movements/${this.editMovementId}/details` },
        { text: 'Edit' },
      ]);
    } else if (!this.manualComplete) {
      breadcrumbs = breadcrumbs.concat([{ text: 'New' }]);
    }

    this.props.dispatch(setBreadcrumbs(breadcrumbs));
    setTimeout(() => {
      this.setState({ setFieldErrors: true });
    }, 1000);

    if (!this.isEditForm) {
      const newState = { ...this.state };
      newState.fields.identifier.value = this.identifierGenerated;
      this.setState(newState);
    }

    this.setOutloadStockOwner(get(params, 'orderId'), get(params, 'commodityContractId'))
    this.setInloadStockOwner(get(params, 'orderId'), get(params, 'commodityContractId'))
  }

  setOutloadStockOwner(orderId, commodityContractId) {
    let data = orderId ? { order_id: orderId } : commodityContractId ? { commodity_contract_id: commodityContractId } : null;
    if (data) {
      APIService.freights().contracts().appendToUrl('outloads/stock-owners/').post(data).then(response => {
        this.setState({ outloadStockOwner: response.outload })
      });
    }
  }

  setInloadStockOwner(orderId, commodityContractId) {
    let data = orderId ? { order_id: orderId } : commodityContractId ? { commodity_contract_id: commodityContractId } : null;
    if (data) {
      APIService.freights().contracts().appendToUrl('inloads/stock-owners/').post(data).then(response => {
        this.setState({ inloadStockOwner: response.inload })
      });
    }
  }

  setDateTimeBoundariesFromQueryParams() {
    const query = new URLSearchParams(this.props.location.search);
    const startDate = query.get('startDate');
    const startTime = query.get('startTime');
    const endDate = query.get('endDate');
    const endTime = query.get('endTime');
    if (startTime || startDate || endTime || endDate) {
      const newState = { ...this.state };
      newState.fields.freightPickup.date.value = startDate;
      newState.fields.freightPickup.timeStart.value = startTime;
      newState.fields.freightDelivery.date.value = endDate;
      newState.fields.freightDelivery.timeStart.value = endTime;
      this.setState(newState, () => {
        this.fetchSlotsIfNeeded('freightPickup.date');
        this.fetchSlotsIfNeeded('freightDelivery.date');
      });
    }
  }

  getBase64Logo() {
    const logoUrl = get(this.props, 'currentUser.myCompanyLogoUrl') || get(this.editMovement, 'createdBy.myCompanyLogoUrl');
    if (logoUrl && !this.state.base64Logo) {
      APIService.base64()
        .post({ url: logoUrl })
        .then(data => {
          this.setState({ base64Logo: data.base64 });
        });
    }
  }

  componentDidUpdate(prevProps) {
    const queryParams = new URLSearchParams(this.props.location.search)
    if(!this.orderId && !queryParams.get('commodityContractId') && !this.isEditForm && this.props.isLoading)
      this.props.dispatch(forceStopLoader())
    if (this.isEditForm && !isEmpty(this.editMovement) && !this.hasFetchedSlotsInEdit)
      this.fetchSlotsIfNeeded();
      if(!isEmpty(this.state.pickupSlotTrailerErrors) && (!isEmpty(compact(map(this.state.pickupSiteTrailers, 'slotId'))) || !this.checkTrailerSlotNeeded(true)))
        this.setState({pickupSlotTrailerErrors: null})
      if(!isEmpty(this.state.deliverySlotTrailerErrors) && (!isEmpty(compact(map(this.state.deliverySiteTrailers, 'slotId'))) || !this.checkTrailerSlotNeeded(false)))
        this.setState({deliverySlotTrailerErrors: null})
    if (this.isEditForm && !this.state.isFetchingSelectedMovement) {
      this.setState(
        {
          isFetchingSelectedMovement: true,
          isPopulated: false,
        },
        this.setMovement,
      );
    } else if(this.isDuplicatingMovement && !this.state.isFetchingSelectedMovement) {
      this.props.dispatch(isLoading(null, 100000000));
      this.setState(
        {
          isFetchingSelectedMovement: true,
          isPopulated: false,
        },
        this.setMovement,
      );
    } else {
      const newState = { ...this.state };
      if (!isEmpty(this.props.specialConditionsAll) && !this.selectedContract && !this.state.isDefaultSpecialConditionApplied && !this.isEditForm) {
        newState.fields.specialConditions.value = map(filter(this.props.specialConditionsAll, { default: true }), 'details').join('\n');
        newState.isDefaultSpecialConditionApplied = true;
      }
      const userCompany= this.props.currentUser.company
      let providers = [...this.props.providers, {id: userCompany.id, name: userCompany.name, typeId: userCompany.typeId}]
      if (!this.state.fields.providerId.value && !this.state.fields.freightPickup.searchOption && !this.state.fields.freightDelivery.searchOption && !this.state.isCopyFromInload && !this.state.isCopyFromOutload) {
        newState.fields.freightPickup.searchOption = SEARCH_BY_ALL_REGOS;
        newState.fields.freightDelivery.searchOption = SEARCH_BY_ALL_REGOS;
      }
      if (Boolean(this.state.fields.providerId.value) && this.state.fields.freightPickup.searchOption ==  SEARCH_BY_FREIGHT_PROVIDER_REGOS && !this.state.isCopyFromInload && !this.state.isCopyFromOutload) {
        newState.fields.freightPickup.outload.truckOwnerId.value = this.state.fields.providerId.value;
        newState.fields.freightPickup.outload.truckOwner.value = find(providers, {id:this.state.fields.providerId.value})
      }
      if(Boolean(this.state.fields.providerId.value) && this.state.fields.freightDelivery.searchOption ==  SEARCH_BY_FREIGHT_PROVIDER_REGOS && !this.state.isCopyFromInload && !this.state.isCopyFromOutload) {
        newState.fields.freightDelivery.inload.truckOwnerId.value = this.state.fields.providerId.value;
        newState.fields.freightDelivery.inload.truckOwner.value = find(providers, {id:this.state.fields.providerId.value});
      }
      if (!isEmpty(this.props.generalConditionsAll) && !this.selectedContract && !this.state.isDefaultGeneralConditionApplied && !this.isEditForm) {
        newState.fields.generalConditions.value = map(filter(this.props.generalConditionsAll, { default: true }), 'details').join('\n');
        newState.isDefaultGeneralConditionApplied = true;
      }
      if(this.props.pickupCreatedTruck && !find(this.state.selectedTrucks, {id: this.props.pickupCreatedTruck?.id})) {
        newState.selectedTrucks = [...this.state.selectedTrucks, this.props.pickupCreatedTruck]
        if((this.state.fields.freightPickup.searchOption == SEARCH_BY_ALL_REGOS && this.props.pickupCreatedTruck) || (this.props.pickupCreatedTruck?.companyId == newState.fields.freightPickup.outload.truckOwner.value?.id))
          this.handleSelectFieldChange(this.props.pickupCreatedTruck?.id, 'freightPickup.outload.truckId', this.props.pickupCreatedTruck)
        this.props.emptyCreatedTruck('pickup')
      }
      else if((this.props.deliveryCreatedTruck && !find(this.state.selectedTrucks, {id: this.props.deliveryCreatedTruck?.id}))) {
        newState.selectedTrucks = [...this.state.selectedTrucks, this.props.deliveryCreatedTruck]
        if((this.state.fields.freightDelivery.searchOption == SEARCH_BY_ALL_REGOS && this.props.deliveryCreatedTruck) || (this.props.deliveryCreatedTruck?.companyId == newState.fields.freightDelivery.inload.truckOwner.value?.id))
          this.handleSelectFieldChange(this.props.deliveryCreatedTruck?.id, 'freightDelivery.inload.truckId', this.props.deliveryCreatedTruck)
        this.props.emptyCreatedTruck('delivery')
      }

      if (this.state.queryParamExists === false) {
        const query = new URLSearchParams(this.props.location.search);
        if (includes(this.props.location.search, 'orderId=')) {
          const baseEntityId = parseInt(query.get('orderId'));
          if (!this.state.didFetchedOrders && (isEmpty(this.state.selectedBaseEntityId) || this.state.selectedBaseEntityId != baseEntityId)) {
            this.setState({ ...this.state, didFetchedOrders: true }, () => this.setSelectedBaseEntity());
          }
        } else if (includes(this.props.location.search, 'commodityContractId=')) {
          const baseEntityId = parseInt(query.get('commodityContractId'));
          if (!this.state.didFetchedContracts && (isEmpty(this.state.selectedBaseEntityId) || this.state.selectedBaseEntityId != baseEntityId)) {
            this.setState({ didFetchedContracts: true }, () => this.setSelectedBaseEntity());
          }
        }
      }
      if (this.state.queryParamExists === true) {
        this.disableForm = false;
      }
      if (!isEqual(this.props, prevProps)) {
        forEach(this.props.serverErrors, (value, key) => {
          if (key == 'freightPickup') {
            forEach(value, (pickup_error, pickup_key) => {
              if (has(newState.fields.freightPickup, pickup_key)) {
                newState.fields.freightPickup[pickup_key].errors = pickup_error;
              } else if (has(newState.fields.freightPickup.outload, pickup_key)) {
                newState.fields.freightPickup.outload[pickup_key].errors = pickup_error;
              }
            });
          } else if (key == 'freightDelivery') {
            forEach(value, (delivery_error, delivery_key) => {
              if (has(newState.fields.freightDelivery, delivery_key)) {
                newState.fields.freightDelivery[delivery_key].errors = delivery_error;
              } else if (has(newState.fields.freightDelivery.inload, delivery_key)) {
                newState.fields.freightDelivery.inload[delivery_key].errors = delivery_error;
              }
            });
          } else if (has(newState.fields, key)) {
            newState.fields[key].errors = value;
          }
        });
        this.setState(newState, ()=> {
          this.setGradeSpecs('freightDelivery.inload');
          this.setGradeSpecs('freightPickup.outload');
        });
      }

      if (
        !this.state.filterParty &&
        !this.state.filterProvider &&
        isEmpty(this.state.filteredEntities) &&
        !isEmpty(this.state.baseEntities) &&
        this.state.filteredEntities !== this.state.baseEntities
      ) {
        this.setState({ filteredEntities: this.state.baseEntities });
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(receiveGeneralConditions([]));
    this.props.dispatch(receiveSpecialConditions([]));
  }

  getLocalDateTime = obj => {
    let newObj = null;
    if (obj && obj.dateTime) {
      newObj = Object.assign({}, obj);
      const localDateTime = getDateTimeFromUTC(obj.dateTime);
      newObj.date = localDateTime.date;
      newObj.timeStart = localDateTime.time;
      return newObj;
    } else return obj;
  };


  isOutloadVarietyMandatory = () => {
    let result = false
    const { selectedConsignor } = this.state
    if(this.isDeliveredMovement() && !this.isEditForm && selectedConsignor?.isVarietyMandatory) {
      const { providers } = this.props
      const companyId = this.state.outloadStockOwner || this.state.fields.customer.companyId.value;
      const company = find(providers, {id: companyId})
      if(company?.id) {
        const isMandatoryForGrowers = selectedConsignor.userTypeForVarietyMandatory.includes('growers')
        const isMandatoryForNonGrowers = selectedConsignor.userTypeForVarietyMandatory.includes('non_growers')
        let isVarietyMandatoryForLoad = ['outload', 'inload_and_outload'].some(type => selectedConsignor.loadTypeForVarietyMandatory.includes(type))
        result = isMandatoryForGrowers && isMandatoryForNonGrowers && isVarietyMandatoryForLoad
        if(!result && isVarietyMandatoryForLoad) {
          const isGrower = isCompanyGrower(company)
          result = isMandatoryForGrowers ? isGrower : !isGrower
        }
      }
    }
    return result
  }

  isInloadVarietyMandatory = () => {
    let result = false
    const { selectedConsignee } = this.state
    if(this.isDeliveredMovement() && !this.isEditForm && selectedConsignee?.isVarietyMandatory) {
      const { providers } = this.props
      const companyId = this.state.inloadStockOwner || this.state.fields.customer.companyId.value;
      const company = find(providers, {id: companyId})
      if(company?.id) {
        const isMandatoryForGrowers = selectedConsignee.userTypeForVarietyMandatory.includes('growers')
        const isMandatoryForNonGrowers = selectedConsignee.userTypeForVarietyMandatory.includes('non_growers')
        let isVarietyMandatoryForLoad = ['inload', 'inload_and_outload'].some(type => selectedConsignee.loadTypeForVarietyMandatory.includes(type))
        result = isMandatoryForGrowers && isMandatoryForNonGrowers && isVarietyMandatoryForLoad
        if(!result && isVarietyMandatoryForLoad) {
          const isGrower = isCompanyGrower(company)
          result = isMandatoryForGrowers ? isGrower : !isGrower
        }
      }
    }
    return result
  }

  setLoadVarietyMandatory = () => {
    const isOutloadVarietyMandatory = this.isOutloadVarietyMandatory()
    const isInloadVarietyMandatory = this.isInloadVarietyMandatory()
    const newState = {...this.state}
    let setState = false
    if(isOutloadVarietyMandatory && !this.state.fields.freightPickup.outload.varietyId.length) {
      newState.fields.freightPickup.outload.varietyId.validators = [required()]
      setState = true
    } else if (!isOutloadVarietyMandatory && this.state.fields.freightPickup.outload.varietyId.length) {
      newState.fields.freightPickup.outload.varietyId.validators = []
      newState.fields.freightPickup.outload.varietyId.errors = []
      setState = true
    }
    if(isInloadVarietyMandatory && !this.state.fields.freightDelivery.inload.varietyId.length) {
      newState.fields.freightDelivery.inload.varietyId.validators = [required()]
      setState = true
    } else if (!isInloadVarietyMandatory && this.state.fields.freightDelivery.inload.varietyId.length) {
      newState.fields.freightDelivery.inload.varietyId.validators = []
      newState.fields.freightDelivery.inload.varietyId.errors = []
      setState = true
    }
    if(setState)
      setTimeout(() => this.setState(newState), 100)
  }

  setMovement = async () => {
    const movementId = this.isDuplicatingMovement ? this.copyFromMovementId : this.editMovementId;
    await APIService.freights().contracts(movementId).get(this.props.userToken, {'REFERER-UNIT' : getCountryMovementDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true}).then(response => {
      this.editMovement = response;
      if (has(this.editMovement, 'order')) {
        let order = this.editMovement.order;
        let customer = get(this.editMovement, 'customer');

        if (customer && isEqual(this.props.userCompanyId, customer.companyId)) {
          if (order && isEqual(order.typeId, REQUEST_ORDER_TYPE.PICKUP_ORDER)) {
            this.setState({
              isPickupSiteCompanyHandler: true,
              isDeliverySiteCompanyHandler: false
            });
          } else if (order && isEqual(order.typeId, REQUEST_ORDER_TYPE.DELIVERY_ORDER)) {
            this.setState({
              isPickupSiteCompanyHandler: false,
              isDeliverySiteCompanyHandler: true
            });
          }
        }
      }
      if (isNil(this.editMovement.order) && isEqual(this.editMovement.typeId, 1)) {
        this.setState({ isCustomerOnlyMovement: true })
      }

      if (has(this.editMovement, 'freightPickup')) {
        this.editMovement.freightPickup = this.getLocalDateTime(this.editMovement.freightPickup);
      }
      if (has(this.editMovement, 'freightDelivery')) {
        this.editMovement.freightDelivery = this.getLocalDateTime(this.editMovement.freightDelivery);
      }
      if (has(this.editMovement, 'note')) {
        const { fields } = this.state;
        set(fields.note, 'description', get(this.editMovement, 'note.description'));
        this.setState({ fields: fields });
      }
      this.setState({ isSelectedMovementFetched: true }, () => {
        let breadcrumbs = [
          { text: 'Movements', route: '/movements/freights' },
          { text: get(this.editMovement, 'identifier'), route: `/freights/movements/${this.editMovementId}/details` }, { text: 'Edit' }
        ];
        if(!this.isDuplicatingMovement)
          this.props.dispatch(setBreadcrumbs(breadcrumbs));
        this.populateRequiredFields();
      });
    });
  };

  isValueChanged = (field) => {
    if (field === 'freightPickup.outload.quantity') {
      return get(this.state.fields, `${field}.value`) !== get(this.editMovement, 'freightPickup.load.0.quantity');
    }
    if (field === 'freightDelivery.inload.quantity') {
      return get(this.state.fields, `${field}.value`) !== get(this.editMovement, 'freightDelivery.load.0.quantity');
    }
    if (field === 'outloadSlotId') {
      return get(this.state.fields, `${field}.value`) !== get(this.editMovement, 'outloadSlotId');
    }
    if (field === 'inloadSlotId') {
      return get(this.state.fields, `${field}.value`) !== get(this.editMovement, 'inloadSlotId');
    }
    else {
      return get(this.state.fields, `${field}.value`) !== get(this.editMovement, field);
    }
  };

  populateRequiredFields = async () => {
    const isCustomerSystem = SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'customer.companyId'))
    const selectedCustomer = !isCustomerSystem ? get(this.editMovement, 'customer.company') : null;
    const getCustomerContacts = this.getContacts(selectedCustomer, get(this.editMovement, 'customer.representedById'));
    const getCustomerNgrs = !isCustomerSystem ? this.getPartyNgrs([get(selectedCustomer, 'id')]) : [get(this.editMovement, 'customer.ngr')];
    const provider = get(this.editMovement, 'provider');
    const providerId = get(provider, 'id');
    const isProviderSystem = SYSTEM_COMPANY_IDS.includes(providerId)
    const getProviderContacts =
      providerId && !isProviderSystem
        ? APIService.companies(provider.id)
          .employees()
          .get(this.props.userToken)
        : [];
    const companyTrucksService = APIService.companies(provider?.id).trucks();
    if (get(this.editMovement, 'plannedTruckId'))
      companyTrucksService.appendToUrl(`?include_truck_id=${get(this.editMovement, 'plannedTruckId')}`);
    const getProviderTrucks =
      providerId && !isProviderSystem
        ? companyTrucksService.get(this.props.userToken)
        : [];
    this.isFetchingFreightProviderEmployeesOrTrucks = true;
    this.isFetchingCustomerContactsAndNgrs = true;
    this.resetFreightPickupDetails = false;
    const getPickupSMSettings = get(this.editMovement, 'freightPickup.consignor.handler.companyId') ? APIService.companies(get(this.editMovement, 'freightPickup.consignor.handler.companyId'))
    .appendToUrl('site-management/settings/')
    .get()
    : [];
    const getDeliverySMSettings = get(this.editMovement, 'freightDelivery.consignee.handler.companyId') ? APIService.companies(get(this.editMovement, 'freightDelivery.consignee.handler.companyId'))
    .appendToUrl('site-management/settings/')
    .get()
    : [];

    await Promise.all([getCustomerContacts, getCustomerNgrs, getProviderContacts, getProviderTrucks, getPickupSMSettings, getDeliverySMSettings]).then(responses => {
      this.setState(
        {
          customerContacts: responses[0],
          customerNgrs: responses[1],
          providerEmployees: responses[2],
          providerTrucks: responses[3],
          pickupOwnerTrucks: responses[3],
          deliveryOwnerTrucks: responses[3],
          pickupSMSettings: responses[4],
          deliverySMSettings: responses[5],
        },
        async () => {
          const newState = { ...this.state };
          if (provider && !isProviderSystem) {
            if(!this.isDuplicatingMovement)
              set(newState, 'providerItems', [provider]);
            set(newState.fields, 'providerId.value', provider.id);
          }
          if (selectedCustomer) {
            set(newState, 'customerItems', [selectedCustomer]);
          }
          if (!this.shouldShowCheckpointDetails()) {
            newState.fields.plannedTruckId.validators = [];
            newState.fields.plannedTonnage.validators = [];
          }

          forEach([
            'typeId', 'commodityContractId', 'customer.companyId', 'rateOvers', 'status',
            'commodityId', 'varietyId', 'plannedGradeId', 'season', 'identifier', 'plannedTonnage', 'invoicing',
            'paymentTermId', 'rateFreightIn', 'rateFreightOut', 'freightPickup.date', 'freightDelivery.date',
            'freightPickup.timeStart', 'freightDelivery.timeStart', 'freightPickup.orderNumber', 'plannedTruckId',
            'freightDelivery.orderNumber', 'generalConditions', 'specialConditions', 'freightPickup.bookingNumber',
            'freightDelivery.bookingNumber', 'freightPickup.instructions', 'freightDelivery.instructions',
          ], key => set(newState.fields, `${key}.value`, get(this.editMovement, key)));
          if (get(this.editMovement, 'status') === 'planned' && this.hasQuantityBasedCommodity(get(this.editMovement, 'commodityId')))
            newState.fields.quantity.validators = [required()];

          const isDeliveredMovement = (
            get(this.editMovement, 'status') == DELIVERED_STATUS ||
            get(this.editMovement,'statusHistory', []).includes(DELIVERED_STATUS)
          )

          if(this.isDuplicatingMovement){
            if(get(this.editMovement, 'status') != 'void')
              newState.fields.identifier.value = generateUpdatedIdentifier(this.editMovement.identifier)

            if(!isDeliveredMovement)
              newState.fields.status.value = PLANNED_STATUS
            else if(isDeliveredMovement)
              newState.fields.status.value = DELIVERED_STATUS

            if(isDeliveredMovement){
              newState.fields.freightPickup.outload.truckId.value = get(this.editMovement, 'freightPickup.load[0].truckId');
              newState.fields.freightPickup.outload.tareWeight.value = get(this.editMovement, 'freightPickup.load[0].tareWeight');
              newState.fields.freightPickup.outload.grossWeight.value = get(this.editMovement, 'freightPickup.load[0].grossWeight');
              newState.fields.freightPickup.outload.gradeId.value = get(this.editMovement, 'freightPickup.load[0].gradeId')
              newState.fields.freightPickup.outload.varietyId.value = get(this.editMovement, 'freightPickup.load[0].varietyId')
              newState.fields.freightPickup.netWeight.value = get(this.editMovement, 'freightPickup.load[0].estimatedNetWeight');
              newState.fields.freightPickup.outload.ngrId.value = get(this.editMovement, 'freightPickup.load[0].ngrId');

              newState.fields.freightDelivery.inload.truckId.value = get(this.editMovement, 'freightDelivery.load[0].truckId');
              newState.fields.freightDelivery.inload.tareWeight.value = get(this.editMovement, 'freightDelivery.load[0].tareWeight');
              newState.fields.freightDelivery.inload.grossWeight.value = get(this.editMovement, 'freightDelivery.load[0].grossWeight');
              newState.fields.freightDelivery.inload.gradeId.value = get(this.editMovement, 'freightDelivery.load[0].gradeId')
              newState.fields.freightDelivery.inload.varietyId.value = get(this.editMovement, 'freightDelivery.load[0].gradeId')
              newState.fields.freightDelivery.netWeight.value = get(this.editMovement, 'freightDelivery.load[0].estimatedNetWeight');
              newState.fields.freightDelivery.inload.ngrId.value = get(this.editMovement, 'freightDelivery.load[0].ngrId');
            }

            newState.fields.baseEntityId.value = get(this.editMovement, 'order.id') || get(this.editMovement, 'commodityContract.id');
            if (get(this.editMovement, 'typeId') === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER) {
              await this.getContractsList(get(this.editMovement, 'commodityContractId'));
              await this.getOrdersList(false, false, get(this.editMovement, 'orderId'));
              newState.baseEntities = this.state.contracts.concat(this.state.orders);
              newState.filteredEntities = newState.baseEntities;
            }
            else{
              await this.getOrdersList(false, false, get(this.editMovement, 'orderId'));
              newState.baseEntities = this.state.orders;
              newState.filteredEntities = newState.baseEntities;
            }
          }

          if (
            get(this.editMovement, 'status') === 'delivered' ||
            (this.isDuplicatingMovement && isDeliveredMovement)
          ) {
            newState.fields.plannedGradeId.value = get(this.editMovement, 'freightPickup.load.0.gradeId') || get(this.editMovement, 'freightDelivery.load.0.gradeId') || get(this.editMovement, 'plannedGradeId');
            newState.fields.freightPickup.date.validators = [];
            newState.fields.freightPickup.timeStart.validators = [];
            newState.fields.freightPickup.consignor.handlerId.validators = [];
            newState.fields.freightPickup.consignor.site.locationType.validators = [];
            newState.fields.freightPickup.consignor.site.locationId.validators = [];
          }
          if ((this.isEditForm || this.isDuplicatingMovement) && !this.shouldShowCheckpointDetails()) {
            newState.fields.freightPickup.date.validators = [];
            newState.fields.freightPickup.timeStart.validators = [];
            newState.fields.freightPickup.consignor.handlerId.validators = [];
            newState.fields.freightPickup.consignor.site.locationType.validators = [];
            newState.fields.freightPickup.consignor.site.locationId.validators = [];
            newState.fields.freightDelivery.date.validators = [];
            newState.fields.freightDelivery.timeStart.validators = [];
            newState.fields.freightDelivery.consignee.handlerId.validators = [];
            newState.fields.freightDelivery.consignee.site.locationType.validators = [];
            newState.fields.freightDelivery.consignee.site.locationId.validators = [];
          }

          set(newState.fields, 'customer.company', selectedCustomer);
          set(newState, 'customerContact', get(this.editMovement, 'customer.contact'));
          set(newState, 'customerNgr', get(this.editMovement, 'customer.ngr'));
          set(newState, 'currency', get(this.editMovement, 'currency'));
          const baseEntity = get(this.editMovement, 'order') || get(this.editMovement, 'commodityContract');
          if (baseEntity) {
            newState.selectedBaseEntity = baseEntity;
            newState.selectedBaseEntityRaw = baseEntity;
            newState.selectedBaseEntityId = baseEntity.id;
            newState.selectedBaseEntityType = baseEntity.entity === 'freightorder' ? 'order' : 'commodityContract';
            if (baseEntity.entity === 'freightorder') {
              const consignorSite = get(baseEntity, 'freightPickup.consignor.sites[0].location.name');
              newState.selectedBaseEntityRaw.consignorHandlerName = get(baseEntity, 'freightPickup.consignor.handler.displayName');
              newState.selectedBaseEntityRaw.consignorLocationName = consignorSite;
              newState.selectedBaseEntityRaw.consignorSites = [consignorSite];

              const consigneeSite = get(baseEntity, 'freightDelivery.consignee.sites[0].location.name');
              newState.selectedBaseEntityRaw.consigneeHandlerName = get(baseEntity, 'freightDelivery.consignee.handler.displayName');
              newState.selectedBaseEntityRaw.consigneeLocationName = consigneeSite;
              newState.selectedBaseEntityRaw.consigneeSites = [consigneeSite];
              newState.selectedBaseEntity.pickupOrderNumber = get(baseEntity, 'freightPickup.orderNumber');
              newState.selectedBaseEntity.deliveryOrderNumber = get(baseEntity, 'freightDelivery.orderNumber');

              if(this.editMovement?.outloadTrailerSlotIds){
                map(this.editMovement?.outloadTrailerSlotIds, (slotId, index) => {
                  newState.pickupSiteTrailers[`trailer${index+1}`].applied = true;
                  newState.pickupSiteTrailers[`trailer${index+1}`].slotId = slotId.toString();
                })
              }
              if(this.editMovement?.inloadTrailerSlotIds){
                map(this.editMovement?.inloadTrailerSlotIds, (slotId, index) => {
                  newState.deliverySiteTrailers[`trailer${index+1}`].applied = true;
                  newState.deliverySiteTrailers[`trailer${index+1}`].slotId = slotId.toString();
                })
              }
            }

            if (get(this.editMovement, 'commodityContractId')) {
              const { commodityContract } = this.editMovement;
              newState.buyer = commodityContract.buyer;
              newState.seller = commodityContract.seller;
            }

            this.setConsignorConsigneeValidators(newState);
          }

          const movementConsignor = get(this.editMovement, 'freightPickup.consignor.handler');
          const movementConsignee = get(this.editMovement, 'freightDelivery.consignee.handler');

          if (!isEmpty(movementConsignor)) {
            newState.selectedConsignor = { id: movementConsignor.id, name: movementConsignor.displayName };
            newState.selectedConsignorId = movementConsignor.id;
            movementConsignor.address = get(movementConsignor, 'address.address');
            newState.fields.freightPickup.consignor.handlerId.value = get(movementConsignor, 'id');
          }

          if (!isEmpty(movementConsignee)) {
            newState.selectedConsignee = { id: movementConsignee.id, name: movementConsignee.displayName };
            newState.selectedConsigneeId = movementConsignee.id;
            movementConsignee.address = get(movementConsignee, 'address.address');
            newState.fields.freightDelivery.consignee.handlerId.value = get(movementConsignee, 'id');
          }

          this.setState(newState, () => {
            if(this.isDuplicatingMovement && get(this.editMovement, 'typeId') === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER){
              this.getFreightPickupNgrs();
              this.getFreightDeliveryNgrs();
            }

            this.isDuplicatingMovement = false;
            this.calculateCommissionRate();
            let handlerIds = compact([movementConsignor?.id, movementConsignee?.id])
            if(handlerIds.length > 0) {
              handlerIds = handlerIds.join(',')
              APIService.contracts().handlers().get(null, null, {handler_ids: handlerIds}).then(res => {
                let items = res?.handlers || []
                let consignors = find(res?.handlers, handler => handler.id == movementConsignor?.id)
                let consignees = find(res?.handlers, handler => handler.id == movementConsignee?.id)
                this.setState({handlers: uniqBy([...this.state.handlers, ...items], 'id'), consignors: [consignors], consignees: [consignees], selectedConsignor: consignors, selectedConsignee: consignees})
              })
            }
            this.setHandlerSites();
            this.setGradeSpecs('freightDelivery.inload');
            this.setGradeSpecs('freightPickup.outload');
          });
        });
      this.isPopulatedAllFields = true;
      const assignToId = get(this.editMovement, 'assignToId');
      if (assignToId) {
        this.handleAssignToIdFieldChange(assignToId, 'assignToId');
      }
    });
  };

  setSlotErrorsIfRequired() {
    const newState = { ...this.state };
    if (this.isBaseEntityNotOrderAndSlotOrderBookingOn('Pickup'))
      newState.fields.outloadSlotId.errors = ["Movement at this site can only be created against an order."];
    if (this.isBaseEntityNotOrderAndSlotOrderBookingOn('Delivery'))
      newState.fields.inloadSlotId.errors = ["Movement at this site can only be created against an order."];
    this.setState(newState);
  }

  populateDependentFields = (consignor, consignee) => {
    const newState = { ...this.state };

    let dependentFields = ['customer.contactId', 'customer.ngrId'];
    forEach(dependentFields, key => set(newState.fields, `${key}.value`, get(this.editMovement, key)));

    if (!isEmpty(consignor)) {
      const handler = get(this.editMovement, 'freightPickup.consignor');
      const site = get(handler, 'sites[0]');
      set(newState.fields, 'freightPickup.consignor.handlerId.value', get(handler, 'handlerId'));
      set(newState.fields, 'freightPickup.consignor.site.address', get(site, 'location.address'));
      newState.consignorAddress = { value: get(site, 'location.address') };
      set(newState.fields, 'freightPickup.consignor.site.locationType.value', get(site, 'locationContentType'));
      set(newState.fields, 'freightPickup.consignor.site.locationId.value', get(site, 'locationId'));
    }

    if (!isEmpty(consignee)) {
      const handler = get(this.editMovement, 'freightDelivery.consignee');
      const site = get(handler, 'sites[0]');
      set(newState.fields, 'freightDelivery.consignee.handlerId.value', get(handler, 'handlerId'));
      set(newState.fields, 'freightDelivery.consignee.site.address', get(site, 'location.address'));
      set(newState.fields, 'freightDelivery.consignee.site.locationType.value', get(site, 'locationContentType'));
      set(newState.fields, 'freightDelivery.consignee.site.locationId.value', get(site, 'locationId'));
      newState.consigneeAddress = { value: get(site, 'location.address') };
      newState.selectedConsigneeSite = find(this.state.consigneeSites, {
        id: get(site, 'locationId'),
        entity: get(site, 'locationContentType'),
      });
    }

    newState.isPopulated = true;
    if (this.isEditingAdHocMovementWithoutPlannedSeason()) {
      newState.fields.season.errors = [];
    }
    this.freightProviderFieldCheck(newState);
    this.setState(newState, () => {
      if (this.state.selectedBaseEntityType === 'order') {
        this.fetchDistance();
      }
      this.fetchSlotsIfNeeded();
      if (!this.shouldFetchSlotsFor('Pickup') && !this.shouldFetchSlotsFor('Delivery'))
        this.props.dispatch(forceStopLoader());

      this.isFetchingFreightProviderEmployeesOrTrucks = false;
      this.isFetchingCustomerContactsAndNgrs = false;
      setTimeout(() => {
        this.resetFreightPickupDetails = true;
      }, 1000);
    });
  };

  setHandlerSites = async () => {
    const selectedConsignor = this.state.selectedConsignor || get(this.editMovement, 'freightPickup.consignor.handler');
    if (!isEmpty(selectedConsignor)) {
      await Promise.all([this.getSites(selectedConsignor.id, 'farm', 'consignor')]);
    }

    const selectedConsignee = this.state.selectedConsignee || get(this.editMovement, 'freightDelivery.consignee.handler');
    if (!isEmpty(selectedConsignee)) {
      await Promise.all([this.getSites(selectedConsignee.id, 'farm', 'consignee')]);
    }

    this.populateDependentFields(selectedConsignor, selectedConsignee);
  };

  isNoteChanged = () => {
    return (get(this.state.fields, `note.description`) != get(this.editOrder, 'note.description') ||
      isEqual(get(this.state.fields, `note.attachments`), get(this.editMovement, 'note.attachments')));
  };


  getEditData = () => {
    let data = {};
    forEach([
      'assignToId', 'plannedTonnage', 'invoicing', 'paymentTermId', 'rateFreightIn', 'rateFreightOut', 'rateOvers',
      'freightPickup.date', 'freightDelivery.date', 'freightPickup.timeStart', 'freightDelivery.timeStart',
      'freightPickup.orderNumber', 'freightDelivery.orderNumber', 'freightPickup.bookingNumber', 'freightDelivery.bookingNumber',
      'freightPickup.instructions', 'freightDelivery.instructions', 'generalConditions', 'specialConditions',
      'customer.contactId', 'customer.ngrId', 'customer.companyId',
      'plannedTruckId', 'commodityId', 'varietyId', 'plannedGradeId', 'season', 'providerId', 'quantity', 'freightPickup.outload.quantity', 'freightDelivery.inload.quantity',
      'inloadSlotId', 'outloadSlotId'
    ], field => {
      if (this.isValueChanged(field)) {
        set(data, field, get(this.state.fields, `${field}.value`));
      }
    });

    if (!this.isFreightProviderCreator() && has(data, 'customer.companyId')) {
      set(data, 'customer.representedById', this.props.currentUser.companyId);
    }

    forEach(data, (value, key) => {
      if (includes(['plannedTonnage', 'rateFreightIn', 'rateFreightOut', 'rateOvers'], key) && isEmpty(value)) {
        set(data, key, null);
      }
    });

    if (this.isHandlerChanged('freightPickup', 'consignor')) {
      set(data, 'freightPickup.consignor.handlerId', get(this.state.selectedConsignor, 'id'));
      set(data, 'freightPickup.consignor.sites[0].locationId', get(this.state.selectedConsignorSite, 'id'));
      set(data, 'freightPickup.consignor.sites[0].locationType', get(this.state.selectedConsignorSite, 'entity'));
    }

    if (this.isHandlerChanged('freightDelivery', 'consignee')) {
      set(data, 'freightDelivery.consignee.handlerId', get(this.state.selectedConsignee, 'id'));
      set(data, 'freightDelivery.consignee.sites[0].locationId', get(this.state.selectedConsigneeSite, 'id'));
      set(data, 'freightDelivery.consignee.sites[0].locationType', get(this.state.selectedConsigneeSite, 'entity'));
      set(data, 'estimatedDistance', this.state.totalDistance === '' ? null : this.state.totalDistance);
      set(data, 'estimatedTime', this.state.estimatedTime === '' ? null : this.state.estimatedTime);
    }
    if (this.isNoteChanged()) {
      let note = get(this.state.fields, 'note');
      note = omit(note, ['errors']);
      data.note = note;
    }
    if(this.editMovement?.outloadTrailerSlotIds && !isEmpty(compact(map(this.state.pickupSiteTrailers, 'slotId'))))
      data.outloadTrailerSlots= {trailer1: Number(this.state.pickupSiteTrailers.trailer1.slotId), trailer2: Number(this.state.pickupSiteTrailers.trailer2.slotId), trailer3: Number(this.state.pickupSiteTrailers.trailer3.slotId)}

    if (this.editMovement?.inloadTrailerSlotIds && !isEmpty(compact(map(this.state.deliverySiteTrailers, 'slotId'))))
      data.inloadTrailerSlots = {trailer1: Number(this.state.deliverySiteTrailers.trailer1.slotId), trailer2: Number(this.state.deliverySiteTrailers.trailer2.slotId), trailer3: Number(this.state.deliverySiteTrailers.trailer3.slotId)}

    if (this.isEditForm && this.editMovement && get(this.editMovement, 'currency') != this.state.currency)
      set(data, 'currency', this.state.currency);

    data = this.setUpMovementDatetimeToUTC(data);
    if (!this.shouldShowCheckpointDetails()) {
      delete data.freightDelivery;
      delete data.freightPickup;
    }
    if (this.isEditForm && this.editMovement && this.shouldFetchSlotsFor('Pickup') && this.shouldFetchSlotsFor('Delivery'))
      delete data.plannedTruckId;
    data.feature = "Movement Form"
    return data;
  };

  isHandlerChanged = (checkpoint, handler) => {
    return (
      this.isValueChanged(`${checkpoint}.${handler}.handlerId`) ||
      get(this.state.fields, `${checkpoint}.${handler}.site.locationType.value`) !==
      get(this.editMovement, `${checkpoint}.${handler}.sites[0].locationContentType`) ||
      get(this.state.fields, `${checkpoint}.${handler}.site.locationId.value`) !==
      get(this.editMovement, `${checkpoint}.${handler}.sites[0].locationId`)
    );
  };

  onPickupDocketImageRemove(fileSrc) {
    if (fileSrc) {
      const newState = { ...this.state };
      newState.fields.freightPickup.docketImage.value = filter(newState.fields.freightPickup.docketImage.value, file => {
        return file.base64 !== fileSrc;
      });
      newState.fileCountPickup = newState.fields.freightPickup.docketImage.value.length;
      this.setState(newState);
    }
  }

  onDeliveryDocketImageRemove(fileSrc) {
    if (fileSrc) {
      const newState = { ...this.state };
      newState.fields.freightDelivery.docketImage.value = filter(newState.fields.freightDelivery.docketImage.value, file => {
        return file.base64 !== fileSrc;
      });
      newState.fileCountDelivery = newState.fields.freightDelivery.docketImage.value.length;
      this.setState(newState);
    }
  }

  onPickupDocketImageUpload(fileState) {
    const newState = { ...this.state };
    if (isEmpty(fileState.errors)) {
      newState.fields.freightPickup.docketImage.value.push(fileState.file);
      var fileCount = newState.fields.freightPickup.docketImage.value.length;
      newState.fileCountPickup = fileCount;
    } else {
      newState.fields.freightPickup.docketImage.errors = fileState.errors;
    }
    this.setState(newState);
  }

  onDeliveryDocketImageUpload(fileState) {
    const newState = { ...this.state };
    if (isEmpty(fileState.errors)) {
      newState.fields.freightDelivery.docketImage.value.push(fileState.file);
      var fileCount = newState.fields.freightDelivery.docketImage.value.length;
      newState.fileCountDelivery = fileCount;
    } else {
      newState.fields.freightDelivery.docketImage.errors = fileState.errors;
    }
    this.setState(newState);
  }

  handleWeightErrors = () => {
    if (get(this.state.fields, 'freightPickup.consignor.site.locationType.value') === 'farmfield') {
      const newState = { ...this.state };
      if (newState.fields.freightPickup.outload.grossWeight.value && !newState.fields.freightPickup.outload.tareWeight.value) {
        newState.fields.freightPickup.outload.tareWeight.errors = ['This field cannot be blank'];
      } else if (newState.fields.freightPickup.outload.tareWeight.value && !newState.fields.freightPickup.outload.grossWeight.value) {
        newState.fields.freightPickup.outload.grossWeight.errors = ['This field cannot be blank'];
      }
      this.setState(newState);
    }
  };

  handlePickupTareWeightChange(event) {
    const newState = { ...this.state };
    if (isEmpty(event.target.value))
      newState.fields.freightPickup.outload.tareWeight.value = undefined;
    else
      newState.fields.freightPickup.outload.tareWeight.value = event.target.value;

    const netWeight = this.pickupNetWeight(newState);
    newState.fields.freightPickup.netWeight.value = netWeight;
    if (netWeight) newState.fields.freightPickup.netWeight.errors = [];
    if (
      newState.fields.freightPickup.outload.grossWeight.value &&
      parseFloat(newState.fields.freightPickup.outload.grossWeight.value) <= parseFloat(event.target.value)
    ) {
      newState.fields.freightPickup.outload.tareWeight.errors = ["Tare Weight can't be greater than Gross Weight"];
    } else {
      newState.fields.freightPickup.outload.tareWeight.errors = [];
      newState.fields.freightPickup.outload.grossWeight.errors = [];
    }
    this.setState(newState, () => this.handleWeightErrors());
  }

  handlePickupContainerTareWeightChange(event) {
    const newState = { ...this.state };
    if (isEmpty(event.target.value))
      newState.fields.freightPickup.outload.containerTareWeight.value = undefined;
    else
      newState.fields.freightPickup.outload.containerTareWeight.value = event.target.value;
    const netWeight = this.pickupNetWeight(newState);
    newState.fields.freightPickup.netWeight.value = netWeight;
    if (netWeight) newState.fields.freightPickup.netWeight.errors = [];
    if (
      newState.fields.freightPickup.outload.grossWeight.value &&
      parseFloat(newState.fields.freightPickup.outload.grossWeight.value) <= parseFloat(event.target.value)
    ) {
      newState.fields.freightPickup.outload.containerTareWeight.errors = ["Container Tare Weight can't be greater than Gross Weight"];
    } else {
      newState.fields.freightPickup.outload.containerTareWeight.errors = [];
      newState.fields.freightPickup.outload.grossWeight.errors = [];
    }
    this.setState(newState, () => this.handleWeightErrors());
  }

  handleDeliveryContainerTareWeightChange(event) {
    const newState = { ...this.state };
    if (isEmpty(event.target.value))
      newState.fields.freightDelivery.inload.containerTareWeight.value = undefined;
    else
      newState.fields.freightDelivery.inload.containerTareWeight.value = event.target.value;
    const netWeight = this.deliveryNetWeight(newState);
    newState.fields.freightDelivery.netWeight.value = netWeight;
    if (netWeight) newState.fields.freightDelivery.netWeight.errors = [];
    if (
      newState.fields.freightDelivery.inload.grossWeight.value &&
      parseFloat(newState.fields.freightDelivery.inload.grossWeight.value) <= parseFloat(event.target.value)
    ) {
      newState.fields.freightDelivery.inload.containerTareWeight.errors = ["Container Tare Weight can't be greater than Gross Weight"];
    } else {
      newState.fields.freightDelivery.inload.containerTareWeight.errors = [];
      newState.fields.freightDelivery.inload.grossWeight.errors = [];
    }
    this.setState(newState, () => this.handleWeightErrors());
  }

  handlePickupGrossWeightChange(event) {
    const newState = { ...this.state };
    if (isEmpty(event.target.value))
      newState.fields.freightPickup.outload.grossWeight.value = undefined;
    else
      newState.fields.freightPickup.outload.grossWeight.value = event.target.value;
    const netWeight = this.pickupNetWeight(newState);
    newState.fields.freightPickup.netWeight.value = netWeight;
    if (netWeight) newState.fields.freightPickup.netWeight.errors = [];
    this.changeFreightPickupTareWeightGrossWeightValidators(newState);
    if (
      newState.fields.freightPickup.outload.tareWeight.value &&
      parseFloat(newState.fields.freightPickup.outload.tareWeight.value) >= parseFloat(event.target.value)
    ) {
      newState.fields.freightPickup.outload.grossWeight.errors = ["Gross Weight can't be less than Tare Weight"];
    } else if (
      newState.fields.freightPickup.outload.containerTareWeight.value &&
      parseFloat(newState.fields.freightPickup.outload.containerTareWeight.value) >= parseFloat(event.target.value)
    ) {
      newState.fields.freightPickup.outload.grossWeight.errors = ["Gross Weight can't be less than Container Tare Weight"];
    } else {
      newState.fields.freightPickup.outload.tareWeight.errors = [];
      newState.fields.freightPickup.outload.grossWeight.errors = [];
    }
    this.setState(newState, () => this.handleWeightErrors());
  }

  handleDeliveryTareWeightChange(event) {
    const newState = { ...this.state };
    if (isEmpty(event.target.value))
      newState.fields.freightDelivery.inload.tareWeight.value = undefined;
    else
      newState.fields.freightDelivery.inload.tareWeight.value = event.target.value;
    const netWeight = this.deliveryNetWeight(newState);
    newState.fields.freightDelivery.netWeight.value = netWeight;
    if (netWeight) newState.fields.freightDelivery.netWeight.errors = [];
    this.changeFreightPickupTareWeightGrossWeightValidators(newState);
    if (
      newState.fields.freightDelivery.inload.grossWeight.value &&
      parseFloat(newState.fields.freightDelivery.inload.grossWeight.value) <= parseFloat(event.target.value)
    ) {
      newState.fields.freightDelivery.inload.tareWeight.errors = ["Tare Weight can't be greater than Gross Weight"];
    } else {
      newState.fields.freightDelivery.inload.tareWeight.errors = [];
      newState.fields.freightDelivery.inload.grossWeight.errors = [];
    }
    this.setState(newState, () => this.checkDeliveryNetWeightAgainstRemainingSpace());
  }

  handleDeliveryGrossWeightChange(event) {
    const newState = { ...this.state };
    if (isEmpty(event.target.value))
      newState.fields.freightDelivery.inload.grossWeight.value = undefined;
    else
      newState.fields.freightDelivery.inload.grossWeight.value = event.target.value;
    const netWeight = this.deliveryNetWeight(newState);
    newState.fields.freightDelivery.netWeight.value = netWeight;
    if (netWeight) newState.fields.freightDelivery.netWeight.errors = [];

    if (
      newState.fields.freightDelivery.inload.tareWeight.value &&
      parseFloat(newState.fields.freightDelivery.inload.tareWeight.value) >= parseFloat(event.target.value)
    ) {
      newState.fields.freightDelivery.inload.grossWeight.errors = ["Gross Weight can't be less than Tare Weight"];
    } else {
      newState.fields.freightDelivery.inload.tareWeight.errors = [];
      newState.fields.freightDelivery.inload.grossWeight.errors = [];
    }
    this.setState(newState, () => this.checkDeliveryNetWeightAgainstRemainingSpace());
  }

  validateWeightFields = (netWeight, site, load) =>
    parseFloat(netWeight).toFixed(2) ===
    (parseFloat(get(this.state, `fields.${site}.${load}.grossWeight.value`)) - parseFloat(get(this.state, `fields.${site}.${load}.tareWeight.value`))).toFixed(2);

  validateNetWeight = (netWeight, freightId, newState) => {
    let isNewStateEmpty = isEmpty(newState);
    let state = isNewStateEmpty ? { ...this.state } : newState;

    const isBothSitesOrderBookingOn = get(state.selectedConsignor, 'isSlotOrderBookingOn') && get(state.selectedConsignee, 'isSlotOrderBookingOn');
    const commonMinimumTonnage = isBothSitesOrderBookingOn ? max([get(state.selectedConsignor, 'minimumTonnage'), get(state.selectedConsignee, 'minimumTonnage')]) : null
    let minimumTonnage = freightId == 'freightPickup' ? get(state.selectedConsignor, 'minimumTonnage') : get(state.selectedConsignee, 'minimumTonnage');
    minimumTonnage = commonMinimumTonnage || minimumTonnage;

    let isSlotOrderBookingOn = freightId == 'freightPickup' ? get(state.selectedConsignor, 'isSlotOrderBookingOn') : get(state.selectedConsignee, 'isSlotOrderBookingOn');
    let isCounterSiteSlotOrderBookingOn = freightId == 'freightPickup' ? get(state.selectedConsignee, 'isSlotOrderBookingOn') : get(state.selectedConsignor, 'isSlotOrderBookingOn');
    let counterSiteMinimumTonnage = freightId == 'freightPickup' ? get(state.selectedConsignee, 'minimumTonnage') : get(state.selectedConsignor, 'minimumTonnage');
    if (this.state.selectedBaseEntity) {
      const maxAllowedWeight = this.getMaxAllowedWeight();
      if (netWeight > maxAllowedWeight) {
        set(state, `fields.${freightId}.netWeight.errors`, [`${this.canUserRaiseAmendRequest() ? 'Warning: ': ''} Maximum allowed tonnage is ${maxAllowedWeight} ${this.priceUnit()}`]);
        if (isNewStateEmpty)
          this.setState(state);
        return
      }
;    }
    let errorMessage = `Tonnage can't be less than minimum tonnage required`;
    if (isSlotOrderBookingOn && netWeight < minimumTonnage) {
      set(state, 'minimumTonnage', minimumTonnage);
      set(state, `fields.${freightId}.netWeight.errors`, [`${errorMessage} ${minimumTonnage}`]);
      set(state, `fields.${freightId}.netWeight.validators`, [valueAbove(minimumTonnage, `${errorMessage} ${minimumTonnage}`)]);
    } else if (!isSlotOrderBookingOn && isCounterSiteSlotOrderBookingOn && netWeight < counterSiteMinimumTonnage) {
      set(state, 'minimumTonnage', counterSiteMinimumTonnage);
      set(state, `fields.${freightId}.netWeight.errors`, [`${errorMessage} ${counterSiteMinimumTonnage}`]);
      set(state, `fields.${freightId}.netWeight.validators`, [valueAbove(counterSiteMinimumTonnage, `${errorMessage} ${counterSiteMinimumTonnage}`)]);
    }
    else {
      set(state, 'minimumTonnage', 0);
      set(state, `fields.${freightId}.netWeight.errors`, []);
      set(state, `fields.${freightId}.netWeight.validators`, [valueAbove(0)]);
    }
    if (isNewStateEmpty)
      this.setState(state);
  }

  canUserRaiseAmendRequest() {
    return get(this.state.selectedBaseEntity, 'canRaiseAmendRequest');
  }

  getMaxAllowedWeight() {
    const parentTonnage = min([get(this.state.selectedBaseEntity, 'maxAllowedTonnageForAmend'), get(this.state.selectedBaseEntity, 'totalTonnageWithTolerance')]) || 0;
    let maxAllowedTonnage = parentTonnage - get(this.state.selectedBaseEntity, 'deliveredTonnage', 0);
    return parseFloat(maxAllowedTonnage.toFixed(2));
  }

  handleNetWeightChange = (event, freightId, load) => {
    const netWeight = parseFloat(event.target.value);
    if (netWeight > 0) {
      const newState = { ...this.state };
      if (!this.validateWeightFields(netWeight, freightId, load)) {
        set(newState, `fields.${freightId}.${load}.tareWeight.value`, undefined);
        set(newState, `fields.${freightId}.${load}.grossWeight.value`, undefined);
      }
      this.validateNetWeight(netWeight, freightId, newState)
      set(newState, `fields.${freightId}.netWeight.value`, parseFloat(netWeight));
      this.setState(newState, () => {
        this.checkDeliveryNetWeightAgainstRemainingSpace();
      });
    } else {
      this.setFieldValue(`${freightId}.netWeight`, undefined, true);
    }
  };

  async setSellerAvailableTonnage() {
    let sellerAvailableTonnage = this.state.sellerAvailableTonnage;
    let sellerAvailableTonnageMessage = this.state.sellerAvailableTonnageMessage;
    const commodityId = get(this.state.fields, 'commodityId.value');
    const seasonId = get(this.state.fields, 'freightPickup.outload.season.value');
    const gradeId = get(this.state.fields, 'freightPickup.outload.gradeId.value');
    const handlerId = get(this.state.fields, 'freightPickup.consignor.handlerId.value')
    const ngrId = get(this.state.fields, 'freightPickup.outload.ngrId.value')
    if (commodityId &&  gradeId && handlerId && seasonId && ngrId) {
      let tonnage = await APIService.ngrs(ngrId).appendToUrl('available-stock/')
                                    .appendToUrl(`?commodityId=${commodityId}&gradeId=${gradeId}&siteId=${handlerId}&season=${seasonId}`)
          .get(null, {'REFERER-UNIT': getCountryConfig()?.truck?.unit, 'REFERER-UNIT-FOR-REQUEST': true});

      sellerAvailableTonnage = tonnage.totalTonnage;
      sellerAvailableTonnageMessage = ` (Available ${this.tonnageLabel}: ${tonnage.totalTonnage} ${this.priceUnit()})`
    }
    else {
      sellerAvailableTonnage = undefined;
    }
    this.setState({ sellerAvailableTonnage: sellerAvailableTonnage, sellerAvailableTonnageMessage: sellerAvailableTonnageMessage });
  }

  checkDeliveryNetWeightAgainstRemainingSpace() {
    let isDeliveryNetWeightGreaterThanRemainingSpace = false;
    if (this.state.fields.status.value === 'delivered' && !this.isEditForm && get(this.state.selectedConsigneeSite, 'entity') === 'storage') {
      const grossWeight = parseFloat(this.state.fields.freightDelivery.inload.grossWeight.value);
      const tareWeight = parseFloat(this.state.fields.freightDelivery.inload.tareWeight.value);
      const netWeight = parseFloat(this.state.fields.freightDelivery.netWeight.value);
      const relevantStocks = this.getRelevantStocks(this.state.selectedConsigneeSite, 'delivery');
      let remainingSpace = get(relevantStocks, 'remainingSpace');
      if (!isNumber(remainingSpace) && isEmpty(get(this.state.selectedConsigneeSite, 'stocks'))) {
        remainingSpace = get(this.state.selectedConsigneeSite, 'size');
      }
      isDeliveryNetWeightGreaterThanRemainingSpace =
        grossWeight && tareWeight && isNumber(remainingSpace) ? grossWeight - tareWeight > remainingSpace : netWeight && isNumber(remainingSpace) ? netWeight > remainingSpace : false;
    }
    this.setState({ isDeliveryNetWeightGreaterThanRemainingSpace: isDeliveryNetWeightGreaterThanRemainingSpace });
  }

  getRelevantStocks(storage, checkpointType) {
    if (!storage || !includes(['pickup', 'delivery'], checkpointType)) {
      return null;
    }
    const partyType = checkpointType === 'pickup' ? 'seller' : 'buyer';
    const commodityId = get(this.state.selectedBaseEntity, 'commodityId') || this.state.fields.commodityId.value;
    const loadKey = checkpointType === 'pickup' ? 'freightPickup.outload' : 'freightDelivery.inload';
    const season = this.isDeliveredMovement()
      ? get(this.state.fields, loadKey + '.season.value')
      : get(this.state.selectedBaseEntity, 'season') || this.state.fields.season.value;
    const ngrId = this.isDeliveredMovement()
      ? get(this.state.fields, loadKey + '.ngrId.value')
      : get(this.state.selectedBaseEntityRaw, `${partyType}.ngrId`) || this.state.fields.customer.ngrId.value;
    const gradeId = this.state.fields[`freight${capitalize(checkpointType)}`].inload.gradeId.value;
    return find(get(storage, 'stocks'), stocksItem => {
      const stockNgrId = get(stocksItem, 'ngr.id');
      return get(storage, 'storageType') === 'system'
        ? get(stocksItem, 'commodity.id') === commodityId &&
        (stockNgrId ? stockNgrId === ngrId : true) &&
        get(stocksItem, 'season') === season &&
        get(stocksItem, 'grade.id') === gradeId
        : stocksItem.commodity.id === commodityId;
    });
  }

  getEmailPopupParties = () => {
    if (this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
      return ['customer', 'provider', 'consignor', 'consignee'];
    }

    const fmOrder = this.isEditForm ? get(this.editMovement, 'order', '') : get(this.state.selectedBaseEntity, 'order', '');
    if (get(fmOrder, 'level') > 0 && get(fmOrder, 'parentOrder.typeId') != FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN) {
      if (get(this.state.selectedBaseEntity, 'administration.brokeredById')) {
        return ['buyer', 'seller', 'broker', 'customer', 'provider', 'consignor', 'consignee'];
      }
      return ['buyer', 'seller', 'customer', 'provider', 'consignor', 'consignee'];
    }

    const buyerCompanyId = this.isEditForm ? get(this.editMovement, 'commodityContract.buyer.companyId', '') : get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId', ''));
    const sellerCompanyId = this.isEditForm ? get(this.editMovement, 'commodityContract.seller.companyId', '') : get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId', ''));
    const customerCompanyId = this.isEditForm ? get(this.editMovement, 'customer.companyId', '') : get(this.state.fields, 'customer.companyId.value', get(this.state.selectedBaseEntity, 'customerCompanyId', ''));
    const buyer = buyerCompanyId === customerCompanyId ? { label: 'Buyer (Customer)', value: 'customer' } : 'buyer';
    const seller = sellerCompanyId === customerCompanyId ? { label: 'Seller (Customer)', value: 'customer' } : 'seller';

    if (
      get(this.state.selectedBaseEntity, 'administration.brokeredById') ||
      get(this.parentContract, 'administration.brokeredById')
    ) {
      return [buyer, seller, 'broker', 'provider', 'consignor', 'consignee'];
    }
    return [buyer, seller, 'provider', 'consignor', 'consignee'];
  };

  getEmailPopupPartiesCompanyIds() {
    const parties = this.getEmailPopupParties();
    const ids = {};
    forEach(parties, party => {
      if (party === 'broker')
        ids.broker = get(this.state.selectedBaseEntity, 'administration.brokerContact.companyId') ||
          get(this.parentContract, 'administration.brokerContact.companyId');
      if (party === 'buyer')
        ids.buyer = this.isEditForm ?
          get(this.editMovement, 'buyer.companyId', get(this.editMovement, 'commodityContract.buyer.companyId')) :
          get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId'));
      if (party === 'seller')
        ids.seller = this.isEditForm ?
          get(this.editMovement, 'seller.companyId', get(this.editMovement, 'commodityContract.seller.companyId')) :
          get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId'));
      if (party === 'consignor')
        ids.consignor = get(this.state.selectedConsignor, 'companyId') || get(this.parentContract, 'consignorsWithSites[0].handler.companyId');
      if (party === 'consignee')
        ids.consignee = get(this.state.selectedConsignee, 'companyId') || get(this.parentContract, 'consigneesWithSites[0].handler.companyId');
      if (party === 'customer' || get(party, 'label'))
        ids.customer = this.state.fields.customer.companyId.value;
      if (party === 'provider')
        ids.provider = this.state.fields.providerId.value;
    });

    return ids;
  }

  async getPartyContacts() {
    if (this.gotOncePartyContacts)
      return;
    this.gotOncePartyContacts = true;
    const parties = this.getEmailPopupPartiesCompanyIds();
    const partiesWithoutContacts = without(keys(parties), 'customer', 'provider');
    const contacts = {};
    forEach(parties, (id, party) => {
      contacts[party] = [];
      if (party === 'customer')
        contacts[party] = this.state.customerContacts;
      if (party === 'provider')
        contacts[party] = this.state.providerEmployees;
    });
    if (!isEmpty(partiesWithoutContacts)) {
      const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
      if (isEmpty(companyIds))
        return contacts;
      const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
      const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.userToken);
      forEach(partiesWithoutContacts, party => {
        contacts[party] = filter(employees, { companyId: parties[party] });
      });
    }
    return contacts;
  }

  getSelectedParties = () => {
    if (this.isEditForm) {
      if (get(this.editMovement, 'isFreightProvider') && get(this.editMovement, 'isCustomer')) return ['customer', 'provider'];
      else if (get(this.editMovement, 'isFreightProvider')) return ['customer'];
      else if (get(this.editMovement, 'isCustomer')) return ['provider'];
    } else {
      const customerCompanyId = get(this.state.fields, 'customer.companyId.value');
      const providerCompanyId = get(this.state.fields, 'providerId.value');
      if (this.props.userCompanyId !== customerCompanyId && this.props.userCompanyId !== providerCompanyId) return ['customer', 'provider'];
      else if (this.props.userCompanyId === providerCompanyId) return ['customer'];
      else if (this.props.userCompanyId === customerCompanyId) return ['provider'];
    }
    return ['customer', 'provider'];
  };

  getPartyEmails = () => {
    return {
      broker: get(this.state.selectedBaseEntity, 'administration.brokerContact.email', ''),
      buyer: this.isEditForm ? get(this.editMovement, 'commodityContract.buyer.contact.email', '') : get(this.state.selectedBaseEntity, 'buyer.contact.email', ''),
      seller: this.isEditForm ? get(this.editMovement, 'commodityContract.seller.contact.email', '') : get(this.state.selectedBaseEntity, 'seller.contact.email', ''),
      customer: get(find(this.state.customerContacts, { id: this.state.fields.customer.contactId.value }), 'email'),
      provider: get(find(this.state.providerEmployees, { id: this.state.fields.assignToId.value }), 'email'),
      consignor: '',
      consignee: '',
    };
  };

  getEmailSubject = () => {
    const companyName = get(this.props.currentUser, 'company.name', "");
    const amendingText = this.isEditForm ? "[Amendment] " : "";
    const identifier = get(this.state.fields, 'identifier.value', '').toUpperCase();
    return `${amendingText}${companyName} Freight Movement #${identifier}`;
  };

  getFooterNote = () => {
    let bindText = '';
    this.sendButtonText = null;
    if (
      (this.isEditForm && get(this.editMovement, 'status') === 'draft') ||
      get(this.state.selectedBaseEntity, 'contractStatus') === 'planned'
    ) {
      bindText = ' after confirmation of Contract.';
      this.sendButtonText = 'Send';
    }
    return this.isEditForm ?
      "Amendments and updated Freight Movement PDF will be automatically sent as part of the email" + bindText :
      "Freight Movement PDF will be automatically sent as part of the email" + bindText;
  };

  openEmailDialog = (data) => {
    this.setState({ showEmailDialog: true, emailPopupParties: this.getEmailPopupParties() });
    this.payloadData = data;
  };

  closeEmailDialog = (communicationData, justClose) => {
    if (justClose) {
      this.gotOncePartyContacts = false;
      this.setState({ showEmailDialog: false });
    }
    else if (this.state.showEmailDialog) {
      let data = this.payloadData;
      if (communicationData) {
        data['communication'] = communicationData;
      }
      this.setState({ showEmailDialog: false }, () => {
        const { dispatch } = this.props;
        data = this.handleEmptyData(data);
        if (this.isEditForm) {
          dispatch(isLoading('alertify'));
          dispatch(raiseMovementAmendRequest(this.editMovementId, data));
        } else {
          dispatch(isLoading('movementDetail'));
          dispatch(create(data, null, null, null, (checkpointType, loadType) => {
            this.fetchSlots(checkpointType, loadType)
            const newState = { ...this.state };
            newState.fields[`${loadType}SlotId`].value = null;
            this.setState(newState);
          }))
        }
      });
    }
    this.handleClosePreview();
  };

  handleEmptyData = (data) => {
    if (has(data, 'freightDelivery.consignee.sites')) {
      let consigneeSite = data.freightDelivery.consignee.sites[0];
      consigneeSite = omitBy(consigneeSite, isNil);
      if (isEmpty(consigneeSite)) {
        delete data.freightDelivery.consignee.sites;
      }
      if (isEmpty(data.freightDelivery.consignee)) {
        delete data.freightDelivery.consignee;
      }
    }
    if (has(data, 'freightPickup.consignor.sites')) {
      let consignorSite = data.freightPickup.consignor.sites[0];
      consignorSite = omitBy(consignorSite, isNil);
      if (isEmpty(consignorSite)) {
        delete data.freightPickup.consignor.sites
      }
      if (isEmpty(data.freightPickup.consignor)) {
        delete data.freightPickup.consignor;
      }
    }

    if (this.state.isPickupSiteCompanyHandler && isEqual(this.state.fields.status.value, 'delivered')) {
      let inloadCheck = this.isLoadDataEmpty('inload', this.state.fields.status.value)
      if (inloadCheck) {
        data.freightDelivery.skipInload = true;
        delete data.freightDelivery.inload;
      }
    } else if (this.state.isDeliverySiteCompanyHandler && isEqual(this.state.fields.status.value, 'delivered')) {
      let outloadCheck = this.isLoadDataEmpty('outload', this.state.fields.status.value)
      if (outloadCheck) {
        data.freightPickup.skipOutload = true;
        delete data.freightPickup.outload;
      }
    }

    return data;
  }

  validateLoads(loadType, movementStatus) {
    let loadCheck = this.isLoadDataEmpty(loadType, movementStatus)
    if (loadCheck) {
      return false;
    } else {
      if (isEqual(movementStatus, 'planned')) {
        if (this.state.isDeliverySiteCompanyHandler) {
          let isPickupRequiredDetailsCompleted = true;
          forOwn(this.state.fields.freightPickup, (item, key) => {
            if (includes(['date', 'timeStart', 'consignor'], key)) {
              if (isEqual(key, 'consignor')) {
                if (isNil(this.state.fields.freightPickup.consignor.site.locationId.value)) {
                  isPickupRequiredDetailsCompleted = false;
                }
                if (this.state.isConsignorEditable) {
                  if (isNil(this.state.selectedConsignor)) {
                    isPickupRequiredDetailsCompleted = false;
                  }
                }
              } else {
                if (isNil(item.value)) {
                  isPickupRequiredDetailsCompleted = false;
                }
              }
            }
          });
          return !isPickupRequiredDetailsCompleted;
        } else if (this.state.isPickupSiteCompanyHandler) {
          let isDeliveryRequiredDetailsCompleted = true;
          forOwn(this.state.fields.freightDelivery, (item, key) => {
            if (includes(['date', 'timeStart', 'consignee'], key)) {
              if (isEqual(key, 'consignee')) {
                if (isNil(this.state.fields.freightDelivery.consignee.site.locationId.value)) {
                  isDeliveryRequiredDetailsCompleted = false;
                }
                if (this.state.isConsignorEditable) {
                  if (isNil(this.state.selectedConsignee)) {
                    isDeliveryRequiredDetailsCompleted = false;
                  }
                }
              } else {
                if (isNil(item.value)) {
                  isDeliveryRequiredDetailsCompleted = false;
                }
              }
            }
          });
          return !isDeliveryRequiredDetailsCompleted;
        }
      } else if (isEqual(movementStatus, 'delivered')) {
        if (this.state.isDeliverySiteCompanyHandler) {
          let isOutloadRequiredDetailsCompleted = true;
          forOwn(this.state.fields.freightPickup, (item, key) => {
            if (includes(['consignor', 'date', 'timeStart', 'outload'], key)) {
              if (isEqual(key, 'consignor')) {
                if (isNil(this.state.fields.freightPickup.consignor.site.locationId.value)) {
                  isOutloadRequiredDetailsCompleted = false;
                }
                if (this.state.isConsignorEditable) {
                  if (isNil(this.state.selectedConsignor)) {
                    isOutloadRequiredDetailsCompleted = false;
                  }
                }
              }
              else if (isEqual(key, 'outload')) {
                if (isNil(this.state.fields.freightPickup.outload.truckId.value)) {
                  isOutloadRequiredDetailsCompleted = false;
                } else if (isEmpty(this.state.fields.freightPickup.outload.season.value)) {
                  isOutloadRequiredDetailsCompleted = false;
                } else if (isNil(this.state.fields.freightPickup.outload.ngrId.value)) {
                  isOutloadRequiredDetailsCompleted = false;
                } else if (isNil(this.state.fields.freightPickup.outload.gradeId.value)) {
                  isOutloadRequiredDetailsCompleted = false;
                }
              }
              else {
                if (isEmpty(item.value)) {
                  isOutloadRequiredDetailsCompleted = false;
                }
              }
            }
          });
          return !isOutloadRequiredDetailsCompleted;
        } else if (this.state.isPickupSiteCompanyHandler) {
          let isInloadRequiredDetailsCompleted = true;
          forOwn(this.state.fields.freightDelivery, (item, key) => {
            if (includes(['consignee', 'date', 'timeStart', 'inload'], key)) {
              if (isEqual(key, 'consignee') && !this.isContainerMovement()) {
                if (isNil(this.state.fields.freightDelivery.consignee.site.locationId.value)) {
                  isInloadRequiredDetailsCompleted = false;
                }
                if (this.state.isConsigneeEditable) {
                  if (isNil(this.state.selectedConsignee)) {
                    isInloadRequiredDetailsCompleted = false;
                  }
                }
              }
              else if (isEqual(key, 'inload')) {
                if (isNil(this.state.fields.freightDelivery.inload.truckId.value)) {
                  isInloadRequiredDetailsCompleted = false;
                } else if (isEmpty(this.state.fields.freightDelivery.inload.season.value)) {
                  isInloadRequiredDetailsCompleted = false;
                } else if (isNil(this.state.fields.freightDelivery.inload.ngrId.value)) {
                  isInloadRequiredDetailsCompleted = false;
                } else if (isNil(this.state.fields.freightDelivery.inload.gradeId.value)) {
                  isInloadRequiredDetailsCompleted = false;
                }
              }
              else {
                if (isEmpty(item.value) && !isEqual(key, 'consignee'))
                  isInloadRequiredDetailsCompleted = false;
                else if (isEqual(key, 'consignee') && this.isContainerMovement() && isNil(this.state.fields.freightDelivery.consignee.handlerId.value))
                  isInloadRequiredDetailsCompleted = false;
              }
            }
          });
          return !isInloadRequiredDetailsCompleted;
        }
      }
    }
  }

  isLoadDataEmpty(loadType, movementStatus) {
    if (isEqual(movementStatus, 'planned')) {
      if (isEqual(loadType, 'outload')) {
        let isPickFieldsEmpty = true;
        forOwn(this.state.fields.freightPickup, (item, key) => {
          if (includes(['consignor', 'date', 'timeStart', 'orderNumber', 'bookingNumber', 'instructions'], key)) {
            if (isEqual(key, 'consignor')) {
              if (!isEmpty(this.state.fields.freightPickup.consignor.site.locationId.value)) {
                isPickFieldsEmpty = false;
              }
              if (this.state.isConsignorEditable) {
                if (!isEmpty(this.state.selectedConsignor)) {
                  isPickFieldsEmpty = false;
                }
              }
            }
            else {
              if (!isEmpty(item.value) && !isEqual(item.value, 'Invalid date')) {
                isPickFieldsEmpty = false;
              }
            }
          }
        });
        return isPickFieldsEmpty;
      } else if (isEqual(loadType, 'inload')) {
        let isDeliverySiteEmpty = true;
        forOwn(this.state.fields.freightDelivery, (item, key) => {
          if (includes(['consignee', 'date', 'timeStart', 'orderNumber', 'bookingNumber', 'instructions'], key)) {
            if (isEqual(key, 'consignee')) {
              if (!isEmpty(this.state.fields.freightDelivery.consignee.site.locationId.value)) {
                isDeliverySiteEmpty = false;
              }
              if (this.state.isConsigneeEditable) {
                if (!isEmpty(this.state.selectedConsignee)) {
                  isDeliverySiteEmpty = false;
                }
              }
            }
            else {
              if (!isEmpty(item.value) && !isEqual(item.value, 'Invalid date')) {
                isDeliverySiteEmpty = false;
              }
            }
          }
        });
        return isDeliverySiteEmpty;
      }
    } else if (isEqual(movementStatus, 'delivered')) {
      if (isEqual(loadType, 'outload')) {
        let isPickFieldsEmpty = true;
        forOwn(this.state.fields.freightPickup, (item, key) => {
          if (includes(['consignor', 'date', 'timeStart', 'orderNumber', 'bookingNumber', 'outload'], key)) {
            if (isEqual(key, 'consignor')) {
              if (!isEmpty(this.state.fields.freightPickup.consignor.site.locationId.value)) {
                isPickFieldsEmpty = false;
              }
              if (this.state.isConsignorEditable) {
                if (!isEmpty(this.state.selectedConsignor)) {
                  isPickFieldsEmpty = false;
                }
              }
            } else if (isEqual(key, 'outload')) {
              forOwn(this.state.fields.freightPickup.outload, (outloadItem, outloadKey) => {
                if (isEqual(outloadKey, 'specs')) {
                  if (!isEmpty(outloadItem)) {
                    forOwn(outloadItem, (specItem) => {
                      if (isNumber(specItem.value) || !isEmpty(specItem.value)) {
                        isPickFieldsEmpty = false;
                      }
                    })
                  }
                } else {
                  if (!includes(['truckOwner', 'truckOwnerId'], outloadKey) && (isNumber(outloadItem.value) || !isEmpty(outloadItem.value))) {
                    isPickFieldsEmpty = false;
                  }
                }
              });
            }
            else {
              if (!isEmpty(item.value) && !isEqual(item.value, 'Invalid date')) {
                isPickFieldsEmpty = false;
              }
            }
          }
        });
        if (!isEmpty(this.state.pickupCode.value))
          isPickFieldsEmpty = false;
        if (!isEmpty(this.state.pickupMassLimitPermit.value))
          isPickFieldsEmpty = false;
        return isPickFieldsEmpty;
      } else if (isEqual(loadType, 'inload')) {
        let isDeliverySiteEmpty = true;
        forOwn(this.state.fields.freightDelivery, (item, key) => {
          if (includes(['consignee', 'date', 'timeStart', 'orderNumber', 'bookingNumber', 'inload'], key)) {
            if (isEqual(key, 'consignee')) {
              if (!isEmpty(this.state.fields.freightDelivery.consignee.site.locationId.value)) {
                isDeliverySiteEmpty = false;
              }
              if (this.state.isConsigneeEditable) {
                if (!isEmpty(this.state.selectedConsignee)) {
                  isDeliverySiteEmpty = false;
                }
              }
            } else if (isEqual(key, 'inload')) {
              forOwn(this.state.fields.freightDelivery.inload, (inloadItem, inloadKey) => {
                if (isEqual(inloadKey, 'specs')) {
                  if (!isEmpty(inloadItem)) {
                    forOwn(inloadItem, (specItem) => {
                      if (isNumber(specItem.value) || !isEmpty(specItem.value)) {
                        isDeliverySiteEmpty = false;
                      }
                    })
                  }
                } else {
                  if (!includes(['truckOwner', 'truckOwnerId'], inloadKey) && (isNumber(inloadItem.value) || !isEmpty(inloadItem.value))) {
                    isDeliverySiteEmpty = false;
                  }
                }
              });
            }
            else {
              if (!isEmpty(item.value) && !isEqual(item.value, 'Invalid date')) {
                isDeliverySiteEmpty = false;
              }
            }
          }
        });
        if (!isEmpty(this.state.deliveryCode.value))
          isDeliverySiteEmpty = false;
        if (!isEmpty(this.state.deliveryMassLimitPermit.value))
          isDeliverySiteEmpty = false;

        return isDeliverySiteEmpty;
      }
    }
  }


  validatePickupAndDeliverySites(consignorSites, consigneeSites) {
    consignorSites = map(consignorSites, partialRight(pick, ['locationId', 'locationType']));
    consigneeSites = map(consigneeSites, partialRight(pick, ['locationId', 'locationType']));
    return isEmpty(intersectionWith(consignorSites, consigneeSites, isEqual));
  }

  isSamePickupAndDeliverySites(consignorSites, consigneeSites) {
    consignorSites = map(consignorSites, partialRight(pick, ['locationId', 'locationType']));
    consigneeSites = map(consigneeSites, partialRight(pick, ['locationId', 'locationType']));
    var isSiteEmpty = true;
    forEach(consignorSites, site => {
      if (site.locationId && site.locationType) {
        isSiteEmpty = false;
      }
    });
    forEach(consigneeSites, site => {
      if (site.locationId && site.locationType) {
        isSiteEmpty = false;
      }
    });
    if (isSiteEmpty) {
      return false;
    }
    return !isEmpty(intersectionWith(consignorSites, consigneeSites, isEqual));
  }

  getSite(type, locationId, locationType) {
    return find(type == 'consignor' ? this.state.consignorSites : this.state.consigneeSites, { id: locationId, entity: locationType });
  }

  isRangeInMaxMinYear = date => {
    if (typeof date === 'string') {
      let year = Number(date.split('-')[0]);
      if (year > MAX_YEAR || year < MIN_YEAR)
        return true;
      return false;
    }
    return false;
  };

  getSubmitData = () => {
    const data = mapValues(this.state.fields, 'value');
    data.identifier = data.identifier.toUpperCase();
    let consignorSites = [];
    let consigneeSites = [];
    data.freightPickup = mapValues(this.state.fields.freightPickup, 'value');
    if (this.state.selectedBaseEntity && this.state.selectedBaseEntity.consignorHandlerId) {
      delete data.freightPickup.consignor;
    } else {
      data.freightPickup.consignor = mapValues(this.state.fields.freightPickup.consignor, 'value');
      data.freightPickup.consignor.site = mapValues(this.state.fields.freightPickup.consignor.site, 'value');
    }

    data.freightDelivery = mapValues(this.state.fields.freightDelivery, 'value');
    let baseEntityConsigneeSites = get(this.state.selectedBaseEntity, 'consigneeSites');

    if(this.isTrailerBookingApplicable("Pickup"))
      data.outloadTrailerSlots= {trailer1: Number(this.state.pickupSiteTrailers.trailer1.slotId), trailer2: Number(this.state.pickupSiteTrailers.trailer2.slotId), trailer3: Number(this.state.pickupSiteTrailers.trailer3.slotId)}

    if (this.isTrailerBookingApplicable("Delivery"))
      data.inloadTrailerSlots = {trailer1: Number(this.state.deliverySiteTrailers.trailer1.slotId), trailer2: Number(this.state.deliverySiteTrailers.trailer2.slotId), trailer3: Number(this.state.deliverySiteTrailers.trailer3.slotId)}

    if (this.state.selectedBaseEntity && this.state.selectedBaseEntity.consigneeHandlerId && baseEntityConsigneeSites && baseEntityConsigneeSites.length == 1) {
      delete data.freightDelivery.consignee;
    } else {
      data.freightDelivery.consignee = mapValues(this.state.fields.freightDelivery.consignee, 'value');
      data.freightDelivery.consignee.site = mapValues(this.state.fields.freightDelivery.consignee.site, 'value');
    }
    data.customer = mapValues(this.state.fields.customer, 'value');

    if (isNaN(parseFloat(data.rateFreightIn))) {
      data.rateFreightIn = null;
    }

    if (has(data, 'freightPickup.consignor.site')) {
      data.freightPickup.consignor.sites = [data.freightPickup.consignor.site];
      delete data.freightPickup.consignor.site;
    } else if (this.hasSelectedBaseEntity()) {
      if (has(this.state.fields.freightPickup, 'consignor.site')) {
        if (this.state.fields.freightPickup.consignor.site.locationId.value && this.state.fields.freightPickup.consignor.site.locationType.value) {
          const locationId = this.state.fields.freightPickup.consignor.site.locationId.value;
          const locationType = this.state.fields.freightPickup.consignor.site.locationType.value;
          consignorSites = [{ locationId, locationType }];
        } else {
          consignorSites = [
            {
              locationId: get(this.state.selectedBaseEntity, 'consignorLocationId'),
              locationType: get(this.state.selectedBaseEntity, 'consignorLocationType'),
            },
          ];
        }
        data.freightPickup.consignor = {
          handlerId: get(this.state.selectedConsignor, 'id') || get(this.state.selectedBaseEntity, 'consignorHandlerId'),
          sites: consignorSites,
        };
      }
    }
    if (has(data, 'freightDelivery.consignee.site')) {
      data.freightDelivery.consignee.sites = [data.freightDelivery.consignee.site];
      delete data.freightDelivery.consignee.site;
    } else if (this.hasSelectedBaseEntity()) {
      if (has(this.state.fields.freightDelivery, 'consignee.site')) {
        if (
          this.state.fields.freightDelivery.consignee.site.locationId.value &&
          this.state.fields.freightDelivery.consignee.site.locationType.value
        ) {
          const locationId = this.state.fields.freightDelivery.consignee.site.locationId.value;
          const locationType = this.state.fields.freightDelivery.consignee.site.locationType.value;
          consigneeSites = [{ locationId, locationType: locationType }];
        } else {
          consigneeSites = [
            {
              locationId: get(this.state.selectedBaseEntity, 'consigneeLocationId'),
              locationType: get(this.state.selectedBaseEntity, 'consigneeLocationType'),
            },
          ];
        }
        const selectedConsignee = get(this.state, 'selectedConsignee');

        data.freightDelivery.consignee = {
          handlerId: get(selectedConsignee, 'id') || get(this.state.selectedBaseEntity, 'consigneeHandlerId'),
          sites: consigneeSites,
        };
      }
    }

    if (this.state.fields.status.value === 'delivered') {
      data.freightPickup.outload = this.state.fields.freightPickup.outload;
      data.freightDelivery.inload = this.state.fields.freightDelivery.inload;
      data.freightPickup.outload = this.getOutloadData(data);
      data.freightDelivery.inload = this.getInloadData(data);
    } else {
      delete data.freightPickup.outload;
      delete data.freightDelivery.inload;
    }

    if (this.isEditForm && this.editMovement && this.shouldFetchSlotsFor('Pickup') && this.shouldFetchSlotsFor('Delivery')) {
      data.plannedTruckId = get(this.editMovement, 'plannedTruckId')
    }

    for (var key in data) {
      if (has(data, key)) {
        if (/^\d+$/.test(key)) {
          delete data[key];
        }
      }
    }

    if (this.hasSelectedBaseEntity()) {
      if (['order', 'freightorder'].includes(this.state.selectedBaseEntity.entity)) {
        data.orderId = data.baseEntityId;
        data.commodityContractId = get(this.state.selectedBaseEntity, 'commodityContractId');
      } else {
        data.commodityContractId = data.baseEntityId;
      }
      if (isEmpty(data, 'customer.representedById'))
        set(data, 'customer.representedById', get(this.state.selectedBaseEntity, 'customerRepresentedById'));
      delete data.baseEntityId;
    } else if (!this.isFreightProviderCreator()) {
      set(data, 'customer.representedById', this.props.currentUser.companyId);
    }
    data.estimatedDistance = this.state.totalDistance === '' ? null : this.state.totalDistance;
    data.estimatedTime = this.state.estimatedTime === '' ? null : this.state.estimatedTime;
    if (this.manualComplete) {
      const tonnage = this.isMeterCubeCommodityCommodity() ? this.state.fields.quantity.value : this.state.fields.plannedTonnage.value;
      data.freightPickup.outload.tareWeight = 1;
      data.freightPickup.outload.grossWeight = parseFloat(tonnage) + 1;
      data.freightDelivery.inload.tareWeight = 1;
      data.freightDelivery.inload.grossWeight = parseFloat(tonnage) + 1;
    }
    let note = this.state.fields.note;
    if (!this.state.isDependent) {
      if (this.orderId) {
        data.sellerId = get(this.state, 'seller.id');
        data.buyerId = get(this.state, 'buyer.id');

      }
      else {
        data.seller = get(this.state, 'sellerDetails');
        data.buyer = get(this.state, 'buyerDetails');
      }
      delete data.baseEntityId;
    }
    if (this.state.isDependent) {
      let commodityContractId = get(this.state.selectedBaseEntity, 'commodityContractId');
      if (this.orderId && !commodityContractId) {
        data.sellerId = get(this.state, 'seller.id');
        data.buyerId = get(this.state, 'buyer.id');
      }
      delete data.baseEntityId;
    }
    note = omit(note, ['errors']);
    data.note = note;
    data.currency = this.state.currency;
    data.feature = "Movement Form"
    return data;
  };

  isMassLimitFieldsValid() {
    let isValid = true;
    let showPickupAdditionalMassLimitCodeFields = false;
    const ERROR_MESSAGE = "This field is required";
    if (this.state.fields.status.value !== 'delivered')
      return isValid;
    if(this.state.fields.freightPickup.consignor.handlerId.value && this.props.companyParties && this.state.consignors) {
      let handlerCompanyId = get(find(this.state.consignors, {id: this.state.fields.freightPickup.consignor.handlerId.value}), 'companyId')
      if (handlerCompanyId)
        showPickupAdditionalMassLimitCodeFields = get(find(this.props.companyParties, {id: handlerCompanyId}), 'additionalMassLimitCodes');
    }

    let showDeliveryAdditionalMassLimitCodeFields = false;
    if(this.state.fields.freightDelivery.consignee.handlerId.value && this.props.companyParties && this.state.consignees) {
      let handlerCompanyId = get(find(this.state.consignees, {id: this.state.fields.freightDelivery.consignee.handlerId.value}), 'companyId')
      if (handlerCompanyId)
        showDeliveryAdditionalMassLimitCodeFields = get(find(this.props.companyParties, {id: handlerCompanyId}), 'additionalMassLimitCodes');
    }
    const newState = {...this.state};
    if (showPickupAdditionalMassLimitCodeFields) {
      if (this.state.freightPickupPermitChecked) {
        if (!this.state.fields.freightPickup.outload.permitNumber.value) {
          isValid = false;
          newState.freightPickupTruckErrors.permitNumber = ERROR_MESSAGE;
        }
        if (!this.state.fields.freightPickup.outload.declaredMassLimit.value) {
          isValid = false;
          newState.freightPickupTruckErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      if (this.state.freightPickupAccreditationNumberChecked && !this.state.fields.freightPickup.outload.accreditationNumber.value) {
        isValid = false;
        newState.freightPickupTruckErrors.accreditationNumber = ERROR_MESSAGE;
      }
      if (this.state.pickupMassLimitPermit.value === 'Notice' && !this.state.fields.freightPickup.outload.noticeNumber.value) {
        isValid = false;
        newState.freightPickupTruckErrors.noticeNumber = ERROR_MESSAGE;
      }
      if (this.state.freightPickupRestrictedChecked) {
        if (!this.state.fields.freightPickup.outload.restricted.value) {
          isValid = false;
          newState.freightPickupTruckErrors.restricted = ERROR_MESSAGE;
        }
        if (!this.state.fields.freightPickup.outload.declaredMassLimit.value) {
          isValid = false;
          newState.freightPickupTruckErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      const validator = get(this.state.pickupMassLimitPermit.validators, '0')
      if (validator && validator.isInvalid(this.state.pickupMassLimitPermit.value) && this.state.pickupCode.value) {
        isValid = false;
        newState.pickupMassLimitPermit.errors = ['This field is required.']
      }
    }
    if (showDeliveryAdditionalMassLimitCodeFields) {
      if (this.state.freightDeliveryPermitChecked) {
        if (!this.state.fields.freightDelivery.inload.permitNumber.value) {
          isValid = false;
          newState.freightDeliveryTruckErrors.permitNumber = ERROR_MESSAGE;
        }
        if (!this.state.fields.freightDelivery.inload.declaredMassLimit.value) {
          isValid = false;
          newState.freightDeliveryTruckErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      if (this.state.freightDeliveryAccreditationNumberChecked && !this.state.fields.freightDelivery.inload.accreditationNumber.value) {
        isValid = false;
        newState.freightDeliveryTruckErrors.accreditationNumber = ERROR_MESSAGE;
      }
      if (this.state.deliveryMassLimitPermit.value === 'Notice' && !this.state.fields.freightDelivery.inload.noticeNumber.value) {
        isValid = false;
        newState.freightDeliveryTruckErrors.noticeNumber = ERROR_MESSAGE;
      }
      if (this.state.freightDeliveryRestrictedChecked) {
        if (!this.state.fields.freightDelivery.inload.restricted.value) {
          isValid = false;
          newState.freightDeliveryTruckErrors.restricted = ERROR_MESSAGE;
        }
        if (!this.state.fields.freightDelivery.inload.declaredMassLimit.value) {
          isValid = false;
          newState.freightDeliveryTruckErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      const validator = get(this.state.deliveryMassLimitPermit.validators, '0')
      if (validator && validator.isInvalid(this.state.deliveryMassLimitPermit.value) && this.state.deliveryCode.value) {
        isValid = false;
        newState.deliveryMassLimitPermit.errors = ['This field is required.']
      }
    }
    this.setState(newState);
    return isValid
  }

  async handleSubmit(event) {
    if (this.state.openConsignorFarmForm || this.state.openConsigneeFarmForm || this.state.openConsignorStorageForm || this.state.openConsigneeStorageForm)
      return;
    event.preventDefault();
    if (!this.checkForSitesWithExternalBooking())
      return;
    if (this.state.fields.status.value === DELIVERED_STATUS) {
      if (get(this.state.selectedConsignor, 'externallySyncSource')) {
        alertifyjs.error("Cannot create movement as pickup site is externally managed");
        return;
      }
      if (get(this.state.selectedConsignee, 'externallySyncSource')) {
        alertifyjs.error("Cannot create movement as delivered site is externally managed");
        return;
      }
    }
    this.setAllFieldErrors();
    const isMassLimitFieldsValid = this.isMassLimitFieldsValid();
    if (!isMassLimitFieldsValid)
      return
    if(this.checkTrailerSlotNeeded(true) || this.checkTrailerSlotNeeded(false))
      return
    if (this.manualComplete) {
      const newState = { ...this.state };
      newState.fields.assignToId.errors = [];
      newState.fields.invoicing.errors = [];
      newState.fields.plannedTruckId.errors = [];
      newState.fields.providerId.errors = [];
      newState.fields.rateFreightOut.errors = [];
      newState.fields.rateFreightIn.errors = [];
      newState.fields.freightDelivery.inload.grossWeight.errors = [];
      newState.fields.freightDelivery.inload.tareWeight.errors = [];
      newState.fields.freightDelivery.netWeight.errors = [];
      newState.fields.freightPickup.outload.tareWeight.errors = [];
      newState.fields.freightPickup.outload.grossWeight.errors = [];
      newState.fields.freightPickup.netWeight.errors = [];
      if (get(this.state.selectedConsignor, 'typeId') === BHC_TYPE_ID) {
        newState.fields.freightPickup.outload.docketNumber.value = 'SYSTEM_DEFAULT';
        newState.fields.freightPickup.outload.docketNumber.errors = [];
      }
      if (get(this.state.selectedConsignee, 'typeId') === BHC_TYPE_ID) {
        newState.fields.freightDelivery.inload.docketNumber.value = 'SYSTEM_DEFAULT';
        newState.fields.freightDelivery.inload.docketNumber.errors = [];
      }
      newState.fields.freightPickup.outload.gradeId.value = newState.fields.plannedGradeId.value;
      newState.fields.freightPickup.outload.gradeId.errors = [];
      newState.fields.freightDelivery.inload.gradeId.value = newState.fields.plannedGradeId.value;
      newState.fields.freightDelivery.inload.gradeId.errors = [];
      this.setState(newState);
    }

    if (this.state.selectedBaseEntity) {
      const newState = { ...this.state };
      newState.fields.freightPickup.date.errors = filter(newState.fields.freightPickup.date.errors, error => !error.startsWith('Warning'));
      newState.fields.freightDelivery.date.errors = filter(newState.fields.freightDelivery.date.errors, error => !error.startsWith('Warning'));
      if (this.isEditForm && this.shouldFetchSlotsFor('Pickup') && this.shouldFetchSlotsFor('Delivery')) {
        newState.fields.plannedTruckId.errors = [];
        newState.fields.driverId.errors = [];
      }
      this.setState(newState);
    }
    this.focusOnFirstErrorField();
    if (
      (this.isLoadDetailsValid() || this.isEditingAdHocMovement() || SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'provider.id'))) &&
      this.valid(this.state.fields.customer) &&
      this.valid(this.state.fields) &&
      !get(this.state, 'errors.party')
    ) {
      const data = this.getSubmitData();

      if (this.manualComplete) {
        data.freightPickup.outload.specs['COIL'] = this.state.coil.value;
        data.freightPickup.outload.specs['IMPU'] = this.state.impu.value;
        data.freightDelivery.inload.specs['COIL'] = this.state.coil.value;
        data.freightDelivery.inload.specs['IMPU'] = this.state.impu.value;
        data.plannedGradeId = '';
      }

      if (this.state.isPickupSiteCompanyHandler) {
        if (this.validateLoads('inload', this.state.fields.status.value)) {
          alertifyjs.alert(
            'Permission Denied',
            `<div><p>Incomplete ${this.state.deliveryTerm} details:</p>
                <ul><li>Please enter all the mandatory ${this.state.deliveryTerm} details or clear any values entered</li></ul>
              </div>`,
            () => { }
          );
          return
        }
      } else if (this.state.isDeliverySiteCompanyHandler) {
        if (this.validateLoads('outload', this.state.fields.status.value)) {
          alertifyjs.alert(
            'Permission Denied',
            `<div><p>Incomplete ${this.state.pickupTerm} details:</p>
              <ul><li>Please enter all the mandatory ${this.state.pickupTerm} details or clear any values entered</li></ul>
            </div>`, () => { }
          );
          return
        }
      }
      if (
        this.isSamePickupAndDeliverySites(
          get(data, 'freightPickup.consignor.sites'), get(data, 'freightDelivery.consignee.sites')
        )
      ) alertifyjs.error("Pickup and Delivery site can't be same.");
      else if (this.isNetWeightMoreThanAllowed() && !this.isMeterCubeCommodityCommodity() && !this.canUserRaiseAmendRequest()) {
        if (get(this.state.selectedBaseEntity, 'entity') === 'order') {
          alertifyjs.error(this.EXCESS_ORDER_TONNAGE_ERROR_MESSAGE);
        } else {
          alertifyjs.error(this.EXCESS_CONTRACT_TONNAGE_ERROR_MESSAGE);
        }
      }
      else if (!this.isEditForm && !isCurrentUserBroker() && this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY
        && !(get(this, 'orderResponse.typeId') > 3) && !(get(this, 'orderResponse.canCreateFreightContract'))
        && !includes([this.state.fields.customer.companyId.value, this.state.fields.providerId.value, get(this.state.selectedConsignor, 'companyId'), get(this.state.selectedConsignee, 'companyId')], this.props.currentUser.companyId)) {
        alertifyjs.error("Your company has to be one of the parties for this order");
      }
      else if (this.isRangeInMaxMinYear(get(this.state, 'fields.freightPickup.date.value'))) {
        alertifyjs.error(`Pickup year can only be between ${MIN_YEAR} and ${MAX_YEAR}`);
      }
      else if (this.isRangeInMaxMinYear(get(this.state, 'fields.freightDelivery.date.value'))) {
        alertifyjs.error(`Delivery year can only be between ${MIN_YEAR} and ${MAX_YEAR}`);
      }
      else if (this.isNetQuantityMoreThanAllowed() && this.isMeterCubeCommodityCommodity()) {
        if (get(this.state.selectedBaseEntity, 'entity') === 'order') {
          alertifyjs.error(this.EXCESS_ORDER_QUANTITY_ERROR_MESSAGE);
        } else {
          alertifyjs.error(this.EXCESS_CONTRACT_QUANTITY_ERROR_MESSAGE);
        }
      }
      else if (this.isContainerMovement() && this.state.selectedConsignee?.id == this.state.selectedConsignor?.id) {
        alertifyjs.error("Pickup and Delivery site cannot be same in container movement.");
      }
      else {
        const sitesValidity = [this.getSitesValidity(data)];
        if (sitesValidity[0].errorMessages.length) {
          alertifyjs.alert('Error in creating Freight Movement', sitesValidity[0].errorMessages.join('</br>'));
          const newState = { ...this.state };
          sitesValidity[0].fieldsToReset.forEach(fieldPath => {
            set(newState, fieldPath, undefined);
          });
          this.setState(newState);
        } else if (sitesValidity[0].shouldCreateAutoInload && !this.state.preview) {
          alertifyjs.confirm(
            'Did you forget to Inload?',
            `The mentioned Outload is taking out more quantity than what is present. As a result,
            system is generating an inload of ${this.state.inloadDeficit} ${MT_UNIT} to balance out the stocks.
            Do you want to continue?`,
            async () => {
              if (this.manualComplete && get(this.state, 'selectedBaseEntity.hasPlannedTitleTransfers')) {
                setTimeout(() => {
                  alertifyjs.confirm(
                    'Warning',
                    `Marking this contract complete will mark the following planned title transfers completed with the transfer date as ${moment(
                      get(data, 'freightDelivery.date'),
                    ).format(this.state.countryFormats.dateDisplay)}. Do you want to continue?`,
                    () => {
                      if (this.manualComplete) {
                        this.submit(data);
                      } else {
                        this.handleOpenPreview();
                      }
                    },
                    () => { },
                  );
                }, 1000);
              } else {
                if (this.manualComplete) {
                  this.submit(data);
                } else {
                  this.handleOpenPreview();
                }
              }
            },
            () => { },
          );
        } else {
          if (this.manualComplete || this.state.preview) {
            if (this.manualComplete && get(this.state, 'selectedBaseEntity.hasPlannedTitleTransfers')) {
              alertifyjs.confirm(
                'Warning',
                `Marking this contract complete will mark the following planned title transfers completed with the transfer date as ${moment(
                  get(data, 'freightDelivery.date'),
                ).format(this.state.countryFormats.dateDisplay)}. Do you want to continue?`,
                () => {
                  this.submit(data);
                },
                () => { },
              );
            } else {
              this.submit(data);
            }
          } else {
            if (!isEmpty(get(data, 'freightPickup.consignor.sites')) && !isEmpty(get(data, 'freightDelivery.consignee.sites')) && this.isDeliveredMovement()) {
              let pickupId = get(this.state, 'selectedConsignorSite.entity') == 'farmfield' ? null : get(data, 'freightPickup.consignor.sites[0].locationId');
              let deliveryId = get(this.state, 'selectedConsigneeSite.entity') == 'farmfield' ? null : get(data, 'freightDelivery.consignee.sites[0].locationId');
              getStoredCommodityDetails(pickupId, (outload) => {
                getStoredCommodityDetails(deliveryId, (inload) => {
                  let isInloadValid = (!some(inload, ['id', get(data, 'commodityId')]) || some(inload, ['id', get(data, 'commodityId')]) && (get(find(inload, ['id', get(data, 'commodityId')]), 'space') < get(data, 'freightDelivery.netWeight', 0))) && !isEmpty(inload);
                  let isOutloadValid = (!some(outload, ['id', get(data, 'commodityId')]) || some(outload, ['id', get(data, 'commodityId')]) && (get(find(outload, ['id', get(data, 'commodityId')]), 'tonnage') < get(data, 'freightPickup.netWeight', 0))) && !isEmpty(outload);
                  if (isInloadValid || isOutloadValid) {
                    alertifyjs.confirm(
                      'Warning',
                      `<div className="">
                      ${this.getCommodityDetailsTable(outload, isOutloadValid, 'selectedConsignorSite', 'outloading')}
                      ${this.getCommodityDetailsTable(inload, isInloadValid, 'selectedConsigneeSite', 'inloading')}
                    </div>`,
                      () => {
                        this.handleOpenPreview();
                      },
                      () => { },
                    );
                  }
                  else
                    this.handleOpenPreview();
                });
              });
            }
            else
              this.handleOpenPreview();
          }
        }
      }
    }
  }

  getCommodityDetailsTable(data, check, site, loadType) {
    if (check) {
      let tableData = `<p>Please verify the storage information before ${loadType}:</p>
                <ul style="list-style: none;">
                <li>Storage Name: ${get(this.state, `${site}.name`)}</li>
                <li>
                <table>
                  <tr>
                    <th>Commodity</th>
                    <th>Grade</th>
                    <th>Season</th>
                    <th>Available ${this.tonnageLabel} (${getCountryMovementDisplayUnit()})</th>
                    <th>Space Remaining</th>
                  </tr>
                  ${getLoadWarningTableData(data)}
                </table>
                </li>
                </ul>`;

      return tableData;
    }
    return '';
  }

  setUpMovementDatetimeToUTC = movement => {
    let newMovement = Object.assign({}, movement);

    if (has(movement, 'freightPickup.date') && has(movement, 'freightPickup.timeStart')) {
      if (movement.freightPickup.date && movement.freightPickup.timeStart) {
        const utcDateTime = getDateTimeInUTC(movement.freightPickup.date, movement.freightPickup.timeStart);
        newMovement.freightPickup.dateTime = utcDateTime.dateTime;
      }
    } else if (has(movement, 'freightPickup.timeStart')) {
      const utcDateTime = getDateTimeInUTC(this.editMovement.freightPickup.date, movement.freightPickup.timeStart);
      newMovement.freightPickup.dateTime = utcDateTime.dateTime;
    } else if (has(movement, 'freightPickup.date')) {
      const utcDateTime = getDateTimeInUTC(movement.freightPickup.date, this.editMovement.freightPickup.timeStart);
      newMovement.freightPickup.dateTime = utcDateTime.dateTime;
    }
    if (has(movement, 'freightDelivery.date') && has(movement, 'freightDelivery.timeStart')) {
      if (movement.freightDelivery.date && movement.freightDelivery.timeStart) {
        const utcDateTime = getDateTimeInUTC(movement.freightDelivery.date, movement.freightDelivery.timeStart);
        newMovement.freightDelivery.dateTime = utcDateTime.dateTime;
      }
    } else if (has(movement, 'freightDelivery.timeStart')) {
      const utcDateTime = getDateTimeInUTC(this.editMovement.freightDelivery.date, movement.freightDelivery.timeStart);
      newMovement.freightDelivery.dateTime = utcDateTime.dateTime;
    } else if (has(movement, 'freightDelivery.date')) {
      const utcDateTime = getDateTimeInUTC(movement.freightDelivery.date, this.editMovement.freightDelivery.timeStart);
      newMovement.freightDelivery.dateTime = utcDateTime.dateTime;
    }

    return newMovement;
  };

  submit(data) {
    data = this.setUpMovementDatetimeToUTC(data);
    if (this.state.amendParentToTonnage)
      data.amendParentToTonnage = this.state.amendParentToTonnage;
    data = omit(data, ['freightPickup.date', 'freightPickup.timeStart', 'freightDelivery.date', 'freightDelivery.timeStart']);
    if (this.isEditForm) {
      const editData = this.getEditData();
      if (isEmpty(editData) || isEqual(keys(editData), 'communication')) {
        alertifyjs.alert('Freight Movement', 'Please update the movement before re-submitting.');
      }
      else if (Object.entries(editData).filter(val => val[1] != null).length == 1 && has(editData, "note")) {
        this.props.dispatch(isLoading('alertify'));
        this.props.dispatch(raiseMovementAmendRequest(this.editMovementId, data));
      }
      else {
        this.openEmailDialog(editData);
      }
    }
    else {
      if (this.manualComplete) {
        data.status = 'manual_contract_complete_balanced';
        data.manualComplete = true;
      }
      this.openEmailDialog(data);
    }
  }

  isLoadDetailsValid() {
    return (
      this.valid(this.state.fields.freightPickup.consignor.site) &&
      this.valid(this.state.fields.freightDelivery.consignee.site) &&
      this.valid(this.state.fields.freightPickup.consignor) &&
      this.valid(this.state.fields.freightDelivery.consignee) &&
      this.valid(this.state.fields.freightPickup) &&
      this.valid(this.state.fields.freightDelivery) &&
      this.valid(this.state.fields.freightPickup.outload) &&
      this.valid(this.state.fields.freightDelivery.inload) &&
      (
        !this.isPlannedMovement() ?
          (
            this.valid(this.state.fields.freightPickup.outload.specs) &&
            this.valid(this.state.fields.freightDelivery.inload.specs)
          ) :
          true
      ) &&
      (
        (this.isCreatingCanolaMovement() && this.isDeliveredMovement()) ?
          (
            this.manualComplete ?
              (this.isValidField(this.state.coil) && this.isValidField(this.state.impu)) :
              (this.state.fields.freightDelivery.inload.specs.COIL.value && this.state.fields.freightDelivery.inload.specs.IMPU.value && this.state.fields.freightPickup.outload.specs.COIL.value && this.state.fields.freightPickup.outload.specs.IMPU.value)
          ) :
          true
      )
    );
  }

  handleCancelButtonClick(event) {
    event.preventDefault();
    window.history.back();
  }

  async setSelectedBaseEntity() {
    const query = new URLSearchParams(this.props.location.search);
    const contractId = query.get('commodityContractId');
    if (this.orderId) {
      await this.getFreightOrder(this.orderId);
    } else if (contractId) {
      await this.getCommodityContract(contractId);
    }
  }

  async getFreightOrder(orderId) {
    if (!this.isFetchingFreightOrder) {
      this.isFetchingFreightOrder = true;
      let freightOrder = await APIService.freights().orders(orderId).get(this.props.userToken, {'REFERER-UNIT' : getCountryMovementDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true})
      this.orderResponse = freightOrder;
      const order = merge(freightOrder, { referenceNumber: freightOrder.identifier, entity: 'order' });
      if (order.canCreateFreightContract || order.isCallOnGrain) {
        setTimeout(() => this.setBaseEntityState(order, 'order'), 1000);
      } else {
        window.location.hash = '#/403';
      }
    }
  }

  async getCommodityContract(contractId) {
    if (!this.isFetchingCommodityContract) {
      this.isFetchingCommodityContract = true;
      let contract = await APIService.contracts(contractId).get(this.props.userToken, {'REFERER-UNIT' : getCountryMovementDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true})
      if (get(contract, 'canCreateFreightContract') || this.manualComplete) {
        this.parentContract = contract;
        setTimeout(() => this.setBaseEntityState(contract, 'commodityContract'), 1000);
      } else {
        window.location.hash = '#/403';
      }
    }
  }

  setBaseEntityState(baseEntity, entityType) {
    if (baseEntity && this.state.selectedBaseEntityId !== baseEntity.id) {
      const newState = { ...this.state };
      var commodityContractId = null;
      if (entityType === 'order') {
        commodityContractId = baseEntity.commodityContractId;
      } else {
        commodityContractId = baseEntity.id;
      }
      if (commodityContractId || get(baseEntity, 'externalReferenceNumber')) {
        newState.fields.typeId.value = FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER;
      } else {
        newState.fields.typeId.value = FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY;
      }

      newState.queryParamExists = true;
      newState.selectedBaseEntityId = baseEntity.id;
      newState.selectedBaseEntityType = entityType;
      this.setState(newState, () => {
        this.handleBaseEntityChange(baseEntity.id, entityType + 'Id', baseEntity, false);
      });
    }
  }

  getBaseEntityFromItem(item) {
    const baseEntity = {
      id: item.id,
      identifier: item.identifier,
      entity: item.entity,
      unaccountedTonnage: item.unaccountedTonnage,
      minimumTonnage: get(item, 'minimumTonnage', 0),
      maximumTonnage: get(item, 'maximumTonnage', 0),
      isPoolContract: get(item, 'isPoolContract'),
      actualCustomer: null,
      hasPlannedTitleTransfers: get(item, 'hasPlannedTitleTransfers'),
      outstandingTonnage: get(item, 'outstandingTonnage'),
      totalTonnageWithTolerance: item?.totalTonnageWithTolerance,
      deliveredTonnage: item?.deliveredTonnage,
      progressTonnage: item?.progressTonnage,
    };
    if (item.entity === 'order') {
      baseEntity.provider = item.provider;
      baseEntity.season = this.getValueFromOrder(item, 'season');
      baseEntity.commodityId = this.getValueFromOrder(item, 'commodityId');
      baseEntity.varietyId = this.getValueFromOrder(item, 'varietyId');
      baseEntity.deliveryStartDate = get(item, 'freightPickup.dateTime') ? moment(get(item, 'freightPickup.dateTime')).format('YYYY-MM-DD') : this.getValueFromOrder(item, 'freightPickup.date', 'deliveryStartDate');
      baseEntity.deliveryEndDate = get(item, 'freightDelivery.dateTime') ? moment(get(item, 'freightDelivery.dateTime')).format('YYYY-MM-DD') : this.getValueFromOrder(item, 'freightDelivery.date', 'deliveryEndDate');

      baseEntity.providerId = this.getValueFromOrder(item, 'providerId');
      baseEntity.assignToId = this.getValueFromOrder(item, 'assignToId', 'administration.assignToId');

      baseEntity.commodityContractId = this.getValueFromOrder(item, 'commodityContractId', 'id');
      baseEntity.commodityContract = this.getValueFromOrder(item, 'commodityContract');
      baseEntity.customerId = this.getValueFromOrder(item, 'customerId');
      baseEntity.customerCompany = this.getValueFromOrder(item, 'customer.company');
      baseEntity.customerCompanyId = this.getValueFromOrder(item, 'customer.companyId');
      baseEntity.customerContactId = this.getValueFromOrder(item, 'customer.contactId');
      baseEntity.customerContact = this.getValueFromOrder(item, 'customer.contact');
      baseEntity.customerNgrId = this.getValueFromOrder(item, 'customer.ngrId');
      baseEntity.customerRepresentedById = this.getValueFromOrder(item, 'customer.representedById');
      baseEntity.canRaiseAmendRequest = item?.canRaiseAmendRequest;
      if (baseEntity.commodityContractId && item.deliveryOnus && !item.customerId) {
        if (item.deliveryOnus.toLowerCase() === 'seller') {
          baseEntity.customerCompany = this.getValueFromOrder(item, 'seller.company');
          baseEntity.customerCompanyId = this.getValueFromOrder(item, 'seller.companyId');
          baseEntity.customerContactId = this.getValueFromOrder(item, 'seller.contactId');
          baseEntity.customerContact = this.getValueFromOrder(item, 'seller.contact');
          baseEntity.customerNgrId = this.getValueFromOrder(item, 'seller.ngrId');
          baseEntity.customerRepresentedById = this.getValueFromOrder(item, 'seller.representedById');
          baseEntity.isCustomerIsSeller = true;
          baseEntity.isCustomerIsBuyer = false;
        }
        else {
          baseEntity.customerCompany = get(item, 'buyer.company');
          baseEntity.customerCompanyId = get(item, 'buyer.companyId');
          baseEntity.customerContactId = get(item, 'buyer.contactId');
          baseEntity.customerContact = get(item, 'buyer.contact');
          baseEntity.customerNgrId = get(item, 'buyer.ngrId');
          baseEntity.customerRepresentedById = this.getValueFromOrder(item, 'buyer.representedById');
          baseEntity.isCustomerIsBuyer = true;
          baseEntity.isCustomerIsSeller = false;
        }
      }
      baseEntity.seller = this.getValueFromOrder(item, 'seller');
      baseEntity.buyer = this.getValueFromOrder(item, 'buyer');
      baseEntity.consignorHandlerCompanyId = this.getValueFromOrder(item, 'freightPickup.consignor.handler.companyId', 'consignorsWithSites[0].handler.companyId');
      baseEntity.currency = get(item, 'currency');

      baseEntity.consignorHandlerId = this.getValueFromOrder(item, 'freightPickup.consignor.handlerId', 'consignorsWithSites[0].handlerId');
      baseEntity.consignorHandlerName = this.getValueFromOrder(item, 'freightPickup.consignor.handler.displayName', 'consignorsWithSites[0].handler.displayName');
      baseEntity.consignorLocationId = this.getValueFromOrder(item, 'freightPickup.consignor.sites.0.locationId', 'consignorsWithSites[0].sites.0.locationId');
      baseEntity.consignors = this.getValueFromOrder(item, 'freightPickup.consignor.handler');
      if (baseEntity.consignors)
        baseEntity.consignors.address = this.getValueFromOrder(item, 'freightPickup.consignor.handler.address.address') || this.getValueFromOrder(item, 'freightPickup.consignor.handler.address');
      baseEntity.consignorSiteAddress = this.getValueFromOrder(
        item,
        'freightPickup.consignor.sites.0.location.address',
        'consignorsWithSites[0].sites.0.location.address',
      );
      baseEntity.consignorLocationType = this.getValueFromOrder(
        item,
        'freightPickup.consignor.sites.0.locationContentType',
        'consignorsWithSites[0].sites.0.locationContentType',
      );
      baseEntity.consignorLocationName = this.getValueFromOrder(
        item,
        'freightPickup.consignor.sites.0.location.name',
        'consignorsWithSites[0].sites.0.location.name',
      );

      baseEntity.consigneeHandlerCompanyId = this.getValueFromOrder(item, 'freightDelivery.consignee.handler.companyId', 'consigneesWithSites[0].handler.companyId');
      baseEntity.consigneeHandlerId = this.getValueFromOrder(item, 'freightDelivery.consignee.handlerId', 'consigneesWithSites[0].handlerId');
      baseEntity.consignees = this.getValueFromOrder(item, 'freightDelivery.consignee.handler');
      if (baseEntity.consignees)
        baseEntity.consignees.address = this.getValueFromOrder(item, 'freightDelivery.consignee.handler.address.address') || this.getValueFromOrder(item, 'freightDelivery.consignee.handler.address');
      baseEntity.consigneeHandlerName = this.getValueFromOrder(
        item,
        'freightDelivery.consignee.handler.displayName',
        'consigneesWithSites[0].handler.displayName',
      );
      baseEntity.consigneeLocationId = this.getValueFromOrder(item, 'freightDelivery.consignee.sites.0.locationId', 'consigneesWithSites[0].sites.0.locationId');
      baseEntity.consigneeSiteAddress = this.getValueFromOrder(
        item,
        'freightDelivery.consignee.sites.0.location.address',
        'consigneesWithSites[0].sites.0.location.address',
      );
      baseEntity.consigneeLocationType = this.getValueFromOrder(
        item,
        'freightDelivery.consignee.sites.0.locationContentType',
        'consigneesWithSites[0].sites.0.locationContentType',
      );
      baseEntity.consigneeLocationName = this.getValueFromOrder(
        item,
        'freightDelivery.consignee.sites.0.location.name',
        'consigneesWithSites[0].sites.0.location.name',
      );

      baseEntity.consignorSites = this.getValueFromOrder(item, 'freightPickup.consignor.sites', 'consignorsWithSites[0].sites');
      baseEntity.consigneeSites = this.getValueFromOrder(item, 'freightDelivery.consignee.sites', 'consigneesWithSites[0].sites');

      baseEntity.spread = this.getValueFromOrder(item, 'spread.details');

      baseEntity.plannedGradeId = this.getValueFromOrder(item, 'plannedGradeId', 'gradeId');
      baseEntity.tonnage = this.getValueFromOrder(item, 'plannedTonnage', 'tonnage');
      baseEntity.orderTonnage = item.orderTonnage;
      baseEntity.trackName = this.getValueFromOrder(item, 'track');
      baseEntity.conveyance = this.getValueFromOrder(item, 'conveyance');
      baseEntity.deliveryOnus = this.getValueFromOrder(item, 'deliveryOnus');
      baseEntity.displayName = this.getValueFromOrder(item, 'displayName');
      baseEntity.pickupOrderNumber = get(item, 'freightPickup.orderNumber');
      baseEntity.pickupOrder = get(item, 'pickupOrder');
      baseEntity.deliveryOrderNumber = get(item, 'freightDelivery.orderNumber');
      baseEntity.deliveryOrder = get(item, 'deliveryOrder');
      baseEntity.freightPickupSiteIsSystemStorage =
        get(item, 'consignorsWithSites[0].handler.typeId') === BHC_TYPE_ID || get(item, 'freightPickup.consignor.handler.typeId') === BHC_TYPE_ID;
      baseEntity.freightDeliverySiteIsSystemStorage =
        get(item, 'consigneesWithSites[0].handler.typeId') === BHC_TYPE_ID || get(item, 'freightDelivery.consignee.handler.typeId') === BHC_TYPE_ID;
      baseEntity.weightId = get(item, 'commodityContract.weightId', DEFAULT_WEIGHT_ID);
      baseEntity.commodityContractInspectionId = get(item, 'commodityContract.inspectionId');
      baseEntity.actualCustomer = get(item, 'orderActualCustomer');
      baseEntity.userHasDeliveryOnus = get(item, 'userHasDeliveryOnus');
      baseEntity.isCallOnGrain = get(item, 'typeId') === CALL_ON_GRAIN_TYPE_ID;
      baseEntity.isPickupOrder = get(item, 'typeId') === PICKUP_REQUEST_ORDER_TYPE_ID;
      baseEntity.isDeliveryOrder = get(item, 'typeId') === DELIVERY_REQUEST_ORDER_TYPE_ID;
      baseEntity.pickupInstructions = get(item, 'freightPickup.instructions');
      baseEntity.deliveryInstructions = get(item, 'freightDelivery.instructions');
      baseEntity.parentOrder = get(item, 'parentOrder');
      baseEntity.maxAllowedTonnageForAmend = get(item, 'maxAllowedTonnageForAmend');
      baseEntity.canAmendRelatedEntity = get(item, 'canAmendRelatedEntity');
      baseEntity.isIndependent = get(item, 'isIndependent');
      baseEntity.reason = get(item, 'reason');
      baseEntity.isBlended = get(item, 'isBlended');
      baseEntity.gradeName = get(item, 'gradeName');
    } else {
      baseEntity.contractStatus = item.status;
      baseEntity.userHasDeliveryOnus = get(item, 'userHasDeliveryOnus');
      baseEntity.commodityContractInspectionId = get(item, 'inspectionId');
      baseEntity.weightId = item.weightId;
      baseEntity.expectedTonnage = item.expectedTonnage;
      baseEntity.directFreightContractsTonnage = item.directFreightContractsTonnage;
      baseEntity.commodityContractId = item.id;
      baseEntity.season = item.season;
      baseEntity.commodityId = item.commodityId;
      baseEntity.varietyId = item.varietyId;
      baseEntity.deliveryStartDate = item.deliveryStartDate;
      baseEntity.deliveryEndDate = item.deliveryEndDate;
      baseEntity.deliveryOnus = item.deliveryOnus;
      baseEntity.seller = item.seller;
      baseEntity.buyer = item.buyer;
      baseEntity.displayName = item.displayName;
      baseEntity.pricePoint = item.pricePoint;
      baseEntity.status = item.status;
      baseEntity.currency = item?.currency;
      baseEntity.canRaiseAmendRequest = item?.canRaiseAmendRequest;
      let consignors = map(item?.consignorsWithSites, consignor => consignor?.handler)
      baseEntity.consignors = map(consignors, ({ address, ...rest }) => ({...rest, address: address?.address || address}));
      let consignees = map(item?.consigneesWithSites, consignee => consignee?.handler)
      baseEntity.consignees = map(consignees, ({ address, ...rest }) => ({...rest, address: address?.address || address}));
      if (item.deliveryOnus) {
        if (item.deliveryOnus.toLowerCase() === 'seller') {
          baseEntity.customerCompany = get(item, 'seller.company');
          baseEntity.customerContact = get(item, 'seller.contact');
          baseEntity.customerCompanyId = get(item, 'seller.companyId');
          baseEntity.customerContactId = get(item, 'seller.contactId');
          baseEntity.customerNgrId = get(item, 'seller.ngrId');
          baseEntity.customerRepresentedById = get(item, 'seller.representedById');
          baseEntity.isCustomerIsSeller = true;
          baseEntity.isCustomerIsBuyer = false;
        }
        else {
          baseEntity.customerCompany = get(item, 'buyer.company');
          baseEntity.customerCompanyId = get(item, 'buyer.companyId');
          baseEntity.customerContactId = get(item, 'buyer.contactId');
          baseEntity.customerContact = get(item, 'buyer.contact');
          baseEntity.customerNgrId = get(item, 'buyer.ngrId');
          baseEntity.customerRepresentedById = get(item, 'buyer.representedById');
          baseEntity.isCustomerIsBuyer = true;
          baseEntity.isCustomerIsSeller = false;
        }
      }
      baseEntity.consignorsWithSites = get(item, 'consignorsWithSites');
      baseEntity.consignorHandlerId = get(item, 'consignorsWithSites.0.handlerId');
      baseEntity.consignorHandlerName = get(item, 'consignorsWithSites.0.handler.displayName');
      baseEntity.consignorLocationId = get(item, 'consignorsWithSites.0.sites.0.locationId');
      baseEntity.consignorLocationType = get(item, 'consignorsWithSites.0.sites.0.locationContentType');
      baseEntity.consignorLocationName = get(item, 'consignorsWithSites.0.sites.0.location.name');
      baseEntity.consignorSites = get(item, 'consignorsWithSites.0.sites');
      baseEntity.freightPickupSiteIsSystemStorage = get(item, 'consignorsWithSites.0.handler.typeId') === BHC_TYPE_ID;
      baseEntity.consigneesWithSites = get(item, 'consigneesWithSites');
      baseEntity.consigneeHandlerId = get(item, 'consigneesWithSites.0.handlerId');
      baseEntity.consigneeHandlerName = get(item, 'consigneesWithSites.0.handler.displayName');
      baseEntity.consigneeLocationId = get(item, 'consigneesWithSites.0.sites.0.locationId');
      baseEntity.consigneeLocationName = get(item, 'consigneesWithSites.0.sites.0.location.name');
      baseEntity.consigneeLocationType = get(item, 'consigneesWithSites.0.sites.0.locationContentType');
      baseEntity.consigneeSites = get(item, 'consigneesWithSites.0.sites');
      baseEntity.freightDeliverySiteIsSystemStorage = get(item, 'consigneesWithSites.0.handler.typeId') === BHC_TYPE_ID;

      baseEntity.spread = get(item, 'spread.details');
      baseEntity.plannedGradeId = item.gradeId;
      baseEntity.tonnage = this.manualComplete ? get(item, 'outstandingTonnage') : item.tonnage;
      baseEntity.quantity = this.manualComplete ? get(item, 'outstandingTonnage') : item.quantity;
      baseEntity.orderTonnage = item.orderTonnage;
      baseEntity.trackName = get(item, 'track');
    }
    return baseEntity;
  }

  getValueFromOrder(order, keyPath, contractKeyPath) {
    if (isEmpty(order)) return null;
    contractKeyPath = contractKeyPath || keyPath;
    const value = get(order, keyPath);
    if (isEmpty(value) && !isNumber(value)) {
      if (!isEmpty(order['commodityContract'])) {
        return get(order['commodityContract'], contractKeyPath);
      }
      if (!isEmpty(order['parentOrder'])) {
        return this.getValueFromOrder(order['parentOrder'], keyPath, contractKeyPath);
      }
    }
    return value;
  }

  isCompanySiteAndNotBHC(siteId, entity, loadType) {
    const sites = loadType === 'outload' ? this.state.consignorSites : this.state.consigneeSites;
    const site = find(sites, { id: siteId, entity: entity });
    return get(site, 'entity') === 'companysite' && !get(site, 'isBhc');
  }

  getLoadData(data, loadType) {
    const commonKeys = ['bookingNumber', 'orderNumber'];
    const keys = ['gradeId', 'truckId', 'docketNumber'];
    const checkpointModel = loadType === 'outload' ? data.freightPickup : data.freightDelivery;
    const loadModel = checkpointModel[loadType];
    const source = FREIGHT_CONTRACT_LOAD_SOURCE;

    const utcDateTime = getDateTimeInUTC(checkpointModel.date, checkpointModel.timeStart);
    let dateTime = null;
    if (!isEqual(utcDateTime.dateTime, 'Invalid date')) {
      dateTime = utcDateTime.dateTime;
    }

    const site = get(loadType === 'outload' ? checkpointModel.consignor : checkpointModel.consignee, 'sites[0]');
    const siteEntity = get(site, 'locationType');
    const siteId = get(site, 'locationId');
    const isCompanySite = this.isCompanySiteAndNotBHC(siteId, siteEntity, loadType);
    let massLimitPayload = {};
    if (this.isDeliveredMovement())
      massLimitPayload = {
        categoryId: loadModel.categoryId.value,
        steer_1_point_1: loadModel.steer_1_point_1.value,
        steer_point_5: loadModel.steer_point_5.value,
        permitNumber: loadModel.permitNumber.value,
        declaredMassLimit: loadModel.declaredMassLimit.value,
        accreditationNumber: loadModel.accreditationNumber.value,
        restricted: loadModel.restricted.value,
        noticeNumber: loadModel.noticeNumber.value,
        loadSharing: loadType === 'outload' ? this.state.freightPickupLoadSharing : this.state.freightDeliveryLoadSharing
      };
    let splitWeights = undefined;
    let tareWeight = this.manualComplete ? 1 : get(checkpointModel, `${loadType}.tareWeight.value`)
    if (loadType === 'outload' && this.state.fields.freightPickup.outload.containerTareWeight.value && this.state.fields.freightPickup.outload.grossWeight.value) {
      splitWeights = { 'container_tare': parseFloat(this.state.fields.freightPickup.outload.containerTareWeight.value), 'truck_tare': parseFloat(this.state.fields.freightPickup.outload.tareWeight.value) };
      tareWeight = parseFloat(tareWeight || 0) + parseFloat(this.state.fields.freightPickup.outload.containerTareWeight.value);
    }
    else if (loadType === 'inload' && this.state.fields.freightDelivery.inload.containerTareWeight.value && this.state.fields.freightDelivery.inload.grossWeight.value) {
      splitWeights = { 'container_tare': parseFloat(this.state.fields.freightDelivery.inload.containerTareWeight.value), 'truck_tare': parseFloat(this.state.fields.freightDelivery.inload.tareWeight.value) };
      tareWeight = parseFloat(tareWeight || 0) + parseFloat(this.state.fields.freightDelivery.inload.containerTareWeight.value);
    }

    return {
      ...pick(data, ['commodityId']),
      ...pick(checkpointModel, commonKeys),
      ...pick(loadModel, keys),
      tareWeight: tareWeight,
      grossWeight: this.manualComplete ? parseFloat(this.state.fields.plannedTonnage.value) + 1 : get(checkpointModel, `${loadType}.grossWeight.value`),
      estimatedNetWeight: this.manualComplete ? parseFloat(this.state.fields.plannedTonnage.value) : checkpointModel.netWeight,
      freightProviderId: data.providerId,
      contractId: get(this.state, 'selectedBaseEntityRaw.entity') === 'contract' ? this.state.selectedBaseEntityRaw.id : null,
      dateTime,
      source,
      storageId: includes(siteEntity, 'storage') ? siteId : null,
      specs: mapValues(loadModel.specs, 'value'),
      season: get(loadModel.season, 'value', null),
      ngrId: get(loadModel.ngrId, 'value', null),
      varietyId: get(loadModel.varietyId, 'value', null),
      companySiteId: isCompanySite ? siteId : null,
      farmFieldId: siteEntity === 'farmfield' ? siteId : null,
      quantity: loadType === 'inload' ?
        get(data, 'freightDelivery.inload.quantity.value') :
        get(data, 'freightPickup.outload.quantity.value'),
      truckId: this.manualComplete ? parseFloat(this.state.fields.plannedTruckId.value) + 1 : get(checkpointModel, `${loadType}.truckId.value`),
      docketNumber: get(checkpointModel, `${loadType}.docketNumber.value`),
      gradeId: this.manualComplete ? parseFloat(this.state.fields.plannedGradeId.value) : get(checkpointModel, `${loadType}.gradeId.value`),
      comment: get(checkpointModel, `${loadType}.comment.value`),
      splitWeights: splitWeights,
      ...massLimitPayload
    };
  }

  isRegoSearchable = () => this.isUserNonDeliveryOnus() && (isEmpty(this.state.providerTrucks) || isEmpty(this.state.pickupOwnerTrucks) || isEmpty(this.state.deliveryOwnerTrucks));

  getInloadData(data) {
    return this.getLoadData(data, 'inload');
  }

  getOutloadData(data) {
    return this.getLoadData(data, 'outload');
  }

  isUserNonDeliveryOnus = () => {
    if (this.isEditForm) {
      if (
        this.hasSelectedBaseEntity() && (get(this.state, 'selectedBaseEntity.entity') === 'contract' || get(this.state, 'selectedBaseEntity.commodityContractId')) &&
        get(this.editMovement, 'isCustomer') === false &&
        get(this.editMovement, 'isFreightProvider') === false &&
        (get(this.editMovement, 'isBuyer') || get(this.editMovement, 'isSeller'))
      ) {
        const deliveryOnus = get(this.state, 'selectedBaseEntity.deliveryOnus', get(this.state, 'selectedBaseEntity.commodityContract.deliveryOnus'));
        return deliveryOnus === 'Seller' ? (this.props.userCompanyId === this.state.buyer.companyId || get(this.editMovement, 'isBuyer')) : (this.props.userCompanyId === this.state.seller.companyId || get(this.editMovement, 'isSeller'));
      }
      return false;
    } else {
      if (get(this.state, 'selectedBaseEntity.entity') === 'contract')
        return get(this.state, 'selectedBaseEntity.userHasDeliveryOnus') === false;
      else if (get(this.state, 'selectedBaseEntity.commodityContractId')) {
        if (has(this.state, 'selectedBaseEntity.userHasDeliveryOnus')) return get(this.state, 'selectedBaseEntity.userHasDeliveryOnus') === false && this.props.userCompanyId !== get(this.state, 'selectedBaseEntity.customerCompanyId') && this.props.userCompanyId !== get(this.state, 'selectedBaseEntity.customerRepresentedById');
        const deliveryOnus = get(this.state, 'selectedBaseEntity.commodityContract.deliveryOnus');
        return deliveryOnus === 'Seller' ? this.props.userCompanyId === get(this.state, 'selectedBaseEntity.commodityContract.buyer.companyId') : this.props.userCompanyId === get(this.state, 'selectedBaseEntity.commodityContract.seller.companyId');
      }
      return false;
    }
  };

  hasSelectedBaseEntity() {
    return !isEmpty(this.state.selectedBaseEntity);
  }

  isNetWeightMoreThanAllowed() {
    const baseEntity = this.state.selectedBaseEntity;
    const showFreightPickupOrDeliverySection = !this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails());
    if (isEmpty(baseEntity) || !showFreightPickupOrDeliverySection) {
      return false;
    } else if (baseEntity.weightId === WEIGHT_DESTINATION_ID) {
      return parseFloat(this.deliveryNetWeight()) > parseFloat(this.unplannedContractTonnage());
    } else if (baseEntity.weightId === WEIGHT_ORIGIN_ID) {
      return parseFloat(this.pickupNetWeight()) > parseFloat(this.unplannedContractTonnage());
    } else {
      return false;
    }
  }

  isNetQuantityMoreThanAllowed() {
    const baseEntity = this.state.selectedBaseEntity;
    const showFreightPickupOrDeliverySection = !this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails());
    if (isEmpty(baseEntity) || !showFreightPickupOrDeliverySection) {
      return false;
    } else if (baseEntity.weightId === WEIGHT_DESTINATION_ID) {
      return parseFloat(this.state.fields.freightDelivery.inload.quantity.value) > parseFloat(this.unplannedContractTonnage());
    } else if (baseEntity.weightId === WEIGHT_ORIGIN_ID) {
      return parseFloat(this.state.fields.freightPickup.outload.quantity.value) > parseFloat(this.unplannedContractTonnage());
    } else {
      return false;
    }
  }

  unplannedContractTonnage() {
    const baseEntity = this.state.selectedBaseEntity;
    const plannedTonnage = get(this.editMovement, 'plannedTonnage', 0);
    const parentUnaccountedTonnage = get(this.editMovement, 'parentUnaccountedTonnage', 0);
    const unaccountedTonnage = parseFloat(get(baseEntity, 'unaccountedTonnage', 0));

    return this.manualComplete ?
      this.maximumTonnage() :
      parseFloat
        (
          parseFloat((unaccountedTonnage || parentUnaccountedTonnage) + plannedTonnage).toFixed(2)
        );
  }

  unplannedMinimumTonnage() {
    const baseEntity = this.state.selectedBaseEntity;
    return parseFloat(parseFloat(get(baseEntity, 'minimumTonnage', 0)).toFixed(2));
  }

  maximumTonnage() {
    const baseEntity = this.state.selectedBaseEntity;
    return parseFloat(parseFloat(get(baseEntity, 'maximumTonnage', 0)).toFixed(2));
  }

  valid(fields) {
    return every(fields, this.isValidField);
  }

  isValidField(field) {
    return has(field, 'errors') ? field.errors.length === 0 : true;
  }

  async getContractsList(entityId = null) {
    if (isEmpty(this.state.contracts)) {
      this.props.dispatch(setLoadingText('Getting eligible contracts and orders list...'));
      this.setState({ didFetchedContracts: true });
      let contractsService = APIService.contracts();

      let queryParams = '?status=open&status=in_progress&status=confirmed&status=delivered&status=completed&status=invoiced&status=paid'
      if(this.isDuplicatingMovement && entityId)
        queryParams = `${queryParams}&entity_id=${entityId}`;

      contractsService.appendToUrl(`minimal/movement-creatable/${queryParams}`);
      const contracts = await contractsService.get(this.props.userToken, {'REFERER-UNIT' : getCountryMovementDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true}, null);
      this.setState({ contracts: contracts });
    }
  }

  async getOrdersList(includeGrainOrders, setLoaderText = true, entityId = null) {
    if (isEmpty(this.state.orders)) {
      if (setLoaderText)
        this.props.dispatch(setLoadingText('Getting eligible orders list...'));
      this.setState({ didFetchedOrders: true });
      let queryParams = 'hasCommodityContract=true';
      if (!includeGrainOrders)
        queryParams = 'commodity_contract_id__isnull=True&external_reference_number__isnull=True';
      if(this.isDuplicatingMovement && entityId)
        queryParams = `${queryParams}&entity_id=${entityId}`;

      let orders = await APIService.freights().orders().appendToUrl(`minimal/movement-creatable/?${queryParams}`).get(this.props.userToken, {'REFERER-UNIT' : getCountryMovementDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true}, null);

      orders = map(orders, item => {
        return { ...item, referenceNumber: item.identifier, entity: 'order' };
      });

      this.setState({ orders: orders });
    }
  }

  async handleMovementTypeChange(value) {
    if (!this.props.isLoading && (!this.isEditForm || get(this.editMovement, 'customerId'))) {
      this.props.dispatch(isLoading(null, 100000000));
    }
    this.resetDistanceAndTimeState();
    if (value !== this.state.fields.typeId.value) {
      const newState = { ...this.state };
      this.resetFields(newState);
      newState.fields.typeId.value = value;
      newState.isDependent = true;
      if (!this.applyingBaseEntity) {
        newState.customerNgrs = [];
        newState.freightPickupNgrs = [];
        newState.freightDeliveryNgrs = [];
      }
      newState.fields.customer.companyId.value = undefined;
      newState.fields.customer.contactId.value = undefined;
      newState.fields.customer.contactId.errors = [];
      newState.fields.customer.ngrId.value = undefined;
      newState.fields.customer.ngrId.errors = [];
      if (value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER) {
        if (!includes(this.props.location.search, 'commodityContractId=')) {
          await this.getContractsList();
        }
        if (!includes(this.props.location.search, 'orderId=')) {
          await this.getOrdersList(true, false);
        }
        newState.filterCustomer = undefined;
        newState.filterCustomerObject = undefined;
        newState.filterParty = undefined;
        newState.filterPartyObject = undefined;
        newState.filterProvider = undefined;
        newState.baseEntities = this.state.contracts.concat(this.state.orders);
        newState.filteredEntities = newState.baseEntities;
        newState.fields.baseEntityId.value = undefined;
        newState.fields.baseEntityId.validators = [required(), selected()];
        newState.fields.season.validators = [];
        newState.fields.commodityId.validators = [];
        newState.fields.plannedGradeId.validators = [];
        newState.fields.customer.companyId.validators = [];
        newState.fields.customer.contactId.validators = [];
      } else {
        if (!includes(this.props.location.search, 'orderId=')) {
          await this.getOrdersList(false);
        }
        newState.filterCustomer = undefined;
        newState.filterCustomerObject = undefined;
        newState.filterParty = undefined;
        newState.filterPartyObject = undefined;
        newState.filterProvider = undefined;
        newState.baseEntities = this.state.orders;
        newState.filteredEntities = newState.baseEntities;
        newState.selectedBaseEntity = null;
        newState.selectedBaseEntityRaw = null;
        newState.fields.baseEntityId.value = undefined;
        newState.fields.baseEntityId.validators = [];
        newState.fields.season.validators = [required()];
        newState.fields.commodityId.validators = [required()];
        newState.fields.plannedGradeId.validators = newState.fields.status.value === 'delivered' ? [] : [required(), selected()]
        newState.fields.customer.companyId.validators = [required(), selected()];
        newState.fields.customer.contactId.validators = [required(), selected()];
        newState.fields.freightPickup.date.validators = [required()];
        newState.fields.freightDelivery.date.validators = [required()];
      }
      if (!isNil(newState.fields.typeId.value)) {
        if (newState.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY && !newState.fields.status.value)
          this.disableForm = true;
        else
          this.disableForm = false;
      }
      this.setConsignorConsigneeValidators(newState);
      this.setState(newState, () => {
        this.setFieldErrors('typeId', value);
        this.handleSideDrawer();
      });
      if (!this.isEditForm || !includes(this.props.location.search, 'commodityContractId=') || !includes(this.props.location.search, 'orderId=')) {
        this.props.dispatch(loaded());
        this.props.dispatch(setLoadingText(null));
      }
    }
    this.props.dispatch(forceStopLoader());
  }

  shouldShowMaximumAllowedTonnageForDeliveredStatus(type) {
    if (this.hasSelectedBaseEntity()) {
      if (get(this.state, 'selectedBaseEntity.entity') === 'commodityContract') {
        if (type === 'inload') {
          return get(this.state, 'selectedBaseEntity.weightId') === WEIGHT_DESTINATION_ID;
        } else if (type === 'outload') {
          return get(this.state, 'selectedBaseEntity.weightId') === WEIGHT_ORIGIN_ID;
        }
      } else if (get(this.state, 'selectedBaseEntity.entity') === 'order') {
        const selectedOrder = find(this.state.orders, { id: this.state.selectedBaseEntity.id }) || this.state.selectedBaseEntity;
        const weighId = get(selectedOrder, 'commodityContract.weighId') || get(this.state.selectedBaseEntity, 'weightId');
        if (selectedOrder && selectedOrder.commodityContractId) {
          if (type === 'inload') {
            return weighId === WEIGHT_DESTINATION_ID;
          } else if (type === 'outload') {
            return weighId === WEIGHT_ORIGIN_ID;
          }
        } else if (selectedOrder && !selectedOrder.commodityContractId) {
          return type === 'inload';
        }
      }
    }
  }

  maximumAllowedTonnage() {
    if (this.hasSelectedBaseEntity()) return this.unplannedContractTonnage();
  }

  minimumAllowedTonnage() {
    if (this.hasSelectedBaseEntity()) return this.unplannedMinimumTonnage();
  }

  manualCompleteTonnageLabel() {
    let label = '';
    if (this.hasSelectedBaseEntity() && !this.isMeterCubeCommodityCommodity()) {
      const maxTonnage = this.maximumAllowedTonnage();
      const minTonnage = this.minimumAllowedTonnage();
      if (maxTonnage && minTonnage) label += ` (Allowed: ${minTonnage} - ${maxTonnage} ${this.priceUnit()})`;
    }

    return label;
  }

  manualCompleteQuantityLabel() {
    let label = '';
    if (this.hasSelectedBaseEntity() && this.isMeterCubeCommodityCommodity()) {
      const maxTonnage = this.maximumAllowedTonnage();
      const minTonnage = this.minimumAllowedTonnage();
      if (maxTonnage && minTonnage) label += ` (Allowed: ${minTonnage} - ${maxTonnage} ${this.getSelectedCommodity()?.unit})`;
    }

    return label;
  }

  maximumAllowedTonnageLabel() {
    if (this.hasSelectedBaseEntity() && !this.isMeterCubeCommodityCommodity()) {
      return ` (Maximum Allowed ${this.tonnageLabel}: ${this.unplannedContractTonnage()} ${this.priceUnit()})`;
    } else {
      return '';
    }
  }

  maximumAllowedQuantityLabel() {
    if (this.hasSelectedBaseEntity() && this.isMeterCubeCommodityCommodity()) {
      const commodity = this.getSelectedCommodity()
      return ` (Maximum Allowed ${commodity?.quantityLabel}: ${this.unplannedContractTonnage()} ${commodity?.unit})`;
    } else {
      return '';
    }
  }

  getQuantityFieldErrors(id) {
    let errors = []
    if (this.hasSelectedBaseEntity() && this.isMeterCubeCommodityCommodity()) {
      let value = get(this.state.fields, `${id}.value`)
      if (parseFloat(value) > this.unplannedContractTonnage()) {
        if (get(this.state.selectedBaseEntity, 'entity') == 'order')
          errors.push(this.EXCESS_ORDER_QUANTITY_ERROR_MESSAGE)
        else
          errors.push(this.EXCESS_CONTRACT_QUANTITY_ERROR_MESSAGE)
      }
      else if (this.manualComplete && parseFloat(value) < this.unplannedMinimumTonnage())
        errors.push(this.LESS_CONTRACT_QUANTITY_ERROR_MESSAGE);
      else
        errors = [];
    }
    return errors;
  }

  handleQuantityFieldChange(event) {
    let id = event.target.id;
    this.setFieldValue(id, event.target.value, true, () => {
      const newState = {...this.state};
      set(newState.fields, `${id}.errors`, this.getQuantityFieldErrors(id) || []);
      this.setState(newState);
    });
  }

  validatePlannedTonnage = (id, value, newState) => {
    let isNewStateEmpty = isEmpty(newState);
    let state = isNewStateEmpty ? { ...this.state } : newState;

    const consignorOrderBookingOn = get(state, 'selectedConsignor.isSlotOrderBookingOn');
    const consigneeOrderBookingOn = get(state, 'selectedConsignee.isSlotOrderBookingOn');
    const isBothSitesOrderBookingOn = consignorOrderBookingOn && consigneeOrderBookingOn;
    const commonMinimumTonnage = isBothSitesOrderBookingOn ? max([get(state, 'selectedConsignor.minimumTonnage'), get(state, 'selectedConsignee.minimumTonnage')]) : null

    let errorMessage = '';
    let minTonnage = 0
    if (commonMinimumTonnage && value < commonMinimumTonnage) {
      minTonnage = commonMinimumTonnage;
      errorMessage = `Tonnage can't be less than minimum tonnage required ${commonMinimumTonnage}`;
    } else if (consignorOrderBookingOn && value < get(state, 'selectedConsignor.minimumTonnage')) {
      minTonnage = get(state, 'selectedConsignor.minimumTonnage');
      errorMessage = `Tonnage can't be less than minimum tonnage required ${minTonnage}`;
    } else if (consigneeOrderBookingOn && value < get(state, 'selectedConsignee.minimumTonnage')) {
      minTonnage = get(state, 'selectedConsignee.minimumTonnage');
      errorMessage = `Tonnage can't be less than minimum tonnage required ${minTonnage}`;
    }

    const validators = errorMessage
        ? [required(), valueAbove(minTonnage || 0, errorMessage)]
        : [required(), valueAbove(0)];

    set(state.fields, `${id}.errors`, errorMessage ? [errorMessage] : []);
    set(state.fields, `${id}.validators`, validators);

    if (isNewStateEmpty)
      this.setState(state);
  }

  handleTonnageFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    this.setFieldErrors(event.target.id, event.target.value);
    this.validatePlannedTonnage(event.target.id, event.target.value);
    if (this.hasSelectedBaseEntity() && !this.isMeterCubeCommodityCommodity()) {
      const newState = { ...this.state };
      if (parseFloat(event.target.value) > this.unplannedContractTonnage()) {
        if (get(this.state.selectedBaseEntity, 'entity') == 'order') {
          newState.fields[event.target.id].errors = [this.EXCESS_ORDER_TONNAGE_ERROR_MESSAGE];
        } else {
          newState.fields[event.target.id].errors = [this.EXCESS_CONTRACT_TONNAGE_ERROR_MESSAGE];
        }
      } else if (this.manualComplete && parseFloat(event.target.value) < this.unplannedMinimumTonnage()) {
        newState.fields[event.target.id].errors = [this.LESS_CONTRACT_TONNAGE_ERROR_MESSAGE];
      } else {
        newState.fields[event.target.id].errors = [];
      }
      this.setState(newState);
    }
    this.fillAndVerifyRequiredFields(true);
  }

  appendGeneralConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.generalConditions.value))
      newState.fields.generalConditions.value = newState.selectedGeneralConditionText;
    else if (!includes(newState.fields.generalConditions.value, newState.selectedGeneralConditionText))
      newState.fields.generalConditions.value = newState.fields.generalConditions.value + '\n\n' + newState.selectedGeneralConditionText;
    this.setState(newState);
  }

  appendSpecialConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.specialConditions.value))
      newState.fields.specialConditions.value = newState.selectedSpecialConditionText;
    else if (!includes(newState.fields.specialConditions.value, newState.selectedSpecialConditionText))
      newState.fields.specialConditions.value = newState.fields.specialConditions.value + '\n\n' + newState.selectedSpecialConditionText;
    this.setState(newState);
  }

  handleConditionSelector(value, id, chosenItem) {
    var key = null;
    if (id === 'generalConditionsSelector') {
      key = 'selectedGeneralConditionText';
    } else if (id === 'specialConditionsSelector') {
      key = 'selectedSpecialConditionText';
    }
    const newState = { ...this.state };
    if (chosenItem) {
      newState[key] = chosenItem.details;
      newState[id] = value;
    } else {
      newState[key] = undefined;
      newState[id] = undefined;
    }
    this.setState(newState);
  }

  isMeterCubeCommodityCommodity(commodityId) {
    const _id = commodityId || this.state.fields.commodityId.value;
    return get(this.getSelectedCommodity(_id), 'isStrictQuantityBased')
  }

  getCommodityLimitWarningMessage() {
    let warningMessage = this.hasQuantityBasedCommodity() ? this.quantityLabelForForm() : this.getTonnageLabel();
    warningMessage = warningMessage.replace(/[()]/g, '').replace(/^\w+\s/, '');
    return `Warning: ${warningMessage}`;
  }

  fillAndVerifyRequiredFields(preventUndefinedInload = false) {
    const storage = this.state.fields.freightPickup.selectedContainer;
    if (storage) {
      const commodities = get(this.state.containerStats, 'commodities');
      const ngrId = get(this.state.containerStats, 'info.ngrId');
      const containerTare = get(this.state.containerStats, 'info.containerTare');
      const newState = { ...this.state };
      const commodityKey = get(keys(commodities), '0');
      if (this.state.fields.status.value === 'planned') {
        newState.fields.commodityId.value = get(commodities, `${commodityKey}.info.id`);
        newState.fields.plannedGradeId.value = get(commodities, `${commodityKey}.info.gradeId`);
        newState.fields.varietyId.value = get(commodities, `${commodityKey}.info.varietyId`);
        newState.fields.season.value = get(commodities, `${commodityKey}.info.season`);
        newState.fields.plannedTonnage.value = get(commodities, `${commodityKey}.total`);
        newState.fields.customer.ngrId.value = ngrId;
      }
      if (this.state.fields.status.value === 'delivered') {
        newState.fields.commodityId.value = get(commodities, `${commodityKey}.info.id`);
        newState.fields.season.value = get(commodities, `${commodityKey}.info.season`);
        newState.fields.freightPickup.outload.season.value = get(commodities, `${commodityKey}.info.season`);
        newState.fields.freightPickup.outload.gradeId.value = get(commodities, `${commodityKey}.info.gradeId`);
        newState.fields.freightPickup.outload.ngrId.value = ngrId;
        newState.fields.freightPickup.outload.containerTareWeight.value = containerTare;
        newState.fields.customer.ngrId.value = ngrId;
        newState.fields.freightPickup.netWeight.value = get(commodities, `${commodityKey}.total`);
        if (get(this.state.selectedBaseEntity, 'isPickupOrder')) {
          if (!preventUndefinedInload) {
            newState.fields.freightDelivery.inload.gradeId.value = undefined;
            newState.fields.freightDelivery.inload.containerTareWeight.value = undefined;
            newState.fields.freightDelivery.netWeight.value = undefined;
            newState.fields.freightDelivery.inload.ngrId.value = undefined;
            newState.fields.freightDelivery.inload.season.value = undefined;
          }
        }
        else {
          newState.fields.freightDelivery.inload.gradeId.value = get(commodities, `${commodityKey}.info.gradeId`);
          newState.fields.freightDelivery.inload.ngrId.value = ngrId;
          newState.fields.freightDelivery.inload.containerTareWeight.value = containerTare;
          newState.fields.freightDelivery.netWeight.value = get(commodities, `${commodityKey}.total`);
          newState.fields.freightDelivery.inload.season.value = get(commodities, `${commodityKey}.info.season`);
        }
      }
      newState.fields.freightDelivery.consignee.site.locationId.validators = [];
      newState.fields.freightDelivery.consignee.site.locationType.validators = [];
      newState.fields.containerNumber.value = storage.name;
      this.setState(newState);
    }
  }

  fetchContainerStats() {
    const container = this.state.fields.freightPickup.selectedContainer;
    if (container) {
      APIService
        .farms(container.farmId)
        .appendToUrl('storage-stats/')
        .get(this.props.userToken, null, { storage_id: container.id, include_grade_season: true })
        .then(response => {
          if (!isNull(response)) {
            const containerCommodities = get(get(response, 'non-empty'), container.id);
            this.setState({ containerStats: containerCommodities }, () => {
              this.fillAndVerifyRequiredFields();
            });
          }
        });
    }
  }

  async handleSiteChange(value, id, item) {
    var collectionKey = null;
    var addressKey = null;
    if (id.search('consignor') > -1) {
      collectionKey = 'consignorSites';
      addressKey = 'consignorAddress';
    } else if (id.search('consignee') > -1) {
      collectionKey = 'consigneeSites';
      addressKey = 'consigneeAddress';
    }
    if (item) {
      set(this.state, addressKey + '.value', item.address);
      this.setFieldValue(id.replace('locationId', 'locationType'), item.entity);
      this.fetchDistance();

      if (includes(id, 'consignor')) {
        const newState = { ...this.state };
        this.changeFreightPickupTareWeightGrossWeightValidators(newState);
        this.changeFreightPickupGradeValidators(newState);
        if (get(item, 'type') === 'container') {
          newState.fields.freightPickup.selectedContainer = item;
          newState.fields.freightPickup.outload.tareWeight.value = undefined
          newState.fields.freightPickup.outload.grossWeight.value = undefined
          newState.fields.freightDelivery.inload.tareWeight.value = undefined
          newState.fields.freightDelivery.inload.grossWeight.value = undefined
        }
        else {
          newState.fields.containerNumber.value = undefined;
          newState.fields.freightPickup.selectedContainer = undefined;
          newState.fields.freightPickup.outload.containerTareWeight.value = undefined;
          newState.fields.freightDelivery.inload.containerTareWeight.value = undefined;
        }
        this.setState(newState, () => {
          this.getModelSpecsByStorageSpecs()
          if (get(item, 'type') === 'container') {
            this.fetchContainerStats();
          }
        });
      }
    } else {
      this.setFieldValue(id.replace('locationId', 'locationType'), null);
      const newState = { ...this.state };
      newState.fields.containerNumber.value = undefined;
      newState.fields.freightPickup.selectedContainer = undefined;
      newState.fields.freightPickup.outload.containerTareWeight.value = undefined;
      newState.fields.freightDelivery.inload.containerTareWeight.value = undefined;
      if (includes(id, 'consignor')) {
        this.changeFreightPickupTareWeightGrossWeightValidators(newState);
        this.changeFreightPickupGradeValidators(newState);
      }
      this.setState(newState);
      this.resetDistanceAndTimeState();
    }
    if (collectionKey === 'consignorSites') {
      set(this.state, 'selectedConsignorSite', item);
    } else if (collectionKey === 'consigneeSites') {
      set(this.state, 'selectedConsigneeSite', item);
    }
    this.handleSelectFieldChange(value, id);
  }

  changeFreightPickupTareWeightGrossWeightValidators = newState => {
    if (newState.fields.status.value === 'delivered' && !this.isEditForm) {
      newState.fields.freightPickup.netWeight.validators = [required(), valueAbove(this.state.minimumTonnage)];
      if (
        !newState.fields.freightPickup.outload.tareWeight.value &&
        !newState.fields.freightPickup.outload.grossWeight.value &&
        newState.fields.freightPickup.consignor.site.locationType.value === 'farmfield' &&
        (get(newState.selectedBaseEntity, 'weightId') !== WEIGHT_ORIGIN_ID ||
          (get(newState, 'selectedBaseEntity.entity') !== 'contract' && !get(newState, 'selectedBaseEntity.commodityContractId')))
      ) {
        newState.fields.freightPickup.netWeight.validators = [valueAbove(this.state.minimumTonnage)];
        newState.fields.freightPickup.netWeight.errors = [];
      }
    } else {
      newState.fields.freightPickup.netWeight.validators = [valueAbove(this.state.minimumTonnage)];
      newState.fields.freightPickup.netWeight.errors = [];
    }
  };

  changeFreightPickupGradeValidators = newState => {
    if (newState.fields.status.value === 'delivered' && !this.isEditForm) {
      newState.fields.freightPickup.outload.gradeId.validators = [required()];
    } else {
      newState.fields.freightPickup.outload.gradeId.validators = [];
      newState.fields.freightPickup.outload.gradeId.errors = [];
    }
  };

  handleConsignorInputChange = (event, value) => {
    this.setState({ consignorInputText: value });
  };

  handleConsigneeInputChange = (event, value) => {
    this.setState({ consigneeInputText: value });
  };

  handleConsignorChange(event, item) {
    const id = 'freightPickup.consignor.handlerId';
    const value = get(item, 'id');
    if (value && includes(['planned', 'confirmed', 'open'], this.state.fields.status.value)) {
      if (!this.canCreatePlannedMovement(item.companyId, item)) {
        alertifyjs.error("Planned Movements for this site can only be created from Slot Booking", 5);
        return;
      }
    }
    this.resetDistanceAndTimeState();
    let changedConsignors = null;
    if (item && item.inputValue) {
      this.handleFarmSideDrawer("consignorSideDrawer", true);
      this.setState({
        inputTextFarm: item.inputValue
      });
      return;
    } else if (item) {
      const newState = { ...this.state };
      newState.selectedConsignorId = null;
      newState.selectedConsignor = item;
      if (find(this.state.consignors, {id: value})) {
        let consignors = filter(this.state.consignors, consignor => consignor.id != value)
        newState.consignors = uniqBy([...consignors, item], 'id');
      } else
        newState.consignors = uniqBy([...this.state.consignors, item], 'id');
      changedConsignors = newState.consignors
      this.setState(newState);
    }
    if (
      this.state.fields.freightPickup.consignor.handlerId.value != value
    ) {
      const newState = { ...this.state };
      newState.selectedConsignor = item;
      newState.fields.freightPickup.consignor.site.locationId.value = null;
      newState.fields.freightPickup.consignor.site.locationType.value = null;
      newState.fields.freightPickup.consignor.handlerId.value = value;
      if(changedConsignors)
        newState.consignors = changedConsignors;
      if (isObject(item)) {
        if (this.state.fields.status.value === DELIVERED_STATUS) {
          if (newState.fields.freightPickup.netWeight.value)
            this.validateNetWeight(newState.fields.freightPickup.netWeight.value, 'freightPickup', newState);
          if(newState.fields.freightDelivery.netWeight.value)
            this.validateNetWeight(newState.fields.freightDelivery.netWeight.value, 'freightDelivery', newState);
        } else if (this.isPlannedMovement() && newState.fields.plannedTonnage.value) {
          this.validatePlannedTonnage('plannedTonnage', newState.fields.plannedTonnage.value, newState);
        }
        if (!this.applyingBaseEntity || (this.isEditForm && this.state.isPopulated)) {
          this.getSites(value, 'farm', 'consignor');
        }
      } else {
        newState.selectedConsignor = null;
        newState.consignorSites = [];
        newState.freightPickupNgrs = [];
      }
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
        this.fetchSlotsIfNeeded('freightPickup.date');
        this.checkForDateMsg('freightPickup.date');
        this.setSellerAvailableTonnage();
        if (!this.applyingBaseEntity) {
          this.getFreightPickupNgrs();
        }
        this.setLoadVarietyMandatory();
        this.fetchDistance();
      });
    }
  }

  setCommoditySpecs(){
    const newState = { ...this.state };
    const commoditySpecs = get(find(this.props.commodities, { id: this.state.fields.commodityId.value }), 'specs', []);
    const modelSpecs = this.getModelSpecsByCommoditySpecs(commoditySpecs, this.state.fields.commodityId.value);
    newState.fields.freightDelivery.inload.specs = cloneDeep(modelSpecs);
    this.setState(newState);
  }

  setGradeSpecs = modelName => {
    const value = get(this.state.fields, `${modelName}.gradeId.value`)
    const commodityGrades = get(
      find(this.props.commodities, { id: this.state.fields.commodityId.value }),
      'grades',
      []
    );
    const gradeSpecs = get(
      find(commodityGrades, { id: value }),
      'specs',
      []
    );
    const gtaCode = get(
      find(commodityGrades, { id: value}),
      'gtaCode',
      []
    );
    if (value) {
      const updatedSpecs = gtaCode ? gradeSpecs : [];
      if (modelName === 'freightPickup.outload')
        this.setState(
          state => ({
            ...state,
            outloadGradeSpecs: updatedSpecs
          }),
          () => {
            forEach(gradeSpecs, (specCode) => 
              this.setFieldWarnings(`fields.${modelName}.specs.${specCode['code']}`, specCode['code'])
            );
          }
        );
      else
        this.setState(
          state => ({
            ...state,
            inloadGradeSpecs: updatedSpecs
          }),
          () => {
            forEach(gradeSpecs, (specCode) => 
              this.setFieldWarnings(`fields.${modelName}.specs.${specCode['code']}`, specCode['code'])
            );
          }
        );
    }
  }

  handleConsigneeChange(event, item) {
    const id = 'freightDelivery.consignee.handlerId';
    const value = get(item, 'id');
    if (value && includes(['planned', 'confirmed', 'open'], this.state.fields.status.value)) {
      if (!this.canCreatePlannedMovement(item.companyId, item)) {
        alertifyjs.error("Planned Movements for this site can only be created from Slot Booking", 5);
        return;
      }
    }
    this.resetDistanceAndTimeState();
    let changedConsignees = null;
    if (item && item.inputValue) {
      this.handleFarmSideDrawer("consigneeSideDrawer", true);
      this.setState({
        inputTextFarm: item.inputValue
      });
      return;
    } else if (item) {
      const newState = { ...this.state };
      newState.selectedConsigneeId = null;
      newState.selectedConsignee = item;
      if (find(this.state.consignees, {id: value})) {
        let consignees = filter(this.state.consignees, consignee => consignee?.id != value)
        newState.consignees = uniqBy([...consignees, item], 'id');
      } else
        newState.consignees = uniqBy([...this.state.consignees, item], 'id');

      if (get(item.deliveryMandatoryFields, 'docketNumber') && this.isDeliveredMovement()){
        newState.fields.freightDelivery.inload.docketNumber.validators = [required()]
      }
      else{
        newState.fields.freightDelivery.inload.docketNumber.validators = []
      }

      changedConsignees = newState.consignees;
      this.setState(newState, this.setCommoditySpecs);
    }
    if (
      this.state.fields.freightDelivery.consignee.handlerId.value != value
    ) {
      const newState = { ...this.state };
      if(changedConsignees)
        newState.consignees = changedConsignees;
      newState.selectedConsignee = item
      newState.fields.freightDelivery.consignee.site.locationId.value = null;
      newState.fields.freightDelivery.consignee.site.locationType.value = null;
      newState.fields.freightDelivery.consignee.handlerId.value = value;
      if (isObject(item)) {
        if (this.state.fields.status.value === DELIVERED_STATUS) {
          if(newState.fields.freightDelivery.netWeight.value)
            this.validateNetWeight(newState.fields.freightDelivery.netWeight.value, 'freightDelivery', newState);
          if(newState.fields.freightPickup.netWeight.value)
            this.validateNetWeight(newState.fields.freightPickup.netWeight.value, 'freightPickup', newState);
        } else if (this.isPlannedMovement() && newState.fields.plannedTonnage.value) {
          this.validatePlannedTonnage('plannedTonnage', newState.fields.plannedTonnage.value, newState);
        }
        if (!this.applyingBaseEntity) {
          this.getSites(value, 'farm', 'consignee');
        }
      } else {
        newState.selectedConsignee = null;
        newState.consigneeSites = [];
        newState.freightDeliveryNgrs = [];
      }
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
        this.fetchSlotsIfNeeded('freightDelivery.date');
        this.checkForDateMsg('freightDelivery.date');
        if (!this.applyingBaseEntity) {
          this.getFreightDeliveryNgrs();
        }
        this.setLoadVarietyMandatory();
        this.fetchDistance();
      });
    }
  }

  canCreatePlannedMovement(siteCompanyId, site) {
    let siteCompany = find(this.props.providers, {id: siteCompanyId});
    return !(get(siteCompany, 'hasExternalBookingEnabled') && get(site, 'isSlotOrderBookingOn'));
  }

  async getSites(handlerId, partyType, getSitesFor) {
    let sites = [];
    if (partyType == 'farm') {
      let storageId = getSitesFor == 'consignor' ? get(this.editMovement, 'freightPickup.consignor.sites[0].locationId') : get(this.editMovement, 'freightDelivery.consignee.sites[0].locationId');
      let queryString = '';
      if (storageId)
        queryString = this.isEditForm ? `?amend&storageId=${storageId}` : '';
      queryString = queryString ? queryString + '&with_containers=true' : '?with_containers=true';
      let companyId = this.state.outloadStockOwner || this.state.fields.customer.companyId.value;
      if (companyId)
        queryString = queryString + `&company_id=${companyId}`;
      let homeStorages = await APIService.farms(handlerId)
        .appendToUrl('storages/home/minimal/').appendToUrl(queryString)
        .get(this.props.userToken);
      let farmFields = await APIService.farms(handlerId)
        .farm_fields()
        .get(this.props.userToken);

      homeStorages = map(homeStorages, storage => {
        return merge(storage, {
          id: storage.id,
          entity: 'storage',
          storageType: 'home',
        });
      });

      farmFields = map(farmFields, ff => {
        return merge(ff, { entity: 'farmfield' });
      });

      sites = farmFields.concat(homeStorages);
    } else {
      let companySites = await APIService.companies(handlerId)
        .company_sites()
        .get(this.props.userToken);
      sites = map(companySites, site => {
        return merge(site, { entity: 'companysite' });
      });
      const selectedTrack = get(this.state.selectedBaseEntity, 'trackName');
      if (selectedTrack && getSitesFor == 'consignee') {
        sites = filter(sites, site => {
          if (site.isBhc) {
            return includes(site.tracks, selectedTrack);
          } else {
            return site;
          }
        });
      }
    }
    if (getSitesFor == 'consignor') {
      this.setState({
        consignorSites: sites,
      }, () => this.updateSelectedStorage('consignor'));
    } else if (getSitesFor == 'consignee') {
      this.setState({
        consigneeSites: sites,
      }, () => this.updateSelectedStorage('consignee'));
    }
  }

  updateSelectedStorage(checkpointType) {
    const newState = {...this.state};
    if (checkpointType === 'consignor' && this.state.selectedConsignorSite)
      newState.selectedConsignorSite = find(this.state.consignorSites, {id: this.state.selectedConsignorSite.id});
    if (checkpointType === 'consignee' && this.state.selectedConsigneeSite)
      newState.selectedConsigneeSite = find(this.state.consigneeSites, {id: this.state.selectedConsigneeSite.id});
    this.setState(newState);
  }

  setSelectedProviderTruck = truck => {
    const newState = { ...this.state };
    if(!find(this.state.selectedTrucks, {id : truck.id}))
      newState.selectedTrucks.push(truck);
    if (this.isDeliveredMovement() && this.isRegoSearchable()) {
      const newState = { ...this.state };
      if (get(truck, 'id')) {
        let category = find(this.state.categories, { id: get(truck, 'categoryId') });
        newState.pickupCode.value = get(category, 'truckCode');
        newState.fields.freightPickup.outload.categoryId.value = get(truck, 'categoryId');
        newState.pickupMassLimitPermit.value = get(category, 'massLimitPermit');
        newState.pickupGHMS.value = get(category, 'ghms');
        newState.fields.freightPickup.outload.steer_1_point_1.value = get(truck, 'steer1Point1');
        newState.fields.freightPickup.outload.steer_point_5.value = get(truck, 'steerPoint5');
        newState.deliveryCode.value = get(category, 'truckCode');
        newState.fields.freightDelivery.inload.categoryId.value = get(truck, 'categoryId');
        newState.deliveryMassLimitPermit.value = get(category, 'massLimitPermit');
        newState.deliveryGHMS.value = get(category, 'ghms');
        newState.fields.freightDelivery.inload.steer_1_point_1.value = get(truck, 'steer1Point1');
        newState.fields.freightDelivery.inload.steer_point_5.value = get(truck, 'steerPoint5');
      } else {
        newState.pickupCode.value = '';
        newState.fields.freightPickup.outload.categoryId.value = '';
        newState.pickupMassLimitPermit.value = '';
        newState.pickupGHMS.value = false;
        newState.fields.freightPickup.outload.steer_1_point_1.value = false;
        newState.fields.freightPickup.outload.steer_point_5.value = false;
        newState.deliveryCode.value = '';
        newState.fields.freightDelivery.inload.categoryId.value = '';
        newState.deliveryMassLimitPermit.value = '';
        newState.deliveryGHMS.value = false;
        newState.fields.freightDelivery.inload.steer_1_point_1.value = false;
        newState.fields.freightDelivery.inload.steer_point_5.value = false;
      }
    }
    this.setState(newState, () => {
      this.setCategory('freightDelivery.inload');
      this.setCategory('freightPickup.outload');
    });
  };

  handleProviderChange(value, id, item) {
    const newState = { ...this.state };
 
    if(!(this.isDuplicatingMovement && value == get(this.editMovement, 'providerId'))){
      newState.fields.assignToId.value = null;
      newState.fields.plannedTruckId.value = null;
      newState.fields.freightPickup.outload.truckId.value = null;
      newState.fields.freightDelivery.inload.truckId.value = null;
    }
    newState.fields.providerId.value = value;
    newState.fields.freightPickup.searchOption = isNull(value) ? SEARCH_BY_ALL_REGOS : SEARCH_BY_FREIGHT_PROVIDER_REGOS;
    newState.fields.freightDelivery.searchOption = isNull(value) ? SEARCH_BY_ALL_REGOS : SEARCH_BY_FREIGHT_PROVIDER_REGOS;
    newState.fields.freightPickup.outload.truckOwnerId.value = value;
    newState.fields.freightDelivery.inload.truckOwnerId.value = value;
    newState.fields.freightPickup.outload.truckOwner.value = item;
    newState.fields.freightDelivery.inload.truckOwner.value = item;
    let isSelf = false;
    if (item) {
      if (newState.fields.customer.companyId.value) {
        const customer = get(newState.fields.customer, 'company');
        if (customer && customer.id === item.id) {
          isSelf = true;
        }
      }
    } else {
      newState.providerTrucks.length = 0;
      newState.providerEmployees.length = 0;
    }
    newState.fields.isSelf.value = isSelf;
    this.setValidatorsForIsSelf(isSelf, newState);
    this.setConsignorConsigneeValidators(newState);
    this.setState(newState, () => {
      this.setFieldErrors(id, value, () => {
        this.handleSideDrawer(null, () => {
          if (!this.isFetchingFreightProviderEmployeesOrTrucks) {
            this.isFetchingFreightProviderEmployeesOrTrucks = true;
            this.getCompanyEmployees(this.state.fields.providerId.value, null, () => {
              this.getProviderTrucks(this.state.fields.providerId.value, () => {
                this.isFetchingFreightProviderEmployeesOrTrucks = false;
              });
              this.getTruckOwnerTrucks(this.state.fields.providerId.value, true);
              this.getTruckOwnerTrucks(this.state.fields.providerId.value, false);
              this.setCurrentUserAsContactIfApplicable(item, 'assignToId');
            });
          }
        });
      });
    });
  }

  setCurrentUserAsContactIfApplicable(company, fieldToSet) {
    const { currentUser } = this.props;
    if (this.isEditForm)
      return;

    if (get(this.state.fields, fieldToSet + '.value'))
      return;

    if (get(currentUser, 'companyId') === get(company, 'id')) {
      const newState = { ...this.state };
      set(newState.fields, fieldToSet + '.value', get(currentUser, 'id'));
      this.setState(newState);
    }
  }

  async getProviderTrucks(companyId, callback) {
    const trucks = companyId
      ? await APIService.companies(companyId)
        .trucks()
        .get(this.props.userToken)
      : [];
    this.setState({ providerTrucks: trucks }, () => {
      if (callback) {
        callback();
      }
    });
  }

  async getCompanyEmployees(companyId, providerContactId = '', callback) {

    const employees = companyId
      ? await APIService.companies(companyId)
        .employees()
        .get(this.props.userToken)
      : [];
    if (!providerContactId) {
      providerContactId = this.isEditForm && this.state.isPopulated ? this.getKeyContactId(employees, companyId, 'company') : null;
    }
    const newState = { ...this.state };
    newState.providerEmployees = employees;
    if (this.applyingBaseEntity && !providerContactId) {
      providerContactId = get(this.state, 'selectedBaseEntity.assignToId');
    }
    if(this.isDuplicatingMovement && !providerContactId)
      providerContactId = get(this.editMovement, 'assignToId');

    if (providerContactId) {
      newState.fields.assignToId.value = providerContactId;
      newState.fields.assignToId.errors = [];
    }

    this.setState(
      newState,
      () => {
        if (callback) {
          callback();
        }
      },
    );
  }



  async handleCommodityChange(value, id) {
    if (this.state.fields.commodityId.value != value) {
      const commoditySpecs = orderBy(get(find(this.props.commodities, { id: value }), 'specs', []), 'order');
      const modelSpecs = this.getModelSpecsByCommoditySpecs(commoditySpecs, value);
      const newState = { ...this.state };
      if (this.hasQuantityBasedCommodity(value)) {
        if (!this.isDeliveredMovement()) {
          newState.fields.quantity.validators = [required()];
          newState.fields.quantity.errors = [];
          newState.fields.freightPickup.outload.quantity.validators = [];
          newState.fields.freightDelivery.inload.quantity.validators = [];
          newState.fields.freightPickup.outload.quantity.errors = [];
          newState.fields.freightDelivery.inload.quantity.errors = [];
          newState.fields.freightPickup.outload.quantity.value = undefined;
          newState.fields.freightDelivery.inload.quantity.value = undefined;
        } else {
          newState.fields.freightPickup.outload.quantity.validators = [required()];
          newState.fields.freightDelivery.inload.quantity.validators = [required()];
          newState.fields.quantity.validators = [];
          newState.fields.quantity.value = undefined;
          newState.fields.quantity.errors = [];
        }
      }
      newState.commoditySpecs = commoditySpecs;
      newState.fields.commodityId.value = value;
      newState.fields.varietyId.value = null;
      newState.fields.freightPickup.outload.gradeId.value = null;
      newState.fields.freightDelivery.inload.gradeId.value = null;
      newState.fields.freightPickup.outload.specs = cloneDeep(modelSpecs);
      newState.fields.freightDelivery.inload.specs = cloneDeep(modelSpecs);
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
        this.checkDeliveryNetWeightAgainstRemainingSpace();
        this.getModelSpecsByStorageSpecs();
      });
    }
  }

  handleVarietyChange(value) {
    if (this.state.fields.varietyId.value != value) {
      const newState = { ...this.state };
      newState.fields.varietyId.value = value;
      if (this.isDeliveredMovement() && !this.isEditForm) {
        newState.fields.freightPickup.outload.varietyId.value = value;
        newState.fields.freightDelivery.inload.varietyId.value = value;
      }
      this.setState(newState);
    }
  }

  handleGradeChange(valueObject, id) {
    this.setFieldValue(id, valueObject.id, false, () => {
      if (id === 'freightDelivery.inload.gradeId') {
        this.checkDeliveryNetWeightAgainstRemainingSpace();
      }
      else {
        this.setSellerAvailableTonnage();
      }
    });
    this.setFieldErrors(id, valueObject.id);
    this.fillAndVerifyRequiredFields(true)
    this.setGradeSpecs('freightDelivery.inload');
    this.setGradeSpecs('freightPickup.outload');
  }

  handleOutloadVarietyChange = value => {
    if (this.state.fields.freightPickup.outload.varietyId.value != value) {
      const newState = { ...this.state };
      newState.fields.freightPickup.outload.varietyId.value = value;
      this.setState(newState);
    }
  };

  handleInloadVarietyChange = value => {
    if (this.state.fields.freightDelivery.inload.varietyId.value != value) {
      const newState = { ...this.state };
      newState.fields.freightDelivery.inload.varietyId.value = value;
      this.setState(newState);
    }
  };

  handleStatusFieldChange(value) {
    const newState = { ...this.state };
    newState.fields.status.value = value;
    const isBookingSlots = this.shouldFetchSlotsFor('Pickup') || this.shouldFetchSlotsFor('Delivery');
    if (value && this.state.fields.typeId.value)
      this.disableForm = false;
    if (value === 'planned' || (this.isEditForm && this.shouldShowCheckpointDetails())) {
      newState.pickupTerm = 'Pickup';
      newState.deliveryTerm = 'Delivery';
      if (!this.isDuplicatingMovement && !isBookingSlots) {
        newState.fields.freightPickup.date.value = undefined;
        newState.fields.freightPickup.date.errors = [];
        newState.fields.freightDelivery.date.value = undefined;
        newState.fields.freightDelivery.date.errors = [];
      }
      newState.fields.freightDelivery.inload.truckId.validators = [];
      newState.fields.freightDelivery.inload.tareWeight.validators = [valueAbove(0)];
      newState.fields.freightDelivery.inload.grossWeight.validators = [valueAbove(0)];
      newState.fields.freightDelivery.netWeight.validators = [valueAbove(0)];
      newState.fields.freightPickup.outload.truckId.validators = [];
      newState.fields.plannedTruckId.validators = [required(), selected()];
      newState.fields.plannedTonnage.validators = [required(), valueAbove(0)];
      newState.fields.plannedGradeId.validators = [required(), selected()];
      newState.fields.freightPickup.outload.varietyId.value = null;
      newState.fields.freightDelivery.inload.varietyId.value = null;
      newState.fields.freightPickup.outload.season.value = null;
      newState.fields.freightDelivery.inload.season.value = null;
      newState.fields.freightDelivery.inload.gradeId.validators = [];
      newState.fields.freightPickup.outload.gradeId.validators = [];
      newState.fields.freightDelivery.inload.season.validators = [];
      newState.fields.freightPickup.outload.season.validators = [];
      newState.fields.freightDelivery.inload.ngrId.validators = [];
      newState.fields.freightPickup.outload.ngrId.validators = [];
      if(this.state.selectedBaseEntity) {
        const dateValidators = [required()];
        if(!isEmpty(this.state.selectedBaseEntity.deliveryStartDate))
          dateValidators.push(minDate(this.state.selectedBaseEntity.deliveryStartDate, 'The order is not valid for this date', 'error'));
        if(!isEmpty(this.state.selectedBaseEntity.deliveryEndDate))
          dateValidators.push(maxDate(this.state.selectedBaseEntity.deliveryEndDate, 'The order is not valid for this date', 'error'));

        newState.fields.freightPickup.date.validators = this.shouldFetchSlotsFor('Pickup') ? dateValidators : [required()]
        newState.fields.freightDelivery.date.validators = this.shouldFetchSlotsFor('Delivery') ? dateValidators : [required()]
      }
      if (this.hasQuantityBasedCommodity()) {
        newState.fields.freightDelivery.inload.quantity.validators = [];
        newState.fields.freightPickup.outload.quantity.validators = [];
        newState.fields.freightPickup.outload.quantity.errors = [];
        newState.fields.freightDelivery.inload.quantity.errors = [];
        newState.fields.freightPickup.outload.quantity.value = undefined;
        newState.fields.freightDelivery.inload.quantity.value = undefined;
        newState.fields.quantity.validators = [required()];
      }
      if (this.isUserNonDeliveryOnus() && !isBookingSlots) {
        const provider = this.props.currentUser.company;
        if (provider && !SYSTEM_COMPANY_IDS.includes(provider.id)) {
          set(newState, 'providerItems', [provider]);
          set(newState.fields, 'providerId.value', provider);
          this.handleProviderChange(provider.id, 'providerId', provider);
        }
      }
    } else if (value === 'delivered' && !this.isEditForm) {
      newState.pickupTerm = 'Outload';
      newState.deliveryTerm = 'Inload';
      if (!this.isDuplicatingMovement && !isBookingSlots) {
        newState.fields.freightPickup.date.value = undefined;
        newState.fields.freightPickup.date.errors = [];
        newState.fields.freightDelivery.date.value = undefined;
        newState.fields.freightDelivery.date.errors = [];
      }
      const regoRegex = MESSAGES.REGO_REGEX.replace('$regoLabel', getCountryLabel('rego'))
      newState.fields.freightDelivery.inload.truckId.validators = [required(), selected(), minLength(4, regoRegex), maxLength(10, regoRegex), truckRegoRegex()];
      newState.fields.freightDelivery.inload.gradeId.validators = [required(), selected()];
      newState.fields.freightPickup.outload.gradeId.validators = [required(), selected()];
      newState.fields.freightDelivery.inload.season.validators = [required()];
      newState.fields.freightPickup.outload.season.validators = [required()];
      newState.fields.freightDelivery.inload.ngrId.validators = [required()];
      newState.fields.freightPickup.outload.ngrId.validators = [required()];
      newState.fields.freightPickup.outload.truckId.validators = [required(), selected(), minLength(4, regoRegex), maxLength(10, regoRegex), truckRegoRegex()];
      newState.fields.plannedTruckId.validators = [];
      newState.fields.plannedTonnage.validators = [valueAbove(0)];
      newState.fields.plannedGradeId.validators = [];
      if (this.state.selectedBaseEntity) {
        const dateValidators = [required()];
        let minDateErrorMessage = `Delivery start date in ${this.state.selectedBaseEntity?.entity === 'order' ? 'order' : 'contract'} is ${moment(this.state.selectedBaseEntity?.deliveryStartDate).format(this.state.countryFormats.date)}`
        let maxDateErrorMessage = `Delivery end date in ${this.state.selectedBaseEntity?.entity === 'order' ? 'order' : 'contract'} is ${moment(this.state.selectedBaseEntity?.deliveryEndDate).format(this.state.countryFormats.date)}`

        if (!isEmpty(this.state.selectedBaseEntity?.deliveryStartDate) && !some(dateValidators, validator => includes(validator.message, minDateErrorMessage)))
          dateValidators.push(minDate(this.state.selectedBaseEntity?.deliveryStartDate, minDateErrorMessage, 'warning'));
        if (!isEmpty(this.state.selectedBaseEntity?.deliveryEndDate) && !some(dateValidators, validator => includes(validator.message, maxDateErrorMessage)))
          dateValidators.push(maxDate(this.state.selectedBaseEntity?.deliveryEndDate, maxDateErrorMessage, 'warning'));
        newState.fields.freightPickup.date.validators = dateValidators;
        newState.fields.freightDelivery.date.validators = dateValidators;
      }
      if (this.hasQuantityBasedCommodity()) {
        newState.fields.quantity.validators = [];
        newState.fields.quantity.value = undefined;
        newState.fields.quantity.errors = [];
        newState.fields.freightDelivery.inload.quantity.validators = [required()];
        newState.fields.freightPickup.outload.quantity.validators = [required()];
      }
      if (this.isUserNonDeliveryOnus() && !isBookingSlots) {
        if (!this.isSelectedBaseEntityAnOrder() || (this.isSelectedBaseEntityAnOrder() && this.isSelectedOrderCallOnGrain())) {
          set(newState, 'providerItems', []);
          set(newState.fields, 'providerId.value', null);
          this.handleProviderChange(null, 'providerId', null);
        }
      }
      const consignorHandler = newState.fields.freightPickup.consignor.handlerId
      this.getFieldErrors(consignorHandler, consignorHandler.value, 'freightPickup.consignor.handlerId');

      const consigneeHandler = newState.fields.freightDelivery.consignee.handlerId
      this.getFieldErrors(consigneeHandler, consigneeHandler.value, 'freightDelivery.consignee.handlerId');
    } else if (this.isEditForm && !this.shouldShowCheckpointDetails()) {
      newState.fields.plannedTruckId.validators = [];
      newState.fields.plannedTonnage.validators = [];
    }
    this.changeFreightPickupTareWeightGrossWeightValidators(newState);
    this.changeFreightPickupGradeValidators(newState);
    this.setState(newState, () => {
      if (this.state.fields.freightPickup.selectedContainer != undefined) {
        this.fillAndVerifyRequiredFields();
      }
      if (this.isDeliveredMovement() && this.state.fields.freightPickup.date.value) {
        if (isEmpty(this.state.permits))
          this.fetchPermits();
        if (isEmpty(this.state.categories))
          this.fetchCategories();
      }
      if (this.isPlannedMovement())
        this.setSlotErrorsIfRequired();
      this.handleSelectFieldChange(value, 'status');
      if (!this.applyingBaseEntity) {
        this.getFreightPickupNgrs();
        this.getFreightDeliveryNgrs();
      }
      this.getSlotBookingForBoth('trucks', this.state.fields.providerId.value);
      this.getSlotBookingForBoth('employees', this.state.fields.providerId.value);

    });

    if(!this.isDuplicatingMovement)
      setTimeout(() => {
        if (this.isDeliveredMovement()) {
          const newState = { ...this.state };
          newState.fields.freightPickup.outload.varietyId.value = get(newState.selectedBaseEntity, 'varietyId');
          newState.fields.freightDelivery.inload.varietyId.value = get(newState.selectedBaseEntity, 'varietyId');
          newState.fields.freightPickup.outload.season.value = get(newState.selectedBaseEntity, 'season');
          newState.fields.freightDelivery.inload.season.value = get(newState.selectedBaseEntity, 'season');
          newState.fields.freightPickup.outload.gradeId.value = get(newState.selectedBaseEntity, 'plannedGradeId');
          newState.fields.freightDelivery.inload.gradeId.value = get(newState.selectedBaseEntity, 'plannedGradeId');
          if (this.state.isPickupSiteCompanyHandler) {
            newState.fields.freightDelivery.inload.varietyId.value = null;
            newState.fields.freightDelivery.inload.season.value = null;
            newState.fields.freightDelivery.inload.gradeId.value = null;
            newState.fields.freightDelivery.inload.ngrId.value = null;
            newState.fields.freightDelivery.inload.season.validators = [];
            newState.fields.freightDelivery.inload.ngrId.validators = [];
          }
          if (this.state.isDeliverySiteCompanyHandler) {
            newState.fields.freightPickup.outload.varietyId.value = null;
            newState.fields.freightPickup.outload.season.value = null;
            newState.fields.freightPickup.outload.gradeId.value = null;
            newState.fields.freightPickup.outload.ngrId.value = null;
            newState.fields.freightPickup.outload.season.validators = [];
            newState.fields.freightPickup.outload.ngrId.validators = [];
          }
          this.setState(newState);
        }
      }, 1000);
  }

  checkForSitesWithExternalBooking() {
    if (includes(['planned', 'confirmed', 'open'], this.state.fields.status.value)) {
      let checkpointsWithErrors = [];
      if (this.state.fields.freightPickup.consignor.handlerId.value) {
        let consignor = this.state.selectedConsignor;
        if (!consignor)
          consignor = find(this.state.consignors, {id: this.state.fields.freightPickup.consignor.handlerId.value});
        if (consignor) {
          if (!this.canCreatePlannedMovement(consignor.companyId, consignor)) {
            checkpointsWithErrors.push("pickup")
          }
        }
      }
      if (this.state.fields.freightDelivery.consignee.handlerId.value) {
        let consignee = this.state.selectedConsignee;
        if (!consignee)
          consignee = find(this.state.consignees, {id: this.state.fields.freightDelivery.consignee.handlerId.value})
        if (consignee) {
          if (!this.canCreatePlannedMovement(consignee.companyId, consignee)) {
            checkpointsWithErrors.push("delivery");
          }
        }
      }
      if (!isEmpty(checkpointsWithErrors)) {
        alertifyjs.error(`Planned Movements for ${checkpointsWithErrors.join(', ')} site can only be created from Slot Booking`, 5);
        return false;
      }
      return true;
    }
    return true;
  }

  freightProviderFieldCheck = (newState) => {
    if (this.isUserNonDeliveryOnus()) {
      newState.fields.providerId.validators = [];
      newState.fields.assignToId.validators = [];
      newState.fields.invoicing.validators = [];
      newState.fields.rateFreightOut.validators = [];
    }
  };

  getSMSettings = async (companyId, checkpoint) => {
    let settings = checkpoint == 'Pickup' ? 'pickupSMSettings' : 'deliverySMSettings';
    APIService.companies(companyId)
    .appendToUrl('site-management/settings/')
    .get()
    .then(res => this.setState({[settings]: res}));
  }

  getTrailerBookingSiteIds = settings => map(settings, i => toNumber(i));

  isTrailerBookingEnabledForSite(checkpoint) {
    let siteId = checkpoint == 'Pickup' ?  this.state.selectedConsignorId :  this.state.selectedConsigneeId;
    let trailerBookingSiteIds = checkpoint == 'Pickup' ? this.getTrailerBookingSiteIds(this.state.pickupSMSettings?.trailerBooking) : this.getTrailerBookingSiteIds(this.state.deliverySMSettings?.trailerBooking);
    return includes(trailerBookingSiteIds, siteId)
  }

  async handleBaseEntityChange(value, id, item, setFieldErrors = true) {
    let order = null;
    if (!this.isEditForm) {
      this.resetDistanceAndTimeState();
      let shouldCallProviderChange = false;
      if (item && this.state.fields.baseEntityId.value !== item.id) {
        if (!this.props.isLoading) {
          this.props.dispatch(isLoading());
        }
        this.applyingBaseEntity = true;
        let baseEntity = null;
        if (item.entity === 'contract') {
          let contract = item;
          if (!includes(this.props.location.search, 'commodityContractId=')) {
            contract = await APIService.contracts(item.id).get(this.props.userToken, {'REFERER-UNIT' : this.priceUnit(), 'REFERER-UNIT-FOR-REQUEST': true});
            this.parentContract = contract;
          }

          baseEntity = this.getBaseEntityFromItem(contract);
          baseEntity.entityType = 'contract';
        } else {
          order = item;
          if (!includes(this.props.location.search, 'orderId=')) {
            order = await APIService.freights().orders(item.id).get(this.props.userToken, {'REFERER-UNIT' : this.priceUnit(), 'REFERER-UNIT-FOR-REQUEST': true});
            order.entity = 'order';
            order.referenceNumber = order.identifier;
            this.props.dispatch(forceStopLoader());
          }
          baseEntity = this.getBaseEntityFromItem(order);
          baseEntity.entityType = 'order'
          shouldCallProviderChange = this.orderId && baseEntity && baseEntity.provider;
        }
        const commoditySpecs = orderBy(get(find(this.props.commodities, { id: baseEntity.commodityId }), 'specs', []), 'order');
        const modelSpecs = this.getModelSpecsByCommoditySpecs(commoditySpecs, baseEntity.commodityId);
        const newState = { ...this.state };
        newState.setFieldErrors = setFieldErrors;
        this.resetFields(newState);
        newState.fields.baseEntityId.value = value;
        newState.selectedBaseEntity = baseEntity;
        newState.selectedBaseEntityRaw = baseEntity;
        newState.fields.season.value = baseEntity.season;
        newState.fields.freightDelivery.inload.season.value = baseEntity.season;
        newState.fields.freightPickup.outload.season.value = baseEntity.season;
        newState.fields.commodityId.value = baseEntity.commodityId;
        newState.commoditySpecs = commoditySpecs;
        newState.customerContact = baseEntity.customerContact;
        newState.fields.providerId.value = baseEntity.providerId;
        newState.fields.freightPickup.consignor.handlerId.value = baseEntity.consignorHandlerId;
        newState.fields.freightDelivery.consignee.handlerId.value = baseEntity.consigneeHandlerId;
        if (baseEntity?.consignors)
          baseEntity.consignors = Array.isArray(baseEntity.consignors) ? baseEntity.consignors : [baseEntity.consignors];
        if (baseEntity?.consignees)
          baseEntity.consignees = Array.isArray(baseEntity.consignees) ? baseEntity.consignees : [baseEntity.consignees];
        if (baseEntity?.consignorHandlerCompanyId)
          this.getSMSettings(baseEntity.consignorHandlerCompanyId, 'Pickup');
        if (baseEntity?.consigneeHandlerCompanyId)
          this.getSMSettings(baseEntity.consigneeHandlerCompanyId, 'Delivery');

        newState.fields.customer.company = baseEntity.customerCompany;
        newState.fields.customer.companyId.value = baseEntity.customerCompanyId;
        newState.fields.customer.companyId.validators = [];
        newState.fields.customer.contactId.validators = [];
        newState.fields.paymentTermId.value = get(baseEntity.customerCompany, 'paymentTermId');
        newState.seller = baseEntity.seller;
        newState.buyer = baseEntity.buyer;
        newState.isCustomerIsBuyer = baseEntity.isCustomerIsBuyer;
        newState.isCustomerIsSeller = baseEntity.isCustomerIsSeller;
        newState.fields.freightPickup.outload.specs = cloneDeep(modelSpecs);
        newState.fields.freightDelivery.inload.specs = cloneDeep(modelSpecs);
        newState.fields.freightPickup.orderNumber.value = get(baseEntity, 'pickupOrderNumber');
        newState.fields.freightDelivery.orderNumber.value = get(baseEntity, 'deliveryOrderNumber');
        newState.fields.freightPickup.instructions.value = get(baseEntity, 'pickupInstructions');
        newState.fields.freightDelivery.instructions.value = get(baseEntity, 'deliveryInstructions');
        newState.didFetchedContracts = false;
        newState.didFetchedOrders = false;
        if (get(baseEntity, 'currency') || get(baseEntity, 'commodityContract.currency'))
          newState.currency = baseEntity.currency || get(baseEntity, 'commodityContract.currency');
        if (this.manualComplete) {
          newState.fields.freightPickup.date.value = baseEntity.deliveryStartDate;
          newState.fields.freightDelivery.date.value = baseEntity.deliveryEndDate;
        }
        if (item.entity === 'contract' && this.manualComplete) {
          if (this.isMeterCubeCommodityCommodity()) {
            newState.fields.quantity.value = get(baseEntity, 'quantity');
            newState.fields.quantity.validators = [required()];
            newState.fields.plannedTonnage.validators = [];
          } else {
            newState.fields.plannedTonnage.validators = [required()];
            newState.fields.plannedTonnage.value = get(baseEntity, 'tonnage');
            newState.fields.quantity.validators = [];
          }
          newState.fields.freightPickup.timeStart.value = "12:00:00";
          newState.fields.freightDelivery.timeStart.value = "12:00:00";
        }
        if (order && isEqual(order.typeId, REQUEST_ORDER_TYPE.PICKUP_ORDER)) {
          if (isEqual(this.props.userCompanyId, baseEntity.consignorHandlerCompanyId)) {
            newState.isPickupSiteCompanyHandler = true;
            newState.isDeliverySiteCompanyHandler = false;
          }
        } else if (order && isEqual(order.typeId, REQUEST_ORDER_TYPE.DELIVERY_ORDER)) {
          if (isEqual(this.props.userCompanyId, baseEntity.consigneeHandlerCompanyId)) {
            newState.isPickupSiteCompanyHandler = false;
            newState.isDeliverySiteCompanyHandler = true;
          }
        }

        this.setConsignorConsigneeValidators(newState);
        newState.setFieldErrors = true;
        this.setState(newState, () => {
          if (shouldCallProviderChange)
            this.handleProviderChange(baseEntity.providerId, 'providerId', baseEntity.provider);
        });
        setTimeout(() => {
          const newState = { ...this.state };
          newState.fields.freightPickup.consignor.site.locationId.validators = [required()];
          newState.fields.freightDelivery.consignee.site.locationId.validators = [required()];
          newState.fields.freightDelivery.consignee.site.locationId.value = baseEntity.consigneeLocationId;
          newState.fields.freightDelivery.consignee.site.locationType.value = baseEntity.consigneeLocationType;
          newState.fields.freightDelivery.consignee.site.address = baseEntity.consigneeSiteAddress;
          newState.consignorAddress = { value: get(baseEntity, 'consignorSites.0.location.address') };
          newState.consigneeAddress = { value: get(baseEntity, 'consigneeSites.0.location.address') };
          newState.fields.varietyId.value = baseEntity.varietyId;
          this.freightProviderFieldCheck(newState);
          this.setState(newState, () => {
            const newState = { ...this.state };
            newState.fields.plannedGradeId.value = baseEntity.plannedGradeId;
            newState.fields.plannedGradeId.errors = [];
            this.setState(newState);
          });
        }, 1000);
        setTimeout(() => {
          const newState = { ...this.state };
          if (baseEntity.consigneeSites && baseEntity.consigneeSites.length > 0) {
            newState.consigneeSites = map(baseEntity.consigneeSites, function (item) {
              return {
                id: item.location.id,
                name: item.location.name,
                companyId: item.location.companyId,
                tracks: item.location.tracks,
                entity: item.location.entity
              };
            });
          }
          const dateValidators = [required()];
          let minDateErrorMessage = `Delivery start date in ${baseEntity?.entity === 'order' ? 'order' : 'contract'} is ${moment(baseEntity?.deliveryStartDate).format(this.state.countryFormats.date)}`
          let maxDateErrorMessage = `Delivery end date in ${baseEntity?.entity === 'order' ? 'order' : 'contract'} is ${moment(baseEntity?.deliveryEndDate).format(this.state.countryFormats.date)}`
          if (!isEmpty(baseEntity?.deliveryStartDate) && !some(dateValidators, validator => includes(validator.message, minDateErrorMessage)))
            dateValidators.push(minDate(baseEntity?.deliveryStartDate, minDateErrorMessage, 'warning'));
          if (!isEmpty(baseEntity?.deliveryEndDate) && !some(dateValidators, validator => includes(validator.message, maxDateErrorMessage)))
            dateValidators.push(maxDate(baseEntity?.deliveryEndDate, maxDateErrorMessage, 'warning'));

          const plannedDateValidators = [required(), minDate(baseEntity?.deliveryStartDate, 'The order is not valid for this date', 'error'), maxDate(baseEntity?.deliveryEndDate, 'The order is not valid for this date', 'error')];

          newState.fields.freightPickup.date.validators = this.shouldFetchSlotsFor('Pickup') ? plannedDateValidators : dateValidators;
          newState.fields.freightDelivery.date.validators = this.shouldFetchSlotsFor('Delivery') ? plannedDateValidators : dateValidators;
          this.setState(newState);
        }, 200);
        let consignorIds = map(baseEntity?.consignors, consignor => consignor?.id)
        let consigneeIds = map(baseEntity?.consignees, consignee => consignee?.id)
        let handlerIds = compact([...consignorIds, ...consigneeIds])
        if (handlerIds.length > 0) {
          handlerIds = handlerIds.join(',');
          await APIService.contracts().handlers().get(null, null, {handler_ids: handlerIds}).then(res => {
            let items = res?.handlers || []
            this.setState({handlers: uniqBy([...this.state.handlers, ...items], 'id'), consignors: filter(res?.handlers, handler => includes(consignorIds, handler.id)), consignees: filter(res?.handlers, handler => includes(consigneeIds, handler.id))})
          })
        }
        if (baseEntity.customerCompanyId) {
          let fetchContactsFor = 'companies';
          if (fetchContactsFor) {
            await this.getCustomerContacts(newState.fields.customer.company, null, null, null, baseEntity.customerRepresentedById);
          }
          if (baseEntity.customerContactId && this.state.customerContacts) {
            const newState = { ...this.state };
            newState.fields.customer.contactId.value = baseEntity.customerContactId;
            newState.fields.customer.contactId.validators = [];
            this.setState(newState);
          }
          await this.getCustomerNgrs();
        }

        if (baseEntity.consignorHandlerId) {
          const selectedConsignor = find(this.state.consignors, {
            id: baseEntity.consignorHandlerId,
          }) || { id: baseEntity.consignorHandlerId, name: baseEntity.consignorHandlerName };
          if (baseEntity.consignorHandlerId) {
            await Promise.all([this.getSites(baseEntity.consignorHandlerId, 'farm', 'consignor')]);
            const newState = { ...this.state };
            newState.selectedConsignor = selectedConsignor;
            const showFreightPickupOrDeliverySection = (!this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails()));
            if (!isSystemCompany() && showFreightPickupOrDeliverySection && get(selectedConsignor, 'stocksManagement') == true && this.props.currentUser.companyId != get(selectedConsignor, 'companyId') && this.isDeliveredMovement())
              newState.fields.freightPickup.consignor.handlerId.errors = [LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created')]
            newState.selectedConsignorId = baseEntity.consignorHandlerId;
            newState.fields.freightPickup.consignor.handlerId.value = baseEntity.consignorHandlerId;
            newState.fields.freightPickup.consignor.site.locationId.errors = [];
            const isPickupSiteGate = get(baseEntity, 'consignorSites.0.location.isGate');
            if (baseEntity.consignorLocationId) {
              newState.isPickupSiteGate = isPickupSiteGate;
              newState.fields.freightPickup.consignor.site.locationId.value = baseEntity.consignorSites[0].locationId;
              newState.fields.freightPickup.consignor.site.locationType.value = baseEntity.consignorSites[0].locationContentType;
              const selectedConsignorSite = find(this.state.consignorSites, {
                id: baseEntity.consignorSites[0].locationId,
                entity: baseEntity.consignorSites[0].locationContentType
              });
              if (selectedConsignorSite) {
                newState.selectedConsignorSite = selectedConsignorSite;
              }
              newState.fields.freightPickup.consignor.site.address = baseEntity.consigneeSiteAddress;
              newState.consignorAddress = { value: get(baseEntity, 'consignorSites.0.location.address') };
            }
            this.setState(newState, () => this.fetchDistance());
          }
        }
        else if (baseEntity.isDeliveryOrder) {
          const newState = {...this.state};
          newState.fields.freightPickup.consignor.handlerId.validators = [];
          this.setState(newState);
        }

        if (baseEntity.consigneeHandlerId) {
          const selectedConsignee = find(this.state.consignees, {
            id: baseEntity.consigneeHandlerId,
          }) || { id: baseEntity.consigneeHandlerId, name: baseEntity.consigneeHandlerName };
          if (baseEntity.consigneeHandlerId) {
            await Promise.all([this.getSites(baseEntity.consigneeHandlerId, 'farm', 'consignee')]);
            const newState = { ...this.state };
            newState.selectedConsignee = selectedConsignee;
            const showFreightPickupOrDeliverySection = (!this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails()));
            if (!isSystemCompany() && showFreightPickupOrDeliverySection && get(selectedConsignee, 'stocksManagement') == true && this.props.currentUser.companyId != get(selectedConsignee, 'companyId') && this.isDeliveredMovement())
              newState.fields.freightDelivery.consignee.handlerId.errors = [LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created')];
            newState.selectedConsigneeId = baseEntity.consigneeHandlerId;
            newState.fields.freightDelivery.consignee.handlerId.value = baseEntity.consigneeHandlerId;
            newState.fields.freightDelivery.consignee.site.locationId.errors = [];
            const isDeliverySiteGate = get(baseEntity, 'consigneeSites.0.location.isGate');
            if (baseEntity.consigneeLocationId && !isDeliverySiteGate) {
              newState.isDeliverySiteGate = isDeliverySiteGate;
              newState.fields.freightDelivery.consignee.site.locationId.value = baseEntity.consigneeSites[0].locationId;
              newState.fields.freightDelivery.consignee.site.locationType.value = baseEntity.consigneeSites[0].locationContentType;
              const selectedConsigneeSite = find(this.state.consigneeSites, {
                id: baseEntity.consigneeSites[0].locationId,
                entity: baseEntity.consigneeSites[0].locationContentType
              });
              if (selectedConsigneeSite) {
                newState.selectedConsigneeSite = selectedConsigneeSite;
              }
              newState.fields.freightDelivery.consignee.site.address = baseEntity.consigneeSiteAddress;
              newState.consigneeAddress = { value: get(baseEntity, 'consigneeSites.0.location.address') };
            }
            this.setState(newState, () => this.fetchDistance());
          }
        }
        else if (baseEntity.isPickupOrder) {
          const newState = {...this.state};
          newState.fields.freightDelivery.consignee.handlerId.validators = [];
          this.setState(newState);
        }

        await this.getFreightPickupNgrs();
        await this.getFreightDeliveryNgrs();
        if (this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
          const newState = { ...this.state };
          newState.fields.freightPickup.outload.varietyId.value = baseEntity.varietyId;
          newState.fields.freightDelivery.inload.varietyId.value = baseEntity.varietyId;
          if (this.state.isPickupSiteCompanyHandler) {
            newState.fields.freightDelivery.inload.varietyId.value = null;
          }
          if (this.state.isDeliverySiteCompanyHandler) {
            newState.fields.freightPickup.outload.varietyId.value = null;
          }
          this.setState(newState);
        } else {
          if (this.isDeliveredMovement() && !this.isEditForm) {
            const newState = { ...this.state };
            if (baseEntity.varietyId) {
              newState.fields.freightPickup.outload.varietyId.value = baseEntity.varietyId;
              newState.fields.freightDelivery.inload.varietyId.value = baseEntity.varietyId;
            }
            if (baseEntity.season) {
              newState.fields.freightPickup.outload.season.value = baseEntity.season;
              newState.fields.freightDelivery.inload.season.value = baseEntity.season;
              newState.fields.freightPickup.outload.season.errors = [];
              newState.fields.freightDelivery.inload.season.errors = [];
            }
            this.setState(newState, ()=> {
              this.setGradeSpecs('freightDelivery.inload');
              this.setGradeSpecs('freightPickup.outload');
            });
          }
        }
        this.handleSideDrawer();
        this.applyingBaseEntity = false;
        this.isFetchingFreightOrder = false;
        this.isFetchingCommodityContract = false;
        if (!this.shouldFetchSlotsFor('Pickup') && !this.shouldFetchSlotsFor('Delivery'))
          this.props.dispatch(forceStopLoader());
      }
    }
  }

  getCompanyForFetchingNgr(fetchFor) {
    let establishmentIdKey = null;
    let establishmentTypeKey = null;
    let defaultNgrId = null;
    if (fetchFor === 'customer') {
      establishmentIdKey = 'fields.customer.companyId.value';
      establishmentTypeKey = 'company';
      defaultNgrId = get(this.state, 'selectedBaseEntity.customerNgrId');
    } else if (fetchFor === 'freightPickup') {
      establishmentIdKey = 'fields.freightPickup.consignor.handlerId.value';
      establishmentTypeKey = 'farm';
      if (this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
        if (get(this.state, 'selectedBaseEntity.actualCustomer')) {
          establishmentIdKey = 'selectedBaseEntity.actualCustomer.company.id';
          establishmentTypeKey = 'company';
          defaultNgrId = get(this.state, 'selectedBaseEntity.actualCustomer.ngr.id');
        } else {
          establishmentIdKey = 'fields.customer.companyId.value';
          establishmentTypeKey = 'company';
          defaultNgrId = get(this.state, 'fields.customer.ngrId.value');
        }
      } else {
        establishmentIdKey = 'selectedBaseEntity.seller.company.id';
        establishmentTypeKey = 'company';
        defaultNgrId = get(this.state, 'selectedBaseEntity.seller.ngrId');
      }
    } else if (fetchFor === 'freightDelivery') {
      establishmentIdKey = 'fields.freightDelivery.consignee.handlerId.value';
      establishmentTypeKey = 'farm';
      if (this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
        if (get(this.state, 'selectedBaseEntity.actualCustomer')) {
          establishmentIdKey = 'selectedBaseEntity.actualCustomer.company.id';
          establishmentTypeKey = 'company';
          defaultNgrId = get(this.state, 'selectedBaseEntity.actualCustomer.ngr.id');
        } else {
          establishmentIdKey = 'fields.customer.companyId.value';
          establishmentTypeKey = 'company';
          defaultNgrId = get(this.state, 'fields.customer.ngrId.value');
        }
      } else {
        establishmentIdKey = 'selectedBaseEntity.buyer.company.id';
        establishmentTypeKey = 'company';
        defaultNgrId = get(this.state, 'selectedBaseEntity.buyer.ngrId');
      }
    }
    if (establishmentIdKey && establishmentTypeKey) {
      return {
        establishmentId: get(this.state, establishmentIdKey),
        establishmentType: get(this.state, establishmentTypeKey),
        defaultNgrId: defaultNgrId,
      };
    }
  }

  resetFields(newState) {
    newState.fields.season.value = undefined;
    newState.fields.commodityId.value = undefined;
    newState.fields.varietyId.value = undefined;
    newState.fields.plannedGradeId.value = undefined;
    newState.fields.providerId.value = undefined;
    newState.fields.assignToId.value = undefined;
    newState.fields.season.errors = [];
    newState.fields.commodityId.errors = [];
    newState.fields.varietyId.errors = [];
    newState.fields.plannedGradeId.errors = [];
    newState.fields.providerId.errors = [];
    newState.fields.assignToId.errors = [];
    newState.fields.customer.companyId.validators = [required(), selected()];
    newState.fields.customer.contactId.validators = [required(), selected()];
    this.resetFreightPickup(newState);
    this.resetFreightDelivery(newState);
  }

  resetFreightPickup(newState) {
    if (!this.shouldFetchSlotsFor('Pickup'))
      newState.fields.freightPickup.date.value = undefined;
    newState.fields.freightPickup.outload.season.value = null;
    newState.fields.freightPickup.outload.ngrId.value = null;
    newState.fields.freightPickup.outload.varietyId.value = null;
    newState.fields.freightPickup.consignor.handlerId.value = undefined;
    newState.fields.freightPickup.consignor.site.locationId.value = undefined;
    newState.fields.freightPickup.date.errors = [];
    newState.fields.freightPickup.consignor.handlerId.errors = [];
    newState.fields.freightPickup.consignor.site.locationId.errors = [];
    newState.fields.freightPickup.consignor.site.locationId.validators = [required(), selected()];
  }

  resetFreightDelivery(newState) {
    if (!this.shouldFetchSlotsFor('Delivery'))
      newState.fields.freightDelivery.date.value = undefined;
    newState.fields.freightDelivery.inload.season.value = null;
    newState.fields.freightDelivery.inload.ngrId.value = null;
    newState.fields.freightDelivery.inload.varietyId.value = null;
    newState.fields.freightDelivery.consignee.handlerId.value = undefined;
    newState.fields.freightDelivery.consignee.site.locationId.value = undefined;
    newState.fields.freightDelivery.date.errors = [];
    newState.fields.freightDelivery.consignee.handlerId.errors = [];
    newState.fields.freightDelivery.consignee.site.locationId.errors = [];
    newState.fields.freightDelivery.consignee.site.locationId.validators = [required(), selected()];
  }

  async handleCustomerChange(value, id, item) {
    const isCustomerOnlyEdit = this.isEditForm && get(this.editMovement, 'typeId') === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY;
    if (!this.isEditForm || isCustomerOnlyEdit || !this.state.fields.customer.companyId.value) {
      const newState = { ...this.state };
      if (newState.fields.customer.companyId.value != value) {
        newState.fields.customer.contactId.value = null;
        if (!SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'customer.companyId'))) {
          newState.fields.customer.ngrId.value = null;
        }
        newState.fields.customer.company = null;
        newState.fields.customer.companyId.value = value;
        newState.fields.customer.representedById.value = get(item, 'representedById');
      }
      if (item) {
        newState.fields.customer.company = item;
        let isSelf = false;
        if (newState.fields.providerId.value) {
          if ((item.entity == 'farm' && newState.fields.providerId.value == item.companyId) || (item.entity == 'company' && newState.fields.providerId.value == item.id)) {
            isSelf = true;
          }
        }
        newState.fields.isSelf.value = isSelf;
        newState.fields.paymentTermId.value = get(item, 'paymentTermId') || get(item, 'company.paymentTermId');
        this.setValidatorsForIsSelf(isSelf, newState);
      } else {
        newState.fields.isSelf.value = false;
        newState.customerContacts = [];
        newState.customerNgrs = [];
        newState.freightPickupNgrs = [];
        newState.freightDeliveryNgrs = [];
        newState.fields.paymentTermId.value = undefined;
      }
      this.setConsignorConsigneeValidators(newState);
      this.setState(newState, () => {
        this.setLoadVarietyMandatory()
        this.setFieldErrors(id, value, () => {
          this.handleSideDrawer(null, () => {
            if (!this.applyingBaseEntity && !this.isFetchingCustomerContactsAndNgrs) {
              this.isFetchingCustomerContactsAndNgrs = true;
              this.getCustomerContacts(item, this.state.fields.customer.contactId.value, null, () => {
                this.getCustomerNgrs();
                this.getFreightPickupNgrs();
                this.getFreightDeliveryNgrs();
                this.setCurrentUserAsContactIfApplicable(item, 'customer.contactId');
                this.setCustomerContactAndNgr();
              });
            }
          });
        });
      });
    }
  }

  setCustomerContactAndNgr() {
    if (this.state.fields.customer.companyId.value) {
      const newState = { ...this.state };
      let customerContactId = newState.fields.customer.companyId.value == get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId')) ? get(this.state, 'sellerDetails.contactId', get(this.state.selectedBaseEntity, 'seller.contactId')) :
        newState.fields.customer.companyId.value == get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId')) ? get(this.state, 'buyerDetails.contactId', get(this.state.selectedBaseEntity, 'buyer.contactId')) : null;
      let customerContacts = newState.fields.customer.companyId.value == get(this.state, 'sellerDetails.companyId') ? get(this.state, 'sellerContacts') :
        newState.fields.customer.companyId.value == get(this.state, 'buyerDetails.companyId') ? get(this.state, 'buyerContacts') : null;
      newState.fields.customer.contactId.value = customerContactId;
      if (customerContacts)
        newState.customerContacts = customerContacts;
      if (!SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'customer.companyId'))) {
        let customerNgrId = newState.fields.customer.companyId.value == get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId')) ? get(this.state, 'sellerDetails.ngrId', get(this.state.selectedBaseEntity, 'seller.ngrId')) :
          newState.fields.customer.companyId.value == get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId')) ? get(this.state, 'buyerDetails.ngrId', get(this.state.selectedBaseEntity, 'buyer.ngrId')) : null;
        let customerNgrs = newState.fields.customer.companyId.value == get(this.state, 'sellerDetails.companyId') ? get(this.state, 'sellerNgrs') :
          newState.fields.customer.companyId.value == get(this.state, 'buyerDetails.companyId') ? get(this.state, 'buyerNgrs') : null;
        newState.fields.customer.ngrId.value = customerNgrId;
        if (customerNgrs)
          newState.customerNgrs = customerNgrs;
      }
      this.setState(newState);
    }
  }

  async getCustomerNgrs() {
    const customerPartyForNgr = this.getCompanyForFetchingNgr('customer');
    if (customerPartyForNgr && !SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'customer.companyId'))) {
      await this.getNgrs(get(customerPartyForNgr, 'establishmentId'), undefined, 'customer');
      if (get(customerPartyForNgr, 'defaultNgrId') && this.state.customerNgrs) {
        const newState = { ...this.state };
        newState.fields.customer.ngrId.value = get(customerPartyForNgr, 'defaultNgrId');
        newState.fields.customer.ngrId.validators = [];
        this.setState(newState);
      }
    }
  }


  getEntitiesForFetchingNgr(entity) {
    const { selectedBaseEntity, fields } = this.state;
    const result = {
      companyIds: [],
      farmIds: [],
      defaultNgrId: undefined,
    };
    if (fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
      result.companyIds.push(
        get(selectedBaseEntity, 'actualCustomer.companyId') ||
        fields.customer.companyId.value
      );
    }
    else if (get(selectedBaseEntity, 'entity') == 'contract' || get(selectedBaseEntity, 'commodityContract')) {
      if (entity === 'freightPickup') {
        result.companyIds = [get(selectedBaseEntity, 'seller.companyId')];
      }
      else if (entity === 'freightDelivery') {
        result.companyIds = [get(selectedBaseEntity, 'buyer.companyId')];
      }
    }
    else {
      result.companyIds = compact([
        get(selectedBaseEntity, 'seller.companyId'),
        get(selectedBaseEntity, 'buyer.companyId'),
      ]);
      result.farmIds = compact([
        fields.freightPickup.consignor.handlerId.value,
        fields.freightDelivery.consignee.handlerId.value,
      ]);
    }

    result.defaultNgrId = this.getDefaultNgrId(entity)

    return result;
  }

  getDefaultNgrId(entity) {
    const { selectedBaseEntity, fields } = this.state;
    let defaultNgrId;
    if (fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY)
      defaultNgrId = get(selectedBaseEntity, 'actualCustomer.ngr.id') || fields.customer.ngrId.value;
    else
      defaultNgrId = entity === 'freightPickup' ? get(selectedBaseEntity, 'seller.ngrId') : get(selectedBaseEntity, 'buyer.ngrId')
    return defaultNgrId
  }

  async getFreightPickupNgrs() {
    const result = await APIService
      .freights()
      .contracts()
      .appendToUrl('outloads/stock-owners/')
      .post({ movementId: get(this.editMovement, 'id'), orderId: get(this.editMovement, 'orderId') || this.orderId })
    const stockOwnerId = get(result, 'outload')
    let freightPickupPartyForNgr = null
    if (stockOwnerId)
      freightPickupPartyForNgr = { companyIds: [stockOwnerId], farmIds: [], defaultNgrId: this.getDefaultNgrId('freightPickup') }
    else
      freightPickupPartyForNgr = this.getEntitiesForFetchingNgr('freightPickup');
    if (!isEmpty([...freightPickupPartyForNgr.companyIds, ...freightPickupPartyForNgr.farmIds])) {
      await this.getNgrsForEntities(freightPickupPartyForNgr, undefined, 'freightPickup');
      const defaultNgrId = get(freightPickupPartyForNgr, 'defaultNgrId')
      if (defaultNgrId && map(this.state.freightPickupNgrs, 'id').includes(defaultNgrId)) {
        const newState = { ...this.state };
        newState.fields.freightPickup.outload.ngrId.value = defaultNgrId
        this.setState(newState);
      }
    }
  }

  async getFreightDeliveryNgrs() {
    const result = await APIService
      .freights()
      .contracts()
      .appendToUrl('inloads/stock-owners/')
      .post({ movementId: get(this.editMovement, 'id'), orderId: get(this.editMovement, 'orderId') || this.orderId })
    const stockOwnerId = get(result, 'inload')
    let freightDeliveryPartyForNgr = null
    if (stockOwnerId)
      freightDeliveryPartyForNgr = { companyIds: [stockOwnerId], farmIds: [], defaultNgrId: this.getDefaultNgrId('freightDelivery') }
    else
      freightDeliveryPartyForNgr = this.getEntitiesForFetchingNgr('freightDelivery');
    if (!isEmpty([...freightDeliveryPartyForNgr.companyIds, ...freightDeliveryPartyForNgr.farmIds])) {
      await this.getNgrsForEntities(freightDeliveryPartyForNgr, undefined, 'freightDelivery');
      const defaultNgrId = get(freightDeliveryPartyForNgr, 'defaultNgrId')
      if (defaultNgrId && map(this.state.freightDeliveryNgrs, 'id').includes(defaultNgrId)) {
        const newState = { ...this.state };
        newState.fields.freightDelivery.inload.ngrId.value = defaultNgrId
        this.setState(newState, () => {
          this.isFetchingCustomerContactsAndNgrs = false;
        });
      } else {
        this.isFetchingCustomerContactsAndNgrs = false;
      }
    } else {
      this.isFetchingCustomerContactsAndNgrs = false;
    }
  }

  async getNgrs(partyId, newlyCreatedNgr, getFor) {
    const ngrs = await this.getPartyNgrs([partyId]);
    if (getFor == 'freightPickup') {
      this.setState({
        freightPickupNgrs: ngrs
      });
    } else if (getFor == 'freightDelivery') {
      this.setState({
        freightDeliveryNgrs: ngrs
      });
    } else {
      const newState = { ...this.state };
      if (newlyCreatedNgr) {
        newState.fields.customer.ngrId.value = newlyCreatedNgr.id;
      }
      newState.customerNgrs = ngrs;
      this.setState(newState);
    }
  }

  async getNgrsForEntities(entityWrapper, newlyCreatedNgr, getFor) {
    const ngrs = await this.getPartyNgrs(entityWrapper.companyIds, entityWrapper.farmIds);
    if (getFor == 'freightPickup') {
      if (ngrs.length == 1) {
        const newState = { ...this.state };
        newState.freightPickupNgrs = ngrs;
        if (!this.state.isDeliverySiteCompanyHandler)
          newState.fields.freightPickup.outload.ngrId.value = ngrs[0].id;
        this.setState(newState);
      }
      else {
        this.setState({
          freightPickupNgrs: ngrs
        });
      }
    } else if (getFor == 'freightDelivery') {
      if (ngrs.length == 1) {
        const newState = { ...this.state };
        newState.freightDeliveryNgrs = ngrs;
        if (!this.state.isPickupSiteCompanyHandler)
          newState.fields.freightDelivery.inload.ngrId.value = ngrs[0].id;
        this.setState(newState);
      }
      else {
        this.setState({
          freightDeliveryNgrs: ngrs
        });
      }
    } else {
      const newState = { ...this.state };
      if (newlyCreatedNgr) {
        newState.fields.customer.ngrId.value = newlyCreatedNgr.id;
      }
      newState.customerNgrs = ngrs;
      this.setState(newState);
    }
  }

  async getPartyNgrs(companyIds, farmIds) {
    farmIds = compact(farmIds || []);
    companyIds = compact(companyIds || []);
    if (!isEmpty([...companyIds, ...farmIds])) {
      const companyQueryString = map(companyIds, id => { return `company_ids=${id}`; }).join('&');
      const farmQueryString = map(farmIds, id => { return `farm_ids=${id}`; }).join('&');
      return await APIService.ngrs().appendToUrl(`?${companyQueryString}&${farmQueryString}`).get(this.props.userToken);
    }
    return [];
  }

  setValidatorsForIsSelf(isSelf, newState) {
    if (isSelf || !this.showInvoicingField() || get(newState.selectedBaseEntity, 'entity') === 'order' || get(newState.selectedBaseEntity, 'entity') === 'freightorder') {
      if (!this.isEditForm)
        newState.fields.rateFreightOut.value = 0;
      newState.fields.invoicing.validators = [];
      newState.fields.paymentTermId.validators = [];
      newState.fields.rateFreightOut.validators = [];
    }
    return newState;
  }

  getProvidersForBroker() {
    var customer = null;
    var providers = this.props.providers;
    if (this.state.fields.typeId.value && this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY && this.state.fields.customer.company) {
      customer = this.state.fields.customer.company;
      if (customer.id !== this.props.currentUser.companyId) {
        providers = [{ ...this.props.currentUser.company, rel: 'companies', entity: 'company' }];
      }
    }
    return providers;
  }

  getProvidersForNonBrokerUsers() {
    var providers = this.props.providers;
    if (this.state.fields.typeId.value && this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY && this.state.fields.customer.company) {
      var customerCompanyId = this.state.fields.customer.company.id;
      if (customerCompanyId != this.props.currentUser.companyId) {
        return [{ ...this.props.currentUser.company, rel: 'companies', entity: 'company' }];
      }
    }
    return providers;
  }

  getProviders() {
    if (this.shouldFetchSlotsFor('Pickup') || this.shouldFetchSlotsFor('Delivery'))
      return this.props.providers || [];

    const baseEntityType = get(this.state.selectedBaseEntity, 'entity');
    if (baseEntityType === 'order' && !this.isSelectedOrderCallOnGrain()) {
      const providerId = get(this.state.selectedBaseEntity, 'providerId');
      return filter(this.props.providers, { id: providerId });
    }
    if (get(this.state.fields, 'status.value') == 'planned' && !this.state.isDependent) {
      if (get(this.state.fields.customer, 'companyId.value') === this.props.currentUser.companyId ||
        (get(this.state.selectedBaseEntity, 'seller.company.id', get(this.state.sellerDetails, 'companyId')) === get(this.state.fields.customer, 'companyId.value') && get(this.state.selectedBaseEntity, 'seller.representedById', get(this.state.sellerDetails, 'representedById')) === this.props.currentUser.companyId) ||
        (get(this.state.selectedBaseEntity, 'buyer.company.id', get(this.state.buyerDetails, 'companyId')) === get(this.state.fields.customer, 'companyId.value') && get(this.state.selectedBaseEntity, 'buyer.representedById', get(this.state.buyerDetails, 'representedById')) === this.props.currentUser.companyId))
        return this.props.providers || [];
      else
        return filter(this.props.providers, { id: this.props.currentUser.companyId });
    }
    if (get(this.state.fields, 'status.value') == 'delivered' && !this.state.isDependent) {
      if (this.orderId) {
        return this.props.providers || [];
      }
    }
    return this.props.providers || [];
  }

  getCustomers() {
    if (this.orderId) {
      let seller = get(this.state.selectedBaseEntity, 'seller.company');
      let buyer = get(this.state.selectedBaseEntity, 'buyer.company');
      set(seller, 'representedById', get(this.state.selectedBaseEntity, 'seller.representedById'));
      set(buyer, 'representedById', get(this.state.selectedBaseEntity, 'buyer.representedById'));
      return [seller, buyer];
    }
    if (!this.orderId && !this.state.isDependent)
      return null;
    return this.props.providers || [];
  }

  getStatuses() {
    if (get(this.state, 'selectedBaseEntity.entity') === 'contract' && get(this.state, 'selectedBaseEntity.status') === PLANNED_STATUS) {
      return filter(this.props.statuses, status => get(status, 'id') !== DELIVERED_STATUS);
    }
    if (this.isUserNonDeliveryOnus() &&
      (
        get(this.state, 'selectedBaseEntity.entity') === 'contract' ||
        (this.isSelectedBaseEntityAnOrder() && get(this.state, 'selectedBaseEntity.isCallOnGrain'))
      )
    ) {
      return this.props.statuses;
    }
    if (
      (
        get(this.state, 'selectedBaseEntity.entity') === 'contract' &&
        get(this.state, 'selectedBaseEntity.userHasDeliveryOnus') === false &&
        this.state.fields.providerId.value !== this.props.currentUser.companyId
      ) ||
      (
        this.isUserNonDeliveryOnus() &&
        this.isSelectedBaseEntityAnOrder() &&
        this.props.currentUser.companyId !== get(this.state, 'selectedBaseEntity.providerId')
      )
    ) {
      return filter(this.props.statuses, status => get(status, 'id') !== PLANNED_STATUS);
    }
    return this.props.statuses;
  }

  async getCustomerContacts(company, _ = '', customerContactId = '', callback, representingCompanyId) { // eslint-disable-line no-unused-vars
    const partyType = get(company, 'entity');
    const contacts = await this.getContacts(company, representingCompanyId);
    this.setState({
      customerContacts: contacts
    }, () => {
      if (!customerContactId) {
        customerContactId = (this.isEditForm && this.state.isPopulated) ? this.getKeyContactId(contacts, get(company, 'id'), partyType) : null;
      }
      if (customerContactId) {
        const newState = { ...this.state };
        newState.fields.customer.contactId.value = customerContactId;
        newState.fields.customer.contactId.errors = [];
        this.setState(newState, () => {
          if (callback) {
            callback();
          }
        });
      } else if (callback) {
        callback();
      }
    });

  }

  async getContacts(company, representingCompanyId) {
    const queryParams = representingCompanyId ? { representing_company_id: representingCompanyId } : {};
    return get(company, 'id') ? await APIService.contracts().companies(company.id).contacts().get(this.props.userToken, {}, queryParams) : [];
  }

  getKeyContactId(contacts, entityId, partyType) {
    const keyContacts = filter(contacts, { keyContact: true });
    let keyContactId = null;
    if (keyContacts && keyContacts.length === 1) {
      keyContactId = keyContacts[0].id;
    } else if (partyType === 'farm') {
      const farmKeyContact = find(keyContacts, { farmId: entityId });
      if (farmKeyContact) {
        keyContactId = farmKeyContact.id;
      }
    } else {
      const companyKeyContact = find(keyContacts, { companyId: entityId });
      if (companyKeyContact) {
        keyContactId = companyKeyContact.id;
      }
    }
    return keyContactId;
  }

  getSelectedProvider() {
    return find([...this.getProviders(), ...this.props.companyParties, ...this.state.providerItems], { id: this.state.fields.providerId.value, entity: 'company' });
  }

  openFreightProviderSideDrawer() {
    const provider = this.getSelectedProvider();
    const companyId = get(provider, 'id');
    if (companyId) {
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.handleSideDrawer('freightProviderSideDrawerIsOpen');
      }));
    }
  }

  alertPermissionError(entity, reason) {
    alertifyjs.alert(
      'Permission Denied',
      `<div className=""><p>You do not have permission to create ${entity} for the party because:</p><ul><li>${reason}</li></ul><div>Please follow <a href=${getZendeskURL()} rel="noopener noreferrer" target='_blank'>FAQs</a> for more details</div></div>`,
      () => {
      }
    );
  }

  closeFreightProviderSideDrawer() {
    this.setState({ freightProviderSideDrawerIsOpen: false });
  }

  openCustomerSideDrawer() {
    const companyId = get(this.state.fields, 'customer.companyId.value');
    if (companyId) {
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.handleSideDrawer('customerSideDrawerIsOpen');
      }));
    }
  }

  closeCustomerSideDrawer() {
    this.setState({ customerSideDrawerIsOpen: false });
  }

  openGeneralConditionSideDrawer() {
    const { dispatch } = this.props;
    dispatch(clickAddGeneralConditionButton());
    this.handleSideDrawer('generalConditionSideDrawerIsOpen');
  }

  closeGeneralConditionSideDrawer() {
    this.setState({ generalConditionSideDrawerIsOpen: false });
  }

  openSpecialConditionSideDrawer() {
    const { dispatch } = this.props;
    dispatch(clickAddSpecialConditionButton());
    this.handleSideDrawer('specialConditionSideDrawerIsOpen');
  }

  closeSpecialConditionSideDrawer() {
    this.setState({ specialConditionSideDrawerIsOpen: false });
  }

  closeTruckSideDrawer() {
    this.setState({ isTruckSideDrawerOpen: false });
  }

  isAnyFarmAssociated(farms) {
    return some(farms, 'isAssociated');
  }

  async openTruckSideDrawer(truckType) {
    if (this.state.newTruckType !== truckType) {
      this.handleSideDrawer();
    }
    if (this.state.fields.providerId.value === get(this.props.currentUser, 'companyId') || !get(this.getSelectedProvider(), 'isRegistered')) {
      this.addNewTruck(truckType);
    } else if (get(this.getSelectedProvider(), 'typeId') !== GROWER) {
      this.alertPermissionError(TRUCKS_ENTITY, FREIGHT_PROVIDER_REGISTERED_ERROR_MESSAGE);
    } else if (get(this.props.currentUser, 'company.typeId') === BROKER) {
      let farms = await APIService.companies(this.state.fields.providerId.value).farms().get(this.props.userToken);
      if (this.isAnyFarmAssociated(farms)) {
        this.addNewTruck(truckType);
      } else {
        this.alertPermissionError(TRUCKS_ENTITY, NO_LINKED_FARMS_ERROR_MESSAGE);
      }
    } else {
      this.alertPermissionError(TRUCKS_ENTITY, FREIGHT_PROVIDER_REGISTERED_ERROR_MESSAGE);
    }
  }

  addNewTruck(truckType) {
    if (this.state.newTruckType !== truckType) {
      const { dispatch } = this.props;
      dispatch(cancelEditTruck());
    }
    this.props.handleAddTruckButtonClick();
    this.setState({ newTruckType: truckType }, () => this.handleSideDrawer('isTruckSideDrawerOpen'));
  }

  shouldShowCheckpointDetails = () => {
    return includes(['planned', 'confirmation_pending', 'confirmed', 'rejected', 'draft'], get(this.editMovement, 'status'));
  };

  openNgrSideDrawer(ngrEntity) {
    const companyId = get(this.state.fields, 'customer.companyId.value');
    if (companyId) {
      const { dispatch } = this.props;
      const selectedEntity = find(this.props.companyParties, { id: companyId });
      dispatch(canCreateNgr(companyId, 'company', selectedEntity, () => {
        this.props.handleAddCompanyNgrButtonClick();
        this.props.handleAddNgrButtonClick();
        this.setState({ ngrEntity: ngrEntity }, () => this.handleSideDrawer('ngrSideDrawerIsOpen'));
      }));
    }
  }

  closeNgrSideDrawer() {
    this.setState({ ngrSideDrawerIsOpen: false });
  }

  handleSpecsChange(modelName, specCode, specValue) {
    this.setFieldValue(`${modelName}.specs.${specCode}`, specValue, true, () => this.setFieldWarnings(`fields.${modelName}.specs.${specCode}`, specCode));
  }

  handlePickupSpecsChange(specCode, specValue) {
    this.handleSpecsChange('freightPickup.outload', specCode, specValue);
  }

  handleDeliverySpecsChange(specCode, specValue) {
    this.handleSpecsChange('freightDelivery.inload', specCode, specValue);
  }

  setDeliveryEndDate() {
    if (!this.state.fields.freightDelivery.date.value && this.state.fields.freightPickup.date.value && !this.state.isPickupSiteCompanyHandler) {
      const newState = { ...this.state };
      newState.fields.freightDelivery.date.value = newState.fields.freightPickup.date.value;
      newState.fields.freightDelivery.date.errors = [];
      this.setState(newState, () => {
        this.fetchSlotsIfNeeded('freightDelivery.date');
      });
    }
  }

  getAddCircleIconWithTooltip(style, truckType, disabled) {
    return this.shouldDisplayAddTruckButton() &&
      <AddCircleIconWithTooltip
        id="addTruck"
        onClick={() => this.openTruckSideDrawer(truckType)}
        title={"Add Trucks To " + get(this.getSelectedProvider(), 'displayName', '')}
        style={style}
        disabled={disabled}
      />;
  }

  syncTrucks(selectedTruck) {
    this.getProviderTrucks(this.state.fields.providerId.value, () => {
      setTimeout(() => {
        const newState = { ...this.state };
        set(newState.fields, `${newState.newTruckType}.value`, selectedTruck);
        this.setState(newState);
      }, 200);
    });
  }


  async handleSlotChange(value, id, slot) {
    const newState = { ...this.state };
    newState.fields[id].value = value;
    let estimatedDeliverySlotStartTime = false;
    if (this.shouldFetchSlotsFor('Pickup') && this.shouldFetchSlotsFor('Delivery')) {
      estimatedDeliverySlotStartTime = this.timeStampToDateTime(this.estimatedDeliverySlotStartTime()) + 'Z';
    }
    if (id === 'inloadSlotId') {
      newState.fields.inloadSlotUpdatedAt.value = slot?.updatedAt
      newState.fields.freightDelivery.timeStart.value = get(slot, 'startTime');
      if (newState.fields.freightPickup.timeStart.value && estimatedDeliverySlotStartTime && value && moment(get(slot, 'start')).isBefore(estimatedDeliverySlotStartTime))
        newState.warningText = 'Warning: Insufficient time to deliver within this slot';
      else
        newState.warningText = '';
    }
    if (id === 'outloadSlotId') {
      newState.fields.outloadSlotUpdatedAt.value = slot?.updatedAt
      newState.fields.freightPickup.timeStart.value = get(slot, 'startTime');
      if (newState.fields.freightDelivery.timeStart.value && estimatedDeliverySlotStartTime &&
        newState.fields.inloadSlotId.value && !isEmpty(newState.inloadSlots) && value) {
        const inloadSlot = find(newState.inloadSlots, { id: newState.fields.inloadSlotId.value });
        if (moment(get(inloadSlot, 'start')).isBefore(estimatedDeliverySlotStartTime))
          newState.warningText = 'Warning: Insufficient time to deliver within this slot';
        else
          newState.warningText = '';
      }
      else
        newState.warningText = '';
    }
    this.setState(newState, () => this.getRecommendedDeliverySlotText());
  }

  timeStampToDateTime(dateTime) {
    let utcDateTime = moment(dateTime).utc().format('YYYY-MM-DD HH:mm:ss');
    let tmsx = moment(utcDateTime).format("X");
    let formattedDateTime = moment.unix(tmsx).format('YYYY-MM-DDTHH:mm:ss');
    return formattedDateTime;
  }

  fetchSlotsIfNeeded(id) {
    this.hasFetchedSlotsInEdit = true;
    const newState = { ...this.state };
    const showFreightPickupOrDeliverySection = (!this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails()));

    if (id) {
      if (id === 'freightPickup.date') {
        newState.fields.outloadSlotId.value = undefined;
        newState.fields.outloadSlotUpdatedAt.value = undefined;
      }
      if (id === 'freightDelivery.date') {
        newState.fields.inloadSlotId.value = undefined;
        newState.fields.inloadSlotUpdatedAt.value = undefined;
      }
    }
    if (this.shouldFetchSlotsFor('Delivery') || (!this.state.isConsigneeEditable && get(this.editMovement, 'isDeliverySiteSlotOrderBookingOn') && showFreightPickupOrDeliverySection)) {
      newState.fields.freightDelivery.timeStart.errors = [];
      newState.fields.freightDelivery.timeStart.validators = [];
      newState.fields.inloadSlotId.validators = [required()];
      this.fetchSlots('freightDelivery.consignee', 'inload');
    } else {
      newState.fields.freightDelivery.timeStart.validators = [required()];
      newState.fields.inloadSlotId.validators = [];
      newState.fields.inloadSlotId.value = undefined;
      newState.fields.inloadSlotId.errors = [];
    }
    if (this.shouldFetchSlotsFor('Pickup') || (!this.state.isConsignorEditable && get(this.editMovement, 'isPickupSiteSlotOrderBookingOn') && showFreightPickupOrDeliverySection)) {
      newState.fields.freightPickup.timeStart.errors = [];
      newState.fields.freightPickup.timeStart.validators = [];
      newState.fields.outloadSlotId.validators = [required()];
      this.fetchSlots('freightPickup.consignor', 'outload');
    } else {
      newState.fields.freightPickup.timeStart.validators = [required()];
      newState.fields.outloadSlotId.validators = [];
      newState.fields.outloadSlotId.value = undefined;
      newState.fields.outloadSlotId.errors = [];
    }
    if (this.shouldFetchSlotsFor('Pickup') && this.shouldFetchSlotsFor('Delivery') && this.state.applySubFreightProvider)
      newState.fields.driverId.validators = [required()];
    this.setState(newState, () => {
      this.getSlotBookingForBoth('trucks', this.state.fields.providerId.value);
      this.getSlotBookingForBoth('employees', this.state.fields.providerId.value);
      this.setSlotErrorsIfRequired();
    }
    );
  }

  checkForDateMsg = id => {
    let changedState = { ...this.state };
    let baseEntity = changedState.selectedBaseEntity;
    let checkFor = id === 'freightPickup.date' ? 'Pickup' : 'Delivery';
    let dateValidator = get(this.state.fields, `${id}.validators`);
    let startDate = get(baseEntity, 'deliveryStartDate') || get(baseEntity, 'freightPickup.dateTime');
    let endDate = get(baseEntity, 'deliveryEndDate') || get(baseEntity, 'freightDelivery.dateTime');
    if (this.shouldFetchSlotsFor(checkFor) && baseEntity) {
      if (!isEmpty(startDate))
        dateValidator.push(minDate(startDate, 'The order is not valid for this date', 'error'));
      if (!isEmpty(endDate))
        dateValidator.push(maxDate(endDate, 'The order is not valid for this date', 'error'));
    } else {
      let minDateErrorMessage = `Delivery start date in ${includes(['freightorder', 'order'], baseEntity?.entity) ? 'order' : 'contract'} is ${moment(startDate).format(this.state.countryFormats.date)}`
      let maxDateErrorMessage = `Delivery end date in ${includes(['freightorder', 'order'], baseEntity?.entity) ? 'order' : 'contract'} is ${moment(endDate).format(this.state.countryFormats.date)}`

      if (baseEntity && !isEmpty(startDate) && !some(dateValidator, validator => includes(validator.message, minDateErrorMessage)))
        dateValidator.push(minDate(startDate, minDateErrorMessage, 'warning'));
      if (baseEntity && !isEmpty(endDate) && !some(dateValidator, validator => includes(validator.message, maxDateErrorMessage)))
        dateValidator.push(maxDate(endDate, maxDateErrorMessage , 'warning'));
    }
    set(changedState.fields, `${id}.validators`, dateValidator);
    this.setState(changedState, () => {
      this.setFieldErrors(id, get(changedState, `fields.${id}.value`));
    });
  };

  regoCallback = data => {
    if(data?.isAvailable) {
      let config = getCountryConfig();
      let payload = {
        rego: data?.rego.toUpperCase(),
        tareWeight: get(config, 'truck.tareWeight'),
        grossWeight: get(config,'truck.grossWeight')
      }
      let companyId;
      if (data?.key == 'freightPickup.outload.truckId')
        companyId= this.state.fields.freightPickup.searchOption == SEARCH_BY_ALL_REGOS ? config?.systemCompanyId : this.state.fields.freightPickup.outload.truckOwner.value?.id || config?.systemCompanyId;
      else
        companyId = this.state.fields.freightDelivery.searchOption == SEARCH_BY_ALL_REGOS ? config?.systemCompanyId : this.state.fields.freightDelivery.inload.truckOwner.value?.id || config?.systemCompanyId;
      this.props.createTruck(companyId, payload, addTruck, null, data?.key);
    }
  }

  handleSelectFieldChange(value, id, item) {
    this.setFieldValue(id, value, false, () => {
      if (value) {
        this.setDefaultPlannedTonnage(id, value);
        this.setDefaultTareAndGrossWeightForFreightPickup(id, value);
        this.setDefaultTareAndGrossWeightForFreightDelivery(id, value);
      }
      if (includes(['freightPickup.consignor', 'freightPickup.outload.season', 'freightPickup.outload.ngrId', 'freightPickup.outload.gradeId'], id))
        this.setSellerAvailableTonnage();
      else if (includes(['season', 'customer.ngrId', 'freightDelivery.inload.season', 'freightDelivery.inload.ngrId'], id)) {
        this.checkDeliveryNetWeightAgainstRemainingSpace();
      } else if (includes(id, 'consignee.site')) {
        this.checkDeliveryNetWeightAgainstRemainingSpace();
      }
      if (includes(['freightDelivery.date', 'freightPickup.date'], id)) {
        this.fetchSlotsIfNeeded(id);
      }
    });
    this.setFieldErrors(id, value);
    if (includes(['freightPickup.date', 'freightPickup.timeStart'], id)) {
      this.checkForDateMsg(id);
      this.fetchDistance();
    }
    if (includes(['freightDelivery.date', 'freightDelivery.timeStart'], id)) {
      this.checkForDateMsg(id);
    }
    if (id === 'season') {
      const newState = { ...this.state };
      if (value) {
        if (!this.state.isPickupSiteCompanyHandler) {
          newState.fields.freightPickup.outload.season.value = value;
          newState.fields.freightPickup.outload.season.errors = [];
        }
        if (!this.state.isDeliverySiteCompanyHandler) {
          newState.fields.freightDelivery.inload.season.value = value;
          newState.fields.freightDelivery.inload.season.errors = [];
        }
      } else {
        newState.fields.freightPickup.outload.season.value = null;
        newState.fields.freightDelivery.inload.season.value = null;
      }
      this.setState(newState);
    } else if ((id === 'freightDelivery.inload.truckId' || id === 'freightPickup.outload.truckId') && this.isRegoSearchable()) {
      const newState = { ...this.state };
      if (id === 'freightDelivery.inload.truckId') {
        this.disabledTruckRegoField = 'freightPickup.outload.truckId';
        newState.fields.freightDelivery.inload.truckId.value = value;
        newState.fields.freightDelivery.inload.truckOwnerId.value = item?.companyId
      } else if (id === 'freightPickup.outload.truckId') {
        this.disabledTruckRegoField = 'freightDelivery.inload.truckId';
        newState.fields.freightPickup.outload.truckId.value = value;
        newState.fields.freightPickup.outload.truckOwnerId.value = item?.companyId
      }

      if (!value) this.disabledTruckRegoField = null;
      this.setState(newState, () => {
        if (this.state.fields.freightPickup.selectedContainer === undefined) {
          this.setDefaultTareAndGrossWeightForFreightPickup(this.disabledTruckRegoField, value);
          this.setDefaultTareAndGrossWeightForFreightDelivery(this.disabledTruckRegoField, value);
        }
        if (includes(['freightDelivery.date', 'freightDelivery.timeStart', 'freightPickup.date', 'freightPickup.timeStart'], id))
          this.getRecommendedDeliverySlotText();
      });
    }

    if ((id === 'freightDelivery.inload.truckId' || id === 'freightPickup.outload.truckId') && this.isDeliveredMovement()) {
      const newState = { ...this.state };
      if (item && item?.inputValue && !(item?.inputValue.length < 4 || item?.inputValue.length > 10)) {
        this.props.validateRego(id, item?.inputValue, this.regoCallback);
      } else {
      if (value) {
        let truck = find(this.state.providerTrucks, { id: value }) || find(this.state.pickupOwnerTrucks, { id: value }) || find(this.state.deliveryOwnerTrucks, { id: value }) || item;
        let category = find(this.state.categories, { id: get(truck, 'categoryId') });
        if(!find(this.state.selectedTrucks, { id: item.id }))
          newState.selectedTrucks.push(item);
        const systemCompanyId = getCountrySystemCompanyId()
        if(item?.companyId == systemCompanyId) {
          const systemCompany = {id: systemCompanyId, name: 'Unknown', transactionParticipation: true}
          if (id === 'freightPickup.outload.truckId') {
            newState.fields.freightPickup.outload.truckOwner.value = systemCompany;
            if(!newState.fields.freightDelivery.inload.truckId.value)
              newState.fields.freightDelivery.inload.truckOwner.value = systemCompany;
          } else if (id === 'freightDelivery.inload.truckId') {
            newState.fields.freightDelivery.inload.truckOwner.value = systemCompany;
          }
        } else {
          APIService.companies(item?.companyId).get().then(data => {
            if (id === 'freightPickup.outload.truckId') {
              newState.fields.freightPickup.outload.truckOwner.value = data;
              if(newState.fields.freightDelivery.inload.truckId.value == newState.fields.freightPickup.outload.truckId.value)
                newState.fields.freightDelivery.inload.truckOwner.value = data;
            }
            else if (id === 'freightDelivery.inload.truckId')
              newState.fields.freightDelivery.inload.truckOwner.value = data;
            });
        }
        if (id === 'freightPickup.outload.truckId') {
          this.getTruckOwnerTrucks(item?.companyId, true);
          newState.fields.freightPickup.outload.truckId.value = value;
          newState.pickupCode.value = get(category, 'truckCode') || get(truck, 'code');
          newState.fields.freightPickup.outload.categoryId.value = get(truck, 'categoryId');
          newState.pickupMassLimitPermit.value = get(category, 'massLimitPermit');
          newState.pickupGHMS.value = get(category, 'ghms');
          newState.fields.freightPickup.outload.permitNumber.value = get(truck, 'permitNumber')
          newState.fields.freightPickup.outload.declaredMassLimit.value = get(truck, 'declaredMassLimit')
          newState.fields.freightPickup.outload.accreditationNumber.value = get(truck, 'accreditationNumber')
          newState.fields.freightPickup.outload.noticeNumber.value = get(truck, 'noticeNumber')
          newState.fields.freightPickup.outload.restricted.value = get(truck, 'restricted')
          newState.freightPickupAccreditationNumberChecked = Boolean(get(truck, 'accreditationNumber'))
          newState.freightPickupLoadSharing = Boolean(get(truck, 'loadSharing'))
          newState.freightPickupPermitChecked = Boolean(get(truck, 'permitNumber'))
          newState.freightPickupRestrictedChecked = Boolean(get(truck, 'restricted'))
          newState.fields.freightPickup.outload.steer_1_point_1.value = get(truck, 'steer1Point1');
          newState.fields.freightPickup.outload.steer_point_5.value = get(truck, 'steerPoint5');
          newState.fields.freightPickup.outload.truckOwnerId.value = item?.companyId;
          newState.isCopyFromOutload = false;
          if(!newState.fields.freightDelivery.inload.truckId.value) {
            newState.truckOptions = true;
            this.getTruckOwnerTrucks(item?.companyId, false);
            newState.fields.freightDelivery.searchOption = this.state.fields.freightPickup.searchOption;
            newState.fields.freightDelivery.inload.truckId.value = value;
            newState.fields.freightDelivery.inload.truckOwnerId.value = item?.companyId;
          }
        } else if (id === 'freightDelivery.inload.truckId') {
          newState.fields.freightDelivery.inload.truckId.value = value;
          newState.deliveryCode.value = get(category, 'truckCode') || get(truck, 'code');
          newState.fields.freightDelivery.inload.categoryId.value = get(truck, 'categoryId');
          newState.deliveryMassLimitPermit.value = get(category, 'massLimitPermit');
          newState.deliveryGHMS.value = get(category, 'ghms');
          newState.fields.freightDelivery.inload.permitNumber.value = get(truck, 'permitNumber')
          newState.fields.freightDelivery.inload.declaredMassLimit.value = get(truck, 'declaredMassLimit')
          newState.fields.freightDelivery.inload.accreditationNumber.value = get(truck, 'accreditationNumber')
          newState.fields.freightDelivery.inload.noticeNumber.value = get(truck, 'noticeNumber')
          newState.fields.freightDelivery.inload.restricted.value = get(truck, 'restricted')
          newState.freightDeliveryAccreditationNumberChecked = Boolean(get(truck, 'accreditationNumber'))
          newState.freightDeliveryLoadSharing = Boolean(get(truck, 'loadSharing'))
          newState.freightDeliveryPermitChecked = Boolean(get(truck, 'permitNumber'))
          newState.freightDeliveryRestrictedChecked = Boolean(get(truck, 'restricted'))
          newState.fields.freightDelivery.inload.steer_1_point_1.value = get(truck, 'steer1Point1');
          newState.fields.freightDelivery.inload.steer_point_5.value = get(truck, 'steerPoint5');
          newState.fields.freightDelivery.inload.truckOwnerId.value = item?.companyId
          newState.isCopyFromInload = false;
          this.getTruckOwnerTrucks(item?.companyId, false)
        }
      } else {
        if (id === 'freightPickup.outload.truckId') {
          newState.fields.freightPickup.outload.truckId.value = '';
          newState.pickupCode.value = '';
          newState.fields.freightPickup.outload.categoryId.value = '';
          newState.pickupMassLimitPermit.value = '';
          newState.pickupGHMS.value = false;
          newState.fields.freightPickup.outload.steer_1_point_1.value = false;
          newState.fields.freightPickup.outload.steer_point_5.value = false;
        } else if (id === 'freightDelivery.inload.truckId') {
          newState.fields.freightDelivery.inload.truckId.value = '';
          newState.deliveryCode.value = '';
          newState.fields.freightDelivery.inload.categoryId.value = '';
          newState.deliveryMassLimitPermit.value = '';
          newState.deliveryGHMS.value = false;
          newState.fields.freightDelivery.inload.steer_1_point_1.value = false;
          newState.fields.freightDelivery.inload.steer_point_5.value = false;
        }
      }

      if(newState.isCopyFromInload) {
        if(this.state.fields.freightDelivery.inload.truckOwner.value && isNull(newState.fields.freightPickup.outload.truckOwner.value) && this.state.pickupOwnerTrucks) {
          newState.fields.freightPickup.outload.truckOwner.value = this.state.fields.freightDelivery.inload.truckOwner.value;
          newState.fields.freightPickup.outload.truckId.value = this.state.fields.freightDelivery.inload.truckId.value;
        }
        newState.isCopyFromInload = false;
      }
      if(newState.isCopyFromOutload) {
        if(this.state.fields.freightPickup.outload.truckOwner.value && isNull(newState.fields.freightDelivery.inload.truckOwner.value) && this.state.deliveryOwnerTrucks) {
          newState.fields.freightDelivery.inload.truckOwner.value = this.state.fields.freightPickup.outload.truckOwner.value;
          newState.fields.freightDelivery.inload.truckId.value = this.state.fields.freightPickup.outload.truckId.value;
        }
        newState.isCopyFromOutload = false;
      }
      this.setState(newState, () => {
        if(id === 'freightDelivery.inload.ngrId')
          this.setLoadVarietyMandatory()
        this.setCategory(id == 'freightDelivery.inload.truckId' ? 'freightDelivery.inload' : 'freightPickup.outload')
      });
    }}
    if (includes(['freightPickup.date', 'freightDelivery.date'], id) && value && this.isDeliveredMovement()) {
      let shouldFetchCategories = true;
      if (!isEmpty(this.state.categories)) {
        let category = this.state.categories[0];
        let date = new Date(value);
        if (date >= new Date(get(category, 'fromDate').substring(0, 10)) && date <= new Date(get(category, 'endDate').substring(0, 10)))
          shouldFetchCategories = false;
      }
      if (shouldFetchCategories) {
        this.fetchCategories();
        this.fetchPermits();
      }
    }
  }

  setCategory = checkpoint => {
    const category = this.findCategory(checkpoint);
    if (get(category, 'id') !== get(this.state.fields, `${checkpoint}.categoryId.value`))
      this.setFieldValue(`${checkpoint}.categoryId`, get(category, 'id'));
  };

  findCategory = checkpoint => {
    const { pickupMassLimitPermit, deliveryMassLimitPermit, pickupGHMS, deliveryGHMS, pickupCode, deliveryCode } = this.state;
    const massLimitPermit = checkpoint === 'freightPickup.outload' ? pickupMassLimitPermit : deliveryMassLimitPermit;
    const ghms = checkpoint === 'freightPickup.outload' ? pickupGHMS : deliveryGHMS;
    const truck_code = checkpoint === 'freightPickup.outload' ? pickupCode : deliveryCode;
    if (massLimitPermit.value && truck_code.value && !isEmpty(this.state.categories)) {
      return find(this.state.categories, { truckCode: truck_code.value, massLimitPermit: massLimitPermit.value, ghms: Boolean(ghms.value) })
    }
    return undefined;
  };

  checkForStatus = () => includes(['planned', 'draft', 'confirmed'], get(this, 'editMovement.status'));

  shouldFetchSlotsFor(checkpointType) {
    if ((!this.isEditForm && this.state.isDependent && this.state.fields.status.value === 'planned') || (this.isEditForm && this.checkForStatus() && !get(this.editMovement, 'externalReferenceNumber'))) {
      const baseEntity = this.orderResponse || get(this.editMovement, 'order') || this.state.selectedBaseEntity;
      return (includes(['order', 'freightorder'], get(baseEntity, 'entity')) || this.isEditForm) &&
        (this.redirectedFromSlots || this.isSlotBookingOnForCheckpoint(checkpointType));
    }
  }

  isSlotBookingOnForCheckpoint(checkpointType) {
    let handlerKey = checkpointType === 'Pickup' ? 'consignor' : 'consignee';
    let loadType = checkpointType === 'Pickup' ? 'isOutloadSlotOrderBookingOn' : 'isInloadSlotOrderBookingOn'

    let handlers = checkpointType === 'Pickup' ? this.state.consignors : this.state.consignees;
    let handler = find(handlers, { id: get(this.state, `fields.freight${checkpointType}.${handlerKey}.handlerId.value`) })
    return get(handler, loadType);
  }

  isBaseEntityNotOrderAndSlotOrderBookingOn(checkpointType) {
    if (this.state.fields.status.value === 'planned') {
      const baseEntity = this.orderResponse || get(this.editMovement, 'order') || this.state.selectedBaseEntity;
      return get(baseEntity, 'entity') !== 'order' && this.isSlotBookingOnForCheckpoint(checkpointType);
    }
    return false;
  }

  // checkpointType can be freightPickup.consignor or freightDelivery.consignee
  // loadType can be inload or outload
  fetchSlots(checkpointType, loadType) {
    const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    const isDuplicatingMovement = Boolean(queryParams.get('copyFrom')) && window.VOID_AND_DUPLICATE_TOGGLE

    const baseEntity = this.orderResponse || get(this.editMovement, 'order') || this.state.selectedBaseEntity;
    const providerId = get(baseEntity, 'providerId') || get(baseEntity, 'provider.id');
    const siteId = get(this.state, `fields.${checkpointType}.handlerId.value`);
    let startDate = this.state.fields.freightPickup.date.value;
    let endDate = this.state.fields.freightDelivery.date.value;

    if (loadType === 'inload')
      startDate = endDate;

    if(!startDate)
      return

    let startDateTime = this.timeStampToDateTime(moment(startDate).startOf('day'));
    let dayEndDate = moment(startDate).endOf('day');
    let endDateTime = this.timeStampToDateTime(dayEndDate);
    if (every([siteId, startDateTime, endDateTime])) {
      const queryParams = { start: startDateTime, end: endDateTime, load_type: loadType };
      if (!isDuplicatingMovement && get(this.editMovement, `${loadType}SlotId`) &&
        ((startDate === get(this.editMovement, 'freightPickup.date') && loadType === 'outload') ||
          (endDate === get(this.editMovement, 'freightDelivery.date') && loadType === 'inload'))) {
          let slotIds = [this.editMovement[`${loadType}SlotId`]]
          if(get(this.editMovement, `${loadType}TrailerSlotIds`))
            slotIds = slotIds.concat(this.editMovement[`${loadType}TrailerSlotIds`])
        queryParams['include_slot_ids'] =  slotIds.join(',');
      }
      if (providerId)
        queryParams['freight_provider_id'] = providerId;
      APIService
        .company_sites(siteId)
        .appendToUrl('slots/planned/')
        .get(null, null, queryParams)
        .then(slots => {
          const eligibleSlots = orderBy(filter(slots, slot => {
            if (slot.commodityId && slot.gradeId)
              return baseEntity.commodityId === slot.commodityId && baseEntity.plannedGradeId === slot.gradeId
            if (slot.commodityId)
              return baseEntity.commodityId === slot.commodityId && !includes(slot.excludedGradeIds, baseEntity.plannedGradeId)
            return !includes(slot.excludedGradeIds, baseEntity.plannedGradeId) && !includes(slot.excludedCommodityIds, baseEntity.commodityId)
          }), slot => new Date(slot.start));
          const newState = { ...this.state };
          const query = new URLSearchParams(this.props.location.search);

          const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
          const isDuplicatingMovement = Boolean(queryParams.get('copyFrom')) && window.VOID_AND_DUPLICATE_TOGGLE

          const outloadSlotId = query.get('outloadSlotId') || get(!isDuplicatingMovement && this.editMovement, `outloadSlotId`);
          const inloadSlotId = query.get('inloadSlotId') || get(!isDuplicatingMovement && this.editMovement, `inloadSlotId`);
          if (loadType === 'inload') {
            if (inloadSlotId) {
              let inloadSlot = find(eligibleSlots, { 'id': parseInt(inloadSlotId) });
              if (inloadSlot) {
                newState.fields.inloadSlotId.value = parseInt(inloadSlotId);
                if (isEmpty(newState.subFreightProvider))
                  newState.subFreightProvider = inloadSlot.subFreightProvider;
                newState.driver = inloadSlot.driver;
              }
            }
            newState.inloadSlots = eligibleSlots;
          } else {
            if (outloadSlotId) {
              let outloadSlot = find(eligibleSlots, { 'id': parseInt(outloadSlotId) });
              if (outloadSlot) {
                newState.fields.outloadSlotId.value = parseInt(outloadSlotId);
                if (isEmpty(newState.subFreightProvider))
                  newState.subFreightProvider = outloadSlot.subFreightProvider;
                newState.driver = outloadSlot.driver;
              }
            }
            newState.outloadSlots = eligibleSlots;
          }
          this.setState(
            newState, () => {
              this.getRecommendedDeliverySlotText();
              this.props.dispatch(forceStopLoader());
            }
          );
        });
    }
  }

  setDefaultPlannedTonnage(id, value) {
    if (id === 'plannedTruckId' && !this.state.fields.plannedTonnage.value) {
      let truck = find(this.state.providerTrucks, { id: value });
      let netWeight = truck.totalWeights.netWeight;
      this.validatePlannedTonnage('plannedTonnage', netWeight);
      if (this.hasSelectedBaseEntity()) {
        let unaccountedTonnage = this.state.selectedBaseEntity.unaccountedTonnage;
        if (value && this.hasSelectedBaseEntity() && parseFloat(netWeight) < parseFloat(unaccountedTonnage)) {
          this.setFieldValue('plannedTonnage', netWeight, true);
        } else {
          this.setFieldValue('plannedTonnage', unaccountedTonnage ? parseFloat(parseFloat(unaccountedTonnage).toFixed(2)) : 0, true);
        }
      } else {
        this.setFieldValue('plannedTonnage', netWeight, true);
      }
    }
  }

  checkAnyWeightExists = (site, load) =>
    some([
      get(this.state, `fields.${site}.${load}.tareWeight.value`),
      get(this.state, `fields.${site}.${load}.grossWeight.value`),
      get(this.state, `fields.${site}.netWeight.value`),
    ]);

  setDefaultTareAndGrossWeightForFreightPickup(id, value) {
    if (((id === 'freightPickup.outload.truckId' && !this.isEditForm  && this.isDeliveredMovement()) || (id === 'freightPickup.outload.truckId' && !this.checkAnyWeightExists('freightPickup', 'outload'))) && this.state.selectedConsignorSite?.type !== 'container') {
      const truck = find(this.state.providerTrucks, { id: value }) || find(this.state.selectedTrucks, { id: value }) ||  find(this.state.pickupOwnerTrucks, { id: value });
      const netWeight = get(truck, 'totalWeights.netWeight');
      this.validateNetWeight(netWeight, 'freightPickup');
      if (this.state.selectedConsignor?.populateDefaultWeights) {
        this.setFieldValue('freightPickup.outload.tareWeight', get(truck, 'totalWeights.tareWeight'), true);
        this.setFieldValue('freightPickup.outload.grossWeight', get(truck, 'totalWeights.grossWeight'), true);
        this.setFieldValue('freightPickup.netWeight', netWeight, true);
      }
    }
  }

  setDefaultTareAndGrossWeightForFreightDelivery(id, value) {
    if (((id === 'freightDelivery.inload.truckId' && !this.isEditForm  && this.isDeliveredMovement()) || (id === 'freightDelivery.inload.truckId' && !this.checkAnyWeightExists('freightDelivery', 'inload'))) && this.state.selectedConsignorSite?.type !== 'container' && this.state.selectedConsigneeSite?.type !== 'container') {
      const truck = find(this.state.providerTrucks, { id: value }) || find(this.state.selectedTrucks, { id: value }) || find(this.state.deliveryOwnerTrucks, { id: value });
      const netWeight = get(truck, 'totalWeights.netWeight');
      this.validateNetWeight(netWeight, 'freightDelivery');
      if (this.state.selectedConsignee?.populateDefaultWeights) {
        this.setFieldValue('freightDelivery.inload.tareWeight', get(truck, 'totalWeights.tareWeight'), true);
        this.setFieldValue('freightDelivery.inload.grossWeight', get(truck, 'totalWeights.grossWeight'), true);
        this.setFieldValue('freightDelivery.netWeight', netWeight, true);
      }
    }
  }

  handleInvoicingFieldChange(value, id) {
    this.setFieldValue(id, value);
    setTimeout(() => {
      this.calculateCommissionRate();
      this.setRateError();
    }, 100);
    this.setFieldErrors(id, value);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleCOILAndIMPUFieldChange = (event) => {
    const newState = { ...this.state };
    set(newState, `${event.target.id}.value`, event.target.value);
    this.setState(newState);
  };

  handleAssignToIdFieldChange(value, id) {
    if ((this.isEditForm && this.isPopulatedAllFields && this.state.fields.assignToId.value !== value) || (!this.isEditForm && this.state.fields.assignToId.value !== value)) {
      this.handleSelectFieldChange(value, id, true);
    }
  }

  handleIdentifierChange(event) {
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      this.setFieldValue(event.target.id, event.target.value);
    } else {
      this.setFieldValue(event.target.id, this.state.fields.identifier.value);
    }
  }

  handlePickupGHMSChange = event => this.setState({ pickupGHMS: { ...this.state.pickupGHMS, value: event.target.checked } }, () => {
    const newState = {...this.state}
    newState.freightPickupPermitChecked = false;
    newState.freightPickupAccreditationNumberChecked = false;
    newState.freightPickupRestrictedChecked = false;
    this.setState(newState, () => this.setCategory('freightPickup.outload'))
  });

  handleDeliveryGHMSChange = event => this.setState({ deliveryGHMS: { ...this.state.deliveryGHMS, value: event.target.checked } }, () => {
    const newState = {...this.state}
    newState.freightDeliveryPermitChecked = false;
    newState.freightDeliveryAccreditationNumberChecked = false;
    newState.freightDeliveryRestrictedChecked = false;
    this.setState(newState, () => this.setCategory('freightDelivery.inload'))
  });

  setFieldValue(id, value, validateAfterSet = false, callback) {
    const newState = { ...this.state };
    set(newState.fields, id + '.value', value);
    this.setState(newState, () => {
      if (validateAfterSet) this.setFieldErrors(id, value);
      if (callback) callback();
    });
  }

  setFieldWarnings(path, specCode) {
    this.setState(state => set(state, `${path}.warnings`, this.getFieldWarnings(path, specCode)));
  }

  setFieldErrors(id, value, callback) {
    const newState = { ...this.state };
    let checkErrors = true;
    if (this.state.isDeliverySiteCompanyHandler) {
      if (includes(id, 'freightPickup')) {
        checkErrors = false;
      }
    } else if (this.state.isPickupSiteCompanyHandler) {
      if (includes(id, 'freightDelivery')) {
        checkErrors = false;
      }
    }
    if (checkErrors) {
      if (newState.setFieldErrors) {
        if (includes(id, 'freightDelivery.inload.specs')){
          forEach(newState.fields.freightDelivery.inload.specs, (value, key) => {
            const path = `fields.freightDelivery.inload.specs.${key}`;
            set(newState, `${path}.errors`, this.getFieldErrors(path));
            set(newState, `${path}.warnings`, this.getFieldWarnings(path, key));
          });
        }
        if (includes(id, 'freightPickup.outload.specs')){
          forEach(newState.fields.freightPickup.outload.specs, (value, key) => {
            const path = `fields.freightPickup.outload.specs.${key}`;
            set(newState, `${path}.warnings`, this.getFieldWarnings(path, key));
          });
        }
        this.getFieldErrors(get(newState.fields, id), value, id);
      }
      this.setState(newState, () => {
        if (callback) {
          callback();
        }
      });
    }
  }


  getFieldWarnings(path, specCode) {
    const { inloadGradeSpecs, outloadGradeSpecs } = this.state;
    const warnings = [];
    const value = get(this.state, `${path}.value`);
    const specs = includes(path, 'outload') ? outloadGradeSpecs : inloadGradeSpecs;

    specs.forEach(spec => {
      if (spec.code === specCode) {
        const min = get(spec, 'min', null);
        const max = get(spec, 'max', null);
        if (!(isNull(min) || isNull(max) || isNull(value) || isNaN(value) || value === '')) {
          if (value < min || value > max) {
            warnings.push(`GTA Suggested Range: ${min} - ${max}`);
          }
        }
      }
    });

    return warnings;
  }


  getFieldErrors(field, value, source = false) {
    if (has(field, 'value') && has(field, 'validators')) {
      field.errors = [];
      if (this.state.isPickupSiteCompanyHandler && source) {
        if (isEqual(source, "freightPickup.date") || isEqual(source, "freightPickup.timeStart"))
          field.validators.unshift(required())
        if (isEqual(source, "freightPickup.timeStart") && isEqual(value, 'Invalid date')) {
          value = null;
          field.value = null;
        }
      }
      else if (this.state.isDeliverySiteCompanyHandler && source) {
        if (isEqual(source, "freightDelivery.date") || isEqual(source, "freightDelivery.timeStart"))
          field.validators.unshift(required())
        if (isEqual(source, "freightDelivery.timeStart") && isEqual(value, 'Invalid date')) {
          value = null;
          field.value = null;
        }
      }
      field.validators.forEach((validator) => {
        if (validator.isInvalid(value || field.value) && !includes(field.errors, validator.message)) {
          field.errors.push(validator.message);
        }
      });
    }

    if(has(field, 'value') && field.value && has(field, 'errors') && ["freightPickup.consignor.handlerId", "freightDelivery.consignee.handlerId", "handlerId"].includes(source)) {
      let pickupSite = find(this.state.consignors, { id: field.value });
      let deliverySite = find(this.state.consignees, { id: field.value });
      const showFreightPickupOrDeliverySection = (!this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails()));

      if (!isSystemCompany() && showFreightPickupOrDeliverySection && get(pickupSite, 'stocksManagement') == true && this.props.currentUser.companyId != get(pickupSite, 'companyId') && this.state.fields.status.value === 'delivered')
        field.errors.push(LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created'));
      else if (!isSystemCompany() && showFreightPickupOrDeliverySection && get(deliverySite, 'stocksManagement') == true && this.props.currentUser.companyId != get(deliverySite, 'companyId') && this.state.fields.status.value === 'delivered')
        field.errors.push(LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created'));
    }
  }

  handleCurrencyChange = value => {
    if (value)
      this.setState({currency: value})
  }

  handleFreightRateChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    setTimeout(() => {
      this.calculateCommissionRate();
      this.setRateError();
    }, 100);
  }

  handleRateBlur() {
    setTimeout(() => {
      this.calculateCommissionRate();
      this.setRateError();
    }, 100);
  }

  setRateError() {
    const newState = { ...this.state };
    newState.fields.rateFreightOut.errors = [];
    this.setState(newState);
  }

  setAllFieldErrors() {
    const newState = { ...this.state };
    if (this.manualComplete && this.isCreatingCanolaMovement()) {
      this.getFieldErrors(newState.coil);
      this.getFieldErrors(newState.impu);
    }
    this.applyValidatorsOn(newState.fields);
    if (!this.state.isPickupSiteCompanyHandler) {
      this.applyValidatorsOn(newState.fields.freightDelivery);
      this.applyValidatorsOn(newState.fields.freightDelivery.consignee);
      this.applyValidatorsOn(newState.fields.freightDelivery.consignee.site);
      this.applyValidatorsOn(newState.fields.freightDelivery.inload);
    }
    if (!this.state.isDeliverySiteCompanyHandler) {
      this.applyValidatorsOn(newState.fields.freightPickup);
      this.applyValidatorsOn(newState.fields.freightPickup.consignor);
      this.applyValidatorsOn(newState.fields.freightPickup.consignor.site);
      this.applyValidatorsOn(newState.fields.freightPickup.outload);
    }

    newState.pickupSlotTrailerErrors = this.checkTrailerSlotNeeded(true) ? 'Please select a trailer slot' : null
    newState.deliverySlotTrailerErrors = this.checkTrailerSlotNeeded(false) ? 'Please select a trailer slot' : null

    this.applyValidatorsOn(newState.fields.customer);
    if (this.isDeliveredMovement())
      this.applyValidatorsOn(newState.fields.freightDelivery.inload.specs);
    this.changeFreightPickupTareWeightGrossWeightValidators(newState);
    this.changeFreightPickupGradeValidators(newState);
    const isStrictQuantityCommodity = this.isMeterCubeCommodityCommodity()
    if (newState.fields.plannedTonnage.value && this.hasSelectedBaseEntity() && !isStrictQuantityCommodity) {
      if ((!this.isEditForm || this.shouldShowCheckpointDetails()) && parseFloat(newState.fields.plannedTonnage.value) > parseFloat(this.unplannedContractTonnage())) {
        newState.fields.plannedTonnage.errors = [`Cannot be greater than Contract ${this.tonnageLabel.toLowerCase()}`];
      } else if (this.manualComplete && (parseFloat(newState.fields.plannedTonnage.value) < parseFloat(this.unplannedMinimumTonnage()))) {
        newState.fields.plannedTonnage.errors = [this.LESS_CONTRACT_TONNAGE_ERROR_MESSAGE];
      }
    }

    if (isStrictQuantityCommodity && newState.fields.plannedTonnage.errors)
      newState.fields.plannedTonnage.errors = []

    if (isStrictQuantityCommodity && this.isPlannedMovement()) {
      let errors = this.getQuantityFieldErrors('quantity');
      newState.fields.quantity.errors.push(...errors)
    }

    /** Validating specs */
    if (this.isCreatingCanolaMovement())
      this.applyValidatorsOn(newState.fields.freightPickup.outload.specs);
    if (this.isCreatingCanolaMovement() || isMoistureSpecMandatory())
      this.applyValidatorsOn(newState.fields.freightDelivery.inload.specs);
    if (get(this.state, 'selectedBaseEntityType') === 'order') {
        const freightDeliveryTime = newState.fields.freightDelivery.timeStart.value
        newState.fields.freightDelivery.timeStart.errors = newState.fields.freightDelivery.date.value && (
          !freightDeliveryTime || freightDeliveryTime === 'Invalid date'
        ) ? ['This field is required.'] : [];
        newState.fields.freightDelivery.date.errors = !newState.fields.freightDelivery.date.value && newState.fields.freightDelivery.timeStart.value ? ['This field is required.'] : newState.fields.freightDelivery.date.errors;

        const freightPickupTime = newState.fields.freightPickup.timeStart.value
        newState.fields.freightPickup.timeStart.errors = newState.fields.freightPickup.date.value && (
          !freightPickupTime || freightPickupTime === 'Invalid date'
        )? ['This field is required.'] : [];
        newState.fields.freightPickup.date.errors = !newState.fields.freightPickup.date.value && newState.fields.freightPickup.timeStart.value ? ['This field is required.'] : newState.fields.freightPickup.date.errors;
    }

    this.setTareWeightGrossWeightFieldErrors(newState);
    this.setState(newState, () => this.setSlotErrorsIfRequired());
    window.scrollTo(0, 0);
  }

  setTareWeightGrossWeightFieldErrors = (newState) => {
    if (newState.fields.freightPickup.outload.tareWeight.value && newState.fields.freightPickup.outload.grossWeight.value) {
      if (parseFloat(newState.fields.freightPickup.outload.tareWeight.value) >= parseFloat(newState.fields.freightPickup.outload.grossWeight.value)) {
        newState.fields.freightPickup.outload.grossWeight.errors = ["Gross Weight can't be less than Tare Weight"];
        newState.fields.freightPickup.outload.tareWeight.errors = ["Tare Weight can't be greater than Gross Weight"];
      }
    }

    if (newState.fields.freightDelivery.inload.tareWeight.value && newState.fields.freightDelivery.inload.grossWeight.value) {
      if (parseFloat(newState.fields.freightDelivery.inload.tareWeight.value) >= parseFloat(newState.fields.freightDelivery.inload.grossWeight.value)) {
        newState.fields.freightDelivery.inload.grossWeight.errors = ["Gross Weight can't be less than Tare Weight"];
        newState.fields.freightDelivery.inload.tareWeight.errors = ["Tare Weight can't be greater than Gross Weight"];
      }
    }
  };

  focusOnFirstErrorField() {
    const nestedFields = ["sellerDetails.companyId", "sellerDetails.contactId", "buyerDetails.companyId",
      "buyerDetails.contactId", "customer.companyId", "customer.contactId", "customer.ngrId", "freightPickup.date",
      "freightPickup.timeStart", "freightPickup.consignor.handlerId", "freightPickup.consignor.site.locationId",
      "freightPickup.orderNumber", "freightPickup.bookingNumber", "freightPickup.outload.truckId", "freightPickup.outload.quantity",
      "freightPickup.outload.ngrId", "freightPickup.outload.tareWeight", "freightPickup.outload.grossWeight", "freightPickup.netWeight", "freightPickup.outload.gradeId",
      "freightPickup.outload.docketNumber", "freightPickup.instructions",
      "freightDelivery.date", "freightDelivery.timeStart", "freightDelivery.consignee.handlerId",
      "freightDelivery.consignee.site.locationId", "freightDelivery.orderNumber",
      "freightDelivery.bookingNumber", "freightDelivery.inload.truckId", "freightDelivery.inload.quantity",
      "freightDelivery.inload.ngrId", "freightDelivery.inload.tareWeight", "freightDelivery.inload.grossWeight", "freightDelivery.netWeight", "freightDelivery.inload.gradeId",
      "freightDelivery.inload.docketNumber", "freightDelivery.instructions",
    ];

    const autoCompleteFields = ['externalReferenceNumber', "baseEntityId", "providerId", "assignToId", 'subFreightProviderId', "commodityId", "varietyId", "plannedGradeId", "plannedTruckId",
      'driverId', "paymentTermId", "generalConditionsSelector", "specialConditionsSelector"];

    for (let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];

      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if ((this.fieldsOrder[i] === "customer.companyId" && this.state.fields.customer.companyId.errors.length) ||
          (this.fieldsOrder[i] === "customer.contactId" && this.state.fields.customer.contactId.errors.length) ||
          (this.fieldsOrder[i] === "customer.ngrId" && this.state.fields.customer.ngrId.errors.length) ||
          (this.fieldsOrder[i] === "freightPickup.consignor.site.locationId" && this.state.fields.freightPickup.consignor.site.locationId.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.outload.truckId" && this.state.fields.freightPickup.outload.truckId.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.outload.gradeId" && this.state.fields.freightPickup.outload.gradeId.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.consignee.site.locationId" && this.state.fields.freightDelivery.consignee.site.locationId.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.inload.truckId" && this.state.fields.freightDelivery.inload.truckId.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.inload.gradeId" && this.state.fields.freightDelivery.inload.gradeId.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.inload.ngrId" && this.state.fields.freightDelivery.inload.ngrId.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.outload.ngrId" && this.state.fields.freightPickup.outload.ngrId.errors.length > 0) ||
          (
            this.fieldsOrder[i] === "sellerDetails.companyId" &&
            get(this.state.errors, 'party')
          ) ||
          (
            this.fieldsOrder[i] === "buyerDetails.companyId" &&
            get(this.state.errors, 'party')
          ) ||
          (
            this.fieldsOrder[i] === "sellerDetails.contactId" &&
            get(this.state.errors, 'party')
          ) ||
          (
            this.fieldsOrder[i] === "buyerDetails.contactId" &&
            get(this.state.errors, 'party')
          )) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
        if (
          (this.fieldsOrder[i] === "customer.contactId" && this.state.fields.customer.contactId.errors.length > 0 && formField.current.node) ||
          (this.fieldsOrder[i] === "freightPickup.consignor.handlerId" && this.state.fields.freightPickup.consignor.handlerId.errors.length > 0 && formField.current.node) ||
          (this.fieldsOrder[i] === "freightDelivery.consignee.handlerId" && this.state.fields.freightDelivery.consignee.handlerId.errors.length > 0 && formField.current.node)
        ) {
          formField.current.node.previousSibling.focus();
          break;
        }
        if ((this.fieldsOrder[i] === "freightPickup.date" && this.state.fields.freightPickup.date.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.timeStart" && this.state.fields.freightPickup.timeStart.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.orderNumber" && this.state.fields.freightPickup.orderNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.bookingNumber" && this.state.fields.freightPickup.bookingNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.outload.quantity" && this.state.fields.freightPickup.outload.quantity.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.outload.tareWeight" && this.state.fields.freightPickup.outload.tareWeight.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.outload.grossWeight" && this.state.fields.freightPickup.outload.grossWeight.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.netWeight" && this.state.fields.freightPickup.netWeight.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.outload.docketNumber" && this.state.fields.freightPickup.outload.docketNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightPickup.instructions" && this.state.fields.freightPickup.instructions.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.timeStart" && this.state.fields.freightDelivery.timeStart.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.date" && this.state.fields.freightDelivery.date.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.orderNumber" && this.state.fields.freightDelivery.orderNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.bookingNumber" && this.state.fields.freightDelivery.bookingNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.inload.quantity" && this.state.fields.freightDelivery.inload.quantity.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.inload.tareWeight" && this.state.fields.freightDelivery.inload.tareWeight.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.inload.grossWeight" && this.state.fields.freightDelivery.inload.grossWeight.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.netWeight" && this.state.fields.freightDelivery.netWeight.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.inload.docketNumber" && this.state.fields.freightDelivery.inload.docketNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.instructions" && this.state.fields.freightDelivery.instructions.errors.length > 0)) {
          if (formField.current) {
            formField.current.focus();
          }
          break;
        }
      } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
      } else if (field && field.errors.length > 0) {
        if (get(formField, 'current.node')) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          const current = get(formField, 'current');
          if (current)
            current.focus();
          break;
        }
      }
    }
  }

  applyValidatorsOn(fields) {
    Object.keys(fields).forEach((fieldName) => {
      const field = fields[fieldName]
      this.getFieldErrors(field, field?.value, fieldName)
    });
  }

  handleBlur(event) {
    this.setFieldErrors(event.target.id, event.target.value);
  }

  filterByCustomer = (customer, baseEntity) => {
    return baseEntity.customerId === customer.id;
  };

  filterByParty = (party, baseEntity) => {
    if (baseEntity.entity === 'order')
      return includes(
        [get(baseEntity, 'sellerCompanyId'), get(baseEntity, 'buyerCompanyId')], party.id
      );

    return baseEntity && includes(
      [baseEntity.seller.companyId, baseEntity.buyer.companyId], party.id
    );
  };

  applyFiltersByCustomer = (value, id, item) => {
    if (this.state.filterParty !== value) {
      let filteredEntities = null;
      if (item) {
        filteredEntities = filter(this.state.baseEntities, (baseEntity) => {
          return this.filterByCustomer(item, baseEntity);
        });
      } else {
        filteredEntities = this.state.baseEntities;
      }
      if (this.state.filterProvider) {
        filteredEntities = filter(filteredEntities, order => { return this.state.filterProvider === order.providerId; });
      }
      this.setState({ filteredEntities: filteredEntities, filterPartyObject: item, filterParty: value }, () => {
        if (isEmpty(this.state.filteredEntities)) {
          this.setState({ fields: { ...this.state.fields, baseEntityId: { ...this.state.fields.baseEntityId, value: undefined } } });
        }
      });
    }
  };

  applyFiltersByParty = (value, id, item) => {
    if (this.state.filterParty !== value) {
      let filteredEntities = null;
      if (item) {
        filteredEntities = filter(this.state.baseEntities, (baseEntity) => {
          return this.filterByParty(item, baseEntity);
        });
      } else {
        filteredEntities = this.state.baseEntities;
      }
      if (this.state.filterProvider) {
        filteredEntities = filter(filteredEntities, order => { return this.state.filterProvider === order.providerId; });
      }
      this.setState({ filteredEntities: filteredEntities, filterPartyObject: item, filterParty: value }, () => {
        if (isEmpty(this.state.filteredEntities)) {
          this.setState({ fields: { ...this.state.fields, baseEntityId: { ...this.state.fields.baseEntityId, value: undefined } } });
        }
      });
    }
  };

  applyFiltersByProvider = (value) => {
    if (this.state.filterProvider !== value) {
      let filteredEntities = null;
      if (value) {
        filteredEntities = filter(this.state.baseEntities, order => { return value === order.providerId; });
      } else {
        filteredEntities = this.state.baseEntities;
      }
      if (this.state.filterParty) {
        filteredEntities = filter(filteredEntities, (baseEntity) => {
          return this.filterByParty(this.state.filterPartyObject, baseEntity);
        });
      }
      if (this.state.filterCustomer) {
        filteredEntities = filter(filteredEntities, (baseEntity) => {
          return this.filterByCustomer(this.state.filterCustomerObject, baseEntity);
        });
      }
      this.setState({ filteredEntities: filteredEntities, filterProvider: value }, () => {
        if (isEmpty(this.state.filteredEntities)) {
          this.setState({ fields: { ...this.state.fields, baseEntityId: { ...this.state.fields.baseEntityId, value: undefined } } });
        }
      });
    }
  };


  calculateCommissionRate() {
    let commissionRate = 0;
    let rateFreightInValue = this.state.fields.rateFreightIn.value;
    if (get(this.editMovement, 'invoicing') === 'Freight Provider to Invoice Broker') {
      const rateFreightIn = get(this.editMovement, 'rateFreightIn');
      const rateFreightOut = get(this.editMovement, 'rateFreightOut');
      if (rateFreightIn && rateFreightOut) {
        commissionRate = max([parseFloat(rateFreightIn) - parseFloat(rateFreightOut), 0]);
        this.setState({
          commissionRate: commissionRate.toFixed(2),
          fields: {
            ...this.state.fields,
            rateFreightIn: {
              ...this.state.fields.rateFreightIn,
              value: rateFreightInValue
            }
          }
        });
      }
    }
  }

  pickUpDateMin() {
    let minimumDate = new Date();
    if (this.isEditForm) {
      const pickupDate = get(this.editMovement, 'freightPickup.date');
      minimumDate = this.state.fields.status.value !== 'delivered' ? moment() : pickupDate ? moment(pickupDate) : minimumDate;
    }
    return (this.state.fields.status.value !== 'delivered') ? minimumDate : null;
  }

  pickUpDateMax() {
    return this.state.fields.freightDelivery.date.value ? new Date(this.state.fields.freightDelivery.date.value) : this.state.fields.status.value !== 'delivered' ? null : new Date();
  }

  deliveryDateMin() {
    let minimumDate = this.state.fields.freightPickup.date.value ? new Date(this.state.fields.freightPickup.date.value) : this.state.fields.status.value === 'delivered' ? null : new Date();
    if (this.isEditForm) {
      const pickupDate = this.state.fields.freightPickup.date.value || get(this.editMovement, 'freightPickup.date');
      minimumDate = pickupDate ? moment(pickupDate) : minimumDate;
    }
    return minimumDate;
  }

  deliveryDateMax() {
    return this.state.fields.status.value !== 'delivered' ? null : new Date();
  }

  deliveryNetWeight() {
    let netWeight = parseFloat(this.state.fields.freightDelivery.inload.grossWeight.value) - parseFloat(this.state.fields.freightDelivery.inload.tareWeight.value);
    if (get(this.state.fields.freightPickup.selectedContainer, 'type') === 'container')
      netWeight = parseFloat(this.state.fields.freightDelivery.inload.grossWeight.value) - parseFloat(this.state.fields.freightDelivery.inload.containerTareWeight.value || 0) - parseFloat(this.state.fields.freightDelivery.inload.tareWeight.value || 0);
    if (isNaN(netWeight))
      netWeight = parseFloat(this.state.fields.freightDelivery.netWeight.value);
    return isNaN(netWeight) ? 0.0 : parseFloat(netWeight).toFixed(2);
  }

  pickupNetWeight() {
    var netWeight = parseFloat(this.state.fields.freightPickup.outload.grossWeight.value) - parseFloat(this.state.fields.freightPickup.outload.tareWeight.value);
    if (get(this.state.fields.freightPickup.selectedContainer, 'type') === 'container')
      netWeight = parseFloat(this.state.fields.freightPickup.outload.grossWeight.value) - parseFloat(this.state.fields.freightPickup.outload.containerTareWeight.value || 0) - parseFloat(this.state.fields.freightPickup.outload.tareWeight.value || 0);
    if (isNaN(netWeight))
      netWeight = parseFloat(this.state.fields.freightPickup.netWeight.value);
    return isNaN(netWeight) ? 0.0 : parseFloat(netWeight).toFixed(2);
  }

  isBrokerAddingTrucksForGrower() {
    return (get(this.getSelectedProvider(), 'typeId') === GROWER && get(this.props.currentUser, 'company.typeId') === BROKER && get(this.getSelectedProvider(), 'isRegistered'));
  }

  getSitesValidity(data) {
    const errorMessages = [];
    const fieldsToReset = [];

    if (data.status === 'delivered' && !this.isEditForm) {
      if (!this.state.isDeliverySiteCompanyHandler)
        this.checkValidityOfPickupSites(errorMessages, fieldsToReset, data);
      if (!this.state.isPickupSiteCompanyHandler)
        this.checkValidityOfDeliverySites(errorMessages, fieldsToReset, data);
    }
    const shouldCreateAutoInload = this.state.inloadDeficit > 0;

    return {
      errorMessages,
      fieldsToReset,
      shouldCreateAutoInload
    };
  }

  checkValidityOfPickupSites(errorMessages, fieldsToReset, data) {
    const grossWeight = data.freightPickup.outload.grossWeight;
    const tareWeight = data.freightPickup.outload.tareWeight;
    let netWeight = grossWeight && tareWeight ? parseFloat(grossWeight) - parseFloat(tareWeight) : null;
    if (!netWeight)
      netWeight = data.freightPickup.netWeight;

    if (grossWeight && tareWeight && !netWeight && this.state.fields.freightPickup.consignor.site.locationType.value !== 'farmfield') {
      errorMessages.push('Gross weight should be greater than Tare weight');
      fieldsToReset.push('fields.freightPickup.outload.grossWeight.value', 'fields.freightPickup.outload.tareWeight.value');
    }
  }

  checkValidityOfDeliverySites = (errorMessages, fieldsToReset, data) => {
    const grossWeight = data.freightDelivery.inload.grossWeight;
    const tareWeight = data.freightDelivery.inload.tareWeight;
    let netWeight = grossWeight && tareWeight ? parseFloat(grossWeight) - parseFloat(tareWeight) : null;
    if (!netWeight) netWeight = data.freightPickup.netWeight;
    if (grossWeight && tareWeight && !netWeight) {
      errorMessages.push('Gross weight should be greater than Tare weight');
      fieldsToReset.push('fields.freightDelivery.inload.grossWeight.value', 'fields.freightDelivery.inload.tareWeight.value');
    }
  };

  getModelSpecsByCommoditySpecs(commoditySpecs, commodityId) {
    const modelSpecs = {};
    const isMoistureMandatory = isMoistureSpecMandatory() && !this.isEditForm;
    const mandatorySpecs = get(this.state.selectedConsignee, 'deliveryMandatoryFields.specs')
    const selectedCommodity = this.getSelectedCommodity(commodityId);
    let mandatorySpecForSelectedCommodity = [];
    if (mandatorySpecs && selectedCommodity && this.isDeliveredMovement())
      mandatorySpecForSelectedCommodity = get(mandatorySpecs, selectedCommodity.name.toLowerCase(), [])
    if (!isEmpty(commoditySpecs)) {
      forEach(orderBy(commoditySpecs, 'order'), (spec) => {
        let validators = [valueBetween(spec.min, spec.max, true)];
        if (((commodityId === COMMODITIES.CANOLA && includes(['IMPU', 'COIL'], spec.code)) || (isMoistureMandatory && spec.code === 'M')))
          validators.push(required())
        if (includes(mandatorySpecForSelectedCommodity, spec.code.toLowerCase()))
          validators.push(required())
        modelSpecs[spec.code] = {
          ...FIELD,
          value: '',
          validators,
          warnings: [],
        };
      });
    }
    return modelSpecs;
  }

  async getModelSpecsByStorageSpecs(commoditySpecs) {
    const modelSpecs = {};
    const isMoistureMandatory = isMoistureSpecMandatory() && !this.isEditForm;
    let response = {};
    if (isEmpty(commoditySpecs))
      commoditySpecs = orderBy(get(find(this.props.commodities, { id: this.state.fields.commodityId.value }), 'specs', []), 'order');
    let pickupSite = find(this.state.consignors, { id: get(this.state, `fields.freightPickup.consignor.handlerId.value`)});
    if (!isEmpty(commoditySpecs)) {
      if (
        this.isDeliveredMovement() &&
        !this.isEditForm &&
        this.state.fields.freightPickup.consignor.site.locationType.value &&
        this.state.fields.freightPickup.consignor.site.locationType.value != 'farmfield' &&
        !get(this.state.selectedConsignorSite, 'isGate') &&
        this.state.fields.freightPickup.consignor.site.locationId.value &&
        this.state.fields.commodityId.value &&
        (get(pickupSite, 'stocksManagement') || (get(this.state.selectedConsignorSite, 'type') === 'container'))
      ) {
        response = await APIService.storages(this.state.fields.freightPickup.consignor.site.locationId.value).appendToUrl(`estimate_specs_grade?commodity_id=${this.state.fields.commodityId.value}`).get();
      }
      forEach(orderBy(commoditySpecs, 'order'), (spec) => {
        let specValue = get(response, `specs.${spec.code.toLowerCase()}`, false) ? parseFloat(get(response, `specs.${spec.code.toLowerCase()}`, 0)).toFixed(2) : null;
        let validators = [valueBetween(spec.min, spec.max, true)];
        if (((this.state.fields.commodityId.value === COMMODITIES.CANOLA && includes(['IMPU', 'COIL'], spec.code)) || (isMoistureMandatory && spec.code === 'M')))
          validators.push(required())
        modelSpecs[spec.code] = {
          ...FIELD,
          value: specValue,
          validators,
        };
      });
    }
    this.setState(
      state => ({
        ...state,
        fields: set(state.fields, 'freightPickup.outload.specs', modelSpecs),
      })
    );
  }

  getBaseEntityFieldLabel() {
    return this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY ? "Order Number (Optional)" : "Contract/Order Number";
  }

  isCreatingCanolaMovement = () => {
    return !this.isEditForm && this.state.fields.commodityId.value == COMMODITIES.CANOLA;
  };

  setConsignorConsigneeValidators(newState) {
    if (newState.selectedBaseEntity) {
      const pricePointId = get(newState.selectedBaseEntity, 'pricePoint.id') || get(newState.selectedBaseEntity, 'commodityContract.pricePoint.id');
      const pickupOrder = get(newState.selectedBaseEntity, 'pickupOrder');
      const deliveryOrder = get(newState.selectedBaseEntity, 'deliveryOrder');
      if (!isEmpty(newState.selectedBaseEntityRaw.consignorsWithSites) && !this.isEditForm && (pricePointId === PRICE_POINTS.EX_FARM_STORE || pricePointId === PRICE_POINTS.FREE_ON_TRUCK)) {
        newState.consignors = map(newState.selectedBaseEntityRaw.consignorsWithSites, handler => {
          return {
            id: get(handler, 'handlerId', null),
            name: get(handler, 'handler.displayName', null), 
            ld: get(handler, 'ld', null), 
            populateDefaultWeights: get(handler, 'handler.populateDefaultWeights', null),
            address: get(handler, 'handler.address.address', null),
            allowOutloadSlotOrderBooking: get(handler, 'handler.allowOutloadSlotOrderBooking', null), 
            allowInloadSlotOrderBooking: get(handler, 'handler.allowInloadSlotOrderBooking', null)
          };
        });
      }
      if (newState.selectedBaseEntityRaw.entity === 'contract' || get(newState.selectedBaseEntityRaw, 'isCallOnGrain')) {
        if (pricePointId === PRICE_POINTS.EX_FARM_STORE || pricePointId === PRICE_POINTS.FREE_ON_TRUCK || pickupOrder) {
          if (!isEmpty(newState.selectedBaseEntityRaw.consignorsWithSites) && newState.consignors.length > 1) {
            newState.isConsignorEditable = true;
            newState.isPickupSiteMandatoryBasedOnPricePoint = true;
          } else
            newState.isConsignorEditable = false;
          if (this.isEditForm) {
            newState.isConsignorEditable = false;
            newState.fields.freightPickup.consignor.handlerId.validators = [];
            newState.fields.freightPickup.consignor.site.locationType.validators = [];
            newState.fields.freightPickup.consignor.site.locationId.validators = [];
          }
        } else {
          newState.isConsignorEditable = true;
        }
        if (includes(DELIVERY_MANDATORY_PRICE_POINTS, pricePointId) || deliveryOrder) {
          newState.isConsigneeEditable = false;
          if (this.isEditForm) {
            newState.fields.freightDelivery.consignee.handlerId.validators = [];
            newState.fields.freightDelivery.consignee.site.locationType.validators = [];
            newState.fields.freightDelivery.consignee.site.locationId.validators = [];
          }

          const consigneesWithSites = get(this.state.selectedBaseEntity, 'consigneesWithSites');
          if (consigneesWithSites && consigneesWithSites.length > 1
            && get(this.state.selectedBaseEntity, 'pricePoint.id') === PRICE_POINTS.DELIVERED_BUYER) {
            newState.isConsigneeEditable = true;
            newState.isDeliverySiteMandatoryBasedOnPricePoint = true;
          }

          if (!isEmpty(newState.selectedBaseEntityRaw.consigneesWithSites) && newState.selectedBaseEntityRaw.consigneesWithSites.length > 1) {
            newState.isConsigneeEditable = true;
            newState.isDeliverySiteMandatoryBasedOnPricePoint = true;
            newState.consignees = map(newState.selectedBaseEntityRaw.consigneesWithSites, handler => {
              return {
                id: get(handler, 'handlerId', null), 
                name: get(handler, 'handler.displayName', null),
                ld: get(handler, 'ld', null), populateDefaultWeights: get(handler, 'handler.populateDefaultWeights', null),
                address: get(handler, 'handler.address.address', null),
                allowOutloadSlotOrderBooking: get(handler, 'handler.allowOutloadSlotOrderBooking', null), 
                allowInloadSlotOrderBooking: get(handler, 'handler.allowInloadSlotOrderBooking', null)
              };
            });
          }
        } else {
          newState.isConsigneeEditable = true;
        }
      }
      if (!get(newState.selectedBaseEntityRaw, 'isCallOnGrain') && (newState.selectedBaseEntityRaw.entity === 'order' || newState.selectedBaseEntityRaw.entity === 'freightorder')) {
        if (get(newState.selectedBaseEntityRaw, 'consignorHandlerName')) {
          newState.isConsignorEditable = false;
          if (this.isEditForm) {
            newState.fields.freightPickup.consignor.handlerId.validators = [];
            newState.fields.freightPickup.consignor.site.locationType.validators = [];
            newState.fields.freightPickup.consignor.site.locationId.validators = [];
          }
        } else {
          newState.isConsignorEditable = true;
        }
        if (get(newState.selectedBaseEntityRaw, 'consigneeHandlerName')) {
          if (this.isEditForm) {
            newState.fields.freightDelivery.consignee.handlerId.validators = [];
            newState.fields.freightDelivery.consignee.site.locationType.validators = [];
            newState.fields.freightDelivery.consignee.site.locationId.validators = [];
          }
          newState.isConsigneeEditable = false;
        } else {
          newState.isConsigneeEditable = true;
        }
      }
    }
  }

  handleOpenPreview = () => {
    if (this.state.selectedBaseEntity && this.state.fields.status.value === 'delivered' && !this.editMovement && this.canUserRaiseAmendRequest()) {
      const checkpoint = this.state.selectedBaseEntity.weightId === WEIGHT_DESTINATION_ID ? 'freightDelivery' : 'freightPickup';
      const maxAllowedTonnage = this.getMaxAllowedWeight();
      const netWeight = get(this.state.fields, `${checkpoint}.netWeight.value`)
      if (netWeight > maxAllowedTonnage)
        this.setState({showAmendTonnagePopup: true});
      else
        this.setState({ preview: true });
    }
    else
      this.setState({ preview: true });
  };

  handleClosePreview = () => {
    this.setState({ preview: false });
  };

  isCustomerFieldsDisabled(isBaseEntityAnOrder) {
    let isCustomerEditable = this.isEditForm || this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER || isBaseEntityAnOrder;
    return get(this.editMovement, 'customer.companyId') && !SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'customer.companyId')) && isCustomerEditable;
  }

  isCustomerNGRRequired = () => {
    return this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY ?
      (this.state.freightPickupSiteIsSystemStorage || this.state.freightDeliverySiteIsSystemStorage) :
      ((this.state.isCustomerIsSeller && this.state.freightPickupSiteIsSystemStorage) || (this.state.isCustomerIsBuyer && this.state.freightDeliverySiteIsSystemStorage));
  };

  isLoadNGRRequired = (loadType) => {
    const freightPickupSiteIsSystemStorage = loadType === 'inload' ? this.state.freightDeliverySiteIsSystemStorage : this.state.freightPickupSiteIsSystemStorage;
    return this.isDeliveredMovement() && !this.isEditForm && freightPickupSiteIsSystemStorage;
  };

  getPreviewFields = () => {
    var freightRateIn = 0;
    const fields = this.state.fields;
    const currentUserCompany = this.isEditForm ? get(this.editMovement, 'createdBy.company') : this.props.currentUser.company;
    const canViewPickup = this.editMovement?.id ? this.editMovement.canViewPickup : true
    const canViewDelivery = this.editMovement?.id ? this.editMovement.canViewDelivery : true
    const customer = fields.customer;
    if (this.hasCommissionRate()) {
      freightRateIn = fields.rateFreightIn.value ? `${this.state.currency} ${parseFloat(fields.rateFreightIn.value).toFixed(2)}` : undefined;
    }
    const freightRateOut = fields.rateFreightOut.value ? `${this.state.currency} ${parseFloat(fields.rateFreightOut.value).toFixed(2)}` : undefined;
    const rateOvers = fields.rateOvers.value ? `${this.state.currency} ${parseFloat(fields.rateOvers.value).toFixed(2)}` : undefined;
    const submitData = this.getSubmitData();
    const freightContractDetails = {
      ...(this.showInvoicingField() && { 'Invoicing': fields.invoicing.value }),
      ...(this.showPaymentTermField() && { 'Payment Term': get(find(this.props.paymentTerms, { id: fields.paymentTermId.value }), 'name') }),
      ...(this.hasCommissionRate() && this.showFreightRateInField() && { 'Freight Rate In': freightRateIn }),
    };
    const consignorHandlerId = get(submitData, 'freightPickup.consignor.handlerId');
    const consigneeHandlerId = get(submitData, 'freightDelivery.consignee.handlerId');
    const consignor = this.getEstablishment(consignorHandlerId, null, this.state.consignors);
    const consignee = this.getEstablishment(consigneeHandlerId, null, this.state.consignees);

    const pickupLocationId = get(submitData, 'freightPickup.consignor.sites.0.locationId');
    const pickupLocationType = get(submitData, 'freightPickup.consignor.sites.0.locationType');
    const deliveryLocationId = get(submitData, 'freightDelivery.consignee.sites.0.locationId');
    const deliveryLocationType = get(submitData, 'freightDelivery.consignee.sites.0.locationType');
    const pickupSite = this.getEstablishment(pickupLocationId, pickupLocationType, this.state.consignorSites);
    const deliverySite = this.getEstablishment(deliveryLocationId, deliveryLocationType, this.state.consigneeSites);
    const pickupDate = get(submitData, 'freightPickup.date') ? moment(get(submitData, 'freightPickup.date')).format(this.state.countryFormats.dateDisplay) : '';
    const isPickupTimeValid = moment(get(submitData, 'freightPickup.timeStart'), "HH:mm:ss").isValid();
    const pickupDetails = (canViewPickup && (!this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails()))) ? {
      'PICKUP DATE': pickupDate,
      'PICKUP TIME': isPickupTimeValid ? moment(get(submitData, 'freightPickup.timeStart'), "HH:mm:ss").format("hh:mm A") : '',
      'PICKUP SITE': this.getEstablishmentName(consignor),
      'PICKUP STORAGE': this.getEstablishmentName(pickupSite),
      'PICKUP SITE PHONE': toPhoneFormat(getSitePhoneNumber(consignor, pickupSite)),
      'PICKUP ADDRESS': consignor ? get(consignor, 'address') : '',
      'PICKUP ORDER NO': fields.freightPickup.orderNumber.value,
      'PICKUP BOOKING NO': fields.freightPickup.bookingNumber.value,
    } : {};
    const deliveryDate = get(submitData, 'freightDelivery.date') ? moment(get(submitData, 'freightDelivery.date')).format(this.state.countryFormats.dateDisplay) : '';
    const isDeliveryTimeValid = moment(get(submitData, 'freightDelivery.timeStart'), "HH:mm:ss").isValid();
    const deliveryDetails = (canViewDelivery && (!this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails()))) ? {
      'DELIVERY DATE': deliveryDate,
      'DELIVERY TIME': isDeliveryTimeValid ? moment(get(submitData, 'freightDelivery.timeStart'), "HH:mm:ss").format("hh:mm A") : '',
      'DELIVERY SITE': this.getEstablishmentName(consignee),
      'DELIVERY STORAGE': this.getEstablishmentName(deliverySite),
      'DELIVERY SITE PHONE': toPhoneFormat(getSitePhoneNumber(consignee, deliverySite)),
      'DELIVERY ADDRESS': consignee ? get(consignee, 'address') : '',
      'DELIVERY ORDER NO': fields.freightDelivery.orderNumber.value,
      'DELIVERY BOOKING NO': fields.freightDelivery.bookingNumber.value,
    } : {};
    let truck;
    if (this.isDeliveredMovement() && !this.isEditForm) {
      if(canViewPickup) {
      truck = find([...this.state.providerTrucks, ...this.state.selectedTrucks], { id: get(submitData, 'freightPickup.outload.truckId') })
      pickupDetails[getCountryLabel('rego').toUpperCase()] = get(truck, 'rego');
      pickupDetails['Season'] = get(submitData, 'freightPickup.outload.season');
      pickupDetails['NGR'] = this.getPropertyById(get(submitData, 'freightPickup.outload.ngrId'), 'ngrNumber', this.state.freightPickupNgrs);
      if (this.hasQuantityBasedCommodity() && this.isDeliveredMovement())
        pickupDetails[this.quantityLabel()] = get(submitData, 'freightPickup.outload.quantity') + this.quantityUnit();
      const outloadTareWeight = get(submitData, 'freightPickup.outload.tareWeight');
      if (outloadTareWeight) pickupDetails['Tare Weight'] = get(submitData, 'freightPickup.outload.tareWeight', '') + ' ' + truck?.unit;
      else pickupDetails['Tare Weight'] = '';
      const outloadGrossWeight = get(submitData, 'freightPickup.outload.grossWeight', '');
      if (outloadGrossWeight) pickupDetails['Gross Weight'] = get(submitData, 'freightPickup.outload.grossWeight', '') + ' ' + truck?.unit;
      else pickupDetails['Gross Weight'] = '';
      if (outloadTareWeight && outloadGrossWeight) {
        pickupDetails['Net Weight'] = this.pickupNetWeight() + ' ' + truck?.unit;
      } else {
        let estimatedNewWeight = get(submitData, 'freightPickup.outload.estimatedNetWeight', '')
        if (estimatedNewWeight)
          pickupDetails['Net Weight'] = get(submitData, 'freightPickup.outload.estimatedNetWeight', '') + ' ' + truck?.unit;
      }

      pickupDetails['Variety'] = get(find(this.props.varieties, { id: get(submitData, 'freightPickup.outload.varietyId') }), 'name');
      pickupDetails['Grade'] = getGradeName({ season: get(submitData, 'freightPickup.outload.season'), variety: find(this.props.varieties, { id: get(submitData, 'freightPickup.outload.varietyId') }), grade: find(this.props.grades, { id: get(submitData, 'freightPickup.outload.gradeId') }) });
      pickupDetails[`${getCountryLabel('docket')} No`] = get(submitData, 'freightPickup.outload.docketNumber');
      pickupDetails['Comment'] = get(submitData, 'freightPickup.outload.comment');
      }
      if(canViewDelivery) {
      truck = find([...this.state.providerTrucks, ...this.state.selectedTrucks], { id: get(submitData, 'freightDelivery.inload.truckId') })

      deliveryDetails[getCountryLabel('rego').toUpperCase()] = get(truck, 'rego');
      deliveryDetails['Season'] = get(submitData, 'freightDelivery.inload.season');
      deliveryDetails['NGR'] = this.getPropertyById(get(submitData, 'freightDelivery.inload.ngrId'), 'ngrNumber', this.state.freightDeliveryNgrs);
      if (this.hasQuantityBasedCommodity() && this.isDeliveredMovement())
        deliveryDetails[this.quantityLabel()] = get(submitData, 'freightDelivery.inload.quantity') + this.quantityUnit();
      const inloadTareWeight = get(submitData, 'freightDelivery.inload.tareWeight', '');
      if (inloadTareWeight) deliveryDetails['Tare Weight'] = inloadTareWeight + ' ' + truck?.unit;
      else deliveryDetails['Tare Weight'] = '';
      const inloadGrossWeight = get(submitData, 'freightDelivery.inload.grossWeight', '');
      if (inloadGrossWeight) deliveryDetails['Gross Weight'] = inloadGrossWeight + ' ' + truck?.unit;
      else deliveryDetails['Gross Weight'] = '';
      const deliveryNetWeight = this.deliveryNetWeight();
      if (deliveryNetWeight)
        deliveryDetails['Net Weight'] = deliveryNetWeight + ' ' + truck?.unit;
      else {
        let estimatedNewWeight = get(submitData, 'freightDelivery.inload.estimatedNetWeight', '')
        if (estimatedNewWeight)
          deliveryDetails['Net Weight'] = estimatedNewWeight + ' ' + truck?.unit;
      }
      deliveryDetails['Variety'] = get(find(this.props.varieties, { id: get(submitData, 'freightDelivery.inload.varietyId') }), 'name');
      deliveryDetails['Grade'] = getGradeName({ season: get(submitData, 'freightDelivery.inload.season'), variety: find(this.props.varieties, { id: get(submitData, 'freightDelivery.inload.varietyId') }), grade: find(this.props.grades, { id: get(submitData, 'freightDelivery.inload.gradeId') }) });
      deliveryDetails[`${getCountryLabel('docket')} No`] = get(submitData, 'freightDelivery.inload.docketNumber');
      deliveryDetails['Comment'] = get(submitData, 'freightDelivery.inload.comment');
      }

      const pickupSpecs = get(submitData, 'freightPickup.outload.specs');
      const deliverySpecs = get(submitData, 'freightDelivery.inload.specs');
      if (canViewPickup && pickupSpecs) {
        forEach(pickupSpecs, (value, key) => {
          pickupDetails[key] = value;
        });
      }
      if (canViewDelivery && deliverySpecs) {
        forEach(deliverySpecs, (value, key) => {
          deliveryDetails[key] = value;
        });
      }
    }

    if (this.isPlannedMovement()) {
      pickupDetails['PICKUP INSTRUCTIONS'] = canViewPickup ? fields.freightPickup.instructions.value : '';
      deliveryDetails['DELIVERY INSTRUCTIONS'] = canViewDelivery ? fields.freightDelivery.instructions.value : '';
    }

    if (this.showFreightRateOutField()) {
      // let label = this.hasCommissionRate() ? 'Freight Rate' : 'Freight Rate Out';
      let label = 'Freight Rate (Optional)';
      freightContractDetails[label] = freightRateOut;
    }

    if (this.showOversRateField()) {
      freightContractDetails['Overs Rate'] = rateOvers;
    }

    if (this.state.totalDistanceLabel) {
      freightContractDetails['Est. Distance'] = this.state.totalDistanceLabel;
    }

    if (this.state.estimatedTime) {
      freightContractDetails['Est. Travel Time'] = this.state.estimatedTime;
    }
    const commodityDetails = {
      'COMMODITY': get(find(this.props.commodities, { id: fields.commodityId.value }), 'displayName'),
    };
    if (this.hasQuantityBasedCommodity() && !this.isDeliveredMovement())
      commodityDetails[this.quantityLabel() ? this.quantityLabel().toUpperCase() : ''] = this.getQuantityWithLabel();

    if (this.isPlannedMovement() || (this.isEditForm && this.shouldShowCheckpointDetails())) {
      truck = find(this.state.providerTrucks, { id: fields.plannedTruckId.value || submitData.plannedTruckId })
      commodityDetails['GRADE'] = getGradeName({ season: fields.season.value, variety: find(this.props.varieties, { id: fields.varietyId.value }), grade: find(this.props.grades, { id: fields.plannedGradeId.value }) });
      commodityDetails[this.tonnageLabel.toUpperCase()] = fields.plannedTonnage.value + ' ' + truck?.unit;
      commodityDetails['VARIETY'] = get(find(this.props.varieties, { id: fields.varietyId.value }), 'name');
      commodityDetails['SEASON'] = fields.season.value;
      commodityDetails[getCountryLabel('rego').toUpperCase()] = get(truck, 'rego');
    }

    return {
      identifier: this.state.fields.identifier.value,
      contractReferenceNumber: get(this.state.selectedBaseEntityRaw, 'referenceNumber'),
      externalReferenceNumber: get(this.state.fields, 'externalReferenceNumber.value'),
      dateIssued: moment(new Date).format(this.state.countryFormats.dateDisplay),
      currentUser: this.isEditForm ? get(this.editMovement, 'createdBy') : this.props.currentUser,
      currentUserCompanyName: get(currentUserCompany, 'name'),
      currentUserCompanyWebsite: get(currentUserCompany, 'website'),
      currentUserCompanyPhone: get(currentUserCompany, 'mobile'),
      currentUserCompanyAbn: get(currentUserCompany, 'abn'),
      currentUserCompanyAddress: get(currentUserCompany, 'address.address'),
      currentUserCompanyLogo: this.state.base64Logo,
      customerDetails: {
        customer: find(this.getCustomers(), { id: customer.companyId.value }) || get(customer, 'company'),
        customerContact: find(this.state.customerContacts, { id: fields.customer.contactId.value }) || this.state.customerContact,
        customerNgr: this.getPropertyById(fields.customer.ngrId.value, 'ngrNumber', this.state.customerNgrs) || get(this.state.customerNgr, 'ngrNumber'),
      },
      providerDetails: {
        provider: this.getSelectedProvider(),
        providerContact: find(this.state.providerEmployees, { id: fields.assignToId.value }),
      },
      subFreightProvider: find(this.state.subFreightProviders, { id: fields.subFreightProviderId.value }),
      commodityDetails,
      freightContractDetails,
      specialConditions: fields.specialConditions.value,
      generalConditions: fields.generalConditions.value,
      pickupDetails,
      deliveryDetails,
      pickupHeader: this.state.pickupTerm,
      deliveryHeader: this.state.deliveryTerm,
      quantity: fields.quantity.value,
      quantityLabel: this.quantityLabel(),
      quantityUnit: this.quantityUnit(),
      note: pick(fields.note, ['description', 'attachments']),
    };
  };

  getEstablishment = (id, type, parties = []) => type ? find(parties, { id: id, entity: type }) : find(parties, { id: id });

  getEstablishmentName = (establishment) => {
    return get(establishment, 'entity') === 'farm' ? get(establishment, 'displayName') : get(establishment, 'name');
  };

  getPropertyById(id, field, list = []) {
    if (!isEmpty(list)) {
      const element = find(list, {id: id});
      return element && get(element, field);
    }
  }

  isPlannedTruckRegoFieldDisabled() {
    return this.isEditForm ? includes(['in_progress', 'delivered', 'completed'], get(this.editMovement, 'status')) : false;
  }

  showFreightContractDetailsSectionOnEdit = () => {
    return !get(this.editMovement, 'orderId') && get(this.editMovement, 'canEditInvoicingSection');
  };

  showInvoicingField = () => {
    return (!this.state.fields.isSelf.value && this.showFreightContractDetailsSection()) || (!this.state.isDependent && this.isSelectedOrderCallOnGrain());
  };

  showPaymentTermField = () => {
    return (!this.state.fields.isSelf.value && this.showFreightContractDetailsSection()) || (!this.state.isDependent && this.isSelectedOrderCallOnGrain());
  };

  showFreightContractDetailsSection = () => {
    if (get(this.state.fields, 'isSelf.value'))
      return false;
    if (this.isEditForm)
      return this.showFreightContractDetailsSectionOnEdit();
    if (get(this.state.selectedBaseEntity, 'entity') === 'order')
      return this.isSelectedOrderCallOnGrain() && (get(this.state, 'selectedBaseEntity.userHasDeliveryOnus') || this.state.fields.providerId.value === this.props.currentUser.companyId);
    if (get(this.state, 'selectedBaseEntity.entity') === 'contract' && get(this.state, 'selectedBaseEntity.userHasDeliveryOnus') === false && this.state.fields.providerId.value !== this.props.currentUser.companyId)
      return false;
    return true;
  };

  showFreightRateOutField = () => {
    return this.showFreightContractDetailsSection();
  };

  getFreightRateOutFieldLabel = () => {
    // return this.state.fields.isSelf.value || this.state.fields.providerId.value == this.props.currentUser.companyId ? 'Freight Rate' : 'Freight Rate Out';
    return 'Freight Rate (Optional)';
  };

  showFreightRateInField = () => {
    return this.showFreightContractDetailsSection() &&
      (
        get(this, 'editMovement.isCreator') ||
        (
          get(this.state.selectedBaseEntity, 'entity') !== 'order' &&
          !this.state.fields.isSelf.value && this.state.fields.providerId.value !==
          this.props.currentUser.companyId
        )
      );
  };

  showOversRateField = () => {
    if (this.isUserNonDeliveryOnus())
      return false;
    if (get(this.state, 'selectedBaseEntity.entity') === 'contract' && get(this.state, 'selectedBaseEntity.userHasDeliveryOnus') === false && this.state.fields.providerId.value !== this.props.currentUser.companyId)
      return false;
    if (get(this.state.selectedBaseEntity, 'entity') === 'order' && this.isSelectedOrderCallOnGrain() && get(this.state, 'selectedBaseEntity.userHasDeliveryOnus') === false && this.state.fields.providerId.value !== this.props.currentUser.companyId)
      return false;
    return this.state.fields.status.value === 'delivered';
  };

  showCommissionRateField = () => {
    return this.showFreightContractDetailsSection() && !this.state.fields.isSelf.value && this.state.fields.providerId.value !== this.props.currentUser.companyId;
  };

  showConditions = () => {
    return this.isEditForm ? this.showFreightContractDetailsSectionOnEdit() : !(this.state.fields.isSelf.value || get(this.state.selectedBaseEntity, 'entity') === 'order') && get(this.state, 'selectedBaseEntity.userHasDeliveryOnus') !== false;
  };

  showFreightContractConditions = () => {
    const showFreightPickupOrDeliverySection = (!this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails()));
    return some([this.showInvoicingField(), this.showPaymentTermField(), showFreightPickupOrDeliverySection, this.showFreightRateInField() && this.hasCommissionRate(), this.showFreightContractDetailsSection(), this.showOversRateField(), this.showCommissionRateField() && this.hasCommissionRate()]);
  };

  isPlannedMovement = () => {
    return this.state.fields.status.value === 'planned';
  };

  isDeliveredMovement = () => {
    return this.state.fields.status.value === 'delivered';
  };

  canEditInstructions = () => !includes(['planned', 'confirmed'], this.state.fields.status.value);

  shouldDisplayAddTruckButton = () => {
    return this.state.fields.providerId.value;
  };

  addFreightProviderContactButton(disabled) {
    return (this.state.fields.providerId.value && get(this.state.selectedBaseEntity, 'entity') !== 'order') &&
      <AddCircleIconWithTooltip
        id="addFreightProviderContact"
        title="Add Freight Provider Contact"
        onClick={() => this.openFreightProviderSideDrawer()}
        disabled={disabled}
      />;
  }

  addCustomerContactButton(disabled) {
    return (this.state.fields.customer.companyId.value && this.state.fields.typeId.value !== FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER && get(this.state.selectedBaseEntity, 'entity') !== 'order') &&
      <AddCircleIconWithTooltip
        id="addCustomerContact"
        title="Add Customer Contact"
        onClick={() => this.openCustomerSideDrawer()}
        disabled={disabled}
      />;
  }

  addGeneralConditionButton() {
    return <AddCircleIconWithTooltip
      id="addGeneralCondition"
      title="Add General Condition"
      onClick={() => this.openGeneralConditionSideDrawer()}
    />;
  }

  addSpecialConditionButton() {
    return <AddCircleIconWithTooltip
      id="addSpecialCondition"
      title="Add Special Condition"
      onClick={() => this.openSpecialConditionSideDrawer()}
    />;
  }

  addCustomerNgrButton(disabled) {
    return (this.state.fields.customer.companyId.value && !(this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER) && !(get(this.state.selectedBaseEntity, 'entity') === 'order')) &&
      <AddCircleIconWithTooltip
        id="addCustomerNgr"
        title="Add Customer NGR"
        onClick={() => this.openNgrSideDrawer(this.state.fields.customer)}
        disabled={disabled}
      />;
  }

  setDefaultGeneralCondition(condition) {
    this.handleSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
    }
  }

  setDefaultSpecialCondition(condition) {
    this.handleSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'specialConditionsSelector', condition);
    }
  }

  handleSideDrawer(type, callback) {
    const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
    if (type) {
      sideDrawer[type] = true;
    }
    this.setState(sideDrawer, () => {
      if (callback) {
        callback();
      }
    });
  }

  getTonnageLabel() {
    if (this.manualComplete) {
      return "Tonnage" + this.manualCompleteTonnageLabel();
    } else {
      return "Tonnage" + this.maximumAllowedTonnageLabel();
    }
  }

  quantityLabelForForm() {
    let label = this.quantityLabel();
    if (this.isMeterCubeCommodityCommodity()) {
      const commodity = this.getSelectedCommodity()
      const quantityLabel = commodity?.quantityLabel || 'Volume'
      if (this.manualComplete) {
        return quantityLabel + this.manualCompleteQuantityLabel();
      } else {
        return quantityLabel + this.maximumAllowedQuantityLabel();
      }
    }
    return label;
  }

  freightProviderSideDrawer() {
    const freightProvider = this.getSelectedProvider();
    return this.props.isEmployeeSideDrawerOpened && freightProvider && <SideDrawer
      isOpen={this.state.freightProviderSideDrawerIsOpen}
      title={getLabelForEmployeeForm(freightProvider)}
      onClose={this.closeFreightProviderSideDrawer}
      app="officeEmployee"
      canCreate={true}
    >
      <CreateEmployee
        closeDrawer={this.closeFreightProviderSideDrawer}
        canAccessAny={true}
        companyId={this.state.fields.providerId.value}
        selectedCompany={freightProvider}
        establishmentDetails={this.state.fields.providerId.value}
        getContacts={this.getCompanyEmployees}
      />
    </SideDrawer>;
  }

  customerSideDrawer() {
    const companyId = get(this.state.fields, 'customer.companyId.value');
    const customer = get(this.state.fields.customer, 'company');
    return this.props.isEmployeeSideDrawerOpened &&
      companyId &&
      <SideDrawer
        isOpen={this.state.customerSideDrawerIsOpen}
        title={getLabelForEmployeeForm(customer)}
        onClose={this.closeCustomerSideDrawer}
        app="officeEmployee"
        canCreate={true}
      >
        <CreateEmployee
          closeDrawer={this.closeCustomerSideDrawer}
          canAccessAny={true}
          companyId={companyId}
          selectedCompany={customer}
          establishmentDetails={this.state.fields.customer}
          getContacts={this.getCustomerContacts}
        />
      </SideDrawer>;
  }

  isSelectedBaseEntityAnOrder() {
    return get(this.state.selectedBaseEntity, 'entity') === 'order' || get(this.state.selectedBaseEntity, 'entity') === 'freightorder';
  }

  isSellerToBuyer() {
    return includes([FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER, FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN], this.state.fields.typeId.value);
  }

  isPlannedCommodityFieldDisabled() {
    if (this.state.isCustomerOnlyMovement)
      return !this.state.isCustomerOnlyMovement;
    return ((!this.isEditForm && !!get(this.state.selectedBaseEntity, 'commodityId')) || this.isPlannedFieldsDisabledInEditForm());
  }

  isPlannedVarietyFieldDisabled() {
    if (this.state.isCustomerOnlyMovement)
      return !this.state.isCustomerOnlyMovement;
    return (!this.isEditForm && Boolean(this.state.selectedBaseEntity)) || (this.isPlannedFieldsDisabledInEditForm());
  }

  isPlannedFieldsDisabledInEditForm() {
    if (this.state.isCustomerOnlyMovement)
      return !this.state.isCustomerOnlyMovement;
    return this.isEditForm && ((
      get(this.editMovement, 'isIndependentCustomerOnly') && !SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'provider.id')) &&
      !includes(['planned', 'confirmation_pending', 'confirmed', 'rejected'], get(this.editMovement, 'status'))
    ) || Boolean(this.state.selectedBaseEntity) && !get(this.editMovement, 'commodityContract.spread.details'));
  }

  isPlannedGradeFieldDisabled() {
    if (this.state.isCustomerOnlyMovement)
      return !this.state.isCustomerOnlyMovement;
    return ((
      !this.isEditForm && (!isNumber(this.state.fields.commodityId.value))
    ) || this.isPlannedFieldsDisabledInEditForm());
  }

  isPlannedSeasonFieldDisabled() {
    if (this.state.isCustomerOnlyMovement)
      return !this.state.isCustomerOnlyMovement;
    return ((!this.isEditForm && !!get(this.state.selectedBaseEntity, 'season')) || (this.isPlannedFieldsDisabledInEditForm()));
  }

  isEditingAdHocMovement() {
    return this.isEditForm && get(this.editMovement, 'isAdHoc');
  }

  isEditingAdHocMovementWithoutPlannedSeason() {
    return this.isEditingAdHocMovement() && !get(this.editMovement, 'season');
  }

  showFreightProviderField = () => {
    if (this.isUserNonDeliveryOnus()) {
      if (this.isDeliveredMovement()) return true;
    }
    return true;
  };

  isFreightProviderFieldDisabled() {
    if (this.isEditForm) {
      return !SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'provider.id'))
    }
    if (this.isUserNonDeliveryOnus() && this.isDeliveredMovement())
      return false;

    return this.isSelectedBaseEntityAnOrder() && !this.isSelectedOrderCallOnGrain();
  }

  isSelectedOrderCallOnGrain() {
    return get(this, 'orderResponse.typeId') >= FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN ||
      get(this, 'state.selectedBaseEntity.isCallOnGrain') === true;
  }

  isFreightProviderCreator() {
    return this.state.fields.providerId.value == this.props.currentUser.companyId;
  }

  hasCommissionRate() {
    return this.state.commissionRate > 0;
  }

  getSelectedCommodity = commodityId => {
    const id = commodityId || this.state.fields.commodityId.value
    return id ? find(this.props.commodities, { id: id }) : null
  }

  hasQuantityBasedCommodity = commodityId => Boolean(this.getSelectedCommodity(commodityId || this.state.fields.commodityId.value)?.isQuantityBased)

  quantityLabel() {
    return get(this.getSelectedCommodity(), 'quantityLabel')
  }

  quantityUnit() {
    return get(this.getSelectedCommodity(), 'unit')
  }
  getQuantityWithLabel() {
    if (this.hasQuantityBasedCommodity())
      return this.state.fields.quantity.value + " " + this.quantityUnit();
  }

  noteHandler = val => {
    this.setState({ fields: { ...this.state.fields, note: val } });
  };


  isRepresentingCustomer() {
    return includes(
      compact([
        get(this.state, 'selectedBaseEntity.customerRepresentedById'),
        get(this.editMovement, 'customer.representedById')
      ]),
      get(this.props, 'currentUser.companyId')
    );
  }

  isCreatingAgainstOrderAndMovementNotDelivered() {
    return !this.isEditForm &&
      get(this.state.selectedBaseEntityRaw, 'entity') === 'order' &&
      this.state.fields.status.value !== 'delivered';
  }

  isEditingMovementAgainstOrderAndMovementNotDelivered() {
    return get(this.editMovement, 'orderId') && get(this.editMovement, 'status') !== 'delivered';
  }


  isEditingBeforeConfirmation() {
    return includes(['draft', 'planned'], get(this.editMovement, 'status'));
  }

  isCurrentUserNotCustomerOrProvider() {
    const sellerBrokerCompanyId = get(this.state.selectedBaseEntity, 'seller.representedById', get(this.state.sellerDetails, 'representedById'))
    const buyerBrokerCompanyId = get(this.state.selectedBaseEntity, 'buyer.representedById', get(this.state.buyerDetails, 'representedById'))
    let movementParty = [this.state.fields.customer.companyId.value, this.state.fields.customer.representedById.value, this.state.fields.providerId.value]
    isEqual(get(this.state.selectedBaseEntity, 'deliveryOnus'), 'Seller') ? movementParty.push(sellerBrokerCompanyId) : movementParty.push(buyerBrokerCompanyId)
    return !includes(movementParty, this.props.currentUser.companyId)
  }

  isAcceptanceDisabled() {
    if (this.shouldFetchSlotsFor('Pickup') || this.shouldFetchSlotsFor('Delivery'))
      return true;

    if (this.isCurrentUserNotCustomerOrProvider())
      return true;

    if (
      this.isEditingMovementAgainstOrderAndMovementNotDelivered() ||
      this.isCreatingAgainstOrderAndMovementNotDelivered()
    )
      return false;
    return this.isEditingBeforeConfirmation() ||
      this.isDeliveredMovement() ||
      this.state.fields.isSelf.value ||
      (this.isRepresentingCustomer() && this.isFreightProviderCreator());
  }

  onFieldChange = event => {
    const newState = { ...this.state };
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    set(newState.fields, `${event.target.id}.value`, value);
    this.setState(newState);
  };

  async handleSubFreightProviderChange(value) {
    const newState = { ...this.state };
    if (value) {
      if (value !== this.state.fields.subFreightProviderId.value) {
        newState.fields.plannedTruckId.value = undefined;
        newState.fields.subFreightProviderId.value = value;
        const employees = await APIService.companies(value)
          .employees()
          .get(this.props.userToken);
        newState.subFreightProviderEmployees = employees;
        this.setState(newState, () => {
          this.getProviderTrucks(value);
        });
      }
    }
    else {
      newState.fields.subFreightProviderId.value = null;
      newState.fields.subFreightProviderId.validators = [required()];
      newState.subFreightProviderEmployees = [];
      newState.providerTrucks = [];
      this.setState(newState);
    }
  }

  async handleSubFreightProviderCheckboxChange(e) {
    const newState = { ...this.state };
    newState.applySubFreightProvider = e.target.checked;
    newState.fields.plannedTruckId.value = undefined;

    if (!newState.applySubFreightProvider) {
      newState.fields.subFreightProviderId.value = null;
      newState.fields.subFreightProviderId.validators = [];
      newState.fields.subFreightProviderId.errors = [];
      newState.subFreightProviderEmployees = [];
      newState.subFreightProviderEmployees = this.state.providerEmployees;
    }
    else {
      let subFreightProviders = await APIService.companies()
          .appendToUrl('directory/names/?excludeGroups=true')
        .get(this.props.userToken);
      newState.subFreightProviders = subFreightProviders;
      newState.providerTrucks = [];
      newState.fields.subFreightProviderId.validators = [required()];
      newState.fields.driverId.validators = [required()];
    }
    this.setState(newState, () => {
      if (!this.state.applySubFreightProvider) {
        this.getProviderTrucks(newState.fields.providerId.value);
      }
    });
  }

  isIndependentCheck(event) {
    const newState = { ...this.state };
    if (event.target.checked) {
      newState.fields.baseEntityId.validators = [selected()];
      newState.fields.sellerDetails = [];
      newState.fields.buyerDetails = [];
    }
    else {
      this.resetFields(newState);
      newState.fields.externalReferenceNumber.validators = [required()];
      newState.fields.baseEntityId.validators = [];
      newState.fields.baseEntityId.value = '';
    }
    newState.isDependent = event.target.checked;
    this.setState(newState);
  }

  updateParty(seller, buyer, errors, contacts, customer, ngrs) {
    const newState = { ...this.state };
    newState.sellerDetails = seller;
    newState.buyerDetails = buyer;
    newState.partyValidate = false;
    newState.errors = merge(this.state.errors, errors);
    newState.customerItems = customer;
    newState.sellerNgrs = ngrs.sellerNgrs;
    newState.buyerNgrs = ngrs.buyerNgrs;
    newState.sellerContacts = contacts.sellerContacts;
    newState.buyerContacts = contacts.buyerContacts;
    this.setState(newState, () => {
      if (this.state.fields.customer.companyId.value && (
        !includes([get(seller, 'contactId'), get(buyer, 'contactId')], this.state.fields.customer.contactId.value) ||
        !includes([get(seller, 'ngrId'), get(buyer, 'ngrId')], this.state.fields.customer.ngrId.value)
      )) {
        this.setCustomerContactAndNgr();
      }
    }
    );
  }

  async getSlotBookingForBoth(entity, companyId) {
    let res = [];
    if (this.shouldFetchSlotsFor('Pickup') && this.shouldFetchSlotsFor('Delivery') && companyId && !this.isEditForm) {
      if (!this.isFreightProviderCreator()) {
        if (entity == 'trucks') {
          res = await APIService.companies(companyId)
            .appendToUrl('trucks/?is_fm_creation=true')
            .get();
          this.setState({ providerTrucks: res });
        }
        else {
          res = await APIService.companies(companyId)
            .appendToUrl('employees/minimal/')
            .get();
          this.setState({ subFreightProviderEmployees: res });
        }
      }
      else
        this.setState({ subFreightProviderEmployees: this.state.providerEmployees });
    }
  }

  handleReferenceNumberBlur(event) {
    if (get(event.target, 'value') != '') {
      APIService.empty().appendToUrl(`common/exists/${event.target.value}/`).get().then(res => {
        const newState = { ...this.state };
        if (res.result) {
          newState.fields.externalReferenceNumber.errors = ['Reference Number already exists in system'];
        }
        else {
          newState.fields.externalReferenceNumber.errors = [];
        }
        this.setState({ newState });
      });
    }
  }

  handleSteerChange = (event, checkpoint) => {
    const newState = { ...this.state };
    const checked = event.target.checked;
    set(newState.fields, `${checkpoint}.${event.target.id}.value`, checked);

    if (checked) {
      if (checkpoint === 'freightPickup.outload')
        newState.freightPickupPermitChecked = false;
      else
        newState.freightDeliveryPermitChecked = false;
      if (event.target.id === 'steer_point_5')
        set(newState.fields, `${checkpoint}.steer_1_point_1.value`, false);
      else
        set(newState.fields, `${checkpoint}.steer_point_5.value`, false);
    }
    this.setState(newState);
  };

  handleMassLimitChange = (value, id) => {
    const newState = { ...this.state };
    newState[id].value = value;
    if (value === 'Notice') {
      if (id === 'pickupMassLimitPermit') {
        newState.pickupGHMS.value = false;
        newState.freightPickupPermitChecked = false;
        newState.freightPickupRestrictedChecked = false;
      }
      else {
        newState.deliveryGHMS.value = false;
        newState.freightDeliveryPermitChecked = false;
        newState.freightDeliveryRestrictedChecked = false;
      }
    }
    else if (value && value.includes('PBS - ')) {
      if (id === 'pickupMassLimitPermit') {
        newState.pickupGHMS.value = false;
        newState.freightPickupAccreditationNumberChecked = false;
      }
      else {
        newState.deliveryGHMS.value = false;
        newState.freightDeliveryAccreditationNumberChecked = false;
      }
    }
    this.setState(newState, () => this.setCategory(id === 'pickupMassLimitPermit' ? 'freightPickup.outload' : 'freightDelivery.inload'));
  };

  openCompanySideDrawer(contractParty) {
    this.setState({ openCompanySideDrawer: true, contractParty: contractParty });
  }

  closeCompanySideDraw() {
    this.setState({
      openCompanySideDrawer: false
    });
  }

  addNewCompany(newCompany) {
    let newState = { ...this.state };
    newCompany.companyId = newCompany.id;
    if (includes(this.state.contractParty, 'freight')) {
      newState.providerItems = [...newState.providerItems, newCompany];
      newState.fields.providerId = {
        ...newState.fields.providerId,
        value: newCompany.id
      };
    } else if (includes(this.state.contractParty, 'customer')) {
      newState.customerItems = [...this.getCustomers(), newCompany];
      newState.fields.customer.companyId = {
        ...newState.fields.customer.companyId,
        value: newCompany.id
      };
    }

    this.setState(newState);
  }

  handleTruckCodeChange = (value, id) => {
    const newState = { ...this.state };
    set(newState, id + '.value', value);
    this.setState(newState, () => this.setCategory(id === 'freightPickup.outload.code' ? 'freightPickup.outload' : 'freightDelivery.inload'));
  };

  shouldEnablePickupSteerPoint5 = () => get(find(this.state.categories, { id: this.state.fields.freightPickup.outload.categoryId.value }), 'steerPoint5');
  shouldEnablePickupSteer1Point1 = () => get(find(this.state.categories, { id: this.state.fields.freightPickup.outload.categoryId.value }), 'steer1Point1');
  shouldEnableDeliverySteerPoint5 = () => get(find(this.state.categories, { id: this.state.fields.freightDelivery.inload.categoryId.value }), 'steerPoint5');
  shouldEnableDeliverySteer1Point1 = () => get(find(this.state.categories, { id: this.state.fields.freightDelivery.inload.categoryId.value }), 'steer1Point1');

  handleCopyLoadDetails(copyToType) {
    const loadFields = [
      'truckId','truckOwnerId', 'truckOwner', 'searchOption', 'steer_point_5', 'steer_1_point_1', 'season', 'ngrId', 'tareWeight', 'grossWeight', 'varietyId',
      'permitNumber', 'declaredMassLimit', 'restricted', 'noticeNumber'
    ];
    const truckCodeFields = copyToType == 'inload' ? ['deliveryMassLimitPermit', 'deliveryCode', 'deliveryGHMS'] : ['pickupMassLimitPermit', 'pickupCode', 'pickupGHMS'];
    const additionalMassLimitFields = copyToType == 'inload' ? ['freightDeliveryPermitChecked', 'freightDeliveryAccreditationNumberChecked', 'freightDeliveryLoadSharing', 'freightDeliveryRestrictedChecked'] : ['freightPickupPermitChecked', 'freightPickupAccreditationNumberChecked', 'freightPickupLoadSharing', 'freightPickupRestrictedChecked']
    let setToFreight = copyToType == 'inload' ? 'freightDelivery' : 'freightPickup';
    let fetchFromFreight = copyToType == 'inload' ? 'freightPickup' : 'freightDelivery';
    let fetchLoadType = copyToType == 'inload' ? 'outload' : 'inload';
    let copyFrom = copyToType == 'inload' ? 'isCopyFromOutload' : 'isCopyFromInload';
    const newState = { ...this.state };
    set(newState, `${copyFrom}` , true);
    if(get(newState.fields, `${fetchFromFreight}.${fetchLoadType}.truckOwnerId.value`))
      this.getTruckOwnerTrucks(get(newState.fields, `${fetchFromFreight}.${fetchLoadType}.truckOwnerId.value`), copyToType == 'outload' );
    forEach(loadFields, field => {
      if (field == 'ngrId') {
        if (!this.isSellerToBuyer())
          set(newState.fields, `${setToFreight}.${copyToType}.${field}.value`, get(newState.fields, `${fetchFromFreight}.${fetchLoadType}.${field}.value`));
        return;
      }
      else if(field == 'searchOption')
        set(newState.fields, `${setToFreight}.${field}`, get(newState.fields, `${fetchFromFreight}.${field}`));
      else
        set(newState.fields, `${setToFreight}.${copyToType}.${field}.value`, get(newState.fields, `${fetchFromFreight}.${fetchLoadType}.${field}.value`));
    });
    set(newState.fields, `${setToFreight}.${copyToType}.specs`, cloneDeep(get(newState.fields, `${fetchFromFreight}.${fetchLoadType}.specs`)));
    forEach(['date', 'netWeight'], field => {
      set(newState.fields, `${setToFreight}.${field}.value`, get(newState.fields, `${fetchFromFreight}.${field}.value`));
    });
    forEach(truckCodeFields, field => {
      set(newState, `${field}.value`, get(newState, `${copyToType == 'inload' ? field.replace('delivery', 'pickup') : field.replace('pickup', 'delivery')}.value`));
    });
    forEach(additionalMassLimitFields, field => {
      set(newState, `${field}`, get(newState, `${copyToType == 'inload' ? field.replace('Delivery', 'Pickup') : field.replace('Pickup', 'Delivery')}`));
    });
    this.setState(newState, () => {
      setTimeout(() => this.handleGradeChange(
        { id: get(this.state.fields, `${fetchFromFreight}.${fetchLoadType}.gradeId.value`) },
        `${setToFreight}.${copyToType}.gradeId`
      ), 2000);
    });
  }

  isContainerMovement() {
    return this.state.fields.containerNumber.value != undefined;
  }

  priceUnit = () => getCountryConfig()?.truck?.unit

  assignCurrentUserAndCompanyAsSubFreightProvider() {
    let { currentUser } = this.props;
    const newState = {...this.state};
    newState.fields.subFreightProviderId.value = currentUser?.companyId;
    newState.fields.driverId.value = currentUser.id;
    newState.selectedTruck = find(this.state.providerTrucks, {rego: 'FLEET'});
    this.setState(newState);
  }

  handleSearchOptionChange = (id, value, event) => {
    value = get(event, 'target.value') || value
    const newState = {...this.state};
    newState.selectedTruck = null;
    newState.fields.driverId.value = null;
    newState.fields.subFreightProviderId.value = null;
    const isPickup = id == 'pickupSearchOption';
    let providers = [...this.props.providers, {id: this.props.currentUser.companyId, name: this.props.currentUser.companyName}];
    let movementProvider = find(providers, {id: this.state.fields.providerId.value});
    if (id == 'pickupSearchOption') {
      newState.fields.freightPickup.searchOption = value;
      newState.fields.freightPickup.outload.truckOwnerId.value = value == SEARCH_BY_FREIGHT_PROVIDER_REGOS ? newState.fields.providerId.value : '';
      newState.fields.freightPickup.outload.truckOwner.value = value == SEARCH_BY_FREIGHT_PROVIDER_REGOS ? movementProvider : null;
      newState.fields.freightPickup.outload.truckId.value = null;
    }
    else if (id == 'deliverySearchOption') {
      newState.fields.freightDelivery.searchOption = value;
      newState.fields.freightDelivery.inload.truckOwnerId.value = value == SEARCH_BY_FREIGHT_PROVIDER_REGOS ? newState.fields.providerId.value : '';
      newState.fields.freightDelivery.inload.truckOwner.value = value == SEARCH_BY_FREIGHT_PROVIDER_REGOS ? movementProvider : null;
      newState.fields.freightDelivery.inload.truckId.value = null;
    }
    this.setState(newState, () => {
      if (value === SEARCH_BY_TRUCK_OWNER_REGOS)
        this.assignCurrentUserAndCompanyAsSubFreightProvider();
      if (value === SEARCH_BY_FREIGHT_PROVIDER_REGOS && this.state.fields.providerId.value)
        this.getTruckOwnerTrucks(this.state.fields.providerId.value, isPickup);
    });
  }

  onCompanyChange = (item, id) => {
    const newState = { ...this.state };
    const isOutloadTruckOwnerId = id == 'outloadTruckOwner';
    const targetField = isOutloadTruckOwnerId ? newState.fields.freightPickup.outload.truckOwner: newState.fields.freightDelivery.inload.truckOwner;
    targetField.value = item;
    if(isNull(item)) {
      let truckIdField = isOutloadTruckOwnerId ? newState.fields.freightPickup.outload.truckId : newState.fields.freightDelivery.inload.truckId;
      truckIdField.value = null;
      if(isOutloadTruckOwnerId)
        newState.pickupOwnerTrucks = [];
      else
        newState.deliveryOwnerTrucks = [];
    }
    this.setState(newState, () => {
      const currentSearchOption = isOutloadTruckOwnerId ? this.state.fields.freightPickup.searchOption : this.state.fields.freightDelivery.searchOption;
      if(item && currentSearchOption != SEARCH_BY_ALL_REGOS)
        this.getTruckOwnerTrucks(item.id, isOutloadTruckOwnerId)
    });
  }

  handleFreightPickupPermitClick = event => {
    this.handlePermitClick(event.target.checked, 'freightPickup')
  }

  handleFreightPickupAccreditationNumberClick = event => {
    this.handleAccreditationNumberClick(event.target.checked, 'freightPickup')
  }

  handleFreightPickupLoadSharingClick = event => {
    this.handleLoadSharingClick(event.target.checked, 'freightPickup')
  }

  handleFreightPickupRestrictedClick = event => {
    this.handleRestrictedClick(event.target.checked, 'freightPickup')
  }

  handleFreightPickupTruckDetailsChange = event => {
    this.handleTruckDetailsChange(event, 'freightPickup')
  }

  handleFreightPickupTruckDetailsSelectValueChange(value, id) {
    this.handleTruckDetailsSelectValueChange(value, id, 'freightPickup')
  }

  handleFreightDeliveryPermitClick = event => {
    this.handlePermitClick(event.target.checked, 'freightDelivery')
  }

  handleFreightDeliveryAccreditationNumberClick = event => {
    this.handleAccreditationNumberClick(event.target.checked, 'freightDelivery')
  }

  handleFreightDeliveryLoadSharingClick = event => {
    this.handleLoadSharingClick(event.target.checked, 'freightDelivery')
  }

  handleFreightDeliveryRestrictedClick = event => {
    this.handleRestrictedClick(event.target.checked, 'freightDelivery')
  }

  handleFreightDeliveryTruckDetailsChange = event => {
    this.handleTruckDetailsChange(event, 'freightDelivery')
  }

  handleFreightDeliveryTruckDetailsSelectValueChange(value, id) {
    this.handleTruckDetailsSelectValueChange(value, id, 'freightDelivery')
  }

  handlePermitClick(value, checkPoint) {
    const newState = {...this.state};
    set(newState, `${checkPoint}PermitChecked`, value);
    let ghmsCheckpoint = checkPoint === 'freightPickup' ? 'pickup' : 'delivery'
    let loadType = checkPoint === 'freightPickup' ? 'outload' : 'inload'
    if (checkPoint === 'freightPickup')
      newState.pickupMassLimitPermit.validators = [required()]
    else
      newState.deliveryMassLimitPermit.validators = [required()]
    if (value) {
      set(newState, `${checkPoint}AccreditationNumberChecked`, false)
      set(newState, `${ghmsCheckpoint}GHMS.value`, false)
      set(newState, `${checkPoint}RestrictedChecked`, false)
      set(newState, `fields.${checkPoint}.${loadType}.steer_point_5.value`)
      set(newState, `fields.${checkPoint}.${loadType}.steer_1_point_1.value`)
      if (checkPoint === 'freightPickup')
        newState.pickupMassLimitPermit.validators = []
      else
        newState.deliveryMassLimitPermit.validators = []
    }
    this.setState(newState);
  }

  handleAccreditationNumberClick(value, checkPoint) {
    const newState = {...this.state};
    set(newState, `${checkPoint}AccreditationNumberChecked`, value);
    let ghmsCheckpoint = checkPoint === 'freightPickup' ? 'pickup' : 'delivery';
    if (value) {
      set(newState, `${checkPoint}PermitChecked`, false);
      set(newState, `${ghmsCheckpoint}GHMS.value`, false)
    }
    this.setState(newState);
  }

  handleLoadSharingClick(value, checkPoint) {
    const newState = {...this.state};
    set(newState, `${checkPoint}LoadSharing`, value);
    this.setState(newState);
  }

  handleRestrictedClick(value, checkPoint) {
    const newState = {...this.state};
    newState.freightPickupRestrictedChecked
    set(newState, `${checkPoint}RestrictedChecked`, value);
    let ghmsCheckpoint = checkPoint === 'freightPickup' ? 'pickup' : 'delivery';
    if (value)
      set(newState, `${ghmsCheckpoint}GHMS.value`, false)
    this.setState(newState);
  }

  handleTruckDetailsChange(event, checkPoint) {
    const newState = {...this.state}
    let id = event.target.id;
    let loadType = checkPoint === 'freightPickup' ? 'outload' : 'inload';
    set(newState, `${id}.value`, id === `fields.${checkPoint}.${loadType}.restricted` ? event.target.checked : event.target.value);
    if (id !== `fields.${checkPoint}.${loadType}.restricted` && event.target.value) {
      const parts = id.split(".")
      const errorField = `${checkPoint}TruckErrors.${parts[1]}`;
      set(newState, errorField, '')
    }
    this.setState(newState);
  }

  handleTruckDetailsSelectValueChange(value, id, checkPoint) {
    const newState = { ...this.state };
    set(newState, `${id}.value`, value);
    const parts = id.split(".")
    const errorField = `${checkPoint}TruckErrors.${parts[1]}`;
    set(newState, errorField, '')
    this.setState(newState);
  }

  getEntityUrl() {
    const reason = get(this.state.selectedBaseEntity, 'reason')
    const canAmendRelatedEntity = get(this.state.selectedBaseEntity, 'canAmendRelatedEntity');
    if (reason) {
      let url = ''
      let identifier = ''
      if (reason === 'contract') {
        identifier = get(this.state.selectedBaseEntity, 'commodityContract.contractNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/contracts/${get(this.state.selectedBaseEntity, 'order.commodityContract.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'pickup order') {
        identifier = get(this.state.selectedBaseEntity, 'pickupOrderNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.state.selectedBaseEntity, 'pickupOrder.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'delivery order') {
        identifier = get(this.state.selectedBaseEntity, 'deliveryOrderNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.state.selectedBaseEntity, 'order.deliveryOrder.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'parent order') {
        identifier = get(this.editMovement, 'order.parentOrder.identifier')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.state.selectedBaseEntity, 'order.parentOrder.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      return url
    }
  }

  updateFarmEntities = (entityId, address, mobile, checkpoint) => {
    let handlers = checkpoint == 'pickup' ? this.state.consignors : this.state.consignees
    let updateSelectedHandler = checkpoint == 'pickup' ? this.state.selectedConsignor : this.state.selectedConsignee
    let updateSelectedHandlerKey = checkpoint == 'pickup' ? 'selectedConsignor' : 'selectedConsignee'
    const newState = {...this.state};
    if (find(handlers, {id: entityId})) {
      newState[handlers] = map(handlers, item => {
        if(item?.id == entityId) {
          item.address=address
          item.mobile = mobile
        }
        return item
      })
    }
    set(newState, updateSelectedHandlerKey, {...updateSelectedHandler, address: address, mobile: mobile})
    this.setState(newState)
  }

  modifySelectedBaseEntityItem = baseEntity => {
    if(get(baseEntity, 'entity') === 'freightorder')
      baseEntity.entity = 'order'
    return baseEntity
  }

  getSlotOptionsForTrailer = (trailer, checkpoint) => {
    let trailerSlots = checkpoint == 'Pickup' ? this.state.outloadSlots : this.state.inloadSlots;
    if (isEmpty(trailerSlots))
      return [];
    let trailers = without(['trailer1', 'trailer2', 'trailer3'], trailer);
    const occupiedSlotIds = checkpoint == 'Pickup' ? map(trailers, t => this.state.pickupSiteTrailers[t].slotId) : map(trailers, t => this.state.deliverySiteTrailers[t].slotId);
    const excludeParentSlotId = checkpoint == 'Pickup' ? this.state.fields.outloadSlotId.value : this.state.fields.inloadSlotId.value;
    return reject(trailerSlots, slot => {
      return Boolean(includes(occupiedSlotIds, slot.id) || excludeParentSlotId == slot.id)
    });
  }

  handleTrailerSlotChange = (value, trailer, siteTrailers) => {
    let trailers = without(['trailer1', 'trailer2', 'trailer3'], trailer)
    const newState = {...this.state};
    if(siteTrailers == 'pickupSiteTrailers') {
      if(!value || (this.state.pickupSiteTrailers[trailers[0]].slotId != value && this.state.pickupSiteTrailers[trailers[1]].slotId != value))
        newState.pickupSiteTrailers[trailer].slotId = value ? value : undefined;
    } else {
      if(!value || this.state.deliverySiteTrailers[trailers[0]].slotId != value && this.state.deliverySiteTrailers[trailers[1]].slotId != value)
        newState.deliverySiteTrailers[trailer].slotId = value ? value : undefined;
    }
    this.setState(newState);
  }

  isTrailerBookingApplicable = checkpoint => (this.isPlannedMovement() || this.shouldShowCheckpointDetails()) && includes(['freightorder', 'order'], this.state.selectedBaseEntity?.entity) && this.isTrailerBookingEnabledForSite(checkpoint)

  trailerBookingQuantity = isPickup => isPickup ? this.state.pickupSMSettings?.trailerBookingQuantity : this.state.deliverySMSettings?.trailerBookingQuantity

  checkTonnageExceedTrailerBookingQuantity = isPickup => {
    const quantity = this.trailerBookingQuantity(isPickup)
    return quantity && this.state.fields.plannedTonnage.value && quantity <= parseFloat(this.state.fields.plannedTonnage.value)
  }

  checkTrailerSlotNeeded = isPickup => {
    let trailerBookingEnabled = this.isTrailerBookingEnabledForSite(isPickup ? 'Pickup' : 'Delivery')
    let trailers = isPickup ? this.state.pickupSiteTrailers : this.state.deliverySiteTrailers
    return trailerBookingEnabled && this.checkTonnageExceedTrailerBookingQuantity(isPickup) && isEmpty(compact(map(trailers, 'slotId')))
  }

  render() {
    const commodity = this.getSelectedCommodity()
    const isBookingBothSlots = this.shouldFetchSlotsFor('Pickup') && this.shouldFetchSlotsFor('Delivery');
    const isBaseEntityAnOrder = this.isSelectedBaseEntityAnOrder();
    const isFreightContractSellerToBuyer = this.isSellerToBuyer();
    const isCustomerNgrDisabled = isFreightContractSellerToBuyer || isBaseEntityAnOrder;
    const spreadDetails = get(this.editMovement, 'commodityContract.spread.details', get(this.state.selectedBaseEntity, 'spread'));
    const canViewPickup = this.editMovement?.id ? this.editMovement.canViewPickup : true
    const canViewDelivery = this.editMovement?.id ? this.editMovement.canViewDelivery : true
    const query = new URLSearchParams(this.props.location.search);
    const commodityContractId = query.get('commodityContractId');
    const foreignCurrencyAllowed = get(this.props.currentUser.company, 'foreignCurrencyAllowed');
    const isRateInOrRateOutFieldVisible = (this.showFreightRateInField() && this.hasCommissionRate()) || this.showFreightContractDetailsSection();
    const isSelectedBaseEntityOrder = get(this.state.selectedBaseEntity, 'entity') == 'order';
    let canRepresent = !this.state.isDependent && !this.editMovement && !this.orderId && isCurrentUserBroker();
    const fixedGrades = filter(this.props.grades, (grade) => {
      return find(spreadDetails, { id: grade.id });
    });
    const showFreightPickupOrDeliverySection = (!this.isEditForm || (this.isEditForm && this.shouldShowCheckpointDetails()));
    const buttons = (
      <div className="cardForm-action col-md-12 top-15">
        <CommonButton
          label='Cancel'
          default={true}
          variant='outlined'
          onClick={this.handleCancelButtonClick}
        />
        <CommonButton
          type='submit'
          label={this.manualComplete ? 'Submit' : 'Continue and Review'}
          primary={true}
          variant="contained"
        />
      </div>
    );
    const addTrucksHeader = (get(this.state.fields.providerId, 'value') == get(this.props, 'currentUser.companyId')) ? " (Only add trucks that you own. If adding sub freight provider's trucks, go to Company Directory" : "";
    const isContainerMovement = this.isContainerMovement();
    const priceUnit = this.priceUnit()
    const disableBasedOnPickupSearchOption = this.state.fields.freightPickup.searchOption === SEARCH_BY_FREIGHT_PROVIDER_REGOS || (this.state.fields.freightPickup.searchOption === SEARCH_BY_ALL_REGOS && isEmpty(this.state.fields.freightPickup.outload.truckId.value))
    const disableBasedOnDeliverySearchOptions = this.state.fields.freightDelivery.searchOption === SEARCH_BY_FREIGHT_PROVIDER_REGOS || (this.state.fields.freightDelivery.searchOption === SEARCH_BY_ALL_REGOS && isEmpty(this.state.fields.freightDelivery.inload.truckId.value))
    const regoLabel = getCountryLabel('rego');
    const isTruckMassLimitsVisible = isVisibleInCountry('truckMassLimits');
    let consigneeSites = this.state.consigneeSites;
    if (!isEmpty(consigneeSites))
      consigneeSites = consigneeSites.filter(site => site.type !== 'container');
    let disableFreightPickupLoadSharing = false;
    if (this.state.fields.freightPickup.outload.categoryId.value && this.state.categories && this.state.selectedConsignor) {
      const category = find(this.state.categories, {id: this.state.fields.freightPickup.outload.categoryId.value});
      const stateCode = get(this.state.selectedConsignor, 'marketZoneStateCode')
      disableFreightPickupLoadSharing = stateCode && get(category, `loadSharing.${stateCode}`) === 0;
    }

    let disableFreightDeliveryLoadSharing = false;
    if (this.state.fields.freightPickup.outload.categoryId.value && this.state.categories && this.state.selectedConsignee) {
      const category = find(this.state.categories, {id: this.state.fields.freightPickup.outload.categoryId.value})
      const stateCode = get(this.state.selectedConsignee, 'marketZoneStateCode')
      disableFreightDeliveryLoadSharing = stateCode && get(category, `loadSharing.${stateCode}`) === 0;
    }
    let showPickupAdditionalMassLimitCodeFields = false;
    if(this.state.fields.freightPickup.consignor.handlerId.value && this.props.companyParties && this.state.consignors) {
      let handlerCompanyId = get(find(this.state.consignors, {id: this.state.fields.freightPickup.consignor.handlerId.value}), 'companyId')
      if (handlerCompanyId)
        showPickupAdditionalMassLimitCodeFields = get(find(this.props.companyParties, {id: handlerCompanyId}), 'additionalMassLimitCodes');
    }

    let showDeliveryAdditionalMassLimitCodeFields = false;
    if(this.state.fields.freightDelivery.consignee.handlerId.value && this.props.companyParties && this.state.consignees) {
      let handlerCompanyId = get(find(this.state.consignees, {id: this.state.fields.freightDelivery.consignee.handlerId.value}), 'companyId')
      if (handlerCompanyId)
        showDeliveryAdditionalMassLimitCodeFields = get(find(this.props.companyParties, {id: handlerCompanyId}), 'additionalMassLimitCodes');
    }
    const checkpoint = get(this.state.selectedBaseEntity, 'weightId') === WEIGHT_DESTINATION_ID ? 'freightDelivery' : 'freightPickup';
    const netWeight = get(this.state.fields, `${checkpoint}.netWeight.value`)
    const entityName = get(this.state.selectedBaseEntity, 'entityType');
    let updateToTonnage = get(this.state.selectedBaseEntity, 'deliveredTonnage', 0) + parseFloat(netWeight);
    updateToTonnage = checkpoint === 'freightPickup' ? updateToTonnage + get(this.state.selectedBaseEntity, 'progressTonnage', 0) : updateToTonnage;

    let consignorAddress = get(this.state.selectedConsignor, 'address');
    let consignorMobile = get(this.state.selectedConsignor, 'mobile');
    if(!consignorAddress && this.state.selectedConsignor && !isEmpty(this.state.consignors)) {
      const consignor = find(this.state.consignors, {id: this.state.selectedConsignor.id});
      if (consignor) {
        consignorAddress = get(consignor, 'address');
        consignorMobile = get(consignor, 'mobile');
      }
    }
    let consignorAddressLink = '';
    if (consignorAddress)
      consignorAddressLink = <div style={{marginTop: "5px"}}>
        <div style={{ display: "block" }}>
          <span>{consignorAddress}</span>
          <IconButton 
            style={{padding: "0px"}}
            onClick={() => this.setState({openConsignorFarmForm: true})}
            size="small">
              <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
        </div>
        {consignorMobile &&
        <div style={{ display: "block" }}>
          <span>{toPhoneFormat(consignorMobile)}</span>
          <IconButton 
            style={{padding: "0px"}}
            onClick={() => this.setState({openConsignorFarmForm: true})}
            size="small">
              <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
        </div>
        }
      </div>

    let consigneeAddress = get(this.state.selectedConsignee, 'address');
    let consigneeMobile = get(this.state.selectedConsignee, 'mobile');
    if(!consigneeAddress && this.state.selectedConsignee && !isEmpty(this.state.consignees)) {
      const consignee = find(this.state.consignees, {id: this.state.selectedConsignee.id});
      if (consignee) {
        consigneeAddress = get(consignee, 'address');
        consigneeMobile = get(consignee, 'mobile');
      }
    }
    let consigneeAddressLink = '';
    if (consigneeAddress)
      consigneeAddressLink = <div style={{marginTop: "5px"}}>
        <div style={{ display: "block" }}>
          <span>{consigneeAddress}</span>
          <IconButton 
            style={{padding: "0px"}}
            onClick={() => this.setState({openConsigneeFarmForm: true})}
            size="small">
              <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
        </div>
        {consigneeMobile &&
        <div style={{ display: "block" }}>
          <span>{toPhoneFormat(consigneeMobile)}</span>
          <IconButton 
            style={{padding: "0px"}}
            onClick={() => this.setState({openConsigneeFarmForm: true})}
            size="small">
              <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
        </div>
        }
      </div>

    const consignorStorageAddress = get(this.state.selectedConsignorSite, 'address.address');
    let consignorStorageAddressLink = '';
    if (consignorStorageAddress)
      consignorStorageAddressLink = <div style={{marginTop: "5px"}}>
        <span>{consignorStorageAddress}</span>
        <IconButton 
          style={{padding: "0px"}}
          onClick={() => this.setState({openConsignorStorageForm: true})}
          size="small">
            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
      </div>

    const consigneeStorageAddress = get(this.state.selectedConsigneeSite, 'address.address');
    let consigneeStorageAddressLink = '';
    if (consigneeStorageAddress)
      consigneeStorageAddressLink = <div style={{marginTop: "5px"}}>
        <span>{consigneeStorageAddress}</span>
        <IconButton 
          style={{padding: "0px"}}
          onClick={() => this.setState({openConsigneeStorageForm: true})}
          size="small">
            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
      </div>

    const isParentOrderIndependent = get(this.state.selectedBaseEntity, 'isIndependent');
    let showUpdateToTonnagePopup = this.state.showAmendTonnagePopup && !isParentOrderIndependent;
    if (showUpdateToTonnagePopup && (!get(this.state.selectedBaseEntity, 'maxAllowedTonnageForAmend') || (parseFloat(updateToTonnage) < get(this.state.selectedBaseEntity, 'maxAllowedTonnageForAmend'))))
      showUpdateToTonnagePopup = true
    else
      showUpdateToTonnagePopup = false
    const parentEntityUrl = this.getEntityUrl();
    const amendMessage = get(this.state.selectedBaseEntity, 'canAmendRelatedEntity') ? `Please amend ${get(this.state.selectedBaseEntity, 'reason')} first.` : `Please contact the relevant party to amend ${get(this.state.selectedBaseEntity, 'reason')}.`;
    return (
      <div ref={this.formRef}>
        <form onSubmit={this.handleSubmit} noValidate>
          {
            !this.manualComplete &&
            <div className="cardForm">
              {
                this.isEditForm && this.state.isPopulated && <div className='non-existent-component hide'></div>
              }
              {
                !this.isEditForm && this.props.isBHCLocationsFetched && this.props.isPartiesFetched && <div className='non-existent-component hide'></div>
              }
              <h4 className="cardForm-title">General</h4>
              <div className="cardForm-content">
                <div className="col-md-5">
                  <div className="col-md-12 padding-reset form-wrap">
                    <CommonSelect
                      id="typeId"
                      setRef={this.fieldRef["typeId"]}
                      onChange={this.handleMovementTypeChange}
                      floatingLabelText={this.disableForm ? 'Select Type to proceed' : "Freight Movement Type"}
                      errorText={this.state.fields.typeId.errors[0]}
                      selectConfig={{ text: 'displayName', value: 'id' }}
                      items={this.props.types}
                      value={this.state.fields.typeId.value}
                      disabled={this.state.queryParamExists || this.isEditForm}
                    />
                  </div>
                  {
                    !this.isEditForm ?
                      <div className="col-md-12 padding-reset form-wrap">
                        <TextField
                          error={!isEmpty(this.state.fields.identifier.errors[0])}
                          id="identifier"
                          inputRef={this.fieldRef["identifier"]}
                          label="Movement No"
                          placeholder="Please enter 14 digit unique number"
                          value={this.state.fields.identifier.value}
                          inputProps={{ maxLength: 14 }}
                          fullWidth
                          disabled={this.disableForm && !this.state.fields.typeId.value}
                          helperText={this.state.fields.identifier.errors[0]}
                          onChange={this.handleIdentifierChange}
                          onBlur={this.handleBlur}
                          variant="standard" />
                      </div> :
                      <div className="col-md-12 padding-reset form-wrap">
                        <CommonTextField
                          id="identifier"
                          label="Movement No"
                          value={this.state.fields.identifier.value}
                          disabled
                        />
                      </div>
                  }
                  {
                    !this.isEditForm &&
                    <div className="col-md-12 padding-reset form-wrap">
                      <CommonSelect
                        style={{ float: 'right' }}
                        id="status"
                        setRef={this.fieldRef["status"]}
                        onChange={this.handleStatusFieldChange}
                        floatingLabelText="Status"
                        items={this.getStatuses()}
                        errorText={this.state.fields.status.errors[0]}
                        disabled={this.isEditForm || (this.disableForm && !this.state.fields.typeId.value)}
                        value={this.state.fields.status.value}
                      />
                    </div>
                  }
                  {
                    get(this.state.fields.typeId, 'value') === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER && !this.isEditForm && !this.orderId && !commodityContractId &&
                    <div className="col-md-12 padding-reset form-wrap">
                      <Grid component="label" container alignItems="center" spacing={1} wrap='nowrap'>
                        <Grid item style={{ fontSize: '15px', color: 'rgba(0, 0, 0, 0.54)', paddingRight: '136px' }}>Contract exists</Grid>
                        <Grid item>Outside AgriChain</Grid>
                        <Grid item>
                          <Switch color='primary' checked={this.state.isDependent} onChange={this.isIndependentCheck} name="checkedC" />
                        </Grid>
                        <Grid item>On AgriChain</Grid>
                      </Grid>
                    </div>
                  }
                </div>
                <div className="col-md-5 col-md-offset-1">
                  {
                    this.state.fields.typeId.value &&
                    this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER &&
                    !this.state.queryParamExists &&
                    !this.isEditForm &&
                    <div className="col-md-12 padding-reset form-wrap">
                      <CommonAutoSelect
                        items={this.getCustomers()}
                        setRef={this.fieldRef["filterParty"]}
                        id="filterParty"
                        label="Filter By Seller/Buyer"
                        value={this.state.filterParty}
                        style={{ float: 'left' }}
                        disabled={!this.state.isDependent}
                        onChange={this.applyFiltersByParty}
                        dontAutoselectSingleItem
                      />
                    </div>
                  }
                  {
                    this.state.fields.typeId.value &&
                    this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY &&
                    !this.state.queryParamExists &&
                    !this.isEditForm &&
                    <div className="col-md-12 padding-reset form-wrap">
                      <CommonAutoSelect
                        items={this.getCustomers()}
                        id="filterCustomer"
                        setRef={this.fieldRef["filterCustomer"]}
                        label="Filter By Customer"
                        value={this.state.filterCustomer}
                        style={{ float: 'left' }}
                        onChange={this.applyFiltersByCustomer}
                        dontAutoselectSingleItem
                      />
                    </div>
                  }
                  {
                    this.state.fields.typeId.value && !this.state.queryParamExists && !this.isEditForm &&
                    <div className="col-md-12 padding-reset form-wrap">
                      <CommonAutoSelect
                        items={this.getProviders()}
                        id="filterProvider"
                        setRef={this.fieldRef["filterProvider"]}
                        label="Filter By Freight Provider"
                        value={this.state.filterProvider}
                        style={{ float: 'left' }}
                        onChange={this.applyFiltersByProvider}
                        disabled={!this.state.isDependent}
                        dontAutoselectSingleItem
                      />
                    </div>
                  }
                  {
                    this.state.queryParamExists || (this.isEditForm && this.hasSelectedBaseEntity()) ?
                      <div className="col-md-12 padding-reset form-wrap freight-movement-base-entity">
                        <CommonTextField
                          id="baseEntityId"
                          label={this.getBaseEntityFieldLabel()}
                          value={get(this.state.selectedBaseEntity, 'displayName')}
                          helperText={this.getCommodityLimitWarningMessage()}
                          fullWidth
                          disabled
                        />
                      </div>
                      : null
                  }
                  {
                    !this.state.isDependent && !this.orderId &&
                    <div className="col-md-12 padding-reset form-wrap" style={{ marginTop: '16px' }}>
                      <CommonTextField
                        id='externalReferenceNumber'
                        placeholder='Contract Reference No.'
                        setRef={this.fieldRef["externalReferenceNumber"]}
                        maxLength={25}
                        onChange={this.onFieldChange}
                        helperText={this.state.fields.externalReferenceNumber.errors[0]}
                        onBlur={this.handleReferenceNumberBlur}
                      />
                    </div>
                  }
                  {
                    this.state.isDependent &&
                      !this.state.queryParamExists && this.state.fields.typeId.value && !this.isEditForm ?
                      <div className="col-md-12 padding-reset form-wrap freight-movement-base-entity">
                        <CommonAutoSelect
                          items={this.state.filteredEntities}
                          id="baseEntityId"
                          setRef={this.fieldRef["baseEntityId"]}
                          dataSourceConfig={{ text: 'displayName', value: 'id' }}
                          label={this.getBaseEntityFieldLabel()}
                          value={this.state.fields.baseEntityId.value}
                          errorText={this.state.fields.baseEntityId.value ? this.getCommodityLimitWarningMessage() : '' || ((this.state.filterParty || this.state.filterProvider) && isEmpty(this.state.filteredEntities) ? NO_FILTERED_ENTITIES : this.state.fields.baseEntityId.errors[0])}
                          style={{ float: 'left' }}
                          onChange={this.handleBaseEntityChange}
                          disabled={this.state.queryParamExists}
                          selectedItem={this.modifySelectedBaseEntityItem(this.state.selectedBaseEntityRaw)}
                          dontAutoselectSingleItem
                          itemFilterFields = {['id','entity']}
                        />
                      </div>
                      : ''
                  }
                </div>
              </div>
            </div>
          }
          {!this.state.isDependent && !this.orderId &&
            <CounterPartiesInfo
              fieldRef={this.fieldRef}
              onChange={this.updateParty}
              validate={this.state.partyValidate}
              canRepresent={canRepresent}
              userCompanyId={this.props.currentUser.companyId}
            />
          }
          {((this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER && this.state.isDependent && (this.state.fields.baseEntityId.value || this.isEditForm)) || (!this.state.isDependent && this.orderId)) && this.state.seller && this.state.buyer &&
            <div style={this.manualComplete ? { display: 'none' } : null} className="contract-details-container">
              {<CommodityContractParties
                seller={this.state.seller}
                buyer={this.state.buyer}
                sellerContact={get(this.state, 'seller.contact')}
                buyerContact={get(this.state, 'buyer.contact')}
              />
              }
            </div>
          }
          {
            !this.manualComplete &&
            <div className="cardForm">
              <h4 className="cardForm-title">Counterparties</h4>
              <div className="cardForm-content">
                <div className="col-md-5">
                  <div className="col-md-12 form-wrap padding-reset">
                    {
                      (this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER || isBaseEntityAnOrder) && this.state.isDependent &&
                        get(this.state.fields.customer, 'company.displayName') ?
                        <CommonTextField
                          id="customer.companyId"
                          label="Customer Name"
                          value={get(this.state.fields.customer, 'company.displayName')}
                          disabled
                        /> :
                        <React.Fragment>
                          <CommonAutoSelect
                            items={isEmpty(this.state.customerItems) ? this.getCustomers() : this.state.customerItems}
                            id="customer.companyId"
                            setRef={this.fieldRef["customer.companyId"]}
                            label="Customer Name"
                            value={this.state.fields.customer.companyId.value}
                            errorText={this.state.fields.customer.companyId.errors[0]}
                            style={{ float: 'left' }}
                            onChange={this.handleCustomerChange}
                            dontAutoselectSingleItem
                            selectedItem={(this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER || isBaseEntityAnOrder) ? null : this.state.fields.customer.company}
                            disabled={this.isCustomerFieldsDisabled(isBaseEntityAnOrder) || this.disableForm}
                          />
                          {this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY &&
                            <AddCircleIconWithTooltip
                              id="addBuyerContact"
                              onClick={() => this.openCompanySideDrawer('customer')}
                              title="Add Customer"
                            />
                          }
                        </React.Fragment>
                    }
                  </div>
                  <div className="col-md-12 form-wrap padding-reset">
                    <CommonAutoSelect
                      items={uniqBy(this.state.customerContacts, 'id')}
                      id="customer.contactId"
                      setRef={this.fieldRef["customer.contactId"]}
                      value={this.state.fields.customer.contactId.value}
                      style={{ float: 'left' }}
                      disabled={this.disableForm || !this.orderId && !this.state.isDependent}
                      floatingLabelText="Customer Contact"
                      label="Customer Contact"
                      errorText={this.state.fields.customer.contactId.errors[0]}
                      onChange={this.handleSelectFieldChange}
                    />
                    {this.addCustomerContactButton()}
                  </div>
                  <div className="col-md-12 form-wrap padding-reset">
                    {
                      this.isEditForm && (get(this.editMovement, 'typeId') === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER || SYSTEM_COMPANY_IDS.includes(get(this.editMovement, 'customer.companyId'))) ?
                        <CommonTextField
                          id="customer.ngrId"
                          label="Customer NGR"
                          value={get(this.state, 'customerNgr.ngrNumber') || ''}
                          disabled
                        /> :
                        <CommonAutoSelect
                          items={this.state.customerNgrs}
                          dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                          id="customer.ngrId"
                          disabled={this.disableForm || !this.orderId && !this.state.isDependent || isContainerMovement}
                          setRef={this.fieldRef["customer.ngrId"]}
                          label={isContainerMovement ? "Customer NGR" : "Customer NGR (Optional)"}
                          value={this.state.fields.customer.ngrId.value}
                          errorText={this.state.fields.customer.ngrId.errors[0] || (((this.state.freightPickupSiteIsSystemStorage || this.state.freightDeliverySiteIsSystemStorage) && !this.state.fields.customer.ngrId.value) ? NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE : '')}
                          style={{ float: 'left' }}
                          onChange={this.handleSelectFieldChange}
                          dontAutoselectSingleItem
                        />
                    }
                    {this.addCustomerNgrButton(isCustomerNgrDisabled || (this.isEditForm && get(this.editMovement, 'typeId') === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER))}
                  </div>
                </div>
                {
                  this.showFreightProviderField() && <div className="col-md-5 col-md-offset-1">
                    <div className="col-md-12 form-wrap padding-reset">
                      <CommonAutoSelect
                        items={isEmpty(this.state.providerItems) ? uniqBy(this.getProviders(), 'id') : uniqBy(this.state.providerItems, 'id')}
                        id="providerId"
                        setRef={this.fieldRef["providerId"]}
                        label={this.isUserNonDeliveryOnus() && this.isDeliveredMovement() ? "Freight Provider (Optional)" : "Freight Provider"}
                        value={this.state.fields.providerId.value}
                        errorText={this.state.fields.providerId.errors[0]}
                        onChange={this.handleProviderChange}
                        disabled={this.isFreightProviderFieldDisabled() || this.disableForm}
                        dontAutoselectSingleItem
                      />
                    </div>
                    <div className="col-md-12 form-wrap padding-reset">
                      <CommonAutoSelect
                        items={this.state.providerEmployees}
                        id="assignToId"
                        setRef={this.fieldRef["assignToId"]}
                        value={this.state.fields.assignToId.value}
                        floatingLabelText={"Freight Provider Contact"}
                        label={this.isUserNonDeliveryOnus() && this.isDeliveredMovement() ? "Freight Provider Contact (Optional)" : "Freight Provider Contact"}
                        errorText={this.state.fields.assignToId.errors[0]}
                        onChange={this.handleAssignToIdFieldChange}
                        dontAutoselectSingleItem={this.isEditForm}
                        disabled={this.disableForm}
                      />
                      {this.addFreightProviderContactButton()}
                    </div>
                    {isBookingBothSlots && this.isFreightProviderCreator() && !this.isEditForm && (
                      <div className='col-md-12 form-wrap padding-reset'>
                        <FormControlLabel
                          control={
                            <Checkbox color='primary' checked={this.state.applySubFreightProvider} onChange={this.handleSubFreightProviderCheckboxChange} />
                          }
                          label='Assign Sub Freight Provider'
                          style={{ marginTop: '10px' }}
                        />
                      </div>
                    )}
                    {this.state.applySubFreightProvider && !this.isEditForm && (
                      <div className='col-md-12 form-wrap padding-reset'>
                        <CommonAutoSelect
                          items={this.state.subFreightProviders}
                          id='subFreightProviderId'
                          setRef={this.fieldRef['subFreightProviderId']}
                          label="Sub Freight Provider"
                          value={this.state.fields.subFreightProviderId.value}
                          errorText={this.state.fields.subFreightProviderId.errors[0]}
                          onChange={this.handleSubFreightProviderChange}
                          dontAutoselectSingleItem={true}
                          dataSourceConfig={{ text: 'name', value: 'id' }}
                        />
                      </div>
                    )}
                    {
                      isBookingBothSlots && this.isEditForm && get(this.state, 'subFreightProvider') &&
                      <div className='col-md-12 form-wrap padding-reset'>
                        <CommonTextField
                          id="subFreightProvider"
                          label="Sub Freight Provider"
                          value={get(this.state, 'subFreightProvider.name')}
                          disabled={this.isEditForm}
                        />
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
          }
          <div className="cardForm">
            <h4 className="cardForm-title">{this.manualComplete ? "Commodity Details" : "Freight Movement Details"}</h4>
            <div className="cardForm-content">
              <div className="col-md-5">
                <div className="col-md-12 form-wrap padding-reset">
                  <CommodityAutoComplete
                    id="commodityId"
                    setRef={this.fieldRef["commodityId"]}
                    onChange={this.handleCommodityChange}
                    floatingLabelText="Commodity"
                    commodityId={this.state.fields.commodityId.value}
                    style={{ float: 'left' }}
                    errorText={this.state.fields.commodityId.errors[0]}
                    disabled={this.isPlannedCommodityFieldDisabled() || this.disableForm || isContainerMovement}
                  />
                </div>
                <div className="col-md-12 form-wrap padding-reset">
                  <VarietyAutoComplete
                    id="varietyId"
                    setRef={this.fieldRef["varietyId"]}
                    label="Variety (Optional)"
                    commodityId={this.state.fields.commodityId.value}
                    varietyId={this.state.fields.varietyId.value}
                    dependsOnCommodity
                    onChange={this.handleVarietyChange}
                    style={{ float: 'left' }}
                    errorText={this.state.fields.varietyId.errors[0]}
                    onBlur={this.handleBlur}
                    disabled={this.isPlannedVarietyFieldDisabled() || this.disableForm || isContainerMovement}
                  />
                </div>
                {
                  get(this.state.selectedBaseEntity, 'isBlended') ?
                  <div className="col-md-12 form-wrap padding-reset">
                    <CommonTextField
                      id="plannedGradeId"
                      label="Grade"
                      value={get(this.state.selectedBaseEntity, 'gradeName')}
                      disabled
                    />
                  </div> :
                  <div className="col-md-12 form-wrap padding-reset" style={this.manualComplete || (this.isPlannedMovement() || this.isEditForm) ? { display: 'inline-block' } : { display: 'none' }}>
                    <GradeAutoComplete
                      id="plannedGradeId"
                      setRef={this.fieldRef["plannedGradeId"]}
                      style={{ float: 'left' }}
                      floatingLabelText="Grade"
                      commodityId={this.state.fields.commodityId.value}
                      gradeId={this.state.fields.plannedGradeId.value}
                      season={this.state.fields.season.value}
                      selectedGradeId={this.state.fields.plannedGradeId.value}
                      dependsOnCommodity
                      dependsOnSeason
                      isFreightMovementForm
                      onChange={this.handleGradeChange}
                      disabled={this.isPlannedGradeFieldDisabled() || this.disableForm || isBookingBothSlots || isContainerMovement}
                      errorText={this.state.fields.plannedGradeId.errors[0]}
                      onBlur={this.handleBlur}
                      fixedGrades={fixedGrades}
                      removeUngraded={get(this.state.selectedBaseEntity, 'isPoolContract')}
                    />
                  </div>
                }
                <div className="col-md-12 form-wrap padding-reset">
                  <SeasonSelect
                    id="season"
                    setRef={this.fieldRef["season"]}
                    onChange={this.handleSelectFieldChange}
                    season={this.state.fields.season.value}
                    style={{ float: 'left' }}
                    errorText={this.state.fields.season.errors[0]}
                    disabled={this.isPlannedSeasonFieldDisabled() || this.disableForm || isContainerMovement || !this.state.fields.status.value}
                  />
                </div>
                {
                  this.manualComplete && this.isCreatingCanolaMovement() &&
                  <div className="col-md-12 form-wrap padding-reset">
                    <CommonTextField
                      id="coil"
                      label="COIL"
                      value={this.state.coil.value}
                      onKeyDown={(event) => positiveDecimalFilter(event, 2, 99.99)}
                      helperText={this.state.coil.errors[0]}
                      onChange={this.handleCOILAndIMPUFieldChange}
                      disabled={this.disableForm}
                    />
                  </div>
                }
                {
                  this.manualComplete && this.isCreatingCanolaMovement() &&
                  <div className='col-md-12 form-wrap padding-reset'>
                    <CommonTextField
                      id="impu"
                      label="IMPU"
                      value={this.state.impu.value}
                      onKeyDown={(event) => positiveDecimalFilter(event, 2, 99.99)}
                      helperText={this.state.impu.errors[0]}
                      onChange={this.handleCOILAndIMPUFieldChange}
                      disabled={this.disableForm}
                    />
                  </div>
                }
              </div>
              <div className="col-md-5 col-md-offset-1">
                {
                  (this.isPlannedMovement() || (!isBookingBothSlots && this.isEditForm && this.shouldShowCheckpointDetails())) &&
                  <div className="col-md-12 form-wrap padding-reset">
                    <CommonAutoSelect
                      items={orderBy(this.state.providerTrucks, 'rego')}
                      id="plannedTruckId"
                      setRef={this.fieldRef["plannedTruckId"]}
                      label={`Truck ${getCountryLabel('rego')}`}
                      value={this.state.fields.plannedTruckId.value}
                      errorText={this.state.fields.plannedTruckId.errors[0]}
                      style={{ float: 'right' }}
                      onChange={this.handleSelectFieldChange}
                      dataSourceConfig={{ text: 'rego', value: 'id' }}
                      dontAutoselectSingleItem
                      disabled={this.isPlannedTruckRegoFieldDisabled() || this.disableForm}
                    />
                    {
                      this.getAddCircleIconWithTooltip({ fontSize: '30px' }, 'plannedTruckId', this.isPlannedTruckRegoFieldDisabled())
                    }
                  </div>
                }
                {
                  isBookingBothSlots && this.isEditForm && this.editMovement &&
                  <div className='col-md-12 form-wrap padding-reset'>
                    <CommonTextField
                      id="plannedTruckRego"
                      label={`Truck ${getCountryLabel('rego')}`}
                      value={get(this.editMovement, 'plannedTruck.rego')}
                      disabled={this.isEditForm}
                    />
                  </div>
                }
                {
                  isBookingBothSlots && this.isEditForm && get(this.state, 'driver') &&
                  <div className='col-md-12 form-wrap padding-reset'>
                    <CommonTextField
                      id="driver"
                      label="Driver"
                      value={get(this.state, 'driver.name')}
                      disabled={this.isEditForm}
                    />
                  </div>
                }
                {
                  isBookingBothSlots && !this.isEditForm && this.state.applySubFreightProvider &&
                  <div className="col-md-12 form-wrap padding-reset">
                    <CommonAutoSelect
                      items={filter(this.state.subFreightProviderEmployees, i => { return i.companyId == get(find(this.state.providerTrucks, ['id', this.state.fields.plannedTruckId.value]), 'companyId'); })}
                      id="driverId"
                      setRef={this.fieldRef["driverId"]}
                      value={this.state.fields.driverId.value}
                      floatingLabelText={"Driver"}
                      label={"Driver"}
                      errorText={this.state.fields.driverId.errors[0]}
                      onChange={this.handleSelectFieldChange}
                      dontAutoselectSingleItem={this.isEditForm}
                      disabled={this.disableForm}
                    />
                  </div>
                }
                {
                  (this.isPlannedMovement() || (this.isEditForm && this.shouldShowCheckpointDetails()) || this.manualComplete) &&
                  <div>
                    {
                      this.hasQuantityBasedCommodity() ?
                        <div className="col-md-12 form-wrap padding-reset">
                          <div className="col-md-12 form-wrap padding-reset">
                            <NumberField
                              id="quantity"
                              label={this.quantityLabelForForm()}
                              setRef={this.fieldRef["quantity"]}
                              value={get(this.editMovement, 'quantity') || this.state.fields.quantity.value}
                              maxValue={99999.99}
                              helperText={this.state.fields.quantity.errors[0]}
                              onChange={this.handleQuantityFieldChange}
                              onBlur={this.handleQuantityFieldChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end" style={{ color: 'rgb(162,162,162)' }}>{this.quantityUnit()}</InputAdornment>
                              }}
                              disabled={(this.isEditForm && includes(['delivered', 'completed'], get(this.editMovement, 'status'))) || this.disableForm}
                            />
                          </div>
                          <div className="col-md-12 form-wrap padding-reset">
                            <NumberField
                              id="plannedTonnage"
                              label={this.getTonnageLabel()}
                              setRef={this.fieldRef["plannedTonnage"]}
                              value={this.state.fields.plannedTonnage.value}
                              maxValue={999999999.99}
                              helperText={this.state.fields.plannedTonnage.errors[0]}
                              onChange={this.handleTonnageFieldChange}
                              onBlur={this.handleTonnageFieldChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end" style={{ color: 'rgb(162,162,162)' }}>{MT_UNIT}</InputAdornment>
                              }}
                              disabled={(this.isEditForm && includes(['delivered', 'completed'], get(this.editMovement, 'status'))) || this.disableForm || isContainerMovement}
                            />
                          </div>
                        </div> :
                        <div className="col-md-12 form-wrap padding-reset">
                          <div className="col-md-12 form-wrap padding-reset">
                            <NumberField
                              id="plannedTonnage"
                              label={this.getTonnageLabel()}
                              setRef={this.fieldRef["plannedTonnage"]}
                              value={this.state.fields.plannedTonnage.value}
                              maxValue={999999999.99}
                              helperText={this.state.fields.plannedTonnage.errors[0]}
                              onChange={this.handleTonnageFieldChange}
                              onBlur={this.handleTonnageFieldChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end" style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                              disabled={(this.isEditForm && includes(['delivered', 'completed'], get(this.editMovement, 'status'))) || this.disableForm || isContainerMovement}
                            />
                          </div>
                        </div>
                    }
                  </div>
                }
                {this.manualComplete &&
                  <div className="col-md-12 form-wrap padding-reset">
                    <TextField
                      className="text-area"
                      id="manual_complete_notes"
                      value={this.state.fields.manual_complete_notes.value}
                      fullWidth
                      multiline={true}
                      placeholder=" Please click here to add notes."
                      disabled={this.disableForm}
                      onChange={this.handleFieldChange}
                      rows={6}
                      underlineStyle={{ display: 'none' }}
                      style={{ border: '1px solid black', borderRadius: '5px' }}
                      variant="standard" />
                  </div>
                }

              </div>
            </div>
          </div>
          {
            showFreightPickupOrDeliverySection &&
            <div className="row">
          {
          canViewPickup &&
              <div className="col-md-6" style={{paddingRight: '5px'}}>
                <div className="cardForm">
                  <h4 className="cardForm-title">{this.state.isDeliverySiteCompanyHandler ? `${this.state.pickupTerm} Details (Optional)` : `${this.state.pickupTerm} Details`}
                    {this.isDeliveredMovement() && !this.isEditForm &&
                      <CommonButton
                        label='Copy from Inload'
                        color='secondary'
                        variant='outlined'
                        size='small'
                        style={{ marginLeft: '10px', textTransform: 'none'}}
                        onClick={() => this.handleCopyLoadDetails('outload')}
                      />}
                  </h4>
                  {get(this.state.recommendedDeliverySlotText, 'length') > 1 ? <br></br> : null}
                  {get(this.state.recommendedDeliverySlotText, 'length') > 1 ? <br></br> : null}
                  <div className="cardForm-content">
                    {!this.state.isConsignorEditable ?
                      <div className="col-md-12 form-wrap">
                        <CommonTextField
                          id="freightPickup.consignor.handlerId"
                          label="Pickup Site"
                          value={get(this.state.selectedBaseEntity, 'consignorHandlerName') || get(this.state.selectedConsignor, 'name')}
                          additionalHelperText={consignorAddressLink}
                          helperText = {this.state.fields.freightPickup.consignor.handlerId.errors?.[0]}
                          disabled
                        />
                      </div> :
                      <div className="col-md-12 form-wrap" ref={this.fieldRef['freightPickup.consignor.handlerId']}>
                      { this.state.isPickupSiteMandatoryBasedOnPricePoint ?
                        <Autocomplete
                          id="freightPickup.consignor.handlerId"
                          ref={this.fieldRef['freightPickup.consignor.handlerId']}
                          blurOnSelect
                          options={this.state.consignors || []}
                          getOptionLabel={option => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.name;
                            }
                            return option.name;
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="standard"
                              label='Pickup Site'
                              fullWidth
                              helperText={this.state.fields.freightPickup.consignor.handlerId.errors[0] || consignorAddressLink}
                              error={this.state.fields.freightPickup.consignor.handlerId.errors[0]}
                            />
                          )}
                          value={get(this.state, 'selectedConsignor', '')}
                          onInputChange={this.handleConsignorInputChange}
                          onChange={this.handleConsignorChange}
                          disabled={this.disableForm}
                        /> :
                        <SiteAsyncAutocomplete
                          limitTags={2}
                          label="Pickup Site"
                          id="freightPickup.consignor.handlerId"
                          onChange={item => this.handleConsignorChange(null, item)}
                          selected={get(this.state, 'selectedConsignor', '')}
                          minLength={3}
                          variant="standard"
                          addSiteOption
                          disabled={this.disableForm}
                          fullWidth
                          activeSitesOnly
                          addLabel
                          helperText={consignorAddressLink}
                          errorText={this.state.fields.freightPickup.consignor.handlerId.errors[0]}
                          options={get(this.state.selectedBaseEntity, 'consignorsWithSites', []).length > 1 ? this.state.consignors : []}
                          override={Boolean(this.editMovement && this.state.selectedConsignorId)}
                          farmId={this.state.selectedConsignorId  && this.editMovement ? this.state.selectedConsignorId : null}
                        />
                      }
                      </div>
                    }
                    {this.state.openConsignorFarmForm &&
                      <UpdateLocation updateEntities={(address, mobile) => this.updateFarmEntities(get(this.state, 'selectedConsignor.id'), address, mobile, 'pickup')} entityId={get(this.state, 'selectedConsignor.id')} entity='farm' onCloseDrawer={() => this.setState({openConsignorFarmForm: false})}/>
                    }
                    {
                      <div className="col-md-12 form-wrap">
                        <CommonAutoSelect
                          items={this.state.consignorSites}
                          id="freightPickup.consignor.site.locationId"
                          setRef={this.fieldRef["freightPickup.consignor.site.locationId"]}
                          label="Pickup Storage"
                          value={this.state.fields.freightPickup.consignor.site.locationId.value}
                          errorText={this.state.fields.freightPickup.consignor.site.locationId.errors[0]}
                          helperText={consignorStorageAddressLink}
                          style={{ float: 'right' }}
                          onChange={this.handleSiteChange}
                          selectedItem={this.state.selectedConsignorSite}
                          dontAutoselectSingleItem
                          top={this.manualComplete}
                          disabled={this.disableForm}
                        />
                      </div>
                    }
                    {this.state.openConsignorStorageForm &&
                      <UpdateLocation updateEntities={() => this.getSites(get(this.state, 'selectedConsignor.id'), 'farm', 'consignor')} selectedStorage={this.state.selectedConsignorSite} entity='storage' onCloseDrawer={() => this.setState({openConsignorStorageForm: false})} farmId={get(this.state, 'selectedConsignor.id')} companyId={get(this.state, 'selectedConsignor.companyId')}/>
                    }
                    <div className="col-md-12">
                      <div className="col-md-6 form-wrap no-left-padding">
                        <CommonDatePicker
                          id="freightPickup.date"
                          setRef={this.fieldRef["freightPickup.date"]}
                          floatingLabelText="Pickup Date"
                          onChange={this.handleSelectFieldChange}
                          errorText={this.state.fields.freightPickup.date.errors[0]}
                          disabled={this.disableForm}
                          value={this.state.fields.freightPickup.date.value}
                          minDate={this.pickUpDateMin()}
                          maxDate={this.pickUpDateMax()}
                          onBlur={this.setDeliveryEndDate}
                        />
                      </div>
                      <div style={{ marginLeft: '0px' }} className="col-md-6 col-md-offset-2 form-wrap no-right-padding">
                        {
                          (this.shouldFetchSlotsFor('Pickup') || this.isBaseEntityNotOrderAndSlotOrderBookingOn('Pickup')) ?
                            <CommonSelect
                              id='outloadSlotId'
                              onChange={this.handleSlotChange}
                              errorText={this.state.fields.outloadSlotId.errors[0]}
                              floatingLabelText="Select Slot"
                              selectConfig={{ text: 'name', value: 'id' }}
                              items={this.state.outloadSlots || []}
                              value={this.state.fields.outloadSlotId.value}
                            /> :
                            <CommonTimePicker
                              id="freightPickup.timeStart"
                              setRef={this.fieldRef["freightPickup.timeStart"]}
                              floatingLabelText="Pickup Time"
                              value={this.state.fields.freightPickup.timeStart.value}
                              onChange={this.handleSelectFieldChange}
                              errorText={this.state.fields.freightPickup.timeStart.errors[0]}
                              disabled={this.disableForm}
                            />
                        }
                      </div>
                    </div>
                    {
                    this.isTrailerBookingApplicable('Pickup') &&
                    <div className='col-sm-12 form-wrap' style={{ minHeight: '75px' }}>
                      Pickup Trailer Slots
                      <div>
                        {
                          map(['trailer1', 'trailer2', 'trailer3'], (trailer, index) => {
                            const fieldSettings = get(this.state.pickupSMSettings, 'fields')
                            return (
                              <div className='col-sm-12 form-wrap' style={{ minHeight: '75px' }} key={trailer}>
                                {find(fieldSettings, {id: `trailer_${index+1}`})?.show &&
                                  <CommonSelect
                                    id={trailer}
                                    floatingLabelText={find(fieldSettings, {id: `trailer_${index+1}`})?.label}
                                    onChange={(value) => this.handleTrailerSlotChange(value, trailer, 'pickupSiteTrailers')}
                                    items={this.getSlotOptionsForTrailer(trailer, 'Pickup')}
                                    value={this.state.pickupSiteTrailers[trailer].slotId}
                                    includeEmptyOption={true}
                                    dontAutoselectSingleItem
                                    errorText= { index == 0 && this.state.pickupSlotTrailerErrors ? this.state.pickupSlotTrailerErrors : null}
                                  />
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    }

                    {this.isDeliveredMovement() && !this.isEditForm &&
                      <div className={this.manualComplete ? "col-md-12 no-side-padding" : ""}>
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <SeasonSelect
                              id="freightPickup.outload.season"
                              floatingLabelText="Season"
                              setRef={this.fieldRef["freightPickup.outload.season"]}
                              onChange={this.handleSelectFieldChange}
                              season={this.state.fields.freightPickup.outload.season.value}
                              style={{ float: 'left' }}
                              errorText={this.state.fields.freightPickup.outload.season.errors[0]}
                              includeEmptyOption={!this.state.freightPickupSiteIsSystemStorage}
                              disabled={this.disableForm || isContainerMovement}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete && <div className="col-md-12 form-wrap">
                            <GradeAutoComplete
                              id="freightPickup.outload.gradeId"
                              setRef={this.fieldRef["freightPickup.outload.gradeId"]}
                              style={{ float: 'right' }}
                              floatingLabelText="Grade"
                              placeholder="Grade"
                              commodityId={this.state.fields.commodityId.value}
                              gradeId={this.state.fields.freightPickup.outload.gradeId.value}
                              season={this.state.fields.season.value}
                              dependsOnCommodity
                              dependsOnSeason
                              onChange={this.handleGradeChange}
                              disabled={!isNumber(this.state.fields.commodityId.value) || this.disableForm || isContainerMovement}
                              errorText={this.state.fields.freightPickup.outload.gradeId.errors[0]}
                              onBlur={this.handleBlur}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"
                                  style={{ color: 'rgb(162,162,162)' }}>{MT_UNIT}</InputAdornment>
                              }}
                              specs={mapValues(this.state.fields.freightPickup.outload.specs, spec => spec.value)}
                              removeUngraded={get(this.state.selectedBaseEntity, 'isPoolContract')}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <VarietyAutoComplete
                              id="freightPickup.outload.varietyId"
                              setRef={this.fieldRef["freightPickup.outload.varietyId"]}
                              label={this.state.fields.freightPickup.outload.varietyId.validators.length ? "Variety" : "Variety (Optional)"}
                              commodityId={this.state.fields.commodityId.value}
                              varietyId={this.state.fields.freightPickup.outload.varietyId.value}
                              dependsOnCommodity
                              onChange={this.handleOutloadVarietyChange}
                              style={{ float: 'left' }}
                              errorText={this.state.fields.freightPickup.outload.varietyId.errors[0]}
                              onBlur={this.handleBlur}
                              disabled={this.disableForm}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <CommonAutoSelect
                              items={this.state.freightPickupNgrs}
                              dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                              id="freightPickup.outload.ngrId"
                              setRef={this.fieldRef["freightPickup.outload.ngrId"]}
                              label='NGR'
                              value={this.state.fields.freightPickup.outload.ngrId.value}
                              errorText={this.state.fields.freightPickup.outload.ngrId.errors[0] || ((this.state.freightPickupSiteIsSystemStorage && !this.state.fields.freightPickup.outload.ngrId.value) ? NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE : '')}
                              style={{ float: 'left' }}
                              onChange={this.handleSelectFieldChange}
                              dontAutoselectSingleItem
                              disabled={this.disableForm || (isContainerMovement && this.state.fields.freightPickup.outload.ngrId.value)}
                            />
                          </div>
                        }
                        {
                          this.isDeliveredMovement() &&
                          <div className='col-sm-12  form-wrap-70'>
                            <TruckSearchOption
                              id='pickupSearchOption'
                              label='Search Trucks Of'
                              handleSearchOptionChange={this.handleSearchOptionChange}
                              value={this.state.fields.freightPickup.searchOption}
                              disabled={!this.state.fields.providerId.value}
                              tooltipPosition='bottom'
                              freightProviderLabel='Freight Provider'
                              allRegoLabel={`All ${regoLabel}s`}
                              truckOwnerLabel='Truck Owner'
                            />
                          </div>
                        }
                        {
                          <div className={'col-sm-12 form-wrap-70'} style={{ marginTop: this.state.fields.freightPickup.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS ? '30px' : '18px', marginBottom: this.state.fields.freightPickup.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS ? '-10px' : '0px'}}>
                            {
                              this.state.fields.freightPickup.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS ?
                              <CompanyAutocomplete
                                id='outloadTruckOwner'
                                selected={this.state.fields.freightPickup?.outload.truckOwner.value}
                                onChange={this.onCompanyChange}
                                minLength={3}
                                getRegisteredCompanies={false}
                                variant="standard"
                                disabled={disableBasedOnPickupSearchOption}
                                label='Truck Owner'
                              /> :
                              <CommonTextField
                                id="outloadTruckOwner"
                                label='Truck Owner'
                                value={this.state.fields.freightPickup?.outload.truckOwner.value?.name}
                                disabled
                              />
                            }
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className='col-md-12 form-wrap'>
                            {
                                this.state.fields.freightPickup.searchOption === SEARCH_BY_ALL_REGOS ?
                                <CommonSelectInput
                                search
                                allowEmptyOptions
                                allowText={false}
                                endpoint="trucks/search/"
                                queryParams={{is_active: true}}
                                options={this.state.isCopyFromInload ? this.state.selectedTrucks : []}
                                optionMap={{ id: 'id', name: 'rego', companyId: 'companyId', companyName: 'companyName', totalWeights: 'totalWeights', categoryId: 'categoryId', code: 'code', steerPoint5: 'steerPoint5', steer1Point1: 'steer1Point1', transactionParticipation: 'transactionParticipation' }}
                                inputText={get(find(this.state.selectedTrucks, { id: this.state.fields.freightPickup.outload.truckId.value }), 'rego')}
                                id='freightPickup.outload.truckId'
                                setRef={this.fieldRef["freightPickup.outload.truckId"]}
                                label={`Truck ${getCountryLabel('rego')}`}
                                create={this.props.createTruck}
                                actionCreator={addTruck}
                                validate={this.props.validateRego}
                                items={this.state.selectedTrucks}
                                value={this.state.fields.freightPickup.outload.truckId.value}
                                errorText={this.isRegoSearchable() ? this.state.fields.freightPickup.outload.truckId.errors[0] : this.state.fields.freightPickup.outload.truckId.errors[0] ? 'Select rego from list' : ''}
                                onChange={this.handleSelectFieldChange}
                                onInput={(e) => {
                                  const newState = {...this.state};
                                  newState.isCopyFromInload = false;
                                  e.target.value = e.target.value.toString().slice(0, 10).replace(/[^0-9a-z]/gi, '');
                                  if(e.target.value == ''){
                                    newState.fields.freightPickup.outload.truckOwnerId.value = '';
                                    newState.fields.freightPickup.outload.truckOwner.value = null;
                                    newState.fields.freightPickup.outload.truckId.value = null;
                                  }
                                  this.setState(newState);
                                }}
                              /> :
                              <CommonAutoSelect
                                items={this.state.pickupOwnerTrucks.length != 0 ?orderBy(this.state.pickupOwnerTrucks, 'id') : orderBy(this.state.providerTrucks, 'id')}
                                id='freightPickup.outload.truckId'
                                setRef={this.fieldRef['freightPickup.outload.truckId']}
                                label={`Truck ${getCountryLabel('rego')}`}
                                value={this.state.fields.freightPickup.outload.truckId.value}
                                errorText={this.state.fields.freightPickup.outload.truckId.errors[0]}
                                onChange={this.handleSelectFieldChange}
                                dataSourceConfig={{ text: 'rego', value: 'id' }}
                                dontAutoselectSingleItem={true}
                                top={true}
                                disabled={this.state.fields.freightPickup.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS && !this.state.fields.freightPickup.outload.truckOwner.value}
                                filterOptions={(options, params) => {
                                    const filtered = autocompleteFilters(options, params);
                                    if(!get(this.state.fields.freightPickup.outload.truckOwner.value , 'transactionParticipation')) {
                                      if (params.inputValue !== '' && params.inputValue.length >= 4 && params.inputValue.length <= 10 && params.inputValue.toLowerCase() !== 'fleet') {
                                        filtered.push({
                                          inputValue: params.inputValue,
                                          name: `Add "${params.inputValue}" as new ${getCountryLabel('rego')}`,
                                        });
                                      }
                                      return filtered;
                                    }
                                    return filtered;
                                  }
                                }
                              />
                            }
                          </div>
                        }
                        {
                          !this.manualComplete && isContainerMovement &&
                          <div className="col-md-12 form-wrap">
                            <CommonTextField
                              id="freightPickup.outload.containerTareWeight"
                              setRef={this.fieldRef["freightPickup.outload.containerTareWeight"]}
                              label="Container Tare"
                              value={this.state.fields.freightPickup.outload.containerTareWeight.value}
                              onKeyDown={(event) => positiveDecimalFilter(event, 2, 9999.99)}
                              helperText={this.state.fields.freightPickup.outload.containerTareWeight.errors[0]}
                              disabled={this.disableForm}
                              onChange={this.handlePickupContainerTareWeightChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"
                                  style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <CommonTextField
                              id="freightPickup.outload.tareWeight"
                              setRef={this.fieldRef["freightPickup.outload.tareWeight"]}
                              label={get(this.state.fields, 'freightPickup.consignor.site.locationType.value') === 'farmfield' && get(this.state, 'selectedBaseEntity.weightId') !== WEIGHT_ORIGIN_ID && !this.state.fields.freightPickup.outload.grossWeight.value ? "Truck Tare Weight (Optional)" : "Truck Tare Weight"}
                              value={this.state.fields.freightPickup.outload.tareWeight.value}
                              onKeyDown={(event) => positiveDecimalFilter(event, 2, 999999999.99)}
                              helperText={this.state.fields.freightPickup.outload.tareWeight.errors[0]}
                              disabled={this.disableForm}
                              onChange={this.handlePickupTareWeightChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"
                                  style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <CommonTextField
                              id="freightPickup.outload.grossWeight"
                              setRef={this.fieldRef["freightPickup.outload.grossWeight"]}
                              label={get(this.state.fields, 'freightPickup.consignor.site.locationType.value') === 'farmfield' && get(this.state, 'selectedBaseEntity.weightId') !== WEIGHT_ORIGIN_ID && !this.state.fields.freightPickup.outload.tareWeight.value ? "Truck Gross Weight (Optional)" : "Truck Gross Weight"}
                              value={this.state.fields.freightPickup.outload.grossWeight.value}
                              disabled={this.disableForm}
                              onKeyDown={(event) => positiveDecimalFilter(event, 2, 9999999999.99)}
                              helperText={get(
                                this.state.fields.freightPickup.outload,
                                'grossWeight.errors[0]'
                              )}
                              onChange={this.handlePickupGrossWeightChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"
                                  style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <CommonTextField
                              id="freightPickup.netWeight"
                              setRef={this.fieldRef["freightPickup.netWeight"]}
                              label={"Net Weight" + get(this.state, 'sellerAvailableTonnageMessage', '')}
                              value={this.state.fields.freightPickup.netWeight.value}
                              disabled={this.disableForm}
                              onKeyDown={(event) => positiveDecimalFilter(event, 2, 999999999.99)}
                              helperText={get(this.state.fields.freightPickup, 'netWeight.errors[0]')}
                              onChange={() => { }}
                              onBlur={e => this.handleNetWeightChange(e, 'freightPickup', 'outload')}
                              InputProps={{
                                endAdornment: <InputAdornment position="end" style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-12 form-wrap">
                            <CommonAutoSelect
                              id="pickupCode"
                              label='Code'
                              placeholder="Select Code..."
                              items={map(TRUCK_CODES, code => ({ id: code, name: code }))}
                              value={this.state.pickupCode.value || ''}
                              onChange={this.handleTruckCodeChange}
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-4 form-wrap">
                            <CommonAutoSelect
                              id="pickupMassLimitPermit"
                              label='Mass Limit Permit'
                              placeholder="Select Mass Limit Permit..."
                              items={map(this.state.permits, permit => ({ id: permit, name: permit }))}
                              value={this.state.pickupMassLimitPermit.value || ''}
                              onChange={this.handleMassLimitChange}
                              dataSourceConfig={{ id: 'id', text: 'name' }}
                              errorText={this.state.pickupMassLimitPermit.errors[0]}
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-3 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='steer_point_5'
                                  color='primary'
                                  checked={this.state.fields.freightPickup.outload.steer_point_5.value}
                                  onChange={event => this.handleSteerChange(event, 'freightPickup.outload')}
                                  disabled={!this.shouldEnablePickupSteerPoint5()}
                                />
                              }
                              label='0.5 T Steer Axle Allowance'
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-3 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='steer_1_point_1'
                                  color='primary'
                                  checked={this.state.fields.freightPickup.outload.steer_1_point_1.value}
                                  onChange={event => this.handleSteerChange(event, 'freightPickup.outload')}
                                  disabled={!this.shouldEnablePickupSteer1Point1()}
                                />
                              }
                              label='1.1 T Allowance'
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-2 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='pickupGHMS'
                                  color='primary'
                                  checked={this.state.pickupGHMS.value}
                                  onChange={this.handlePickupGHMSChange}
                                />
                              }
                              label='GHMS'
                            />
                          </div>
                       }
                       { this.state.pickupMassLimitPermit.value === 'Notice' &&
                          <div className="col-sm-4" style={{marginBottom: '30px'}}>
                            <CommonAutoSelect
                              id="fields.freightPickup.outload.noticeNumber"
                              label='Notice Number'
                              placeholder="Select Notice Number..."
                              items={NOTICE_NUMBERS}
                              value={this.state.fields.freightPickup.outload.noticeNumber.value}
                              onChange={this.handleFreightPickupTruckDetailsSelectValueChange}
                              dataSourceConfig={{id: 'id', text: 'name'}}
                              errorText={this.state.freightPickupTruckErrors.noticeNumber}
                            />
                          </div>
                        }
                        {
                          showPickupAdditionalMassLimitCodeFields && isTruckMassLimitsVisible &&
                          <AdditionalMassLimitFields
                            permitChecked={Boolean(this.state.freightPickupPermitChecked)}
                            handlePermitClick={this.handleFreightPickupPermitClick}
                            accreditationNumberChecked={Boolean(this.state.freightPickupAccreditationNumberChecked)}
                            handleAccreditationNumberClick={this.handleFreightPickupAccreditationNumberClick}
                            loadSharing={Boolean(this.state.freightPickupLoadSharing)}
                            handleLoadSharingClick={this.handleFreightPickupLoadSharingClick}
                            disableLoadSharing={disableFreightPickupLoadSharing}
                            isPBSMassLimitPermit={(this.state.pickupMassLimitPermit.value || '').includes('PBS - ')}
                            notice={this.state.pickupMassLimitPermit.value === 'Notice'}
                            handleTruckDetailsChange={this.handleFreightPickupTruckDetailsChange}
                            restrictedChecked={this.state.freightPickupRestrictedChecked}
                            handleRestrictedClick={this.handleFreightPickupRestrictedClick}
                            handleTruckDetailsSelectValueChange={this.handleFreightPickupTruckDetailsSelectValueChange}
                            accreditationNumberFieldId="fields.freightPickup.outload.accreditationNumber"
                            accreditationNumber={this.state.fields.freightPickup.outload.accreditationNumber.value}
                            restrictedFieldId="fields.freightPickup.outload.restricted"
                            restricted={this.state.fields.freightPickup.outload.restricted.value}
                            declaredMassLimitFieldId="fields.freightPickup.outload.declaredMassLimit"
                            declaredMassLimit={this.state.fields.freightPickup.outload.declaredMassLimit.value}
                            permitNumberFieldId="fields.freightPickup.outload.permitNumber"
                            permitNumber={this.state.fields.freightPickup.outload.permitNumber.value}
                            categoryId={this.state.fields.freightPickup.outload.categoryId.value}
                            truckErrors={this.state.freightPickupTruckErrors}
                            truckUnit={priceUnit}
                          />
                        }
                        {
                          !this.manualComplete && this.isDeliveredMovement() && this.quantityLabel() &&
                          this.hasQuantityBasedCommodity() &&
                          <div className="col-md-12 form-wrap">
                            <NumberField
                              id="freightPickup.outload.quantity"
                              label={this.quantityLabel()}
                              setRef={this.fieldRef["freightPickup.outload.quantity"]}
                              value={this.state.fields.quantity.value}
                              fieldType={'float'}
                              maxValue={99999.99}
                              helperText={this.state.fields.freightPickup.outload.quantity.errors[0]}
                              onChange={this.handleQuantityFieldChange}
                              onBlur={this.handleQuantityFieldChange}
                              disabled={(this.isEditForm && includes(['delivered', 'completed'], get(this.editMovement, 'status'))) || this.disableForm}
                            />
                          </div>
                        }


                        {
                          !this.manualComplete &&
                          <div className="col-md-8">
                            {
                              this.shouldShowMaximumAllowedTonnageForDeliveredStatus('outload') && !this.isMeterCubeCommodityCommodity() &&
                              <Typography variant="subheading" gutterBottom>
                                Maximum Allowed {this.tonnageLabel}: {this.unplannedContractTonnage()} {priceUnit}
                              </Typography>
                            }
                            {
                              this.shouldShowMaximumAllowedTonnageForDeliveredStatus('outload') && this.isMeterCubeCommodityCommodity() &&
                              <Typography variant="subheading" gutterBottom>
                                {`Maximum Allowed ${commodity?.quantityLabel}: ${this.unplannedContractTonnage()} ${commodity?.unit}`}
                              </Typography>
                            }
                          </div>
                        }
                        {this.isDeliveredMovement() && !this.isEditForm && !this.manualComplete &&
                          <div className="col-md-12 padding-reset label-small">
                            <SpecParametersValue
                              id='outload.specs'
                              commoditySpecs={this.state.commoditySpecs}
                              fieldsSpecs={this.state.fields.freightPickup.outload.specs}
                              onChange={this.handlePickupSpecsChange}
                              errorTexts={mapValues(this.state.fields.freightPickup.outload.specs, spec => {
                                return get(spec, 'errors[0]', '');
                              })}
                              warningTexts={mapValues(this.state.fields.freightPickup.outload.specs, spec => get(spec, 'warnings[0]', ''))}
                              fieldWrapperClassName="col-xs-4 form-wrap"
                              clearIndex={[3]}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-5 form-wrap">
                            <CommonTextField
                              id="freightPickup.outload.docketNumber"
                              setRef={this.fieldRef["freightPickup.outload.docketNumber"]}
                              label={`${getCountryLabel('docket')} No${this.state.freightPickupSiteIsSystemStorage ? '' : ' (Optional)'}`}
                              value={this.state.fields.freightPickup.outload.docketNumber.value}
                              helperText={this.state.fields.freightPickup.outload.docketNumber.errors[0]}
                              onChange={this.handleFieldChange}
                              onBlur={this.handleBlur}
                              disabled={this.disableForm}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-7 form-wrap padding-reset">
                            <FileUpload
                              id="freightPickup.docketImage"
                              floatingLabelText={`${getCountryLabel('docket')} Image`}
                              fullWidth={true}
                              fileCount={this.state.fileCountPickup}
                              errorText={this.state.fields.freightPickup.docketImage.errors[0]}
                              textFieldstyle={{ float: 'left', color: 'black' }}
                              onChange={this.onPickupDocketImageUpload}
                              onRemove={this.onPickupDocketImageRemove}
                              disabled={this.disableForm}
                            />
                          </div>
                        }
                      </div>
                    }
                    {
                      !this.manualComplete &&
                      <div className="col-md-12 form-wrap">
                        <CommonTextField
                          id="freightPickup.orderNumber"
                          setRef={this.fieldRef["freightPickup.orderNumber"]}
                          label="Order No (Optional)"
                          value={this.state.fields.freightPickup.orderNumber.value}
                          maxLength="10"
                          helperText={this.state.fields.freightPickup.orderNumber.errors[0]}
                          onChange={this.handleFieldChange}
                          onBlur={this.handleBlur}
                          disabled={get(this.state.selectedBaseEntity, 'pickupOrderNumber') || this.disableForm}
                        />
                      </div>
                    }
                    {
                      !this.manualComplete &&
                      <div className="col-md-12 form-wrap">
                        <CommonTextField
                          id="freightPickup.bookingNumber"
                          setRef={this.fieldRef["freightPickup.bookingNumber"]}
                          label="Booking No (Optional)"
                          value={this.state.fields.freightPickup.bookingNumber.value}
                          maxLength="10"
                          helperText={this.state.fields.freightPickup.bookingNumber.errors[0]}
                          onChange={this.handleFieldChange}
                          onBlur={this.handleBlur}
                          disabled={this.disableForm}
                        />
                      </div>
                    }
                    {(this.isPlannedMovement() || this.isEditForm) &&
                      <div className="col-md-12 form-wrap top-15">
                        <CommonTextField
                          id="freightPickup.instructions"
                          setRef={this.fieldRef["freightPickup.instructions"]}
                          label="Pickup Instructions (Optional)"
                          value={this.state.fields.freightPickup.instructions.value}
                          helperText={this.state.fields.freightPickup.instructions.errors[0]}
                          disabled={this.disableForm || this.canEditInstructions()}
                          onChange={this.handleFieldChange}
                          multiline={true}
                          rows={2}
                          rowsMax={2}
                          onBlur={this.handleBlur}
                        />
                      </div>
                    }
                    {this.isDeliveredMovement() &&
                      <div className="col-md-12 form-wrap top-15">
                        <CommonTextField
                          id="freightPickup.outload.comment"
                          label="Pickup Comment (Optional)"
                          value={this.state.fields.freightPickup.outload.comment.value}
                          disabled={this.disableForm}
                          onChange={this.handleFieldChange}
                          multiline={true}
                          rows={2}
                          rowsMax={2}
                          onBlur={this.handleBlur}
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
          }
          {
          canViewDelivery &&
              <div className="col-md-6" style={{paddingLeft: '5px'}}>
                <div className="cardForm">
                  <h4 className="cardForm-title">
                    {this.state.isPickupSiteCompanyHandler ? `${this.state.deliveryTerm} Details (Optional)` : `${this.state.deliveryTerm} Details `}
                    {
                      this.isDeliveredMovement() && !this.isEditForm &&
                        <CommonButton
                          label='Copy from Outload'
                          color='secondary'
                          variant='outlined'
                          size='small'
                          style={{ marginLeft: '10px', textTransform: 'none'}}
                          onClick={() => this.handleCopyLoadDetails('inload')}
                        />
                    }
                    {get(this.state.recommendedDeliverySlotText, 'length') > 1 ? <br></br> : null}
                    {map(this.state.recommendedDeliverySlotText, data => { return (<span style={{ fontSize: '14px', fontWeight: '400' }}>{data}<br></br></span>); }
                    )}
                  </h4>
                  <div className="cardForm-content">
                    {!this.state.isConsigneeEditable ?
                      <div className="col-md-12 form-wrap">
                        <CommonTextField
                          id="freightDelivery.consignee.handlerId"
                          label="Delivery Site"
                          value={get(this.state.selectedBaseEntity, 'consigneeHandlerName') || get(this.state.selectedConsignee, 'name')}
                          additionalHelperText={consigneeAddressLink}
                          helperText = {this.state.fields.freightDelivery.consignee.handlerId.errors?.[0]}
                          disabled
                        />
                      </div> :
                      <div className="col-md-12 form-wrap" ref={this.fieldRef['freightDelivery.consignee.handlerId']}>
                        { this.state.isDeliverySiteMandatoryBasedOnPricePoint ?
                          <Autocomplete
                          id="freightDelivery.consignee.handlerId"
                          blurOnSelect
                          options={this.state.consignees || []}
                          getOptionLabel={option => {
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.name;
                            }
                            return option.name;
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="standard"
                              label='Delivery Site'
                              fullWidth
                              error={this.state.fields.freightDelivery.consignee.handlerId.errors[0]}
                              helperText={this.state.fields.freightDelivery.consignee.handlerId.errors[0] || consigneeAddressLink}
                            />
                          )}
                          value={get(this.state, 'selectedConsignee', '')}
                          onInputChange={this.handleConsigneeInputChange}
                          onChange={this.handleConsigneeChange}
                          disabled={this.disableForm}
                        /> :
                        <SiteAsyncAutocomplete
                          limitTags={2}
                          label="Delivery Site"
                          id="freightDelivery.consignee.handlerId"
                          onChange={item => this.handleConsigneeChange(null, item)}
                          selected={get(this.state, 'selectedConsignee', '')}
                          minLength={3}
                          variant="standard"
                          addSiteOption
                          disabled={this.disableForm}
                          fullWidth
                          activeSitesOnly
                          addLabel
                          helperText={consigneeAddressLink}
                          errorText={this.state.fields.freightDelivery.consignee.handlerId.errors[0]}
                          options={get(this.state.selectedBaseEntity, 'consigneesWithSites', []).length > 1 ? this.state.consignees : []}
                          override={Boolean(this.editMovement && this.state.selectedConsigneeId)}
                          farmId={this.state.selectedConsigneeId && this.editMovement ? this.state.selectedConsigneeId : null}
                        />
                      }
                      </div>
                    }
                    {this.state.openConsigneeFarmForm &&
                      <UpdateLocation updateEntities={(address, mobile) => this.updateFarmEntities(get(this.state, 'selectedConsignee.id'), address, mobile, 'delivery')} entityId={get(this.state, 'selectedConsignee.id')} entity='farm' onCloseDrawer={() => this.setState({openConsigneeFarmForm: false})}/>
                    }
                    {!isContainerMovement &&
                      <div className="col-md-12 form-wrap">
                        <CommonAutoSelect
                          items={consigneeSites}
                          id="freightDelivery.consignee.site.locationId"
                          setRef={this.fieldRef["freightDelivery.consignee.site.locationId"]}
                          label="Delivery Storage"
                          value={this.state.fields.freightDelivery.consignee.site.locationId.value}
                          errorText={this.state.fields.freightDelivery.consignee.site.locationId.errors[0]}
                          helperText={consigneeStorageAddressLink}
                          style={{ float: 'right' }}
                          onChange={this.handleSiteChange}
                          selectedItem={this.state.selectedConsigneeSite}
                          dontAutoselectSingleItem
                          top={this.manualComplete}
                          disabled={this.disableForm}
                        />
                      </div>
                    }
                    {this.state.openConsigneeStorageForm &&
                      <UpdateLocation updateEntities={() => this.getSites(get(this.state, 'selectedConsignee.id'), 'farm', 'consignee')} selectedStorage={this.state.selectedConsigneeSite} entity='storage' onCloseDrawer={() => this.setState({openConsigneeStorageForm: false})} farmId={get(this.state, 'selectedConsignee.id')} companyId={get(this.state, 'selectedConsignee.companyId')}/>
                    }
                    {
                      isContainerMovement &&
                      <div className="col-md-12 form-wrap">
                        <CommonTextField
                          id="containerNumber"
                          setRef={this.fieldRef["containerNumber"]}
                          label="Container Number"
                          value={this.state.fields.containerNumber.value}
                          disabled
                        />
                      </div>
                    }
                    <div className="col-md-12">
                      <div className="col-md-6 form-wrap no-left-padding">
                        <CommonDatePicker
                          id="freightDelivery.date"
                          setRef={this.fieldRef["freightDelivery.date"]}
                          floatingLabelText="Delivery Date"
                          onChange={this.handleSelectFieldChange}
                          errorText={this.state.fields.freightDelivery.date.errors[0]}
                          disabled={this.disableForm}
                          value={this.state.fields.freightDelivery.date.value}
                          style={{ float: 'right' }}
                          minDate={this.deliveryDateMin()}
                          maxDate={this.deliveryDateMax()}
                        />
                      </div>
                      <div style={{ marginLeft: '0px' }} className="col-md-6 col-md-offset-2 form-wrap no-right-padding">
                        {
                          (this.shouldFetchSlotsFor('Delivery') || this.isBaseEntityNotOrderAndSlotOrderBookingOn("Delivery")) ?
                            <CommonSelect
                              id='inloadSlotId'
                              onChange={this.handleSlotChange}
                              errorText={this.state.fields.inloadSlotId.errors[0] || this.state.warningText}
                              floatingLabelText="Select Slot"
                              selectConfig={{ text: 'name', value: 'id' }}
                              items={this.state.inloadSlots || []}
                              value={this.state.fields.inloadSlotId.value}
                            /> :
                            <CommonTimePicker
                              id="freightDelivery.timeStart"
                              setRef={this.fieldRef["freightDelivery.timeStart"]}
                              floatingLabelText="Delivery Time"
                              value={this.state.fields.freightDelivery.timeStart.value}
                              onChange={this.handleSelectFieldChange}
                              disabled={this.disableForm}
                              errorText={this.state.fields.freightDelivery.timeStart.errors[0]}
                              style={{ float: 'right' }}
                            />
                        }
                      </div>
                    </div>
                    {
                    this.isTrailerBookingApplicable('Delivery') &&
                    <div className='col-sm-12 form-wrap' style={{ minHeight: '75px' }}>
                      Delivery Trailer Slots
                      <div>
                        {
                          map(['trailer1', 'trailer2', 'trailer3'], (trailer, index) => {
                            const fieldSettings = get(this.state.deliverySMSettings, 'fields')
                            return (
                              <div className='col-sm-12 form-wrap' style={{ minHeight: '75px' }} key={trailer}>
                                {find(fieldSettings, {id: `trailer_${index+1}`})?.show &&
                                  <CommonSelect
                                    id={trailer}
                                    floatingLabelText={find(fieldSettings, {id: `trailer_${index+1}`})?.label}
                                    onChange={(value) => this.handleTrailerSlotChange(value, trailer, 'deliverySiteTrailers')}
                                    items={this.getSlotOptionsForTrailer(trailer, 'Delivery')}
                                    value={this.state.deliverySiteTrailers[trailer].slotId}
                                    includeEmptyOption={true}
                                    dontAutoselectSingleItem
                                    errorText= { index == 0 && this.state.deliverySlotTrailerErrors ? this.state.deliverySlotTrailerErrors : null}
                                  />
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    }

                    {this.isDeliveredMovement() && !this.isEditForm &&
                      <div className={this.manualComplete ? "col-md-12 no-side-padding" : ""}>
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <SeasonSelect
                              id="freightDelivery.inload.season"
                              floatingLabelText="Season"
                              setRef={this.fieldRef["freightDelivery.inload.season"]}
                              onChange={this.handleSelectFieldChange}
                              season={this.state.fields.freightDelivery.inload.season.value}
                              style={{ float: 'left' }}
                              errorText={this.state.fields.freightDelivery.inload.season.errors[0]}
                              includeEmptyOption={!this.state.freightDeliverySiteIsSystemStorage}
                              disabled={this.disableForm || isContainerMovement}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete && <div className="col-md-12 form-wrap">
                            <GradeAutoComplete
                              id="freightDelivery.inload.gradeId"
                              setRef={this.fieldRef["freightDelivery.inload.gradeId"]}
                              style={{ float: 'right' }}
                              floatingLabelText="Grade"
                              commodityId={this.state.fields.commodityId.value}
                              gradeId={this.state.fields.freightDelivery.inload.gradeId.value}
                              season={this.state.fields.season.value}
                              dependsOnCommodity
                              dependsOnSeason
                              onChange={this.handleGradeChange}
                              disabled={!isNumber(this.state.fields.commodityId.value) || this.disableForm || (isContainerMovement && !get(this.state.selectedBaseEntity, 'isPickupOrder'))}
                              errorText={this.state.fields.freightDelivery.inload.gradeId.errors[0]}
                              onBlur={this.handleBlur}
                              specs={mapValues(this.state.fields.freightDelivery.inload.specs, spec => spec.value)}
                              removeUngraded={get(this.state.selectedBaseEntity, 'isPoolContract')}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <VarietyAutoComplete
                              id="freightDelivery.inload.varietyId"
                              setRef={this.fieldRef["freightDelivery.inload.varietyId"]}
                              commodityId={this.state.fields.commodityId.value}
                              varietyId={this.state.fields.freightDelivery.inload.varietyId.value}
                              label={this.state.fields.freightDelivery.inload.varietyId.validators.length ? "Variety" : "Variety (Optional)"}
                              dependsOnCommodity
                              onChange={this.handleInloadVarietyChange}
                              style={{ float: 'left' }}
                              disabled={this.disableForm}
                              errorText={this.state.fields.freightDelivery.inload.varietyId.errors[0]}
                              onBlur={this.handleBlur}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <CommonAutoSelect
                              items={this.state.freightDeliveryNgrs}
                              dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                              id="freightDelivery.inload.ngrId"
                              setRef={this.fieldRef["freightDelivery.inload.ngrId"]}
                              label='NGR'
                              value={this.state.fields.freightDelivery.inload.ngrId.value}
                              errorText={this.state.fields.freightDelivery.inload.ngrId.errors[0] || ((this.state.freightDeliverySiteIsSystemStorage && !this.state.fields.freightDelivery.inload.ngrId.value) ? NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE : '')}
                              style={{ float: 'left' }}
                              onChange={this.handleSelectFieldChange}
                              dontAutoselectSingleItem
                              disabled={this.disableForm}
                            />
                          </div>
                        }
                        {
                          this.isDeliveredMovement() &&
                          <div className='col-sm-12  form-wrap-70'>
                            <TruckSearchOption
                              id='deliverySearchOption'
                              label='Search Trucks Of'
                              handleSearchOptionChange={this.handleSearchOptionChange}
                              value={this.state.fields.freightDelivery.searchOption}
                              disabled={!this.state.fields.providerId.value}
                              tooltipPosition='bottom'
                              freightProviderLabel='Freight Provider'
                              allRegoLabel={`All ${regoLabel}s`}
                              truckOwnerLabel='Truck Owner'
                            />
                          </div>
                        }
                        {
                          <div className={'col-sm-12 form-wrap-70'} style={{ marginTop: this.state.fields.freightDelivery.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS ? '30px' : '18px', marginBottom: this.state.fields.freightDelivery.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS ? '-10px' : '0px'}}>
                            { this.state.fields.freightDelivery.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS ?
                              <CompanyAutocomplete
                                id='inloadTruckOwner'
                                selected={this.state.fields.freightDelivery?.inload.truckOwner.value}
                                onChange={this.onCompanyChange}
                                minLength={3}
                                getRegisteredCompanies={false}
                                variant="standard"
                                disabled={disableBasedOnDeliverySearchOptions}
                                label='Truck Owner'
                              /> :
                              <CommonTextField
                                id="inloadTruckOwner"
                                label='Truck Owner'
                                value={this.state.fields.freightDelivery?.inload.truckOwner.value?.name}
                                disabled
                              />
                            }
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className='col-md-12 form-wrap'>
                            {
                              this.state.fields.freightDelivery.searchOption === SEARCH_BY_ALL_REGOS ?
                              <CommonSelectInput
                                search
                                allowEmptyOptions
                                allowText={false}
                                endpoint="trucks/search/"
                                queryParams={{is_active: true}}
                                options={(this.state.isCopyFromOutload || this.state.truckOptions) ? this.state.selectedTrucks : []}
                                optionMap={{ id: 'id', name: 'rego', companyId: 'companyId', companyName: 'companyName', totalWeights: 'totalWeights', categoryId: 'categoryId', code: 'code', steerPoint5: 'steerPoint5', steer1Point1: 'steer1Point1', transactionParticipation: 'transactionParticipation' }}
                                inputText={get(find(this.state.selectedTrucks, { id: this.state.fields.freightDelivery.inload.truckId.value }), 'rego')}
                                getSelectedOption={this.setSelectedProviderTruck}
                                id="freightDelivery.inload.truckId"
                                setRef={this.fieldRef["freightDelivery.inload.truckId"]}
                                label={`Truck ${getCountryLabel('rego')}`}
                                value={this.state.fields.freightDelivery.inload.truckId.value}
                                errorText={this.isRegoSearchable() ? this.state.fields.freightDelivery.inload.truckId.errors[0] : this.state.fields.freightDelivery.inload.truckId.errors[0] ? `Select ${getCountryLabel('rego').toLowerCase()} from list` : ''}
                                onChange={this.handleSelectFieldChange}
                                create={this.props.createTruck}
                                actionCreator={addTruck}
                                validate={this.props.validateRego}
                                items={this.state.selectedTrucks}
                                onInput={(e) => {
                                  const newState = {...this.state};
                                  newState.isCopyFromOutload = false;
                                  newState.truckOptions = false;
                                  e.target.value = e.target.value.toString().slice(0, 10).replace(/[^0-9a-z]/gi, '');
                                  if(e.target.value == '') {
                                    newState.fields.freightDelivery.inload.truckOwnerId.value = '';
                                    newState.fields.freightDelivery.inload.truckOwner.value = null;
                                  }
                                  this.setState(newState);
                                }}
                              /> :
                              <CommonAutoSelect
                                items={this.state.deliveryOwnerTrucks.length != 0 ? orderBy(this.state.deliveryOwnerTrucks, 'id') : orderBy(this.state.providerTrucks, 'id')}
                                id='freightDelivery.inload.truckId'
                                setRef={this.fieldRef['freightDelivery.inload.truckId']}
                                label={`Truck ${getCountryLabel('rego')}`}
                                value={this.state.fields.freightDelivery.inload.truckId.value}
                                errorText={this.state.fields.freightDelivery.inload.truckId.errors[0]}
                                onChange={this.handleSelectFieldChange}
                                dataSourceConfig={{ text: 'rego', value: 'id' }}
                                dontAutoselectSingleItem={true}
                                top={true}
                                disabled={this.state.fields.freightDelivery.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS && !this.state.fields.freightDelivery.inload.truckOwner.value}
                                filterOptions={(options, params) => {
                                  const filtered = autocompleteFilters(options, params);
                                  if(!get(this.state.fields.freightDelivery.inload.truckOwner.value , 'transactionParticipation')) {
                                    if (params.inputValue !== ''  && params.inputValue.length >= 4 && params.inputValue.length <= 10 && params.inputValue.toLowerCase() !== 'fleet') {
                                      filtered.push({
                                        inputValue: params.inputValue,
                                        name: `Add "${params.inputValue}" as new ${getCountryLabel('rego')}`,
                                      });
                                    }
                                    return filtered;
                                  }
                                  return filtered;
                                }
                              }
                              />
                            }
                          </div>
                        }
                        {
                          !this.manualComplete && isContainerMovement &&
                          <div className="col-md-12 form-wrap">
                            <CommonTextField
                              id="freightDelivery.inload.containerTareWeight"
                              setRef={this.fieldRef["freightDelivery.inload.containerTareWeight"]}
                              label="Container Tare"
                              value={this.state.fields.freightDelivery.inload.containerTareWeight.value}
                              onKeyDown={(event) => positiveDecimalFilter(event, 2, 9999.99)}
                              helperText={this.state.fields.freightDelivery.inload.containerTareWeight.errors[0]}
                              disabled={this.disableForm}
                              onChange={this.handleDeliveryContainerTareWeightChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"
                                  style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <CommonTextField
                              id="freightDelivery.inload.tareWeight"
                              setRef={this.fieldRef["freightDelivery.inload.tareWeight"]}
                              label="Truck Tare Weight"
                              value={this.state.fields.freightDelivery.inload.tareWeight.value}
                              onKeyDown={(event) => positiveDecimalFilter(event, 2, 999999999.99)}
                              helperText={this.state.fields.freightDelivery.inload.tareWeight.errors[0]}
                              disabled={this.disableForm}
                              onChange={this.handleDeliveryTareWeightChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"
                                  style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <CommonTextField
                              id="freightDelivery.inload.grossWeight"
                              setRef={this.fieldRef["freightDelivery.inload.grossWeight"]}
                              label="Truck Gross Weight"
                              value={this.state.fields.freightDelivery.inload.grossWeight.value}
                              onKeyDown={(event) => positiveDecimalFilter(event, 2, 999999999.99)}
                              disabled={this.disableForm}
                              helperText={get(
                                this.state.fields.freightDelivery.inload, 'grossWeight.errors[0]',
                                this.state.isDeliveryNetWeightGreaterThanRemainingSpace ? INLOAD_EXCESS_NET_WEIGHT_WARNING_MESSAGE : ''
                              )}
                              onChange={this.handleDeliveryGrossWeightChange}
                              InputProps={{
                                endAdornment: <InputAdornment position="end"
                                  style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-12 form-wrap">
                            <CommonTextField
                              id="freightDelivery.netWeight"
                              setRef={this.fieldRef["freightDelivery.netWeight"]}
                              label="Net Weight"
                              value={this.state.fields.freightDelivery.netWeight.value}
                              onKeyDown={(event) => positiveDecimalFilter(event, 2, 999999999.99)}
                              disabled={this.disableForm}
                              helperText={get(
                                this.state.fields.freightDelivery, 'netWeight.errors[0]',
                                this.state.isDeliveryNetWeightGreaterThanRemainingSpace ? INLOAD_EXCESS_NET_WEIGHT_WARNING_MESSAGE : ''
                              )}
                              onChange={() => { }}
                              onBlur={e => this.handleNetWeightChange(e, 'freightDelivery', 'inload')}
                              InputProps={{
                                endAdornment: <InputAdornment position="end" style={{ color: 'rgb(162,162,162)' }}>{priceUnit}</InputAdornment>
                              }}
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-12 form-wrap">
                            <CommonAutoSelect
                              id="deliveryCode"
                              label='Code'
                              placeholder="Select Code..."
                              items={map(TRUCK_CODES, code => ({ id: code, name: code }))}
                              value={this.state.deliveryCode.value || ''}
                              onChange={this.handleTruckCodeChange}
                              dataSourceConfig={{ id: 'id', text: 'name' }}
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-4 form-wrap">
                            <CommonAutoSelect
                              id="deliveryMassLimitPermit"
                              label='Mass Limit Permit'
                              placeholder="Select Mass Limit Permit..."
                              items={map(this.state.permits, permit => ({ id: permit, name: permit }))}
                              value={this.state.deliveryMassLimitPermit.value || ''}
                              onChange={this.handleMassLimitChange}
                              dataSourceConfig={{ id: 'id', text: 'name' }}
                              errorText={this.state.deliveryMassLimitPermit.errors[0]}
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-3 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='steer_point_5'
                                  color='primary'
                                  checked={this.state.fields.freightDelivery.inload.steer_point_5.value}
                                  onChange={event => this.handleSteerChange(event, 'freightDelivery.inload')}
                                  disabled={!this.shouldEnableDeliverySteerPoint5()}
                                />
                              }
                              label='0.5 T Steer Axle Allowance'
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-3 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='steer_1_point_1'
                                  color='primary'
                                  checked={this.state.fields.freightDelivery.inload.steer_1_point_1.value}
                                  onChange={event => this.handleSteerChange(event, 'freightDelivery.inload')}
                                  disabled={!this.shouldEnableDeliverySteer1Point1()}
                                />
                              }
                              label='1.1 T Allowance'
                            />
                          </div>
                        }
                        {
                          isTruckMassLimitsVisible &&
                          <div className="col-sm-2 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='deliveryGHMS'
                                  color='primary'
                                  checked={this.state.deliveryGHMS.value}
                                  onChange={this.handleDeliveryGHMSChange}
                                />
                              }
                              label='GHMS'
                            />
                          </div>
                        }
                        { this.state.deliveryMassLimitPermit.value === 'Notice' &&
                          <div className="col-sm-4" style={{marginBottom: '30px'}}>
                            <CommonAutoSelect
                              id="fields.freightDelivery.inload.noticeNumber"
                              label='Notice Number'
                              placeholder="Select Notice Number..."
                              items={NOTICE_NUMBERS}
                              value={this.state.fields.freightDelivery.inload.noticeNumber.value}
                              onChange={this.handleFreightDeliveryTruckDetailsSelectValueChange}
                              dataSourceConfig={{id: 'id', text: 'name'}}
                              errorText={this.state.freightDeliveryTruckErrors.noticeNumber}
                            />
                          </div>
                        }
                        {
                          showDeliveryAdditionalMassLimitCodeFields && isTruckMassLimitsVisible &&
                          <AdditionalMassLimitFields
                            permitChecked={Boolean(this.state.freightDeliveryPermitChecked)}
                            handlePermitClick={this.handleFreightDeliveryPermitClick}
                            accreditationNumberChecked={Boolean(this.state.freightDeliveryAccreditationNumberChecked)}
                            handleAccreditationNumberClick={this.handleFreightDeliveryAccreditationNumberClick}
                            loadSharing={Boolean(this.state.freightDeliveryLoadSharing)}
                            handleLoadSharingClick={this.handleFreightDeliveryLoadSharingClick}
                            disableLoadSharing={disableFreightDeliveryLoadSharing}
                            isPBSMassLimitPermit={(this.state.deliveryMassLimitPermit.value || '').includes('PBS - ')}
                            notice={this.state.deliveryMassLimitPermit.value === 'Notice'}
                            handleTruckDetailsChange={this.handleFreightDeliveryTruckDetailsChange}
                            restrictedChecked={this.state.freightDeliveryRestrictedChecked}
                            handleRestrictedClick={this.handleFreightDeliveryRestrictedClick}
                            noticeNumberFieldId="fields.freightDelivery.inload.noticeNumber"
                            noticeNumber={this.state.fields.freightDelivery.inload.noticeNumber.value}
                            handleTruckDetailsSelectValueChange={this.handleFreightDeliveryTruckDetailsSelectValueChange}
                            accreditationNumberFieldId="fields.freightDelivery.inload.accreditationNumber"
                            accreditationNumber={this.state.fields.freightDelivery.inload.accreditationNumber.value}
                            restrictedFieldId="fields.freightDelivery.inload.restricted"
                            restricted={this.state.fields.freightDelivery.inload.restricted.value}
                            declaredMassLimitFieldId="fields.freightDelivery.inload.declaredMassLimit"
                            declaredMassLimit={this.state.fields.freightDelivery.inload.declaredMassLimit.value}
                            permitNumberFieldId="fields.freightDelivery.inload.permitNumber"
                            permitNumber={this.state.fields.freightDelivery.inload.permitNumber.value}
                            categoryId={this.state.fields.freightDelivery.inload.categoryId.value}
                            truckErrors={this.state.freightDeliveryTruckErrors}
                            truckUnit={priceUnit}
                          />
                        }


                        {
                          !this.manualComplete && this.isDeliveredMovement() && this.quantityLabel() &&
                          this.hasQuantityBasedCommodity() &&
                          <div className="col-md-12 form-wrap">
                            <NumberField
                              id="freightDelivery.inload.quantity"
                              label={this.quantityLabel()}
                              setRef={this.fieldRef["freightDelivery.inload.quantity"]}
                              value={this.state.fields.quantity.value}
                              maxValue={99999.99}
                              helperText={this.state.fields.freightDelivery.inload.quantity.errors[0]}
                              onChange={this.handleQuantityFieldChange}
                              onBlur={this.handleQuantityFieldChange}
                              disabled={(this.isEditForm && includes(['delivered', 'completed'], get(this.editMovement, 'status'))) || this.disableForm}
                            />
                          </div>
                        }


                        {
                          !this.manualComplete &&
                          <div className="col-md-8">
                            {
                              this.shouldShowMaximumAllowedTonnageForDeliveredStatus('inload') && !this.isMeterCubeCommodityCommodity() &&
                              <Typography variant="subheading" gutterBottom>
                                Maximum Allowed {this.tonnageLabel}: {this.unplannedContractTonnage()} {priceUnit}
                              </Typography>
                            }
                            {
                              this.shouldShowMaximumAllowedTonnageForDeliveredStatus('inload') && this.isMeterCubeCommodityCommodity() &&
                                <Typography variant="subheading" gutterBottom>
                                  {`Maximum Allowed ${commodity?.quantityLabel}: ${this.unplannedContractTonnage()} ${commodity?.unit}`}
                              </Typography>
                            }
                          </div>
                        }
                        {this.isDeliveredMovement() && !this.isEditForm && !this.manualComplete &&
                          <div className="col-md-12 padding-reset label-small">
                            <SpecParametersValue
                              id='inload.specs'
                              commoditySpecs={this.state.commoditySpecs}
                              disabled={this.disableForm}
                              fieldsSpecs={this.state.fields.freightDelivery.inload.specs}
                              onChange={this.handleDeliverySpecsChange}
                              errorTexts={mapValues(this.state.fields.freightDelivery.inload.specs, spec => get(spec, 'errors[0]', ''))}
                              warningTexts={mapValues(this.state.fields.freightDelivery.inload.specs, spec => get(spec, 'warnings[0]', ''))}
                              fieldWrapperClassName="col-xs-4 form-wrap"
                              clearIndex={[3]}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-5 form-wrap">
                            <CommonTextField
                              id="freightDelivery.inload.docketNumber"
                              setRef={this.fieldRef["freightDelivery.inload.docketNumber"]}
                              label={`${getCountryLabel('docket')} No${this.state.freightDeliverySiteIsSystemStorage || this.state.fields.freightDelivery.inload.docketNumber.validators ? '' : ' (Optional)'}`}
                              value={this.state.fields.freightDelivery.inload.docketNumber.value}
                              helperText={this.state.fields.freightDelivery.inload.docketNumber.errors[0]}
                              disabled={this.disableForm}
                              onChange={this.handleFieldChange}
                              onBlur={this.handleBlur}
                            />
                          </div>
                        }
                        {
                          !this.manualComplete &&
                          <div className="col-md-7 form-wrap padding-reset">
                            <FileUpload
                              id="freightDelivery.docketImage"
                              floatingLabelText={`${getCountryLabel('docket')} Image`}
                              fullWidth={true}
                              fileCount={this.state.fileCountDelivery}
                              errorText={this.state.fields.freightDelivery.docketImage.errors[0]}
                              textFieldstyle={{ float: 'left', color: 'black' }}
                              onChange={this.onDeliveryDocketImageUpload}
                              onRemove={this.onDeliveryDocketImageRemove}
                              disabled={this.disableForm}
                            />
                          </div>
                        }
                      </div>
                    }
                    {
                      !this.manualComplete &&
                      <div className="col-md-12 form-wrap">
                        <CommonTextField
                          id="freightDelivery.orderNumber"
                          setRef={this.fieldRef["freightDelivery.orderNumber"]}
                          label="Order No (Optional)"
                          value={this.state.fields.freightDelivery.orderNumber.value}
                          maxLength="10"
                          helperText={this.state.fields.freightDelivery.orderNumber.errors[0]}
                          onChange={this.handleFieldChange}
                          onBlur={this.handleBlur}
                          disabled={get(this.state.selectedBaseEntity, 'deliveryOrderNumber') || this.disableForm}
                        />
                      </div>
                    }
                    {
                      !this.manualComplete &&
                      <div className="col-md-12 form-wrap">
                        <CommonTextField
                          id="freightDelivery.bookingNumber"
                          setRef={this.fieldRef["freightDelivery.bookingNumber"]}
                          label="Booking No (Optional)"
                          value={this.state.fields.freightDelivery.bookingNumber.value}
                          maxLength="10"
                          helperText={this.state.fields.freightDelivery.bookingNumber.errors[0]}
                          onChange={this.handleFieldChange}
                          onBlur={this.handleBlur}
                          disabled={this.disableForm}
                        />
                      </div>
                    }

                    {(this.isPlannedMovement() || this.isEditForm) &&
                      <div className="col-md-12 form-wrap top-15">
                        <CommonTextField
                          id="freightDelivery.instructions"
                          setRef={this.fieldRef["freightDelivery.instructions"]}
                          label="Delivery Instructions (Optional)"
                          value={this.state.fields.freightDelivery.instructions.value}
                          helperText={this.state.fields.freightDelivery.instructions.errors[0]}
                          onChange={this.handleFieldChange}
                          multiline={true}
                          rows={2}
                          rowsMax={2}
                          onBlur={this.handleBlur}
                          disabled={this.disableForm || this.canEditInstructions()}
                        />
                      </div>
                    }
                    {this.isDeliveredMovement() &&
                      <div className="col-md-12 form-wrap top-15">
                        <CommonTextField
                          id="freightDelivery.inload.comment"
                          label="Delivery Comment (Optional)"
                          value={this.state.fields.freightDelivery.inload.comment.value}
                          disabled={this.disableForm}
                          onChange={this.handleFieldChange}
                          multiline={true}
                          rows={2}
                          rowsMax={2}
                          onBlur={this.handleBlur}
                        />
                      </div>
                    }
                  </div>
                </div>
              </div>
          }
            </div>
          }
          {
            !this.manualComplete && this.showFreightContractConditions() &&
            <div className="cardForm">
              <h4 className="cardForm-title">Freight Contract Details</h4>
              <div className="cardForm-content">
                <div className="col-md-5 form-wrap padding-reset">
                  {
                    this.showInvoicingField() &&
                    <div className="col-md-12 form-wrap">
                      <CommonSelect
                        id="invoicing"
                        setRef={this.fieldRef["invoicing"]}
                        onChange={this.handleInvoicingFieldChange}
                        floatingLabelText="Invoicing (Optional)"
                        disabled={this.disableForm}
                        errorText={this.state.fields.invoicing.errors[0]}
                        items={this.state.commissionRate !== "0.00" && this.editMovement.invoicing === 'Freight Provider to Invoice Broker' ?
                          INVOICINGS :
                          INVOICINGS_NEW_MOVEMENTS
                        }
                        style={{ float: 'left' }}
                        value={get(this.editMovement, 'invoicing') || this.state.fields.invoicing.value}
                      />
                    </div>
                  }
                  {
                    this.showPaymentTermField() &&
                    <div className="col-md-12 form-wrap">
                      <CommonAutoSelect
                        id="paymentTermId"
                        setRef={this.fieldRef["paymentTermId"]}
                        onChange={this.handleSelectFieldChange}
                        label="Payment Term (Optional)"
                        dataSourceConfig={{ text: 'name', value: 'id' }}
                        value={this.state.fields.paymentTermId.value}
                        errorText={this.state.fields.paymentTermId.errors[0]}
                        items={this.props.paymentTerms}
                        disabled={this.disableForm}
                      />
                    </div>
                  }
                  {showFreightPickupOrDeliverySection && <React.Fragment>
                    <div className="col-md-6 top-15">
                      Est. Distance: {this.state.totalDistanceLabel}
                    </div>
                    <div className="col-md-5 top-15">
                      Est. Travel Time: {this.state.estimatedTime}
                    </div>
                  </React.Fragment>}
                </div>

                {(this.showFreightContractDetailsSection() || this.isEditForm || this.state.fields.status.value === 'delivered') && <div className="col-md-5 col-md-offset-1 padding-reset">
                  {this.showFreightRateInField() && this.hasCommissionRate() &&
                    <div className="col-md-12 form-wrap" style={{ display: 'flex' }}>
                      <CurrencyField
                        id="rateFreightIn"
                        setRef={this.fieldRef["rateFreightIn"]}
                        label="Freight Rate In (Optional)"
                        value={this.state.fields.rateFreightIn.value}
                        helperText={this.state.fields.rateFreightIn.errors[0]}
                        inputRef={this.fieldRef['price']}
                        fullWidth
                        onChange={this.handleFreightRateChange}
                        onBlur={this.handleRateBlur}
                        onKeyDown={(event) => positiveDecimalFilter(event, 2, 10000)}
                        disabled={this.state.fields.invoicing.value !== 'Freight Provider to Invoice Broker' || this.disableForm}
                        variant="standard"
                        onCurrencyChange={this.handleCurrencyChange}
                        selectedCurrency={this.state.currency}
                        disabledCurrency={!foreignCurrencyAllowed || isSelectedBaseEntityOrder}
                      />
                    </div>
                  }
                  {
                    this.showFreightContractDetailsSection() &&
                    <div className="col-md-12 form-wrap" style={{ display: 'flex' }}>
                      <CurrencyField
                        id="rateFreightOut"
                        setRef={this.fieldRef["rateFreightOut"]}
                        label={this.hasCommissionRate() ? this.getFreightRateOutFieldLabel() : 'Freight Rate (Optional)'}
                        value={this.state.fields.rateFreightOut.value}
                        helperText={this.state.fields.rateFreightOut.errors[0]}
                        inputRef={this.fieldRef['price']}
                        fullWidth
                        onChange={this.handleFreightRateChange}
                        onBlur={this.handleRateBlur}
                        onKeyDown={(event) => positiveDecimalFilter(event, 2, 10000)}
                        disabled={this.disableForm}
                        variant="standard"
                        onCurrencyChange={this.handleCurrencyChange}
                        selectedCurrency={this.state.currency}
                        disabledCurrency={!foreignCurrencyAllowed || isSelectedBaseEntityOrder}
                      />
                    </div>
                  }

                  {
                    this.showOversRateField() &&
                    <div className="col-md-12 form-wrap" style={{ display: 'flex' }}>
                      <CurrencyField
                        id="rateOvers"
                        setRef={this.fieldRef["rateOvers"]}
                        label="Overs Rate (Optional)"
                        value={this.state.fields.rateOvers.value}
                        helperText={this.state.fields.rateOvers.errors[0]}
                        inputRef={this.fieldRef['price']}
                        fullWidth
                        onChange={this.handleFieldChange}
                        onBlur={this.handleBlur}
                        onKeyDown={(event) => positiveDecimalFilter(event, 2, 10000)}
                        disabled={this.disableForm}
                        variant="standard"
                        onCurrencyChange={this.handleCurrencyChange}
                        selectedCurrency={this.state.currency}
                        disabledCurrency={!foreignCurrencyAllowed || isRateInOrRateOutFieldVisible || isSelectedBaseEntityOrder}
                      />
                    </div>
                  }
                  {
                    this.showCommissionRateField() && this.hasCommissionRate() &&
                    <div className="col-md-12 top-15">
                      Commission Rate : {get(this.editMovement, 'invoicing') == 'Freight Provider to Invoice Broker' && `${this.state.currency} ${this.state.commissionRate}`}
                    </div>
                  }
                </div>
                }
              </div>
            </div>
          }
          {this.showConditions() && !this.manualComplete &&
            <div className="cardForm">
              <h4 className="cardForm-title">Freight Contract Conditions</h4>
              <div className="cardForm-content col-md-12">
                <div className="col-md-5 form-wrap padding-reset">
                  <CommonAutoSelect
                    items={this.props.generalConditionsAll}
                    style={{ float: 'left' }}
                    id="generalConditionsSelector"
                    setRef={this.fieldRef["generalConditionsSelector"]}
                    value={this.state.generalConditionsSelector}
                    label="General"
                    disabled={this.disableForm}
                    onChange={this.handleConditionSelector}
                    dontAutoselectSingleItem
                  />
                  {this.addGeneralConditionButton()}
                  <CommonTextField
                    id="generalConditionViewer"
                    value={this.state.selectedGeneralConditionText}
                    multiline={true}
                    rows={2}
                    disabled={true}
                    rowsMax={2}
                  />
                </div>
                <div className="cardForm-content col-md-1 arrow-button">
                  <IconButton
                    disabled={!isNumber(this.state.generalConditionsSelector)}
                    onClick={this.appendGeneralConditions}
                    size="large">
                    <ArrowForward color={PRIMARY_COLOR_GREEN} />
                  </IconButton>
                </div>
                <div className="cardForm-content col-md-5">
                  <div className="field-label">Conditions (Optional)</div>
                  <CommonTextField
                    id="generalConditions"
                    className="text-area"
                    label=""
                    value={this.state.fields.generalConditions.value}
                    multiline={true}
                    onChange={this.handleFieldChange}
                    rows={6}
                    rowsMax={6}
                    onBlur={this.handleBlur}
                  />
                </div>
              </div>
              <div className="cardForm-content col-md-12">
                <div className="col-md-5 form-wrap padding-reset">
                  <CommonAutoSelect
                    items={this.props.specialConditionsAll}
                    id="specialConditionsSelector"
                    setRef={this.fieldRef["specialConditionsSelector"]}
                    value={this.state.specialConditionsSelector}
                    label="Special"
                    onChange={this.handleConditionSelector}
                    dontAutoselectSingleItem
                    disabled={this.disableForm}
                  />
                  {this.addSpecialConditionButton()}
                  <CommonTextField
                    id="specialConditionViewer"
                    value={this.state.selectedSpecialConditionText}
                    multiline={true}
                    rows={2}
                    disabled={true}
                    rowsMax={2}
                    onBlur={this.handleBlur}
                  />
                </div>
                <div className="cardForm-content col-md-1 arrow-button">
                  <IconButton
                    disabled={!isNumber(this.state.specialConditionsSelector)}
                    onClick={this.appendSpecialConditions}
                    size="large">
                    <ArrowForward color={PRIMARY_COLOR_GREEN} />
                  </IconButton>
                </div>
                <div className="cardForm-content col-md-5">
                  <div className="field-label">Conditions (Optional)</div>
                  <CommonTextField
                    id="specialConditions"
                    className="text-area"
                    label=""
                    value={this.state.fields.specialConditions.value}
                    multiline={true}
                    onChange={this.handleFieldChange}
                    rows={6}
                    rowsMax={6}
                    onBlur={this.handleBlur}
                  />
                </div>
              </div>
            </div>
          }
          <div className="cardForm">
            <h4 className="cardForm-title">Internal Notes (Optional)</h4>
            <div className="cardForm-content col-md-5">
              <Notes
                title=''
                handler={this.noteHandler}
                note={this.state.fields.note}
                disabled={false}
              />
            </div>
          </div>
          <div className="col-md-12" style={{ textAlign: 'right' }}>{buttons}</div>
          {(showUpdateToTonnagePopup || (this.state.showAmendTonnagePopup && isParentOrderIndependent)) &&
          <Dialog open onClose={() => this.setState({showAmendTonnagePopup: false})}>
            <DialogTitleWithCloseIcon onClose={() => this.setState({showAmendTonnagePopup: false})}>Warning</DialogTitleWithCloseIcon>
            <DialogContent>
              This {entityName} can take up to only {min([get(this.state.selectedBaseEntity, 'maxAllowedTonnageForAmend'), get(this.state.selectedBaseEntity, 'totalTonnageWithTolerance')])} {this.priceUnit()} (Inc tolerance).
              Saving this movement will take the {entityName}&apos;s {this.countryTonnageLabel.toLowerCase()} to {updateToTonnage} {this.priceUnit()}. Do you want to automatically 
              update the {entityName} {this.countryTonnageLabel.toLowerCase()} to {updateToTonnage} {this.priceUnit()} on saving this movement?
            </DialogContent>
            <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({showAmendTonnagePopup: false})}
              variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={() => this.setState({amendParentToTonnage: updateToTonnage, showAmendTonnagePopup: false}, () => this.setState({ preview: true }))} color='primary' variant='outlined'>
              Yes, Proceed
            </Button>
            </DialogActions>
          </Dialog>
          }
          {this.state.showAmendTonnagePopup && !isParentOrderIndependent && !showUpdateToTonnagePopup &&
          <Dialog open onClose={() => this.setState({showAmendTonnagePopup: false})}>
            <DialogTitleWithCloseIcon onClose={() => this.setState({showAmendTonnagePopup: false})}>Warning</DialogTitleWithCloseIcon>
            <DialogContent>
              This {entityName} can take up to only {min([get(this.state.selectedBaseEntity, 'maxAllowedTonnageForAmend'), get(this.state.selectedBaseEntity, 'totalTonnageWithTolerance')])} {this.priceUnit()} (Inc tolerance)
              and can be amended upto {get(this.state.selectedBaseEntity, 'maxAllowedTonnageForAmend') || get(this.state.selectedBaseEntity, 'totalTonnageWithTolerance')} {this.priceUnit()} due to {get(this.state.selectedBaseEntity, 'reason')} {parentEntityUrl}. {amendMessage}
            </DialogContent>
            <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({showAmendTonnagePopup: false})}
              variant='outlined'>
              Cancel
            </Button>
            </DialogActions>
          </Dialog>
          }
        </form>
        <Dialog
          open={Boolean(this.state.preview)}
          onClose={this.handleClosePreview}
          scroll="paper"
          fullScreen
        >
          <DialogTitle>Freight Movement Preview</DialogTitle>
          <DialogContent>
            <MovementPreview
              previewFields={this.getPreviewFields()}
              ref={el => (this.componentRef = el)}
            />
          </DialogContent>
          <DialogActions style={{paddingBottom: "40px"}}>
            <CommonButton
              label="Back"
              key="closeButton"
              default
              onClick={this.handleClosePreview}
              variant="flat"
            />
            <CommonButton
              label="Submit"
              key="submitButton"
              primary={true}
              onClick={this.handleSubmit}
              variant="flat"
            />
          </DialogActions>
        </Dialog>

        {this.state.consignorSideDrawer && <SideDrawer
          isOpen={this.state.consignorSideDrawer}
          title="Add Site"
          onClose={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
          app="condition"
        >
          <CreateCompanySite closeDrawer={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
                             canAccessAny={true} companyId={getCountrySystemCompanyId()}
            handleFarmSubmit={(data) => this.handleFarmSubmit("consignorSideDrawer", data)}
            inputText={this.state.inputTextFarm} />
        </SideDrawer>}

        {this.state.consigneeSideDrawer && <SideDrawer
          isOpen={this.state.consigneeSideDrawer}
          title="Add Site"
          onClose={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
          app="condition"
        >
          <CreateCompanySite closeDrawer={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
                             canAccessAny={true} companyId={getCountrySystemCompanyId()}
            handleFarmSubmit={(data) => this.handleFarmSubmit("consigneeSideDrawer", data)}
            inputText={this.state.inputTextFarm} />
        </SideDrawer>}

        {this.freightProviderSideDrawer()}
        {this.customerSideDrawer()}
        {this.props.isConditionSideDrawerOpened && <SideDrawer
          isOpen={this.state.generalConditionSideDrawerIsOpen}
          title="Add General Condition"
          onClose={this.closeGeneralConditionSideDrawer}
          app="condition"
        >
          <CreateGeneralCondition
            selectedConditionType="freight"
            closeDrawer={this.closeGeneralConditionSideDrawer}
            onSuccess={this.setDefaultGeneralCondition}
          />
        </SideDrawer>}
        {this.props.isConditionSideDrawerOpened && <SideDrawer
          isOpen={this.state.specialConditionSideDrawerIsOpen}
          title="Add Special Condition"
          onClose={this.closeSpecialConditionSideDrawer}
          app="condition"
        >
          <CreateSpecialCondition
            selectedConditionType="freight"
            closeDrawer={this.closeSpecialConditionSideDrawer}
            onSuccess={this.setDefaultSpecialCondition}
          />
        </SideDrawer>}
        {this.props.isTruckCreateFormDisplayed && <SideDrawer
          isOpen={this.state.isTruckSideDrawerOpen}
          title={"Add Trucks"}
          subHeading={addTrucksHeader}
          size="big"
          onClose={this.closeTruckSideDrawer}
          app="truck"
        >
          <CreateTruck
            closeDrawer={this.closeTruckSideDrawer}
            company={this.getSelectedProvider()}
            canAccessAny={true}
            brokerAddingTrucksForGrower={this.isBrokerAddingTrucksForGrower()}
            isCompanyRegistered={get(this.getSelectedProvider(), 'isRegistered')}
            syncTrucks={this.syncTrucks}
            companyId={get(this.getSelectedProvider(), 'id') || this.state.fields.providerId.value} />
        </SideDrawer>}

        {this.state.ngrEntity && this.props.isCreateNgrFormDisplayed &&
          <SideDrawer
            isOpen={this.state.ngrSideDrawerIsOpen}
            title="Add NGR"
            size="big"
            onClose={this.closeNgrSideDrawer}
          >
            <CreateCompanyNgr companyId={this.state.ngrEntity?.company?.id} company={get(this.state.ngrEntity, 'company')} canAccessAny={true} closeDrawer={this.closeNgrSideDrawer} onSuccess={this.getNgrs} bankAccountDetailsMandatory={false} />
          </SideDrawer>
        }
        {this.state.showEmailDialog &&
          <CustomEmailDialog
            parties={this.state.emailPopupParties}
            selectedParties={this.getSelectedParties()}
            title="Email PDF copies to"
            partyEmails={this.getPartyEmails()}
            partyContacts={this.getPartyContacts()}
            subject={this.getEmailSubject()}
            noBody={true}
            footer={this.getFooterNote()}
            open={this.state.showEmailDialog}
            onClose={this.closeEmailDialog}
            isScheduled={!!this.sendButtonText}
            okText={this.sendButtonText}
            disableAcceptanceRequired={this.isAcceptanceDisabled()}
          />
        }
        {
          this.state.openCompanySideDrawer &&
          <SideDrawer isOpen={this.state.openCompanySideDrawer} title="Add Company" onClose={this.closeCompanySideDraw}>
            <CreateCompany closeDrawer={this.closeCompanySideDraw} skipCompanyRedirection addNewCompany={this.addNewCompany} />
          </SideDrawer>
        }
      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    handleAddEmployeeButtonClick: (type) => {
      if (type === 'farm') {
        dispatch(clickAddFarmEmployeeButton());
      } else {
        dispatch(clickAddEmployeeButton());
      }
    },
    handleAddTruckButtonClick: () => dispatch(clickAddTruckButton()),
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    handleAddNgrButtonClick: () => dispatch(clickAddNgrButton()),
    validateRego: (key, value, callback) => dispatch(validateRego(key, value, callback)),
    createTruck: (companyId, data, addTruck, callback, id) => dispatch(createTruck(companyId, data, addTruck, callback, id)),
    emptyCreatedTruck: (id) => dispatch(emptyCreatedTruck(id)),
    dispatch
  };
};
const mapStateToProps = (state) => {
  var types = state.companies.freights.types;
  var paymentTerms = state.companies.freights.paymentTerms;
  var customerContacts = state.companies.contracts.customerContacts;
  var statuses = [];

  if (!isEmpty(state.companies.contracts.buyerContacts)) {
    customerContacts = sortBy(state.companies.contracts.buyerContacts, contact => contact.id === state.main.user.user.id ? 0 : 1);
  }
  if (!isEmpty(state.companies.freights.statuses)) {
    statuses = filter(state.companies.freights.statuses, (status) => { return includes(['planned', 'delivered'], status.id); });
  }

  const userTrucksCount = state.companies.companies.company.trucks.userTrucks ? state.companies.companies.company.trucks.userTrucks.length : 0;
  return {
    companyParties: state.companies.companies.companyParties,
    userTrucksCount,
    customerContacts,
    types,
    paymentTerms,
    serverErrors: state.companies.freights.serverErrors,
    currentUser: state.main.user.user,
    generalConditionsAll: state.companies.conditions.generalConditions,
    specialConditionsAll: state.companies.conditions.specialConditions,
    grades: state.master.grades.items || [],
    providers: state.companies.companies.companyParties || [],
    statuses: statuses,
    providerEmployees: state.companySettings.employees.items,
    providerTrucks: state.companies.companies.company.trucks.items,
    userCompanyId: state.main.user.user.companyId,
    commodities: state.master.commodities.items || [],
    varieties: state.master.varieties.items || [],
    userToken: state.main.user.token,
    isTruckCreateFormDisplayed: state.companies.companies.company.trucks.isCreateFormDisplayed,
    isCreateNgrFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    isCreateFormDisplayed: state.companies.ngrs.isCreateFormDisplayed,
    isConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    isEmployeeSideDrawerOpened: state.companySettings.employees.isCreateFormDisplayed || state.companies.farmEmployees.isCreateFormDisplayed,
    isPartiesFetched: true && !isEmpty(types) && !isEmpty(state.master.commodities.items),
    isBHCLocationsFetched: true,
    isLoading: state.main.isLoading,
    isRegoAvailable: state.main.isRegoAvailable,
    createdTruck: state.companies.companies.company.trucks.createdTruck,
    pickupCreatedTruck: state.companies.companies.company.trucks.pickupCreatedTruck,
    deliveryCreatedTruck: state.companies.companies.company.trucks.deliveryCreatedTruck,
  };

};

export default connect(mapStateToProps, mapDispatchToProps)(FreightMovementForm);
