import React from 'react';
import { set, cloneDeep, forEach, find, get, compact } from 'lodash';
import {
  CheckCircle as YesIcon, Cancel as NoIcon
} from '@mui/icons-material';
import { required, requiredBoolean } from './validators';
import { getGradeName, getCountryLabel } from './utils';
import { TAB } from '../components/cash-boards/constants';
import { BIDS_TAB } from '../components/contract-bids/constants';
import XeroIcon from '../components/common/XeroIcon';
import includes from 'lodash/includes';
import { loadReferencesDisplay, getLoadReferences } from '../components/stocks/utils';
import MovementIdentifier from '../components/freights/MovementIdentifier';
import { getCountryCurrency } from './utils';

export const FIELD = {
  value: null,
  validators: [],
  errors: [],
};
export const YEAR_MARGIN = 2
export const BASE_SEASON = 16 //base season is 16/17
export const CENTURY_BASE_YEAR = 2000
export const SEASON_MONTH = 10
export const SEASON_DATE = 1
export const SEASON_NA = 'N/A'
export const LOGISTICS_LITE_PLAN = 'logistics_lite';
export const PREMIUM_PLAN = 'premium';
export const DEFAULT_LAT = -23.7001391;
export const DEFAULT_LNG = 133.8810526;
export const REQUIRED_FIELD = set(cloneDeep(FIELD), 'validators', [required()]);
export const REQUIRED_BOOLEAN_FIELD = set(cloneDeep(FIELD), 'validators', [requiredBoolean()]);
export const DATE_DB_FORMAT = 'YYYY-MM-DD';
export const TIME_DB_FORMAT = 'HH:mm:ss';
export const TIME_DISPLAY_FORMAT = 'h:mm a';
export const PRIMARY_COLOR_GREEN = 'rgb(106,174,32)';
export const BG_GREEN_FADED = 'rgba(106,174,32, 0.1)';
export const BG_BLUE_FADED = 'rgba(0,25,43, 0.1)';
export const ACCENT_COLOR_BLUE = 'rgb(0,25,43)';
export const LIGHT_ACCENT_BLUE = '#112c42';
export const BROWN = 'rgb(175,145,63)';
export const TOMATO_RED = '#ef6030';
export const ERROR = '#d32f2f';
export const WARNING_ORANGE = '#f0ad4e';
export const PINK_PLUM = '#DB5A6B'; //Planned
export const ORANGE_ECSTACY = '#F9690E'; //booked
export const YELLOW_GAMBOGE = '#FFB61E'; //InProgress
export const GREEN_EUCALYPTUS = '#26A65B'; //Completed
export const PURPLE_MEDIUM = '#BF55EC'; //Delayed
export const BLUE_LAPIS_LAZULI = '#1F4788'; //Cancelled?
export const CORNFLOWER_BLUE = 'rgb(100, 149, 237)'; //Delivered
export const MAUVE = 'rgb(224, 176, 255)'; //Invoiced
export const PINK_NEON = '#eb3580';
export const GREEN_NEON = '#7bef4a';
export const YELLOW_NEON = '#fdfc57';
export const SLOT_PLANNED = 'rgb(74,185,216)';
export const SLOT_BOOKED = 'rgb(247,199,24)';
export const SLOT_IN_PROGRESS = 'rgb(255,133,0)';
export const SLOT_COMPLETED = 'rgb(59,184,32)';
export const SLOT_DELAYED = '#D41BD4';
export const SLOT_CANCELLED = 'rgb(203,0,0)';
export const SLOT_RESTRICTED = '#9d9d9d';
export const VERY_LIGHT_GRAY = 'rgb(249,249,249)';
export const WEEK_DAY_LIGHT_GRAY = 'rgb(222, 224, 224)';
export const WEEK_DAY_DARK_GRAY = 'rgb(114, 114, 114)';
export const LIGHT_GRAY = 'lightgray';
export const NEUTRAL_GRAY = '#999999';
export const INLOAD_COLOR = '#003351';
export const WARM_GREY = '#808080';
export const LIGHT_GREY = '#d3d3d3';
export const OUTLOAD_COLOR = '#A15900';
export const WHITE = '#fff';
export const BLACK = '#000';
export const EMPTY_VALUE = '-';
export const EMPTY = '';
export const GROWER = 1;
export const COMPANY_ADMIN = 1;
export const OBSERVER_TYPE_ID = 10;
export const AU_SYSTEM_COMPANY_ID = 1;
export const US_SYSTEM_COMPANY_ID = -1;
export const CA_SYSTEM_COMPANY_ID = -2;
export const NZ_SYSTEM_COMPANY_ID = -3;
export const SYSTEM_COMPANY_IDS = [AU_SYSTEM_COMPANY_ID, US_SYSTEM_COMPANY_ID, CA_SYSTEM_COMPANY_ID, NZ_SYSTEM_COMPANY_ID];
export const ROOT_USER_IDS = [AU_SYSTEM_COMPANY_ID, US_SYSTEM_COMPANY_ID, CA_SYSTEM_COMPANY_ID, NZ_SYSTEM_COMPANY_ID];
export const FARM_ADMIN = 4;
export const FARM_EMPLOYEE = 5;
export const OFFICE_ADMIN = 2;
export const OFFICE_EMPLOYEE = 3;
export const DRIVER_TYPE_ID = 9;
export const BROKER = 2;
export const MAX_YEAR = 2030;
export const MIN_YEAR = 2020;
export const LOGISTIC = 3;
export const BHC_TYPE_ID = 4;
export const TRADER = 5;
export const SYSTEM = 6;
export const BROKER_NOTE_PREFIX = 'B';
export const SALES_CONFIRMATION_PREFIX = 'S';
export const CONTRACT_PREFIX = 'C';
export const FREIGHT_MOVEMENT_PREFIX = 'M';
export const FREIGHT_ORDER_PREFIX = 'F';
export const GRAIN_ORDER_PREFIX = 'G';
export const PICKUP_ORDER_PREFIX = 'P';
export const DELIVERY_ORDER_PREFIX = 'D';
export const TITLE_TRANSFER_PREFIX = 'T';
export const INVOICE_PREFIX = 'I';
export const VD_PREFIX = 'V';
export const PACK_ORDER_PREFIX = 'O';
export const LOAD_PREFIX = 'L';
export const REGRADE_RESEASON_PREFIX = 'R';
export const STORAGE_TO_STORAGE_PREFIX = 'L';
export const STOCK_SWAP_PREFIX = 'L';
export const PAYMENT_RUN_PREFIX = 'X';
export const STOCK_UPDATE_OPTION_TYPE = -5;
export const OPTION_TYPE_STORAGE_EMPTY_LOAD = -6;
export const OPTION_TYPE_STORAGE_UPDATE_LOAD = -7;
export const OPTION_TYPE_STOCK_EMPTY_LOAD = -8;
export const OPTION_TYPE_STOCK_UPDATE_LOAD = -9;
export const REGRADE_RESEASON_OPTION_TYPE = -2;
export const OPTION_TYPE_WEB_SPLIT_LOADS = 14;
export const FREIGHT_DELIVERY_CHARGES = 0.14;
export const DISTANCE_FOR_LEVY = 20;
export const UNASSIGNED_EMPLOYEE_TYPE_ID = 12;
export const MAX_UPLOAD_DOCKETS = 7;

export const STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES = [
  OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STOCK_UPDATE_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD, OPTION_TYPE_STORAGE_UPDATE_LOAD,
  STOCK_UPDATE_OPTION_TYPE
];

export const COMMODITIES = {
  WHEAT: 1,
  CANOLA: 3,
  BARLEY: 2,
};

export const GRADES = {
  APH1: 1,
};
export const CONTRACT_TYPES = {
  FIXED_GRADE: 1,
  MULTI_GRADE: 2,
  FLOATING_MULTI_GRADE: 3,
  POOL_CASH_AND_CALL: 4,
  POOL_STRATEGIC_6_MONTH: 5,
  POOL_STRATEGIC_12_MONTH: 6,
  AREA: 7,
  SPOT: 8,
  BLENDED: 9,
};
export const PAYMENT_SCALES = {
  FLAT: 1,
  AOF_BASIC: 2,
  AOF_BASIC_CAP: 3,
};
export const PRICE_POINTS = {
  DELIVERED_SITE: 1,
  EX_FARM_STORE: 2,
  DELIVERED_BUYER: 3,
  DELIVERED_PORT: 4,
  TRACK: 5,
  FREE_IN_STORE: 6,
  FREE_ON_TRUCK: 7,
  DELIVERED_DCT: 8,
  DELIVERED_MARKET_ZONE: 9,
  FREE_ON_RAIL: 10,
  COST_AND_FREIGHT: 11,
  COST_INSURANCE_FREIGHT: 12,
  FREE_ON_BOARD: 13,
  EX_VESSEL: 14,
  STOCK_SWAP: 15,
  DPU: 16,
};

export const PICKUP_MANDATORY_PRICE_POINTS = [PRICE_POINTS.EX_FARM_STORE, PRICE_POINTS.FREE_ON_TRUCK, PRICE_POINTS.FREE_ON_RAIL, PRICE_POINTS.EX_VESSEL, PRICE_POINTS.STOCK_SWAP];
export const DELIVERY_MANDATORY_PRICE_POINTS = [
  PRICE_POINTS.DELIVERED_SITE, PRICE_POINTS.DELIVERED_PORT, PRICE_POINTS.FREE_IN_STORE,
  PRICE_POINTS.DELIVERED_DCT, PRICE_POINTS.DELIVERED_BUYER, PRICE_POINTS.COST_AND_FREIGHT,
  PRICE_POINTS.COST_INSURANCE_FREIGHT, PRICE_POINTS.FREE_ON_BOARD, PRICE_POINTS.DPU
];
export const PRICE_POINTS_TRACK_AND_DELIVERED_MARKET_ZONE = [PRICE_POINTS.TRACK, PRICE_POINTS.DELIVERED_MARKET_ZONE];

export const EMPLOYEE_TYPES = {
  OFFICE_ADMIN: 2,
  OFFICE_EMPLOYEE: 3,
  FARM_ADMIN: FARM_ADMIN,
  FARM_EMPLOYEE: 5,
};
export const COMPANY_TYPES = {
  "1": 'grower',
  "2": 'broker',
  "3": 'logistics',
  "4": 'bhc',
  "5": 'trader',
  "6": 'system'
}

export const FARM_EMPLOYEE_ROLES = [FARM_ADMIN, FARM_EMPLOYEE];
export const OFFICE_EMPLOYEE_ROLES = [OFFICE_ADMIN, OFFICE_EMPLOYEE];

export const WARNING_ORANGE_CLASSES = {
  warningColor: {
    color: `${WARNING_ORANGE} !important`,
  },
  warningUnderline: {
    '&:after': {
      borderBottomColor: `${WARNING_ORANGE} !important`,
    },
  },
};
export const AMENDABLE_STATUSES = ['planned', 'confirmation_pending', 'confirmed', 'open', 'in_progress', 'delivered', 'rejected'];
export const OUTLOAD_EXCESS_NET_WEIGHT_ERROR_MESSAGE = 'Net Weight exceeds current stock level';
export const INLOAD_EXCESS_NET_WEIGHT_WARNING_MESSAGE = 'Warning: You are adding an Inload which exceeds the remaining space of the storage';
export const INLOAD_INVALID_NET_WEIGHT_ERROR_MESSAGE = "Can't be less than what has already been outloaded";
export const LOAD_NOT_LAST_EDIT_NOT_ALLOWED = 'Edit not allowed because the load is not last';
export const FREIGHT_CONTRACT_LOAD_SOURCE = 'freight_contract';
export const NGR_MISSING_DETAILS_ERROR = 'NGR is missing some key details. Add missing details for the NGR or select to add Bank details manually.';
export const NGR_MISSING_DETAILS_ERROR_WITHOUT_ACCESS =
  "NGR details are missing or incomplete. Please ask the counter party or contact AgriChain to Add/Edit Bank Account details.";
export const MOVEMENT_TONNAGE_GREATER_THAN_ORDER_ERROR_MESSAGE = 'Cannot be greater than Order tonnage';
export const MOVEMENT_TONNAGE_GREATER_THAN_CONTRACT_ERROR_MESSAGE = 'Cannot be greater than Contract tonnage';
export const MOVEMENT_QUANTITY_GREATER_THAN_ORDER_ERROR_MESSAGE = 'Cannot be greater than Order volume';
export const MOVEMENT_QUANTITY_GREATER_THAN_CONTRACT_ERROR_MESSAGE = 'Cannot be greater than Contract volume';
export const SITE_EXTERNALLY_MANAGED_ERROR = "Cannot perform this operation as site is externally managed";
export const LOAD_CREATE_OR_EDIT_ERROR_MESSAGE = 'Loads for this site can only be $action by site employees';

export const NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE = 'Warning: Enter NGR to maintain stocks';
export const FREIGHT_CONTRACT_TYPE = {
  CUSTOMER_ONLY: 1,
  SELLER_TO_BUYER: 2,
  CALL_ON_GRAIN: 3,
};

export const INOVICE_TYPES = {
  COMMODITY_CONTRACT_INVOICE: 'Commodity Contract Invoice',
  BROKERAGE: 'Brokerage',
  FREIGHT_INVOICE: 'Freight Invoice',
};

export const PICKUP_REQUEST_ORDER_TYPE_ID = 4;
export const DELIVERY_REQUEST_ORDER_TYPE_ID = 5;
export const PACK_ORDER_TYPE_ID = 6;
export const REQUEST_ORDER_TYPE_IDS = [PICKUP_REQUEST_ORDER_TYPE_ID, DELIVERY_REQUEST_ORDER_TYPE_ID];
export const REQUEST_ORDER_TYPE = {
  PICKUP_ORDER: PICKUP_REQUEST_ORDER_TYPE_ID,
  DELIVERY_ORDER: DELIVERY_REQUEST_ORDER_TYPE_ID
};

export const ORDER_TYPE_NAMES = [
  {id: PICKUP_REQUEST_ORDER_TYPE_ID, name: 'Pickup'},
  {id: DELIVERY_REQUEST_ORDER_TYPE_ID, name: 'Delivery'},
  {id: FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN, name: 'Call on Grain'},
  {id: FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY, name: 'Freight'},
  {id: FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER, name: 'Freight'}
]

export const CONVEYENCES = {
  BUYERS_CALL: 1,
  SELLERS_CALL: 2,
  EVEN_SPREAD: 3,
  REFER_TO_SPECIAL_CONDITIONS: 4,
};

export const FREIGHT_ORDER_MAX_LEVEL = 2;
export const ZENDESK_URL = 'https://agrichain.zendesk.com/access/jwt?jwt=';
export const WEIGHT_DESTINATION_ID = 1;
export const DEFAULT_WEIGHT_ID = WEIGHT_DESTINATION_ID;
export const WEIGHT_ORIGIN_ID = 2;
export const INSPECTION_ORIGIN_ID = 2;

export const PRE_DELIVERY = 'Pre-Delivery';
export const POST_DELIVERY = 'Post-Delivery';
export const END_OF_DELIVERY = 'End of Delivery Period';

export const CALENDAR_STRUCT = {
  id: null,
  name: null,
  checked: true,
  color: null,
  bgColor: null,
  borderColor: null,
  dragBgColor: null,
};

export const CALENDAR_FREQUENCIES = [
  { id: 15, name: '15mins' },
  { id: 20, name: '20mins' },
  { id: 30, name: '30mins' },
  { id: 45, name: '45mins' },
  { id: 60, name: '60mins' },
  { id: 90, name: '90mins' },
  { id: 120, name: '120mins' },
];

export const DAYS_OF_WEEK = [
  { id: 0, name: 'S' },
  { id: 1, name: 'M' },
  { id: 2, name: 'T' },
  { id: 3, name: 'W' },
  { id: 4, name: 'T' },
  { id: 5, name: 'F' },
  { id: 6, name: 'S' },
];

export const REASONS_TO_REPRESENT = [
  'Accept/Reject this contract',
  'Amend this contract',
  'Create Freight Orders from this contract',
  'Accept Freight Orders created from this contract',
  'Create Freight Movements from this contract',
  'Accept Freight Movements created from this contract',
  'Create Commodity Contract Invoices',
];

export const REASONS_TO_REPRESENT_FOR_SELLER = [
  'Accept/Reject this contract',
  'Amend this contract',
  'Create Freight Orders from this contract',
  'Accept Freight Orders created from this contract',
  'Create Freight Movements from this contract',
  'Accept Freight Movements created from this contract',
  'Create Commodity Contract Invoices',
  'Create Title Transfers from this contract',
];

export const DAY = 'day';
export const DAYS = 'days';
export const WEEK = 'week';
export const MIDNIGHT = '23:59:59';
export const PASSWORDS_DO_NOT_MATCH = 'Passwords do not match';
export const TOLERANCE_5_OR_12_PERCENT = 2;
export const TOLERANCE_5_OR_20_PERCENT = 16;
export const TOLERANCE_NIL = 1;
export const FREIGHT = 'Freight';
export const FARM_ENTITY = 'farm';
export const HOME = '/action-centre';
export const SUPER_ADMIN_HOME = '/companies';
export const SITE_EMPLOYEE_HOME = '/site-management';
export const METER_CUBE_UNIT = 'm³';
export const KG_UNIT = 'kg';
export const LITRE_UNIT = 'litre';
export const LITRE_UNIT_ABBREVIATION = 'L';
export const BALES_UNIT = 'Bales';
export const MT_UNIT = 'MT';
export const CENTURY_TONNE_UNIT = 'CWT';
export const BUSHEL_UNIT = 'BU';
export const POUND_UNIT = 'LB';
export const SHORT_TONNE_UNIT = 'ST';
export const STRICT_COMMODITY_BASED_UNITS = [KG_UNIT, METER_CUBE_UNIT, LITRE_UNIT];
export const ALL_COMMODITY_UNITS = [MT_UNIT, BALES_UNIT, METER_CUBE_UNIT, KG_UNIT, POUND_UNIT, SHORT_TONNE_UNIT, CENTURY_TONNE_UNIT, BUSHEL_UNIT, LITRE_UNIT]
export const CALL_ON_GRAIN_TYPE_ID = 3;
export const SITE_GATE = 'SITE GATE';
export const INFINITY_SYMBOL = '∞';
export const FARM_GATE = 'FARM GATE';
export const CONTRACTS_TABLE_OPTIONS_ITEMS = [
  //Set hasFunc to true to make an api call
  //Set SideDrawer to true to call methods to open SideDrawer
  {
    key: 'view_in_new_tab',
    text: 'View in New Tab',
  },
  {
    key: 'freight_orders',
    text: 'Freight Orders',
    subItems: [
      { key: 'freight_orders_add', text: 'Add' },
      { key: 'freight_orders_view', text: 'View' },
    ],
  },
  {
    key: 'call_on_grain_orders',
    text: 'Call On Grain Orders',
    subItems: [
      { key: 'call_on_grain_orders_add', text: 'Add', sideDrawer: true },
      { key: 'call_on_grain_orders_view', text: 'View' },
    ],
  },
  {
    key: 'freight_movements',
    text: 'Freight Movements',
    subItems: [
      { key: 'freight_movements_add', text: 'Add' },
      { key: 'freight_movements_view', text: 'View' },
    ],
  },
  {
    key: 'title_transfers',
    text: 'Title Transfers',
    subItems: [
      { key: 'title_transfers_add', text: 'Add', sideDrawer: true },
      { key: 'title_transfers_view', text: 'View' },
    ],
  },
  {
    key: 'invoices',
    text: 'Invoices',
    subItems: [],
    hasFunc: true,
  },
  {
    key: 'vendor_dec',
    text: 'Vendor Dec',
    subItems: [
      { key: 'vendor_dec_add_contract', text: 'Add' },
      { key: 'vendor_dec_view_contract', text: 'View' },
      { key: 'vendor_dec_request_contract', text: 'Request' }
    ],
  },
  {
    key: 'amend',
    text: 'Amend',
  },
  {
    key: 'void',
    text: 'Void',
  },
  {
    key: 'duplicate',
    text: 'Duplicate',
  },
  {
    key: 'void_and_duplicate',
    text: 'Void And Duplicate',
  },
  {
    key: 'download_pdf',
    text: 'Download PDF',
    subItems: [],
    hasFunc: true,
  },
  {
    key: 'mark_complete',
    text: 'Mark Complete',
    hasFunc: true,
  },
];

export const FO_FA_FSA_TABLE_OPTIONS_ITEMS = () => {
  let option_items = [
    //Set hasFunc to true to make an api call
    //Set SideDrawer to true to call methods to open SideDrawer
    {
      key: 'view_in_new_tab',
      text: 'View in New Tab',
    },
    {
      key: 'freight_allocations',
      text: 'Freight Allocations',
      subItems: [
        { key: 'freight_allocations_add', text: 'Add' },
        { key: 'freight_allocations_view', text: 'View' },
      ],
    },
    {
      key: 'freight_movements',
      text: 'Freight Movements',
      subItems: [
        { key: 'freight_movements_add', text: 'Add' },
        { key: 'freight_movements_view', text: 'View' },
      ],
    },
    {
      key: 'invoices',
      text: 'Invoices',
      subItems: [
        { key: 'freight_invoice_add', text: 'Add Freight Invoice' },
        { key: 'freight_invoice_view', text: 'View' },
      ],
    },
    {
      key: 'vendor_dec',
      text: 'Vendor Dec',
      subItems: [
        { key: 'vendor_dec_add_fo', text: 'Add' },
        { key: 'vendor_dec_view_fo', text: 'View' },
        { key: 'vendor_dec_request_fo', text: 'Request' }
      ],
    },
    {
      key: 'amend',
      text: 'Amend',
    },
    {
      key: 'void',
      text: 'Void',
    },
    {
      key: 'duplicate',
      text: 'Duplicate',
    },
    {
      key: 'void_and_duplicate',
      text: 'Void And Duplicate',
    },
    { key: 'close_out', text: 'Close Out Order' },
    {
      key: 'download_pdf',
      text: 'Download PDF',
      subItems: [],
      hasFunc: true,
    },
  ];

  if(!window.VOID_AND_DUPLICATE_TOGGLE)
    option_items = option_items.filter(
      option => !DUPLICATE_OPTION_KEYS.includes(option.key)
    );

  return option_items
};

export const INVOICE_TABLE_OPTIONS_ITEMS = [
  //Set hasFunc to true to make an api call
  //Set SideDrawer to true to call methods to open SideDrawer
  {
    key: 'view_in_new_tab',
    text: 'View in New Tab',
  },
  {
    key: 'amend',
    text: 'Amend',
  },
  {
    key: 'void',
    text: 'Void',
  },
  {
    key: 'add_payment',
    text: 'Add Payment',
    sideDrawer: true,
  },
  {
    key: 'view_payments',
    text: 'View Payments',
  },
  {
    key: 'download_pdf',
    text: 'Download PDF',
    sideDrawer: true,
    hasFunc: true,
    subItems: [],
  },
  {
    key: 'mark_paid',
    text: 'Manually Mark Paid',
    sideDrawer: true,
  },
];

export const WAREHOUSE_DASHBOARD_TABLE_OPTIONS_ITEMS = ([
  {
    key: 'create',
    text: 'Create',
  },
  {
    key: 'createSend',
    text: 'Create and Send',
  },
  {
    key: 'edit',
    text: 'Edit',
  },
  {
    key: 'updateRecipients',
    text: 'Update Recipients',
  },
  {
    key: 'dueDate',
    text: 'Update Payment Due Date',
  },
  {
    key: 'verifyLoads',
    text: 'Verify Loads',
  },
  {
    key: 'invoices',
    text: 'Generated Invoices',
  },
]);

export const PAYMENT_RUN_TABLE_OPTIONS_ITEMS = ([
  {
    key: 'view_in_new_tab',
    text: 'View In New Tab'
  },
]);

export const CALL_ON_GRAIN_ORDER_TABLE_OPTIONS_ITEMS = () => {
  let option_items = [
    //Set hasFunc to true to make an api call
    //Set SideDrawer to true to call methods to open SideDrawer
    {
      key: 'view_in_new_tab',
      text: 'View in New Tab',
    },
    {
      key: 'freight_orders',
      text: 'Freight Orders',
      subItems: [
        { key: 'freight_orders_add', text: 'Add' },
        { key: 'freight_orders_view', text: 'View' },
      ],
    },
    {
      key: 'freight_movements',
      text: 'Freight Movements',
      subItems: [
        { key: 'freight_movements_add', text: 'Add' },
        { key: 'freight_movements_view', text: 'View' },
      ],
    },
    {
      key: 'vendor_dec',
      text: 'Vendor Dec',
      subItems: [
        { key: 'vendor_dec_add_cog', text: 'Add' },
        { key: 'vendor_dec_view_cog', text: 'View' },
        { key: 'vendor_dec_request_cog', text: 'Request' }
      ],
    },
    {
      key: 'amend',
      text: 'Amend',
    },
    {
      key: 'void',
      text: 'Void',
    },
    {
      key: 'duplicate',
      text: 'Duplicate',
    },
    {
      key: 'void_and_duplicate',
      text: 'Void And Duplicate',
    },
    { key: 'close_out', text: 'Close Out Order' },
    {
      key: 'download_creator_pdf',
      text: 'Download PDF',
      component: true,
      data: { entity: 'freightorder' },
    },
  ];

  if(!window.VOID_AND_DUPLICATE_TOGGLE)
    option_items = option_items.filter(
      option => !DUPLICATE_OPTION_KEYS.includes(option.key)
    );

  return option_items
};

export const MOVEMENTS_TABLE_OPTIONS_ITEMS = () => {
  //Set hasFunc to true to make an api call
  //Set SideDrawer to true to call methods to open SideDrawer
  let option_items = [
    {
    key: 'view_in_new_tab',
    text: 'View in New Tab',
    },
    {
      key: 'load_reject',
      text: 'Reject Inload',
      sideDrawer: true,
      hasFunc: true,
    },
    {
      key: 'add_edit_outload',
      text: 'Add Outload',
      sideDrawer: true,
    },

    {
      key: 'add_edit_inload',
      text: 'Add Inload',
      sideDrawer: true,
    },
    {
      key: 'vendor_dec',
      text: 'Vendor Dec',
      subItems: [
        { key: 'truck_cleanliness', text: 'Add Truck Cleanliness' },
        { key: 'grain_commodity', text: 'Add Grain Commodity' },
        { key: 'vendor_dec_view', text: 'View' },
        { key: 'truck_cleanliness_request', text: 'Request Truck Cleanliness' },
        { key: 'grain_commodity_request', text: 'Request Grain Commodity' }
      ],
    },
    {
      key: 'void',
      text: 'Void',
      sideDrawer: true,
    },
    {
      key: 'duplicate',
      text: 'Duplicate',
    },
    {
      key: 'void_and_duplicate',
      text: 'Void And Duplicate',
      sideDrawer: true,
    },
    {
      key: 'amend',
      text: 'Amend',
    },
    {
      key: 'download_pdf',
      text: 'Download PDF',
      subItems: [],
      hasFunc: true,
    },
    {
      key: 'assign_to',
      text: 'Assign to',
      sideDrawer: true,
    }
  ];

  if(!window.VOID_AND_DUPLICATE_TOGGLE)
    option_items = option_items.filter(
      option => !DUPLICATE_OPTION_KEYS.includes(option.key)
    );

  return option_items;
}

export const CURRENCIES = [
  {id: 'AU$', countryCode: 'au'},
  {id: 'US$', countryCode: 'us'},
  {id: 'CA$', countryCode: 'ca'},
  {id: 'NZ$', countryCode: 'nz'},
]

const getGradeDisplay = item => {
  const name = getGradeName(item)
  if(name && name.includes('/')){
    const names = name.split('/')
    return <React.Fragment>{names.map(_name => <React.Fragment key={_name}><span style={{whiteSpace: 'pre'}}>{_name.trim()}</span><br /></React.Fragment>)}</React.Fragment>
  }
    return name
}

export const getContractsGlobalListingTableHeaders = () => {
  return [
    { key: 'referenceNumber', header: 'Identifier', className: 'small' },
    { key: 'statusDisplayName', header: 'Status', className: 'xsmall capitalize'},
    { key: 'sellerName', header: 'Seller', className: 'small' },
    { key: 'buyerName', header: 'Buyer', className: 'small' },
    { key: 'invoicing', header: 'Invoicing', className: 'xsmall' },
    { key: 'deliveryStartDate', header: 'Start Date', className: 'xsmall' },
    { key: 'pricePoint', header: 'Price Point', className: 'xsmall' },
    { key: 'commodityName', secondaryKey: 'commodity.displayName', header: 'Commodity', className: 'xsmall' },
    { key: 'season', header: 'Season', className: 'xxsmall' },
    { key: 'grade', header: 'Base Grade', className: 'xsmall', default: item => getGradeDisplay(item) },
    { key: 'price', header: 'Price', className: 'xxsmall', default: item => item.price ? item.priceUnit ? `${item?.currency} ${parseFloat(item.price).toFixed(2)}/${item.priceUnit}` : parseFloat(item.price).toFixed(2) : EMPTY_VALUE },
    { key: 'tonnageDisplayValue', header: 'Quantity', className: 'xxsmall' },
    { key: 'totalDeliveredTonnage', header: 'Delivered', className: 'xxsmall', default: item => `${parseFloat(item.totalDeliveredTonnage).toFixed(2)} ${item.requestedUnit || item.priceUnit}` },
    { key: 'outstandingTonnage', header: 'Outstanding', className: 'xxsmall', default: item => `${parseFloat(item.inferredTonnage - item.totalDeliveredTonnage).toFixed(2)} ${item.requestedUnit || item.priceUnit}`, sortable: false },
    { key: 'contractDateFilter', header: 'Contract Date', className: 'xsmall', orderBy: 'contractDate' },
    { key: 'deliveryEndDate', header: 'End Date', className: 'xsmall' },
    { key: 'contractType', header: 'Type', className: 'xxsmall' },
    { key: 'deliveryOnus', header: 'Delivery Onus', className: 'xxsmall' },
    { key: 'paymentTerm', header: 'Payment Term', className: 'xsmall' },
    { key: 'tolerance', header: 'Tolerance', className: 'xsmall' },
    { key: 'deliverySites', header: 'Delivery Sites', className: 'large', sortable: false },
    { key: 'pickupSites', header: 'Pickup Sites', className: 'large', sortable: false },
    { key: 'lotNumber', header: 'Lot No.', className: 'small', },
    { key: 'sustainableCommodity', header: 'Sustainable Contract', className: 'xsmall', type: "bool" },
    { key: 'internalReferenceNumber', header: 'Internal Reference No.', className: 'small', },
    { key: 'unallocatedTonnage', header: () => `Unallocated ${getCountryLabel('tonnage')}`, className: 'xxsmall', sortable: false, default: item => `${item.unallocatedTonnage} ${item.requestedUnit || item.priceUnit}` },
    { key: 'remainingTonnage', header: () => `Remaining ${getCountryLabel('tonnage')}`, className: 'xxsmall', sortable: false, default: item => `${item.remainingTonnage} ${item.requestedUnit || item.priceUnit}` },
  ];
};

export const CONTRACT_ACTION_OPTIONS_TOGGLE_OFF = [
  { key: 'movements', text: 'Add Freight Movement' },
  { key: 'view_movements', text: 'View Freight Movements' },
  { key: 'view_orders', text: 'View Freight Orders' },
  { key: 'orders', text: 'Add Freight Order' },
  { key: 'title_transfers', text: 'Title Transfers' },
];

export const getFreightOrdersGlobalListingHeaders = hasParentOrderId => {
  return [
    { key: 'identifier', header: hasParentOrderId ? 'Allocation No' : 'Order No', className: 'small' },
    { key: 'displayStatus', secondaryKey: 'orderStatusDisplayName', header: 'Status', className: 'xsmall capitalize' },
    { key: 'customerName', header: 'Customer', className: 'small' },
    { key: 'freightProvider', secondaryKey: 'provider.name', header: 'Freight Provider', className: 'small'},
    { key: 'pickupDate', secondaryKey: 'freightPickup.date', header: 'Start Date', className: 'msmall' },
    { key: 'deliveryDate', secondaryKey: 'freightDelivery.date', header: 'End Date', className: 'msmall' },
    {
      key: 'consignorName',
      secondaryKey: 'freightPickup.consignor.handler.name',
      tertiaryKey: 'consignor.name',
      quaternaryKey: 'freightPickup.consignor.handler.displayName',
      header: 'Pickup Site',
      className: 'small'
    },
    {
      key: 'consigneeName',
      secondaryKey: 'freightDelivery.consignee.handler.name',
      tertiaryKey: 'consignee.name',
      quaternaryKey: 'freightDelivery.consignee.handler.displayName',
      header: 'Delivery Site',
      className: 'small'
    },
    {
      key: 'commodityName',
      quaternaryKey: 'commodity.displayName',
      secondaryKey: 'commodityContract.commodity.displayName',
      tertiaryKey: 'parentOrder.commodity.displayName',
      header: 'Commodity',
      className: 'small',
    },
    { key: 'gradeName', header: 'Grade', className: 'xsmall' },
    { key: 'tonnageDisplayValue', header: 'Quantity', className: 'xxsmall', sortable: false },
    { key: 'remainingTonnage', header: 'Unplanned', className: 'xxsmall', default: item => `${item.remainingTonnage} ${item.requestedUnit || item.priceUnit}`, sortable: false},
    { key: 'contractNumber', header: 'Contract No', className: 'small', default: item => item.contractNumber || item.externalReferenceNumber },
    { key: 'season', header: 'Season', className: 'xxsmall' },
    { key: 'buyer', header: 'Buyer', className: 'msmall' },
    { key: 'seller', header: 'Seller', className: 'msmall' },
    { key: 'deliveredTonnage', header: 'Delivered', className: 'msmall', default: item => `${item.deliveredTonnage} ${item.requestedUnit || item.priceUnit}`, sortable: false },
    { key: 'outstandingTonnage', header: 'Outstanding', className: 'msmall', default: item => `${item.outstandingTonnage} ${item.requestedUnit || item.priceUnit}`, sortable: false }
  ];
};

export const INVOICE_TABLE_GLOBAL_LISTING_HEADERS = [
  { key: 'identifier', header: 'Invoice No.', className: 'small' },
  {
    key: 'contractNumber',
    urlKey: 'parentWebUrl',
    header: "Contract/Order No.",
    className: 'small',
    fieldType: 'url-conditional',
    link: true
  },
  { key: 'statusDisplayName', header: 'Status', className: 'small capitalize', sortable: false },
  { key: 'payerDisplayName', secondaryKey: 'payer.company.displayName', header: 'Bill To', className: 'small' },
  { key: 'payeeDisplayName', secondaryKey: 'payee.company.displayName', header: 'Bill For', className: 'small' },
  { key: 'type', header: 'Type', className: 'xsmall' },
  { key: 'commodity', header: 'Commodity', className: 'small', sortable: false},
  { key: 'invoicedTonnageDisplayName', header: 'Invoiced Quantity', className: 'xsmall', sortable: false},
  { key: 'dueDate', header: 'Payment Due Date', className: 'xsmall' },
  { key: 'paidOn', header: 'Paid On', className: 'xsmall' },
  { key: 'subTotal', type: 'currency', header: () => `Sub Total (Ex ${getCountryLabel('gst')})`, className: 'xsmall' },
  { key: 'total', header: () => `Total (Inc ${getCountryLabel('gst')})`, type: 'currency', className: 'xsmall' },
  { key: 'createdOn', header: 'Created On', className: 'xsmall', orderBy: 'createdAt' },
  {
    key: 'xeroSyncedAt',
    header: 'Xero Synced At',
    className: 'xsmall',
    sortable: false,
    type: 'datetime',
  },
  {
    key: 'xeroId',
    header: 'Xero',
    className: 'xxsmall',
    sortable: false,
    align: 'center',
    valueFunction: item => item.xeroInvoiceUrl ?
      <XeroIcon
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          window.open(item.xeroInvoiceUrl)
        }}
        connected
        href={item.xeroInvoiceUrl}
        rel='noopener noreferrer'
        target='_blank'
      /> :
    ''
  },
  { key: 'tenure', header: 'Tenure', className: 'medium'},
];

const getWarehouseRecipients = invoice => {
  let result = [];
  result = get(invoice, 'recipients.names') || [];
  forEach(get(invoice, 'recipients.emails', []), email => {
    if(!find(invoice.recipients.names, name => name.includes(email)))
      result.push(email);
  });
  return result.join(', ');
};

export const WAREHOUSE_DASHBOARD_HEADERS = [
  { key: 'statusDisplayName', header: 'Status', className: 'small capitalize' },
  { key: 'payerDisplayName', header: 'Bill To', className: 'small' },
  { key: 'payerNgr', header: 'Bill To NGR', className: 'small' },
  { key: 'commodityDisplayName', header: 'Commodity', className: 'small' },
  { header: 'Recipients', className: 'large', default: getWarehouseRecipients, sortable: false },
  { key: 'dueDate', header: 'Payment Due Date', className: 'xsmall' },
  { key: 'tenure', header: 'Tenure', className: 'xsmall' },
  { header: 'Fees', className: 'xsmall', default: item => `${getCountryCurrency()} ${item.systemAmount ? parseFloat(item.systemAmount).toFixed(2) : 0.00}`, sortable: false },
  { header: 'Fees Not Invoiced', className: 'xsmall', default: item => `${getCountryCurrency()} ${parseFloat(item.systemAmount - item.invoicedAmount).toFixed(2)}`, sortable: false },
  { header: 'Adjusted Fees', className: 'xsmall', default: item => `${getCountryCurrency()} ${parseFloat(item.customAmount).toFixed(2)}` },
  { header: 'Paid', className: 'xsmall', default: item => `${getCountryCurrency()} ${parseFloat(item.paidAmount).toFixed(2)}` },
  { key: 'createdOn', header: 'Created On', className: 'xsmall', orderBy: 'createdAt' },
];

export const getCallOnGrainOrdersGlobalListingHeaders = hasParentOrderId => {
  return [
    { key: 'identifier', header: hasParentOrderId ? 'Allocation No' : 'Order No', className: 'small' },
    { key: 'displayStatus', secondaryKey: 'orderStatusDisplayName', header: 'Status', className: 'xsmall capitalize' },
    { key: 'sellerName', header: 'Seller', className: 'small'},
    { key: 'buyerName', header: 'Buyer', className: 'small'},
    { key: 'pickupDate', secondaryKey: 'freightPickup.date', header: 'Start Date', className: 'msmall' },
    { key: 'deliveryDate', secondaryKey: 'freightDelivery.date', header: 'End Date', className: 'msmall' },
    {
      key: 'consignorName',
      secondaryKey: 'freightPickup.consignor.handler.name',
      tertiaryKey: 'consignor.name',
      quaternaryKey: 'freightPickup.consignor.handler.displayName',
      header: 'Pickup Site',
      className: 'small'
    },
    {
      key: 'consigneeName',
      secondaryKey: 'freightDelivery.consignee.handler.name',
      tertiaryKey: 'consignee.name',
      quaternaryKey: 'freightDelivery.consignee.handler.displayName',
      header: 'Delivery Site',
      className: 'small'
    },
    {
      key: 'commodityName',
      quaternaryKey: 'commodity.displayName',
      secondaryKey: 'commodityContract.commodity.displayName',
      tertiaryKey: 'parentOrder.commodity.displayName',
      header: 'Commodity',
      className: 'small',
    },
    { key: 'gradeName', header: 'Grade', className: 'xxsmall' },
    { key: 'tonnageDisplayValue', header: 'Quantity', className: 'xxsmall', sortable: false },
    { key: 'remainingTonnage', header: () => `Unplanned ${getCountryLabel('tonnage')}`, className: 'xxsmall', sortable: false },
    { key: 'contractNumber', header: 'Contract No', className: 'xsmall' },
    { key: 'season', header: 'Season', className: 'xxsmall' },
  ];
};

export const REQUEST_ORDERS_HEADERS = [
  { key: 'identifier', header: 'Order No', className: 'small' },
  { key: 'status', header: 'Status', className: 'xsmall capitalize', sortable: false },
  { key: 'customer', header: 'Customer', className: 'small' },
  { key: 'site', header: 'Site', className: 'small'},
  { key: 'orderType', header: 'Order Type', className: 'xsmall' },
  { key: 'pickupDate', header: 'Start Date', className: 'msmall' },
  { key: 'deliveryDate', header: 'End Date', className: 'msmall' },
  { key: 'commodityName', header: 'Commodity', className: 'small' },
  { key: 'gradeName', header: 'Grade', className: 'xsmall' },
  { key: 'season', header: 'Season', className: 'xxsmall' },
  { key: 'tonnage', header: 'Quantity', className: 'xxsmall', sortable: false },
  { key: 'remainingTonnage', header: () => `Unplanned ${getCountryLabel('tonnage')}`, className: 'xxsmall', sortable: false },
];

export const PACK_ORDERS_HEADERS = [
  { key: 'identifier', header: 'Order No', className: 'small' },
  { key: 'displayStatus', header: 'Status', className: 'xsmall capitalize', sortable: false },
  { key: 'customerName', header: 'Customer', className: 'small' },
  { key: 'shippingLine', header: 'Shipping Line', className: 'small', sortable: false },
  { key: 'vesselName', header: 'Vessel', className: 'xsmall' },
  { key: 'releaseNumbers', header: 'Release No.', className: 'small' },
  { key: 'packByDate', header: 'Pack By', className: 'xsmall' },
  { key: 'deliverByDate', header: 'Deliver By', className: 'xsmall' },
  { key: 'packSite', header: 'Pack Site', className: 'small' },
  { key: 'commodityName', header: 'Commodity', className: 'xsmall' },
  { key: 'gradeName', header: 'Grade', className: 'xsmall' },
  { key: 'containers', header: 'Containers', className: 'xsmall' },
];

export const CONTRACTS_TEMPLATES_HEADERS = [
  { key: 'templateName', header: 'Template Name', className: 'xxlarge capitalize', sortable: true },
  { key: 'createdAt', header: 'Created on', className: 'xxlarge capitalize', sortable: true },
];

export const CONTRACTS_TEMPLATES_SUPER_ADMIN_HEADERS = [
  { key: 'templateName', header: 'Template Name', className: 'xxlarge capitalize', sortable: true },
  { key: 'createdAt', header: 'Created on', className: 'xxlarge capitalize', sortable: true },
  { key: 'createdBy', header: 'Created By', className: 'xxlarge capitalize', sortable: true },
  { key: 'creatorCompany', header: 'Company Name', className: 'xxlarge capitalize', sortable: true },
];

export const MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS = [
  { key: 'identifier', header: 'Movement No', className: 'small', valueFunction: item => (<MovementIdentifier movement={item} />) },
  { key: 'orderIdentifier', header: 'Order No', className: 'small', default: item => get(item, 'buyerLinkedDeliveryOrder.identifier') || get(item, 'orderIdentifier') },
  { key: 'displayStatus', secondaryKey: 'freightStatusDisplayName', header: 'Status', className: 'xsmall capitalize' },
  { key: 'customerName', header: 'Customer', className: 'small' },
  { key: 'freightProvider', header: 'Freight Provider', className: 'small' },
  { key: 'rego', header: () => getCountryLabel('rego'), className: 'xsmall' },
  { key: 'pickupDatetime', header: 'Pickup Date', className: 'msmall' },
  { key: 'deliveryDatetime', header: 'Delivery Date', className: 'msmall' },
  { key: 'consignorName', header: 'Pickup Site', className: 'small', sortable: false },
  { key: 'consigneeName', header: 'Delivery Site', className: 'small', sortable: false },
  { key: 'commodityName', header: 'Commodity', className: 'small' },
  { key: 'gradeName', header: 'Grade', className: 'xsmall' },
  { key: 'tonnageDisplayValue', header: 'Quantity', className: 'xxsmall', sortable: false },
  { key: 'contractNumber', header: 'Contract No', className: 'small', default: item => get(item, 'buyerLinkedDeliveryOrder.commodityContractIdentifier') || get(item, 'contractNumber') },
  { key: 'saleContract', header: 'Sale Contract', className: 'small'},
  { key: 'purchaseContract', header: 'Purchase Contract', className: 'medium'},
  { key: 'saleContractPricePoint', header: 'Sale Contract Price Point', className: 'small'},
  { key: 'purchaseContractPricePoint', header: 'Purchase Contract Price Point', className: 'medium'},
  { key: 'saleContractInvoicing', urlKey: 'url', textKey: 'identifier', header: 'Sale Contract Invoicing', fieldType: "multi-values", className: 'small', link: true},
  { key: 'purchaseContractInvoicing', urlKey: 'url', textKey: 'identifier', header: 'Purchase Contract Invoicing', fieldType: "multi-values", className: 'small', link: true},
  { key: 'isCommodityContractInvoiced', urlKey: 'url', textKey: 'identifier', header: 'Commodity Invoiced', fieldType: "multi-values", className: 'small', link: true},
  { key: 'freightInvoiced', urlKey: 'url', textKey: 'identifier', header: 'Freight Invoiced', fieldType: "multi-values", className: 'small', link: true},
  { key: 'warehouseInvoiced', urlKey: 'url', textKey: 'identifier', header: 'Warehouse Invoiced', fieldType: "multi-values", className: 'small', link: true},
  { key: 'season', header: 'Season', className: 'small' },
  { key: 'buyer', header: 'Buyer', className: 'small' },
  { key: 'seller', header: 'Seller', className: 'small' },
  { key: 'pickupDocketNumber', header: () => `Pickup ${getCountryLabel('docket')}`, className: 'small' },
  { key: 'deliveryDocketNumber', header: () => `Delivery ${getCountryLabel('docket')}`, className: 'small' },
  { key: 'pickupBookingNumber', header: 'Pickup Booking Number', className: 'small' },
  { key: 'deliveryBookingNumber', header: 'Delivery Booking Number', className: 'small' },
  { key: 'plannedGrade', header: 'Planned Grade', className: 'small' },
  { key: 'plannedSeason', header: 'Planned Season', className: 'small' },
  { key: 'plannedTonnage', header: () => `Planned ${getCountryLabel('tonnage')}`, className: 'small' },
  { key: 'plannedVariety', header: 'Planned Variety', className: 'small' },
  { key: 'pickupSiteNames', header: 'Pickup Storage', className: 'small' },
  { key: 'deliverySiteNames', header: 'Delivery Storage', className: 'small' },
  { key: 'externalReferences', header: 'External References', className: 'medium' }
];

export const SITE_LOADS_HEADERS = [
  { key: 'dateTime', header: 'Date Time', className: 'small', type: 'dateTime' },
  { key: 'subType', header: 'Sub Type', className: 'small' },
  { header: 'Reference(s)', formatter: loadReferencesDisplay, default: getLoadReferences},
  { key: 'storage', header: 'Storage', className: 'small' },
  { key: 'stockOwner', header: 'Stock Owner', className: 'medium' },
  { key: 'ngr', header: 'NGR', className: 'small' },
  { key: 'freightProvider', header: 'Freight Provider', className: 'small' },
  { key: 'rego', header: () => getCountryLabel('rego'), className: 'small' },
  { key: 'commodity', header: 'Commodity', className: 'small' },
  { key: 'grade', header: 'Grade', className: 'small' },
  { key: 'season', header: 'Season', className: 'small' },
  { key: 'netWeight', header: 'Quantity', className: 'small' },
  { key: 'tonnageWithShrinkage', header: 'Quantity with shrinkage', className: 'small', sortable: false },
  { key: 'creator', header: 'Creator', className: 'small' },
];

export const PACK_MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS = [
  { key: 'identifier', header: ' Pack Movement No', className: 'small' },
  { key: 'orderIdentifier', header: 'Pack Order No', className: 'small' },
  { key: 'displayStatus', secondaryKey: 'freightStatusDisplayName', header: 'Status', className: 'xsmall capitalize', sortable: false },
  { key: 'customerName', header: 'Customer', className: 'small' },
  { key: 'shippingLine', header: 'Shipping Line', className: 'small', sortable: false },
  { key: 'containerNumber', header: 'Container No.', className: 'xsmall' },
  { key: 'packDate', header: 'Pack Date', className: 'msmall' },
  { key: 'packSite', header: 'Pack Site', className: 'msmall', sortable: false },
  { key: 'commodityName', header: 'Commodity', className: 'small' },
  { key: 'gradeName', header: 'Grade', className: 'xsmall', sortable: false },
  { key: 'tonnageDisplayValue', header: 'Quantity', className: 'xxsmall', sortable: false },
];

export const EMAIL_REGEX = /^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/; // eslint-disable-line

export const CONTRACT_FILTER_STATUSES = [
  ['planned', 'Action Pending/Awaiting Confirmation'],
  ['manual_contract_complete_balanced', 'Manually Completed'],
  ['confirmed', 'Confirmed'],
  ['rejected', 'Rejected'],
  ['open', 'Open'],
  ['in_progress', 'In Progress'],
  ['delivered', 'Delivered'],
  ['invoiced', 'Invoiced'],
  ['void', 'Void'],
  ['delayed', 'Delayed'],
  ['completed', 'Completed'],
  ['paid', 'Paid']
];

export const FO_GO_FILTER_STATUSES = [
  ['draft', 'Draft'],
  ['confirmed', 'Confirmed'],
  ['planned', 'Action Pending/Awaiting Confirmation'],
  ['rejected', 'Rejected'],
  ['open', 'Open'],
  ['in_progress', 'In Progress'],
  ['delivered', 'Delivered'],
  ['void', 'Void'],
  ['completed', 'Completed'],
];

export const FM_FILTER_STATUSES = [
  ['draft', 'Draft'],
  ['planned', 'Action Pending/Awaiting Confirmation'],
  ['confirmed', 'Confirmed'],
  ['rejected', 'Rejected'],
  ['in_progress', 'In Progress'],
  ['delivered', 'Delivered'],
  ['void', 'Void'],
  ['load_rejected', 'Load Rejected'],
  ['completed', 'Completed'],
  ['auto_completed', 'Auto Completed'],
];

export const INVOICED_FOR_STATUSES = [
  ['commodity_contract', "Commodity Contract Invoiced"],
  ['freight', "Freight Invoiced"],
]

export const PACK_MOVEMENT_FILTER_STATUSES = [
  ['planned', 'Action Pending/Awaiting Confirmation'],
  ['confirmed', 'Empty'],
  ['in_progress', 'Packing In Progress'],
  ['void', 'Void'],
  ['load_rejected', 'Load Rejected'],
  ['completed', 'Packed'],
  ['auto_completed', 'Auto Completed'],
];


export const CONTRACT_INVOICING = [
  [
    "Seller to Invoice Buyer",
    "Seller to Invoice Buyer"
  ],
  [
    "Broker to Invoice Buyer",
    "Broker to Invoice Buyer"
  ],
  [
    "Buyer RCTI",
    "Buyer RCTI"
  ]
];

export const FO_GO_INVOICING = [
  [
    "Freight Provider to Invoice Broker",
    "Freight Provider to Invoice Broker"
  ],
  [
    "Freight Provider to Invoice Customer",
    "Freight Provider to Invoice Customer"
  ],
  [
    "Customer RCTI",
    "Customer RCTI"
  ]
];

export const COMPANY_DIRECTORY = 'company_directory';
export const TRUCK_CODES = [
  "2 Axle Rigid Truck - Code 1",
  "3 Axle Semitrailer - Code 2",
  "Common 4 Axle Semitrailer - Code 3",
  "5 Axle Semitrailer - Code 4",
  "2 Axle Rigid Truck and 2 Axle Pig Trailer - Code 5",
  "2 Axle Rigid Truck and 3 Axle Pig Trailer - Code 6",
  "2 Axle Rigid Truck and 2 Axle Dog - Code 7",
  "2 Axle Rigid Truck and 3 Axle Dog Trailer - Code 8",
  "3 Axle Rigid Truck - Code 9",
  "4 Axle Semitrailer - Code 10",
  "Common 5 Axle Semitrailer - Code 11",
  "Common 6 Axle Semitrailer - Code 12",
  "3 Axle Rigid Truck and 2 Axle Pig Trailer - Code 13",
  "3 Axle Rigid Truck and 3 Axle Pig Trailer - Code 14",
  "3 Axle Rigid Truck and 2 Axle Dog Trailer - Code 15",
  "3 Axle Rigid Truck and 3 Axle Dog Trailer - Code 16",
  "3 Axle Twin Steer Rigid Truck - Code 17",
  "4 Axle Twin Steer Rigid Truck - Code 18",
  "4 Axle Twin Steer Rigid Truck and 2 Axle Pig Trailer - Code 19",
  "4 Axle Twin Steer Rigid Truck and 3 Axle Pig Trailer - Code 20",
  "4 Axle Twin Steer Rigid Truck and 2 Axle Dog Trailer - Code 21",
  "4 Axle Twin Steer Rigid Truck and 3 Axle Dog Trailer - Code 22",
  "5 Axle Semitrailer and 3 Axle Dog Trailer (Road train) - Code 23",
  "3 Axle Prime Mover and 2 Axle Trailer and 2 Axle Dog Trailer and 2 Axle Trailer - Code 24",
  "5 Axle Prime Mover and 2 Axle Dolly and 3 Axle Trailer (Road train) - Code 25",
  "6 Axle Semitrailer and 3 Axle Dog Trailer (Road train) - Code 26",
  "6 Axle Prime Mover and 2 Axle Dolly and 2 Axle Trailer - Code 27",
  "Common 11 Axle Road train - Code 28",
  "8 Axle B-double - Code 29",
  "9 Axle B Triple Road Train - Code 30",
  "11 Axle Road train - Code 31",
  "4 Axle Tri Drive Rigid Truck and 5 Axle Dog (PBS) - Code 32",
  "4 Axle Prime Mover and 4 Axle - Code 33",
  "3 Axle Prime Mover and 4 Axle Semitrailer (PBS) - Code 34",
  "4 Axle Twin Steer Rigid Truck and 1 Axle Dog Trailer - Code 35",
  "5 Axle Twin Steer Rigid Truck - Code 36",
  "3 Axle Rigid Truck and 2 x 3 Axle Dog Trailers (Road train) - Code 37",
  "3 Axle Rigid Truck and 4 Axle Dog Trailer and 3 Axle Dog Trailer (Road train) - Code 38",
  "3 Axle Rigid Truck and 2 x 4 Axle Dog Trailers (Road train) - Code 39",
  "4 Axle Twin Steer Rigid Truck and 2 x 3 Axle Dog Trailers (Road train) - Code 40",
  "4 Axle Twin Steer Rigid Truck and 4 Axle Dog Trailer and 3 Axle Dog Trailer (Road train) - Code 41",
  "4 Axle Twin Steer Rigid Truck 2 4 Axle Dog Trailers (Road train) - Code 42",
  "4 Axle Tri Drive Rigid Truck - Code 43",
  "3 Axle Rigid Truck and 6 Axle Dog Trailer (PBS vehicle) - Code 44",
  "7 Axle Twin Steer Semitrailer - Code 45",
  "3 Axle Rigid Truck and 5 Axle Dog Trailer and 4 Axle Dog Trailer (PBS) - Code 49",
  "17 Axle ABB-quad Road train and 3 Axle Dolly - Code 50",
  "18 Axle ABB-quad Road train and 3 Dolly - Code 51",
  "Common 17 Axle BAB-quad Road train and 2 Axle Dolly - Code 52",
  "18 Axle BAB-quad Road train and 3 Axle Dolly - Code 53",
  "4 Axle Twin Steer Rigid Truck and 5 Axle Dog Trailer (PBS) - Code 55",
  "4 Axle Twin Steer Rigid Truck and 6 Axle Dog Trailer - Code 56",
  "4 Axle Twin Steer Prime Mover and 2 Axle Semitrailer (PBS) - Code 57",
  "Tri-Quad B-double (PBS Vehicle) - Code 58",
  "11 Axle AB-triple - Code 60",
  "3 Axle Rigid and 3 Axle Dolly and 3 Axle Trailer (PBS) - Code 62",
  "3 Axle Rigid Truck and 4 Axle Dog Trailer (Road Train) - Code 63",
  "12 Axle AB-triple Road train and 3 Axle Dolly - Code 64",
  "13 Axle AB-triple Road train and 3 Axle Dolly - Code 66",
  "Common 9 Axle B-double - Code 68",
  "10 Axle B-triple Road train - Code 69",
  "13 Axle AB-triple Road train and 2 Axle Dolly - Code 70",
  "13 Axle AB-triple Road train and 3 Axle Dolly - Code 71",
  "4 Axle Twin Steer Rigid Truck and 4 Axle Dog Trailer - Code 72",
  "4 Axle Twin Steer Rigid Truck and 4 Axle Dog Trailer - Code 73",
  "Common 16 Axle 2 Axle Dolly Type 2 Road train - Code 74",
  "3 Axle Rigid Truck and 4 Axle Dog Trailer (Road Train) - Code 75",
  "3 Axle Rigid Truck and 4 Axle Dog - Code 76",
  "6 Axle Semitrailer and 1 Axle Dolly and 1 Axle Trailer - Code 77",
  "14 Axle Road train (Type 2) - Code 78",
  "9 Axle Road train (Type 1) - Code 79",
  "15 Axle Road train (Type 2) - Code 80",
  "10 Axle Road train (Type 1) - Code 81",
  "Common 7 Axle B-double - Code 83",
  "3 Axle Rigid Truck and 1 Axle Pig Trailer - Code 84",
  "Twin Steer 10 Axle B-double - Code 85",
  "2 Axle Rigid Truck and 1 Axle Pig Trailer - Code 86",
  "3 Axle Rigid Truck and 5 Axle Dog - Code 87",
  "Common 14 Axle AB-triple Road train and 2 Axle Dolly - Code 88",
  "Common 18 Axle 3 Axle Dolly Road train (Type 2) - Code 89",
  "17 Axle Road train (Type 2) - Code 90",
  "Common 12 Axle Road train - Code 91",
  "8 Axle B-double - Code 92",
  "13 Axle AB-triple Road train and 2 Axle Dolly - Code 93",
  "Common 12 Axle B-triple Road train - Code 94",
  "12 Axle AB-triple Road train and 2 Axle Dolly - Code 95",
  "15 Axle AB-triple Road train and 3 Axle Dolly - Code 96",
  "11 Axle B-triple Road train - Code 97",
  "14 Axle AB-triple Road train and 3 Axle Dolly - Code 98",
  "3 Axle Rigid and 3 Axle Dolly and 3 Axle A Trailer and 3 Axle B Trailer (PBS) - Code 100",
  "3 Axle Rigid and 2 Axle Dolly and 4 Axle Trailer (PBS) - Code 102",
  "3 Axle Rigid and 5 Axle Dog and 5 Axle Dog - Code 103",
  "3 Axle Prime Mover and 3 Axle Trailer and 4 Axle Dog (Road Train) - Code 104",
  "3 Axle Prime Mover and 2 Axle Trailer and 4 Axle Dog (Road Train) - Code 105",
  "Twin steer 5 Axle Rigid and 3 Axle Dog (PBS) - Code 106",
  "4 Axle Tri Drive Rigid Truck and 4 Axle Dog (PBS) - Code 107",
  "3 Axle Rigid and 5 Axle Dog Trailer (Road Train) - Code 108",
  "3 Axle Prime Mover and 3 Axle (1 2) Group Trailer - 110",
  "3 Axle Prime Mover and 3 Axle (1 2) Group Trailer and 2 Axle Dolly and 3 Axle (1 2) Group Trailer (PBS) - Code 111",
];

export const getCashPriceGlobalListingTableHeaders = (isAllCashPriceTab, isPresentation) => {
  const commonCellStyle = isPresentation ? {fontSize: '16px'} : {}
  const commonHeadStyle = {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}
  const buyer = includes([TAB.ALL_CASH_PRICE, TAB.SITE_ARCHIVED_CASH_PRICE, TAB.UNREGISTERED_CASH_PRICE, TAB.SITE_ACTIVE_CASH_PRICE], isAllCashPriceTab) ? [{ key: 'buyer.companyName', header: 'Buyer', className: 'medium', cellStyle: commonCellStyle }] : [];
  const limit = !includes([TAB.ALL_CASH_PRICE, TAB.UNREGISTERED_CASH_PRICE, TAB.SITE_ARCHIVED_CASH_PRICE], isAllCashPriceTab) ? [
    { key: 'limit', header: 'Limit', className: 'xxsmall', cellStyle: commonCellStyle, sortable: false, default: item => `${item.limit} ${item.priceUnit}` },
    { key: 'bought', header: 'Bought', className: 'xxsmall', cellStyle: commonCellStyle, sortable: false, default: item => `${item.limit-item.limitRemaining} ${item.priceUnit}` },
    { key: 'limitRemaining', header: 'Limit Remaining', className: 'xxsmall', cellStyle: commonCellStyle, sortable: false, default: item => `${item.limitRemaining} ${item.priceUnit}` },
  ] : [];
  const limit_without_remaining = includes([TAB.SITE_ARCHIVED_CASH_PRICE], isAllCashPriceTab) ? [
    { key: 'limit', header: 'Limit', className: 'xxsmall', cellStyle: commonCellStyle, sortable: false, default: item => `${item.limit} ${item.priceUnit}` },
    { key: 'bought', header: 'Bought', className: 'xxsmall', cellStyle: commonCellStyle, sortable: false, default: item => `${item.limit-item.limitRemaining} ${item.priceUnit}` },
  ] : [];
  const status = !includes([TAB.ALL_CASH_PRICE, TAB.UNREGISTERED_CASH_PRICE], isAllCashPriceTab) ? [
    { key: 'startDateTime', header: 'Start Date', className: 'small', cellStyle: commonCellStyle },
    { key: 'endDateTime', header: 'End Date', className: 'small', cellStyle: commonCellStyle },
  ] : [];
  const paymentTerms = !includes([TAB.SITE_ARCHIVED_CASH_PRICE], isAllCashPriceTab) ? [
    { key: 'paymentTerm.name', header: 'Payment Terms', className: 'xsmall', cellStyle: commonCellStyle },
  ] : [];

  const sustainableColumn = {
    align: 'center',
    key: 'isCommoditySustainable',
    header: 'Sustainable',
    className: 'xxsmall',
    sortable: false,
    formatter: item => item.isCommoditySustainable ?
      (isPresentation ? <YesIcon style={{color: PRIMARY_COLOR_GREEN}} /> : 'True') :
      (isPresentation ? <NoIcon color='warning'/> : 'False'),
    headStyle: commonHeadStyle
  }


  const priceColumn = {
    key: 'price',
    header: 'Price',
    className: 'xxsmall',
    default: item => `${getCountryCurrency()} ${item.price}`,
    cellStyle: isPresentation ? {backgroundColor: PRIMARY_COLOR_GREEN, color: WHITE, ...commonCellStyle, fontSize: '24px'} : commonCellStyle,
    align: isPresentation ? 'right' : 'left',
  }


  return compact([
    { key: 'siteName', header: 'Site', className: isPresentation ? 'large' : 'small', cellStyle: commonCellStyle },
    { key: 'track', header: 'Port', className: 'xxsmall', cellStyle: commonCellStyle },
    ...buyer,
    isPresentation ? null : { key: 'commodityName', header: 'Commodity', className: 'xxsmall', headStyle: commonHeadStyle },
    isPresentation ? null : sustainableColumn,
    { key: 'gradeName', header: 'Grade', className: 'xxsmall', cellStyle: commonCellStyle },
    { key: 'season', header: 'Season', className: 'xxsmall', cellStyle: commonCellStyle },
    ...limit,
    ...limit_without_remaining,
    ...paymentTerms,
    ...status,
    isPresentation ? sustainableColumn : null,
    priceColumn,
  ]);
};

export const getContractBidGlobalListingTableHeaders = tabType => {
  const commonHeadStyle = {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}
  return compact([
    { key: 'siteName', header: 'Site', className: 'small' },
    BIDS_TAB.CONTRACT_BIDS == tabType ? { key: 'buyer.companyName', header: 'Buyer', className: 'small'} : null,
    { key: 'commodityName', header: 'Commodity', className: 'xsmall', headStyle: commonHeadStyle },
    { key: 'gradeName', header: 'Grade', className: 'xxsmall'},
    { key: 'season', header: 'Season', className: 'xxsmall' },
    { key: 'limit', header: 'Limit', className: 'xxsmall', sortable: false, default: item => `${item.limit} ${item.priceUnit}` },
    { key: 'bought', header: 'Bought', className: 'xxsmall', sortable: false, default: item => `${item.limit-item.limitRemaining} ${item.priceUnit}` },
    { key: 'limitRemaining', header: 'Limit Remaining', className: 'xxsmall', sortable: false, default: item => `${item.limitRemaining} ${item.priceUnit}` },
    { key: 'price', header: 'Price', className: 'xxsmall', sortable: false, default: item => `${getCountryCurrency()} ${item.price}` },
    { key: 'deliveryStartDate', header: 'Delivery Start Date', className: 'small'},
    { key: 'deliveryEndDate', header: 'Delivery End Date', className: 'small'},
    BIDS_TAB.CONTRACT_BIDS != tabType ? { key: 'group.name', header: 'Group', className: 'xxsmall'} : null,
    BIDS_TAB.CONTRACT_BIDS != tabType ? { key: 'startDateTime', header: 'Start Date', className: 'small'} : null,
    BIDS_TAB.CONTRACT_BIDS != tabType ? { key: 'endDateTime', header: 'End Date', className: 'small' } : null,
  ]);
}


export const TITLE_TRANSFER_CONTRACT_TYPE_FILTER_STATUSES = [
  { id: 'cashed_transfers', name: 'Cashed Transfers' },
  { id: 'contract', name: 'Contract Linked Transfers' },
  { id: 'external', name: 'External Contract transfers' },
];

export const TITLE_TRANSFER_FILTER_STATUSES = [
  ['planned', 'Action Pending/Awaiting Confirmation'],
  ['invoiced', 'Invoiced'],
  ['void', 'Void'],
  ['completed', 'Completed'],
];


export const WEIGH_BRIDGE_COMPANY_LIST = [
  { id: 'rinstrum', name: 'Rinstrum' },
  { id: 'mettler', name: 'Mettler' }
];

export const OTHERS_CANNOT_CREATE = 'others_cannot_create'
export const OTHERS_CAN_CREATE_BUT_REQUIRES_ACCEPTANCE= 'others_can_create_but_requires_acceptance'

export const CONTRACT_CREATION_RESTRICTIONS = [
  { id: "others_can_create_without_acceptance", name: "Others can create without acceptance" },
  { id: OTHERS_CAN_CREATE_BUT_REQUIRES_ACCEPTANCE, name: "Others can create but requires acceptance" },
  { id: OTHERS_CANNOT_CREATE, name: "Others cannot create" }
]

export const VARIETY_MANDATORY_LOAD_OPTIONS = [
  {id: 'inload', name: 'Inload'},
  {id: 'inload_and_outload', name: 'Inload and Outload'},
  {id: 'title_transfers_and_cash_outs', name: 'Title Transfers and Cash Outs'}
]

export const VARIETY_MANDATORY_USER_OPTIONS = [
  {id: 'growers', name: 'Growers'},
  {id: 'non_growers', name: 'Non Growers'},
]

export const WAREHOUSE_INLOAD_FEES_CHARGED_AT = [ //same fields as in DB
  {id: 'inload', name: 'Inload'},
  {id: 'outload', name: 'Outload'},
  {id: 'outload/transfer', name: 'Outload/Transfer(whichever earlier)'},
  {id: 'outload_or_transfer_until_date', name: 'Outload/Transfer until a specified date'},
];

export const XERO_INVOICE_STATUSES = [ //same fields as in DB
  {id: 'DRAFT', name: 'Draft'},
  {id: 'SUBMITTED', name: 'Awaiting Approval'},
  {id: 'AUTHORISED', name: 'Awaiting Payment'},
];

export const LOAD_TYPE_OPTIONS = [
  { id: 'title_transfers' , name:'Title Transfers' },
  { id: 'freight_movements' , name:'Freight Movements' },
  { id: 'direct_loads' , name:'Direct Loads ' },
  { id: 'shrinkages' , name:'Shrinkages' },
  { id: 'stock_updates' , name:'Stock Updates' },
  { id: 'stock_empty' , name:'Stock Empty' },
  { id: 'storage_stock_updates' , name:'Storage Stock Updates' },
  { id: 'storage_empty' , name:'Storage Empty' },
  { id: 'storage_transfers' , name:'Storage Transfers' },
  { id: 'regrades' , name:'Regrades/Reseasons' },
  { id: 'stock_swaps' , name:'Stock Swaps' }
]

export const STOCK_SWAP = -4;
export const DUPLICATE_BUSINESS_NAME_ERROR = "Company with this business name already exists";

export const TITLE_TRANSFER_HEADERS = [
  { key: 'identifier', header: 'Title Transfer No.', className: 'small' },
  {
    urlKey: 'contractUrl',
    header: 'Contract No.',
    fieldType: 'url-conditional',
    link: true,
    key: 'contractNumber',
    className: 'xsmall'
  },
  { key: 'status', header: 'Status', className: 'xsmall capitalize' },
  { key: 'sellerName', header: 'Seller', className: 'xsmall' },
  { key: 'buyerName', header: 'Buyer', className: 'xsmall' },
  { key: 'processOn', header: 'Transfer Date', className: 'small' },
  { key: 'siteDisplayName', header: 'Site', className: 'small'},
  { key: 'commodityName', header: 'Commodity', className: 'xsmall' },
  { key: 'gradeName', header: 'Grade', className: 'xsmall', sortable: false },
  { key: 'varietyName', header: 'Variety', className: 'xsmall' },
  { key: 'tonnageDisplayValue', header: 'Quantity', className: 'xsmall', sortable: false },
  { key: 'sustainable', header: 'Sustainable', className: 'xsmall', sortable: false },
];

export const CONTRACT_TABLE_COLUMN_LIMIT = 15;
export const DEFAULT_CONTRACT_TABLE_COLUMN_LIMIT = 15;
export const ORDERS_TABLE_COLUMN_LIMIT = 13;
export const DEFAULT_ORDERS_TABLE_COLUMN_LIMIT = 12;
export const FREIGHT_MOVEMENTS_TABLE_COLUMN_LIMIT = 14;
export const DEFAULT_FREIGHT_MOVEMENTS_TABLE_COLUMN_LIMIT = 13;
export const TITLE_TRANSFER_TABLE_COLUMN_LIMIT = 13;
export const DEFAULT_TITLE_TRANSFER_TABLE_COLUMN_LIMIT = 11;
export const INVOICES_TABLE_COLUMN_LIMIT = 13;
export const DEFAULT_INVOICES_TABLE_COLUMN_LIMIT = 13;
export const FREIGHT_ALLOCATION_TABLE_COLUMN_LIMIT = 12;

export const MIN_SEARCH_LENGTH = 3

const iconStyle = {width: '20px'};

const INVOICE_ITEM_COLUMNS = [
  {
    key: 'ref',
    header: 'Ref',
    className: 'msmall',
    fieldType: 'url-conditional',
    urlKey:'itemUrl',
    link: true
  },
  {
    key: 'loadRef',
    header: 'Load Ref',
    className: 'msmall',
  },
  {
    key: 'contract',
    fieldType: 'url-conditional',
    urlKey:'contractUrl',
    header: 'Contract',
    className: 'msmall',
    link: true
  },
  {
    urlKey: 'invoiceUrl',
    header: 'Invoice',
    fieldType: 'url-conditional',
    link: true,
    key: 'confirmedInvoice',
    className: 'msmall'
  },
  { key: 'contractInvoicing', header: 'Contract Invoicing', className: 'small'},
  { key: 'description', header: 'Description', className: 'small' },
  { key: 'paymentDue', header: 'Payment Due', className: 'xsmall' },
  { key: 'tonnageDisplayName', header: () => getCountryLabel('tonnage'), className: 'xxsmall' },
  { key: 'rateDisplayName', header: 'Rate', className: 'xxsmall' },
  { key: 'carry', header: 'Carry', className: 'xxsmall', type: 'currency'},
  { key: 'epr', header: () => `EPR (Ex ${getCountryLabel('gst')})`, className: 'xsmall', isColumnEditable: true, type: 'currency', editableFieldType: 'number' },
  { key: 'subTotal', header: () => `Price (Ex ${getCountryLabel('gst')})`, className: 'xxsmall' },
  { key: 'gst', header: () => `${getCountryLabel('gst')}`, className: 'xxsmall' },
  { key: 'total', header: () => `Total (Inc ${getCountryLabel('gst')})`, className: 'xxsmall' },
];

export const PAYABLE_INVOICE_ITEMS_COLUMNS = [
  {
    key: 'sellerNgrDetails.isVerified',
    header: 'NGR Verified',
    cellStyle: iconStyle,
    sortable: false,
    formatter: item => item.sellerNgrDetails ?
      item.sellerNgrDetails.isVerified ? <YesIcon style={{color: PRIMARY_COLOR_GREEN}} /> : <YesIcon style={{color: LIGHT_GREY}}/> : 'False'
  },
  ...INVOICE_ITEM_COLUMNS
];

export const RECEIVABLE_INVOICE_ITEMS_COLUMNS = [...INVOICE_ITEM_COLUMNS];

export const PAYMENT_RUN_TABLE_HEADERS = [
  { key: 'identifier', header: 'Payment Run No.', className: 'small capitalize' },
  { key: 'statusDisplay', header: 'Status', className: 'medium' },
  { key: 'tonnage', header: () => getCountryLabel('tonnage'), className: 'msmall' },
  { key: 'createdAt', header: 'Creation Date', className: 'medium' },
  { key: 'paymentDue', header: 'Payment Due Date', className: 'medium' },
  { key: 'bankProcessingDate', header: 'Bank Processing Date', className: 'medium' },
  { key: 'total', header: () => `Total Payable (Inc ${getCountryLabel('gst')})`, className: 'small' },
  { key: 'totalPaid', header: 'Total Paid', className: 'small', type: 'currency' },
]

export const TRANSACTION_TYPES = [
  { id: 'contract', name: 'Contract' },
];

export const TRANSACTION_TYPE_MAPPER = {
  'contract': 'contract',
}


export const BSB_MISMATCH_ERROR = () => `${getCountryLabel('bsb')} Number for selected Bank does not match`;

export const CONTRACT_ALLOCATION_MOVEMENT_HEADERS = [
  'saleContract',
  'purchaseContract',
  'saleContractPricePoint',
  'purchaseContractPricePoint',
  'purchaseContractInvoicing',
  'saleContractInvoicing',
  'freightInvoiced',
  'warehouseInvoiced'
];

export const ZENDESK_KEY = '5222da11-c694-4561-be9a-81280beec33e';

export const AUSTRALIA_COUNTRY_ID = 1;
export const USA_COUNTRY_ID = 2;

export const SEARCH_BY_FREIGHT_PROVIDER_REGOS = 'freight_provider_regos'
export const SEARCH_BY_ALL_REGOS = 'all_regos'
export const SEARCH_BY_TRUCK_OWNER_REGOS = 'truck_owner_regos'

export const TRUCK_SEARCH_OPTIONS = [
  { id: SEARCH_BY_FREIGHT_PROVIDER_REGOS, name: 'Freight Provider' },
  { id: SEARCH_BY_ALL_REGOS, name: `All Regos`},
  { id: SEARCH_BY_TRUCK_OWNER_REGOS, name: 'Truck Owner' },
]

export const NOTICE_NUMBERS = [
  {id: 'N', name: 'N'},
  {id: '19', name: 'N 19'},
  {id: '20', name: 'N 20'},
  {id: '23', name: 'N 23'},
]

export const PREDEFINED_DATE_RANGE_FILTERS = [
  {id: 'custom', name: 'Custom', secondaryText: 'Any Date Range'},
  {id: 'today', name: 'Today', secondaryText: 'Ongoing Day'},
  {id: 'yesterday', name: 'Yesterday', secondaryText: 'Previous Day'},
  {id: 'current_week', name: 'Current Week', secondaryText: 'Ongoing Week'},
  {id: 'last_week', name: 'Last Week', secondaryText: 'Previous Week'},
  {id: 'last_7_days', name: 'Last 7 days', secondaryText: 'Previous 7 days including current day'},
  {id: 'last_10_days', name: 'Last 10 days', secondaryText: 'Previous 10 days including current day'},
  {id: 'last_15_days', name: 'Last 15 days', secondaryText: 'Previous 15 days including current day'},
  {id: 'last_30_days', name: 'Last 30 days', secondaryText: 'Previous 30 days including current day'},
  {id: 'current_month', name: 'Current Month', secondaryText: 'Ongoing month'},
  {id: 'last_month', name: 'Last Month', secondaryText: 'Previous month'},
  {id: 'current_quarter', name: 'Current Quarter', secondaryText: 'Ongoing quarter'},
  {id: 'last_quarter', name: 'Last Quarter', secondaryText: 'Previous quarter'},
  {id: 'current_year', name: 'Current Year', secondaryText: 'Ongoing Year'},
  {id: 'last_year', name: 'Last Year', secondaryText: 'Previous Year'}
];


export const FREIGHT_RECEIVABLE_ITEM_COLUMNS = [
  {
    key: 'ref',
    header: 'Ref',
    className: 'msmall',
    fieldType: 'url-conditional',
    urlKey:'itemUrl',
    link: true
  },
  {
    key: 'freightOrder',
    fieldType: 'url-conditional',
    urlKey:'freightOrderUrl',
    header: 'Order',
    className: 'msmall',
    link: true
  },
  { key: 'freightOrderInvoicing', header: 'Order Invoicing', className: 'small'},
  { key: 'description', header: 'Description', className: 'small'},
  { key: 'pickupSite', header: 'Pickup Site', className: 'small' },
  { key: 'deliverySite', header: 'Delivery Site', className: 'medium' },
  { key: 'paymentDue', header: 'Payment Due', className: 'xsmall' },
  { key: 'tonnageDisplayName', header: () => getCountryLabel('tonnage'), className: 'xxsmall' },
  { key: 'freightRate', header: 'Freight Rate', className: 'xxsmall', type: 'currency' },
  { key: 'oversRate', header: 'Overs Rate', className: 'xxsmall', type: 'currency'},
  { key: 'subTotal', header: () => `Price (Ex ${getCountryLabel('gst')})`, className: 'xxsmall' },
  { key: 'gst', header: () => `${getCountryLabel('gst')}`, className: 'xxsmall' },
  { key: 'total', header: () => `Total (Inc ${getCountryLabel('gst')})`, className: 'xxsmall' },
];

export const PREDEFINED_DATE_RANGE_FILTER_KEYS = [
  'load_date_range', 'delivery_start_date_range', 'delivery_end_date_range', 'updated_at_date_range',
  'freight_pickup_date_range', 'freight_delivery_date_range', 'outload_date_range', 'inload_date_range',
  'updated_at_date_range', 'pack_by_date_range', 'process_on_date_range', 'payment_due_date_range'
]

export const FILTER_KEYS_TO_EXCLUDE = [
  'delivery_start_date__gte', 'delivery_end_date__lte', 'updated_at__gte', 'updated_at__lte', 'freight_pickup__date_time__gte',
  'freight_delivery__date_time__lte', 'freight_pickup__date_time__lte', 'freight_delivery__date_time__gte',
  'outload__date_time__gte', 'outload__date_time__lte', 'inload__date_time__gte', 'inload__date_time__lte',
  'pack__load__date_time__gte', 'pack__load__date_time__lte', 'process_on__gte', 'process_on__lte', 'payment_due_date__gte',
  'payment_due_date__lte', 'created_at__gte', 'created_at__lte', 'date_range_filters', 'min_custom_date', 'max_custom_date'
]

export const ORDER_FILTER_KEYS_MAPPING = {
  'delivery_start_date_range': ['freight_pickup__date_time__gte'],
  'delivery_end_date_range': ['freight_delivery__date_time__lte'],
  'updated_at_date_range': ['updated_at__gte', 'updated_at__lte']
}

export const INVOICE_FILTER_KEYS_MAPPING = {
  'payment_due_date_range': ['payment_due_date__gte', 'payment_due_date__lte'],
  'updated_at_date_range': ['updated_at__gte', 'updated_at__lte']
}

export const THROUGH_WAREHOUSE_ALLOCATION = 'through_warehouse';
export const DIRECT_TO_BUYER_ALLOCATION = 'direct_to_buyer';
export const STOCK_ALLOCATION = 'stock';
export const ALL_ALLOCATIONS = 'all_of_the_above';

export const THROUGH_WAREHOUSE_ALLOCATION_DISPLAY_NAME = 'Through Warehouse';
export const DIRECT_TO_BUYER_ALLOCATION_DISPLAY_NAME = 'Direct To Buyer';

export const CONTRACT_ALLOCATION_TYPES = [
  {'id': 'through_warehouse', 'name': THROUGH_WAREHOUSE_ALLOCATION_DISPLAY_NAME},
  {'id': 'direct_to_buyer', 'name': DIRECT_TO_BUYER_ALLOCATION_DISPLAY_NAME},
  {'id': 'stock', 'name': 'Stock Allocation'}
]

export const UNIT_ABBREVIATIONS = {
  [MT_UNIT]: MT_UNIT,
  [BALES_UNIT]: BALES_UNIT,
  [METER_CUBE_UNIT]: METER_CUBE_UNIT,
  [BUSHEL_UNIT]: BUSHEL_UNIT,
  [POUND_UNIT]: POUND_UNIT,
  [CENTURY_TONNE_UNIT]: CENTURY_TONNE_UNIT,
  [KG_UNIT]: KG_UNIT,
  [LITRE_UNIT]: LITRE_UNIT_ABBREVIATION
}

const CONTRACT_DOCUMENT_TYPE_ID = 12;
const SALES_CONFIRMATION_DOCUMENT_TYPE_ID = 14;
const BROKER_NOTE_DOCUMENT_TYPE_ID = 13;

export const COMPANY_TYPE_DOCUMENT_TYPE_MAPPING = {
  [GROWER]: [CONTRACT_DOCUMENT_TYPE_ID, SALES_CONFIRMATION_DOCUMENT_TYPE_ID],
  [BROKER]: [CONTRACT_DOCUMENT_TYPE_ID, SALES_CONFIRMATION_DOCUMENT_TYPE_ID, BROKER_NOTE_DOCUMENT_TYPE_ID],
  [LOGISTIC]: [CONTRACT_DOCUMENT_TYPE_ID, SALES_CONFIRMATION_DOCUMENT_TYPE_ID],
  [BHC_TYPE_ID]: [CONTRACT_DOCUMENT_TYPE_ID, SALES_CONFIRMATION_DOCUMENT_TYPE_ID],
  [TRADER]: [CONTRACT_DOCUMENT_TYPE_ID, SALES_CONFIRMATION_DOCUMENT_TYPE_ID],
  [SYSTEM]: [],
}

export const DUPLICATE_OPTION_KEYS = ['duplicate', 'void_and_duplicate']

export const TRANSPORT_MODE_TRUCK = 'Truck'
export const TRANSPORT_MODE_WAGON = 'Wagon'
export const TRANSPORT_MODES = [TRANSPORT_MODE_TRUCK, TRANSPORT_MODE_WAGON]

export const ORDER_TYPE_TITLE_MAPPING = {
  [CALL_ON_GRAIN_TYPE_ID]: "Grain Order",
  [DELIVERY_REQUEST_ORDER_TYPE_ID]: "Delivery Request Order",
  [PICKUP_REQUEST_ORDER_TYPE_ID]: "Pickup Request Order",
  [PACK_ORDER_TYPE_ID]: "Pack Order"
}

export const ORDER_TYPE_ROUTE_MAPPING = {
  [CALL_ON_GRAIN_TYPE_ID]: "grain",
  [DELIVERY_REQUEST_ORDER_TYPE_ID]: "requests",
  [PICKUP_REQUEST_ORDER_TYPE_ID]: "requests",
  [PACK_ORDER_TYPE_ID]: "packing"
}
