import React from 'react';
import { connect } from 'react-redux';
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Dialog, DialogActions, DialogContent, Tooltip, DialogTitle, Checkbox } from '@mui/material';
import { get, isEmpty, find, reject, set, map, filter, includes } from 'lodash';
import APIService from '../../services/APIService';
import { currentUserCompany, getCountryFormats, attachCSVEventListener, defaultViewAction } from '../../common/utils';
import { forceStopLoader, isLoading, setDownloadBar } from '../../actions/main';
import InvoicePayableTable from '../../containers/InvoicePayableTable';
import { FILTER_KEYS_TO_EXCLUDE, FREIGHT_RECEIVABLE_ITEM_COLUMNS, INVOICE_FILTER_KEYS_MAPPING, PREDEFINED_DATE_RANGE_FILTER_KEYS } from '../../common/constants';
import IconButton from '@mui/material/IconButton';
import Create from '@mui/icons-material/Create';
import moment from 'moment';
import {
  Event as DueDateIcon,
  AssignmentTurnedIn as CreateIcon,
  MarkEmailRead as SendIcon,
} from '@mui/icons-material';
import CommonDatePicker from '../common/CommonDatePicker';
import alertifyjs from 'alertifyjs';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import EmailAutocomplete from '../common/autocomplete/EmailAutocomplete';
import CommonListingButton from '../common/CommonListingButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import InvoiceFilters from '../common/InvoiceFilters';

const CUSTOM_HEADER_EDIT = {
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer',
  marginLeft: '10px'
};

const OPTIONS = {
  'create': {
    title: 'Create Invoices',
  },
  'createSend': {
    title: 'Create and Send Invoices',
  },
  'dueDate': {
    title: 'Update Payment Due Date',
  }
}

const DEFAULT_FILTERS = {
  'payer__company_id__in': '',
  'freight_order__commodity__id__in': '',
  'freight_order__invoicing__in': '',
  'payment_due_date_range': '',
  'payment_due_date__gte': '',
  'payment_due_date__lte': ''
}

class FreightInvoiceReceivable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceItems: [],
      isFetchingInvoices: false,
      companyDirectory: [],
      invoiceItemsGroupedByPayer: [],
      isFetchingCompanyDirectory: false,
      selectedInvoiceItems: [],
      openBulkActions: false,
      selectedBulkOption: undefined,
      openDialog: false,
      dueDate: undefined,
      selectedRecipients: undefined,
      recipientsDialogOpen: false,
      sellerEmployeeData: undefined,
      showRecipientsError: false,
      csvPopup: false,
      isFilteredCsv: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      customColumnTitle: undefined,
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
    }
  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.fetchInvoices();
    APIService.profiles()
    .filters('freight_invoice_receivable')
    .get()
    .then(res => {
      this.setState({
        filters: get(res, 'freight_invoice_receivable') || DEFAULT_FILTERS,
      });
    });
  }

  onDownloadResponse(message) {
    this.props.dispatch(setDownloadBar(message, true, this.onCloseDownloadResponse));
  }

  _attachCSVEventListener() {
    attachCSVEventListener('freight_receivables-csv-ready', 'Freight Invoice Receivables', this.onDownloadResponse);
  }

  fetchInvoices() {
    this.props.dispatch(isLoading('LoadingReceivables'))
    if(!this.state.isFetchingInvoices) {
      this.setState({isFetchingInvoices: true}, async () => {
        const company = currentUserCompany();
        APIService.invoices().appendToUrl(`freight-receivable-items/?company_id=${company.id}`).get().then(response =>
          this.setState({invoiceItems: response, isFetchingInvoices: false}, () => {
            if(isEmpty(this.state.companyDirectory))
              this.getCompanyDirectory()
            else
              this.setReceivableItems();
            this.props.dispatch(forceStopLoader())
          }));
      })
    }
  }

  toFormattedDate = date => {
    if(date) {
      const mDate = moment(date)
      return mDate.isValid() ? mDate.format(getCountryFormats().date) : date
    }
    return date
  }

  setReceivableItems() {
    const {invoiceItems} = this.state
    if (!isEmpty(invoiceItems) && !isEmpty(this.state.companyDirectory)) {
      map(invoiceItems, obj => {
        let payee = find(this.state.companyDirectory, {id: obj.companyId});
        obj.payerName = get(payee, 'name');
        obj.allSelected = false;
        if (obj.recipients && !isEmpty(obj.recipients)) {
          let recipientDisplayStr = "";
          map(obj.recipients, recipient => {
            if (isEmpty(recipientDisplayStr))
              recipientDisplayStr = recipientDisplayStr + recipient.email;
            else
              recipientDisplayStr = recipientDisplayStr + ", " + recipient.email;
          });
          obj['recipientDisplay'] = 'Recipients: (' + recipientDisplayStr + ')';
        }
        map(obj.items, invoiceItem => {
          invoiceItem.paymentDue = this.toFormattedDate(invoiceItem.paymentDue)
        })
      })
      this.setState({invoiceItemsGroupedByPayer: invoiceItems});
    }
  }

  getCompanyDirectory() {
    if(!this.state.isFetchingCompanyDirectory)
      this.setState({isFetchingCompanyDirectory: true}, async () => {
        APIService.companies().appendToUrl('directory/names/?excludeGroups=true').get().then(companies =>
          this.setState({companyDirectory: companies, isFetchingCompanyDirectory: false}, this.setReceivableItems));
      })
  }

  isSelected = invoiceItem => Boolean(find(this.state.selectedInvoiceItems, {id: invoiceItem.id}))

  updateSelectedInvoiceItem = (selected, isChecked, obj) => {
    const newState = {...this.state};
    newState.selectedInvoiceItems = isChecked ? [...this.state.selectedInvoiceItems, selected] : reject(this.state.selectedInvoiceItems, {id: selected.id});
    let allPresent = obj.items.every(item =>newState.selectedInvoiceItems.some(selectedItem => selectedItem.id === item.id));
    var index = this.state.invoiceItemsGroupedByPayer.findIndex(item => item.companyId === obj.companyId);
    set(newState, `invoiceItemsGroupedByPayer.${index}.allSelected`, allPresent);
    this.setState(newState);
  }

  onSelectAllToggle = (event, value, obj) => {
    const newState = {...this.state};
    var index = this.state.invoiceItemsGroupedByPayer.findIndex(item => item.companyId === obj.companyId);
    set(newState, `invoiceItemsGroupedByPayer.${index}.allSelected`, value);
    if (value)
      newState.selectedInvoiceItems = [...this.state.selectedInvoiceItems, ...obj.items];
    else {
      let itemIds = map(obj.items, 'id');
      newState.selectedInvoiceItems = this.state.selectedInvoiceItems.filter(item => !itemIds.includes(item.id));
    }
    this.setState(newState);
  }

  handleEmailRecipients(companyId) {
    var index = this.state.invoiceItemsGroupedByPayer.findIndex(item => item.companyId === companyId);
    const selectedRecipients = get(this.state.invoiceItemsGroupedByPayer[index], 'recipients');
    this.setState({selectedRecipients: selectedRecipients})
    APIService
      .profiles()
      .appendToUrl('employees-signature/')
      .get(null, null, {company_ids: companyId})
      .then(employees => {
        const contactsWithEmail = map(filter(employees, employee => employee.email), employee => ({...employee, title: `${employee.name} (${employee.email})`, value: employee.email}))
        this.setState({sellerEmployeeData: contactsWithEmail, recipientsDialogOpen: true})
      })
  }

  toggleBulkActions = event => this.setState(
    {openBulkActions: this.state.openBulkActions ? false : event.currentTarget}
  )

  onBulkActionClick = event => this.setState({openDialog: true, openBulkActions: false, selectedBulkOption: event.currentTarget.id})

  onDialogClose = () => this.setState({recipientsDialogOpen: false});

  onPaymentDueDateChange = dueDate => this.setState({dueDate: dueDate});

  onBulkInvoiceClose = () => this.setState({openDialog: false, selectedBulkOption: false});

  onRecipientsChange = (event, selected) => {
    if (selected && selected.length <= 3)
      this.setState({showRecipientsError: false});
    this.setState({selectedRecipients: selected});
  }

  generateInvoices = noMail => {
    this.props.dispatch(isLoading('bulkGenerateReceivableInvoices'))
    const xeroEnabled = get(this.props, 'currentUser.company.xeroEnabled');
    let data = {
      'invoiceItems': [...new Set(this.state.selectedInvoiceItems.map(item => item.id))],
      'noMail': noMail,
    };
    if (xeroEnabled)
      data['communication'] = {'createXeroInvoice': true};
    APIService.invoices()
    .appendToUrl('freight-receivable-generate/')
    .post(data)
    .then(() => {
      alertifyjs.success("Receivables Invoice created successfully");
      this.onBulkInvoiceClose()
      this.fetchInvoices()
      window.location.reload()
    });
  }

  onSubmit = () => {
    const { dueDate, selectedBulkOption, selectedInvoiceItems } = this.state
    if(selectedBulkOption === 'create')
      this.generateInvoices(true)
    else if(selectedBulkOption === 'createSend')
      this.generateInvoices(false)
    else if(selectedBulkOption === 'dueDate')
      APIService.invoices().appendToUrl('web/').put({invoice_ids: map(selectedInvoiceItems, 'invoiceId'), payment_due_date: dueDate}).then(() => {
        this.fetchInvoices()
        this.onBulkInvoiceClose()
      })
  }

  submitEmailRecipients = () => {
    if (this.state.selectedRecipients.length > 3) {
      this.setState({showRecipientsError: true});
    }
    else {
      this.setState({recipientsDialogOpen: false, showRecipientsError: false});
      const companyId = get(this.state.selectedRecipients, '0.companyId');
      var index = this.state.invoiceItemsGroupedByPayer.findIndex(item => item.companyId === companyId);
      var invoiceIds = map(this.state.invoiceItemsGroupedByPayer[index].items, item => item.invoiceId);
      const data = {
        'selectedRecipients': this.state.selectedRecipients,
        'invoiceIds': invoiceIds,
      }
      APIService
        .invoices()
        .appendToUrl('email-recipients/')
        .put(data)
        .then(() => window.location.reload());
    }
  }

  fetchCSVData = (callback) => {
    const company = currentUserCompany();
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.customColumns) {
      param = param.length == 0 ? param + 'custom_csv' : param + '&custom_csv';
    }
    this.props.dispatch(setDownloadBar('Your Freight Receivables CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true))
    const service = APIService.invoices();
    service.appendToUrl(`freight-receivables/csv/?company_id=${company.id}&${param}`);
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(() => this.setState({csvPopup: false}));
    if (callback) {
      callback();
    }
  };

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/freight_invoice_receivables_csv/`).get();
    this.setState({customColumnNames: columnNames, customHeaderOptions: true});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters' && value) {
        const { filters } = this.state;
        delete filters['commodity__id__in'];
        APIService
          .profiles()
          .filters()
          .post({ freight_invoice_receivable: filters }, this.props.token)
          .then(res => {
            this.setState({filters: get(res, 'filters.freight_invoice_receivable', {})}, () => {
              this.props.dispatch(isLoading());
              this.fetchInvoices();
            })
          });
      }
    });
  };

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(INVOICE_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  render() {
    const {
      invoiceItemsGroupedByPayer, selectedInvoiceItems, openBulkActions, openDialog, selectedBulkOption,
      dueDate, recipientsDialogOpen, selectedRecipients, sellerEmployeeData
    } = this.state
    const selectedCount = selectedInvoiceItems?.length || 0;
    const isDueDateOption = selectedBulkOption === 'dueDate'
    const isCreateOption = selectedBulkOption === 'create'
    const isCreateAndSendOption = selectedBulkOption === 'createSend'
    const today = moment()
    return (
      <Paper className="paper-table">
        <div>
          <span style={{ paddingLeft: '10px', fontWeight: 'bold', fontSize: '20px', marginTop: '8px' }}>
            Freight Receivable
          </span>

          <CommonListingButton
            defaultHandler={() => this.customCsvEnabled(false)}
            showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => get(val, '1.length', 0) !== 0))}
            optionMapper={[
              { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
              { name: 'Filtered List', fx: () => this.customCsvEnabled(true) },
            ]}
            title='Download Contents of the table in a CSV'
            name='Export'
          />
          <Tooltip title='Apply filters' placement='top'>
            <Button
              value={this.state.applyFilters}
              variant="contained"
              type='button'
              onClick={() => this.handleFilters(true)}
              color='primary'
              className='add-button'
              style={{ float: 'right', marginLeft: '10px' }}
            >
              <FilterListIcon style={{ paddingRight: '5px' }} />
              FILTERS{' '}
              {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                : ''}
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            type='button'
            color='primary'
            disabled={selectedCount === 0}
            onClick={this.toggleBulkActions}
            style={{ float: 'right', marginLeft: '10px' }}
          >
            Action Selected Invoices
          </Button>
          <CommonListingButton
            showMenus
            showDownLoadIcon={false}
            optionMapper={this.getActionsOptionMapperListItems()}
            title='Actions'
            name='Actions'
          />
          <Dialog open={this.state.csvPopup} onClose={() => this.setState({csvPopup: false})} aria-labelledby='form-dialog-title' fullWidth>
            <DialogTitleWithCloseIcon
              onClose={() => this.setState({csvPopup: false})}
              closeButtonStyle={{ marginTop: '0px' }}
              id='form-dialog-title'>
              Download Freight Invoice Receivable Data
            </DialogTitleWithCloseIcon>
            <DialogContent style={{ marginTop: '15px' }}>
              <span style={{float: 'left'}}>Select checkbox for custom csv download</span>
              <div className='col-sm-6 padding-reset' style={{marginTop: '10px'}}>
                <Checkbox
                  id={'custom-headers'}
                  checked={this.state.customColumns}
                  style={{ height: '40px' }}
                  onChange={() => this.setState({customColumns: !this.state.customColumns})}
                />
                Custom Columns
                <a onClick={() => this.editCustomHeaderOptions()} hidden={!this.state.customColumns} style={CUSTOM_HEADER_EDIT}>View & Update</a>
              </div>
              <SideDrawer
                isOpen={this.state.customHeaderOptions}
                title={this.state.customColumnTitle}
                onClose={() => this.setState({customHeaderOptions: false})}
                size="small"
              >
                <CustomHeaderOptions
                  customColumns={this.state.customColumnNames}
                  closeDrawer={() => this.setState({customHeaderOptions: false})}
                  user={this.props.currentUser}
                  csv_type="freight_invoice_receivables_csv"
                  updateColumnCount={(count) => this.updateColumnCount(count)}
                />
              </SideDrawer>
            </DialogContent>
            <DialogActions>
              <Button
                type='button'
                onClick={() => this.setState({csvPopup: false})}
                variant='outlined'>
                Cancel
              </Button>
              <Button type='button' onClick={() => this.fetchCSVData()} color='primary' variant='outlined'>
                Download
              </Button>
            </DialogActions>
          </Dialog>
          {this.state.applyFilters && (
            <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
              <InvoiceFilters
                handleFilterState={this.handleFilterState}
                filters={this.state.filters}
                freightReceivableInvoice={true}
              />
            </SideDrawer>
          )}
          <Divider light style={{ marginLeft: '-10px', marginRight: '-10px', marginTop: '20px' }} />
          {!isEmpty(invoiceItemsGroupedByPayer) &&
            map(invoiceItemsGroupedByPayer, (obj, index) => (
              <div key={index}>
                <div style={{ marginLeft: '-10px', marginRight: '-10px', background: '#F5F5F5', height: '50px' }}>
                  <span style={{display: 'inline-flex', alignItems: 'center'}}>
                    <span style={{ paddingLeft: '15px', fontSize: '20px', lineHeight: '50px' }}>{obj.payerName}</span>
                    {
                      get(obj, 'recipientDisplay') &&
                      <span style={{ paddingLeft: '15px', fontSize: '15px' }}>{get(obj, 'recipientDisplay')}</span>
                    }
                    <IconButton
                      onClick={() => this.handleEmailRecipients(obj.companyId)}
                      size="large">
                      <Create fontSize="small" />
                    </IconButton>
                  </span>
                </div>
                <div style={{ marginTop: '10px' }}>
                  <InvoicePayableTable
                    items={obj.items}
                    columns={[
                      { header: 'All', checkbox: true, className: 'xxsmall', onChange:(selected, isChecked) => this.updateSelectedInvoiceItem(selected, isChecked, obj), 
                      func: this.isSelected, selectAll: true, checked: obj.allSelected, onSelectAll:(event, value) => this.onSelectAllToggle(event, value, obj) },
                      ...FREIGHT_RECEIVABLE_ITEM_COLUMNS
                    ]}
                    isSubscriber={obj.isSubscriber}
                  />
                </div>
                {
                  recipientsDialogOpen &&
                  <Dialog open keepMounted fullWidth onClose={this.onDialogClose}>
                    <DialogTitleWithCloseIcon
                      onClose={this.onDialogClose}
                      closeButtonStyle={{ marginTop: '0px' }}
                      id='form-dialog-title'>
                      Update Recipients
                    </DialogTitleWithCloseIcon>
                    <DialogContent>
                      <React.Fragment>
                        <div>
                          {'Update Recipients'}
                        </div>
                        <div>
                          <EmailAutocomplete
                            options={sellerEmployeeData}
                            onChange={this.onRecipientsChange}
                            selected={selectedRecipients}
                          />
                        </div>
                        {
                          this.state.showRecipientsError &&
                          <div style={{ marginTop: '3px', color: '#FF0000', fontSize: '15px'}}>
                            You can select only 3 emails.
                          </div>
                        }
                      </React.Fragment>
                    </DialogContent>
                    <DialogActions>
                      <Button color='default' onClick={this.onDialogClose}>Cancel</Button>
                      <Button onClick={this.submitEmailRecipients}>Proceed</Button>
                    </DialogActions>
                  </Dialog>
                }
              </div>
            ))
          }
        </div>
        {
          Boolean(openBulkActions) &&
          <Menu
            id="actions-menu"
            anchorEl={openBulkActions}
            open
            onClose={this.toggleBulkActions}>
            <MenuItem onClick={this.onBulkActionClick} id='create'>
              <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                <CreateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Create</ListItemText>
            </MenuItem>
            <MenuItem onClick={this.onBulkActionClick} id='createSend'>
              <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                <SendIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Create & Send</ListItemText>
            </MenuItem>
            <MenuItem onClick={this.onBulkActionClick} id='dueDate'>
              <ListItemIcon style={{minWidth: 'unset', marginRight: '5px'}}>
                <DueDateIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Update Payment Due Date</ListItemText>
            </MenuItem>
          </Menu>
        }
        {
          openDialog &&
            <Dialog open keepMounted onClose={() => this.setState({openDialog: false})}>
              <DialogTitle>
                {OPTIONS[selectedBulkOption].title}
              </DialogTitle>
              <DialogContent>
                {
                  isDueDateOption &&
                  <div style={{marginTop: '10px'}}>
                    <CommonDatePicker
                      minDate={today.format('YYYY-MM-DD')}
                      label='Payment Due Date'
                      onChange={this.onPaymentDueDateChange}
                      value={dueDate}
                      variant='outlined'
                    />
                  </div>
                }
                {
                  isCreateOption &&
                  "Selected Invoice(s) will be created but emails will not be sent. Are you sure you want to proceed?"
                }
                {
                  isCreateAndSendOption &&
                  <React.Fragment>
                    <div>
                      {
                        `Selected Invoice(s) will be created and emails will be sent to corresponding recipients. Are you sure you want to proceed?`
                      }
                    </div>
                  </React.Fragment>
                }
              </DialogContent>
              <DialogActions>
                <Button color='default' onClick={this.onBulkInvoiceClose}>Cancel</Button>
                <Button disabled={isDueDateOption && !dueDate} onClick={this.onSubmit}>Proceed</Button>
              </DialogActions>
            </Dialog>
        }
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    currentUser: state.main.user.user,
  };
};

export default connect(mapStateToProps)(FreightInvoiceReceivable);
