import React from 'react';

import { connect } from 'react-redux';
import { DialogTitleWithCloseIcon } from '../../common/DialogTitleWithCloseIcon';
import CommonButton from '../../common/CommonButton';
import APIService from '../../../services/APIService';
import { Dialog, DialogActions, DialogContent, Autocomplete, TextField, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { get, debounce, uniqBy, set, forEach, has, isEmpty } from 'lodash';
import alertifyjs from 'alertifyjs';
import CommonAutoSelect from '../../common/autocomplete/CommonAutoSelect';
import { required, selected } from '../../../common/validators';

class CompanyAssignmentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompany: null,
      searchText: '',
      companies: [],
      isLoadingCompanies: false,
      customerContacts: null,
      customerNgrs: null,
      updateMovements: false,
      fields: {
        customerDetails: {
          contactId: {
            value: null,
            validators: [required(), selected()],
            errors: [],
          },
          ngrId: {
            value: null,
            validators: [required(), selected()],
            errors: [],
          },
        },
      }
    };
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
  }

  handleDialogClose = () => {
    this.props.onClose();
  };

  handleSubmit = () => {
    const { entityField, token, entityName, movementId } = this.props;
    if(this.state.selectedCompany) {
      const payload = {};
      payload[entityField] = this.state.selectedCompany.id;
      APIService
        .freights()
        .contracts(movementId)
        .appendToUrl('customer/')
        .put(payload, token)
        .then(data => {
          if(!data)
            alertifyjs.error('An Error Occurred!');
          if(data.errors)
            alertifyjs.error(`Error: ${data.errors}`);
          else {
            alertifyjs.success(`${entityName} updated successfully!`, 1, () => window.location.reload());
          }
        });
      this.handleDialogClose();
    } else alertifyjs.error(`Please select ${entityName}`, 5);
  };

  handleOrderCustomerSubmit = () => {
    this.setAllFieldsErrors();
    const { entityName, orderId } = this.props;
    if(this.state.selectedCompany) {
      const payload = {
        "customer": {
            "companyId": this.state.selectedCompany.id,
            "contactId": this.state.fields.customerDetails.contactId.value,
            "ngrId": this.state.fields.customerDetails.ngrId.value
        }
      }
      APIService
        .freights()
        .orders(orderId)
        .appendToUrl(`customer/?update_movements=${this.state.updateMovements}`)
        .put(payload)
        .then(data => {
          if(!data)
            alertifyjs.error('An Error Occurred!');
          if(data.errors)
            alertifyjs.error(`Error: ${data.errors}`);
          else {
            alertifyjs.success(`${entityName} updated successfully!`, 1, () => window.location.reload())
          }
        });
      this.handleDialogClose();
    } else alertifyjs.error(`Please select ${entityName} details`, 5);
  };

  fetchCompanies = debounce(searchStr => {
    APIService
      .companies()
      .appendToUrl(`global-search/${encodeURIComponent(encodeURIComponent(searchStr))}/`)
      .get(this.props.token)
      .then(data => {
        this.setState({companies: get(data, 'companies'), isLoadingCompanies: false});
      });
  }, 500);

  handleCompanyChange = (event, item) => {
    const { selectedCompany } = this.state;
    const text = get(item, 'name', '');
    if(!item || (item.id !== get(selectedCompany, 'id'))) {
      const newState = {...this.state};
      newState.searchText = text;
      newState.selectedCompany = item;
      newState.isLoadingCompanies = Boolean(item && this.state.isLoadingCompanies);
      const value = get(item, 'id');
      if (value && this.props.isOrderCustomer) {
        Promise.all([
          APIService.contracts().companies(value).contacts().get(this.props.unregToken),
          APIService.companies(value).ngrs().appendToUrl('minimal/').get(this.props.unregToken)
        ]).then(([contacts, items]) => {
          newState.customerContacts = uniqBy(contacts, 'id');
          newState.customerNgrs = uniqBy(items, 'id');
          this.setState(newState);
        });
      }
      this.setState(newState);
    }
  };

  handleCompanyInputChange = (event, value) => {
    this.setState({searchText: value, isLoadingCompanies: Boolean(value) }, () => {
      if(value && value.length > 2)
        this.fetchCompanies(value);
    });
  };

  isSearchableInput() {
    return get(this.state.searchText, 'length') > 2;
  }

  getFieldErrors(field) {
    if (has(field, 'value') && has(field, 'validators')) {
      field.errors = [];
      if (!this.state.resetAllFieldValues) {
        field.validators.forEach(validator => {
          if (validator.isInvalid(field.value)) {
            field.errors.push(validator.message);
          }
        });
      }
    }
    if (has(field, 'errors') && !isEmpty(field.errors)) {
      field.value = undefined;
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    this.applyValidatorsOn(newState.fields);
    this.applyValidatorsOn(newState.fields.customerDetails);
    this.setState(newState);
  }

  setFieldValue(id, value, callback) {
    const newState = { ...this.state };
    set(newState.fields, id + '.value', value);
    this.setState(newState, () => {
      if (callback) {
        callback();
      }
    });
  }

  setFieldErrors(id) {
    const newState = { ...this.state };
    this.getFieldErrors(get(newState.fields, id));
    this.setState(newState);
  }

  handleSelectFieldChange(value, id) {
    this.setFieldValue(id, value);
    this.setFieldErrors(id, value);
  }

  applyValidatorsOn(fields) {
    forEach(fields, field => {
      this.getFieldErrors(field);
    });
  }

  handleCheckboxClick = () => this.setState({updateMovements: !this.state.updateMovements});

  render() {
    const { entityName, isOrderCustomer } = this.props;
    const { isLoadingCompanies, companies, searchText } = this.state;
    const isSearchableInput = this.isSearchableInput();
    return (
      <Dialog fullWidth onClose={this.handleDialogClose} open={this.props.open}>
        <DialogTitleWithCloseIcon onClose={this.handleDialogClose} id="company-editor">
          {`Add/Edit ${entityName}`}
        </DialogTitleWithCloseIcon>
        <DialogContent style={{marginTop: '10px'}}>
          <Autocomplete
            id="company"
            blurOnSelect
            options={companies}
            getOptionLabel={option => option.name}
            loading={isLoadingCompanies}
            loadingText={isSearchableInput ? 'Loading...' : 'Type at least 3 characters to search'}
            noOptionsText={searchText ? "No results" : 'Start typing...'}
            renderInput={params => (
              <TextField
                {...params}
                value={searchText}
                      variant="standard"
                      placeholder={`${entityName} Name (Type at least 3 characters to search)`}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {
                              isLoadingCompanies &&
                              <CircularProgress color="inherit" size={20} />
                            }
                            { params.InputProps.endAdornment }
                          </React.Fragment>
                        ),
                      }}
                      fullWidth
              />
            )}
            value={get(this.state, 'selectedCompany')}
            onChange={this.handleCompanyChange}
            onInputChange={this.handleCompanyInputChange}
          />
          {isOrderCustomer ? <React.Fragment>
            <CommonAutoSelect
              items={this.state.customerContacts}
              id="customerDetails.contactId"
              label="Customer Contact"
              value={this.state.fields.customerDetails.contactId.value}
              onChange={this.handleSelectFieldChange}
              errorText={this.state.fields.customerDetails.contactId.errors[0]}
            />
            <CommonAutoSelect
              items={this.state.customerNgrs || []}
              dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
              id="customerDetails.ngrId"
              label="NGR"
              value={this.state.fields.customerDetails.ngrId.value}
              onChange={this.handleSelectFieldChange}
              errorText={this.state.fields.customerDetails.ngrId.errors[0]}
              dontAutoselectSingleItem={true}
            />
            <FormControlLabel
              control={
                <Checkbox
                    color="primary"
                    id="shouldUpdateMovements"
                    checked={this.state.updateMovements}
                    onChange={this.handleCheckboxClick}
                />
              }
              label="Update Movements"
            />
            </React.Fragment> : null}
        </DialogContent>
        <DialogActions>
          <CommonButton
            variant="contained"
            label="Cancel"
            secondary={true}
            onClick={this.handleDialogClose}
          />
          <CommonButton
            variant="contained"
            label="Save"
            primary={true}
            onClick={isOrderCustomer ? this.handleOrderCustomerSubmit : this.handleSubmit}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
  };
}

export default connect(mapStateToProps)(CompanyAssignmentDialog);
