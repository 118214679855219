import React from 'react';

import moment from 'moment';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import {
  Comment as CommentIcon, History as HistoryIcon, Error as ErrorIcon, CheckCircle as SuccessIcon
} from '@mui/icons-material';
import TruckSearchOption from '../common/TruckSearchOption';
import {
  TextField, IconButton, Dialog, DialogActions, DialogContent, Button, Checkbox, Divider,
  FormControlLabel, Tooltip, Badge, createFilterOptions
} from '@mui/material';
import {
  set, get, includes, snakeCase, compact, camelCase, isArray, filter, reject, find, forEach,
  mapValues, isEmpty, uniqBy, map, max, cloneDeep, some, times, flatten, isFunction, startCase,
  without, toNumber, pickBy, pull, orderBy, isString, min, keys, uniq, values, isEqual, every, isDate, isBoolean, capitalize, startsWith
} from 'lodash';
import CommonTextField from '../common/CommonTextField';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { required, valueAbove, maxValue } from '../../common/validators';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import CommodityMultiSelect from '../common/autocomplete/CommodityMultiSelect';
import GradeMultiSelect from '../common/autocomplete/GradeMultiSelect';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonSelect from '../common/select/CommonSelect';
import { isLoading, forceStopLoader, validateRego } from '../../actions/main';
import { getCompanyTrucks, createTruck } from '../../actions/api/trucks';
import { getCompanyEmployeesMinimal } from '../../actions/api/employees';
import { receiveTrucks, addTruck, emptyCreatedTruck } from '../../actions/companies/trucks';
import { receiveEmployees } from '../../actions/company-settings/employees';
import { bulkUpdateSlot, updateSlot, createSlots, deleteSlot } from '../../actions/api/company-sites';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonDatePickerUncontrolled from '../common/CommonDatePickerUncontrolled';
import CommonTimePicker from '../common/CommonTimePicker';
import { positiveDecimalFilter } from '../../common/input-filters';
import SeasonSelect from '../common/select/SeasonSelect';
import {
  INLOAD_COLOR,
  OUTLOAD_COLOR,
  DAYS_OF_WEEK,
  DAY,
  WEEK,
  CALENDAR_FREQUENCIES,
  MIDNIGHT,
  NEUTRAL_GRAY,
  PINK_NEON,
  GREEN_NEON,
  YELLOW_NEON,
  FREIGHT_CONTRACT_TYPE,
  TRUCK_CODES,
  WARNING_ORANGE,
  SEARCH_BY_FREIGHT_PROVIDER_REGOS,
  SEARCH_BY_ALL_REGOS,
  SEARCH_BY_TRUCK_OWNER_REGOS,
  NOTICE_NUMBERS,
  MT_UNIT,
  EMPTY_VALUE,
  UNIT_ABBREVIATIONS,
} from '../../common/constants';
import {
  dateTimeToUTC,
  getAutoSelectFocusField,
  isSystemCompany,
  isCurrentUserCompanyAdmin,
  generateIdentifier,
  getDateTimeInUTC,
  currentUserCompany,
  isSiteEmployee,
  getCountryFormats,
  getCountryLabel,
  getCountrySystemCompanyId,
  getCountryConfig,
  getCountryMovementDisplayUnit

} from '../../common/utils';
import CommonAutoSuggest from '../common/autocomplete/CommonAutoSuggest';
import RepeatSlot from './RepeatSlot';
import DeleteSlot from './DeleteSlot';
import SlotComments from './SlotComments';
import SlotHistory from './SlotHistory';
import PickupInfo from './PickupInfo';
import SlotVendorDec from './SlotVendorDec';
import { getSlotComments } from '../../actions/api/company-sites';
import APIService from '../../services/APIService';
import LoaderInline from '../LoaderInline';
import MovementIcon from '../common/icons/Movement';
import { receiveFreightSlotUpdated } from '../../actions/companies/company-sites';
import VendorDecsIcon from '../common/icons/VendorDecs';
import { create } from '../../actions/companies/freights'
import CommonSelectInput from '../common/CommonSelectInput';
import { Cancel as RemoveIcon } from '@mui/icons-material'
import AddButton from '../common/AddButton';

const NO_SLOTS_AVAILABLE = 'There are no slots available. Please contact site manager.'
const NO_SLOTS_AVAILABLE_SM = 'There are no slots available. Please create planned slots.'
const MAX_SLOTS = 1000;
const ORDER_GRADE_IN_SLOT_EXCLUDED_GRADE_MESSAGE = "Selected grade falls in this slot's Excluded Grade list. Select a different slot or change the order."
const ORDER_COMMODITY_IN_SLOT_EXCLUDED_COMMODITY_MESSAGE =  "Selected commodity falls in this slot's Excluded Commodity list. Select a different slot or change the order."

const autocompleteFilters = createFilterOptions();

class FreightSlotForm extends React.Component {
  constructor(props) {
    super(props);

    this.ENDS_ON = 'on';
    this.ENDS_AFTER = 'after';
    this.SINGLE_SLOT = 'Single Slot';
    this.MULTIPLE_SLOTS_SAME_DAY = 'Multiple Slots (same day)';
    this.MULTIPLE_SLOTS_ACROSS_DAYS = 'Multiple Slots (across days)';

    this.STATUSES = [
      { id: 'planned', name: 'Planned' },
      { id: 'booked', name: 'Booked' },
      { id: 'in_progress', name: 'In Progress' },
      { id: 'completed', name: 'Completed' },
      { id: 'delayed', name: 'Delayed' },
      { id: 'cancelled', name: 'Incomplete' },
      { id: 'rejected', name: 'Rejected' },
      { id: 'restricted', name: 'Restricted' },
    ];

    this.PITS = [
      { id: 'road', name: 'Road' },
      { id: 'rail', name: 'Rail' },
      { id: 'bulk', name: 'Bulk' },
      { id: 'liquid', name: 'Liquid' },
      { id: 'additive', name: 'Additive' },
      { id: 'flour', name: 'Flour' },
      { id: 'animal_nutrition', name: 'Animal Nutrition' },
      { id: 'meal', name: 'Meal' },
    ];

    this.TYPES = [
      { id: 'outload', name: 'Outload' },
      { id: 'inload', name: 'Inload' },
    ];

    this.FREQUENCIES = CALENDAR_FREQUENCIES;
    this.DAYS = DAYS_OF_WEEK;
    this.REPEAT_EVERY_OPTIONS = [
      { id: DAY, name: DAY },
      { id: WEEK, name: WEEK },
    ];

    this.RESTRICTED_CODES = [
      {id: 'R', name: 'R'},
      {id: 'R1', name: 'R1'},
      {id: 'R2', name: 'R2'},
      {id: 'R3', name: 'R3'},
    ]

    this.countryTonnageLabel = getCountryLabel('tonnage');
    this.ORDER_NOT_VALID_MESSAGE = 'This order is not valid';

    this.state = {
      duplicateSlotErrorTriggered: false,
      counterSlot: null,
      unit: getCountryConfig()?.truck?.unit || MT_UNIT,
      countryFormats: getCountryFormats(),
      allGrades: [],
      allCompanies: [],
      isFetchingTruckDetails: false,
      slotTruckDetails: {},
      massLimitCode: {
        value: '',
        errors: [],
        validators: [],
      },
      truckDetails: {},
      truckDetailsErrors: {},
      permits: [],
      categories: [],
      bothSitesOrderBookingOn: false,
      isOnlyValidatingCustomer: false,
      isPickupSiteUser: false,
      isDeliverySiteUser: false,
      counterSlotUpdatedAt: false,
      counterSlotSettingsUpdatedAt: false,
      counterSlotSiteId: '',
      isStatusChangedToCompletedForSlotWithTrailers: false,
      counterSlotSmSetting: null,
      deliveryTrailerSlots: [],
      pickupTrailerSlots: [],
      pickupSlot: null,
      deliverySlot: null,
      showVendorDecInfo: false,
      shouldShowVendorDec: false,
      vendorDecs: [],
      orderUnaccountedTonnage: 0,
      selectedOrder: null,
      orders: [],
      isFetchingUnaccountedTonnage: false,
      isFetchingOrders: false,
      isCheckingOrder: false,
      isFetchingOrderRefs: false,
      orderRefs: {},
      bookieTrucks: [],
      bookieDrivers: [],
      comment: undefined,
      commentError: '',
      showPickupInfo: false,
      showHistory: false,
      showComments: false,
      showDelete: false,
      repeatSelectValue: 1,
      frequencies: [],
      update: false,
      showDialog: this.props.showDialog,
      selectedGrade: null,
      selectedCommodity: null,
      selectedTruck: null,
      selectedDriver: null,
      selectedProvider: null,
      selectedSubProvider: null,
      repeat: false,
      repeatDetails: null,
      applySubFreightProvider: false,
      isReadonly: false,
      orderTonnageMap: {},
      pickupSite: undefined,
      deliverySite: undefined,
      pickupSlots: undefined,
      deliverySlots: undefined,
      pickupStartTime: undefined,
      pickupEndTime: undefined,
      pickupUtcOffsetMinutes: undefined,
      deliveryUtcOffsetMinutes: undefined,
      pickupTZAbbreviation: undefined,
      deliveryTZAbbreviation: undefined,
      warningText: '',
      permitChecked: false,
      accreditationNumberChecked: false,
      loadSharing: true,
      restrictedChecked: false,
      subFreightProviderCompanies: [],
      allDrivers: [],
      selectedOrderIdentifier: "",
      showProceedWithGivenOrderDialog: true,
      canUserAmend: false,
      isOrderIndependent: false,
      commodityContractId: undefined,
      contractNumber: undefined,
      pickupOrderId: undefined,
      pickupOrderIdentifier: undefined,
      deliveryOrderId: undefined,
      deliveryOrderIdentifier: undefined,
      parentOrderId: undefined,
      parentOrderIdentifier: undefined,
      maxAllowedTonnageForOrderAmend: undefined,
      canAmendRelatedEntity: false,
      reason: undefined,
      pickupSiteTrailers: {
        trailer1: {
          applied: false,
          slotId: undefined,
        },
        trailer2: {
          applied: false,
          slotId: undefined,
        },
        trailer3: {
          applied: false,
          slotId: undefined,
        }
      },
      deliverySiteTrailers: {
        trailer1: {
          applied: false,
          slotId: undefined,
        },
        trailer2: {
          applied: false,
          slotId: undefined,
        },
        trailer3: {
          applied: false,
          slotId: undefined,
        }
      },
      pickupSlotTrailerErrors: null,
      deliverySlotTrailerErrors: null,
      totalDistance: {
        value: null,
        validators: [],
        errors: [],
      },
      distanceInKm: {
        value: null,
        validators: [],
        errors: [],
      },
      estimatedTime: {
        value: null,
        validators: [],
        errors: [],
      },
      estimatedTimeInSeconds: 0,
      selectedPickupSlot: undefined,
      selectedDeliverySlot: undefined,
      orderStartDate: undefined,
      orderEndDate: undefined,
      multiSlots: [],
      pickupSlotsCache: {},
      deliverySlotsCache: {},
      multiSlotsBothSiteBooking: [],
      multiSlotsDates: {delivery: {}, pickup: {}},
      searchOption: get(this.props, 'slot.event.raw.slot') ? (get(this.props, 'slot.event.raw.slot.freightProviderId') == get(this.props, 'slot.event.raw.slot.truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS) : SEARCH_BY_FREIGHT_PROVIDER_REGOS,
      isBlended: false,
      spreadDetails: [],
      baseEntityChemicalApplications: [],
      chemicalApplications: [],
      blendedOrderGrade: undefined,
      doNotSetChemicalApplications: false,
      fields: {
        massLimitPermit: {
          value: '',
          validators: [],
          errors: [],
        },
        ghms: {
          value: false,
          validators: [],
          errors: [],
        },
        siteId: {
          value: get(this.props, 'sites[0].id'),
          errors: [],
          validators: [required()],
        },
        type: {
          value: undefined,
          errors: [],
          validators: [],
        },
        pits: {
          value: undefined,
          errors: [],
          validators: [],
        },
        startDate: {
          value: undefined,
          errors: [],
          validators: [required()],
        },
        endDate: {
          value: undefined,
          errors: [],
          validators: [required()],
        },
        startTime: {
          value: undefined,
          errors: [],
          validators: [required()],
        },
        endTime: {
          value: undefined,
          errors: [],
          validators: [required()],
        },
        bookingNumber: {
          value: undefined,
          errors: [],
          validators: [],
        },
        deliveryOrderNumber: {
          value: undefined,
          errors: [],
          validators: [],
        },
        orderId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        status: {
          value: 'planned',
          errors: [],
          validators: [required()],
        },
        commodityId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        excludedCommodityIds: {
          value: [],
          errors: [],
          validators: [],
        },
        gradeId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        excludedGradeIds: {
          value: [],
          errors: [],
          validators: [],
        },
        tonnage: {
          value: undefined,
          errors: [],
          validators: [valueAbove(0)],
        },
        driverId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        priority: {
          value: false,
          errors: [],
          validators: [],
        },
        truckId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        frequency: {
          value: undefined,
          errors: [],
          validators: [],
        },
        customer: {
          value: undefined,
          errors: [],
          validators: [],
        },
        freightProviderId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        subFreightProviderId: {
          value: undefined,
          errors: [],
          validators: [],
        },
        restrictedVisibleToCarrier: {
          value: false,
          errors: [],
          validators: [],
        },
        season: {
          value: null,
          validators: [],
          errors: [],
        },
        freightPickup: {
          date: {
            value: undefined,
            validators: [],
            errors: [],
          },
          timeStart: {
            value: undefined,
            validators: [],
            errors: [],
          },
          consignor: {
            handlerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
          },
        },
        freightDelivery: {
          date: {
            value: undefined,
            validators: [],
            errors: [],
          },
          timeStart: {
            value: undefined,
            validators: [],
            errors: [],
          },
          consignee: {
            handlerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
          },
        },
        pickupSlotId: {
          value: null,
          validators: [],
          errors: [],
        },
        deliverySlotId: {
          value: null,
          validators: [],
          errors: [],
        },
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.upsert = this.upsert.bind(this);
    this.handleFreightProviderChange = this.handleFreightProviderChange.bind(this);
    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleOrderSelect = this.handleOrderSelect.bind(this);
    this.onOrderSelect = this.onOrderSelect.bind(this);
    this.handleOrderBlur = this.handleOrderBlur.bind(this);
    this.handleSubFreightProviderChange = this.handleSubFreightProviderChange.bind(this);
    this.handleDriverChange = this.handleDriverChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleRepeatCheckBoxChange = this.handleRepeatCheckBoxChange.bind(this);
    this.handleMillPriorityChange = this.handleMillPriorityChange.bind(this);
    this.handleRestrictedVisibleToCarrierChange = this.handleRestrictedVisibleToCarrierChange.bind(this);
    this.handleCommodityChange = this.handleCommodityChange.bind(this);
    this.handleTruckChange = this.handleTruckChange.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.handleTonnageChange = this.handleTonnageChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleCancelBooking = this.handleCancelBooking.bind(this);
    this.handleFrequencyChange = this.handleFrequencyChange.bind(this);
    this.setFrequencyOptions = this.setFrequencyOptions.bind(this);
    this.handleRepeatDetailsChange = this.handleRepeatDetailsChange.bind(this);
    this.handleRepeatSelectFieldChange = this.handleRepeatSelectFieldChange.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCommentsIconsClick = this.handleCommentsIconsClick.bind(this);
    this.handleHistoryIconClick = this.handleHistoryIconClick.bind(this);
    this.handlePickupInfoIconClick = this.handlePickupInfoIconClick.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.getSlotsByFrequency = this.getSlotsByFrequency.bind(this);
    this.handleMarkBooked = this.handleMarkBooked.bind(this);
    this.fetchVendorDeclarations = this.fetchVendorDeclarations.bind(this);
    this.handleVendorDecInfoIconClick = this.handleVendorDecInfoIconClick.bind(this);
    this.handleSeasonChange = this.handleSeasonChange.bind(this);
    this.handleMultiSlotSelect = this.handleMultiSlotSelect.bind(this);
    this.handleSlotChange = this.handleSlotChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.isLocationTrackingEnabled = this.isLocationTrackingEnabled.bind(this);
    this.fetchSitesInfo = this.fetchSitesInfo.bind(this);
    this.handleTruckDetailsSelectValueChange = this.handleTruckDetailsSelectValueChange.bind(this);
    this.handleTrailerSlotChange = this.handleTrailerSlotChange.bind(this);
    this.getSlotOptionsForTrailer = this.getSlotOptionsForTrailer.bind(this);

    this.fieldsOrder = [
      "siteId", "type", "status", "startDate", "startTime", "endTime", "commodityId", "gradeId",
      "tonnage", "pits", "customer", "bookingNumber", "season", "freightProviderId", "subFreightProviderId",
      "truckId", "driverId", "deliveryOrderNumber", "comment",
    ];

    this.fieldRef = {};

    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));

  }

  componentDidMount() {
    this.fetchSlotTruckDetails()
    if (get(this.props, 'startDate')) {
      this.fetchPermits()
      this.fetchCategories()
    }
    this.fetchAllGrades()
    const isMultiSlotBooking = this.isMultiSlotBooking()

    if(isMultiSlotBooking)
      this.setFieldsForMultiSlotBooking()
    if(!isEmpty(this.props.trailerBookingSlots)) {
      const trailerSlots = map(this.props.trailerBookingSlots, slot => ({id: slot.id, name: slot.name, title: slot.title, slotDetails: slot.raw.slot}));
      this.setState({trailerSlots: trailerSlots, pickupSlots: trailerSlots});
    }
    if (get(this.props.slot, 'event.raw.slot.orderId')) this.getOrderRefs();
    if (this.props.slot) {
      if (get(this.props.slot, 'event.raw.slot.id'))
        this.props.getSlotComments(this.props.slot.event.raw.slot.siteId, this.props.slot.event.raw.slot.id);
      this.setBoundaries(this.props.slot, true);
      this.filterStatuses();
    }
    if (this.props.siteBooking && (this.props.slot || get(this.props, 'multiSlots.0.raw.slot'))) {
      const slot = get(this.props.slot, 'event.raw.slot') || get(this.props, 'multiSlots.0.raw.slot');
      let isReadonly = !isSystemCompany() && get(slot, 'freightProviderId') && this.props.currentUserCompanyId !== slot.freightProviderId;
      const subFPId = get(slot, 'subFreightProviderId');
      if(subFPId && this.props.currentUserCompanyId === subFPId)
        isReadonly = false;

      this.setState({
        bookieTrucks: this.props.bookieTrucks,
        bookieDrivers: this.props.bookieDrivers,
        isReadonly: isReadonly,
      });
    }
    if(get(this.props, 'slot.event.raw.slot.orderId') || get(this.props, 'slot.event.raw.slot.commodityContractId') || get(this.props, 'slot.event.raw.slot.movementId')){
      this.fetchVendorDeclarations();
    }
    const status = get(this.props, 'slot.event.raw.slot.status');
    const orderBookingBothSides = get(this.props, 'slot.event.raw.slot.bothSitesOrderBookingOn');
    if (status === 'booked' && orderBookingBothSides) {
      this.fillRequiredDetailsForBookedSlot();
    }
    this.fetchSitesInfo();
    this.getSubFreightProviders(true);
    this.getDrivers();
    this.fetchAllCompanies()
  }

  fetchAllCompanies() {
    if (get(this.props, 'slot.event.raw.slot.status') !== 'planned') {
      APIService
      .companies()
      .appendToUrl('minimal/all/')
      .get()
      .then(items => {
        this.setState({ allCompanies: items});
      });
    }
  }


  fetchAllGrades() {
    APIService.commodities().grades().appendToUrl('all/').get().then(grades => this.setState({allGrades: grades}))
  }

  setFieldsForMultiSlotBooking() {
    this.setState({update: true, multiSlots: orderBy(this.props.multiSlots, slot => slot.start.toDate())}, () => {
      this.handleStatusChange('booked')
      this.setBoundaries(this.state.multiSlots[0])
    })
  }

  fetchPermits(forDate=null) {
    APIService.trucks().appendToUrl(`categories/permits/?for_date=${forDate || this.props.startDate.substring(0, 10)}`).get().then(permits => this.setState({permits: permits}));
  }

  fetchCategories(forDate=null) {
    APIService.trucks().appendToUrl(`categories/?for_date=${forDate || this.props.startDate.substring(0, 10)}`).get().then(categories => this.setState({categories: categories}, this.setCategory));
  }

  setCategory(categoryId=null) {
    if (categoryId)
      this.setCategoryFromCategories(categoryId);
    else {
      const category = this.findCategory();
      categoryId = this.state.truckDetails?.categoryId
      if(get(category, 'id') !== categoryId)
        this.setCategoryFromCategories(get(category, 'id'));
    }
  }

  isPickupSiteOrderBookingOnForSelectedTruck = (site, truck) => this.isOrderBookingOnForSelectedTruckForSite(site || this.state.pickupSite, truck)
  isDeliverySiteOrderBookingOnForSelectedTruck = (site, truck) => this.isOrderBookingOnForSelectedTruckForSite(site || this.state.deliverySite, truck)

  isBothSiteOrderBookingOn = (pickupSite, deliverySite, truck) => this.isPickupSiteOrderBookingOnForSelectedTruck(pickupSite, truck) && this.isDeliverySiteOrderBookingOnForSelectedTruck(deliverySite, truck)

  isOrderBookingOnForSelectedTruckForSite = (site, truck, bothSiteBooking) => {
    // Assumes that both site order booking is on in general
    const { selectedTruck, orderValidationResponse } = this.state;
    let result = bothSiteBooking || orderValidationResponse?.bothSitesOrderBookingOn
    let _truck = truck || selectedTruck
    const allowedTruckCompanyIds = site?.settings?.allowedTruckCompanyIds
    if(result && _truck?.companyId && allowedTruckCompanyIds && !isEmpty(allowedTruckCompanyIds)) {
      result = includes(allowedTruckCompanyIds, _truck.companyId)
    }
    return result
  }

  updateTruck(createdSlotId) {
    const { truckDetails, loadSharing } = this.state;
    let category = find(this.state.categories, {id: truckDetails.categoryId});
    const data = {
      categoryId: truckDetails.categoryId,
      steer_point_5: truckDetails.steerPoint5,
      steer_1_point_1: truckDetails.steer1Point1,
      permit_number: this.state.permitChecked ? truckDetails.permitNumber : null,
      declared_mass_limit: (this.state.permitChecked || this.state.restrictedChecked) ? truckDetails.declaredMassLimit : null,
      accreditation_number: this.state.accreditationNumberChecked ? truckDetails.accreditationNumber : null,
      load_sharing: loadSharing,
      notice_number: get(category, 'massLimitPermit') === 'Notice' ? truckDetails.noticeNumber : null,
      restricted: this.state.restrictedChecked ? truckDetails.restricted : null
    }
    if(this.isMultiSlotBooking()) {
      const outloads = map(this.state.multiSlotsBothSiteBooking, 'outload')
      const inloads = map(this.state.multiSlotsBothSiteBooking, 'inload')
      forEach(compact([...outloads, ...inloads]), slotId => {
        APIService
          .company_sites()
          .slots(slotId)
          .appendToUrl('truck/')
          .put(data).then(() => {})
      })
    } else if (this.state.bothSitesOrderBookingOn) {
      forEach(compact([this.state.fields.deliverySlotId.value, this.state.fields.pickupSlotId.value]), slotId => {
        APIService
          .company_sites()
          .slots(slotId)
          .appendToUrl('truck/')
          .put(data).then(() => {})
      })
    } else {
      if(this.state.isReadonly || this.isSlotCancelled() || (!get(this.props.slot, 'event.raw.slot.id') && !createdSlotId))
        return
      APIService
        .company_sites()
        .slots(createdSlotId || this.props.slot.event.raw.slot.id)
        .appendToUrl('truck/')
        .put(data).then(() => {})
    }
  }


  fetchSlotTruckDetails = async () => {
    if(!this.props.isNew && this.props.slot?.event?.raw?.slot?.truckId) {
      const truckDetails = await APIService
            .company_sites()
            .slots(this.props.slot.event.raw.slot.id)
            .appendToUrl('truck/')
            .get()
      const newState = {...this.state}
      newState.truckDetails.categoryId = truckDetails.categoryId
      newState.truckDetails.code = get(truckDetails, 'category.truckCode')
      newState.truckDetails.steer1Point1 = truckDetails.steer1Point1
      newState.truckDetails.steerPoint5 = truckDetails.steerPoint5
      newState.fields.massLimitPermit.value = get(truckDetails, 'category.massLimitPermit');
      newState.fields.ghms.value = get(truckDetails, 'category.ghms');
      let permitNumber = truckDetails.permitNumber
      let declaredMassLimit = truckDetails.declaredMassLimit
      let accreditationNumber = truckDetails.accreditationNumber
      newState.truckDetails.permitNumber = permitNumber;
      newState.truckDetails.declaredMassLimit = declaredMassLimit;
      newState.truckDetails.accreditationNumber = accreditationNumber;
      newState.truckDetails.noticeNumber = truckDetails.noticeNumber;
      newState.truckDetails.restricted = truckDetails.restricted;
      newState.loadSharing = truckDetails.loadSharing
      newState.permitChecked = Boolean(permitNumber);
      newState.accreditationNumberChecked = Boolean(accreditationNumber);
      newState.restrictedChecked = Boolean(truckDetails.restricted);
      newState.truckDetails.id = truckDetails.truckId
      newState.slotTruckDetails = truckDetails
      this.setState(newState, () => {
        this.setCategory()
        const newState = {...this.state}
        this.setMandatoryClause(newState)
        this.setState(newState)
      })
    }
  }

  setAdditionalMassLimitFields() {
    const { truckDetails } = this.state;
    const newState = {...this.state};
    newState.permitChecked = Boolean(truckDetails.permitNumber);
    newState.accreditationNumberChecked = Boolean(truckDetails.accreditationNumber);
    newState.restrictedChecked = Boolean(truckDetails.restricted);
    this.setState(newState);
  }

  fetchTruck() {
    if(!this.state.isFetchingTruckDetails && this.state.fields.truckId.value && this.state.truckDetails?.id !== this.state.fields.truckId.value) {
      this.setState({isFetchingTruckDetails: true}, () => {
        APIService.trucks(this.state.fields.truckId.value).get().then(truck => this.setState({truckDetails: truck, isFetchingTruckDetails: false}, () => {
          this.setCategory(truck?.categoryId);
          const newState = {...this.state};
          this.setMandatoryClause(newState);
          this.setState(newState, () =>
            this.setState({bothSitesOrderBookingOn: this.isBothSiteOrderBookingOn()}, () => {
              this.updateSlotValidators()
              this.setAdditionalMassLimitFields();
            })
          );
        }))
      })
    }
  }

  updateSlotValidators = () => {
    const bothSitesOrderBookingOn = this.isBothSiteOrderBookingOn()
    if(!bothSitesOrderBookingOn && (this.state.fields.pickupSlotId.validators.length || this.state.fields.pickupSlotId.validators.length)) {
      const newState = {...this.state}
      newState.bothSitesOrderBookingOn = bothSitesOrderBookingOn
      newState.fields.pickupSlotId.validators = []
      newState.fields.pickupSlotId.errors = []
      newState.fields.deliverySlotId.validators = []
      newState.fields.deliverySlotId.errors = []
      this.setState(newState)
    } else if(bothSitesOrderBookingOn && (!this.state.fields.pickupSlotId.validators.length || !this.state.fields.pickupSlotId.validators.length)) {
      const newState = {...this.state}
      newState.bothSitesOrderBookingOn = bothSitesOrderBookingOn
      newState.fields.pickupSlotId.validators =  this.isMultiSlotBooking() ? [] : [required()]
      newState.fields.deliverySlotId.validators = this.isMultiSlotBooking() ? [] : [required()]
      this.setState(newState)
    }
  }

  findCategory() {
    const { truckDetails, fields } = this.state;
    const {massLimitPermit, ghms} = fields
    const details = truckDetails
    const showAdditionalMassLimitCodeFields = get(this.state.pickupSite, 'additionalMassLimitCodes') || get(this.state.deliverySite, 'additionalMassLimitCodes');
    if(details.code && (showAdditionalMassLimitCodeFields || massLimitPermit.value)) {
      const code = details.code.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      const categories = filter(this.state.categories, category => {
        let truckCode = category.truckCode;
        if(isString(category.truckCode)) {
          truckCode = category.truckCode.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
          return truckCode === code;
        }
      });
      return find(categories, {massLimitPermit: massLimitPermit.value ? massLimitPermit.value : null, ghms: ghms.value});
    }
    return undefined;
  }

  fetchSitesInfo() {
    const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
    let slotId = get(this.props, 'slot.event.raw.slot.id');
    let pickupSlot;
    if (slotType === 'outload') {
      pickupSlot = get(this.props, 'slot.event.raw.slot');
    }
    else {
      pickupSlot = get(this.props, 'slot.event.raw.slot.counterSlotId');
    }
    if(!slotId)
      return
    APIService.company_sites()
      .appendToUrl(`slots/${slotId}/verbose/`)
      .get(this.props.token, {'REFERER-UNIT' : getCountryMovementDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true})
      .then(response => {
        const newState = {...this.state};
        newState.pickupUtcOffsetMinutes = get(response, 'pickupSiteTimezone', 60) / 60;
        newState.pickupStartTime = pickupSlot?.start ? moment(pickupSlot.start).utcOffset(newState.pickupUtcOffsetMinutes) : newState.pickupStartTime
        newState.deliveryUtcOffsetMinutes = get(response, 'deliverySiteTimezone', 60) / 60;
        newState.pickupTZAbbreviation = response?.pickupSiteTimezoneAbbreviation
        newState.deliveryTZAbbreviation = response?.deliverySiteTimezoneAbbreviation
        newState.isPickupSiteUser = response?.isPickupSiteUser
        newState.isDeliverySiteUser = response?.isDeliverySiteUser
        newState.counterSlotUpdatedAt = response?.counterSlotUpdatedAt
        newState.counterSlotSettingsUpdatedAt = response?.counterSlotSettingsUpdatedAt
        newState.counterSlotSiteId = response?.counterSlotSiteId
        newState.baseEntityChemicalApplications = response?.chemicalApplications || []
        newState.spreadDetails = response?.spread || []
        let trailerSlotType = slotType === 'outload' ? 'pickupTrailerSlots' : 'deliveryTrailerSlots'
        newState[trailerSlotType] = response?.trailerSlots
        this.setState(newState, () => {
          this.fetchCounterSlot(this.props.slot?.event?.raw.slot?.counterSlotId);
          this.setChemicalApplications(true);
          this.props.forceStopLoader()
        });
      });
  }

  setCategoryFromCategories(categoryId) {
    const details = this.state.truckDetails
    categoryId = categoryId || details.categoryId;
    const category = find(this.state.categories, {id: categoryId});
    if(category) {
      if(categoryId !== details.categoryId || this.state.fields.massLimitPermit.value !== category.massLimitPermit || this.state.fields.ghms.value !== category.ghms) {
        const newState = {...this.state};
        newState.truckDetails.categoryId = categoryId;
        newState.truckDetails.code = category.truckCode
        newState.fields.massLimitPermit.value = category.massLimitPermit;
        newState.fields.ghms.value = category.ghms;
        if (!category.steerPoint5)
          newState.truckDetails.steerPoint5 = false;
        if (!category.steer1Point1)
          newState.truckDetails.steer1Point1 = false;
        const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
        const stateCode = slotType === 'outload' ? get(this.state.pickupSite, 'marketZoneStateCode'): get(this.state.deliverySite, 'marketZoneStateCode');
        if (get(category, `loadSharing.${stateCode}`) === 0)
          newState.loadSharing = true
        this.setState(newState);
      }
    }
  }


  fetchCounterSlot = slotId => {
    if(slotId) {
      APIService
        .company_sites()
        .appendToUrl(`slots/${slotId}/verbose/`)
        .get(this.props.token, {'REFERER-UNIT' : getCountryMovementDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true})
        .then(response => {
          this.setState({counterSlot: response}, this.fillCounterSlot)
        })
    }
  }

  fillCounterSlot = () => {
    if(this.state.counterSlot?.id) {
      const slot = this.state.counterSlot
      const newState = {...this.state}
      const start = this.initialMDate(slot, 'start');
      const timezoneOffset = this.getTimezoneOffsetMinutesToAdd(start);
      if(timezoneOffset) {
        start.add(timezoneOffset, 'minutes');
      }
      if(this.state.counterSlot.type == 'outload') {
        newState.fields.pickupSlotId.value = slot.id
        newState.fields.freightPickup.date.value = start.format('YYYY-MM-DD')
        newState.fields.deliverySlotId.value = this.props.slot.event.raw.slot.id
        newState.selectedPickupSlot = slot
      } else {
        newState.fields.deliverySlotId.value = slot.id
        newState.fields.freightDelivery.date.value = start.format('YYYY-MM-DD')
        newState.fields.pickupSlotId.value = this.props.slot.event.raw.slot.id
        newState.selectedDeliverySlot = slot
      }
      this.setState(newState)
    }
  }

  handleTruckAttrFieldChange = (id, value) => {
    const newState = {...this.state}
    newState.truckDetails[id] = value || ''
    if(value && id === 'code')
      newState.massLimitCode.errors = []
    this.setState(newState, this.setCategory)
  }

  shouldEnableSteerPoint5 = () => {
    const categoryId = this.state.truckDetails.categoryId
    return get(find(this.state.categories, {id: categoryId}), 'steerPoint5');
  }

  shouldEnableSteer1Point1 = () => {
    const categoryId = this.state.truckDetails.categoryId
    return get(find(this.state.categories, {id: categoryId}), 'steer1Point1');
  }

  componentDidUpdate(prevProps) {
    if(!this.state.duplicateSlotErrorTriggered && this.props.isDuplicate && !this.props.slot?.event?.raw?.slot?.id && !this.state.fields.tonnage.errors.length && this.state.selectedOrder?.id && this.state.fields.tonnage.value && this.state.orderUnaccountedTonnage) {
      this.setState({duplicateSlotErrorTriggered: true}, () => this.handleTonnageChange({target: {id: 'tonnage', value: this.state.fields.tonnage.value}}))
    }
    if (
      isEmpty(prevProps.comments) &&
        isArray(this.props.comments) &&
        filter(this.props.comments, {archived: false}).length > 0
    ) {
      this.setState({ showComments: true });
    }
    if(this.props.createdTruck && this.state.selectedTruck != this.props.createdTruck) {
      const newState = {...this.state};
      newState.selectedTruck =  this.props.createdTruck;
      if(this.state.searchOption != SEARCH_BY_ALL_REGOS && this.props.createdTruck?.companyId != 1)
        this.handleTruckChange(this.props.createdTruck?.id, 'truckId', this.props.createdTruck)
      this.setState(newState);
    }

    if (isEmpty(prevProps.drivers) && !isEmpty(this.props.drivers))
      this.getDrivers();
    if (this.isSlotPlanned() && this.state.isBlended && !this.state.doNotSetChemicalApplications && this.state.baseEntityChemicalApplications && !isEmpty(this.state.baseEntityChemicalApplications) && isEmpty(this.state.chemicalApplications))
      this.setChemicalApplications();
    if(!isEmpty(this.state.pickupSlotTrailerErrors) && !isEmpty(compact(map(this.state.pickupSiteTrailers, 'slotId'))))
      this.setState({pickupSlotTrailerErrors: null})
    if(!isEmpty(this.state.deliverySlotTrailerErrors) && !isEmpty(compact(map(this.state.deliverySiteTrailers, 'slotId'))))
      this.setState({deliverySlotTrailerErrors: null})
  }

  fillRequiredDetailsForBookedSlot() {
    const newState = {...this.state};
    const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
    if (slotType === 'outload') {
      newState.fields.pickupSlotId.value = get(this.props, 'slot.event.raw.slot.id');
      newState.fields.deliverySlotId.value = get(this.props, 'slot.event.raw.slot.counterSlotId');
    }
    else {
      newState.fields.pickupSlotId.value = get(this.props, 'slot.event.raw.slot.counterSlotId');
      newState.fields.deliverySlotId.value = get(this.props, 'slot.event.raw.slot.id');
    }
    newState.fields.truckId.value = get(this.props, 'slot.event.raw.slot.truckId');
    newState.fields.driverId.value = get(this.props, 'slot.event.raw.slot.driverId');
    newState.bookieTrucks = this.props.bookieTrucks;
    newState.bookieDrivers = this.props.bookieDrivers;
    newState.searchOption = get(this.props, 'slot.event.raw.slot.freightProviderId') == get(this.props, 'slot.event.raw.slot.truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS;
    this.setState(newState, () => {
      const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
      let slotId;
      if (slotType === 'outload') {
        slotId = this.state.fields.deliverySlotId.value;
      }
      else {
        slotId = this.state.fields.pickupSlotId.value;
      }
      if(!slotId)
        return
      APIService.company_sites()
        .appendToUrl(`slots/${slotId}/verbose/`)
        .get(this.props.token, {'REFERER-UNIT' : getCountryMovementDisplayUnit(), 'REFERER-UNIT-FOR-REQUEST': true})
        .then(response => {
          const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
          let pickupSlot;
          let deliverySlot;
          const newState = {...this.state};
          if (slotType === 'outload') {
            pickupSlot = get(this.props.slot, 'event.raw.slot');
            deliverySlot = response;
            newState.deliverySlot = response;
            if(deliverySlot?.trailerSlotIds){
              map(deliverySlot.trailerSlotIds, (slotId, index) => {
                newState.deliverySiteTrailers[`trailer${index+1}`].applied = true;
                newState.deliverySiteTrailers[`trailer${index+1}`].slotId = slotId.toString();
              })
            }
            if(response?.trailerSlots)
              newState.deliveryTrailerSlots = response.trailerSlots;
          }
          else {
            deliverySlot = get(this.props.slot, 'event.raw.slot');
            pickupSlot = response;
            newState.pickupSlot = response;
            if(pickupSlot?.trailerSlotIds){
              map(pickupSlot.trailerSlotIds, (slotId, index) => {
                newState.pickupSiteTrailers[`trailer${index+1}`].applied = true;
                newState.pickupSiteTrailers[`trailer${index+1}`].slotId = slotId.toString();
              })
            }
            if(response?.trailerSlots)
              newState.pickupTrailerSlots = response.trailerSlots;
          }
          let pickupDate = get(response, 'actualPickupDate') || get(response, 'pickupDate')
          let deliveryDate = get(response, 'actualDeliveryDate')  || get(response, 'deliveryDate')
          const pickupTz = get(response, 'pickupSiteTimezone');
          const deliveryTz = get(response, 'deliverySiteTimezone');
          let utcOffsetMinutes = pickupTz / 60;
          let startTime = moment(pickupSlot.start).utcOffset(utcOffsetMinutes);
          let endTime = moment(pickupSlot.end).utcOffset(utcOffsetMinutes);
          newState.pickupUtcOffsetMinutes = utcOffsetMinutes;
          newState.pickupStartTime = startTime;
          newState.pickupEndTime = endTime;
          newState.selectedPickupSlot = startTime.format('hh:mm A') + " - " + endTime.format('hh:mm A');
          newState.fields.freightPickup.date.value = startTime.format('YYYY-MM-DD');
          newState.fields.freightDelivery.date.value = moment(deliveryDate).format('YYYY-MM-DD');
          newState.fields.freightPickup.timeStart.value = moment(pickupDate).format('hh:mm:ss');
          newState.fields.freightDelivery.timeStart.value = moment(deliveryDate).format('hh:mm:ss');
          utcOffsetMinutes = deliveryTz / 60;
          startTime = moment(deliverySlot.start).utcOffset(utcOffsetMinutes);
          endTime = moment(deliverySlot.end).utcOffset(utcOffsetMinutes);
          newState.deliveryUtcOffsetMinutes = utcOffsetMinutes;
          newState.selectedDeliverySlot = startTime.format('hh:mm A') + " - " + endTime.format('hh:mm A');
          this.setState(newState, () => {
            this.fetchTruck()
            this.fetchCounterSlot(this.props.slot.event?.raw?.slot?.counterSlotId)
          });
          this.props.forceStopLoader()
        })
    });
  }

  selectedSite() {
    const { sites, slot, isNew } = this.props;

    if (isNew || !slot) return find(sites, { id: this.state.fields.siteId.value });

    return find(sites, { id: get(slot, 'event.raw.slot.siteId') });
  }

  shouldFetchOrders() {
    return this.isOrderBooking() && !this.state.isFetchingOrders;
  }

  handleTrailerBooking(trailer, checked) {
    const newState = {...this.state};
    newState[trailer].applied = checked;
    if(!checked)
      newState[trailer].slotId = '';
    this.setState(newState);
  }

  handleTrailerSlotChange = (value, trailer, siteTrailers) => {
    let trailers = without(['trailer1', 'trailer2', 'trailer3'], trailer)
    const newState = {...this.state};
    if(siteTrailers == 'pickupSiteTrailers') {
      if(!value || (this.state.pickupSiteTrailers[trailers[0]].slotId != value && this.state.pickupSiteTrailers[trailers[1]].slotId != value))
        newState.pickupSiteTrailers[trailer].slotId = value ? value : undefined;
    } else {
      if(!value || this.state.deliverySiteTrailers[trailers[0]].slotId != value && this.state.deliverySiteTrailers[trailers[1]].slotId != value)
        newState.deliverySiteTrailers[trailer].slotId = value ? value : undefined;
    }
    this.setState(newState);
  }

  getCounterSlotOptionForTrailers = (trailer, siteTrailers) => {
    let trailerSlots = siteTrailers == 'delivery' ? this.state.deliverySlots : this.state.pickupSlots;
    if(!trailerSlots)
      return [];

    let excludedCommodityAndGradeSlotIds = [];
    let slotIdsWithDifferentCommodityGrade = [];

    map(trailerSlots, slot => {
      if(slot.excludedCommodityIds && includes(slot.excludedCommodityIds, this.state.fields.commodityId.value))
        excludedCommodityAndGradeSlotIds.push(slot.id)
      else if(slot.excludedGradeIds && includes(slot.excludedGradeIds, this.state.fields.gradeId.value))
        excludedCommodityAndGradeSlotIds.push(slot.id)
      else if (slot.commodityId && slot.commodityId != this.state.fields.commodityId.value)
        slotIdsWithDifferentCommodityGrade.push(slot.id)
      else if (slot.gradeId && slot.gradeId != this.state.fields.gradeId.value)
        slotIdsWithDifferentCommodityGrade.push(slot.id)
    })

    let trailers = without(['trailer1', 'trailer2', 'trailer3'], trailer);
    const occupiedSlotIds = siteTrailers == 'delivery' ? map(trailers, t => this.state.deliverySiteTrailers[t].slotId) : map(trailers, t => this.state.pickupSiteTrailers[t].slotId);
    const excludeParentSlotId = siteTrailers == 'delivery' ? this.state.fields.deliverySlotId.value : this.state.fields.pickupSlotId.value;
    const loadType = siteTrailers === 'delivery' ? 'inload' : 'outload'
    return reject(trailerSlots, slot => {
      let slotType = (slot?.slotDetails?.type || slot.type)
      return (includes(occupiedSlotIds, slot.id) || slot.id == excludeParentSlotId || includes(excludedCommodityAndGradeSlotIds, slot.id) || (slotType && slotType !== loadType) || includes(slotIdsWithDifferentCommodityGrade, slot.id))
    });
  }

  getSlotOptionsForTrailer = (trailer, loadType) => {
    let { trailerSlots } = this.state;
    if(!trailerSlots)
      return [];
    let excludedCommodityAndGradeSlotIds = [];
    let slotIdsWithDifferentCommodityGrade = [];
    let isOrderDeliverySite = this.state.deliverySite?.id == this.state.fields.siteId.value;

    let selectedSlot = this.props.slot.event?.raw?.slot;
    let siteTimezone = this.props.slot.event?.raw?.site?.timezone
    let utcOffsetMinutes = siteTimezone?.utcoffsetSeconds / 60;
    let startTime = moment(selectedSlot.start).utcOffset(utcOffsetMinutes);
    let endTime = moment(selectedSlot.end).utcOffset(utcOffsetMinutes);

    selectedSlot = {
      'id': selectedSlot?.id,
      'name': `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')} (${get(siteTimezone, 'abbreviation')})`,
      'slotDetails': selectedSlot,
      'title': `${startTime.format('hh:mm A')} (${get(siteTimezone, 'abbreviation')})`
    }
    trailerSlots = uniq([...trailerSlots, selectedSlot], 'id')
    loadType = loadType || this.state.fields.type.value

    map(trailerSlots, slot => {
      if(slot.slotDetails.excludedCommodityIds && includes(slot.slotDetails.excludedCommodityIds, this.state.fields.commodityId.value))
        excludedCommodityAndGradeSlotIds.push(slot.id)
      else if(slot.slotDetails.excludedGradeIds && includes(slot.slotDetails.excludedGradeIds, this.state.fields.gradeId.value))
        excludedCommodityAndGradeSlotIds.push(slot.id)
      else if (slot.slotDetails?.commodityId && slot.slotDetails?.commodityId != this.state.fields.commodityId.value)
        slotIdsWithDifferentCommodityGrade.push(slot.id)
      else if (slot.slotDetails?.gradeId && slot.slotDetails.gradeId != this.state.fields.gradeId.value)
        slotIdsWithDifferentCommodityGrade.push(slot.id)
    })

    let trailers = without(['trailer1', 'trailer2', 'trailer3'], trailer);

    const occupiedSlotIds = isOrderDeliverySite ? map(trailers, t => this.state.deliverySiteTrailers[t].slotId) : map(trailers, t => this.state.pickupSiteTrailers[t].slotId);
    const excludeParentSlotId = isOrderDeliverySite ? this.state.fields.deliverySlotId.value : this.state.fields.pickupSlotId.value;
    let slots = reject(trailerSlots, slot => {
      let slotType = (slot?.slotDetails?.type || slot.type)
      return Boolean(includes(occupiedSlotIds, slot.id) || includes(excludedCommodityAndGradeSlotIds, slot.id) || (slotType && slotType !== loadType) || excludeParentSlotId == slot.id || includes(slotIdsWithDifferentCommodityGrade, slot.id))
    });
    return slots.sort((a, b) => {
      const startTimeA = new Date(a.slotDetails.start).getTime();
      const startTimeB = new Date(b.slotDetails.start).getTime();
      return startTimeA - startTimeB;
    });
  }

  getStartEndDateForMultiSlotsBooking = () => {
    const { multiSlots } = this.state
    const dates = map(multiSlots, slot => slot.start.toDate())
    const startDate = moment(min(dates)).format('YYYY-MM-DD')
    const endDate = moment(max(dates)).format('YYYY-MM-DD')
    return {startDate, endDate}
  }

  getOrders() {
    const site = this.selectedSite();
    const siteCompanyId = get(site, 'companyId');
    if (siteCompanyId && !this.state.isFetchingOrders) {
      this.setState(
        {
          selectedOrder: null,
          isFetchingOrders: true,
        },
        () => {
          const service = APIService.companies(siteCompanyId).orders();
          if (site)
            service.appendToUrl(`?site_id=${site.id}&load_type=${this.state.fields.type.value}`);
          if(this.props.slot)
            service.appendToUrl(`&booking_date=${this.state.fields.startDate.value}`);
          else if (!isEmpty(this.state.multiSlots)) {
            const {startDate, endDate} = this.getStartEndDateForMultiSlotsBooking()
            service.appendToUrl(`&booking_start_date=${startDate}&booking_end_date=${endDate}`);
          }
          if(this.isFieldDisabled('orderId') && this.state.selectedOrderIdentifier)
            service.appendToUrl(`&identifier=${this.state.selectedOrderIdentifier}`)

          service.get(this.props.token).then(orders => {
            this.setState({ isFetchingOrders: false, orders: orders }, () => {
              if (this.state.selectedOrderIdentifier && !this.state.selectedOrder) {
                const order = this.getSelectedOrderFromIdentifier();
                const newState = {...this.state};
                newState.selectedOrder = order;
                if(order)
                  newState.fields.orderId.value = order.id;
                this.setState(newState, () => {
                  this.handleOrderBlur();
                });
              }
            });
          });
        },
      );
    }
  }

  fetchVendorDeclarations(){
    let url = '';
    let params = pickBy({
      order_id: get(this.props.slot, 'event.raw.slot.orderId'),
      movement_id: get(this.props.slot, 'event.raw.slot.movementId'),
      contract_id: get(this.props.slot, 'event.raw.slot.commodityContractId'),
    }, value => value);
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    if(queryString)
      url += '?' + queryString;
    APIService.vendor_decs().appendToUrl(`linked/${url}`).get().then(
      res => {
        const newState = {...this.state};
        newState.vendorDecs = res;
        newState.shouldShowVendorDec = res.length > 0 ? true : false;
        this.setState(newState);
      }
    );
  }

  getOrderUnaccountedTonnage() {
    let orderIdentifier, orderId, orderUnaccountedTonnage;
    if(this.state.selectedOrder) {
      orderIdentifier = get(this.state.selectedOrder, 'identifier');
      orderUnaccountedTonnage = this.state.selectedOrder?.unaccountedTonnage
      orderId = get(this.state.selectedOrder, 'id');
    } else {
      const order = this.getSelectedOrderFromIdentifier(null, this.state.orders);
      orderIdentifier = get(order, 'identifier');
      orderId = get(order, 'id');
    }
    const cachedTonnages = this.state.orderTonnageMap[orderIdentifier];
    if(cachedTonnages || orderUnaccountedTonnage)
      this.setState({
        orderUnaccountedTonnage: cachedTonnages?.unaccountedTonnage || orderUnaccountedTonnage,
        contractUnaccountedTonnage: cachedTonnages?.contractUnaccountedTonnage
      }, this.updateTonnageValidation);
    else if (orderId) {
      this.setState({ isFetchingUnaccountedTonnage: true }, () => {
        APIService.freights()
          .orders(orderId)
          .appendToUrl('unaccounted-tonnage/')
          .get(this.props.token, {'REFERER-UNIT': this.state.unit, 'REFERER-UNIT-FOR-REQUEST': true})
          .then(data => {
            const tonnage = get(data, 'unaccountedTonnage', 0);
            const contractUnaccountedTonnage = get(data, 'contractUnaccountedTonnage', 0);
            const tonnageMap = {...this.state.orderTonnageMap};
            tonnageMap[orderIdentifier] = data;
            if(tonnage > 0){
              this.showBookViaMovementPopup();
            }
            this.setState({
              orderUnaccountedTonnage: tonnage,
              contractUnaccountedTonnage: contractUnaccountedTonnage,
              isFetchingUnaccountedTonnage: false,
              orderTonnageMap: tonnageMap,
            }, this.updateTonnageValidation);
          });
      });
    }
  }

  getOrderRefs() {
    const { slot } = this.props;
    const slotId = get(slot, 'event.raw.slot.id');
    if (slotId) {
      setTimeout(() => {
        this.setState({ isFetchingOrderRefs: true });
      }, 100); // hack
      this.setState({ isFetchingOrderRefs: true }, () => {
        APIService.company_sites()
          .slots(slotId)
          .appendToUrl('orders/')
          .get(this.props.token)
          .then(refs => {
            this.setState({ orderRefs: refs, isFetchingOrderRefs: false });
          });
      });
    }
  }

  shouldApplyFilterFor(id) {
    return this.shouldShowField(id) && get(this.state.fields, `${id}.value`);
  }

  orderItems() {
    if (this.shouldApplyFilterFor('customer') || this.shouldApplyFilterFor('commodityId') || this.shouldApplyFilterFor('gradeId') || this.shouldApplyFilterFor('season'))
      return filter(this.state.orders, order => {
        let criteria = true;
        if (this.shouldApplyFilterFor('customer'))
          criteria = (order.customerName || '').toLowerCase() === (get(this.state.fields.customer, 'value', '') || '').toLowerCase();

        if (this.shouldApplyFilterFor('commodityId')) criteria = criteria && order.commodityId === this.state.fields.commodityId.value;

        let fixedGrades = map(order.spreadDetails, 'id');
        fixedGrades = [ ...fixedGrades, order.plannedGradeId];
        if (this.shouldApplyFilterFor('gradeId')) criteria = criteria && includes(fixedGrades, this.state.fields.gradeId.value);

        if (this.shouldApplyFilterFor('season')) criteria = criteria && order.season === this.state.fields.season.value;

        return criteria;
      });

    return this.state.orders;
  }

  filterStatuses() {
    if (includes(['booked', 'delayed'], get(this.props.slot, 'event.raw.slot.status')))
      this.STATUSES = reject(this.STATUSES, {id: 'planned'});
    if (get(this.props.slot, 'event.raw.slot.status') === 'in_progress')
      this.STATUSES = filter(this.STATUSES, status => {
        return !includes(['planned', 'booked'], status.id);
      });
    if (get(this.props.slot, 'event.raw.slot.status') === 'completed')
      this.STATUSES = filter(this.STATUSES, status => {
        return !includes(['planned', 'booked', 'in_progress'], status.id);
      });
  }

  getTimezoneOffsetMinutesToAdd(date) {
    const { selectedTimezone } = this.props;
    if(date && selectedTimezone) {
      const currentTimezoneOffset = date.utcOffset();
      const selectedTimezoneOffset = selectedTimezone.utcoffsetSeconds/60;
      if(currentTimezoneOffset !== selectedTimezoneOffset)
        return selectedTimezoneOffset - currentTimezoneOffset;
    }
  }

  initialMDate = (slot, attr) => {
    let date = get(slot, attr) || get(slot, `event.${attr}`)
    if(date && !isDate(date) && !isString(date))
      date = date.toDate()
    return moment(date)
  }

  setBoundaries(slot, cascade=true) {
    const newState = { ...this.state };
    const start = this.initialMDate(slot, 'start');
    let end = this.initialMDate(slot, 'end');
    if (start.toString() === end.toString() && !this.props.siteBooking) end.add(30, 'minutes');
    const timezoneOffset = this.getTimezoneOffsetMinutesToAdd(start);
    if(timezoneOffset) {
      start.add(timezoneOffset, 'minutes');
      end.add(timezoneOffset, 'minutes');
    }
    newState.fields.startDate.value = start.format('YYYY-MM-DD');
    newState.fields.startTime.value = start.format('HH:mm');
    newState.fields.endDate.value = end.format('YYYY-MM-DD');
    newState.fields.endTime.value = end.format('HH:mm');
    this.setState(newState, () => {
      if(cascade) {
        this.setFrequencyOptions();
        if (slot.event) {
          this.setAllFieldsValuesFromSelectedSlot(slot);
        }
      }
    });
  }

  isBookingOpenSlot() {
    return this.props.siteBooking && this.isSlotPlanned();
  }

  isSlotBooked() {
    return this.hasSlotStatus('booked');
  }

  isSlotRejected() {
    return this.hasSlotStatus('rejected');
  }

  isSlotPlanned() {
    return this.hasSlotStatus('planned');
  }

  isSlotInProgress() {
    return this.hasSlotStatus('in_progress');
  }

  isSlotDelayed() {
    return this.hasSlotStatus('delayed');
  }

  isSlotCompleted() {
    return this.hasSlotStatus('completed');
  }

  isSlotCancelled() {
    return this.hasSlotStatus('cancelled');
  }

  hasSlotStatus(status) {
    return get(this.props.slot, 'event.raw.slot.status') === status;
  }

  isFreightProvider() {
    return get(this.props.slot, 'event.raw.slot.isProvider');
  }

  isDeliverySite() {
    return get(this.props.slot, 'event.raw.slot.isDeliverySite');
  }

  isSubFreightProvider() {
    return get(this.props.slot, 'event.raw.slot.isSubProvider');
  }

  shouldSetMandatoryClauseFromSettings(slot) {
    const status = get(slot, 'event.raw.slot.status');
    return includes(['booked', 'completed', 'in_progress', 'delayed'], status) || (status === 'planned' && this.props.siteBooking);
  }


  shouldShowTruckConfig = () => this.shouldShowField('massLimitCode') && !isEmpty(this.state.truckDetails) && (get(this.props.slot, 'event.raw.slot.movementId') || (this.isNewSlot() ? !['planned', 'restricted'].includes(this.state.fields.status.value) : this.state.fields.truckId.value) || this.props.siteBooking)


  setMandatoryClause = newState => {
    const shouldShowTruckConfig = this.shouldShowTruckConfig()
    const additionalMassLimitCodeFields = get(this.state.pickupSite, 'additionalMassLimitCodes') || get(this.state.deliverySite, 'additionalMassLimitCodes')
    this.props.settings.fields.forEach(field => {
      if(field.id === 'massLimitCode' && field.mandatory) {
        if(shouldShowTruckConfig && !get(this.state.truckDetails, 'isFleet')) {
          newState.massLimitCode.validators = [required()];
          newState.fields.massLimitPermit.validators = additionalMassLimitCodeFields ? [] : [required()];
        }
        else
          newState.massLimitCode.validators = [];
      }
      else if (field.id === 'deliveryOrderNumber' && field.mandatory) {
        if (this.shouldShowOrderNumberField() && !this.shouldShowOrderIdField())
          newState.fields[field.id].validators = [required()];
      }
      else if (field.mandatory) newState.fields[field.id].validators = [required()];
    });
  }

  setAllFieldsValuesFromSelectedSlot(slot) {
    const newState = { ...this.state };

    newState.fields.freightProviderId.value = get(slot, 'event.raw.slot.freightProviderId', this.props.companyId);

    if (this.props.siteBooking && get(slot, 'event.raw.slot.status') === 'planned' && !newState.fields.freightProviderId.value)
      newState.fields.freightProviderId.value = this.props.currentUserCompanyId;

    newState.selectedCommodity = get(slot, 'event.raw.slot.commodity');
    newState.selectedGrade = get(slot, 'event.raw.slot.grade');
    newState.fields.season.value = get(slot, 'event.raw.slot.season');
    newState.selectedTruck = get(slot, 'event.raw.slot.truck');
    newState.selectedProvider = get(slot, 'event.raw.slot.freightProvider');
    newState.selectedSubProvider = get(slot, 'event.raw.slot.subFreightProvider');
    newState.selectedDriver = get(slot, 'event.raw.slot.driver');
    newState.fields.status.value = get(slot, 'event.raw.slot.status');
    newState.fields.excludedCommodityIds.value = get(slot, 'event.raw.slot.excludedCommodityIds') || [];
    newState.fields.excludedGradeIds.value = get(slot, 'event.raw.slot.excludedGradeIds') || [];
    newState.applySubFreightProvider = Boolean(newState.selectedSubProvider);
    newState.searchOption = get(slot,'event.raw.slot.freightProviderId') == get(slot, 'event.raw.slot.truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS;
    if(get(slot, 'event.raw.slot.status') === 'restricted') {
      newState.comment = slot.event.raw.slot.restrictionReason;
      newState.fields.restrictedVisibleToCarrier.value = slot.event.raw.slot.restrictedVisibleToCarrier;
    }

    [
      'siteId',
      'type',
      'bookingNumber',
      'deliveryOrderNumber',
      'commodityId',
      'gradeId',
      'tonnage',
      'driverId',
      'priority',
      'truckId',
      'customer',
      'pits',
      'season',
      'subFreightProviderId',
      'orderId',
    ].forEach(attr => {
      newState.fields[attr].value = get(slot, `event.raw.slot.${attr}`);
    });

    const existingOrderNumber = get(slot, 'event.raw.slot.deliveryOrderNumber');

    if ((this.shouldShowOrderIdField() || this.shouldShowOrderNumberField()) && existingOrderNumber) {
      newState.showProceedWithGivenOrderDialog = false;
      newState.selectedOrderIdentifier = existingOrderNumber;
    }

    if (this.shouldSetMandatoryClauseFromSettings(slot)) this.setMandatoryClause(newState)

    if (this.shouldShowOrderIdField()) {
      newState.fields.orderId.validators = [required()];
      newState.fields.deliveryOrderNumber.validators = [];
    }

    newState.update = !this.props.isNew;
    this.setState(newState, () => {
      //this.fetchTruck()
      this.getDrivers();
      let appendToUrl = this.state.fields.truckId.value ? `?include_truck_id=${this.state.fields.truckId.value}` : null;
      this.props.getCompanyTrucks(get(this.state.selectedSubProvider, 'id') || get(this.state.selectedProvider, 'id'), receiveTrucks, appendToUrl);
      this.props.getCompanyEmployeesMinimal(get(this.state.selectedSubProvider, 'id') || get(this.state.selectedProvider, 'id'), receiveEmployees);
    });
  }

  handleDialogClose() {
    this.setState({ showDialog: false }, () => {
      this.props.handleClose();
    });
  }

  cancelBooking = () => {
    const data = this.getSlotPayload();
    data.freightProviderId = null;
    data.subFreightProviderId = null;
    data.truckId = null;
    data.driverId = null;
    data.deliveryOrderNumber = null;
    this.props.slot.event.raw.slot.providerUpdatedFields.forEach(field => {
      if (!includes(['updated_at', 'updated_by_id', 'start', 'end', 'siteId'], field)) data[camelCase(field)] = null;
    });
    data.status = 'planned';
    delete data.isUrgentForProvider;
    delete data.isUrgentForManager;
    delete data.movementId;
    delete data.orderId;
    delete data.createdAt;
    data['updatedAt'] = this.props.slot.event.raw.slot.updatedAt;
    data['settingsUpdatedAt'] = this.props.slot.event.raw.slot.settingsUpdatedAt;
    this.props.isLoading('loaderDom');
    const truckConfig = this.state.truckDetails;
    data['truckConfig'] = {
      'category_id': get(truckConfig, 'categoryId'),
      'steer_point_5': get(truckConfig, 'steerPoint5'),
      'steer_1_point_1': get(truckConfig, 'steer1Point1')
    }
    if(this.isTrailerBooking() && this.state.bothSitesOrderBookingOn) {
      if(!this.isCounterSiteTrailerBookingEnabled()) {
        if (get(this.state.deliverySite, 'id') === this.state.fields.siteId.value)
          data['trailerSlots'] = {trailer1: Number(this.state.deliverySiteTrailers.trailer1.slotId), trailer2: Number(this.state.deliverySiteTrailers.trailer2.slotId), trailer3: Number(this.state.deliverySiteTrailers.trailer3.slotId)};
        else if (get(this.state.pickupSite, 'id') === this.state.fields.siteId.value)
          data['trailerSlots'] = {trailer1: Number(this.state.pickupSiteTrailers.trailer1.slotId), trailer2: Number(this.state.pickupSiteTrailers.trailer2.slotId), trailer3: Number(this.state.pickupSiteTrailers.trailer3.slotId)};
      }
    }
    this.props.isLoading('loaderDom');
    this.props.updateSlot(this.props.slot.event.id, data, () => {
      this.updateTruck()
      this.props.handleClose()
      this.props.forceStopLoader()
    }, null, null, this.props.startDate, this.props.endDate, get(this.props.slot, 'event.raw.site.companyId'));
  };

  handleCancelBooking() {
    if(this.props.siteBooking && get(this.props.slot, 'event.raw.slot.restrictSlotCancellation') && this.isStartTimeWithinNHours(get(this.props.slot, 'event.raw.slot.hoursBeforeCancellationStops', 0))) {
      alertifyjs.alert('Cancellation Not Allowed', `Slot cannot be cancelled within ${get(this.props.slot, 'event.raw.slot.hoursBeforeCancellationStops', 0)} hours of the booking time. Please contact Site Manager/Operator to cancel the booking.`, () => {});
    }
    else if (get(this.props.slot, 'event.raw.slot.onRoute') || isEqual(get(this.props.slot, 'event.raw.slot.movementStatus'), 'delivered')){
      let counterLoadType = this.isOutload() ? 'inload' : 'outload';
      let confirmMessage = `${capitalize(counterLoadType)} has been added at the ${this.isOutload() ? 'delivery': 'pickup'} site. If you cancel this slot then ${counterLoadType} details will become void. Are you sure you want to cancel this booking?`
      alertifyjs.confirm(
        'Warning',
        confirmMessage,
        this.cancelBooking,
        () => {}
      ).set(
        'labels', {ok: 'Yes', cancel: 'No'}
      ).show();
    }
    else {
      APIService.company_sites()
        .slots(get(this.props.slot, 'event.raw.slot.id'))
        .appendToUrl('cancel/')
        .get()
        .then(response => {
          let cannotCancelSlot = !isEmpty(get(response, 'reasons'));
          if (cannotCancelSlot && this.props.siteBooking)
            alertifyjs.alert('Cancellation Not Allowed', "You can't cancel this booking because truck load processing has started on the Site.", () => {});
          else {
            let confirmMessage = 'Are you sure you want to cancel this booking?'
            if (cannotCancelSlot && !this.props.siteBooking)
              confirmMessage = 'Truck load processing for this slot has started on the Site. Are you sure you want to cancel this booking?'
            alertifyjs.confirm(
              'Warning',
              confirmMessage,
              this.cancelBooking,
              () => {}
            ).set(
              'labels', {ok: 'Yes', cancel: 'No'}
            ).show();
          }
        })
    }
  }

  isCommentNeeded() {
    if(!this.state.comment) {
      return (!this.wasRejectedOrCancelled() && this.isRejectedOrCancelled()) || this.isRestricted();
    }

    return false;
  }

  getCommentReasonMessage() {
    if (this.state.comment && this.state.fields.status.value === 'rejected') return `Rejection Reason: ${this.state.comment}`;
    if (this.state.comment && this.state.fields.status.value === 'cancelled') return `Incompletion Reason: ${this.state.comment}`;

    return this.state.comment;
  }

  isStartTimeWithinNHours(N) {
    const slot_time = this.initialMDate(this.props.slot, 'start');
    const minutesFromStart = Math.abs(moment().diff(slot_time, 'minutes'));
    return moment() > slot_time ? true : minutesFromStart/60 <= N;
  }

  getMovementCreateData() {
    const data = {};
    data['assignToId'] = this.state.fields.driverId.value;
    data['driverId'] = this.state.fields.driverId.value;
    data['commodityId'] = this.state.fields.commodityId.value;
    data['communication'] = {'acceptanceRequired': false};
    data['customer'] = {
      'companyId': get(find(this.state.orders, {id: this.state.fields.orderId.value}), 'customerId')
    };
    data['estimatedDistance'] = this.state.totalDistance.value;
    data['estimatedTime'] = this.state.estimatedTime.value;
    const freightDelivery = {};
    freightDelivery['consignee'] = {
      'handlerId': this.state.fields.freightDelivery.consignee.handlerId.value
    };
    let utcDateTime = getDateTimeInUTC(this.state.fields.freightDelivery.date.value, this.state.fields.freightDelivery.timeStart.value);
    freightDelivery.dateTime = utcDateTime.dateTime;
    data['freightDelivery'] = freightDelivery;
    const freightPickup = {};
    freightPickup['consignor'] = {
      'handlerId': this.state.fields.freightPickup.consignor.handlerId.value
    };
    utcDateTime = getDateTimeInUTC(this.state.fields.freightPickup.date.value, this.state.fields.freightPickup.timeStart.value);
    freightPickup.dateTime = utcDateTime.dateTime;
    data['freightPickup'] = freightPickup;
    data['identifier'] = generateIdentifier('freight_movement');
    data['inloadSlotId'] = this.state.fields.deliverySlotId.value;
    data['outloadSlotId'] = this.state.fields.pickupSlotId.value;
    const deliverySlot = find(this.state.deliverySlots || [], {id: this.state.fields.deliverySlotId.value})
    const pickupSlot = find(this.state.pickupSlots || [], {id: this.state.fields.pickupSlotId.value})
    data['inloadSlotUpdatedAt'] = deliverySlot?.updatedAt
    data['outloadSlotUpdatedAt'] = pickupSlot?.updatedAt
    if (this.state.bothSitesOrderBookingOn) {
      if (this.isPickupSiteTrailerBookingEnabled())
      data['outloadTrailerSlots'] = {trailer1: Number(this.state.pickupSiteTrailers.trailer1.slotId), trailer2: Number(this.state.pickupSiteTrailers.trailer2.slotId), trailer3: Number(this.state.pickupSiteTrailers.trailer3.slotId)};
      if (this.isDeliverySiteTrailerBookingEnabled())
        data['inloadTrailerSlots'] = {trailer1: Number(this.state.deliverySiteTrailers.trailer1.slotId), trailer2: Number(this.state.deliverySiteTrailers.trailer2.slotId), trailer3: Number(this.state.deliverySiteTrailers.trailer3.slotId)};
    }
    data['orderId'] = this.state.fields.orderId.value;
    data['plannedGradeId'] = this.state.fields.gradeId.value;
    data['plannedTonnage'] = this.state.fields.tonnage.value;
    data['plannedTruckId'] = this.state.fields.truckId.value;
    data['providerId'] = this.state.fields.freightProviderId.value;
    data['season'] = this.state.fields.season.value;
    data['status'] = 'planned';
    data['typeId'] = FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY;
    if(this.state.selectedOrder?.commodityContractId) {
      data['typeId'] = FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER;
      data['commodityContractId'] = this.state.selectedOrder.commodityContractId;
    }
    const truckConfig = this.state.truckDetails;
    let category = find(this.state.categories, {id: get(truckConfig, 'categoryId')})
    data['truckConfig'] = {
      'category_id': get(truckConfig, 'categoryId'),
      'steer_point_5': get(truckConfig, 'steerPoint5'),
      'steer_1_point_1': get(truckConfig, 'steer1Point1'),
      'permit_number': this.state.permitChecked ? get(truckConfig, 'permitNumber') : null,
      'declared_mass_limit': (this.state.permitChecked || this.state.restrictedChecked) ? get(truckConfig, 'declaredMassLimit') : null,
      'accreditation_number': this.state.accreditationNumberChecked ? get(truckConfig, 'accreditationNumber') : null,
      'load_sharing': this.state.loadSharing,
      'notice_number': get(category, 'massLimitPermit') === 'Notice' ? get(truckConfig, 'noticeNumber'): null,
      'restricted': this.state.restrictedChecked ? get(truckConfig, 'restricted') : null
    }
    if (!isEmpty(this.state.chemicalApplications)) {
      data.chemicalApplications = this.state.chemicalApplications.map(chemicalApplication => {
        return {
          commodityId: chemicalApplication.commodityId,
          gradeId: chemicalApplication.gradeId,
          applicationFee: chemicalApplication.applicationRate
        }
      });
    }
    return data;
  }

  getMultiSlotsBothSiteBookingData() {
    const data = {}
    data['slots'] = this.state.multiSlotsBothSiteBooking
    data['freightProviderId'] = this.state.fields.freightProviderId.value;
    data['driverId'] = this.state.fields.driverId.value;
    data['commodityId'] = this.state.fields.commodityId.value;
    data['truckId'] = this.state.fields.truckId.value;
    data['subFreightProviderId'] = this.state.fields.subFreightProviderId.value;
    data['orderId'] = this.state.fields.orderId.value
    data['gradeId'] = this.state.fields.gradeId.value;
    data['tonnage'] = this.state.fields.tonnage.value;
    data['_enteredTonnage'] = this.state.fields.tonnage.value;
    data['season'] = this.state.fields.season.value;
    return data
  }

  isMultiSlotsValid() {
    const { multiSlotsBothSiteBooking, multiSlots } = this.state
    let result = false
    if(multiSlotsBothSiteBooking.length === multiSlots.length){
      const outloads = map(multiSlotsBothSiteBooking, 'outload')
      const inloads = map(multiSlotsBothSiteBooking, 'inload')
      result = outloads.length + inloads.length === multiSlots.length * 2
      if(result) {
        result = uniq(outloads).length === outloads.length && uniq(inloads).length === inloads.length
      }

    }
    return result
  }

  createMovement() {
    if (!this.isFormInvalid()) {
      if(this.isMultiSlotBooking()) {
        if(this.isMultiSlotsValid()) {
          this.props.isLoading('loaderDom');
          let data = this.getMultiSlotsBothSiteBookingData()
          APIService.freights().contracts().appendToUrl('bulk-booking/').post(data).then(response => {
            if(response.result) {
              alertifyjs.success('Successfully Booked!')
              this.props.onMultiSlotsClose()
              this.props.handleClose(true);
              this.props.forceStopLoader();
            } else {
              alertifyjs.error('An Error Occurred!')
              this.props.forceStopLoader();
            }
          })
        } else {
          alertifyjs.error('Please select all unique slots.')
        }
      } else {
        this.props.isLoading('loaderDom');
        this.props.create(this.getMovementCreateData(), () => {
          this.props.handleClose(true);
          this.props.forceStopLoader();
        });
      }
    }
  }

  getSlotDisplay = (slot, excludeDate) => {
    const start = this.initialMDate(slot, 'start');
    let end = this.initialMDate(slot, 'end');
    if (start.toString() === end.toString() && !this.props.siteBooking) end.add(30, 'minutes');
    const timezoneOffset = this.getTimezoneOffsetMinutesToAdd(start);
    if(timezoneOffset) {
      start.add(timezoneOffset, 'minutes');
      end.add(timezoneOffset, 'minutes');
    }
    let label = `${start.format(this.state.countryFormats.time)} - ${end.format(this.state.countryFormats.time)}`
    if(!excludeDate)
      label = `${start.format(this.state.countryFormats.date)} - ${label}`
    return label
  }

  __isValidCommodity = (commodityId, excludedCommodityIds) => {
    if(commodityId && !isEmpty(excludedCommodityIds) && isArray(excludedCommodityIds))
      return !includes(excludedCommodityIds, commodityId)
    return true
  }

  __isValidGrade = (gradeId, excludedGradeIds) => {
    if(gradeId && !isEmpty(excludedGradeIds) && isArray(excludedGradeIds))
      return !includes(excludedGradeIds, gradeId)
    return true
  }

  isValidCommodity() {
    const { multiSlots, fields, bothSitesOrderBookingOn, multiSlotsBothSiteBooking, pickupSlotsCache, deliverySlotsCache } = this.state
    const selectedCommodityId = fields.commodityId.value
    if(!selectedCommodityId)
      return true
    if(this.isMultiSlotBooking()) {
      if(bothSitesOrderBookingOn) {
        const allSlots = flatten([...multiSlots, ...values(deliverySlotsCache), ...values(pickupSlotsCache)])
        return every(multiSlotsBothSiteBooking, slotCombo => {
          return every(values(slotCombo), slotId => {
            const slot = find(allSlots, slot => includes([slotId, slotId.toString()], slot.id))
            const rawSlot = slot.raw ? slot.raw.slot : slot
            return this.__isValidCommodity(this.state.fields.commodityId.value, rawSlot.excludedCommodityIds)
          })
        })
      } else {
        return every(this.state.multiSlots, slot => this.__isValidCommodity(this.state.fields.commodityId.value, slot.raw.slot.excludedCommodityIds))
      }
    } else {
      return this.__isValidCommodity(this.state.fields.commodityId.value, this.state.fields.excludedCommodityIds.value)
    }
  }

  isValidGrade() {
    const { multiSlots, fields, bothSitesOrderBookingOn, multiSlotsBothSiteBooking, pickupSlotsCache, deliverySlotsCache } = this.state
    const selectedGradeId = fields.gradeId.value
    if(!selectedGradeId)
      return true
    if(this.isMultiSlotBooking()) {
      if(bothSitesOrderBookingOn) {
        const allSlots = flatten([...multiSlots, ...values(deliverySlotsCache), ...values(pickupSlotsCache)])
        return every(multiSlotsBothSiteBooking, slotCombo => {
          return every(values(slotCombo), slotId => {
            const slot = find(allSlots, slot => includes([slotId, slotId.toString()], slot.id))
            const rawSlot = slot.raw ? slot.raw.slot : slot
            return this.__isValidGrade(this.state.fields.gradeId.value, rawSlot.excludedGradeIds)
          })
        })
      } else {
        return every(this.state.multiSlots, slot => this.__isValidGrade(this.state.fields.gradeId.value, slot.raw.slot.excludedGradeIds))
      }
    } else {
      return this.__isValidGrade(this.state.fields.gradeId.value, this.state.fields.excludedGradeIds.value)
    }
  }

  isMassLimitFieldsValid() {
    const newState = {...this.state};
    let isValid = true;
    const ERROR_MESSAGE = "This field is required";
    const additionalMassLimitCodeFields = get(this.state.pickupSite, 'additionalMassLimitCodes') || get(this.state.deliverySite, 'additionalMassLimitCodes')
    if (additionalMassLimitCodeFields) {
      if (this.state.permitChecked) {
        if (!get(this.state.truckDetails, 'permitNumber')) {
          isValid = false;
          newState.truckDetailsErrors.permitNumber = ERROR_MESSAGE;
        }
        if (!get(this.state.truckDetails, 'declaredMassLimit')) {
          isValid = false;
          newState.truckDetailsErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      if (this.state.accreditationNumberChecked && !get(this.state.truckDetails, 'accreditationNumber')) {
        isValid = false;
        newState.truckDetailsErrors.accreditationNumber = ERROR_MESSAGE;
      }
      if (this.state.fields.massLimitPermit.value === 'Notice' && !get(this.state.truckDetails, 'noticeNumber')) {
        isValid = false;
        newState.truckDetailsErrors.noticeNumber = ERROR_MESSAGE;
      }
      if (this.state.restrictedChecked) {
        if (!get(this.state.truckDetails, 'restricted')) {
          isValid = false;
          newState.truckDetailsErrors.restricted = ERROR_MESSAGE;
        }
        if (!get(this.state.truckDetails, 'declaredMassLimit')) {
          isValid = false;
          newState.truckDetailsErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
    }
    return isValid;
  }

  isChemicalApplicationsValid() {
    let isValid = true;
    if (this.state.isBlended && !isEmpty(this.state.chemicalApplications)) {
      const newState = {...this.state};
      newState.chemicalApplications = this.state.chemicalApplications.map(chemicalApplication => {
        if (!chemicalApplication.commodityId || !chemicalApplication.gradeId || !chemicalApplication.applicationRate) {
          isValid = false;
          return {...chemicalApplication, errors: 'This field is required.'};
        }
        return {...chemicalApplication}
      })
      this.setState(newState);
    }
    return isValid;
  }

  handleSubmit() {
    this.setAllFieldsErrors();
    const isMassLimitFieldsValid = this.isMassLimitFieldsValid();
    const isChemicalApplicationsValid = this.isChemicalApplicationsValid();
    if (!isChemicalApplicationsValid)
      return
    if(this.isStartAfterEndTime())
      return
    if (!isMassLimitFieldsValid)
      return
    if(!isEmpty(this.state.massLimitCode.errors) || this.isLessTonnage() || this.isExcessTonnage())
      return
    if(!this.isValidCommodity()) {
      if (this.isMultiSlotBooking())
        alertifyjs.error(ORDER_COMMODITY_IN_SLOT_EXCLUDED_COMMODITY_MESSAGE, 5)
      return
    }
    if(!this.isValidGrade()) {
      if (this.isMultiSlotBooking())
        alertifyjs.error(ORDER_GRADE_IN_SLOT_EXCLUDED_GRADE_MESSAGE, 5)
      return
    }
    if(document.getElementsByClassName('slot-error-icon').length) {
      alertifyjs.error('Please select correct slots and try again', 5)
      return
    }
    if(this.checkTrailerSlotNeeded(true) || this.checkTrailerSlotNeeded(false))
      return
    if (this.state.bothSitesOrderBookingOn && (this.isMultiSlotBooking() || this.isSlotPlanned() || this.isNewSlot())) {
      this.createMovement()
    }
    else {
      this.focusOnFirstErrorField();
      if (this.isCommentNeeded()) {
        this.setState({ commentError: 'Please enter reason' });
        return;
      } else {
        this.setState({ commentError: null });
      }

      if (!this.isFormInvalid() && !this.state.commentError) {
        if(this.props.siteBooking && this.state.update && this.isSlotPlanned() && get(this.props.slot, 'event.raw.slot.restrictSlotCancellation') && this.isStartTimeWithinNHours(get(this.props.slot, 'event.raw.slot.hoursBeforeCancellationStops', 0))) {
          alertifyjs.confirm(
            'Warning',
            'Booking for this slot cannot be cancelled once booked. To cancel this booking, please contact Site Manager/Operator. Do you wish to proceed?',
            this.upsert,
            () => {}
          ).set(
            'labels', {ok: 'Yes', cancel: 'No'}
          ).show();
        } else if (this.state.update && this.state.isStatusChangedToCompletedForSlotWithTrailers) {
          alertifyjs.confirm(
            'Warning',
            'All trailer slots will be marked completed as well. Do you wish to proceed?',
            this.upsert,
            () => {}
          ).set('reverseButtons', true).set(
            'labels', {ok: 'Proceed', cancel: 'Cancel'}
          ).show()
        }
        else {
          this.upsert();
        }
      }
    }
  }

  upsert() {
    const data = this.getSlotPayload();
    if (this.isCompleted()) {
      const allowedTonnage = this.getAllowedTonnageOnOrder();
      if ((allowedTonnage || allowedTonnage === 0) && this.state.fields.tonnage.value > allowedTonnage) {
        if (!this.state.canUserAmend) {
          this.setState({
            fields: {
              ...this.state.fields,
              tonnage: {
                ...this.state.fields.tonnage,
                errors: [`Cannot be greater than ${parseFloat(allowedTonnage).toFixed(2)} ${this.state.unit}.`],
              },
            }
          })
          return;
        }
        const progressOrDeliveredTonnage = this.isOutload() ? this.getDeliveredTonnageOnOrder() + this.getProgressTonnageOnOrder() : this.getDeliveredTonnageOnOrder();
        const parentTonnage = min([this.state.maxAllowedTonnageForOrderAmend, get(this.state.selectedOrder, 'totalTonnageWithTolerance')]);
        const updateToTonnage = (this.isSlotCompleted() ? progressOrDeliveredTonnage - parseFloat(get(this.props.slot, 'event.raw.slot.tonnage')) : progressOrDeliveredTonnage) + parseFloat(this.state.fields.tonnage.value);
        let amendMessage = '';
        if (this.state.canAmendRelatedEntity) {
          amendMessage = `<div>This order can take up to only ${parentTonnage} ${this.state.unit} (Inc tolerance) and
          can be amended up to ${this.state.maxAllowedTonnageForOrderAmend || get(this.state.selectedOrder, 'totalTonnageWithTolerance')} ${this.state.unit} due to ${this.state.reason} <a rel="noopener noreferrer" target='_blank' href=${this.getEntityUrl('url')}>${this.getEntityUrl('identifier')}</a>. Please amend ${this.state.reason} first.</div>`
        }
        else {
          amendMessage = `<div>This order can take up to only ${parentTonnage} ${this.state.unit} (Inc tolerance) and
          can be amended up to ${this.state.maxAllowedTonnageForOrderAmend || get(this.state.selectedOrder, 'totalTonnageWithTolerance')} ${this.state.unit} due to ${this.state.reason} ${this.getEntityUrl('identifier')}</a>. Please contact the relevant party to amend ${this.state.reason}.</div>`
        }
        if (this.state.isOrderIndependent || parseFloat(updateToTonnage) < this.state.maxAllowedTonnageForOrderAmend) {
          alertifyjs.confirm(
            'Warning',
            `<div>This order can take up to only ${parentTonnage} ${this.state.unit} (Inc tolerance).
             Saving this slot will take the Order's ${this.countryTonnageLabel.toLowerCase()} to ${updateToTonnage} ${this.state.unit}. Do you want to automatically
             update the order ${this.countryTonnageLabel.toLowerCase()} to ${updateToTonnage} ${this.state.unit} on saving this slot?</div>`,
             () => {
              data.amendOrderToTonnage = updateToTonnage;
              this.confirmUpsert(data)
             },
             () => {}
          ).set({ labels: { ok: 'Yes, Proceed', cancel: 'Cancel' } }).set('reverseButtons', true)
        }
        else {
          alertifyjs.alert(
            'Warning',
            amendMessage,
            () => { }
          )
        }
      }
      else
        this.confirmUpsert(data)
    }
    else
      this.confirmUpsert(data)
  }

  confirmUpsert(data) {
    const isBooked = this.isSlotBooked();
    const isDelayed = this.isSlotDelayed();
    const isBookedOrDelayed = isBooked || isDelayed
    const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
    if(!this.isRestricted())
      data.comment = this.getCommentReasonMessage();
    if (this.state.update) {
      this.props.isLoading('loaderDom');
      if (get(this.state.deliverySite, 'id') === this.state.fields.siteId.value)
        data['trailerSlots'] = {trailer1: Number(this.state.deliverySiteTrailers.trailer1.slotId), trailer2: Number(this.state.deliverySiteTrailers.trailer2.slotId), trailer3: Number(this.state.deliverySiteTrailers.trailer3.slotId)};
      else if (get(this.state.pickupSite, 'id') === this.state.fields.siteId.value)
        data['trailerSlots'] = {trailer1: Number(this.state.pickupSiteTrailers.trailer1.slotId), trailer2: Number(this.state.pickupSiteTrailers.trailer2.slotId), trailer3: Number(this.state.pickupSiteTrailers.trailer3.slotId)};
      let payload = []
      if(this.isMultiSlotBooking()) {
        forEach(this.state.multiSlots, slot => {
          const rawSlot = slot.raw.slot
          payload.push({
            ...data, id: rawSlot.id, updatedAt: rawSlot.updatedAt, settingsUpdatedAt: rawSlot.settingsUpdatedAt,
            start: rawSlot.start, end: rawSlot.end
          })
        })
        this.props.bulkUpdateSlot(this.state.multiSlots[0].raw.slot.siteId, payload, response => {
          this.updateTruck()

          const slotIds = keys(response) || []
          if(slotIds.length === this.state.multiSlots.length) {
            const statuses = uniq(map(values(response), 'status')) || []
            if(statuses.length === 1 && statuses[0] === 200) {
              alertifyjs.success('Successfully Booked!')
              this.props.onMultiSlotsClose()
            }
            else {
              this.props.onMultiSlotsClose(response)
            }
          }
          else
            alertifyjs.error('An Error Occurred!')

          this.props.handleClose(true);
          this.props.forceStopLoader();
        });
      } else {
        data['updatedAt'] = this.props.slot.event.raw.slot.updatedAt;
        data['settingsUpdatedAt'] = this.props.slot.event.raw.slot.settingsUpdatedAt;
        const truckConfig = this.state.truckDetails;
        let category = find(this.state.categories, {id: get(truckConfig, 'categoryId')})
        if(this.isCounterSiteTrailerBookingEnabled()) {
          if (get(this.state.deliverySite, 'id') === this.state.fields.siteId.value)
            data['counterTrailerSlots'] = {trailer1: Number(this.state.pickupSiteTrailers.trailer1.slotId), trailer2: Number(this.state.pickupSiteTrailers.trailer2.slotId), trailer3: Number(this.state.pickupSiteTrailers.trailer3.slotId)};
          else if (get(this.state.pickupSite, 'id') === this.state.fields.siteId.value)
            data['counterTrailerSlots'] = {trailer1: Number(this.state.deliverySiteTrailers.trailer1.slotId), trailer2: Number(this.state.deliverySiteTrailers.trailer2.slotId), trailer3: Number(this.state.deliverySiteTrailers.trailer3.slotId)};
        }
        data['truckConfig'] = {
          'category_id': get(truckConfig, 'categoryId'),
          'steer_point_5': get(truckConfig, 'steerPoint5'),
          'steer_1_point_1': get(truckConfig, 'steer1Point1'),
          'permit_number': this.state.permitChecked ? get(truckConfig, 'permitNumber') : null,
          'declared_mass_limit': (this.state.permitChecked || this.state.restrictedChecked) ? get(truckConfig, 'declaredMassLimit') : null,
          'accreditation_number': this.state.accreditationNumberChecked ? get(truckConfig, 'accreditationNumber') : null,
          'load_sharing': this.state.loadSharing,
          'notice_number': get(category, 'massLimitPermit') === 'Notice' ? get(truckConfig, 'noticeNumber') : null,
          'restricted': this.state.restrictedChecked ? get(truckConfig, 'restricted') : null
        }
        const deliverySlotId = get(this.state.fields, 'deliverySlotId.value', '');
        const pickupSlotId = get(this.state.fields, 'pickupSlotId.value', '');
        let existingDeliverySlotId, existingPickupSlotId, pickupSiteId, deliverySiteId = '';
        if (slotType === 'outload') {
          existingPickupSlotId = this.props.slot.event.raw?.slot?.id || this.state.fields.pickupSlotId.value;
          existingDeliverySlotId = this.props?.slot?.event.raw?.slot?.counterSlotId || this.state.fields.deliverySlotId.value
          pickupSiteId = get(this.props, 'slot.event.raw.slot.siteId', '');
          deliverySiteId = this.state.counterSlotSiteId;
        }
        else {
          existingPickupSlotId = this.props?.slot?.event.raw?.slot?.counterSlotId || this.state.fields.pickupSlotId.value
          existingDeliverySlotId = this.props.slot.event.raw?.slot?.id || this.state.fields.deliverySlotId.value
          pickupSiteId = this.state.counterSlotSiteId;
          deliverySiteId = get(this.props, 'slot.event.raw.slot.siteId', '');
        }
        const isDeliverySlotUpdated = get(this.props.slot, 'event.raw.slot.movementId') && isBookedOrDelayed && !isEqual(existingDeliverySlotId, deliverySlotId);
        const isPickupSlotUpdated = get(this.props.slot, 'event.raw.slot.movementId') && isBookedOrDelayed && !isEqual(existingPickupSlotId, pickupSlotId);
        if (isDeliverySlotUpdated && isPickupSlotUpdated){
          data['counterSlotUpdatedAt'] = this.state.counterSlotUpdatedAt;
          data['counterSlotSettingsUpdatedAt'] = this.state.counterSlotSettingsUpdatedAt;
          if (slotType === 'outload'){
            this.props.updateSlot(existingPickupSlotId, data, () => {
              this.updateTruck(this.state.fields.deliverySlotId.value)
              this.props.handleClose(true);
              this.props.forceStopLoader();
            }, this.state.fields.pickupSlotId.value, this.state.fields.deliverySlotId.value,  this.props.startDate, this.props.endDate, get(this.props.slot, 'event.raw.site.companyId'));
          }
          else {
            this.props.updateSlot(existingDeliverySlotId, data, () => {
              this.updateTruck(this.state.fields.deliverySlotId.value)
              this.props.handleClose(true);
              this.props.forceStopLoader();
            }, this.state.fields.deliverySlotId.value, this.state.fields.pickupSlotId.value,  this.props.startDate, this.props.endDate, get(this.props.slot, 'event.raw.site.companyId'));
          }
        }
        else if (isDeliverySlotUpdated){
          data['type'] = 'inload'
          data['siteId'] = deliverySiteId
          if(slotType === 'outload') {
            data.updatedAt = this.state.counterSlotUpdatedAt
            data.settingsUpdatedAt = this.state.counterSlotSettingsUpdatedAt
            data.trailerSlots = data?.counterTrailerSlots
            delete data.counterTrailerSlots
            delete data.counterSlotUpdatedAt
            delete data.counterSlotSettingsUpdatedAt
          }
          this.props.updateSlot(existingDeliverySlotId, data, () => {
            this.updateTruck(this.state.fields.deliverySlotId.value)
            this.props.handleClose(true);
            this.props.forceStopLoader();
          }, this.state.fields.deliverySlotId.value, null, this.props.startDate, this.props.endDate, get(this.props.slot, 'event.raw.site.companyId'));
        }
        else if (isPickupSlotUpdated){
          data['type'] = 'outload'
          data['siteId'] = pickupSiteId
          if(slotType === 'inload') {
            data.updatedAt = this.state.counterSlotUpdatedAt
            data.settingsUpdatedAt = this.state.counterSlotSettingsUpdatedAt
            data.trailerSlots = data?.counterTrailerSlots
            delete data.counterTrailerSlots
            delete data.counterSlotUpdatedAt
            delete data.counterSlotSettingsUpdatedAt
          }
          this.props.updateSlot(existingPickupSlotId, data, () => {
            this.updateTruck(this.state.fields.pickupSlotId.value)
            this.props.handleClose(true);
            this.props.forceStopLoader();
          }, this.state.fields.pickupSlotId.value, null, this.props.startDate, this.props.endDate, get(this.props.slot, 'event.raw.site.companyId'));
        }
        else {
          this.props.updateSlot(this.props.slot.event.id, data, () => {
            this.updateTruck()
            this.props.handleClose();
            this.props.forceStopLoader();
          }, null, null, this.props.startDate, this.props.endDate, get(this.props.slot, 'event.raw.site.companyId'));
        }
      }
    } else {
      if (this.isMultipleSlot()) {
        this.props.isLoading('loaderDom');
        let slots = this.getMultiSlots(data) || [];
        if (slots.length > 0) {
        this.props.createSlots(data.siteId, slots, () => {
            this.updateTruck()
            this.props.handleClose(true);
            this.props.forceStopLoader();
          });
        }
      } else {
        this.props.isLoading('loaderDom');
        this.props.createSlots(data.siteId, data, (response) => {
          this.updateTruck(get(response, 'ids.0'))
          this.props.handleClose(true);
          this.props.forceStopLoader();
        });
      }
    }
  }

  handleMarkBooked() {
    const { slot } = this.props;
    const slotId = get(slot, 'event.raw.slot.id');
    if (slotId) {
      if (get(slot, 'event.raw.slot.status') === 'completed' && get(slot, 'event.raw.slot.movementStatus') === 'completed') {
        alertifyjs.alert('Permission Denied', "You cannot mark this slot booked as the movement is in Completed status. Please mark the movement as delivered from the movement details page and then mark this slot as Booked");
      }
      else {
        APIService.company_sites()
          .slots()
          .appendToUrl('mark-booked/')
          .put({ 'ids': [slotId] }, this.props.token)
          .then(data => {
            if(get(data.success, [0]) && get(data.success, [0]) == slotId)
            {
              this.updateTruck()
              this.props.receiveFreightSlotUpdated(data.item);
              this.props.handleClose();
              this.props.forceStopLoader();
            }
            else if(get(data.errors, [0]) && get(data.errors, [0]) == slotId)
            {
              if(get(data, 'errorReason'))
              {
                alertifyjs.alert(
                  'Error',
                  get(data, 'errorReason'),
                  () => this.props.handleClose(),
                ).show();
              }
              else
                alertifyjs.error('Update Failed!');
            }
          });
      }
    }
  }

  getSlotsByCombinationOfRepeatOccurrenceAndFrequency = data => {
    let slots = this.getSlotsByRepeatOccurrence(data)
    const start = moment(data.start);
    const end = moment(data.end);
    const timeBoundary = end.diff(start, 'minutes');
    const frequency = this.state.fields.frequency.value;
    const frequencySlotsCount = timeBoundary / frequency;
    if(slots.length * frequencySlotsCount <= MAX_SLOTS) {
      return flatten(map(slots, this.getSlotsByFrequency))
    } else {
      this.props.forceStopLoader()
      alertifyjs.alert('Warning', `You are trying to create more than ${MAX_SLOTS} slots. Please adjust the duration.`, () => {});
    }
  }

  getMultiSlots(data) {
    if (this.state.repeat && !this.state.fields.frequency.value) return this.getSlotsByRepeatOccurrence(data);
    if (!this.state.repeat && this.state.fields.frequency.value) return this.getSlotsByFrequency(data);
    if (this.state.repeat && this.state.fields.frequency.value) return this.getSlotsByCombinationOfRepeatOccurrenceAndFrequency(data);
  }

  getSlotsByRepeatOccurrence(data) {
    let multiSlotData = [];
    const repeatDetails = cloneDeep(this.state.repeatDetails);
    if(!repeatDetails)
      return multiSlotData
    let totalOccurrences, mEndsOn, endRepeatDate;
    const isWeekly = repeatDetails.interval === WEEK;
    const interval = repeatDetails.intervalOccurrence;
    const intervalPeriod = repeatDetails.interval;
    const mStart = moment(data.start);
    const mEnd = moment(data.end);
    const onSpecificDaysOfWeek = isWeekly && isArray(repeatDetails.on) && repeatDetails.on.length > 0;
    const endsOnDate = repeatDetails.ends === this.ENDS_ON;
    const endsOnAfterOccurrences = repeatDetails.ends === this.ENDS_AFTER;
    const totalOccurrencesInDuration = onSpecificDaysOfWeek && isArray(repeatDetails.on) ? repeatDetails.on.length : 1;

    if (endsOnDate) {
      repeatDetails.endsOn = dateTimeToUTC(repeatDetails.endsOn, MIDNIGHT);
      mEndsOn = moment(repeatDetails.endsOn);
      totalOccurrences = Infinity;
      endRepeatDate = mEndsOn.clone();
    } else if (endsOnAfterOccurrences) {
      totalOccurrences = max([parseInt(repeatDetails.endsAfter), 1]);
      endRepeatDate = mStart.clone().add(totalOccurrences * interval, intervalPeriod);
    }
    let exitLoop = false
    for (
      let dateIndex = mStart.clone(), endDateIndex = mEnd.clone();
      dateIndex <= endRepeatDate;
      dateIndex.add(interval, intervalPeriod), endDateIndex.add(interval, intervalPeriod)
    ) {
      if (exitLoop) break;
      times(totalOccurrencesInDuration, i => {
        let startDate, endDate;
        if (onSpecificDaysOfWeek) {
          let day = repeatDetails.on[i];
          let dayIndex = dateIndex.day() > day ? 7 + day : day;
          startDate = dateIndex.clone().day(dayIndex);
          endDate = endDateIndex.clone().day(dayIndex);
          if(endDate.isBefore(startDate))
            endDate = startDate.clone().add(endDateIndex.diff(dateIndex, 'minutes'), 'minutes')
        } else {
          startDate = dateIndex.clone();
          endDate = endDateIndex.clone();
        }
        let payload = cloneDeep(data);
        payload.start = startDate.toISOString();
        payload.end = endDate.toISOString();

        if (this.isNewSlot() && this.state.comment) payload.comment = this.state.comment;
        if(this.isRestricted()) {
          payload.restrictionReason = this.state.comment;
          delete payload.comment;
        }
        if (multiSlotData.length < MAX_SLOTS) {
          if (startDate <= endRepeatDate && isArray(multiSlotData) && multiSlotData.length < totalOccurrences) multiSlotData.push(payload);
        } else if (!this.state.fields.frequency.value) {
          this.props.forceStopLoader()
          alertifyjs.alert('Warning', `You are trying to create more than ${MAX_SLOTS} slots. Please adjust the duration`, () => {});
          multiSlotData = [];
          exitLoop = true
          return;
        }
      });
    }
    return multiSlotData;
  }

  getSlotsByFrequency(data) {
    let multiSlotData = [];
    const start = moment(data.start);
    const end = moment(data.end);
    const timeBoundary = end.diff(start, 'minutes');
    const frequency = this.state.fields.frequency.value;
    const slotsCount = timeBoundary / frequency;
    if (slotsCount <= MAX_SLOTS) {
    times(slotsCount, i => {
      let payload = cloneDeep(data);
      delete payload.start;
      delete payload.end;
      let slotStart = start.clone().add(frequency * i, 'minutes');
      let slotEnd = slotStart.clone().add(frequency, 'minutes');
      payload.start = slotStart.toISOString();
      payload.end = slotEnd.toISOString();

      if (this.isNewSlot() && this.state.comment) payload.comment = this.state.comment;
      if(this.isRestricted()) {
        payload.restrictionReason = this.state.comment;
        delete payload.comment;
      }
      multiSlotData.push(payload);
    });
    return multiSlotData;
    } else {
      this.props.forceStopLoader()
      alertifyjs.alert('Warning', `You are trying to create more than ${MAX_SLOTS} slots. Please adjust the duration`, () => {});
    }
  }

  handleDelete() {
    const hasSiblings = get(this.props.slot, 'event.raw.slot.hasSiblings', false);
    if (hasSiblings) this.openDeleteDialog();
    else this.props.deleteSlot(this.props.slot.event.raw.slot.siteId, this.props.slot.event.raw.slot.id, this.props.handleClose);
  }

  openDeleteDialog() {
    this.setState({ showDelete: true });
  }

  closeDeleteDialog() {
    this.setState({ showDelete: false });
  }

  onDelete() {
    this.setState({ showDelete: false }, this.handleDialogClose);
  }
  onDeleteFailure = () => this.setState({ showDelete: false })

  getSlotPayload() {
    const data = mapValues(this.state.fields, 'value');
    this.setDatesToUTC(data);

    if (this.isMultipleSlot()) delete data.bookingNumber;
    if (this.isSlotPlanned() && this.props.siteBooking) {
      data.status = 'booked';
      delete data.excludedCommodityIds
      delete data.excludedGradeIds
    }

    if(!this.isSlotPlanned() && !this.isNewSlot()) {
      delete data.excludedCommodityIds
      delete data.excludedGradeIds
    }

    delete data.massLimitPermit
    delete data.ghms

    delete data.frequency;
    delete data.startDate;
    delete data.startTime;
    delete data.endDate;
    delete data.endTime;

    delete data.freightDelivery;
    delete data.freightPickup;
    delete data.pickupSlotId;
    delete data.deliverySlotId;
    if(!data.gradeId)
      data.gradeId = null
    if(!data.commodityId)
      data.commodityId = null

    if (this.isNewSlot() && this.state.comment) {
      data.comment = this.state.comment;
    }

    if(this.isRestricted()) {
      data.restrictionReason = this.state.comment;
      delete data.comment;
    }
    if (data.status !== 'planned' && !data.freightProviderId && this.state.selectedTruck)
      data.freightProviderId = get(this.state.selectedTruck, 'companyId')

    data._enteredTonnage = data.tonnage
    if (!isEmpty(this.state.chemicalApplications)) {
      data.chemicalApplications = this.state.chemicalApplications.map(chemicalApplication => {
        return {
          commodityId: chemicalApplication.commodityId,
          gradeId: chemicalApplication.gradeId,
          applicationFee: chemicalApplication.applicationRate
        }
      });
    }
    else
      data.chemicalApplications = [];
    return data;
  }

  setDatesToUTC(data) {
    data.start = this.toUTC(data.startDate, data.startTime)
    data.end = this.toUTC(data.endDate, data.endTime)
  }

  toUTC(date, time) {
    if(this.props.selectedTimezone) {
      const mDate = moment(date + ' ' + time);
      return mDate.subtract(this.props.selectedTimezone.utcoffsetSeconds, 'seconds').format('YYYY-MM-DD HH:mm:ss') + 'Z';
    } else {
      return dateTimeToUTC(date, time)
    }
  }

  isFormInvalid() {
    return some(this.state.fields, (field, key) => {
      if (includes(['orderId', 'tonnage'], key) && ((!isEmpty(field.errors) && startsWith(field.errors[0], 'Warning')) || (field.value != null && this.props.slot?.event?.raw?.slot?.id && field.value == get(this.props.slot, `event.raw.slot.${key}`)))) return false;
      if (includes(['orderId', 'pickupSlotId', 'deliverySlotId'], key) && !isEmpty(field.errors)) return true;
      if (key === 'freightProviderId' && this.state.searchOption === SEARCH_BY_ALL_REGOS && this.state.selectedTruck  && !isEmpty(field.errors)) return false;
      if (key == 'subFreightProviderId' && this.state.searchOption != SEARCH_BY_TRUCK_OWNER_REGOS && isEmpty(field.value)) return false;
      if (key === 'deliveryOrderNumber' && this.state.fields.orderId.value && isEmpty(field.value)) return false;
      return some(field.validators, validator => {
        return validator.isInvalid(field.value);
      });
    });
  }

  isNewSlot() {
    return this.props.isNew;
  }

  isFPFieldDisabled() {
    return ((!this.isNewSlot() && !this.isSlotPlanned() && !this.isMultiSlotBooking()) || this.props.siteBooking || get(this.state.selectedOrder, 'providerId'));
  }

  isCompleted() {
    return this.state.fields.status.value === 'completed';
  }

  trailerBookingQuantity = isPickup => {
    const isOrderDeliverySite = get(this.state.deliverySite, 'id') === this.state.fields.siteId.value;
    const pickupSiteSMSettings = isOrderDeliverySite ? this.state.counterSlotSmSetting : this.props.settings;
    const deliverySiteSMSettings = isOrderDeliverySite ? this.props.settings : this.state.counterSlotSmSetting;
    return isPickup ? pickupSiteSMSettings?.trailerBookingQuantity : deliverySiteSMSettings?.trailerBookingQuantity
  }

  checkTonnageExceedTrailerBookingQuantity = isPickup => {
    const quantity = this.trailerBookingQuantity(isPickup)
    return quantity && this.state.fields.tonnage.value && quantity <= this.state.fields.tonnage.value
  }

  checkTrailerSlotNeeded = isPickup => {
    let trailerBookingEnabled = isPickup ? this.isPickupSiteTrailerBookingEnabled() : this.isDeliverySiteTrailerBookingEnabled()
    let trailers = isPickup ? this.state.pickupSiteTrailers : this.state.deliverySiteTrailers
    return trailerBookingEnabled && this.checkTonnageExceedTrailerBookingQuantity(isPickup) && isEmpty(compact(map(trailers, 'slotId'))) 
  }

  setAllFieldsErrors() {
    if (this.shouldShowOrderIdField() && !this.isSlotBooked() && isEmpty(this.state.fields.orderId.errors)) this.handleOrderBlur(true);
    const newState = { ...this.state };
    forEach(newState.fields, (field, key) => {
      if (includes(['orderId', 'pickupSlotId', 'deliverySlotId'], key)) {
        if (isEmpty(field.errors) || field.errors[0].startsWith('Warning:')) field.errors = this.getFieldErrors(key);
      } else if (key == 'tonnage' && field.value != null && field.value == get(this.props.slot, 'event.raw.slot.tonnage') && !this.props.isNew)
          field.errors = [];
      else
          field.errors = this.getFieldErrors(key);
    });

    if(!newState.truckDetails.code && !isEmpty(newState.massLimitCode.validators)) {
      newState.massLimitCode.errors = [newState.massLimitCode.validators[0].message]
    } else {
      newState.massLimitCode.errors = []
    }
    newState.pickupSlotTrailerErrors = this.checkTrailerSlotNeeded(true) ? 'Please select a trailer slot' : null
    newState.deliverySlotTrailerErrors = this.checkTrailerSlotNeeded(false) ? 'Please select a trailer slot' : null
    if(this.isStartAfterEndTime())
      newState.fields.endTime.errors = ["Can't be before Start Time"]

    this.setState(newState, () => {
      if (this.isExcessTonnage())
        this.setState({
          fields: {
            ...this.state.fields,
            tonnage: {
              ...this.state.fields.tonnage,
              errors: [`${this.countryTonnageLabel} cannot be greater than Order ${this.countryTonnageLabel.toLowerCase()}`],
            },
          },
        });
      if (this.isLessTonnage())
        this.setState({
          fields: {
            ...this.state.fields,
            tonnage: {
              ...this.state.fields.tonnage,
              errors: [`Can't book less than ${this.getMinimumTonnage()} ${this.state.unit}`],
            },
          },
        });
    });
  }

  getFieldErrors(key) {
    const errors = [];
    const value = get(this.state.fields, `${key}.value`);
    const validators = get(this.state.fields, `${key}.validators`) || [];
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    if(key === 'tonnage') {
      const maxTonnage = this.isCompleted() ? this.getAllowedTonnageOnOrder() : this.getMaxTonnage()
      if (value && (this.props.isDuplicate || !this.state.canUserAmend) && value > maxTonnage)
        errors.push(`Cannot be greater than ${maxTonnage.toFixed(2)} ${this.state.unit}`);
    }
    if (key === 'commodityId' && value && includes(this.state.fields.excludedCommodityIds.value, value))
      errors.push(ORDER_COMMODITY_IN_SLOT_EXCLUDED_COMMODITY_MESSAGE)
    if (key === 'gradeId' && value && includes(this.state.fields.excludedGradeIds.value, value))
      errors.push(ORDER_GRADE_IN_SLOT_EXCLUDED_GRADE_MESSAGE)
    return errors;
  }

  handleCustomerChange(value) {
    const newState = { ...this.state };
    set(newState, 'fields.customer.value', value || null);
    if (newState.selectedOrder && value && get(newState.selectedOrder, 'customerName') !== value)
      newState.selectedOrder = null;
    else {
      const selectedOrder = this.getSelectedOrderFromIdentifier(null, this.state.orders);
      newState.selectedOrder = selectedOrder;
      newState.fields.orderId.value = get(selectedOrder, 'id');
    }
    newState.isOnlyValidatingCustomer = true
    this.setState(newState, this.handleOrderBlur);
  }

  handleMultiCommodityChange = (id, selectedCommodities) => {
    const ids = map(selectedCommodities, 'id')
    if(!isEqual(this.state.fields.excludedCommodityIds.value, ids)) {
      const newState = {...this.state}
      newState.fields.excludedCommodityIds.value = ids
      newState.fields.gradeId.value = null
      if (newState.selectedOrder && !includes(ids, get(newState.selectedOrder, 'commodityId')))
        newState.selectedOrder = null;
      else {
        const selectedOrder = this.getSelectedOrderFromIdentifier(null, this.state.orders);
        newState.selectedOrder = selectedOrder;
        newState.fields.orderId.value = get(selectedOrder, 'id');
      }
      this.setState(newState, () => this.updateGradesExclusionList(this.handleOrderBlur));
    }
  }

  handleMultiGradeChange = (id, selectedGrades) => {
    const ids = map(selectedGrades, 'id')
    if(!isEqual(this.state.fields.excludedGradeIds.value, ids)) {
      const newState = {...this.state}
      newState.fields.excludedGradeIds.value = ids
      if (newState.selectedOrder && !includes(ids, get(newState.selectedOrder, 'plannedGradeId')))
        newState.selectedOrder = null;
      else {
        const selectedOrder = this.getSelectedOrderFromIdentifier(null, this.state.orders);
        newState.selectedOrder = selectedOrder;
        newState.fields.orderId.value = get(selectedOrder, 'id');
      }
      this.setState(newState, this.handleOrderBlur);
    }
  }

  handleCommodityChange(value, id, item) {
    if (this.state.fields.commodityId.value != value) {
      const newState = { ...this.state };
      newState.selectedCommodity = item;
      newState.fields.commodityId.value = value;
      newState.fields.gradeId.value = null;
      if (value) newState.fields.commodityId.errors = [];
      if (newState.selectedOrder && get(newState.selectedOrder, 'commodityId') !== get(item, 'id'))
        newState.selectedOrder = null;
      else {
        const selectedOrder = this.getSelectedOrderFromIdentifier(null, this.state.orders);
        newState.selectedOrder = selectedOrder;
        newState.fields.orderId.value = get(selectedOrder, 'id');
      }

      this.setState(newState, () => this.updateGradesExclusionList(() => this.handleOrderBlur(true)));
    }
  }

  regoCallback = data => {
    if(data?.isAvailable) {
      let config = getCountryConfig();
      let payload = {
        rego: data?.rego.toUpperCase(),
        tareWeight: get(config, 'truck.tareWeight'),
        grossWeight: get(config, 'truck.grossWeight')
      }
      let companyId = this.state.searchOption == SEARCH_BY_ALL_REGOS ? config?.systemCompanyId : (this.state.searchOption == SEARCH_BY_TRUCK_OWNER_REGOS ? this.state.fields.subFreightProviderId.value : this.state.fields.freightProviderId.value);
      this.props.createTruck(companyId, payload, addTruck);
    }
  }

  handleTruckChange(value, id, item) {
    if (item && item?.inputValue && !(item?.inputValue.length < 4 || item?.inputValue.length > 10)) {
      this.props.validateRego(id, item?.inputValue, this.regoCallback);
    } else {
    if (this.state.fields.truckId.value !== value || Boolean(this.props.createdTruck)) {
      const newState = { ...this.state };
      newState.selectedTruck = item;
      newState.fields.truckId.value = value;
      if(this.props.createdTruck && this.props.createdTruck?.id != value)
        newState.fields.truckId.value = this.props.createdTruck?.id
      if(!item) {
        newState.truckDetails = {}
        newState.fields.massLimitPermit.value = ''
        newState.fields.ghms.value = false
      }
      if (value) newState.fields.truckId.errors = [];
      const companyId = get(item, 'companyId');
      if (newState.fields.freightProviderId.value && companyId && companyId !== newState.fields.freightProviderId.value) {
        if (get(newState.selectedSubProvider, 'companyId') !== companyId) newState.selectedSubProvider = null;
        newState.fields.subFreightProviderId.value = companyId;
        newState.applySubFreightProvider = true;
      }
      else if (companyId === newState.fields.freightProviderId.value) {
        newState.fields.subFreightProviderId.value = null;
        newState.applySubFreightProvider = false;
      }
      newState.bothSitesOrderBookingOn = this.isBothSiteOrderBookingOn(null, null, newState.selectedTruck)
      if (get(item, 'assignedDriverId'))
        newState.fields.driverId.value = get(item, 'assignedDriverId');
      else if (get(newState.selectedDriver, 'truckId'))
        newState.fields.driverId.value = undefined;
      this.setState(newState, () => {
        if(!this.props.createdTruck) {
          this.fetchTruck();
        }
        this.getDrivers();
        if(this.props.createdTruck)
          this.props.emptyCreatedTruck();
        this.setState({bothSitesOrderBookingOn: this.isBothSiteOrderBookingOn(null, null, item)}, () => {
          this.updateSlotValidators()
          if(this.state.orderUnaccountedTonnage > 0){
            this.showBookViaMovementPopup();
          }
          if(this.state.fields.truckId.value) {
            setTimeout(() => {
              const el = document.getElementsByClassName('freight-slot-form-content')[0]
              if(el)
                el.scrollTop = el.scrollHeight
            }, 300)
          }
        })
      });
    } else {
      let bothSiteOrderBooking = this.isBothSiteOrderBookingOn()
      if(bothSiteOrderBooking !== this.state.bothSitesOrderBookingOn)
        this.setState({bothSitesOrderBookingOn: bothSiteOrderBooking}, this.updateSlotValidators)
    }
  }
  }

  handleDriverChange(value, id, item) {
    if (this.state.fields.driverId.value !== value) {
      const newState = { ...this.state };
      newState.selectedDriver = item;
      newState.fields.driverId.value = value;
      if (value) newState.fields.driverId.errors = [];
      if (!this.props.siteBooking) {
        const companyId = get(item, 'companyId');
        if (companyId && companyId !== newState.fields.freightProviderId.value) {
          if (get(newState.selectedSubProvider, 'companyId') !== companyId) newState.selectedSubProvider = null;
          newState.fields.subFreightProviderId.value = companyId;
        }
      }
      if (get(newState.selectedDriver, 'truckId')) {
        newState.fields.truckId.value = get(newState.selectedDriver, 'truckId');
        newState.selectedTruck = find(this.props.trucks, {id: get(newState.selectedDriver, 'truckId')});
      }
      else if (get(newState.selectedTruck, 'assignedDriverId')) {
        newState.fields.truckId.value = undefined;
        newState.selectedTruck = null;
      }
      this.setState(newState);
    }
  }

  handleGradeChange(item) {
    const value = get(item, 'id');
    if (item && this.state.fields.gradeId.value != value) {
      const newState = { ...this.state };
      newState.selectedGrade = item;
      newState.fields.gradeId.value = value;
      if (value) newState.fields.gradeId.errors = [];
      let fixedGrades = map(get(newState.selectedOrder, 'spreadDetails'), 'id');
      fixedGrades = [ ...fixedGrades, get(newState, 'selectedOrder.plannedGradeId')];
      if (newState.selectedOrder && !includes(fixedGrades, get(item, 'id')))
        newState.selectedOrder = null;
      else {
        const selectedOrder = this.getSelectedOrderFromIdentifier(null, this.state.orders);
        newState.selectedOrder = selectedOrder;
        newState.fields.orderId.value = get(selectedOrder, 'id');
      }

      this.setState(newState, this.handleOrderBlur(true));
    }
  }

  handleSeasonChange(value) {
    const newState = { ...this.state };
    set(newState, 'fields.season.value', value || null);
    if (value) newState.fields.season.errors = [];
    this.setState(newState, this.handleOrderBlur(true));
  }

  handleMillPriorityChange(e) {
    const newState = { ...this.state };
    newState.fields.priority.value = e.target.checked;
    this.setState(newState);
  }

  handleRestrictedVisibleToCarrierChange(e) {
    const newState = { ...this.state };
    newState.fields.restrictedVisibleToCarrier.value = e.target.checked;
    this.setState(newState);
  }

  handleRepeatSelectFieldChange(value) {
    const newState = { ...this.state };
    newState.repeatSelectValue = value;
    if (value === 1) {
      newState.repeat = false;
      newState.fields.frequency.value = null;
    } else if (value === 2) {
      newState.repeat = false;
      newState.fields.frequency.value = 15;
    } else if (value === 3) {
      newState.repeat = true;
      newState.fields.frequency.value = null;
    }

    this.setState(newState, this.updateTonnageValidation);
  }

  handleRepeatDetailsChange(details) {
    this.setState({ repeatDetails: details }, () => {
      this.setFrequencyOptions()
      this.updateTonnageValidation()
    });
  }

  handleRepeatCheckBoxChange(e) {
    this.setState({ repeat: e.target.checked }, this.updateTonnageValidation);
  }

  useBookieTrucksAndDrivers() {
    return !this.state.applySubFreightProvider && this.props.siteBooking && this.props.currentUserCompanyId;
  }

  handleFreightProviderChange(value, id, item) {
    if (value !== this.state.fields.freightProviderId.value) {
      const newState = { ...this.state };
      newState.fields.truckId.value = undefined;
      newState.fields.driverId.value = undefined;
      newState.fields.freightProviderId.value = value;
      if (value) newState.fields.freightProviderId.errors = [];
      newState.selectedProvider = item;
      this.setState(newState, () => {
        if (!value)
          this.handleSearchOptionChange(null, SEARCH_BY_FREIGHT_PROVIDER_REGOS);
        this.props.getCompanyTrucks(value, receiveTrucks);
        this.props.getCompanyEmployeesMinimal(value, receiveEmployees);
        this.getDrivers();
      });
    }
  }

  setSelectedOrderFromSelectedIdentifier(identifier) {
    const selectedOrder = this.getSelectedOrderFromIdentifier(identifier);
    if (selectedOrder) this.setState({ selectedOrder: selectedOrder }, this.handleOrderBlur);
  }

  getSelectedOrderFromIdentifier(identifier, orders) {
    identifier = (identifier || this.state.selectedOrderIdentifier || '').toUpperCase();
    return find(orders || this.orderItems(), { identifier: identifier });
  }

  getMaxTonnage() {
    let tonnage = this.maximumUnaccountedTonnage();

    if (tonnage) return parseFloat(parseFloat(tonnage / this.getTotalSlotsCounts()).toFixed(2));
    return 0;
  }

  updateTonnageValidation() {
    if (this.shouldShowOrderIdField()) {
      const maxTonnage = this.isCompleted() ? this.getAllowedTonnageOnOrder() : this.getMaxTonnage();
      let tonnageValidators = this.isFieldMandatory('tonnage') ? [required()] : [];
      if (!this.isCompleted() && maxTonnage)
        tonnageValidators.push(maxValue(maxTonnage));
      this.setState({
        fields: {
          ...this.state.fields,
          tonnage: {
            ...this.state.fields.tonnage,
            validators: tonnageValidators,
          },
        },
      });
    }
  }

  handleOrderSelect(value) {
    this.handleOrderChange({ target: { value: value } });
  }

  handleOrderChange(event, triggerBlur) {
    let value = event.target.value;
    if(value)
      value = value.toUpperCase();

    if (value !== this.state.selectedOrderIdentifier) {
      const selectedOrder = find(this.orderItems(), { identifier: value });
      const selectedOrderProviderId = get(selectedOrder, 'providerId');
      let providerId = this.state.fields.freightProviderId.value;
      if (this.props.siteBooking) providerId = this.props.currentUserCompanyId;
      else if (selectedOrderProviderId) providerId = selectedOrderProviderId;

      const isProviderIdDifferent = this.state.fields.freightProviderId.value !== providerId;
      const maxTonnage = this.isCompleted() ? this.getAllowedTonnageOnOrder() : this.getMaxTonnage();
      let tonnageValidators = this.isFieldMandatory('tonnage') ? [required()] : [];
      if (!this.isCompleted() && maxTonnage)
        tonnageValidators.push(maxValue(maxTonnage));
      this.setState(
        {
          pickupSlotsCache: {},
          deliverySlotsCache: {},
          pickupTrailerSlots: [],
          deliveryTrailerSlots: [],
          pickupSlot: null,
          deliverySlot: null,
          pickupSlots: [],
          deliverySlots: [],
          orderValidationResponse: null,
          selectedOrder: selectedOrder,
          selectedOrderIdentifier: value,
          showProceedWithGivenOrderDialog: true,
          deliveryOrderNumber: selectedOrder ? null : value,
          fields: {
            ...this.state.fields,
            orderId: {
              ...this.state.fields.orderId,
              value: get(selectedOrder, 'id'),
            },
            freightProviderId: {
              ...this.state.fields.freightProviderId,
              value: providerId,
            },
            tonnage: {
              ...this.state.fields.tonnage,
              validators: selectedOrder ? tonnageValidators : [],
            },
            pickupSlotId: {
              ...this.state.fields.pickupSlotId,
              value: null
            },
            deliverySlotId: {
              ...this.state.fields.deliverySlotId,
              value: null
            }
          },
        },
        () => {
          const providerId = this.state.fields.freightProviderId.value;
          if (providerId && isProviderIdDifferent) {
            this.props.getCompanyTrucks(providerId, receiveTrucks);
            this.props.getCompanyEmployeesMinimal(providerId, receiveEmployees);
          }
          if(triggerBlur)
            this.handleOrderBlur();
        },
      );
    }
  }


  getOrderUnmatchFields = (commodityId, gradeId, season, customer, type, siteId, excludedCommodityIds, excludedGradeIds) => {
    let fields = [];
    if(this.state.selectedOrderIdentifier) {
      const order = this.getSelectedOrderFromIdentifier(this.state.selectedOrderIdentifier, this.state.orders);
      if (!order) return fields;

      const { pickupSite, deliverySite } = this.state
      if((type && siteId) && ((type === 'inload' && siteId !== get(deliverySite, 'id')) || (type === 'outload' && siteId !== get(pickupSite, 'id'))))
        fields.push('Type')

      if(
        this.shouldShowField('customer') &&
          customer &&
          ((order.customerName || '').toLowerCase() !== (customer || '').toLowerCase())
      )
        fields.push('Customer');

      if(this.shouldShowField('commodityId') && commodityId && order.commodityId !== commodityId)
        fields.push('Commodity');

      if(!isEmpty(excludedCommodityIds) && this.shouldShowField('commodityId') && includes(excludedCommodityIds, order.commodityId))
        fields.push('Excluded Commodity');

      if(!isEmpty(excludedGradeIds) && this.shouldShowField('gradeId') && includes(excludedGradeIds, order.plannedGradeId))
        fields.push('Excluded Grade');

      const fixedGrades = compact([...map(order.spreadDetails, 'id'), order.plannedGradeId]);

      if(this.shouldShowField('gradeId') && gradeId && !includes(fixedGrades, gradeId))
        fields.push('Grade');

      if(this.shouldShowField('season') && season && order.season !== season)
        fields.push('Season');
    }

    return fields;
  }

  getErrorLabelFromFields = (unmatchedFields, suffix) => {
    if(isEmpty(unmatchedFields))
      return
    if(includes(unmatchedFields, 'Excluded Commodity'))
      return ORDER_COMMODITY_IN_SLOT_EXCLUDED_COMMODITY_MESSAGE
    else if (includes(unmatchedFields, 'Excluded Grade'))
      return ORDER_GRADE_IN_SLOT_EXCLUDED_GRADE_MESSAGE
    else
      return  "This slot doesn't match with order's " + unmatchedFields.join(', ') + " fields." + suffix
  }

  getSlotErrorState = unmatchedFields => {
    if(isEmpty(unmatchedFields))
      return {valid: true, error: false}
    if(this.props.siteBooking || includes(unmatchedFields, 'Excluded Commodity') || includes(unmatchedFields, 'Excluded Grade'))
      return {valid: false, error: true}
    return {valid: false, error: false}
  }

  getUnmatchedFieldsWithSelectedOrder() {
    return this.getOrderUnmatchFields(
      this.state.fields.commodityId.value,
      this.state.fields.gradeId.value,
      this.state.fields.season.value,
      this.state.fields.customer.value
    );
  }

  onOrderSelect() {
    const callback = () => {
      const orderEl = document.getElementById('orderId');
      if(orderEl)
        setTimeout(() => {
          orderEl.blur();
          this.handleOrderBlur();
        }, 100);
    }
    const order = this.getSelectedOrderFromIdentifier();
    if(order && this.state.fields.orderId.value !== order.id) {
      const newState = {...this.state}
      newState.fields.orderId.value = order.id
      this.setState(newState, callback)
    } else callback()
  }

  setOrderValues(overrideValues) {
    const order = this.getSelectedOrderFromIdentifier(this.state.selectedOrderIdentifier, this.state.orders);
    if (order && (!isBoolean(overrideValues) || !overrideValues) && (this.props.isNew || this.isSlotPlanned() || this.isMultiSlotBooking())) {
      const newState = {...this.state};
      let providerId = get(order, 'providerId');
      if (!this.props.siteBooking || !this.state.fields.commodityId.value)
        newState.fields.commodityId.value = get(order, 'commodityId');
      if (!this.props.siteBooking || !this.state.fields.season.value)
        newState.fields.season.value = get(order, 'season');
      if (!this.props.siteBooking || !this.state.fields.gradeId.value)
        newState.fields.gradeId.value = get(order, 'plannedGradeId');
      if (providerId) {
        newState.fields.freightProviderId.value = providerId;
        this.props.getCompanyTrucks(providerId, receiveTrucks);
        this.props.getCompanyEmployeesMinimal(providerId, receiveEmployees);
      }
      else if (this.props.siteBooking && !providerId) {
        newState.fields.freightProviderId.value = this.props.currentUserCompanyId;
        this.props.getCompanyTrucks(this.props.currentUserCompanyId, receiveTrucks);
        this.props.getCompanyEmployeesMinimal(this.props.currentUserCompanyId, receiveEmployees);
      }
      else if (!this.props.siteBooking && !providerId && isEmpty(this.state.allCompanies)) {
        APIService
          .companies()
          .appendToUrl('minimal/all/')
          .get()
          .then(items => {
            this.setState({ allCompanies: items});
          });
      }
      this.setState(newState, () => {
        if(this.props.siteBooking && this.state.fields.freightProviderId.value !== this.props.currentUserCompanyId) {
          this.handleSearchOptionChange(null, SEARCH_BY_TRUCK_OWNER_REGOS);
        } else
          this.setFleetTruckIfSiteBooking();
        this.getDrivers();
      });
    }
  }


  handleOrderBlur(overrideValues=false) {
    if(this.state.update && includes(['completed', 'cancelled', 'rejected'], this.state.fields.status.value)) {
      if(this.state.fields.status.value == 'completed' && !isEmpty(this.props.existingTrailerSlotIds)) {
        const newState = {...this.state};
        const isOrderDeliverySite = get(newState.deliverySite, 'id') === newState.fields.siteId.value;
        forEach(this.props.existingTrailerSlotIds, (id, index) => {
          const i = index + 1;
          if(isOrderDeliverySite) {
            newState.deliverySiteTrailers[`trailer${i}`].applied = true;
            newState.deliverySiteTrailers[`trailer${i}`].slotId = id.toString();
          } else {
            newState.pickupSiteTrailers[`trailer${i}`].applied = true;
            newState.pickupSiteTrailers[`trailer${i}`].slotId = id.toString();
          }
        });
        this.setState(newState, () => {
          this.validateOrderIdentifier(false, false)
          this.getOrderUnaccountedTonnage();
        });
      }
      this.validateOrderIdentifier(false, false)
      this.getOrderUnaccountedTonnage();
      return;
    }
    if (!this.state.isOnlyValidatingCustomer)
      this.setOrderValues(overrideValues)
    const unmatchedFields = this.getUnmatchedFieldsWithSelectedOrder();
    if (this.state.selectedOrderIdentifier) {
      let errorMessage;
      if(!isEmpty(unmatchedFields))
        errorMessage = "The order number doesn't match the value entered in " + unmatchedFields.join(', ');
      if(get(this.state.orderValidationResponse, 'errors.0') && this.isOrderBooking())
        errorMessage = this.state.orderValidationResponse.errors[0];
      else if (!this.state.selectedOrder && !this.getSelectedOrderFromIdentifier(this.state.selectedOrderIdentifier, this.state.orders) && this.isOrderBooking()) {
        this.validateOrderIdentifier(this.state.selectedOrderIdentifier, true);
        errorMessage = "Warning: Validating order...";
      }
      const newState = {...this.state};
      newState.fields.orderId.errors = errorMessage ? [errorMessage]: [];
      newState.fields.commodityId.errors = this.state.fields.commodityId.value && includes(this.state.fields.excludedCommodityIds.value , this.state.fields.commodityId.value) ? [ORDER_COMMODITY_IN_SLOT_EXCLUDED_COMMODITY_MESSAGE] : newState.fields.commodityId.errors
      newState.fields.gradeId.errors = this.state.fields.gradeId.value && includes(this.state.fields.excludedGradeIds.value , this.state.fields.gradeId.value) ? [ORDER_GRADE_IN_SLOT_EXCLUDED_GRADE_MESSAGE] : newState.fields.gradeId.errors
      if(this.state.isOnlyValidatingCustomer){
        newState.isOnlyValidatingCustomer = false;
        this.setState(newState)
      }
      else
        this.setState(newState, this.validateOrderIdentifier)
    } else {
      this.setState({
        orderValidationResponse: null,
        fields: {
          ...this.state.fields,
          orderId: { ...this.state.fields.orderId, errors: [] },
        },
      }, this.validateOrderIdentifier);
    }

    this.getOrderUnaccountedTonnage();
  }

  validateOrderIdentifier(identifier, setErrors) {
    identifier = identifier || this.state.selectedOrderIdentifier;
    if(!identifier)
      return;

    const site = this.selectedSite();
    const siteCompanyId = get(site, 'companyId');
    if (siteCompanyId) {
      setTimeout(() => {
        this.setState({ isCheckingOrder: true });
      }, 100); // hack
      this.setState(
        {isCheckingOrder: true, orderValidationResponse: null},
        () => {
          const service = APIService.companies(siteCompanyId).orders().appendToUrl(`${identifier}/`);
          if (site)
            service.appendToUrl(`?site_id=${site.id}&load_type=${this.state.fields.type.value}`);
          if(this.props.slot)
            service.appendToUrl(`&booking_date=${this.state.fields.startDate.value}`);
          else if (this.state.multiSlots) {
            const {startDate, endDate} = this.getStartEndDateForMultiSlotsBooking()
            service.appendToUrl(`&booking_start_date=${startDate}&booking_end_date=${endDate}`);
          }

          service.get(this.props.token, {'REFERER-UNIT': this.state.unit, 'REFERER-UNIT-FOR-REQUEST': true}).then(res => {
            const newState = {...this.state};
            newState.isCheckingOrder = false;
            if(isEqual(res?.errors, ["There is no tonnage left on this order please update the order tonnage"]) && this.state.fields.tonnage.value && this.props.slot?.event?.raw?.slot?.id && (this.state.fields.tonnage.value === this.props.slot?.event?.raw?.slot?.tonnage || this.state.fields.tonnage.value < this.props.slot?.event?.raw?.slot?.tonnage || !this.state.fields.tonnage.value)) {
              res.errors = []
            }
            newState.orderValidationResponse = res;
            if(newState.selectedOrder?.id && res?.orderId === newState.selectedOrder?.id) {
              newState.selectedOrder.deliveredTonnage = res.deliveredTonnage
              newState.selectedOrder.progressTonnage = res.progressTonnage
              newState.selectedOrder.inferredTonnage = res.inferredTonnage
              newState.selectedOrder.totalTonnageWithTolerance = res.totalTonnageWithTolerance
              newState.selectedOrder.unaccountedTonnage = res?.unaccountedTonnage
            }
            newState.orderUnaccountedTonnage = res?.unaccountedTonnage
            newState.canUserAmend = get(res, 'canUserAmend');
            newState.isOrderIndependent = get(res, 'isIndependent');
            newState.commodityContractId = get(res, 'commodityContractId');
            newState.contractNumber = get(res, 'contractNumber');
            newState.pickupOrderId = get(res, 'pickupOrderId');
            newState.pickupOrderIdentifier = get(res, 'pickupOrderIdentifier');
            newState.deliveryOrderId = get(res, 'deliveryOrderId');
            newState.deliveryOrderIdentifier = get(res, 'deliveryOrderIdentifier');
            newState.parentOrderId = get(res, 'parentOrderId');
            newState.parentOrderIdentifier = get(res, 'parentOrderIdentifier');
            newState.maxAllowedTonnageForOrderAmend = get(res, 'maxAllowedTonnageForAmend');
            newState.canAmendRelatedEntity = get(res, 'canAmendRelatedEntity');
            newState.reason = get(res, 'reason');
            const isStatusBookedCompletedOrInProgress = includes(['booked', 'completed', 'in_progress'], this.state.fields.status.value);
            newState.fields.orderId.errors = get(res?.errors, '0') ? ((res.errors[0] == this.ORDER_NOT_VALID_MESSAGE && !this.isOrderBooking()) ? [] : [res.errors[0]]) : newState.fields.orderId.errors;
            if (!this.isOrderBooking() && isEmpty(res?.success) && isEmpty(newState.fields.orderId.errors) && this.state.showProceedWithGivenOrderDialog && isStatusBookedCompletedOrInProgress) {
              alertifyjs.confirm(
                'Warning',
                'This is not a valid order, so it will not create a planned freight movement. Are you sure you want to proceed?',
                () => {
                  this.setState({showProceedWithGivenOrderDialog: false, fields: {...this.state.fields, deliveryOrderNumber: {...this.state.deliveryOrderNumber, value: identifier}, orderId: {...this.state.fields.orderId, errors: []}}})},
                () => {
                  this.setState({selectedOrderIdentifier: "", selectedOrder: null, fields: {...this.state.fields,  deliveryOrderNumber: {...this.state.fields.deliveryOrderNumber, value: ""},orderId: {...this.state.fields.orderId, value: null, errors: []}}});
                }
                ).set('reverseButtons', true).set(
                'labels', {ok: 'Yes, Proceed', cancel: 'Cancel'}
              ).show();
            }
            if (!isStatusBookedCompletedOrInProgress && res.errors[0] == this.ORDER_NOT_VALID_MESSAGE && !this.isOrderBooking())
              newState.fields.deliveryOrderNumber.value = identifier;
            newState.bothSitesOrderBookingOn = this.isOrderBookingOnForSelectedTruckForSite(res?.pickupSite, this.state.selectedTruck, res?.bothSitesOrderBookingOn) && this.isOrderBookingOnForSelectedTruckForSite(res?.deliverySite, this.state.selectedTruck, res?.bothSitesOrderBookingOn);
            if (get(res, 'pickupSite')) {
              newState.pickupSite = get(res, 'pickupSite');
            }
            if(isEmpty(get(res, 'errors'))) {
              newState.fields.type.value = newState.fields.siteId.value === get(newState.pickupSite, 'id') ? 'outload' : 'inload'
            }
            if (get(res, 'deliverySite')) {
              newState.deliverySite = get(res, 'deliverySite');
            }
            const distance = get(res, 'distance');
            if (distance) {
              newState.distanceInKm.value = distance['text'];
              newState.totalDistance.value = (distance['value'] / 1000).toFixed(2);
            }
            const duration = get(res, 'duration');
            if (duration) {
              newState.estimatedTime.value = duration['text'];
              newState.estimatedTimeInSeconds = duration['value'];
            }
            if(setErrors && isArray(res.errors))
              newState.fields.orderId.errors = res.errors;
            const startDate = get(res, 'orderStartDate');
            if (startDate) {
              newState.orderStartDate = moment(startDate).format('YYYY-MM-DD');
            }
            const endDate = get(res, 'orderEndDate');
            if (endDate) {
              newState.orderEndDate = moment(endDate).format('YYYY-MM-DD');
            }
            const isOrderDeliverySite = get(newState.deliverySite, 'id') === newState.fields.siteId.value;
            if(isOrderDeliverySite && !this.state.fields.freightDelivery.date.value && !this.state.fields.deliverySlotId.value) {
              newState.fields.freightDelivery.date.value = moment(get(this.props, 'slot.event.raw.slot.start')).format('YYYY-MM-DD');
              newState.fields.freightDelivery.timeStart.value = moment(get(this.props, 'slot.event.raw.slot.start')).format('hh:mm:ss');
              newState.fields.deliverySlotId.value = get(this.props, 'slot.event.raw.slot.id');
            }
            else if(!isOrderDeliverySite && !this.state.fields.freightPickup.date.value && !this.state.fields.pickupSlotId.value) {
              newState.fields.freightPickup.date.value = moment(get(this.props, 'slot.event.raw.slot.start')).format('YYYY-MM-DD');
              newState.fields.freightPickup.timeStart.value = moment(get(this.props, 'slot.event.raw.slot.start')).format('hh:mm:ss');
              newState.fields.pickupSlotId.value = get(this.props, 'slot.event.raw.slot.id');
            }
            if(res?.bothSitesOrderBookingOn) {
              const counterSlotId = get(this.props.slot, 'event.raw.slot.counterSlotId')
              if(counterSlotId) {
                if(this.props.slot.event.raw.slot.type === 'inload')
                  newState.fields.pickupSlotId.value = counterSlotId
                else
                  newState.fields.deliverySlotId.value = counterSlotId
              }
            }

            if ((isOrderDeliverySite || res?.bothSitesOrderBookingOn) && !this.state.deliverySlots && this.state.fields.freightProviderId.value) {
              let deliveryStartDateTime = this.timeStampToDateTime(moment(newState.fields.freightDelivery.date.value).startOf('day'));
              let deliveryDayEndDate = moment(newState.fields.freightDelivery.date.value).endOf('day');
              let deliveryEndDateTime = this.timeStampToDateTime(deliveryDayEndDate);
              const queryParams = {start: deliveryStartDateTime, end: deliveryEndDateTime, load_type: 'inload', freight_provider_id: this.state.fields.freightProviderId.value};
              if (!res?.errors || isEmpty(res?.errors))
                this.fetchSlots(newState.deliverySite?.id, queryParams, 'deliverySlots');
            }
            if ((!isOrderDeliverySite || res?.bothSitesOrderBookingOn) && !this.state.pickupSlots && this.state.fields.freightProviderId.value) {
              let pickupStartDateTime = this.timeStampToDateTime(moment(newState.fields.freightPickup.date.value).startOf('day'));
              let pickupDayEndDate = moment(newState.fields.freightPickup.date.value).endOf('day');
              let pickupEndDateTime = this.timeStampToDateTime(pickupDayEndDate);
              const queryParams = {start: pickupStartDateTime, end: pickupEndDateTime, load_type: 'outload', freight_provider_id: this.state.fields.freightProviderId.value};
              if (!res?.errors || isEmpty(res?.errors))
                this.fetchSlots(newState.pickupSite?.id, queryParams, 'pickupSlots');
            }
            let counterSiteCompanyId = isOrderDeliverySite ? newState.pickupSite?.companyId : newState.deliverySite?.companyId;
            if (counterSiteCompanyId && !this.state.counterSlotSmSetting) {
              APIService.companies(counterSiteCompanyId)
              .appendToUrl('site-management/settings/')
              .get(this.props.token)
              .then(res => {
                this.setState({counterSlotSmSetting: res})
              });
            }
            if(!isEmpty(this.props.existingTrailerSlotIds)) {
              forEach(this.props.existingTrailerSlotIds, (id, index) => {
                const i = index + 1;
                if(isOrderDeliverySite) {
                  newState.deliverySiteTrailers[`trailer${i}`].applied = true;
                  newState.deliverySiteTrailers[`trailer${i}`].slotId = id.toString();
                } else {
                  newState.pickupSiteTrailers[`trailer${i}`].applied = true;
                  newState.pickupSiteTrailers[`trailer${i}`].slotId = id.toString();
                }
              });
            }
            if (get(res, 'isBlended')) {
              newState.isBlended = true;
              newState.spreadDetails = get(res, 'spread') || [];
              newState.baseEntityChemicalApplications = get(res, 'chemicalApplications') || [];
              newState.blendedOrderGrade = get(res, 'gradeName');
            }
            this.setState(newState, () => {
              this.setState({bothSitesOrderBookingOn: this.isBothSiteOrderBookingOn()}, () => {
                this.updateSlotValidators()
                if (this.isSlotPlanned())
                  this.setChemicalApplications();
                if(this.state.orderUnaccountedTonnage > 0){
                  this.showBookViaMovementPopup();
                }
              })
            });
          });
        },
      );
    }
  }

  forceCloseSelf() {
    document.getElementsByClassName('freight-slot-dialog')[0].children[0].click();
  }

  shouldAllowBothSiteBooking() {
    const { fields, bothSitesOrderBookingOn, orderUnaccountedTonnage } = this.state;
    const { slot } = this.props;
    const currentStatus = fields.status.value;

    if(bothSitesOrderBookingOn && fields.orderId.value && orderUnaccountedTonnage > 0) {
      if(includes(['planned', 'booked'], currentStatus))
        return true;
      if(slot.event.raw.slot.orderId !== fields.orderId.value && fields.orderId.value)
        return true;
    }

    return false;
  }

  timeStampToDateTime(dateTime) {
    let utcDateTime = moment(dateTime).utc().format('YYYY-MM-DD HH:mm:ss');
    let tmsx = moment(utcDateTime).format("X");
    let formattedDateTime = moment.unix(tmsx).format('YYYY-MM-DDTHH:mm:ss');
    return formattedDateTime;
  }

  fetchSlots(siteId, params, id) {
    const isPickupSlots = id === 'pickupSlots'
    const isDeliverySlots = id === 'deliverySlots'
    const callback = (slots, alreadyPrepared) => {
      let eligibleSlots;
      let utcOffsetMinutes;
      if(alreadyPrepared)
        eligibleSlots = slots
      else {
        eligibleSlots = orderBy(slots, slot => new Date(slot.start));
        const site = isPickupSlots ? this.state.pickupSite : this.state.deliverySite
        utcOffsetMinutes = get(site, 'timezone.utcoffsetSeconds') / 60;
        eligibleSlots.map(obj => {
          let startTime = moment(obj.start).utcOffset(utcOffsetMinutes);
          let endTime = moment(obj.end).utcOffset(utcOffsetMinutes);
          obj.name = `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')} (${get(site, 'timezone.abbreviation')})`;
        });
      }
      const newState = {...this.state}
      const site = isPickupSlots ? this.state.pickupSite : this.state.deliverySite
      utcOffsetMinutes = get(site, 'timezone.utcoffsetSeconds') / 60;
      if(isPickupSlots) {
        if(!newState.fields.freightPickup.date.value && params?.include_slot_id && !isEmpty(eligibleSlots)) {
          newState.fields.freightPickup.date.value = moment(find(eligibleSlots, {id: params.include_slot_id}).start).format('YYYY-MM-DD')
        }
        newState.pickupSlots = eligibleSlots
        newState.pickupUtcOffsetMinutes = utcOffsetMinutes
        newState.pickupSlotsCache[params.start] = eligibleSlots
        if(this.state.pickupTrailerSlots) {
          map(this.state.pickupTrailerSlots, obj => {
            let startTime = moment(obj.start).utcOffset(utcOffsetMinutes);
            let endTime = moment(obj.end).utcOffset(utcOffsetMinutes);
            obj.name = `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')} (${get(site, 'timezone.abbreviation')})`;
          })
          newState.pickupSlots = uniq([...newState.pickupSlots, ...this.state.pickupTrailerSlots], 'id')
          if(!(this.state.pickupSiteTrailers.trailer1?.slotId || this.state.pickupSiteTrailers.trailer2?.slotId || this.state.pickupSiteTrailers.trailer3?.slotId)) {
            map(this.state.pickupTrailerSlots, (trailer, index) => {
              newState.pickupSiteTrailers[`trailer${index + 1}`].slotId = trailer?.id
            })
          }
        }
      } else {
        if(!newState.fields.freightDelivery.date.value && params?.include_slot_id && !isEmpty(eligibleSlots)) {
          newState.fields.freightDelivery.date.value = moment(find(eligibleSlots, {id: params.include_slot_id}).start).format('YYYY-MM-DD')
        }
        newState.deliverySlots = eligibleSlots
        newState.deliveryUtcOffsetMinutes = utcOffsetMinutes
        newState.deliverySlotsCache[params.start] = eligibleSlots
        if(this.state.deliveryTrailerSlots) {
          map(this.state.deliveryTrailerSlots, obj => {
            let startTime = moment(obj.start).utcOffset(utcOffsetMinutes);
            let endTime = moment(obj.end).utcOffset(utcOffsetMinutes);
            obj.name = `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')} (${get(site, 'timezone.abbreviation')})`;
          })
          newState.deliverySlots = uniq([...newState.deliverySlots, ...this.state.deliveryTrailerSlots], 'id')
          if(!(this.state.deliverySiteTrailers.trailer1?.slotId || this.state.deliverySiteTrailers.trailer2?.slotId || this.state.deliverySiteTrailers.trailer3?.slotId)) {
            map(this.state.deliveryTrailerSlots, (trailer, index) => {
              newState.deliverySiteTrailers[`trailer${index + 1}`].slotId = trailer?.id
            })
          }
        }
      }
      this.setState(newState)
    }
    const cache = isPickupSlots ? this.state.pickupSlotsCache : this.state.deliverySlotsCache
    const existingSlots = get(cache, params.start)
    if(existingSlots)
      return callback(existingSlots, true)
    if(!siteId)
      return

    let plannedSlotService = APIService
      .company_sites(siteId)
      .appendToUrl('slots/planned/')

    let slotDate = get(this.props.slot, 'event.raw.slot.start') ? get(this.props.slot, 'event.raw.slot.start') : '';
    if(this.props.slot?.event?.raw?.slot?.start) {
      slotDate = this.initialMDate(this.props.slot.event.raw.slot, 'start');
      const timezoneOffset = this.getTimezoneOffsetMinutesToAdd(slotDate);
      if(timezoneOffset) {
        slotDate.add(timezoneOffset, 'minutes');
      }
      slotDate = slotDate.format('YYYY-MM-DD')
    }

    if (isPickupSlots && this.state.fields.pickupSlotId.value && (slotDate === this.state.fields.freightPickup.date.value || [this.props.slot.event.raw?.slot?.counterSlotId, this.props.slot.event.raw?.slot?.id].includes(this.state.fields.pickupSlotId.value))){
      params.include_slot_ids = this.state.fields.pickupSlotId.value
    }
    else if(isDeliverySlots && this.state.fields.deliverySlotId.value && (slotDate === this.state.fields.freightDelivery.date.value || [this.props.slot.event.raw?.slot?.counterSlotId, this.props.slot.event.raw?.slot?.id].includes(this.state.fields.deliverySlotId.value))){
      params.include_slot_ids = this.state.fields.deliverySlotId.value
    }

    plannedSlotService
      .get(null, null, params)
      .then(slots => {
        callback(slots, false)
      });
  }

  showBookViaMovementPopup() {
    const { fields } = this.state;
    if(this.shouldAllowBothSiteBooking()) {
      const { isNew } = this.props;
      if(isNew && fields.status.value !== 'planned') {
        !this.props.isDuplicate ? this.showWarningForOrderBookingOnBothSites() : false;
      } else {
        const newState = {...this.state};
        const isMultiSlotBooking = this.isMultiSlotBooking()
        const isBothSiteOrderMultiSlotBooking = isMultiSlotBooking && this.isBothSiteOrderBookingOn()
        if(!isMultiSlotBooking && !this.isSlotBooked()) {
          if (!newState.fields.freightDelivery.date.value)
            newState.fields.freightDelivery.date.value = this.state.fields.startDate.value;
          if (!newState.fields.freightPickup.date.value)
            newState.fields.freightPickup.date.value = this.state.fields.startDate.value;
        }
        newState.fields.freightDelivery.consignee.handlerId.value = get(this.state.deliverySite, 'id');
        newState.fields.freightPickup.consignor.handlerId.value = get(this.state.pickupSite, 'id');
        newState.fields.freightDelivery.date.validators = isBothSiteOrderMultiSlotBooking ? [] : [required()];
        newState.fields.freightPickup.date.validators = isBothSiteOrderMultiSlotBooking ? [] : [required()];
        newState.fields.freightDelivery.consignee.handlerId.validators = isBothSiteOrderMultiSlotBooking ? [] : [required()];
        newState.fields.freightPickup.consignor.handlerId.validators = isBothSiteOrderMultiSlotBooking ? [] : [required()];
        newState.fields.pickupSlotId.validators = isBothSiteOrderMultiSlotBooking ? [] : [required()];
        newState.fields.deliverySlotId.validators = isBothSiteOrderMultiSlotBooking ? [] : [required()];
        newState.fields.truckId.validators = [required()];
        newState.fields.driverId.validators = [required()];
        this.setState(newState, () => {
          const isOrderDeliverySite = get(this.state.deliverySite, 'id') === this.state.fields.siteId.value
          if(isMultiSlotBooking) {
            forEach(this.props.multiSlots, slot => {
              const mStart = moment(slot.start.toDate())
              let startDateTime = this.timeStampToDateTime(mStart.startOf('day'));
              let endDateTime = this.timeStampToDateTime(mStart.endOf('day'));
              let params = {
                start: startDateTime,
                end: endDateTime,
                freight_provider_id: this.state.fields.freightProviderId.value,
              }
              if(isOrderDeliverySite)
                this.fetchSlots(this.state.pickupSite.id, {...params, load_type: 'outload'}, 'pickupSlots');
              else
                this.fetchSlots(this.state.deliverySite.id, {...params, load_type: 'inload'}, 'deliverySlots');

            })
          } else {
            const pStart = moment(this.state.fields.freightPickup.date.value)
            let pickupParams = {
              start: this.timeStampToDateTime(pStart.startOf('day')),
              end: this.timeStampToDateTime(pStart.endOf('day')),
              freight_provider_id: this.state.fields.freightProviderId.value,
            }
            const dStart = moment(this.state.fields.freightDelivery.date.value)
            let deliveryParams = {
              start: this.timeStampToDateTime(dStart.startOf('day')),
              end: this.timeStampToDateTime(dStart.endOf('day')),
              freight_provider_id: this.state.fields.freightProviderId.value,
            }
            this.fetchSlots(this.state.pickupSite.id, {...pickupParams, load_type: 'outload'}, 'pickupSlots');
            this.fetchSlots(this.state.deliverySite.id, {...deliveryParams, load_type: 'inload'}, 'deliverySlots');
          }
          const newState = {...this.state};
          const id = get(this.props.slot, 'event.raw.slot.id');
          const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
          if (slotType === 'outload' && !newState.fields.pickupSlotId.value) {
            newState.fields.pickupSlotId.value = id;
          }
          else if(slotType == 'inload' && !newState.fields.deliverySlotId.value) {
            newState.fields.deliverySlotId.value = id;
          }
          if (this.state.fields.pickupSlotId.value) {
            let slot = find(this.state.pickupSlots, {id: this.state.fields.pickupSlotId.value});
            if (slot)
              newState.fields.freightPickup.timeStart.value = get(slot, 'startTime');
          }
          if (this.state.fields.deliverySlotId.value) {
            let slot = find(this.state.deliverySlots, {id: this.state.fields.deliverySlotId.value});
            if (slot)
              newState.fields.freightDelivery.timeStart.value = get(slot, 'startTime');
          }
          this.setState(newState, this.updateSlotValidators);
        });
      }
    }
  }

  showWarningForOrderBookingOnBothSites() {
    alertifyjs.alert(
      'Important',
      'This order requires both pickup and delivery slots to be booked. You will first have to create planned slots and then you can book them.',
      () => {},
    ).show();
  }

  handleSubFreightProviderChange(value, id, item) {
    if (value !== this.state.fields.subFreightProviderId.value) {
      const newState = { ...this.state };
      newState.fields.truckId.value = undefined;
      newState.fields.driverId.value = undefined;
      newState.fields.subFreightProviderId.value = value;
      if (this.state.searchOption !== SEARCH_BY_TRUCK_OWNER_REGOS)
        newState.fields.freightProviderId.value = this.props.currentUserCompanyId;
      newState.selectedSubProvider = item;
      this.setState(newState, () => {
        this.props.getCompanyTrucks(value, receiveTrucks);
        this.props.getCompanyEmployeesMinimal(value, receiveEmployees);
      });
    }
  }

  handleStatusChange(value) {
    if (value !== this.state.fields.status.value) {
      const newState = { ...this.state };
      newState.fields.status.value = value;
      let trailersExist = false;
      if (this.state.fields.siteId.value == this.state.deliverySite?.id)
        trailersExist = Boolean(this.state.deliverySiteTrailers.trailer1?.slotId || this.state.deliverySiteTrailers.trailer2?.slotId || this.state.deliverySiteTrailers.trailer3?.slotId)
      else if (this.state.fields.siteId.value == this.state.pickupSite?.id)
        trailersExist = Boolean(this.state.pickupSiteTrailers.trailer1?.slotId || this.state.pickupSiteTrailers.trailer2?.slotId || this.state.pickupSiteTrailers.trailer3?.slotId)
      if(this.state.update && value == 'completed' && trailersExist)
        newState.isStatusChangedToCompletedForSlotWithTrailers = true;
      else
        newState.isStatusChangedToCompletedForSlotWithTrailers = false;
      if (includes(['booked', 'completed', 'in_progress', 'delayed'], value) && this.props.settings) {
        this.setMandatoryClause(newState)
      } else {
        [
          'type',
          'bookingNumber',
          'deliveryOrderNumber',
          'orderId',
          'commodityId',
          'gradeId',
          'tonnage',
          'priority',
          'truckId',
          'freightProviderId',
          'driverId',
          'customer',
        ].forEach(field => {
          newState.fields[field].validators = [];
          newState.fields[field].errors = [];
        });
        newState.massLimitCode.validators = []
        newState.massLimitCode.errors = []
      }
      this.setState(newState, () => {
        let orderIdValidators = [];
        if (this.shouldShowOrderIdField()) {
          if (isEmpty(this.state.fields.orderId.validators)) orderIdValidators = [required()];
        }
        this.setState({
          fields: {
            ...this.state.fields,
            orderId: {
              ...this.state.fields.orderId,
              validators: orderIdValidators,
            },
            deliveryOrderNumber: {
              ...this.state.fields.orderId,
              validators: isEmpty(orderIdValidators) ? this.state.fields.deliveryOrderNumber.validators : [],
            },
          },
        });
      });
    }
  }

  handleFrequencyChange(value) {
    if (value !== this.state.fields.frequency.value) {
      const newState = { ...this.state };
      newState.fields.frequency.value = value;
      if (value > 1) {
        newState.fields.bookingNumber.value = undefined;
        newState.fields.bookingNumber.errors = [];
        newState.fields.bookingNumber.validators = [];
      }
      this.setState(newState, this.updateTonnageValidation);
    }
  }

  isMultipleSlot() {
    return this.state.fields.frequency.value > 1 || this.state.repeat;
  }

  getOptionalFieldLabel(field) {
    if (this.isFieldMandatory(field)) {
      return '';
    } else {
      return ' (Optional)';
    }
  }

  isFieldMandatory(field) {
    return this.state.fields[field].validators.length > 0;
  }

  handleStartDateChange(value, id) {
    this.handleSelectFieldChange(value, id);
    this.handleSelectFieldChange(value, 'endDate');
  }

  handleSelectFieldChange(value, id, callback) {
    const newState = { ...this.state };
    set(newState, `fields.${id}.value`, value || null);
    this.setState(newState, () => {
      if (isFunction(callback))
        callback();
      else if(['massLimitPermit'].includes(id)) {
        if (value === 'Notice') {
          const newState = {...this.state};
          newState.fields.ghms.value = false;
          newState.restrictedChecked = false;
          newState.permitChecked = false;
          this.setState(newState);
        }
        else if (value && value.includes('PBS - ')) {
          const newState = {...this.state};
          newState.fields.ghms.value = false;
          newState.accreditationNumberChecked = false;
          this.setState(newState);
        }
        this.setCategory();
      }
    });
  }

  fetchCategoriesIfNeeded(value) {
    if (value) {
      let shouldFetchCategories = true;
      if (!isEmpty(this.state.categories)) {
        let category = this.state.categories[0];
        let date = new Date(value);
        if (date >= new Date(get(category, 'fromDate').substring(0, 10)) && date <= new Date(get(category, 'endDate').substring(0, 10)))
          shouldFetchCategories = false;
      }
      if (shouldFetchCategories) {
        this.fetchCategories(value);
        this.fetchPermits(value);
      }
    }
  }

  handleDateChange(value, id) {
    const newState = { ...this.state };
    set(newState, `fields.${id}.value`, value || null);
    if (id == 'freightDelivery.date') {
      set(newState, `fields.deliverySlotId.value`, null);
    }
    this.setState(newState);
    if (value){
      let startDateTime = this.timeStampToDateTime(moment(value).startOf('day'));
      let dayEndDate = moment(value).endOf('day');
      let endDateTime = this.timeStampToDateTime(dayEndDate);
      if (id == 'freightDelivery.date') {
        const queryParams = {start: startDateTime, end: endDateTime, load_type: 'inload', freight_provider_id: this.state.fields.freightProviderId.value};
        this.fetchSlots(this.state.deliverySite.id, queryParams, 'deliverySlots');
      }
      else {
        const queryParams = {start: startDateTime, end: endDateTime, load_type: 'outload', freight_provider_id: this.state.fields.freightProviderId.value};
        this.fetchSlots(this.state.pickupSite.id, queryParams, 'pickupSlots');
        this.fetchCategoriesIfNeeded(value);
      }
    }
  }

  handleTypeChange(value) {
    const newState = { ...this.state };
    newState.fields.type.value = value;
    this.setState(newState, () => {
      if (this.state.fields.type.value) this.getOrders();
      this.handleOrderBlur();
    });
  }

  handleTimeChange(value, id) {
    this.handleSelectFieldChange(value, id, () => {
      const newState = {...this.state}
      if(this.isStartAfterEndTime())
        newState.fields.endTime.errors = ["Can't be before Start Time"]
      else
        newState.fields.endTime.errors = []
      this.setState(newState, this.setFrequencyOptions)
    });
  }

  isStartAfterEndTime = () => {
    const date = this.state.fields.startDate.value
    const startTime = this.state.fields.startTime.value
    const endTime = this.state.fields.endTime.value
    let result = false
    if(date && startTime && endTime)
      result = !moment(date + ' ' + startTime).isBefore(moment(date + ' ' + endTime))
    return result
  }

  isDeliverySlotBeforePickupSlot = (newState, id) => {
    const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
    let pickupSlotId = '';
    let deliverySlotId = '';
    if (slotType === 'outload') {
      pickupSlotId = this.state.fields.pickupSlotId.value || get(this.props, 'slot.event.raw.slot.id');
      deliverySlotId = this.state.fields.deliverySlotId.value || get(this.props, 'slot.event.raw.slot.counterSlotId');
    }
    else {
      pickupSlotId = this.state.fields.pickupSlotId.value  || get(this.props, 'slot.event.raw.slot.counterSlotId');
      deliverySlotId = this.state.fields.deliverySlotId.value  || get(this.props, 'slot.event.raw.slot.id');
    }
    if((newState.fields.status.value === 'booked' || this.isSlotBooked()) && deliverySlotId && pickupSlotId){
      const deliverySlot = this.state.deliverySlot || find(this.state.deliverySlots, { id: deliverySlotId });
      const pickupSlot = this.state.pickupSlot || find(this.state.pickupSlots, { id: pickupSlotId });
      const deliverySlotStart = new Date(deliverySlot?.start)
      const pickupSlotEnd = new Date(pickupSlot?.end)
      if (pickupSlot && deliverySlot && deliverySlotStart <= pickupSlotEnd){
        newState.fields[id].errors = id === 'pickupSlotId' ? ["Pickup Slot time can't be after Delivery Slot"] : ["Delivery Slot time can't be before Pickup Slot"]
      }
    }
  }

  setFrequencyOptions() {
    if (this.state.fields.startDate.value && this.state.fields.startTime.value && this.state.fields.endTime.value) {
      let start = moment(this.state.fields.startDate.value + ' ' + this.state.fields.startTime.value);
      let end = moment(this.state.fields.endDate.value + ' ' + this.state.fields.endTime.value);
      const timeSlot = end.diff(start, 'minutes');
      const frequencies = filter(this.FREQUENCIES, option => {
        return timeSlot > option.id && timeSlot % option.id === 0;
      });
      this.setState({ frequencies: frequencies });
    }
  }

  handleTextFieldChange(event) {
    this.handleSelectFieldChange(event.target.value, event.target.id);
  }

  isExcessTonnage() {
    if (this.state.selectedOrder && this.state.fields.tonnage.value && !this.isCompleted() && (this.props.isNew || this.state.fields.tonnage.value !== this.props.slot?.event?.raw?.slot?.tonnage))
      return this.maximumUnaccountedTonnage() < this.state.fields.tonnage.value;
    return false;
  }

  isLessTonnage() {
    if(!this.state.fields.tonnage.value)
      return false;
    if(this.state.fields.tonnage.value !== this.props.slot?.event?.raw?.slot?.tonnage || this.props.isNew)
      return this.getMinimumTonnage() > this.state.fields.tonnage.value;
    return false
  }

  excessTonnageErrorMessage(tonnage) {
    const { siteBooking } = this.props;

    if(siteBooking)
      return 'Tonnage cannot be greater than Order Tonnage';

    return `${this.isCompleted() && this.state.canUserAmend ? 'Warning: ': ''}Cannot be greater than ${parseFloat(tonnage).toFixed(2)} ${this.state.unit}.`;
  }

  getDeliveredTonnageOnOrder() {
    return get(this.state.selectedOrder, 'deliveredTonnage')
  }

  getProgressTonnageOnOrder() {
    return get(this.state.selectedOrder, 'progressTonnage')
  }

  getAllowedTonnageOnOrder() {
    if (this.state.selectedOrder) {
      const parentTonnage = min([this.state.maxAllowedTonnageForOrderAmend, get(this.state.selectedOrder, 'totalTonnageWithTolerance')]);
      let allowedTonnageOnOrder = parentTonnage - this.getDeliveredTonnageOnOrder();
      if (this.isOutload()) {
        if ((get(this.props.slot, 'event.raw.slot.movementStatus') === 'confirmed') || (get(this.props.slot, 'event.raw.slot.movementStatus') === 'in_progress'))
          allowedTonnageOnOrder -= this.getProgressTonnageOnOrder();
        if (!this.isSlotCompleted() && get(this.props.slot, 'event.raw.slot.movementStatus') === 'delivered')
          allowedTonnageOnOrder += get(this.props.slot, 'event.raw.slot.movementInferredTonnage')
      }
      if (this.isSlotCompleted())
        allowedTonnageOnOrder += get(this.props.slot, 'event.raw.slot.tonnage')
      return parseFloat(allowedTonnageOnOrder.toFixed(2))
    }
  }

  handleTonnageChange(event) {
    const { id } = event.target;
    let value = event.target.value
    if(isString(value)) {
      value = parseFloat(value.match(/[0-9.]*/))
    }
    const maxTonnageError = `${this.state.canUserAmend ? 'Warning: ': ''}There is not enough ${this.countryTonnageLabel.toLowerCase()} left on this order.`;
    this.handleSelectFieldChange(value, id);
    const newState = {...this.state};
    newState.pickupSlotTrailerErrors = !isEmpty(newState.pickupSlotTrailerErrors) && this.checkTrailerSlotNeeded(true) ? 'Please select a trailer slot' : null
    newState.deliverySlotTrailerErrors = !isEmpty(newState.deliverySlotTrailerErrors ) && this.checkTrailerSlotNeeded(false) ? 'Please select a trailer slot' : null
    this.setState(newState);
    if(value) {
      let errors = [];
      let orderErrors = this.state.fields.orderId.errors;
      pull(orderErrors, maxTonnageError);
      if(this.state.selectedOrder) {
        const checkForTonnage = this.isCompleted() ? this.getAllowedTonnageOnOrder() : this.getMaxTonnage();
        if(value > checkForTonnage && isEmpty(orderErrors))
        {errors = [this.excessTonnageErrorMessage(checkForTonnage)];
         orderErrors = [maxTonnageError];}
        else if(value > checkForTonnage)
          errors = [`Warning: you are exceeding the order ${this.countryTonnageLabel.toLowerCase()}`];
      }

      this.setState({
        fields: {
          ...this.state.fields,
          tonnage: {
            ...this.state.fields.tonnage,
            errors: errors,
          },
          orderId:{
            ...this.state.fields.orderId,
            errors: orderErrors,
          },
        },
      });
    }
  }

  getFullStartDateTime() {
    const { selectedTimezone } = this.props;
    const startDatetime = get(
      this.state.fields, 'startDate.value', ''
    ) + ' ' + get(
      this.state.fields, 'startTime.value', ''
    );

    return moment(startDatetime).format('h:mm a') + (
      selectedTimezone ? ` (${selectedTimezone.abbreviation})` : ''
    );
  }

  getTonnageTitlePart() {
    const tonnage = get(this.state.fields, 'tonnage.value', false);
    if (tonnage) return tonnage.toString() + ' ' + this.state.unit;
  }

  getCommodityTitlePart() {
    return get(this.state, 'selectedCommodity.displayName');
  }

  getGradeTitlePart() {
    return get(this.state, 'selectedGrade.name');
  }

  getSeasonTitlePart() {
    return get(this.state.fields.season, 'value');
  }

  getBookingNumberTitlePart() {
    return get(this.state.fields, 'bookingNumber.value');
  }

  getTruckTitlePart() {
    return get(this.state, 'selectedTruck.rego');
  }

  getFreightProviderTitlePart() {
    return get(this.state, 'selectedProvider.name');
  }

  getTruckOwnerTitlePart() {
    return get(this.state, 'selectedTruck.companyName');
  }

  getDriverTitlePart() {
    return get(this.state, 'selectedDriver.mobile');
  }

  getCustomerTitlePart() {
    return get(this.state.fields, 'customer.value');
  }

  getPriorityTitlePart() {
    return get(this.state.fields, 'priority.value') ? 'Priority' : '';
  }

  getPitsTitlePart() {
    return startCase(get(this.state.fields, 'pits.value', ''));
  }

  getTypeTitlePart() {
    return startCase(get(this.state.fields, 'type.value') || '');
  }

  getFreightMovementNumberTitlePart() {
    return get(this.props.slot, 'event.raw.slot.movementIdentifier');
  }

  getOrderNumberTitlePart() {
    return get(this.props.slot, 'event.raw.slot.deliveryOrderNumber');
  }

  getCommodityContractNumberTitlePart() {
    return get(this.props.slot, 'event.raw.slot.commodityContractNumber');
  }

  getSiteOrderTitlePart() {
    let val;
    const {slot, siteOrdersMap} = this.props
    const orderNumber = slot?.event?.raw?.slot?.deliveryOrderNumber
    if(orderNumber && get(siteOrdersMap, orderNumber.toLowerCase())) {
      const orders = siteOrdersMap[orderNumber.toLowerCase()]
      val = slot.type =='outload' ? orders?.pickupSiteOrderNumber : orders?.deliverySiteOrderNumber
    }
    return val || orderNumber || ''
  }


  isMultiSlotBooking = () => !this.props.slot && this.props.multiSlots.length > 0

  getTitle() {
    if (this.state.update) {
      if(this.isMultiSlotBooking())
        return get(this.selectedSite(), 'name')
      if (get(this.props.settings, 'titleOrder')) {
        let title = [];
        const onRoute = get(this.props.slot, 'event.raw.slot.onRoute');
        const onRouteLabel = '[On Route] ';

        this.props.settings.titleOrder.forEach(field => {
          if (field === 'start') title.push(this.getFullStartDateTime());
          if (field === 'commodity') title.push(this.getCommodityTitlePart());
          if (field === 'bookingNumber') title.push(this.getBookingNumberTitlePart());
          if (field === 'tonnage') title.push(this.getTonnageTitlePart());
          if (field === 'freightProvider') title.push(this.getFreightProviderTitlePart());
          if (field === 'truckOwner') title.push(this.getTruckOwnerTitlePart());
          if (field === 'truck') title.push(this.getTruckTitlePart());
          if (field === 'driver') title.push(this.getDriverTitlePart());
          if (field === 'grade') title.push(this.getGradeTitlePart());
          if (field === 'pits') title.push(this.getPitsTitlePart());
          if (field === 'priority') title.push(this.getPriorityTitlePart());
          if (field === 'customer') title.push(this.getCustomerTitlePart());
          if (field === 'type') title.push(this.getTypeTitlePart());
          if (field === 'season') title.push(this.getSeasonTitlePart());
          if(field === 'movementIdentifier')
            title.push(this.getFreightMovementNumberTitlePart());
          if(field === 'deliveryOrderNumber')
            title.push(this.getOrderNumberTitlePart());
          if(field === 'commodityContractNumber')
            title.push(this.getCommodityContractNumberTitlePart());
          if(field === 'siteOrder')
            title.push(this.getSiteOrderTitlePart())
        });
        if(isSystemCompany())
          title = [`[${get(this.props.slot, 'event.raw.slot.id')}]`, ...title];

        let titleLabel = compact(title).join(' - ');
        if(onRoute)
          titleLabel = onRouteLabel + titleLabel;

        return titleLabel;
      } else {
        return compact([
          this.getFullStartDateTime(),
          this.getCommodityTitlePart(),
          this.getBookingNumberTitlePart(),
          this.getTonnageTitlePart(),
          this.getFreightProviderTitlePart(),
          this.getTruckTitlePart(),
          this.getDriverTitlePart(),
        ]);
      }
    } else if (this.isMultipleSlot()) {
      if (this.state.repeat) return this.MULTIPLE_SLOTS_ACROSS_DAYS;
      else return this.MULTIPLE_SLOTS_SAME_DAY;
    } else return this.SINGLE_SLOT;
  }

  getBgColor() {
    const status = this.state.fields.status.value;

    if (this.props.statusColors) return this.props.statusColors[camelCase(status)];

    return NEUTRAL_GRAY;
  }

  getAllStatuses() {
    let statuses = this.STATUSES;
    if (this.props.settings)
      statuses = map(this.STATUSES, status => {
        return {
          id: status.id,
          name: get(find(this.props.settings.statuses, { id: status.id }), 'label', name),
        };
      });

    if(!this.isNewSlot() && get(this.props.slot, 'event.raw.slot.status') !== 'restricted')
      statuses = reject(statuses, {id: 'restricted'});

    return statuses;
  }

  getBorderColor() {
    if (get(this.props, 'settings.leftBorderColorByPits')) return this.getBorderColorByPits();

    return this.getBorderColorByLoadType();
  }

  isInload() {
    return this.state.fields.type.value === 'inload';
  }

  isOutload() {
    return this.state.fields.type.value === 'outload';
  }

  getBorderColorByLoadType() {
    if (this.isInload()) return INLOAD_COLOR;
    if (this.isOutload()) return OUTLOAD_COLOR;

    return NEUTRAL_GRAY;
  }

  getBorderColorByPits() {
    const pits = (this.state.fields.pits.value || '').toLowerCase();
    if (pits === 'road') return INLOAD_COLOR;
    if (pits === 'rail') return INLOAD_COLOR;
    if (pits === 'bulk') return PINK_NEON;
    if (pits === 'liquid') return GREEN_NEON;
    if (pits === 'additive') return YELLOW_NEON;
    if (pits === 'flour') return PINK_NEON;
    if (pits === 'animal_nutrition') return GREEN_NEON;
    if (pits === 'meal') return YELLOW_NEON;
  }

  getTitleClass() {
    return 'dialog-title-white-text';
  }

  canDelete() {
    return !this.props.siteBooking && (this.isSlotPlanned() || this.isRestricted()) && this.state.update;
  }

  hasSavedValue(field) {
    return (
      !includes(get(this.props.slot, 'event.raw.slot.providerUpdatedFields'), snakeCase(field)) &&
        get(this.props.slot, `event.raw.slot.${field}`)
    );
  }

  isFieldDisabledForBookie(field) {
    if (this.isSlotPlanned() || this.isSlotBooked())
      return (
        includes(['siteId', 'status', 'startDate', 'startTime', 'endTime'], field) ||
          (includes(['customer', 'commodityId', 'gradeId', 'tonnage', 'deliveryOrderNumber', 'bookingNumber', 'type', 'orderId'], field) &&
           this.hasSavedValue(field))
      );
    if (this.isSlotInProgress() || this.isSlotCompleted() || this.isSlotDelayed()) return !includes(['truckId', 'driverId'], field);
    if (this.isSlotCancelled() || this.isSlotRejected()) return true;
  }

  isFieldDisabledForSiteManager(field) {
    if(!this.isNewSlot() && this.isRestricted() && field === 'status') return true;
    if (this.isNewSlot() || this.isSlotPlanned()) return false;
    if (this.isSlotBooked()) return includes(['siteId', 'orderId', 'type', 'deliveryOrderNumber', 'commodityId', 'gradeId', 'season'], field);
    if (this.isSlotInProgress() || this.isSlotCompleted() || this.isSlotDelayed())
      return includes(['siteId', 'startDate', 'startTime', 'endTime'], field);
    if (this.isSlotCancelled() || this.isSlotRejected()) return true;
  }

  isFieldDisabled(field) {
    if(this.state.isReadonly)
      return true;
    if (this.isSlotCompleted()) {
      if(field === 'tonnage' && !this.props.siteBooking && this.props.slot?.event?.raw?.slot?.movementStatus === 'completed')
        return true
      if(field === 'tonnage' && !this.props.siteBooking)
        return false;
      return true;
    };
    if (field === 'orderId' && this.isSlotBooked())
      return true;

    return this.props.siteBooking ? this.isFieldDisabledForBookie(field) : this.isFieldDisabledForSiteManager(field);
  }

  getFieldInfo(id, attr) {
    const { settings, selectedTimezone } = this.props;
    const field = find(settings.fields, { id: id });
    let value = get(field, attr, null);

    if(includes(['startTime', 'endTime'], id) && attr === 'label' && selectedTimezone) {
      value += ` (${selectedTimezone.abbreviation})`;
    }
    return value;
  }

  getSlotLabel(id, label) {
    const timezoneAbbreviation = get(this.state, `${id}.timezone.abbreviation`);
    return `${label} Slot (${timezoneAbbreviation})`;
  }

  getTotalSlotsCounts() {
    if(this.isMultiSlotBooking())
      return this.state.multiSlots.length
    if (this.props.isNew && this.isMultipleSlot()) {
      const slots = this.getMultiSlots(this.getSlotPayload());
      if (isArray(slots)) return slots.length;
    }
    return 1;
  }

  getMinimumTonnage() {
    let minimumTonnage = get(this.props, 'settings.minimumTonnage') || 0.1;
    if(this.state.selectedOrder) {
      const unaccountedTonnage = this.unaccountedTonnage();
      if(unaccountedTonnage < minimumTonnage)
        minimumTonnage = unaccountedTonnage;
    }
    return parseFloat(parseFloat(minimumTonnage).toFixed(2));
  }

  getTonnageSubLabel() {
    const identifier = this.state.selectedOrderIdentifier;
    if (get(this.state.orderTonnageMap, identifier) && !this.state.isFetchingUnaccountedTonnage) {
      let unit = this.state.unit
      if (get(this.state, 'selectedOrder.isStrictQuantityBasedCommodity')) unit = this.state.selectedOrder.quantityUnit;
      const tonnageLabel = this.isCompleted() ? this.getAllowedTonnageOnOrder() : this.getMaxTonnage()
      if(tonnageLabel === 0)
        return ` (0 ${unit})`

      return ` (0 - ${parseFloat(tonnageLabel).toFixed(2)} ${unit})`;
    }

    return '';
  }

  unaccountedTonnage() {
    let unaccountedTonnage = this.state.orderUnaccountedTonnage || 0;
    if (
      !this.props.isNew &&
        get(this.props.slot, 'event.raw.slot.movementId') &&
        (this.state.selectedOrderIdentifier === this.props.slot.event.raw.slot.deliveryOrderNumber || this.state.fields.orderId.value === this.props.slot.event.raw.slot.orderId)
    )
      unaccountedTonnage += get(this.props.slot, 'event.raw.slot.tonnage', 0);

    return parseFloat(parseFloat(unaccountedTonnage).toFixed(2));
  }

  maximumUnaccountedTonnage() {
    return this.unaccountedTonnage();
  }

  shouldShowOrderNumberField() {
    const isOrderBooking = this.isOrderBooking();
    const isRestricted = this.isRestricted();
    return !isRestricted && (
      (this.getFieldInfo('deliveryOrderNumber', 'show') && !isOrderBooking) ||
        (isOrderBooking &&
         !this.props.isNew &&
         !this.props.siteBooking &&
         !this.state.fields.orderId.value &&
         !get(this.props.slot, 'event.raw.slot.orderId') &&
         this.state.fields.deliveryOrderNumber.value)
    );
  }

  isOrderBooking() {
    return get(this.props, 'settings.orderBooking');
  }

  isTrailerSlot() {
    return get(this.props.slot, 'event.raw.slot.isTrailerSlot', false);
  }

  isTrailerBooking() {
    return includes(this.getTrailerBookingSiteIds(get(this.props, 'settings.trailerBooking')), this.state.fields.siteId.value);
  }

  isCounterSiteTrailerBookingEnabled() {
    const isOrderDeliverySite = get(this.state.deliverySite, 'id') === this.state.fields.siteId.value;
    let counterSite = isOrderDeliverySite ? this.state.pickupSite : this.state.deliverySite;
    return includes(this.getTrailerBookingSiteIds(get(this.state, 'counterSlotSmSetting.trailerBooking')), counterSite?.id);
  }

  getTrailerBookingSiteIds(settings) {
    return map(settings, i => toNumber(i));
  }

  isTrailerBookingEnabledForSite(site) {
    let trailerBookingSiteIds = get(site, 'id') === this.state.fields.siteId.value ? this.getTrailerBookingSiteIds(get(this.props, 'settings.trailerBooking')) : this.getTrailerBookingSiteIds(get(this.state, 'counterSlotSmSetting.trailerBooking'));
    return includes(trailerBookingSiteIds, get(site, 'id'))
  }

  isPickupSiteTrailerBookingEnabled() {
    if (this.state.pickupSite)
      return this.isTrailerBookingEnabledForSite(this.state.pickupSite);
  }

  isDeliverySiteTrailerBookingEnabled() {
    if (this.state.deliverySite)
      return this.isTrailerBookingEnabledForSite(this.state.deliverySite);
  }

  shouldShowOrderIdField() {
    if(this.isRestricted())
      return false;
    if (this.isOrderBooking() && this.getFieldInfo('deliveryOrderNumber', 'show')) {
      if (!this.props.siteBooking) return this.state.fields.status.value !== 'planned';

      return true;
    }
  }

  shouldShowField(id) {
    if(this.state.fields.status.value === 'restricted')
      return includes(
        ['status', 'siteId', 'restrictedVisibleToCarrier', 'comment', 'startDate', 'startTime', 'endTime'],
        id
      );

    return this.getFieldInfo(id, 'show');
  }

  handleCommentsIconsClick() {
    this.setState({ showComments: !this.state.showComments });
  }

  handleHistoryIconClick() {
    this.setState({ showHistory: !this.state.showHistory });
  }

  handlePickupInfoIconClick() {
    if(this.isLocationTrackingEnabled())
      this.setState({ showPickupInfo: !this.state.showPickupInfo });
  }

  isLocationTrackingEnabled() {
    let result = true;
    let startTime = '', utcOffsetMinutes = '', currentTime = '', trackingStartTime = '';
    if(this.state.pickupStartTime) {
      startTime = cloneDeep(this.state.pickupStartTime);
      let utcOffset = get(this.state.pickupSite, 'timezone.utcoffsetSeconds') || get(find(this.props.sites, { id: this.state.fields.siteId.value }), 'timezone.utcoffsetSeconds');
      utcOffsetMinutes = utcOffset / 60;
      currentTime = moment().utcOffset(utcOffsetMinutes);
      trackingStartTime = startTime.utcOffset(utcOffsetMinutes).subtract(6, 'hours')
    }

    if(this.state.pickupStartTime && currentTime.isBefore(trackingStartTime)) {
      result = false;
      alertifyjs.error("Truck's Location tracking is only enabled 6 hours before the slot start time", 5);
    }
    return result;
  }

  handleVendorDecInfoIconClick() {
    this.setState({ showVendorDecInfo: !this.state.showVendorDecInfo });
  }

  handleCommentChange(event) {
    this.setState({ comment: event.target.value, commentError: event.target.value ? null : this.state.commentError });
  }

  getTrucks() {
    if (this.state.searchOption === SEARCH_BY_ALL_REGOS)
      return []
    let companyId = this.state.fields.subFreightProviderId.value || this.state.fields.freightProviderId.value
    if (this.props.siteBooking) {
      companyId = companyId || this.props.currentUserCompanyId;
      const trucks = uniqBy(filter((this.props.trucks || []).concat(this.state.bookieTrucks), { companyId: companyId }), 'id');
      return trucks;
    } else {
      const removeFleetTrucks = !this.state.fields.subFreightProviderId.value;
      let trucks = uniqBy(
        filter(this.props.trucks, truck => {
          return (removeFleetTrucks ? truck.rego !== 'FLEET' : true) || truck.companyId === companyId;
        }),
        'id',
      );
      if (this.state.fields.driverId.value) {
        let selectedDriverCompanyId = get(this.state, 'selectedDriver.companyId');
        if (!this.state.selectedDriver) {
          let selectedDriver = find(this.state.allDrivers, {id: this.state.fields.driverId.value});
          selectedDriverCompanyId = get(selectedDriver, 'companyId');
        }
        trucks = uniqBy(
          filter(this.props.trucks, truck => {
            return (
              truck.companyId === selectedDriverCompanyId &&
                ((removeFleetTrucks ? truck.rego !== 'FLEET' : true) || truck.companyId === companyId)
            );
          }),
          'id',
        );
      }
      else if(companyId)
        trucks =  uniqBy(filter(this.props.trucks, truck => {
          return (removeFleetTrucks ? truck.rego !== 'FLEET' : true) || truck.companyId === companyId
        }), 'id')

      if (this.state.searchOption === SEARCH_BY_FREIGHT_PROVIDER_REGOS) {
        trucks =  uniqBy(filter(trucks, truck => {
          return truck.companyId === companyId
        }), 'id');
      }

      return trucks
    }
  }

  setCompanyDrivers(companyId) {
    APIService.companies(companyId)
      .appendToUrl('employees/minimal/')
      .get()
      .then(drivers => this.setState({allDrivers: drivers}));
  }

  async getDrivers() {
    let drivers = []
    let driverCompanyId = null;
    if (this.state.searchOption === SEARCH_BY_ALL_REGOS && this.state.fields.truckId.value) {
      let companyId = get(this.state, 'selectedTruck.companyId');
      drivers = uniqBy(filter((this.props.drivers || []).concat(this.state.bookieDrivers), { companyId: companyId }), 'id')
      driverCompanyId = companyId;
    }
    else {
      let companyId = this.state.fields.subFreightProviderId.value || this.state.fields.freightProviderId.value;
      let selectedOrder = this.state.selectedOrder || this.getSelectedOrderFromIdentifier(this.state.selectedOrderIdentifier, this.state.orders);
      if (this.props.siteBooking && get(selectedOrder, 'providerId') && get(selectedOrder, 'providerId') !== this.props.currentUserCompanyId)
        companyId = this.props.currentUserCompanyId;
      if (this.state.fields.truckId.value) {
        let selectedTruck = get(this.state, 'selectedTruck') || find(this.props.trucks, {id: this.state.fields.truckId.value});
        if (!selectedTruck)
          selectedTruck = await APIService.trucks(this.state.fields.truckId.value).get();
        drivers = uniqBy(filter(this.props.drivers, { companyId: get(selectedTruck, 'companyId') }), 'id');
        driverCompanyId = get(selectedTruck, 'companyId');
      }
      else if(companyId) {
        drivers = uniqBy(filter(this.props.drivers || [], { companyId: companyId }), 'id')
        driverCompanyId = companyId;
      }
      else
        drivers = uniqBy(this.props.drivers, 'id');
    }
    if ((!drivers || isEmpty(drivers)) && driverCompanyId)
      this.setCompanyDrivers(driverCompanyId);
    else
      this.setState({allDrivers: drivers});
    return drivers;
  }

  wasRejectedOrCancelled() {
    return includes(['rejected', 'cancelled'], get(this.props.slot, 'event.raw.slot.status'));
  }

  isRejectedOrCancelled() {
    return includes(['rejected', 'cancelled'], this.state.fields.status.value);
  }

  shouldShowInlineCommentBox() {
    return this.isRestricted() || this.isNewSlot() || (!this.props.siteBooking && this.isRejectedOrCancelled() && !this.wasRejectedOrCancelled());
  }

  getInlineCommentBoxLabel() {
    if (!this.props.siteBooking && this.state.fields.status.value === 'rejected') return 'Rejected reason...';
    if (!this.props.siteBooking && this.state.fields.status.value === 'cancelled') return 'Incompleted reason...';
    if (this.isNewSlot()) return 'Write a comment/note...';
  }

  getEntityDisplayDom(entity) {
    const { isFetchingOrderRefs, orderRefs } = this.state;

    if (isFetchingOrderRefs) return <LoaderInline style={{height: 'auto'}} imageStyle={{ width: '15%' }} containerClassName='inline-loader-container' />;
    const orderDetails = get(orderRefs, entity)

    const orderIdentifier = orderDetails?.identifier;
    const orderIdentifierDisplay = orderIdentifier || EMPTY_VALUE;
    if (!orderDetails?.canView) return orderIdentifierDisplay;
    const entityId = get(orderRefs, `${entity}.id`)
    if (orderIdentifier && entityId)
      return (
        <a target='_blank' rel='noopener noreferrer' href={`/#/freights/orders/${entityId}/order`}>
          {orderIdentifierDisplay}
        </a>
      );

    return orderIdentifierDisplay;
  }

  getMovementIdentifierDisplayDom() {
    const { slot } = this.props;

    const identifier = slot?.event?.raw?.slot?.movementIdentifier
    return isSiteEmployee() ? identifier : (
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={`/#/freights/movements/${slot?.event?.raw?.slot?.movementId}/details`}
      >
        {identifier}
      </a>
    )
  }

  getCommodityContractDisplayDom() {
    const { siteBooking, slot } = this.props;
    const { isFetchingOrderRefs, orderRefs } = this.state;
    if (isFetchingOrderRefs) return <LoaderInline style={{height: 'auto'}} imageStyle={{ width: '15%' }} containerClassName='inline-loader-container' />;
    const commodityContractNumber = get(orderRefs, 'siteOrder.commodityContractNumber') || get(slot, 'event.raw.slot.commodityContractNumber');
    const commodityContractId = get(orderRefs, 'siteOrder.commodityContractId') || get(slot, 'event.raw.slot.commodityContractId');
    const commodityContractNumberDisplay = commodityContractNumber || '-';
    if (siteBooking && !this.state.isReadonly) return commodityContractNumberDisplay;

    if (commodityContractNumber && commodityContractId)
      return (
        <a target='_blank' rel='noopener noreferrer' href={`/#/contracts/${commodityContractId}/contract`}>
          {commodityContractNumberDisplay}
        </a>
      );

    return commodityContractNumberDisplay;
  }

  isBookedAndDelayed() {
    return this.isSlotDelayed() && get(this.props.slot, 'event.raw.slot.freightProviderId');
  }

  isRestricted() {
    return this.state.fields.status.value === 'restricted';
  }

  focusOnFirstErrorField = () => {
    for (let i = 0; i < this.fieldsOrder.length; i++) {

      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];
      const autoCompleteFields = [
        "siteId", "type", "status", "startDate", "startTime", "endTime", "commodityId", "gradeId",
        "tonnage", "pits", "customer", "bookingNumber", "freightProviderId", "subFreightProviderId",
        "truckId", "driverId", "deliveryOrderNumber", "comment",
      ];

      if (
        (this.fieldsOrder[i] === "type" && this.state.fields.type.errors.length) ||
          (this.fieldsOrder[i] === "comment" && this.state.commentError)
      ) {
        formField.current.focus();
        break;
      }

      if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
      }
    }
  };

  _getSubFreightProviders(companies) {
    return map(companies, company => {
      return {
        id: company.id,
        name: company.name,
        transactionParticipation: company.transactionParticipation,
      };
    }
  );
  }

  assignCurrentUserAndCompanyAsSubFreightProvider(subFreightProviders) {
    let { currentUserCompanyId, siteBooking, currentUser } = this.props;
    const newState = {...this.state}
    let selectedOrder = this.state.selectedOrder || this.getSelectedOrderFromIdentifier(this.state.selectedOrderIdentifier, this.state.orders);
    let userCompany= currentUserCompany();
    subFreightProviders.push(
      {id: currentUserCompanyId, name: get(userCompany, 'name'), transactionParticipation: get(userCompany, 'transactionParticipation')},
      {id: getCountrySystemCompanyId(), name: 'Unknown', transactionParticipation: true }
    );
    const slot = get(this.props.slot, 'event.raw.slot') || get(this.props, 'multiSlots.0.raw.slot')
    if (get(slot, 'status') === 'planned' && siteBooking && get(selectedOrder, 'providerId') && get(selectedOrder, 'providerId') !== currentUserCompanyId) {
      newState.fields.subFreightProviderId.value = currentUserCompanyId;
      newState.fields.driverId.value = currentUser.id;
      newState.selectedTruck = find(this.getTrucks(), {rego: 'FLEET'});
      newState.fields.truckId.value = get(newState.selectedTruck, 'id');
    }
    newState.subFreightProviderCompanies = subFreightProviders;
    this.setState(newState);
  }

  getSubFreightProviders(slotSearchOption=false) {
    let { companyDirectory, currentUserCompanyId } = this.props;
    let regoSearchOption = get(this.props, 'slot.event.raw.slot.freightProviderId') == get(this.props, 'slot.event.raw.slot.truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS;
    let searchOption = slotSearchOption ? regoSearchOption: this.state.searchOption;
    if (searchOption === SEARCH_BY_TRUCK_OWNER_REGOS) {
      APIService.companies(currentUserCompanyId)
        .appendToUrl('companies/minimal/')
        .get()
        .then(items => {
          let subFreightProviders = this._getSubFreightProviders(items);
          this.assignCurrentUserAndCompanyAsSubFreightProvider(subFreightProviders)
        })
    }
    else
      this.setState({subFreightProviderCompanies: this._getSubFreightProviders(companyDirectory || [])})
  }

  shouldShowPickupInfo() {
    const { slot } = this.props;
    const isOutloadSlot = isEqual(get(this.props.slot, 'event.raw.slot.type'), 'outload');
    const slotStatus = get(this.props.slot, 'event.raw.slot.status')

    return get(slot, 'event.raw.slot.movementId') &&
      (isOutloadSlot ? includes(['booked', 'completed', 'in_progress', 'delayed'], slotStatus) :
       includes(['booked', 'in_progress', 'delayed'], slotStatus ));
  }

  estimatedDeliverySlotStartTime(isPickupBooked=false) {
    const { estimatedTimeInSeconds, pickupSlots, fields } = this.state;
    if(isPickupBooked)
      return moment(this.state.pickupEndTime).add(estimatedTimeInSeconds, 'seconds')
    if (estimatedTimeInSeconds && fields.pickupSlotId.value && !isEmpty(pickupSlots)) {
      const pickupSlot = find(pickupSlots, { 'id': fields.pickupSlotId.value });
      if (pickupSlot) {
        return moment(pickupSlot.end).add(estimatedTimeInSeconds, 'seconds');
      }
    }
  }

  async handleMultiSlotSelect(value, id, slot, index) {
    const isDeliverySlot = id === 'deliverySlotId'
    const newState = {...this.state}
    let outloadSlotId = isDeliverySlot ? parseInt(this.state.multiSlots[index].id) : slot.id
    let inloadSlotId = isDeliverySlot ? slot.id : parseInt(this.state.multiSlots[index].id)
    newState.multiSlotsBothSiteBooking.splice(index, 1, {outload: outloadSlotId, inload: inloadSlotId})
    this.setState(newState)
  }

  async handleSlotChange(value, id, slot) {
    const newState = { ...this.state };
    const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
    if ((slotType === 'outload' && id==='deliverySlotId') || (slotType === 'inload' && id==='pickupSlotId')) {
      newState.fields[id].value = value;
      newState.fields[id].errors = [];
    }
    if (id === 'deliverySlotId' || id === 'pickupSlotId') {
      newState.fields[id].value = value;
      newState.fields[id].errors = [];
    }
    let estimatedDeliverySlotStartTime = false;
    estimatedDeliverySlotStartTime = this.timeStampToDateTime(this.estimatedDeliverySlotStartTime()) + 'Z';
    newState.warningText = '';
    this.isDeliverySlotBeforePickupSlot(newState, id);
    if (id === 'deliverySlotId') {
      newState.fields.freightDelivery.timeStart.value = get(slot, 'startTime');
      if (newState.fields.freightPickup.timeStart.value && estimatedDeliverySlotStartTime && value && moment(get(slot, 'start')).isBefore(estimatedDeliverySlotStartTime))
        newState.warningText = 'Warning: Insufficient time to deliver within this slot';
      const isBooked = get(this.props.slot, 'event.raw.slot.status') === 'booked';
      const deliverySlot = find(newState.deliverySlots, { id: newState.fields.deliverySlotId.value });
      if (newState.fields.freightDelivery.timeStart.value && estimatedDeliverySlotStartTime && isBooked &&
        newState.fields.deliverySlotId.value && !isEmpty(newState.deliverySlots) && value) {
        const estimatedDeliverySlot = this.timeStampToDateTime(this.estimatedDeliverySlotStartTime(true)) + 'Z';
        if (moment(get(deliverySlot, 'start')).isBefore(estimatedDeliverySlot))
          newState.warningText = 'Warning: Insufficient time to deliver within this slot';
      }
      if (isBooked && !this.props.isDuplicate){
        newState.fields.excludedCommodityIds.value = get(deliverySlot, 'excludedCommodityIds') || [];
        newState.fields.excludedGradeIds.value = get(deliverySlot, 'excludedGradeIds') || [];
      }
    }
    if (id === 'pickupSlotId') {
      newState.fields.freightPickup.timeStart.value = get(slot, 'startTime');
      if (newState.fields.freightDelivery.timeStart.value && estimatedDeliverySlotStartTime &&
          newState.fields.deliverySlotId.value && !isEmpty(newState.deliverySlots) && value) {
        const deliverySlot = find(newState.deliverySlots, { id: newState.fields.deliverySlotId.value });
        if (moment(get(deliverySlot, 'start')).isBefore(estimatedDeliverySlotStartTime))
          newState.warningText = 'Warning: Insufficient time to deliver within this slot';
      }
    }
    if ((this.state.fields.type.value == 'outload' && id == 'pickupSlotId') || (this.state.fields.type.value == 'inload' && id == 'deliverySlotId')) {
      newState.fields.excludedCommodityIds.value = get(slot, 'excludedCommodityIds')
      newState.fields.excludedGradeIds.value = get(slot, 'excludedGradeIds')
      newState.fields.commodityId.errors = this.state.fields.commodityId.value && includes(get(slot, 'excludedCommodityIds'), this.state.fields.commodityId.value) ? [ORDER_COMMODITY_IN_SLOT_EXCLUDED_COMMODITY_MESSAGE] : newState.fields.commodityId.errors
      newState.fields.gradeId.errors = this.state.fields.gradeId.value && includes(get(slot, 'excludedGradeIds'), this.state.fields.gradeId.value) ? [ORDER_GRADE_IN_SLOT_EXCLUDED_GRADE_MESSAGE] : newState.fields.gradeId.errors
    }
    this.setState(newState);
  }

  deliveryDateMin(pickupSlot) {
    if(pickupSlot)
      return moment(pickupSlot.start.toDate())
    return this.state.fields.freightPickup.date.value ? moment(this.state.fields.freightPickup.date.value) : moment.now();
  }

  deliveryDateMax() {
    return this.state.orderEndDate ? moment(this.state.orderEndDate) : moment.now();
  }

  pickupDateMin() {
    return this.state.orderStartDate ? moment(this.state.orderStartDate) : moment.now();
  }

  pickupDateMax(deliverySlot) {
    if(deliverySlot)
      return moment(deliverySlot.end.toDate())

    return this.state.fields.freightDelivery.date.value ? moment(this.state.fields.freightDelivery.date.value) : moment.now();
  }

  isSlotOrDateFieldDisabled(id) {
    const isPickupField = includes(['pickupSlotId', 'freightPickup.date'], id);
    const isDeliveryField = includes(['deliverySlotId', 'freightDelivery.date'], id);
    const slotType = get(this.state.fields, 'type.value') || get(this.props.slot, 'event.raw.slot.type');
    const isBooked = this.isSlotBooked();
    const isFreightProvider = this.isFreightProvider();
    const isSelfSiteSlot = !isBooked || (slotType === 'outload' && isPickupField && this.state.isPickupSiteUser) || (slotType === 'inload' && isDeliveryField && this.state.isDeliverySiteUser)
    const isDeliverySlotFieldEditable = isBooked && isDeliveryField && (isFreightProvider || this.state.isDeliverySiteUser);
    const isPickupSlotFieldEditable = isBooked && isPickupField && (isFreightProvider || this.state.isPickupSiteUser);
    return (isDeliverySlotFieldEditable || isPickupSlotFieldEditable || this.props.isDuplicate) ? false : !this.props.isDuplicate && !isSelfSiteSlot
  }

  handleMultiSlotDateChange = (value, id, index) => {
    let startDateTime = this.timeStampToDateTime(moment(value).startOf('day'));
    let dayEndDate = moment(value).endOf('day');
    let endDateTime = this.timeStampToDateTime(dayEndDate);
    if (id == 'freightDelivery.date') {
      const newState = {...this.state}
      newState.multiSlotsDates.delivery[index] = value
      this.setState(newState, () => {
        const queryParams = {start: startDateTime, end: endDateTime, load_type: 'inload', freight_provider_id: this.state.fields.freightProviderId.value};
        this.fetchSlots(this.state.deliverySite.id, queryParams, 'deliverySlots');
      })
    }
    else {
      const newState = {...this.state}
      newState.multiSlotsDates.pickup[index] = value
      this.setState(newState, () => {
        const queryParams = {start: startDateTime, end: endDateTime, load_type: 'outload', freight_provider_id: this.state.fields.freightProviderId.value};
        this.fetchSlots(this.state.pickupSite.id, queryParams, 'pickupSlots');
      })
    }
  }

  getEstimatedArrivalTime = estimatedStartTime => {
    const { deliverySite } = this.state
    const utcOffsetMinutes = (get(deliverySite, 'timezone.utcoffsetSeconds') || 60) / 60;
    return estimatedStartTime.utcOffset(utcOffsetMinutes);
  }

  getEstimatedArrivalTimeText = (estimatedStartTime, suffix) => {
    const _suffix = suffix || ''
    const arrivalTime = this.getEstimatedArrivalTime(estimatedStartTime)
    return `(Estimated Arrival Time: ${arrivalTime.format(this.state.countryFormats.datetime)}${_suffix}`
  }


  getRecommendedDeliverySlot(pickupSlot, deliverySlots) {
    const { estimatedTimeInSeconds } = this.state
    if(pickupSlot && deliverySlots) {
      let secondsToReach = estimatedTimeInSeconds || 0 //seconds
      const deliverySlotStartTime = moment(get(pickupSlot, 'end.toDate()') || get(pickupSlot, 'end')).add(secondsToReach, 'seconds')
      const recommendedDeliverySlot = find(orderBy(deliverySlots, 'start'), slot => moment(slot.start).isSameOrAfter(deliverySlotStartTime))

      return recommendedDeliverySlot
    }
  }

  getRecommendedDeliverySlotTextWithoutAPI(pickupSlot, deliverySlots) {
    const slot = this.getRecommendedDeliverySlot(pickupSlot, deliverySlots)
    if(slot)
      return `Recommended: ${this.getSlotDisplay(slot, true)}`
    return ''
  }

  insufficientTimeText = index => {
    const slots = get(this.state.multiSlotsBothSiteBooking, index)
    const outloadSlotId = get(slots, 'outload')
    const inloadSlotId = get(slots, 'inload')
    if(outloadSlotId && inloadSlotId) {
      const outloadSlot = find(this.props.multiSlots, {id: outloadSlotId.toString()}) || find(flatten(values(this.state.pickupSlotsCache)), {id: outloadSlotId})
      const inloadSlot = find(this.props.multiSlots, {id: inloadSlotId.toString()}) || find(flatten(values(this.state.deliverySlotsCache)), {id: inloadSlotId})
      if(outloadSlot && inloadSlot) {
        const { estimatedTimeInSeconds } = this.state
        let secondsToReach = estimatedTimeInSeconds || 0 //seconds
        const mOutloadEnd = moment(get(outloadSlot, 'raw.slot.end') || get(outloadSlot, 'end'))
        mOutloadEnd.add(secondsToReach, 'seconds')
        const mInloadStart = moment(get(inloadSlot, 'raw.slot.start') || get(inloadSlot, 'start'))
        if(!mInloadStart.isSameOrAfter(mOutloadEnd))
          return 'Warning: Insufficient time to deliver within this slot'
      }
    }
    return ''
  }


  handleCheckboxChange = event => {
    const newState = {...this.state}
    const value = event.target.checked
    const id = event.target.id
    set(newState, `fields.${id}.value`, value || false)
    if (id === 'ghms' && value) {
      newState.permitChecked = false;
      newState.accreditationNumberChecked = false;
      newState.restrictedChecked = false;
    }
    this.setState(newState, () => this.setCategory())
  };

  handleSteerChange = event => {
    const newState = {...this.state};
    const checked = event.target.checked;
    newState.truckDetails[event.target.id] = checked;

    if(checked) {
      newState.permitChecked = false;
      if(event.target.id === 'steerPoint5')
        newState.truckDetails.steer1Point1 = false;
      else
        newState.truckDetails.steerPoint5 = false;
    }
    this.setState(newState);
  };


  getGradesExclusionList = () => {
    const { allGrades, fields } = this.state;
    const commodityId = fields.commodityId.value
    const excludedCommodityIds = fields.excludedCommodityIds.value
    if(commodityId)
      return filter(allGrades, {commodityId: commodityId})
    if(!isEmpty(excludedCommodityIds))
      return filter(allGrades, grade => !includes(excludedCommodityIds, grade.commodityId))
    return allGrades
  }

  updateGradesExclusionList = callback => {
    //triggered on change of commodity/excludedCommodity

    const excludedGradeIds = this.state.fields.excludedGradeIds.value
    if(!isEmpty(excludedGradeIds)) {
      const grades = this.getGradesExclusionList()
      if(isEmpty(grades)) {
        if(callback)
          callback()
        return;
      }
      const allowedGradeIds = map(grades, 'id')
      const newGradeIds = filter(excludedGradeIds, gradeId => includes(allowedGradeIds, gradeId))
      if(!isEqual(newGradeIds, excludedGradeIds)) {
        const newState = {...this.state}
        newState.fields.excludedGradeIds.value = newGradeIds
        this.setState(newState, () => {
          if(callback)
            callback()
        })
      }
      else {
        if(callback)
          callback()
      }
    } else {
      if(callback)
        callback()
    }
  }

  handlePermitClick = event => {
    const isChecked = event.target.checked;
    const newState = {...this.state};
    set(newState, 'permitChecked', isChecked);
    if (isChecked) {
      newState.accreditationNumberChecked = false;
      newState.fields.ghms.value = false;
      newState.truckDetails.steerPoint5 = false;
      newState.truckDetails.steer1Point1 = false;
    }
    this.setState(newState);
  }

  handleAccreditationNumberClick = event => {
    const isChecked = event.target.checked;
    const newState = {...this.state};
    set(newState, 'accreditationNumberChecked', isChecked);
    if (isChecked) {
      newState.permitChecked = false;
      newState.fields.ghms.value = false;
    }
    this.setState(newState);
  }

  handleRestrictedClick = event => {
    const isChecked = event.target.checked;
    const id = event.target.id;
    const newState = {...this.state};
    set(newState, id, isChecked);
    if (isChecked)
      newState.fields.ghms.value = false;
    this.setState(newState);
  }

  handleLoadSharingClick = event => event.target.checked ? this.setState({loadSharing: true}) : this.setState({loadSharing: false});

  handleTruckDetailsChange = event => {
    const newState = {...this.state}
    let id = event.target.id;
    set(newState, id, id === 'truckDetails.restricted' ? event.target.checked : event.target.value);
    if (id !== 'truckDetails.restricted' && event.target.value) {
      const parts = id.split(".")
      const errorField = `truckDetailsErrors.${parts[1]}`;
      set(newState, errorField, '')
    }
    this.setState(newState);
  }

  handleTruckDetailsSelectValueChange(value, id) {
    const newState = { ...this.state };
    set(newState, id, value);
    const parts = id.split(".")
    const errorField = `truckDetailsErrors.${parts[1]}`;
    set(newState, errorField, '')
    this.setState(newState);
  }

  handleSearchOptionChange = (event, value) => {
    value = get(event, 'target.value') || value
    const newState = {...this.state};
    newState.searchOption = value;
    newState.selectedTruck = null;
    newState.fields.truckId.value = null;
    newState.fields.driverId.value = null;
    newState.fields.subFreightProviderId.value = null;
    if (value === SEARCH_BY_FREIGHT_PROVIDER_REGOS && this.state.fields.freightProviderId.value)
      this.props.getCompanyTrucks(this.state.fields.freightProviderId.value, receiveTrucks);
    if (value === SEARCH_BY_TRUCK_OWNER_REGOS)
      newState.applySubFreightProvider = true;
    this.setState(newState, () => {
      if (value === SEARCH_BY_TRUCK_OWNER_REGOS)
        this.getSubFreightProviders();
    });
  }

  setFleetTruckIfSiteBooking() {
    let trucks = this.getTrucks()
    const newState = {...this.state};
    if (this.props.siteBooking && !isEmpty(trucks) && !this.state.fields.truckId.value) {
      const fleetTruck = find(trucks, {rego: "FLEET"});
      if (fleetTruck) {
        newState.fields.truckId.value = get(fleetTruck, 'id');
        newState.selectedTruck = fleetTruck;
      }
    }
    if (!this.state.fields.driverId.value && !isEmpty(this.state.allDrivers)) {
      const currentUserDriver = find(this.state.allDrivers, {id: this.props.currentUser.id})
      if(currentUserDriver)
        newState.fields.driverId.value = currentUserDriver.id
    }
    this.setState(newState);
  }

  getLockedFreightProviderName() {
    let selectedOrder = this.state.selectedOrder || this.getSelectedOrderFromIdentifier(this.state.selectedOrderIdentifier, this.state.orders);
    let lockedProvider = this.props.siteBooking && !get(selectedOrder, 'providerId') ? get(this.props.currentUser, 'company.name') : get(this.props.slot, 'event.raw.slot.freightProvider.name') || get(selectedOrder, 'providerName') || get(this.state.selectedTruck, 'companyName');
    const slot = get(this.props.slot, 'event.raw.slot') || get(this.props, 'multiSlots.0.raw.slot')
    if (slot && get(slot, 'status') !== 'planned')
      lockedProvider = get(this.props.slot, 'event.raw.slot.freightProvider.name');
    return lockedProvider;
  }

  getEntityUrl(field) {
    if (this.state.reason) {
      let url = ''
      let identifier = ''
      if (this.state.reason === 'contract') {
        identifier = this.state.contractNumber
        url = `#/contracts/${this.state.commodityContractId}/edit`
      }
      else if (this.state.reason === 'pickup order') {
        identifier = this.state.pickupOrderIdentifier
        url = `#/freights/orders/${this.state.pickupOrderId}/edit`
      }
      else if (this.state.reason === 'delivery order') {
        identifier = this.state.deliveryOrderIdentifier
        url = `#/freights/orders/${this.state.deliveryOrderId}/edit`
      }
      else if (this.state.reason === 'parent order') {
        identifier = this.state.parentOrderIdentifier
        url = `#/freights/orders/${this.state.parentOrderId}/edit`
      }
      return field === 'url' ? url : identifier
    }
  }

  setChemicalApplications(forceSet) {
    if (forceSet || (this.state.baseEntityChemicalApplications && !isEmpty(this.state.baseEntityChemicalApplications) && isEmpty(this.state.chemicalApplications))) {
      let chemicalApplications = [];
      const commodityIdOptions = this.state.baseEntityChemicalApplications.map(chemicalApplication => chemicalApplication.commodityId)
      this.state.baseEntityChemicalApplications.map((obj, index) => {
        chemicalApplications.push({
          id: index + 1,
          commodityId: obj.commodityId,
          gradeId: obj.gradeId,
          applicationRate: obj.applicationFee,
          commodityIdOptions: commodityIdOptions,
          gradeIds: this.state.spreadDetails.map(detail => detail.gradeId),
          errors: ''
        })
      })
      this.setState({chemicalApplications: chemicalApplications});
    }
    else if (!isEmpty(this.state.spreadDetails) && isEmpty(get(this.state.chemicalApplications, '0.gradeIds'))) {
      this.setState(prevState => ({
        chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => ({...chemicalApplication, gradeIds:this.state.spreadDetails.map(detail => detail.gradeId)}))
      }));
    }
  }

  onChemicalApplicationCommodityChange(obj, value) {
    let commodity = undefined
    if (value)
      commodity = find(this.props.commodities, {id: value});
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, commodityId: value, commodity: commodity};
        return {...chemicalApplication}
      })
    }));
  }

  applicationRateUnitDisplay(commodity) {
    let applicationUnit = get(UNIT_ABBREVIATIONS, commodity?.unit);
    let displayUnit = ''
    if (applicationUnit)
      displayUnit = `(${get(UNIT_ABBREVIATIONS, commodity?.unit)}/${this.state.unit})`;
    return displayUnit;
  }

  handleApplicationRateChange(obj, value) {
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, applicationRate: value};
        return {...chemicalApplication}
      })
    }));
  }

  onChemicalApplicationGradeChange(obj, item) {
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, gradeId: item.id, gradeName: item.name};
        return {...chemicalApplication}
      })
    }));
  }

  onChemicalApplicationAdd() {
    const newState = {...this.state};
    const newObj = {
      id: newState.chemicalApplications.length + 1,
      commodityIdOptions: this.state.baseEntityChemicalApplications.map(chemicalApplication => chemicalApplication.commodityId),
      commodityId: undefined,
      commodity: undefined,
      gradeId: undefined,
      gradeIds: this.state.spreadDetails.map(detail => detail.gradeId),
      gradeName: undefined,
      applicationRate: undefined,
      errors: []
    }
    newState.chemicalApplications = [...newState.chemicalApplications, newObj];
    newState.doNotSetChemicalApplications = false;
    this.setState(newState)
  }

  onChemicalApplicationDelete(obj) {
    const newState = {...this.state};
    let chemicalApplications = [...newState.chemicalApplications.filter(chemicalApplication => chemicalApplication.id !== obj.id)];
    newState.chemicalApplications = chemicalApplications.map((chemicalApplication, index) => ({...chemicalApplication, id: index + 1}));
    if (isEmpty(newState.chemicalApplications))
      newState.doNotSetChemicalApplications = true;
    this.setState(newState)
  }

  render() {
    const { isReadonly, multiSlots, subFreightProviderCompanies, allDrivers } = this.state;
    const { siteBooking, parentSlot } = this.props;
    const isMultiSlotBooking = this.isMultiSlotBooking()
    const isTrailerSlot = this.isTrailerSlot();
    let trucks = this.getTrucks();
    const isTrailerBooking = this.isTrailerBooking();
    const isCounterSiteTrailerBooking = this.isCounterSiteTrailerBookingEnabled();
    const statuses = this.getAllStatuses();
    const canDelete = this.canDelete();
    const pickupSlot = this.props.slot?.event?.raw?.slot?.type === 'outload' ? this.props.slot.event.raw.slot : this.state.counterSlot
    const deliverySlot = this.props.slot?.event?.raw?.slot?.type === 'inload' ? this.props.slot.event.raw.slot : this.state.counterSlot
    const isBothSiteOrderBookingOn = this.isBothSiteOrderBookingOn()
    const isDisabledDeliverySlot = isBothSiteOrderBookingOn && ['completed', 'in_progress'].includes(deliverySlot?.status)
    const isDisabledPickupSlot = isBothSiteOrderBookingOn && ['completed', 'in_progress'].includes(pickupSlot?.status)
    const isCompleted = this.isSlotCompleted();
    const isPlanned = this.isSlotPlanned();
    const isNew = this.isNewSlot();
    const isRestricted = this.isRestricted();
    const isFrequencySelected = !this.state.repeat && this.state.fields.frequency.value;
    const isBooked = this.isSlotBooked();
    const isBookedAndDelayed = this.isBookedAndDelayed();
    const isCancelled = this.isSlotCancelled();
    const showRepeatFields = !isFrequencySelected && isNew;
    const showFrequencyField = isNew && (this.state.repeatSelectValue === 2 || this.state.repeatSelectValue === 3);
    const isMultipleSlot = this.isMultipleSlot();
    const titleStyles = this.state.fields.status.value ? { position: 'relative', top: '-5px' } : {};
    const showSaveButton = isCancelled ? isNew : true;
    const shouldShowInlineCommentBox = this.shouldShowInlineCommentBox();
    const shouldShowPickupInfo = this.shouldShowPickupInfo();
    const titleHeight = isEmpty(parentSlot) ? '58px': '68px';
    const showMarkBookedButton = isCompleted && (
      get(find(this.props.sites, { id: this.state.fields.siteId.value }), 'companyId') == this.props.currentUserCompanyId
    ) && isCurrentUserCompanyAdmin();
    const slotType = get(this.props.slot, 'event.raw.slot.type') || get(this.state.fields, 'type.value');
    const isOrderDeliverySite = get(this.state.deliverySite, 'id') === this.state.fields.siteId.value
    const pickupSiteSMSettings = isOrderDeliverySite ? this.state.counterSlotSmSetting : this.props.settings;
    const deliverySiteSMSettings = isOrderDeliverySite ? this.props.settings : this.state.counterSlotSmSetting;
    const isPickupUserSite = Boolean(find(this.props.sites, {id: get(this.state.pickupSite, 'id')}))
    const isDeliveryUserSite = Boolean(find(this.props.sites, {id: get(this.state.deliverySite, 'id')}))
    const selectedPickupSlot = find(this.state.pickupSlots || [], {id: this.state.fields.pickupSlotId.value})
    const selectedDeliverySlot = find(this.state.deliverySlots || [], {id: this.state.fields.deliverySlotId.value})
    const pickupSlotUnmatchedFields = selectedPickupSlot ? this.getOrderUnmatchFields(selectedPickupSlot.commodityId, selectedPickupSlot.gradeId, selectedPickupSlot.season, selectedPickupSlot.customer, selectedPickupSlot.type, selectedPickupSlot.siteId, selectedPickupSlot.excludedCommodityIds, selectedPickupSlot.excludedGradeIds) : [];
    const deliverySlotUnmatchedFields = selectedDeliverySlot ? this.getOrderUnmatchFields(selectedDeliverySlot.commodityId, selectedDeliverySlot.gradeId, selectedDeliverySlot.season, selectedDeliverySlot.customer, selectedDeliverySlot.type, selectedDeliverySlot.siteId, selectedDeliverySlot.excludedCommodityIds, selectedDeliverySlot.excludedGradeIds): [];
    const pickupSlotErrorState = this.getSlotErrorState(pickupSlotUnmatchedFields)
    const deliverySlotErrorState = this.getSlotErrorState(deliverySlotUnmatchedFields)
    const isValidPickupSlot = pickupSlotErrorState.valid
    const isValidDeliverySlot = deliverySlotErrorState.valid
    const pickupSlotErrorColor = (pickupSlotErrorState.error || !isPickupUserSite) ? 'error' : 'warning'
    const deliverySlotErrorColor = (deliverySlotErrorState.error || !isDeliveryUserSite) ? 'error' : 'warning'
    const slotErrorSuffix = siteBooking ? ' Change order number or this slot.' : ' This will update the Type/Commodity/Grade selected in the slot.'
    const pickupSlotErrorLabel = this.getErrorLabelFromFields(pickupSlotUnmatchedFields, slotErrorSuffix)
    const deliverySlotErrorLabel = this.getErrorLabelFromFields(deliverySlotUnmatchedFields, slotErrorSuffix)
    const commodityFieldMarginBottom = (isPlanned || isNew) ? (isEmpty(this.state.fields.excludedCommodityIds.value) ? 17 : 10) : 0
    const commodityFieldHeight = `${75-commodityFieldMarginBottom}px`
    const gradeFieldMarginBottom = (isPlanned || isNew) ? (isEmpty(this.state.fields.excludedGradeIds.value) ? 17 : 10) : 0
    const gradeFieldHeight = `${75-gradeFieldMarginBottom}px`
    let companiesWithTrucks = [...this.props.companiesWithTrucks, currentUserCompany()]
    if (!this.props.siteBooking && !get(this.state.selectedOrder, 'providerId') && !isEmpty(this.state.allCompanies))
      companiesWithTrucks = [...this.state.allCompanies];
    const exclusionGrades = this.getGradesExclusionList()
    const shouldShowTruckConfig = this.shouldShowTruckConfig()
    const showAdditionalMassLimitCodeFields = get(this.state.pickupSite, 'additionalMassLimitCodes') || get(this.state.deliverySite, 'additionalMassLimitCodes');
    const categoryId = get(this.state.truckDetails, 'categoryId');
    let disableLoadSharing = false;
    if (categoryId && this.state.categories) {
      const category = find(this.state.categories, {id: categoryId})
      const stateCode = slotType === 'outload' ? get(this.state.pickupSite, 'marketZoneStateCode'): get(this.state.deliverySite, 'marketZoneStateCode');
      disableLoadSharing = get(category, `loadSharing.${stateCode}`) === 0;
    }
    const showLockedSubFreightProvider = (!siteBooking || this.isSubFreightProvider()) && this.state.fields.subFreightProviderId.value && this.shouldShowField('subFreightProviderId') && get(this.props.slot, 'event.raw.slot.subFreightProvider.name') && includes([SEARCH_BY_ALL_REGOS, SEARCH_BY_FREIGHT_PROVIDER_REGOS], this.state.searchOption);
    const regoLabel = getCountryLabel('rego');
    const siteOrderDisplay = get(this.props.slot, 'event.raw.slot.movementId') ? this.getEntityDisplayDom('siteOrder') : '-'
    let refItemWidth = siteOrderDisplay != '-' && siteOrderDisplay ? '20%' : '33%';
    const isParentSlotOfCurrentUserCompany = get(parentSlot, 'raw.site.companyId') == this.props.currentUserCompanyId;
    const isNoticePermit = this.state.fields.massLimitPermit.value === 'Notice';
    return (
      <Dialog
        open={this.state.showDialog}
        onClose={this.handleDialogClose}
        aria-labelledby='form-dialog-title'
        className={'freight-slot-dialog' + ((this.state.showComments || this.state.showHistory || this.state.showPickupInfo || this.state.showVendorDecInfo) ? ' with-comments' : '')}
      >
        <DialogTitleWithCloseIcon
          onClose={this.handleDialogClose}
          className={this.getTitleClass()}
          style={{ backgroundColor: this.getBgColor(), marginBottom: '10px', height: titleHeight, borderBottom: '5px solid ' + this.getBorderColor() }}
          id='form-dialog-title'
        >
          <span style={titleStyles}>{this.getTitle()}</span>
          {parentSlot &&
            <span style={{fontSize: '14px', display: 'block', marginTop: '-10px'}}>
              <a target='_blank' rel='noopener noreferrer' href={isParentSlotOfCurrentUserCompany ? `#/site-management?slotId=${get(parentSlot, 'raw.slot.id')}&startDate=${get(parentSlot, 'raw.slot.start')}&siteId=${get(parentSlot, 'raw.site.id')}` : `#/site-bookings?companyId=${get(parentSlot, 'raw.site.companyId')}&slotId=${get(parentSlot, 'raw.slot.id')}&startDate=${get(parentSlot, 'raw.slot.start')}&siteId=${get(parentSlot, 'raw.site.id')}`} style={{ textDecoration: 'none' }}>
                {`(Truck Slot - ${parentSlot.title})`}
              </a>
            </span>
          }
        </DialogTitleWithCloseIcon>
        {get(this.props.slot, 'event.raw.slot.movementId') && (
          <div className='freight-slot-load-details col-sm-12'>
            <div className='item' style={{width: refItemWidth}}>
              <div className='label'>Freight Movement</div>
              <div className='value'>{this.getMovementIdentifierDisplayDom()}</div>
            </div>
            {
                siteOrderDisplay !== '-' &&
            <div className='item' style={{width: refItemWidth}}>
                  <div className='label'>Site Order</div>
                  <div className='value'>{siteOrderDisplay}</div>
                </div>
            }
            <div className='item' style={{width: refItemWidth}}>
              <div className='label'>Freight Order</div>
              <div className='value'>{this.getEntityDisplayDom('freightOrder')}</div>
            </div>
            <div className='item' style={{width: refItemWidth}}>
              <div className='label'>Grain Order</div>
              <div className='value'>{this.getEntityDisplayDom('grainOrder')}</div>
            </div>
            <div className='item item-last' style={{width: refItemWidth}}>
              <div className='label'>Commodity Contract</div>
              <div className='value'>{this.getCommodityContractDisplayDom()}</div>
            </div>
          </div>
        )}
        <DialogContent className='freight-slot-form-content'>
          <div className='col-sm-12 no-side-padding' style={{ height: '700px', width: '93%' }}>
            <form onSubmit={this.handleSubmit}>
              <div className='col-sm-12 no-side-padding'>
                <div className='col-sm-12 no-side-padding'>
                  {this.shouldShowField('siteId') && (
                    <div className='col-sm-6'>
                      <CommonSelect
                        id='siteId'
                        setRef={this.fieldRef['siteId']}
                        floatingLabelText={this.getFieldInfo('siteId', 'label')}
                        onChange={this.handleSelectFieldChange}
                        errorText={this.state.fields.siteId.errors[0]}
                        items={this.props.sites}
                        value={this.state.fields.siteId.value}
                        disabled={!isNew || isReadonly}
                      />
                    </div>
                  )}
                  {this.shouldShowField('type') && (
                    <div className='col-sm-6'>
                      <CommonSelect
                        id='type'
                        setRef={this.fieldRef['type']}
                        onChange={this.handleTypeChange}
                        floatingLabelText={this.getFieldInfo('type', 'label')}
                        errorText={this.state.fields.type.errors[0]}
                        items={this.TYPES}
                        value={this.state.fields.type.value}
                        selectConfig={{ text: 'name', value: 'id' }}
                        disabled={this.isFieldDisabled('type')}
                        includeEmptyOption={true}
                        emptyOptionText='Select...'
                      />
                    </div>
                  )}
                </div>
                {!siteBooking && (
                  <div style={{ marginTop: '20px', marginBottom: this.state.fields.status.value === 'planned' ? '18px' : '0px' }} className='col-sm-12 no-side-padding'>
                    {this.shouldShowField('status') && (
                      <div className='col-sm-6'>
                        <CommonSelect
                          id='status'
                          setRef={this.fieldRef['status']}
                          onChange={this.handleStatusChange}
                          floatingLabelText={this.getFieldInfo('status', 'label')}
                          errorText={this.state.fields.status.errors[0]}
                          items={statuses}
                          value={this.state.fields.status.value}
                          selectConfig={{ text: 'name', value: 'id' }}
                          disabled={this.isFieldDisabled('status') || isMultiSlotBooking || isTrailerSlot}
                        />
                      </div>
                    )}
                    {this.shouldShowField('priority') && (
                      <div className='col-sm-6'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              checked={this.state.fields.priority.value}
                              onChange={this.handleMillPriorityChange}
                              disabled={this.isFieldDisabled('priority')}
                            />
                          }
                          label={this.getFieldInfo('priority', 'label')}
                          style={{ marginTop: '10px' }}
                        />
                      </div>
                    )}
                    {isRestricted && (
                      <div className='col-sm-6'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color='primary'
                              checked={this.state.fields.restrictedVisibleToCarrier.value}
                              onChange={this.handleRestrictedVisibleToCarrierChange}
                              disabled={isReadonly}
                            />
                          }
                          label='Visible to Carriers'
                          style={{ marginTop: '10px' }}
                        />
                      </div>
                    )}
                    {this.shouldShowOrderIdField() && !this.state.isFetchingOrders && !this.props.siteBooking && (
                      <div className='col-sm-6'>
                        <CommonAutoSuggest
                          id='orderId'
                          suggestions={this.orderItems()}
                          label={this.getFieldInfo('deliveryOrderNumber', 'label')}
                          value={this.state.selectedOrderIdentifier}
                          errorText={this.state.fields.orderId.errors[0]}
                          onChange={this.handleOrderSelect}
                          onBlur={() => this.handleOrderBlur()}
                          dontAutoselectSingleItem={true}
                          disabled={this.isFieldDisabled('orderId')}
                          mainContainerStyle={{ marginTop: '3px' }}
                          onSelect={this.onOrderSelect}
                          inputProps={{ autoComplete: 'off' }}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className='col-sm-12 no-side-padding'>
                  <div className='col-sm-6'>
                    {this.shouldShowOrderIdField() && !this.state.isFetchingOrders && this.props.siteBooking && (
                      <div style={{ minHeight: '75px' }}>
                        <CommonTextField
                          id='orderId'
                          setRef={this.fieldRef['orderId']}
                          label={this.getFieldInfo('deliveryOrderNumber', 'label')}
                          value={this.state.selectedOrderIdentifier}
                          helperText={this.state.fields.orderId.errors[0]}
                          onChange={this.handleOrderChange}
                          onBlur={this.handleOrderBlur}
                          disabled={this.isFieldDisabled('orderId') || isTrailerSlot}
                          style={{ marginTop: '3px' }}
                          lockIconStyle={{ marginTop: '3px' }}
                          inputProps={{ autoComplete: 'off' }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {
                !isRestricted &&
                  <Divider light style={{ width: '120%', marginLeft: '-100px', marginBottom: '20px' }} />
              }

              <div className='col-sm-12 no-side-padding'>
                {
                  isMultiSlotBooking && !this.state.bothSitesOrderBookingOn &&
                    <div className='col-sm-12 no-side-padding' style={{marginBottom: "10px", marginTop: "-10px"}}>
                      <div className='col-sm-12' style={{marginBottom: '5px', fontWeight: '500'}}>
                        {`Slot Selected: ${multiSlots.length}`}
                      </div>
                      {
                        map(multiSlots, slot => {
                          const rawSlot = slot.raw.slot
                          const unmatchedFields = this.getOrderUnmatchFields(
                            rawSlot.commodityId, rawSlot.gradeId, rawSlot.season, null, rawSlot.type, rawSlot.siteId, rawSlot.excludedCommodityIds, rawSlot.excludedGradeIds)
                          const slotErrorState = this.getSlotErrorState(unmatchedFields)
                          const isValid = slotErrorState.valid
                          const errorLabel = this.getErrorLabelFromFields(unmatchedFields, slotErrorSuffix)
                          const errorColor = slotErrorState.error ? 'error' : 'warning'
                          return (
                            <div className='col-sm-12' key={slot.id} style={{display: 'flex', alignItems: 'center', margin: '2px 0'}}>
                              <span style={{display: 'flex', alignItems: 'center', marginRight: '5px'}}>{this.getSlotDisplay(slot)}</span>
                              <Tooltip title={ isValid ? "This slot matches all the criteria." : errorLabel } arrow placement='right'>
                                <span style={{display: 'flex', alignItems: 'center'}}>
                                  {
                                    isValid ?
                                      <SuccessIcon color='success' /> :
                                      <ErrorIcon color={errorColor} className='slot-error-icon' />
                                  }
                                </span>
                              </Tooltip>
                            </div>
                          )
                        })
                      }
                    </div>

                }
                {
                  !this.state.bothSitesOrderBookingOn && !isMultiSlotBooking &&
                    <div className='col-sm-12 no-side-padding'>
                      <div className='col-sm-12' style={{ marginBottom: '20px' }}>
                        {this.shouldShowField('startDate') && (
                          <div className='col-sm-6 no-left-padding'>
                            <CommonDatePicker
                              id='startDate'
                              setRef={this.fieldRef['startDate']}
                              floatingLabelText={this.getFieldInfo('startDate', 'label')}
                              onChange={this.handleStartDateChange}
                              errorText={this.state.fields.startDate.errors[0]}
                              value={this.state.fields.startDate.value}
                              disabled={this.isFieldDisabled('startDate')}
                            />
                          </div>
                        )}
                        <div className='col-sm-6 no-right-padding'>
                          {this.shouldShowField('startTime') && (
                            <div className='col-sm-6 no-left-padding'>
                              <CommonTimePicker
                                id='startTime'
                                setRef={this.fieldRef['startTime']}
                                floatingLabelText={this.getFieldInfo('startTime', 'label')}
                                onChange={this.handleTimeChange}
                                errorText={this.state.fields.startTime.errors[0]}
                                defaultValue={this.state.fields.startTime.value}
                                disabled={this.isFieldDisabled('startTime')}
                              />
                            </div>
                          )}
                          {this.shouldShowField('endTime') && (
                            <div className='col-sm-6 no-side-padding'>
                              <CommonTimePicker
                                id='endTime'
                                setRef={this.fieldRef['endTime']}
                                floatingLabelText={this.getFieldInfo('endTime', 'label')}
                                onChange={this.handleTimeChange}
                                errorText={this.state.fields.endTime.errors[0]}
                                defaultValue={this.state.fields.endTime.value}
                                disabled={this.isFieldDisabled('endTime')}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      {
                        (showRepeatFields || showFrequencyField) && (
                          <div className='col-sm-12'>
                            <div className='col-sm-6 no-left-padding'>
                              <CommonSelect
                                id='repeat'
                                onChange={this.handleRepeatSelectFieldChange}
                                items={[
                                  { id: 1, name: this.SINGLE_SLOT },
                                  { id: 2, name: this.MULTIPLE_SLOTS_SAME_DAY },
                                  { id: 3, name: this.MULTIPLE_SLOTS_ACROSS_DAYS },
                                ]}
                                value={this.state.repeatSelectValue}
                              />
                            </div>
                            {showRepeatFields && (
                              <div className='col-sm-6 no-right-padding'>
                                <RepeatSlot
                                  endsOnMinDate={this.state.fields.startDate.value}
                                  show={this.state.repeat}
                                  onChange={this.handleRepeatDetailsChange}
                                />
                              </div>
                            )}
                            {
                              showRepeatFields && showFrequencyField &&
                                <div className='col-sm-6 no-right-padding'></div>
                            }
                            {
                              showFrequencyField &&
                                <div className='col-sm-6 no-right-padding'>
                                  <CommonSelect
                                    id='frequency'
                                    floatingLabelText='Time Slot Frequency'
                                    onChange={this.handleFrequencyChange}
                                    errorText={this.state.fields.frequency.errors[0]}
                                    items={this.state.frequencies}
                                    value={this.state.fields.frequency.value}
                                    includeEmptyOption={true}
                                    dontAutoselectSingleItem={true}
                                  />
                                </div>
                            }
                          </div>
                        )}
                    </div>
                }
                {
                  !isRestricted && !this.state.bothSitesOrderBookingOn &&
                    <Divider light style={{ width: '120%', marginLeft: '-100px', marginBottom: '20px' }} />
                }
                {
                  this.state.bothSitesOrderBookingOn &&
                    <div className='col-sm-12 no-side-padding' style={{ marginBottom: '20px' }}>
                      <div className='col-sm-6'>
                        Pickup
                        <div style={{ minHeight: '75px', marginBottom: '10px' }}>
                          <CommonTextField
                            id="pickupSite"
                            label="Pickup Site"
                            value={get(this.state, 'pickupSite.displayName')}
                            disabled
                          />
                        </div>
                        {
                          isMultiSlotBooking ? (
                            isOrderDeliverySite ? (
                              <React.Fragment>
                                {
                                  map(multiSlots, (_slot, index) => {
                                    const date = this.state.multiSlotsDates.pickup[index] || moment(_slot.raw.slot.start).startOf('day')
                                    const startDateTime = this.timeStampToDateTime(date)
                                    const candidateSlots = get(this.state.pickupSlotsCache, startDateTime) || []
                                    const rawSlot = find(candidateSlots, {id: get(this.state.multiSlotsBothSiteBooking, `${index}.outload`)})
                                    const unmatchedFields = rawSlot ? this.getOrderUnmatchFields(
                                      rawSlot.commodityId, rawSlot.gradeId, rawSlot.season, null, rawSlot.type, rawSlot.siteId, rawSlot.excludedCommodityIds, rawSlot.excludedGradeIds) : []
                                    const slotErrorState = this.getSlotErrorState(unmatchedFields)
                                    const isValid = slotErrorState.valid
                                    const errorColor = (slotErrorState.error || !isPickupUserSite) ? 'error' : 'warning'
                                    const errorLabel = this.getErrorLabelFromFields(unmatchedFields, slotErrorSuffix)
                                    return (
                                      <React.Fragment key={_slot.id}>
                                        <div className='col-sm-5 no-left-padding' style={{ minHeight: '75px' }}>
                                          <CommonDatePickerUncontrolled
                                            id="freightPickup.date"
                                            floatingLabelText="Pickup Date"
                                            onChange={(value, id) => this.handleMultiSlotDateChange(value, id, index)}
                                            errorText={this.state.fields.freightPickup.date.errors[0]}
                                            defaultValue={moment(_slot.raw.slot.start).format('YYYY-MM-DD')}
                                            style={{ float: 'left' }}
                                            minDate={this.pickupDateMin()}
                                            maxDate={this.pickupDateMax(_slot)}
                                          />
                                        </div>
                                        <div className='col-sm-6 no-side-padding' style={{ minHeight: '75px' }}>
                                          <CommonSelect
                                            id='pickupSlotId'
                                            onChange={
                                              (value, id, slot) => this.handleMultiSlotSelect(value, id, slot, index)
                                            }
                                            floatingLabelText={this.getSlotLabel("pickupSite", "Select")}
                                            selectConfig={{ text: 'name', value: 'id' }}
                                            items={candidateSlots}
                                            dontAutoselectSingleItem
                                          />
                                          {
                                            isEmpty(candidateSlots) &&
                                              <div className='col-xs-12 no-side-padding' style={{fontSize: '12px', color: WARNING_ORANGE}}>
                                                {this.props.siteBooking ? NO_SLOTS_AVAILABLE : NO_SLOTS_AVAILABLE_SM}
                                              </div>
                                          }
                                        </div>
                                        {
                                          rawSlot &&
                                            <div className='col-sm-1 no-side-padding' style={{ minHeight: '75px', display: 'flex', justifyContent: 'right' }}>
                                              <Tooltip title={ isValid ? "This slot matches all the criteria." : errorLabel} arrow placement='right'>
                                                <span style={{display: 'flex', alignItems: 'center'}}>
                                                  {
                                                    isValid ?
                                                      <SuccessIcon color='success' /> :
                                                      <ErrorIcon color={errorColor} className='slot-error-icon' />
                                                  }
                                                </span>
                                              </Tooltip>
                                            </div>
                                        }
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {
                                  map(multiSlots, _slot => {
                                    const rawSlot = get(_slot, 'raw.slot')
                                    const unmatchedFields = rawSlot ? this.getOrderUnmatchFields(
                                      rawSlot.commodityId, rawSlot.gradeId, rawSlot.season, null, rawSlot.type, rawSlot.siteId, rawSlot.excludedCommodityIds, rawSlot.excludedGradeIds) : []
                                    const slotErrorState = this.getSlotErrorState(unmatchedFields)
                                    const isValid = slotErrorState.valid
                                    const errorColor = (slotErrorState.error || !isPickupUserSite) ? 'error' : 'warning'
                                    const errorLabel = this.getErrorLabelFromFields(unmatchedFields, slotErrorSuffix)
                                    return (
                                      <React.Fragment key={_slot.id}>
                                        <div className='col-sm-5 no-left-padding' style={{ minHeight: '75px' }}>
                                          <CommonTextField
                                            id="freightPickup.date"
                                            label="Pickup Date"
                                            value={moment(_slot.raw.slot.start).format(this.state.countryFormats.date)}
                                            style={{ float: 'left' }}
                                            disabled
                                          />
                                        </div>
                                        <div className='col-sm-6 no-side-padding' style={{ minHeight: '75px' }}>
                                          <CommonTextField
                                            id="pickupSlot"
                                            label={this.getSlotLabel("pickupSite", "Pickup")}
                                            value={this.getSlotDisplay(_slot, true)}
                                            disabled
                                          />
                                        </div>
                                        {
                                          rawSlot &&
                                            <div className='col-sm-1 no-side-padding' style={{ minHeight: '75px', display: 'flex', justifyContent: 'right' }}>
                                              <Tooltip title={ isValid ? "This slot matches all the criteria." : errorLabel} arrow placement='right'>
                                                <span style={{display: 'flex', alignItems: 'center'}}>
                                                  {
                                                    isValid ?
                                                      <SuccessIcon color='success' /> :
                                                      <ErrorIcon color={errorColor} className='slot-error-icon' />
                                                  }
                                                </span>
                                              </Tooltip>
                                            </div>
                                        }
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </React.Fragment>
                            )
                          ) :
                            <React.Fragment>
                              <div className='col-sm-5 no-left-padding' style={{ minHeight: '75px' }}>
                                <CommonDatePicker
                                  id="freightPickup.date"
                                  floatingLabelText="Pickup Date"
                                  onChange={this.handleDateChange}
                                  errorText={this.state.fields.freightPickup.date.errors[0]}
                                  value={this.state.fields.freightPickup.date.value}
                                  style={{ float: 'left' }}
                                  minDate={this.pickupDateMin()}
                                  maxDate={this.pickupDateMax()}
                                  disabled={isDisabledPickupSlot || this.isSlotOrDateFieldDisabled("freightPickup.date")}
                                />
                              </div>
                              <div className='col-sm-6 no-right-padding' style={{ minHeight: '75px' }}>
                                { ((isBooked && this.state.bothSitesOrderBookingOn) || !isBooked || this.props.isDuplicate) ?
                                  <CommonAutoSelect
                                    id='pickupSlotId'
                                    onChange={this.handleSlotChange}
                                    errorText={this.state.fields.pickupSlotId.errors[0]}
                                    label={this.getSlotLabel("pickupSite", "Select")}
                                    dataSourceConfig={{ text: 'name', value: 'id' }}
                                    items={this.state.pickupSlots || []}
                                    value={this.state.fields.pickupSlotId.value}
                                    disabled={isDisabledPickupSlot || this.isSlotOrDateFieldDisabled('pickupSlotId')}
                                    dontAutoselectSingleItem
                                    notClearable
                                  /> :
                                  <CommonTextField
                                    id="pickupSlot"
                                    label={this.getSlotLabel("pickupSite", "Pickup")}
                                    value={get(this.state, 'selectedPickupSlot')}
                                    disabled
                                  />
                                }
                              </div>
                              {
                                selectedPickupSlot &&
                                  <div className='col-sm-1 no-side-padding' style={{ minHeight: '75px', display: 'flex', justifyContent: 'right' }}>
                                    <Tooltip title={ isValidPickupSlot ? "This slot matches all the criteria." : pickupSlotErrorLabel} arrow placement='right'>
                                      <span style={{display: 'flex', alignItems: 'center'}}>
                                        {
                                          isValidPickupSlot ?
                                            <SuccessIcon color='success' /> :
                                            <ErrorIcon color={pickupSlotErrorColor} className='slot-error-icon' />
                                        }
                                      </span>
                                    </Tooltip>
                                  </div>
                              }
                              {
                              !isTrailerSlot && this.isPickupSiteTrailerBookingEnabled() && !isRestricted && !isNew && (this.state.fields.status.value !== 'planned' || siteBooking) && !isMultipleSlot && this.state.fields.status.value !== 'rejected' &&
                              <div className='col-sm-12 no-side-padding' style={{ minHeight: '75px' }}>
                                Pickup Site Trailer Slots
                                <div className='col-sm-12 no-side-padding'>
                                  {
                                    map(['trailer1', 'trailer2', 'trailer3'], (trailer, index) => {
                                      const fieldSettings = get(pickupSiteSMSettings, 'fields')
                                      return (
                                        <div className='col-sm-12 no-left-padding' style={{display: 'flex', alignItems: 'center', marginTop: '10px'}} key={trailer}>
                                          <div className='col-md-11 no-right-padding'>
                                          {find(fieldSettings, {id: `trailer_${index+1}`})?.show &&
                                            <CommonSelect
                                              id={trailer}
                                              floatingLabelText={find(fieldSettings, {id: `trailer_${index+1}`})?.label}
                                              onChange={(value) => this.handleTrailerSlotChange(value, trailer, 'pickupSiteTrailers')}
                                              items={isOrderDeliverySite ? this.getCounterSlotOptionForTrailers(trailer, 'pickup') : this.getSlotOptionsForTrailer(trailer, 'outload')}
                                              value={this.state.pickupSiteTrailers[trailer].slotId}
                                              disabled={isCompleted || isReadonly}
                                              includeEmptyOption={true}
                                              dontAutoselectSingleItem
                                              errorText= { index == 0 && this.state.pickupSlotTrailerErrors ? this.state.pickupSlotTrailerErrors : null}
                                            />
                                          }
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              }
                            </React.Fragment>
                        }
                      </div>
                      <Divider orientation='vertical' light />
                      <div className='col-sm-6'>
                        Delivery
                        <div style={{ minHeight: '75px', marginBottom: '10px' }}>
                          <CommonTextField
                            id="deliverySite"
                            label="Delivery Site"
                            value={get(this.state, 'deliverySite.displayName')}
                            disabled
                          />
                          <div className='col-sm-12 no-side-padding' style={{ marginTop: '2px', fontSize: '0.8125rem' }}>
                            <div className='col-sm-5 no-left-padding'>
                              Est. Dist.: {this.state.distanceInKm.value}
                            </div>
                            <div className='col-sm-7 no-right-padding'>
                              Est. Time: {this.state.estimatedTime.value}
                            </div>
                          </div>
                        </div>
                        {
                          isMultiSlotBooking ? (
                            isOrderDeliverySite ?
                              <React.Fragment>
                                {
                                  map(multiSlots, _slot => {
									                  const rawSlot = get(_slot, 'raw.slot')
								                    const unmatchedFields = rawSlot ? this.getOrderUnmatchFields(
                                      rawSlot.commodityId, rawSlot.gradeId, rawSlot.season, null, rawSlot.type, rawSlot.siteId, rawSlot.excludedCommodityIds, rawSlot.excludedGradeIds) : []
                                    const slotErrorState = this.getSlotErrorState(unmatchedFields)
								                    const isValid = slotErrorState.valid
                                    const errorColor = (slotErrorState.error || !isDeliveryUserSite) ? 'error' : 'warning'
                                    const errorLabel = this.getErrorLabelFromFields(unmatchedFields, slotErrorSuffix)
                                    return (
                                      <React.Fragment key={_slot.id}>
                                        <div className='col-sm-5 no-left-padding' style={{ minHeight: '75px' }}>
                                          <CommonTextField
                                            id="freightDelivery.date"
                                            label="Delivery Date"
                                            value={moment(_slot.raw.slot.start).format(this.state.countryFormats.date)}
                                            style={{ float: 'right' }}
                                            disabled
                                          />
                                        </div>
                                        <div className='col-sm-6 no-right-padding' style={{ minHeight: '75px' }}>
                                          <CommonTextField
                                            id='deliverySlotId'
                                            value={this.getSlotDisplay(_slot, true)}
                                            label={this.getSlotLabel("deliverySite", "Select")}
                                            disabled
                                          />
                                        </div>
										                    {
                                          rawSlot &&
                                            <div className='col-sm-1 no-side-padding' style={{ minHeight: '75px', display: 'flex', justifyContent: 'right' }}>
                                              <Tooltip title={ isValid ? "This slot matches all the criteria." : errorLabel} arrow placement='right'>
                                                <span style={{display: 'flex', alignItems: 'center'}}>
                                                  {
                                                    isValid ?
                                                      <SuccessIcon color='success' /> :
                                                      <ErrorIcon color={errorColor} className='slot-error-icon' />
                                                  }
                                                </span>
                                              </Tooltip>
                                            </div>
                                        }
                                      </React.Fragment>
                                    )
                                  })
                                }
                              </React.Fragment> :
                            <React.Fragment>
                              {
                                map(multiSlots, (_slot, index) => {
                                  const date = this.state.multiSlotsDates.delivery[index] || moment(_slot.raw.slot.start).startOf('day')
                                  const startDateTime = this.timeStampToDateTime(date)
                                  const insufficientTimeText = this.insufficientTimeText(index)
                                  const candidateSlots = get(this.state.deliverySlotsCache, startDateTime) || []
								                  const rawSlot = find(candidateSlots, {id: get(this.state.multiSlotsBothSiteBooking, `${index}.inload`)})
								                  const unmatchedFields = rawSlot ?
                                        this.getOrderUnmatchFields(
                                          rawSlot.commodityId, rawSlot.gradeId, rawSlot.season, null, rawSlot.type, rawSlot.siteId, rawSlot.excludedCommodityIds, rawSlot.excludedGradeIds
                                        ) :
                                        []
                                  const slotErrorState = this.getSlotErrorState(unmatchedFields)
                                  const errorColor = (slotErrorState.error || !isDeliveryUserSite) ? 'error' : 'warning'
                                  const isValid = slotErrorState.valid
                                  const errorLabel = this.getErrorLabelFromFields(unmatchedFields, slotErrorSuffix)
                                  return (
                                    <React.Fragment key={_slot.id}>
                                      <div className='col-sm-5 no-left-padding' style={{ minHeight: '75px' }}>
                                        <CommonDatePickerUncontrolled
                                          id="freightDelivery.date"
                                          floatingLabelText="Delivery Date"
                                          onChange={(value, id) => this.handleMultiSlotDateChange(value, id, index)}
                                          errorText={this.state.fields.freightDelivery.date.errors[0]}
                                          defaultValue={moment(_slot.raw.slot.start).format('YYYY-MM-DD')}
                                          style={{ float: 'right' }}
                                          minDate={this.deliveryDateMin(_slot)}
                                          maxDate={this.deliveryDateMax()}
                                        />
                                      </div>
                                      <div className='col-sm-6 no-right-padding' style={{ minHeight: '75px' }}>
                                        <CommonSelect
                                          id='deliverySlotId'
                                          onChange={
                                            (value, id, slot) => this.handleMultiSlotSelect(value, id, slot, index)
                                          }
                                          floatingLabelText={this.getSlotLabel("deliverySite", "Select")}
                                          selectConfig={{ text: 'name', value: 'id' }}
                                          items={get(this.state.deliverySlotsCache, startDateTime) || []}
                                          dontAutoselectSingleItem
                                        />
                                        {
                                          insufficientTimeText &&
                                            <div className='col-xs-12 no-side-padding' style={{fontSize: '12px', color: WARNING_ORANGE}}>
                                              {insufficientTimeText}
                                            </div>
                                        }
                                        {
                                          isEmpty(candidateSlots) &&
                                            <div className='col-xs-12 no-side-padding' style={{fontSize: '12px', color: WARNING_ORANGE}}>
                                              {this.props.siteBooking ? NO_SLOTS_AVAILABLE : NO_SLOTS_AVAILABLE_SM}
                                            </div>
                                        }
                                        <div className='col-xs-12 no-side-padding' style={{fontSize: '12px'}}>
                                          {this.getRecommendedDeliverySlotTextWithoutAPI(this.state.multiSlots[index], candidateSlots)}
                                        </div>
                                      </div>
                                      {
                                        rawSlot &&
                                          <div className='col-sm-1 no-side-padding' style={{ minHeight: '75px', display: 'flex', justifyContent: 'right' }}>
                                            <Tooltip title={ isValid ? "This slot matches all the criteria." : errorLabel} arrow placement='right'>
                                              <span style={{display: 'flex', alignItems: 'center'}}>
                                                {
                                                  isValid ?
                                                    <SuccessIcon color='success' /> :
                                                    <ErrorIcon color={errorColor} className='slot-error-icon' />
                                                }
                                              </span>
                                            </Tooltip>
                                          </div>
                                      }
                                    </React.Fragment>
                                  )
                                })
                              }
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className='col-sm-5 no-left-padding' style={{ minHeight: '75px' }}>
                                <CommonDatePicker
                                  id="freightDelivery.date"
                                  floatingLabelText="Delivery Date"
                                  onChange={this.handleDateChange}
                                  errorText={this.state.fields.freightDelivery.date.errors[0]}
                                  value={this.state.fields.freightDelivery.date.value}
                                  style={{ float: 'right' }}
                                  minDate={this.deliveryDateMin()}
                                  maxDate={this.deliveryDateMax()}
                                  disabled={isDisabledDeliverySlot || this.isSlotOrDateFieldDisabled('freightDelivery.date')}
                                />
                              </div>
                              <div className='col-sm-6 no-right-padding' style={{ minHeight: '75px' }}>
                                { ((isBooked && this.state.bothSitesOrderBookingOn) || !isBooked || this.props.isDuplicate) ?
                                  <React.Fragment>
                                    <CommonAutoSelect
                                      id='deliverySlotId'
                                      onChange={this.handleSlotChange}
                                      errorText={isDisabledDeliverySlot ? undefined : (this.state.fields.deliverySlotId.errors[0] || this.state.warningText)}
                                      label={this.getSlotLabel("deliverySite", "Select")}
                                      dataSourceConfig={{ text: 'name' , value: 'id' }}
                                      items={this.state.deliverySlots || []}
                                      value={this.state.fields.deliverySlotId.value}
                                      disabled={isDisabledDeliverySlot || this.isSlotOrDateFieldDisabled('deliverySlotId')}
                                      dontAutoselectSingleItem
                                      notClearable
                                    />
                                    {
                                      this.state.fields.pickupSlotId.value && !this.isSlotOrDateFieldDisabled('deliverySlotId') && !isDisabledDeliverySlot &&
                                        <div className='col-xs-12 no-side-padding' style={{fontSize: '12px'}}>
                                          {this.getRecommendedDeliverySlotTextWithoutAPI(find(this.state.pickupSlots || [], { id: this.state.fields.pickupSlotId.value}), this.state.deliverySlots || [])}
                                        </div>
                                    }
                                  </React.Fragment> :
                                  <CommonTextField
                                    id="deliverySlot"
                                    label={this.getSlotLabel("deliverySite", "Delivery")}
                                    value={get(this.state, 'selectedDeliverySlot')}
                                    disabled
                                  />
                                }
                              </div>
                              {
                                selectedDeliverySlot &&
                                  <div className='col-sm-1 no-side-padding' style={{ minHeight: '75px', display: 'flex', justifyContent: 'right' }}>
                                    <Tooltip title={ isValidDeliverySlot ? "This slot matches all the criteria." : deliverySlotErrorLabel} arrow placement='right'>
                                      <span style={{display: 'flex', alignItems: 'center'}}>
                                        {
                                          isValidDeliverySlot ?
                                            <SuccessIcon color='success' /> :
                                            <ErrorIcon color={deliverySlotErrorColor} className='slot-error-icon' />
                                        }
                                      </span>
                                    </Tooltip>
                                  </div>
                              }
                              {
                              !isTrailerSlot && this.isDeliverySiteTrailerBookingEnabled() && !isRestricted && !isNew && (this.state.fields.status.value !== 'planned' || siteBooking) && !isMultipleSlot && this.state.fields.status.value !== 'rejected' &&
                              <div className='col-sm-12 no-side-padding' style={{ minHeight: '75px' }}>
                                Delivery Site Trailer Slots
                                <div className='col-sm-12 no-side-padding'>
                                  {
                                    map(['trailer1', 'trailer2', 'trailer3'], (trailer, index) => {
                                      const fieldSettings = get(deliverySiteSMSettings, 'fields')
                                      return (
                                        <div className='col-sm-12 no-left-padding' style={{display: 'flex', alignItems: 'center', marginTop: '10px'}} key={trailer}>
                                          <div className='col-md-11 no-right-padding'>
                                          {find(fieldSettings, {id: `trailer_${index+1}`})?.show &&
                                            <CommonSelect
                                              id={trailer}
                                              floatingLabelText={find(fieldSettings, {id: `trailer_${index+1}`})?.label}
                                              onChange={(value) => this.handleTrailerSlotChange(value, trailer, 'deliverySiteTrailers')}
                                              items={isOrderDeliverySite ? this.getSlotOptionsForTrailer(trailer, 'inload') : this.getCounterSlotOptionForTrailers(trailer, 'delivery')}
                                              value={this.state.deliverySiteTrailers[trailer].slotId}
                                              disabled={isCompleted || isReadonly}
                                              includeEmptyOption={true}
                                              dontAutoselectSingleItem
                                              errorText= { index == 0 && this.state.deliverySlotTrailerErrors ? this.state.deliverySlotTrailerErrors : null}
                                            />
                                          }
                                          </div>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                              }
                            </React.Fragment>
                          )
                        }
                      </div>
                    </div>
                }
                {
                  !isTrailerSlot && isTrailerBooking && !this.state.bothSitesOrderBookingOn && !isCounterSiteTrailerBooking && !isRestricted && !isNew && (this.state.fields.status.value !== 'planned' || siteBooking) && !isMultipleSlot && this.state.fields.status.value !== 'rejected' &&
                  <React.Fragment>
                    <div className='col-sm-12 form-wrap-70'>
                      Trailer Slots
                    </div>
                    <div className='col-sm-12 no-side-padding'>
                      {
                        map(['trailer1', 'trailer2', 'trailer3'], (trailer, index) => {
                          const label = index === 0 ? 'Book extra slot for B-Double' : '(internal use only)';
                          return (
                            <div className='col-sm-6 no-left-padding' style={{display: 'flex', alignItems: 'center'}} key={trailer}>
                              <div className='col-md-11 no-right-padding'>
                                {find(this.props.settings?.fields, {id: `trailer_${index+1}`})?.show &&
                                <CommonSelect
                                  id={trailer}
                                  floatingLabelText={find(this.props.settings?.fields, {id: `trailer_${index+1}`})?.label || label}
                                  onChange={(value) => this.handleTrailerSlotChange(value, trailer, isOrderDeliverySite ? 'deliverySiteTrailers' : 'pickupSiteTrailers')}
                                  items={this.getSlotOptionsForTrailer(trailer)}
                                  value={isOrderDeliverySite ? this.state.deliverySiteTrailers[trailer]?.slotId : this.state.pickupSiteTrailers[trailer]?.slotId}
                                  disabled={isCompleted || isReadonly}
                                  includeEmptyOption={true}
                                  dontAutoselectSingleItem
                                />
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                    </React.Fragment>
                }
                {
                  !isRestricted && this.state.bothSitesOrderBookingOn &&
                    <Divider light style={{ width: '120%', marginLeft: '-100px', marginBottom: '20px' }} />
                }
                <div className='col-sm-12 no-side-padding' style={{ marginBottom: '20px' }}>
                  <div className='col-sm-6'>
                    {this.shouldShowField('commodityId') && (
                      <div style={{ minHeight: '75px' }}>
                        <CommodityAutoComplete
                          id='commodityId'
                          setRef={this.fieldRef['commodityId']}
                          floatingLabelText={this.getFieldInfo('commodityId', 'label')}
                          commodityId={this.state.fields.commodityId.value}
                          onChange={this.handleCommodityChange}
                          errorText={this.state.fields.commodityId.errors[0]}
                          style={{ marginTop: '1px' }}
                          disabled={this.isFieldDisabled('commodityId') || isTrailerSlot}
                          noVarieties={true}
                        />
                      </div>
                    )}
                    {this.shouldShowField('commodityId') && (isPlanned || isNew) && !siteBooking &&
                     <div style={{ minHeight: commodityFieldHeight, marginBottom: commodityFieldMarginBottom }}>
                       <CommodityMultiSelect
                         onChange={this.handleMultiCommodityChange}
                         commodities={this.props.commodities}
                         selectedCommodities={this.state.fields.excludedCommodityIds.value}
                         label="Excluded Commodities"
                         variant='standard'
                         limitTags={3}
                         clearAll
                         selectAll
                       />
                     </div>
                    }
                    {this.shouldShowField('gradeId') && (
                      this.state.isBlended ?
                      <div style={{ minHeight: '75px' }}>
                        <CommonTextField
                          id="gradeId"
                          label={this.getFieldInfo('gradeId', 'label')}
                          value={this.state.blendedOrderGrade}
                          disabled
                        />
                      </div> :
                      <div style={{ minHeight: '75px' }}>
                        <GradeAutoComplete
                          id='gradeId'
                          setRef={this.fieldRef['gradeId']}
                          floatingLabelText={this.getFieldInfo('gradeId', 'label')}
                          commodityId={this.state.fields.commodityId.value}
                          onChange={this.handleGradeChange}
                          errorText={this.state.fields.gradeId.errors[0]}
                          disabled={!this.state.fields.commodityId.value || this.isFieldDisabled('gradeId') || isTrailerSlot}
                          dependsOnCommodity
                          dependsOnSeason
                          gradeId={this.state.fields.gradeId.value}
                          selectedGradeId={this.state.fields.gradeId.value}
                          nameSuffix={this.props.slot?.event?.raw?.slot?.varietyName}
                        />
                      </div>
                    )}
                    {
                      this.shouldShowField('gradeId') && (isPlanned || isNew) && !siteBooking &&
                        <div style={{ minHeight: gradeFieldHeight, marginBottom: gradeFieldMarginBottom }}>
                          <GradeMultiSelect
                            onChange={this.handleMultiGradeChange}
                            options={exclusionGrades}
                            selected={this.state.fields.excludedGradeIds.value}
                            label="Excluded Grades"
                            variant='standard'
                            limitTags={3}
                            clearAll
                            selectAll
                          />
                        </div>
                    }
                    {this.shouldShowField('tonnage') && (
                      <div style={{ minHeight: '75px' }}>
                        <CommonTextField
                          id='tonnage'
                          setRef={this.fieldRef['tonnage']}
                          label={this.getFieldInfo('tonnage', 'label') + this.getTonnageSubLabel()}
                          value={this.state.fields.tonnage.value}
                          onKeyDown={event => positiveDecimalFilter(event, 2, 999999.99)}
                          helperText={this.state.fields.tonnage.errors[0]}
                          onChange={this.handleTonnageChange}
                          disabled={this.isFieldDisabled('tonnage') || isTrailerSlot}
                          inputProps={{ autoComplete: 'off' }}
                        />
                      </div>
                    )}
                    {this.shouldShowField('pits') && (
                      <div style={{ minHeight: '75px' }}>
                        <CommonSelect
                          id='pits'
                          setRef={this.fieldRef['pits']}
                          onChange={this.handleSelectFieldChange}
                          floatingLabelText={this.getFieldInfo('pits', 'label')}
                          errorText={this.state.fields.pits.errors[0]}
                          items={
                            filter(
                              this.PITS,
                              pit => {
                                return includes(get(this.props.settings, 'pits', []), pit.id);
                              }
                            )
                          }
                          value={this.state.fields.pits.value}
                          selectConfig={{ text: 'name', value: 'id' }}
                          disabled={this.isFieldDisabled('pits')}
                          includeEmptyOption={true}
                          emptyOptionText='Select...'
                        />
                      </div>
                    )}
                  </div>
                  <div className='col-sm-6'>
                    {this.shouldShowField('season') && (
                      <div style={{ minHeight: '75px' }}>
                        <SeasonSelect
                          id='season'
                          setRef={this.fieldRef['season']}
                          floatingLabelText={this.getFieldInfo('season', 'label')}
                          onChange={this.handleSeasonChange}
                          season={this.state.fields.season.value}
                          style={{ float: 'right' }}
                          errorText={get(this.state, 'fields.season.errors[0]', '')}
                          disabled={this.isFieldDisabled('season')}
                        />
                      </div>
                    )}
                    {this.shouldShowField('customer') && (
                      <div style={{ minHeight: '75px' }}>
                        <CommonAutoSuggest
                          suggestions={this.props.companyNames}
                          id='customer'
                          label={this.getFieldInfo('customer', 'label')}
                          value={this.state.fields.customer.value || ""}
                          onChange={this.handleCustomerChange}
                          disabled={this.isFieldDisabled('customer') || isTrailerSlot}
                          mainContainerStyle={{ marginTop: '3px' }}
                          errorText={this.state.fields.customer.errors[0]}
                        />
                      </div>
                    )}
                    {this.shouldShowOrderNumberField() && !this.shouldShowOrderIdField() && (
                      <div style={{ minHeight: '75px' }}>
                        <CommonAutoSuggest
                          id='orderId'
                          suggestions={this.orderItems()}
                          label={this.getFieldInfo('deliveryOrderNumber', 'label')}
                          value={this.state.selectedOrderIdentifier}
                          errorText={this.state.fields.orderId.errors[0]}
                          onChange={this.handleOrderSelect}
                          onBlur={() => this.handleOrderBlur()}
                          dontAutoselectSingleItem
                          disabled={this.isFieldDisabled('orderId')}
                          mainContainerStyle={{ marginTop: '3px' }}
                          onSelect={this.onOrderSelect}
                          inputProps={{ autoComplete: 'off' }}
                        />
                      </div>
                    )}
                    {this.shouldShowOrderIdField() && this.state.isFetchingOrders && (
                      <div style={{ minHeight: '75px' }}>
                        <LoaderInline imageStyle={{ width: '17%' }} containerClassName='inline-loader-container' />
                      </div>
                    )}
                    {!isMultipleSlot && this.shouldShowField('bookingNumber') && (
                      <div style={{ minHeight: '75px' }}>
                        <CommonTextField
                          id='bookingNumber'
                          setRef={this.fieldRef['bookingNumber']}
                          label={this.getFieldInfo('bookingNumber', 'label')}
                          value={this.state.fields.bookingNumber.value}
                          helperText={this.state.fields.bookingNumber.errors[0]}
                          onChange={this.handleTextFieldChange}
                          disabled={this.isFieldDisabled('bookingNumber') || isTrailerSlot}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {
                  !isRestricted &&
                    <Divider light style={{ width: '120%', marginLeft: '-100px', marginBottom: '20px' }} />
                }

                {
                  this.state.isBlended &&
                  <>
                    <div className='col-sm-12 no-side-padding' style={{ marginBottom: '20px' }}>
                    {!isEmpty(this.state.chemicalApplications) &&
                      map(this.state.chemicalApplications, (obj, index) => (
                        <div key={index}>
                          <div className="col-md-12" style={{padding: "10px 0px 10px 0px"}}>
                            <div className="col-md-4">
                            <CommodityAutoComplete
                              id='commodity'
                              commodityId={obj.commodityId}
                              selected={obj.commodity}
                              onChange={(value) => this.onChemicalApplicationCommodityChange(obj, value)}
                              label='Application Commodity'
                              itemFilterFunc={commodities => filter(commodities, {type: 'chemical'})}
                              errorText={obj.commodityId ? '' : obj.errors}
                              disabled={isCompleted}
                            />
                            </div>
                            <div className={isCompleted ? "col-md-4" : "col-md-3"}>
                              <CommonTextField
                                label={`Application Rate ${this.applicationRateUnitDisplay(obj.commodity)}`}
                                value={obj.applicationRate}
                                onChange={(event) => this.handleApplicationRateChange(obj, event.target.value)}
                                onKeyDown={(event) => positiveDecimalFilter(event, 2, 999999999.99)}
                                helperText={obj.applicationRate ? '' : obj.errors}
                                disabled={isCompleted}
                              />
                            </div>
                            <div className="col-md-4">
                              <GradeAutoComplete
                                floatingLabelText="Apply On"
                                commodityId={this.state.fields.commodityId.value}
                                gradeId={obj.gradeId}
                                selectedGradeId={obj.gradeId}
                                itemFilterFunc={grades => filter(grades, grade => includes(obj.gradeIds, grade.id))}
                                dependsOnCommodity
                                onChange={item => this.onChemicalApplicationGradeChange(obj, item)}
                                errorText={obj.gradeId ? '' : obj.errors}
                                disabled={isCompleted}
                              />
                            </div>
                            {!isCompleted &&
                              <div className='col-md-1'>
                                <IconButton onClick={() => this.onChemicalApplicationDelete(obj)} color='error'>
                                  <RemoveIcon fontSize='inherit' />
                                </IconButton>
                              </div>
                            }
                          </div>
                        </div>
                      ))
                    }
                    {!isCompleted &&
                    <div className='col-xs-12 padding-reset' style={{marginTop: '30px'}}>
                      <AddButton label='Application' onClick={() => this.onChemicalApplicationAdd()} style={{float: 'left'}} />
                    </div>
                    }
                    </div>
                    <Divider light style={{ width: '120%', marginLeft: '-100px', marginBottom: '20px' }} />
                  </>
                }

                <div className='col-sm-12 no-side-padding' style={{ marginBottom: '30px' }}>
                  <div className={shouldShowInlineCommentBox ? 'col-sm-6' : ''}>
                    {!siteBooking && !this.isSubFreightProvider() && this.shouldShowField('freightProviderId') && !(this.state.searchOption === SEARCH_BY_ALL_REGOS &&
                     this.state.selectedTruck && !this.state.fields.freightProviderId.value) && (
                      <div className={shouldShowInlineCommentBox ? 'form-wrap-70' : 'col-sm-12 form-wrap-70'}>
                        <CommonAutoSelect
                          items={companiesWithTrucks}
                          id='freightProviderId'
                          setRef={this.fieldRef['freightProviderId']}
                          label={this.getFieldInfo('freightProviderId', 'label')}
                          value={this.state.fields.freightProviderId.value}
                          errorText={this.state.fields.freightProviderId.errors[0]}
                          onChange={this.handleFreightProviderChange}
                          dontAutoselectSingleItem={true}
                          disabled={isReadonly || this.isFPFieldDisabled()}
                          top={true}
                        />
                      </div>
                    )}
                    {
                      (this.state.fields.status.value !== 'planned' || siteBooking) &&
                      <div style={{ display: 'flex', alignItems: 'flex-start', marginTop: '20px' }} className='col-sm-12  form-wrap-70'>
                        <TruckSearchOption
                          id='searchOption'
                          label={`Search ${regoLabel} In`}
                          handleSearchOptionChange={this.handleSearchOptionChange}
                          value={this.state.searchOption}
                          disabled={false}
                          tooltipPosition='top'
                          freightProviderLabel={this.getFieldInfo('freightProviderId', 'label')}
                          truckOwnerLabel={this.getFieldInfo('subFreightProviderId', 'label')}
                          allRegoLabel={`All ${regoLabel}`}
                        />
                      </div>
                    }
                    {((siteBooking && ((this.shouldShowField('subFreightProviderId') && this.isSubFreightProvider()) || get(this.state.selectedOrder, 'providerId'))) || (this.state.searchOption === SEARCH_BY_ALL_REGOS && this.state.selectedTruck && !this.state.fields.freightProviderId.value) || (siteBooking && !get(this.state.selectedOrder, 'providerId'))) && (
                      <div className='col-sm-12 form-wrap-70' style={{ marginBottom: '20px' }}>
                        <CommonTextField
                          id='freightProviderId'
                          setRef={this.fieldRef['freightProviderId']}
                          label={this.getFieldInfo('freightProviderId', 'label')}
                          value={this.getLockedFreightProviderName()}
                          disabled
                        />
                      </div>
                    )}
                    {(!showLockedSubFreightProvider && this.state.applySubFreightProvider && this.state.searchOption === SEARCH_BY_TRUCK_OWNER_REGOS) && (
                      <div className={shouldShowInlineCommentBox ? 'form-wrap-70' : 'col-sm-12 form-wrap-70'} style={{ marginTop: '10px', marginBottom: '8px' }}>
                        <CommonAutoSelect
                          items={subFreightProviderCompanies}
                          id='subFreightProviderId'
                          setRef={this.fieldRef['subFreightProviderId']}
                          label={this.getFieldInfo('subFreightProviderId', 'label')}
                          value={this.state.fields.subFreightProviderId.value}
                          errorText={this.state.fields.subFreightProviderId.errors[0]}
                          onChange={this.handleSubFreightProviderChange}
                          dontAutoselectSingleItem={true}
                          dataSourceConfig={{ text: 'name', value: 'id' }}
                          disabled={isReadonly}
                        />
                      </div>
                    )}
                    {this.shouldShowField('truckId') && (
                      <div
                        className={shouldShowInlineCommentBox ? 'form-wrap-70' : 'col-sm-12 form-wrap-70'}
                        style={{ marginTop: siteBooking ? '0' : '18px' }}
                      >
                        {
                          this.state.searchOption === SEARCH_BY_ALL_REGOS ?
                          <CommonSelectInput
                            search
                            allowEmptyOptions
                            allowText={false}
                            endpoint="trucks/search/"
                            queryParams={{is_active: true}}
                            options={[]}
                            optionMap={{ id: 'id', name: 'rego' }}
                            id='truckId'
                            inputText={get(this.state.selectedTruck, 'rego')}
                            setRef={this.fieldRef["truckId"]}
                            label={this.getFieldInfo('truckId', 'label')}
                            value={this.state.fields.truckId.value}
                            disabled={isReadonly || isCancelled}
                            actionCreator={addTruck}
                            create={this.props.createTruck}
                            validate={this.props.validateRego}
                            errorText={this.state.fields.truckId.errors[0]}
                            onChange={this.handleTruckChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.toString().slice(0, 10).replace(/[^0-9a-z]/gi, '');
                            }}
                          /> :
                          <CommonAutoSelect
                            items={orderBy(trucks, 'rego')}
                            id='truckId'
                            setRef={this.fieldRef['truckId']}
                            label={this.getFieldInfo('truckId', 'label')}
                            value={this.state.fields.truckId.value}
                            errorText={this.state.fields.truckId.errors[0]}
                            onChange={this.handleTruckChange}
                            dataSourceConfig={{ text: 'rego', value: 'id' }}
                            dontAutoselectSingleItem={true}
                            disabled={isReadonly || isCancelled || isTrailerSlot}
                            top={true}
                            filterOptions={(items, params) => {
                              const filtered = autocompleteFilters(items, params);
                              let subFreightProvider = find(subFreightProviderCompanies, { id: this.state.fields.subFreightProviderId.value });
                              let providerTransactionParticipation = get(this.state.selectedOrder, 'providerTransactionParticipation');
                              if((this.state.searchOption == SEARCH_BY_FREIGHT_PROVIDER_REGOS && !providerTransactionParticipation) || (this.state.searchOption == SEARCH_BY_TRUCK_OWNER_REGOS && subFreightProvider && !get(subFreightProvider, 'transactionParticipation'))) {
                                if (params.inputValue !== '' && params.inputValue.length >= 4 && params.inputValue.length <= 10 && params.inputValue.toLowerCase() !== 'fleet') {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    name: `Add "${params.inputValue}" as new ${getCountryLabel('rego')}`,
                                  });
                                }
                                return filtered;
                              }
                              return filtered;
                            }
                            }
                          />
                        }
                      </div>
                    )}
                    {(this.state.selectedTruck && (showLockedSubFreightProvider || (this.state.applySubFreightProvider && this.state.searchOption === SEARCH_BY_ALL_REGOS))) && (
                       <div className={shouldShowInlineCommentBox ? 'form-wrap-70' : 'col-sm-12 form-wrap-70'} style={{ marginTop: '20px'}}>
                         <CommonTextField
                           id='subFreightProviderId'
                           setRef={this.fieldRef['subFreightProviderId']}
                           label={this.getFieldInfo('subFreightProviderId', 'label')}
                           value={get(this.props.slot, 'event.raw.slot.subFreightProvider.name') || get(this.state.selectedTruck, 'companyName')}
                           disabled
                         />
                       </div>
                     )}
                    {this.shouldShowField('driverId') && (
                      <div className={shouldShowInlineCommentBox ? 'form-wrap-70' : 'col-sm-12 form-wrap-70'} style={{ marginTop: '18px' }}>
                        <CommonAutoSelect
                          items={allDrivers}
                          id='driverId'
                          setRef={this.fieldRef['driverId']}
                          label={this.getFieldInfo('driverId', 'label')}
                          value={this.state.fields.driverId.value}
                          errorText={this.state.fields.driverId.errors[0]}
                          onChange={this.handleDriverChange}
                          dataSourceConfig={{ text: 'nameWithMobile', value: 'id' }}
                          dontAutoselectSingleItem={true}
                          disabled={isReadonly || isCancelled || !this.state.fields.truckId.value || isTrailerSlot}
                          top={true}
                        />
                      </div>
                    )}
                  </div>
                  {shouldShowInlineCommentBox && (
                    <div className={isRestricted ? 'col-sm-12 no-side-padding' : 'col-sm-6'}>
                      <TextField
                        id='comment'
                        inputRef={this.fieldRef['comment']}
                        placeholder={this.getInlineCommentBoxLabel()}
                        multiline
                        rows='7'
                        value={this.state.comment}
                        onChange={this.handleCommentChange}
                        margin='normal'
                        variant='outlined'
                        inputProps={{ autoComplete: 'off' }}
                        fullWidth
                        helperText={this.state.commentError}
                        error={Boolean(this.state.commentError)}
                        disabled={isReadonly}
                      />
                    </div>
                  )}
                </div>
              </div>
              {
                shouldShowTruckConfig &&
                  <div className='col-sm-12 form-wrap-70'>
                    <Divider light style={{ width: '120%', marginLeft: '-100px', marginBottom: '20px' }} />
                    <div className='col-sm-12 form-wrap-70'>
                      <div className="col-sm-6" style={{marginBottom: '15px'}}>
                        <CommonAutoSelect
                          id="code"
                          label='Code'
                          placeholder="Select code..."
                          items={map(TRUCK_CODES, code => ({id: code, name: code}))}
                          value={this.state.truckDetails.code || ''}
                          errorText={this.state.massLimitCode.errors[0]}
                          onChange={value => this.handleTruckAttrFieldChange('code', value)}
                          dataSourceConfig={{id: 'id', text: 'name'}}
                          disabled={isReadonly || isCancelled || isTrailerSlot}
                        />
                      </div>
                      <div className="col-sm-6" style={{marginBottom: '15px'}}>
                        <CommonAutoSelect
                          id="massLimitPermit"
                          label='Mass Limit Permit'
                          placeholder="Select Mass Limit Permit..."
                          items={map(this.state.permits, permit => ({id: permit, name: permit}))}
                          value={this.state.fields.massLimitPermit.value || ''}
                          errorText={this.state.fields.massLimitPermit.errors[0]}
                          onChange={this.handleSelectFieldChange}
                          dataSourceConfig={{id: 'id', text: 'name'}}
                          disabled={isReadonly || isCancelled || isTrailerSlot}
                        />
                      </div>
                    </div>
                    <div className='col-sm-12 form-wrap-70'>
                      <div className="col-sm-3 form-wrap">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id='steerPoint5'
                              color='primary'
                              checked={Boolean(this.state.truckDetails.steerPoint5)}
                              onChange={this.handleSteerChange}
                              disabled={!this.shouldEnableSteerPoint5() || isReadonly || isCancelled || isTrailerSlot}
                            />
                          }
                          label='0.5 T Steer Axle Allowance'
                        />
                      </div>
                      <div className="col-sm-3 form-wrap">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id='steer1Point1'
                              color='primary'
                              checked={Boolean(this.state.truckDetails.steer1Point1)}
                              onChange={this.handleSteerChange}
                              disabled={!this.shouldEnableSteer1Point1() || isReadonly || isCancelled || isTrailerSlot}
                            />
                          }
                          label='1.1 T Allowance'
                        />
                      </div>
                      <div className="col-sm-2 form-wrap">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id='ghms'
                              color='primary'
                              checked={Boolean(this.state.fields.ghms.value)}
                              onChange={this.handleCheckboxChange}
                              disabled={isReadonly || isCancelled || isTrailerSlot || isNoticePermit || (this.state.fields.massLimitPermit.value || '').includes('PBS - ')}
                            />
                          }
                          label='GHMS'
                        />
                      </div>
                    </div>
                    {showAdditionalMassLimitCodeFields &&
                    <div>
                      <div className='col-sm-12 form-wrap-70'>
                        <div className="col-sm-3 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='permitChecked'
                                  color='primary'
                                  checked={Boolean(this.state.permitChecked)}
                                  onChange={this.handlePermitClick}
                                  disabled={isNoticePermit}
                                />
                              }
                              label='I have a Permit'
                            />
                        </div>
                        <div className="col-sm-5 form-wrap">
                          <FormControlLabel
                            control={
                              <Checkbox
                                id='accreditationNumberChecked'
                                color='primary'
                                checked={Boolean(this.state.accreditationNumberChecked)}
                                onChange={this.handleAccreditationNumberClick}
                                disabled={(this.state.fields.massLimitPermit.value || '').includes('PBS - ')}
                              />
                            }
                            label='I have an accreditation number'
                          />
                        </div>
                        <div className="col-sm-3 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='loadSharing'
                                  color='primary'
                                  checked={Boolean(this.state.loadSharing)}
                                  onChange={this.handleLoadSharingClick}
                                  disabled={disableLoadSharing}
                                />
                              }
                              label='LS Present'
                            />
                        </div>
                      </div>
                      <div className='col-sm-12 form-wrap-70'>
                        <div className="col-sm-3 form-wrap">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id='restrictedChecked'
                                  color='primary'
                                  checked={Boolean(this.state.restrictedChecked)}
                                  onChange={this.handleRestrictedClick}
                                  disabled={isNoticePermit}
                                />
                              }
                              label='Restricted'
                            />
                        </div>
                      </div>
                      {!this.state.loadSharing && get(this.state.truckDetails, 'categoryId') &&
                        <span style={{color: "red", fontSize: "14px", marginLeft: "30px"}}>
                          {`1 ${get(this.state.selectedTruck, 'unit') || this.state.unit} will be deducted from mass limit on account of no LS in truck.`}
                        </span>
                      }
                      {this.state.permitChecked &&
                      <div className="col-sm-12 form-wrap" style={{marginTop: '5px'}}>
                        <div className='col-sm-6 form-wrap-70'>
                          <CommonTextField
                            id='truckDetails.permitNumber'
                            label="Permit Number"
                            value={this.state.truckDetails.permitNumber}
                            onChange={this.handleTruckDetailsChange}
                            helperText={this.state.truckDetailsErrors.permitNumber}
                          />
                        </div>
                        {!this.state.restrictedChecked &&
                          <div className='col-sm-6 form-wrap-70'>
                            <CommonTextField
                              id='truckDetails.declaredMassLimit'
                              label="Declared Mass Limit"
                              value={this.state.truckDetails.declaredMassLimit}
                              onChange={this.handleTruckDetailsChange}
                              onKeyDown={event => positiveDecimalFilter(event, 2, 999999.99)}
                              helperText={this.state.truckDetailsErrors.declaredMassLimit}
                            />
                          </div>
                        }
                      </div>
                      }
                      {this.state.accreditationNumberChecked &&
                      <div className="col-sm-12 form-wrap" style={{marginTop: '20px'}}>
                        <div className='col-sm-6 form-wrap-70'>
                          <CommonTextField
                            id='truckDetails.accreditationNumber'
                            label="Accreditation Number"
                            value={this.state.truckDetails.accreditationNumber}
                            onChange={this.handleTruckDetailsChange}
                            helperText={this.state.truckDetailsErrors.accreditationNumber}
                          />
                        </div>
                      </div>
                      }
                      {this.state.restrictedChecked &&
                      <div className="col-sm-12 form-wrap" style={{marginTop: '20px'}}>
                        <div className='col-sm-6 form-wrap-70'>
                          <CommonAutoSelect
                            id="truckDetails.restricted"
                            label='Restricted Code'
                            placeholder="Select Restricted Code..."
                            items={this.RESTRICTED_CODES}
                            value={this.state.truckDetails.restricted}
                            onChange={this.handleTruckDetailsSelectValueChange}
                            dataSourceConfig={{id: 'id', text: 'name'}}
                            errorText={this.state.truckDetailsErrors.restricted}
                          />
                        </div>
                        <div className='col-sm-6 form-wrap-70'>
                          <CommonTextField
                            id='truckDetails.declaredMassLimit'
                            label="Declared Mass Limit"
                            value={this.state.truckDetails.declaredMassLimit}
                            onChange={this.handleTruckDetailsChange}
                            onKeyDown={event => positiveDecimalFilter(event, 2, 999999.99)}
                            helperText={this.state.truckDetailsErrors.declaredMassLimit}
                          />
                        </div>
                      </div>
                      }
                    </div>
                    }
                    {this.state.fields.massLimitPermit.value === 'Notice' &&
                      <div className="col-sm-12 form-wrap" style={{marginTop: '20px'}}>
                        <div className='col-sm-6 form-wrap-70'>
                          <CommonAutoSelect
                            id="truckDetails.noticeNumber"
                            label='Notice Number'
                            placeholder="Select Notice Number..."
                            items={NOTICE_NUMBERS}
                            value={this.state.truckDetails.noticeNumber}
                            onChange={this.handleTruckDetailsSelectValueChange}
                            dataSourceConfig={{id: 'id', text: 'name'}}
                            errorText={this.state.truckDetailsErrors.noticeNumber}
                          />
                        </div>
                      </div>
                      }
                  </div>
              }
            </form>
          </div>
          {this.state.update && (
            <div style={{ display: 'inline-block', position: 'fixed' }}>
              {
                !isRestricted &&
                  <div>
                    <Tooltip title='Comments'>
                      <IconButton
                        aria-label='Comment'
                        className={this.state.showComments ? 'slot-comments-selected' : 'slot-comments-unselected'}
                        style={{ border: '3px solid', padding: '10px' }}
                        onClick={this.handleCommentsIconsClick}
                        size="large">
                        <Badge badgeContent={filter(this.props.comments, {archived: false}).length} color='primary'>
                          <CommentIcon fontSize='medium' />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </div>
              }
              <div style={{marginTop: '5px'}}>
                <Tooltip title='Audit History'>
                  <IconButton
                    aria-label='Audit History'
                    className={this.state.showHistory ? 'slot-comments-selected' : 'slot-comments-unselected'}
                    style={{ border: '3px solid', padding: '10px' }}
                    onClick={this.handleHistoryIconClick}
                    size="large">
                    <HistoryIcon fontSize='medium' />
                  </IconButton>
                </Tooltip>
              </div>
              {
                shouldShowPickupInfo &&
                  <div style={{marginTop: '5px'}}>
                    <IconButton
                      aria-label='Truck Status'
                      className={this.state.showPickupInfo ? 'slot-comments-selected' : 'slot-comments-unselected'}
                      style={{ border: '3px solid', padding: '10px' }}
                      onClick={this.handlePickupInfoIconClick}
                      size="large">
                      <MovementIcon fill={this.state.showPickupInfo ? '#fff' : '#000'} title='Truck Status'/>
                    </IconButton>
                  </div>
              }
              {
                this.state.shouldShowVendorDec &&
                  <div style={{marginTop: '5px'}}>
                    <IconButton
                      aria-label='Vendor Dec'
                      className={this.state.showVendorDecInfo ? 'slot-comments-selected' : 'slot-comments-unselected'}
                      style={{ border: '3px solid', padding: '10px' }}
                      onClick={this.handleVendorDecInfoIconClick}
                      size="large">
                      <VendorDecsIcon fill={this.state.showVendorDecInfo ? '#fff' : '#000'} title='Vendor Declaration'/>
                    </IconButton>
                  </div>
              }
            </div>
          )}
        </DialogContent>
        <DialogActions>
          {(isBooked || isBookedAndDelayed) && get(this.props.slot, 'event.id') && (
            <Button
              disabled={isReadonly}
              type='button'
              onClick={this.handleCancelBooking}
              variant='outlined'>
              Cancel Booking
            </Button>
          )}
          {canDelete && (
            <Button disabled={isReadonly} onClick={this.handleDelete} color='secondary' type='button' variant='contained'>
              Delete
            </Button>
          )}
          {
            showMarkBookedButton && (
              <Button disabled={isReadonly} onClick={this.handleMarkBooked} color='primary' type='button' variant='contained'>
                Mark Booked
              </Button>
            )
          }
          {showSaveButton && (
            <Button disabled={isReadonly || this.state.isCheckingOrder} onClick={this.handleSubmit} color='primary' type='button' variant='contained'>
              {(this.isBookingOpenSlot() || isMultiSlotBooking) ? 'Book' : 'Save'}
            </Button>
          )}
        </DialogActions>
        {this.state.showDelete && (
          <DeleteSlot
            siteId={get(this.props.slot, 'event.raw.slot.siteId')}
            slotId={get(this.props.slot, 'event.raw.slot.id')}
            show={this.state.showDelete}
            handleClose={this.closeDeleteDialog}
            onDelete={this.onDelete}
            onDeleteFailure={this.onDeleteFailure}
          />
        )}
        <SlotComments
          siteBooking={this.props.siteBooking}
          onClose={this.handleCommentsIconsClick}
          show={this.state.showComments}
          slotId={get(this.props.slot, 'event.raw.slot.id')}
          siteId={get(this.props.slot, 'event.raw.slot.siteId')}
        />
        {
          this.state.showHistory &&
            <SlotHistory
              onClose={this.handleHistoryIconClick}
              show={this.state.showHistory}
              timezone={this.props.selectedTimezone}
              slotId={get(this.props.slot, 'event.raw.slot.id')}
              siteId={get(this.props.slot, 'event.raw.slot.siteId')}
            />
        }
        {
          this.state.showPickupInfo &&
            <PickupInfo
              onClose={this.handlePickupInfoIconClick}
              show={this.state.showPickupInfo}
              slotType={slotType}
              movementId={get(this.props.slot, 'event.raw.slot.movementId')}
              slotStatus={get(this.props.slot, 'event.raw.slot.status')}
              pickupUtcOffsetMinutes={this.state.pickupUtcOffsetMinutes}
              deliveryUtcOffsetMinutes={this.state.deliveryUtcOffsetMinutes}
              pickupTZAbbreviation={this.state.pickupTZAbbreviation}
              deliveryTZAbbreviation={this.state.deliveryTZAbbreviation}
            />
        }
        {
          this.state.showVendorDecInfo &&
            <SlotVendorDec
              parentLink={isSystemCompany() || !siteBooking || isReadonly}
              onClose={this.handleVendorDecInfoIconClick}
              show={this.state.showVendorDecInfo}
              vendorDecs={get(this.state, 'vendorDecs')}
            />
        }
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    companiesWithTrucks: state.companies.companies.items,
    trucks: state.companies.companies.company.trucks.items,
    drivers: state.companySettings.employees.items,
    companyNames: state.companies.companies.names,
    currentUser: state.main.user.user,
    currentUserCompanyId: state.main.user.user.companyId,
    comments: state.companies.companySites.comments,
    token: state.main.user.token,
    commodities: state.master.commodities.items,
    isRegoAvailable: state.main.isRegoAvailable,
    createdTruck: state.companies.companies.company.trucks.createdTruck
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCompanyTrucks: (providerId, receiveTrucks, appendToUrl) => dispatch(getCompanyTrucks(providerId, receiveTrucks, appendToUrl)),
    getCompanyEmployeesMinimal: (providerId, receiveEmployees) => dispatch(getCompanyEmployeesMinimal(providerId, receiveEmployees)),
    isLoading: (component) => dispatch(isLoading(component)),
    forceStopLoader: () => dispatch(forceStopLoader()),
    createSlots: (siteId, data, callback) => dispatch(createSlots(siteId, data, callback)),
    updateSlot: (slotId, data, callback, newSlotId, newCounterSlotId, startDate, endDate, siteCompanyId) => dispatch(updateSlot(slotId, data, callback, newSlotId, newCounterSlotId, startDate, endDate, siteCompanyId)),
    deleteSlot: (siteId, slotId, callback) => dispatch(deleteSlot(siteId, slotId, callback)),
    getSlotComments: (siteId, slotId) => dispatch(getSlotComments(siteId, slotId)),
    bulkUpdateSlot: (siteId, data, callback) => dispatch(bulkUpdateSlot(siteId, data, callback)),
    create: (data, callback) => dispatch(create(data, callback)),
    receiveFreightSlotUpdated: (item) => dispatch(receiveFreightSlotUpdated(item)),
    validateRego: (key, value, callback) => dispatch(validateRego(key, value, callback)),
    createTruck: (companyId, data, addTruck) => dispatch(createTruck(companyId, data, addTruck)),
    emptyCreatedTruck: () => dispatch(emptyCreatedTruck()),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(FreightSlotForm);
