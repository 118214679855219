import React from 'react';
import moment from 'moment';

import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { forceStopLoader } from '../../../actions/main';
import {
  get, has, compact, times, isEmpty, reduce, find, includes, isNumber, isArray, isString, capitalize, isEqual, startCase, flatten, forEach, filter, uniqBy,
  upperFirst
} from 'lodash';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import ZoomIn from '@mui/icons-material/ZoomIn';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import RotateRight from '@mui/icons-material/RotateRight';
import IconButton from '@mui/material/IconButton';
import MTooltip from '@mui/material/Tooltip';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Grid } from '@mui/material'
import {
  EMPTY_VALUE,
  FREIGHT_CONTRACT_TYPE,
  PACK_ORDER_TYPE_ID,
  LOAD_CREATE_OR_EDIT_ERROR_MESSAGE
} from '../../../common/constants';
import SideDrawer from '../../common/SideDrawer';
import RightSideDrawer from '../../common/RightSideDrawer';
import UpdateOutload from '../../../containers/UpdateOutload';
import UpdateInload from '../../../containers/UpdateInload';
import {
  getGradeName,
  getDateTimeFromUTC,
  isSystemStorageOrBhc,
  isSystemCompany,
  currentUserCompany,
  toDateFormat,
  getCountryLabel,
  getCountrySystemCompanyId,
  getCountryMovementDisplayUnit,
  validateMovementUnitForLoadEdit,
  getCountryFormats,
  isSuperuser,
} from '../../../common/utils';
import Tooltip from "../../../common/Tooltip";
import PDFViewer from '../../common/PDFViewer';
import InloadOutloadConstDrawer from "./InloadOutloadConstDrawer";
import { INLOAD, OUTLOAD, SPLIT_INLOAD, SPLIT_OUTLOAD } from "./Constants";
import { canLoadBeVoided } from '../../../actions/companies/freights';
import { RejectionReasonDialog } from '../../rejections/RejectionReasonDialog';
import APIService from '../../../services/APIService';
import { isLoading } from '../../../actions/main';
import { AppContext } from '../../main/LayoutContext';
import LoadSMSTable from './LoadSMSTable'

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
              style={{ fill: '#112c42', height: '20px', width: '20px' }}
    />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} />);
};

class MovementDeliveredDetails extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      pickupTruckCompany: null,
      deliveryTruckCompany: null,
      isOutloadConstSideDrawerOpened: false,
      isInloadConstSideDrawerOpened: false,
      isOutloadSplitSideDrawerOpened: false,
      isInloadSplitSideDrawerOpened: false,
      preview: '',
      allCompanies: [],
      isVoidDialogOpen: false,
      requestReason: '',
      loadsSelectorValue: 'all',
      outloadMessagesFetched: false,
      inloadMessagesFetched: false,
      outloadMessages: [],
      inloadMessages: [],
      selectedLoadForMessages: false,
      pickupLoads: [],
      deliveryLoads: [],
      pickupIndex: 0
    };

    this.openOutloadConstSideDrawer = this.openOutloadConstSideDrawer.bind(this);
    this.openInloadConstSideDrawer = this.openInloadConstSideDrawer.bind(this);
    this.closeOutloadConstSideDrawer = this.closeOutloadConstSideDrawer.bind(this);
    this.closeInloadConstSideDrawer = this.closeInloadConstSideDrawer.bind(this);
    this.openOutloadSplitSideDrawer = this.openOutloadSplitSideDrawer.bind(this);
    this.openInloadSplitSideDrawer = this.openInloadSplitSideDrawer.bind(this);
    this.closeOutloadSplitSideDrawer = this.closeOutloadSplitSideDrawer.bind(this);
    this.closeInloadSplitSideDrawer = this.closeInloadSplitSideDrawer.bind(this);
    this.getEstablishmentForFetchingNgr = this.getEstablishmentForFetchingNgr.bind(this);
    this.openDocketImageSideDrawer = this.openDocketImageSideDrawer.bind(this);
    this.closeDocketImageSideDrawer = this.closeDocketImageSideDrawer.bind(this);
    this.openInloadSideDrawer = this.openInloadSideDrawer.bind(this);
    this.openOutloadSideDrawer = this.openOutloadSideDrawer.bind(this);
    this.rotateImage = this.rotateImage.bind(this);
    this.isLoadVoidable = this.isLoadVoidable.bind(this);
    this.voidLoad = this.voidLoad.bind(this);
    this.voidSelectedLoad = this.voidSelectedLoad.bind(this);
    this.closeVoidDialog = this.closeVoidDialog.bind(this);
  }

  getCompany(companyId, field) {
    const systemCompanyId = getCountrySystemCompanyId()
    if (companyId && companyId != systemCompanyId)
      APIService.companies(companyId).get().then(item => this.setState({[field]: item}))
    else
      this.setState({[field]: {id: systemCompanyId, name: 'Unknown'}})
  }

  async setUpLoad() {
    if(get(this.props.movement, 'freightPickup.load'))
      this.getCompany(get(this.props.movement, 'freightPickup.load.0.truck.companyId'), 'pickupTruckCompany')
    if(get(this.props.movement, 'freightDelivery.load'))
      this.getCompany(get(this.props.movement, 'freightDelivery.load.0.truck.companyId'), 'deliveryTruckCompany')
    let pickupLoads = get(this.props.movement, 'freightPickup.load');
    let deliveryLoads = get(this.props.movement, 'freightDelivery.load');
    const pickupPromises = [];
    const deliveryPromises = [];
    const newState = {...this.state};
    if (!isEmpty(pickupLoads)) {
      pickupLoads = pickupLoads.map((load, index) => {
        return {
          load: this.getLoadWithLocalDateTime(load),
          openSideDrawer: false,
          id: index + 1
        }
      });
      if (get(this.props.movement, 'isBlended')) {
        forEach(pickupLoads, pickupLoad => {
          const promise = APIService.loads(pickupLoad.load.id).appendToUrl('chemical-applications/').get();
          pickupPromises.push(promise);
          promise.then(response => {
            pickupLoad.chemicalApplications = response;
          })
        });
      }
      newState.pickupLoads = pickupLoads;
    } else {
      newState.pickupLoads = []
    }
    if (!isEmpty(deliveryLoads)) {
      deliveryLoads = deliveryLoads.map((load, index) => {
        return {
          load: this.getLoadWithLocalDateTime(load),
          openSideDrawer: false,
          id: index + 1
        }
      });
      if (get(this.props.movement, 'isBlended')) {
        forEach(deliveryLoads, deliveryLoad => {
          const promise = APIService.loads(deliveryLoad.load.id).appendToUrl('chemical-applications/').get();
          deliveryPromises.push(promise);
          promise.then(response => {
            deliveryLoad.chemicalApplications = response;
          })
        });
      }
      newState.deliveryLoads = deliveryLoads;
    } else {
      newState.deliveryLoads = []
    }
    await Promise.all(pickupPromises);
    await Promise.all(deliveryPromises);
    this.setState(newState);
  }

  componentDidMount() {
    this.setUpLoad()
  }

  componentDidUpdate(prevProps) {
    if(this.props.movement && !this.state.outloadMessagesFetched && !this.state.inloadMessagesFetched)
      this.getMessages()
    if((prevProps.movement?.id !== this.props.movement?.id) || (prevProps?.movement?.requestedUnit !== this.props.movement?.requestedUnit && this.props.movement?.requestedUnit))
      this.setUpLoad()
    if(!prevProps.resolveDocket && this.props.resolveDocket)
      this.props.clarificationLoad === INLOAD ? this.openInloadConstSideDrawer() : this.openOutloadConstSideDrawer();
  }


  getMessages = () => {
    const { movement } = this.props
    if(!this.state.outloadMessagesFetched) {
      this.setState({outloadMessagesFetched: true}, () => {
        forEach(movement?.freightPickup?.load, load => {
          this.fetchMessage(load, response => this.setState({outloadMessages: [...this.state.outloadMessages, ...response]}))
        })
      })
    }
    if(!this.state.inloadMessagesFetched) {
      this.setState({inloadMessagesFetched: true}, () => {
        forEach(movement?.freightDelivery?.load, load => {
          this.fetchMessage(load, response => this.setState({inloadMessages: [...this.state.inloadMessages, ...response]}))
        })
      })
    }
  }

  fetchMessage = (load, callback) => {
    if(load.id)
      APIService.loads(load?.id).appendToUrl('mobile/messages/?type=docket').get().then(callback)
  }

  getLoadMessages = load => load?.id ? uniqBy(filter([...this.state.outloadMessages, ...this.state.inloadMessages], {loadId: load.id}), 'id') : []

  rotateImage() {
    const el = document.getElementById('docket-image-rendered');
    const transform = el.style.transform;
    let deg = parseInt(transform.match(/\d+/));
    deg += 90;
    el.style.transform = "rotate(" + deg + "deg)";
  }

  openOutloadSideDrawer() {
    this.setState({
      isOutloadConstSideDrawerOpened: true,
      isInloadConstSideDrawerOpened: false,
      isOutloadSplitSideDrawerOpened: false,
      isInloadSplitSideDrawerOpened: false,
    });
  }

  openOutloadConstSideDrawer() {
    if (!this.state.isOutloadConstSideDrawerOpened) {
      if (this.props.movement?.outloadSlotId && this.isLoadCreateOrEditRestricted(get(this.props.movement, 'freightPickup.consignor.handler'))) {
        alertifyjs.alert(
          'Permission Denied',
          LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created'),
          () => {}
        );
      } else {
      if (!get(this.props, 'movement.providerId') || get(this.props, 'movement.providerId') === 1) {
        alertifyjs.confirm(
          'Warning',
          'Freight Movement is missing Freight Provider details. It is advisable to edit the freight movement and add freight provider. Do you want to proceed to add/edit Outload details?',
          this.openOutloadSideDrawer,
          () => { },
        );
      } else this.openOutloadSideDrawer();
    }
    }
  }

  openDocketImageSideDrawer(url) {
    this.setState({
      isOutloadSplitSideDrawerOpened: false,
      isInloadSplitSideDrawerOpened: false,
      isDocketImageSideDrawerOpened: true,
      preview: url,
    });
  }

  voidLoad(loadId, reason, loadsSelectorValue) {
    const payload = {
      reason: reason,
      loadsSelectorValue: loadsSelectorValue,
      feature: "Movement Details",
    }
    this.props.dispatch(isLoading('alertify'))
    APIService
      .loads(loadId)
      .appendToUrl('void/')
      .put(payload).then(response => {
        if(response?.errors) {
          this.props.dispatch(forceStopLoader())
          alertifyjs.error(flatten(response.errors)[0], 10)
        } else {
          alertifyjs.success('Load Deleted', 2, () => {
            window.location.reload();
          });
        }
        this.props.closeDrawer;
      });
  }

  voidSelectedLoad = (loadId, reason, loadsSelectorValue) => {
    this.voidLoad(loadId, reason, loadsSelectorValue);
    this.closeVoidDialog();
  };

  getMovementData() {
    const seller = get(this.props.movement, 'commodityContract.seller');
    const buyer = get(this.props.movement, 'commodityContract.buyer');
    const customer = get(this.props.movement, 'customer');
    const isCustomerRegistered = get(this.props.movement, 'isCustomerRegistered');
    const freightProvider = get(this.props.movement, 'provider');
    return { seller, buyer, customer, isCustomerRegistered, freightProvider };
  }

  openInloadSideDrawer() {
    const result = validateMovementUnitForLoadEdit(this.props.movement)
    if(!result)
      return

    this.setState({
      isOutloadConstSideDrawerOpened: false,
      isInloadConstSideDrawerOpened: true,
      isOutloadSplitSideDrawerOpened: false,
      isInloadSplitSideDrawerOpened: false,
    });
  }

  isLoadCreateOrEditRestricted = handler => handler?.stocksManagement && !isSystemCompany() && handler?.companyId !== currentUserCompany().id

  openInloadConstSideDrawer() {
    const result = validateMovementUnitForLoadEdit(this.props.movement)
    if(!result)
      return
    if (!this.state.isInloadConstSideDrawerOpened) {
      if (this.props.movement?.inloadSlotId && this.isLoadCreateOrEditRestricted(get(this.props.movement, 'freightDelivery.consignee.handler'))) {
        alertifyjs.alert(
          'Permission Denied',
          LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created'),
          () => {}
        );
      } else {
      if (!get(this.props, 'movement.providerId') || get(this.props, 'movement.providerId') === 1) {
        alertifyjs.confirm(
          'Warning',
          'Freight Movement is missing Freight Provider details. It is advisable to edit the freight movement and add freight provider. Do you want to proceed to add/edit Inload details?',
          this.openInloadSideDrawer,
          () => { },
        );
      } else this.openInloadSideDrawer();
      }
    }
  }

  openOutloadSplitSideDrawer() {
    const result = validateMovementUnitForLoadEdit(this.props.movement)
    if(!result)
      return
    if (!this.state.isOutloadSplitSideDrawerOpened) {
      this.setState({
        isOutloadConstSideDrawerOpened: false,
        isInloadConstSideDrawerOpened: false,
        isOutloadSplitSideDrawerOpened: true,
        isInloadSplitSideDrawerOpened: false,
      });
    }
  }

  openInloadSplitSideDrawer() {
    const result = validateMovementUnitForLoadEdit(this.props.movement)
    if(!result)
      return
    if (!this.state.isInloadSplitSideDrawerOpened) {
      this.setState({
        isOutloadConstSideDrawerOpened: false,
        isInloadConstSideDrawerOpened: false,
        isOutloadSplitSideDrawerOpened: false,
        isInloadSplitSideDrawerOpened: true,
      });
    }
  }

  closeOutloadConstSideDrawer() {
    this.setState({
      isOutloadConstSideDrawerOpened: false,
    });
    this.props.unmarkResolveDocket();
  }

  closeInloadConstSideDrawer() {
    this.setState({
      isInloadConstSideDrawerOpened: false,
    });
    this.props.unmarkResolveDocket();
  }

  closeOutloadSplitSideDrawer() {
    this.setState({
      isOutloadSplitSideDrawerOpened: false,
    });
  }

  closeInloadSplitSideDrawer() {
    this.setState({
      isInloadSplitSideDrawerOpened: false,
    });
  }

  closeDocketImageSideDrawer() {
    this.setState({
      isDocketImageSideDrawerOpened: false,
    });
  }

  toggleExpanded = () => this.setState((prevState) => ({ ...prevState, expanded: !prevState.expanded }));

  setRequestReason = (value) => {
    this.setState({
      requestReason: value
    });
  };

  onChangeLoadSelection = (value) => {
    this.setState({
      loadsSelectorValue: value
    });
  }

  closeVoidDialog(){
    this.setState({ isVoidDialogOpen: false, requestReason: '', loadsSelectorValue: 'all' });
  };

  isLoadVoidable(movementId, loadId, loadType){
    this.props.dispatch(
      canLoadBeVoided(movementId, loadId, loadType, () => {
        this.setState({ isVoidDialogOpen: true })})
    );
  }

  variableKeys = (checkpointConst, loadConst) => {
    const handlerLabel = has(checkpointConst, 'consignor') ? 'Pickup Site' : 'Delivery Site';
    const storageLabel = get(loadConst, 'farmFieldId') ? 'Field' : 'Storage';
    const handlerName = get(loadConst, 'handler.displayName') ||
                        get(checkpointConst, `${handlerLabel.toLowerCase()}.handler.displayName`) ||
                        get(this.props.movement, `${handlerLabel.toLowerCase()}.handler.displayName`);
    const showAllTimeFields = Boolean(loadConst.completedAtTime);
    let variableKeys = {};
    if (loadConst && get(loadConst, 'identifier'))
      variableKeys = {
        'Identifier': get(loadConst, 'identifier')
      }
    variableKeys = {
      ...variableKeys,
      'Date': loadConst ? toDateFormat(loadConst.date) : EMPTY_VALUE,
      'Time': loadConst ? moment(loadConst.time, ["HH:mm"]).format("hh:mm A") : EMPTY_VALUE,
      'Start Time': loadConst && showAllTimeFields ? moment(loadConst.time, ["HH:mm"]).format("hh:mm A") : EMPTY_VALUE,
      'End Time': get(loadConst, 'completedAtTime') && showAllTimeFields ? moment(loadConst.completedAtTime, ["HH:mm"]).format('hh:mm A') : EMPTY_VALUE,
      'Total Time': get(loadConst, 'totalLoadTime') && showAllTimeFields ? get(loadConst, 'totalLoadTime') : EMPTY_VALUE,
      'Site Entry Time': get(loadConst, 'truckEntryTime') && showAllTimeFields ? moment(loadConst.truckEntryTime, ["HH:mm"]).format('hh:mm A') : EMPTY_VALUE,
      'Site Exit Time': get(loadConst, 'truckExitTime') && showAllTimeFields ? moment(loadConst.truckExitTime, ["HH:mm"]).format('hh:mm A') : EMPTY_VALUE,
      'Site': handlerName,
      [storageLabel]: get(loadConst, 'siteName'),
      'Order No': get(loadConst, 'orderNumber', EMPTY_VALUE),
    };
    return variableKeys
  };

  reducedSpecList = (dataList) => reduce(dataList, function (hash, value) {
    var key = value['key'];
    var showValue = isNumber(value['value']) ? true : isEmpty(value['value']) ? false: true;
    hash[key] = showValue ? parseFloat(value['value']).toFixed(1) + ' ' + value['header'] : EMPTY_VALUE;
    return hash;
  }, {});

  specList = (movement, loadConst) => {
    const specs = get(movement, 'commodity.specs', []);
    return times(specs.length, index => {
      const spec = find(specs, { order: index + 1 });
      const code = get(spec, 'code');
      const name = get(spec, 'name');
      const unit = isString(name) ? name.substring(name.indexOf('(') + 1, name.indexOf(')')) : undefined;
      const key = isString(code) ? `specs.${code.toLowerCase().replace('-', '')}` : '';
      return {
        key: code,
        header: `${unit}`,
        value: get(loadConst, key)
      };
    });
  };

  imageTemplate = (imageList, type, preview = true) => {
    imageList = compact(isArray(imageList) ? imageList : [imageList]);
    return (
      imageList.map(imageUrl => {
        const isPDF = includes(imageUrl.toLowerCase(), '.pdf');
        return (
          <span key={imageUrl} className="field-value" style={{marginBottom: '5px', width: '100%'}}>
            <a style={{ marginRight: '10px' }} href={imageUrl}>
              {
                isPDF ?
                <MTooltip title="Download PDF" placement="top">
                  <a href={imageUrl} target='_blank' rel="noopener noreferrer">
                    <PictureAsPdf />
                  </a>
                </MTooltip> :
                <MTooltip title="Download Image" placement="top">
                  <a href={imageUrl} target='_blank'rel="noopener noreferrer">
                    <img className="thumb1" style={{maxHeight: '100px', objectFit: 'cover'}} src={imageUrl} />
                  </a>
                </MTooltip>
              }
            </a>
            {
              preview &&
              <MTooltip title="Preview" placement="top">
                <a onClick={() => this.openDocketImageSideDrawer(imageUrl)}>
                  <ZoomIn />
                </a>
              </MTooltip>
            }
          </span>
        );
      })
    );
  };


  openSideDrawerForLoad = (loadType, loadConst) => {
    const result = validateMovementUnitForLoadEdit(this.props.movement)
    if(!result)
      return
    if(this.isLoadCreateOrEditRestricted(get(loadConst, 'load.handler'))) {
      alertifyjs.alert(
        'Permission Denied',
        LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'edited'),
        () => {}
      );
      return
    }
    if (loadType === 'outload') {
      this.setState(prevState => ({
        pickupLoads: prevState.pickupLoads.map(pickupLoad => {
          if (pickupLoad?.id == loadConst?.id)
            return {...pickupLoad, openSideDrawer: true};
          return {...pickupLoad}
        })
      }));
    }
    else {
      this.setState(prevState => ({
        deliveryLoads: prevState.deliveryLoads.map(deliveryLoad => {
          if (deliveryLoad?.id == loadConst?.id)
            return {...deliveryLoad, openSideDrawer: true};
          return {...deliveryLoad}
        })
      }))
    }
  }

  closeSideDrawerForLoad = (loadType, loadConst) => {
    if (loadType === 'outload') {
      this.setState(prevState => ({
        pickupLoads: prevState.pickupLoads.map(pickupLoad => {
          if (pickupLoad.id == loadConst.id)
            return {...pickupLoad, openSideDrawer: false};
          return {...pickupLoad}
        })
      }));
    }
    else {
      this.setState(prevState => ({
        deliveryLoads: prevState.deliveryLoads.map(deliveryLoad => {
          if (deliveryLoad.id == loadConst.id)
            return {...deliveryLoad, openSideDrawer: false};
          return {...deliveryLoad}
        })
      }))
    }
  }

  onMoveToNewMovement = load => {
    alertifyjs.prompt(
      `Move ${upperFirst(load.type)} to new Movement`,
      'Please enter new Movement Identifier',
      '',
      (event, movementIdentifier) => {
        if(movementIdentifier) {
          APIService.loads(load.id).appendToUrl('move/').post({'new_movement': movementIdentifier}).then(response => {
            let reasons = get(response, 'errors', '')
            if(reasons && reasons.length > 0){
              reasons = '<li>' + reasons.join('</li><li>');
              alertifyjs.alert(
                'Permission Denied',
                `<div id="complete-dialog-open" className=""><p>Load cannot be moved because:</p><ul>${reasons}</ul></div>`,
                () => {},
              );
            }
            else {
              if(response?.detail)
                alertifyjs.error(response?.detail)
              else if (response?.movementIdentifier?.toLowerCase() === movementIdentifier.toLowerCase())
                alertifyjs.success('Moved successfully. Reloading...', 2, () => window.location.reload())
              else
                alertifyjs.warning('Reloading...', 2, () => window.location.reload())
            }
          })
        }
      },
      () => {}
    )
  }

  getBrokerageDetailsSectionAsPerGrid = (payload, sectionName, load, loadConst) => {
    const { movement } = this.props;
    const isOutload = (sectionName === 'OutloadConst' || sectionName === 'OutloadSplit');
    const isLoadRejected = get(movement, 'status') === 'load_rejected';
    const userCompany = currentUserCompany();
    const currentUserCompanyId = userCompany && userCompany.id;
    const isOutloadSlotOfCurrentUser = get(movement, 'freightPickup.consignor.handler.companyId') === currentUserCompanyId;
    const isInloadSlotOfCurrentUser = get(movement, 'freightDelivery.consignee.handler.companyId') === currentUserCompanyId;
    const isAddEditLinkAvailable = () => {
      return includes(
        ['confirmed', 'in_progress', 'delivered', 'manual_contract_complete_balanced', 'completed', 'invoiced'],
        get(movement, 'status')
      ) || (isOutload && isLoadRejected && load) || isSystemCompany();
    };
    const { isMobileDevice } = this.context
    const messages = this.getLoadMessages(load)
    const shouldCreateSplitForBlendedMovement = get(movement, 'isBlended');
    const pickupLabel = movement?.isPackMovement ? 'Outload' : 'Pickup'
    const deliveryLabel = movement?.isPackMovement ? 'Packing' : 'Delivery'
    return (
      <div>
        {
          isOutload ?
          (
            !isMobileDevice && movement.outloadSlotId &&
            <span style={{fontWeight: '500', fontSize: '13px'}}>
              <Link to={isOutloadSlotOfCurrentUser ? `/site-management?slotId=${movement.outloadSlotId}&startDate=${get(movement, 'freightPickup.dateTime')}&siteId=${get(movement, 'freightPickup.consignor.handlerId')}&slotType=outload` : `/site-bookings?companyId=${get(movement, 'freightPickup.consignor.handler.companyId')}&slotId=${movement.outloadSlotId}&startDate=${get(movement, 'freightPickup.dateTime')}&siteId=${get(movement, 'freightPickup.consignor.handlerId')}&slotType=outload`} target='_blank'>
                View Outload Slot
              </Link>
            </span>
          ) : (
            !isMobileDevice && movement.inloadSlotId &&
            <span style={{fontWeight: '500', fontSize: '13px'}}>
              <Link to={isInloadSlotOfCurrentUser ? `/site-management?slotId=${movement.inloadSlotId}&endDate=${get(movement, 'freightDelivery.dateTime')}&siteId=${get(movement, 'freightDelivery.consignee.handlerId')}&slotType=inload` : `/site-bookings?companyId=${get(movement, 'freightDelivery.consignee.handler.companyId')}&slotId=${movement.inloadSlotId}&endDate=${get(movement, 'freightDelivery.dateTime')}&siteId=${get(movement, 'freightDelivery.consignee.handlerId')}&slotType=inload`} target='_blank'>
                View Inload Slot
              </Link>
            </span>
          )
        }
        <ul className="field-label-list">
          <li>
            <span className="field-label section-title" style={{color: '#000'}}>
              {isOutload ? `${pickupLabel} ${get(loadConst, 'id') || ''}` : (get(movement, 'status') == 'load_rejected') ? ('Inload (Rejected)') : `${deliveryLabel} ${get(loadConst, 'id') || ''}`}
            </span>
            <span className="fieldValue section-title cursor-pointer">
              {
                isAddEditLinkAvailable() && !isMobileDevice && movement.status !== 'void' &&
                <a onClick={ loadConst ? () => this.openSideDrawerForLoad(isOutload ? 'outload' : 'inload', loadConst) : this[`open${sectionName}SideDrawer`]}>{!load || isEqual(get(load, 'status'), 'void') ? 'Add' : 'Edit'}</a>
              }
            </span>
            {
              !isMobileDevice && load && !isEqual(get(load, 'status'), 'void') ?
                <span className="fieldValue section-title cursor-pointer" style={{ margin: '0 2px' }}>
                | <a onClick={() => this.isLoadVoidable(get(movement, 'id'), get(load, 'id'), get(load, 'type'))}>Void</a>
                </span> :
              null
            }
            {
              !isMobileDevice && ((sectionName === 'OutloadConst') || (sectionName === 'InloadConst') || shouldCreateSplitForBlendedMovement) && load && !isEqual(get(load, 'status'), 'void') ?
                <span className="fieldValue section-title cursor-pointer" style={{ margin: '0 2px' }}>
                | <a onClick={sectionName === 'OutloadConst' ? this.openOutloadSplitSideDrawer : this.openInloadSplitSideDrawer}>Split Load</a>
                </span> :
              null
            }
            {
              !isMobileDevice && !isEmpty(messages) &&
                <span className="fieldValue section-title cursor-pointer" style={{ margin: '0 2px' }}>
                  | <a onClick={() => this.setState({selectedLoadForMessages: load})}>
                      SMS
                    </a>
                </span>
            }
            {
              !isMobileDevice && isSuperuser() && load?.id &&
                <span className="fieldValue section-title cursor-pointer" style={{ margin: '0 2px' }}>
                  | <a onClick={() => this.onMoveToNewMovement(load)}>
                      Move
                    </a>
                </span>
            }
          </li>
          {payload && Object.keys(payload).map( (key, index) => {
             return (
               <li key={index}>
                 <Tooltip
                   className="field-label ellipses"
                   tooltipText={key}
                   textContent={key}
                 />
                 <Tooltip
                   className={key == 'Docket Image' ? "field-value" : "field-value ellipses"}
                   tooltipText={payload[key] || EMPTY_VALUE}
                   textContent={payload[key] || EMPTY_VALUE}
                   valueStyle={key === "Comment" ? {whiteSpace: 'break-spaces'} : {}}
                 />
               </li>
             );
          })}
        </ul>
      </div>
    );
  };

  getLoadWithLocalDateTime(load) {
    let newLoad = null;
    if (load) {
      newLoad = Object.assign({}, load);
      if (load.dateTime) {
        const localDateTime = getDateTimeFromUTC(load.dateTime);
        newLoad.date = localDateTime.date;
        newLoad.time = localDateTime.time;
      }
      if (load.completedAt) {
        const localDateTime = getDateTimeFromUTC(load.completedAt);
        newLoad.completedAtTime = localDateTime.time;
      }
      if (load.truckEntryTime) {
        const localDateTime = getDateTimeFromUTC(load.truckEntryTime);
        newLoad.truckEntryTime = localDateTime.time;
      }
      if (load.truckExitTime) {
        const localDateTime = getDateTimeFromUTC(load.truckExitTime);
        newLoad.truckExitTime = localDateTime.time;
      }
    }
    return newLoad;
  }

  getEstablishmentForFetchingNgr(fetchFor, freightConst, loadConst) {
    let establishmentIdKey = null;
    let establishmentTypeKey = 'company';
    let isSystemStorage = false;
    const movementType = get(this.props, 'movement.typeId');
    if (fetchFor === 'freightPickup') {
      establishmentIdKey = 'movement.freightPickup.consignor.handler.companyId';
      establishmentTypeKey = 'company';
      isSystemStorage = isSystemStorageOrBhc(get(freightConst, 'consignor'), loadConst);
      if (isSystemStorage) {
        if (movementType === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
          if (get(this.props, 'movement.orderActualCustomer')) {
            establishmentIdKey = 'movement.orderActualCustomer.company.id';
            establishmentTypeKey = 'company';
          } else {
            establishmentIdKey = 'movement.customer.companyId';
            establishmentTypeKey = 'company';
          }
        } else {
          establishmentIdKey = 'movement.commodityContract.seller.companyId';
          establishmentTypeKey = 'company';
        }
      }
    } else if (fetchFor === 'freightDelivery') {
      establishmentIdKey = 'movement.freightDelivery.consignee.handler.companyId';
      establishmentTypeKey = 'company';
      if (get(this.props, 'movement.freightDelivery.consignee.handler.entity') === 'company') {
        establishmentIdKey = 'movement.freightDelivery.consignee.handler.id';
      }
      isSystemStorage = isSystemStorageOrBhc(get(freightConst, 'consignee'), loadConst);
      if (isSystemStorage) {
        if (movementType === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
          if (get(this.props, 'movement.orderActualCustomer')) {
            establishmentIdKey = 'movement.orderActualCustomer.company.id';
            establishmentTypeKey = 'company';
          } else {
            establishmentIdKey = 'movement.customer.companyId';
            establishmentTypeKey = 'company';
          }
        } else {
          establishmentIdKey = 'movement.commodityContract.buyer.companyId';
          establishmentTypeKey = 'company';
        }
      }
    }

    if ((!freightConst || !loadConst) && !get(this.props, 'movement.commodityContract') && get(this.props.movement, 'customer.companyId')) {
      establishmentIdKey = 'movement.customer.companyId';
      establishmentTypeKey = 'company';
    }


    if (establishmentIdKey && establishmentTypeKey) {
      return {
        establishmentId: get(this.props, establishmentIdKey),
        establishmentType: establishmentTypeKey,
      };
    }
  }

  getEntityIdsToFetchNgrs = () => {
    const { movement } = this.props;
    return {
      companyIds: compact([
        get(movement, 'customer.companyId'),
        get(movement, 'commodityContract.seller.companyId'),
        get(movement, 'commodityContract.buyer.companyId'),
        get(movement, 'orderActualCustomer.companyId'),
        get(movement, 'customer.companyId'),
        get(movement, 'customer.companyId'),
      ]),
      farmIds: compact([
        get(movement, 'freightPickup.consignor.handlerId'),
        get(movement, 'freightDelivery.consignee.handlerId'),
      ])
    };
  };

  hasQuantityBasedCommodity(movement) {
    return Boolean(movement?.commodity?.isQuantityBased)
  }

  quantityLabel(movement) {
    return get(movement, 'quantityLabel')
  }

  quantityUnit(movement) {
    if(this.quantityLabel(movement))
      return get(movement, 'commodity.unit')
  }

  isPackMovement(movement) {
    return get(movement, 'typeId') === PACK_ORDER_TYPE_ID;
  }

  priceUnit() {
    return get(this.props, 'movement.commodity.priceUnit')
  }

  render() {
    const { movement, voidableLoad } = this.props;
    const { expanded, isVoidDialogOpen, requestReason, loadsSelectorValue } = this.state;
    const { canViewPickup, canViewDelivery } = movement
    const pickupConst = movement.freightPickup;
    const deliveryConst = movement.freightDelivery;
    const packMovement = this.isPackMovement(movement);
    const isContainerMovement = Boolean(!packMovement && get(movement, 'containerNumber'));
    const unit = get(movement, 'requestedUnit') || get(movement, 'commodity.tonnageUnit') || get(movement, 'commodity.priceUnit');
    const shouldUseEnteredWeights = unit == getCountryMovementDisplayUnit()
    let inloadConst = null;
    let outloadConst = null;
    let inloadSplit = null;
    let outloadSplit = null;
    if (movement.status === 'void') {
      inloadConst = get(movement.freightDelivery, 'load') ? get(movement.freightDelivery, 'load')[0] : null;
      outloadConst = get(movement.freightPickup, 'load') ? get(movement.freightPickup, 'load')[0] : null;
      inloadSplit = get(movement.freightDelivery, 'load') ? get(movement.freightDelivery, 'load')[1] : null;
      outloadSplit = get(movement.freightPickup, 'load') ? get(movement.freightPickup, 'load')[1] : null;
    } else {
      if (get(movement.freightDelivery, 'load')) {
        if (get(movement.freightDelivery, 'load.0.status') !== 'void') {
          inloadConst = get(movement.freightDelivery, 'load')[0];
          if (get(movement.freightDelivery, 'load.1.status') !== 'void') {
            inloadSplit = get(movement.freightDelivery, 'load')[1];
          }
        } else if (get(movement.freightDelivery, 'load.1.status') !== 'void') {
          inloadConst = get(movement.freightDelivery, 'load')[1];
        }
      }
      if (get(movement.freightPickup, 'load')) {
        if (get(movement.freightPickup, 'load.0.status') !== 'void') {
          outloadConst = get(movement.freightPickup, 'load')[0];
          if (get(movement.freightPickup, 'load.1.status') !== 'void') {
            outloadSplit = get(movement.freightPickup, 'load')[1];
          }
        } else if (get(movement.freightPickup, 'load.1.status') !== 'void') {
          outloadConst = get(movement.freightPickup, 'load')[1];
        }
      }
    }
    const entityIdsForNgrs = this.getEntityIdsToFetchNgrs();
    inloadConst = this.getLoadWithLocalDateTime(inloadConst);
    inloadSplit = this.getLoadWithLocalDateTime(inloadSplit);
    outloadConst = this.getLoadWithLocalDateTime(outloadConst);
    outloadSplit = this.getLoadWithLocalDateTime(outloadSplit);

    const pickupLoads = this.state.pickupLoads;
    const deliveryLoads = this.state.deliveryLoads;
    const deliveredLoadDetails = (freightConst, loadConst, chemicalApplications) => {
      const ngr = get(loadConst, 'ngr');
      var deliveredDetails = {
        ...this.variableKeys(freightConst, loadConst),
        [getCountryLabel('rego')]: get(loadConst.truck, 'rego'),
        'Truck Owner': loadConst.type === 'outload' ? this.state.pickupTruckCompany?.name : this.state.deliveryTruckCompany?.name,
        'Season': get(loadConst, 'season'),
        'NGR': get(ngr, 'ngrNumber'),
      };
      if (this.hasQuantityBasedCommodity(movement) && loadConst.quantity) {
        deliveredDetails[this.quantityLabel(movement)] = loadConst.quantity + " " + this.quantityUnit(movement);
      }
      if (isContainerMovement) {
        let containerTare = get(loadConst, 'splitWeights.containerTare');
        let truckTare = get(loadConst, 'splitWeights.truckTare');
        deliveredDetails['Container Tare'] = containerTare ? parseFloat(containerTare).toFixed(2) + ` ${unit}` : EMPTY_VALUE;
        deliveredDetails['Truck Tare Weight'] = truckTare ? parseFloat(truckTare).toFixed(2) + ` ${unit}` : EMPTY_VALUE;
      }
      else
        deliveredDetails['Truck Tare Weight'] = loadConst.tareWeight ? parseFloat(shouldUseEnteredWeights ? loadConst.tareWeightLb || loadConst.tareWeight : loadConst.tareWeight).toFixed(2) + ` ${unit}` : EMPTY_VALUE;
      deliveredDetails = {
        ...deliveredDetails,
        'Truck Gross Weight': loadConst.grossWeight ? parseFloat(shouldUseEnteredWeights ? loadConst.grossWeightLb || loadConst.grossWeight : loadConst.grossWeight).toFixed(2) + ` ${unit}` : EMPTY_VALUE,
        'Net Weight': loadConst.netWeight ? parseFloat(shouldUseEnteredWeights ? loadConst.netWeightLb || loadConst.netWeight : loadConst.netWeight).toFixed(2) + ` ${unit}` : EMPTY_VALUE,
        'Variety': get(loadConst, 'variety.name'),
        'Grade': getGradeName(loadConst),
      };
      if (!isEmpty(chemicalApplications)) {
        forEach(chemicalApplications, (chemicalApplication, index) => {
          let chemicalApplicationDetails = {
            [`Chemical Commodity ${index + 1}`]: chemicalApplication.commodityDisplay,
            'Applying Rate': chemicalApplication.applicationFeeDisplay,
            'Chemical Storage': chemicalApplication.storageName,
            'Quantity': chemicalApplication.quantityApplied
          }
          deliveredDetails = {...deliveredDetails, ...chemicalApplicationDetails};
        })
      }
      const docketLabel = getCountryLabel('docket')
      deliveredDetails['Booking No'] = get(loadConst,'bookingNumber');
      deliveredDetails[`${docketLabel} No`] = get(loadConst,'docketNumber');
      deliveredDetails[`${docketLabel} Image`] = !isEmpty(get(loadConst,'docketImageUrl')) ? this.imageTemplate(get(loadConst,'docketImageUrl'), loadConst.type): null;
      deliveredDetails['Driver Signature'] = !isEmpty(get(loadConst,'driverSignatureUrl')) ? this.imageTemplate(get(loadConst,'driverSignatureUrl'), loadConst.type, false): null;
      deliveredDetails['Farm Employee Signature'] = !isEmpty(get(loadConst,'farmemployeeSignatureUrl')) ? this.imageTemplate(get(loadConst,'farmemployeeSignatureUrl'), loadConst.type, false): null;
      if(get(movement,'commodity') && get(loadConst, 'specs')){
        deliveredDetails = {
          ...deliveredDetails,
          ...this.reducedSpecList(this.specList(movement, loadConst))
        };
      }
      if(loadConst.comment)
        deliveredDetails['Comment'] = loadConst.comment;
      deliveredDetails['Created By'] = get(loadConst, 'createdBy.name');
      let deviceSource = get(loadConst, 'deviceSource');
      deliveredDetails['Created From'] = get(deviceSource, 'mobile') ? 'Mobile' : get(deviceSource, 'web') ? 'Web': '';
      deliveredDetails['Created From Device'] = get(loadConst, 'createdFromDevice') ? get(loadConst, 'createdFromDevice') : EMPTY_VALUE;
      deliveredDetails['Created At'] = moment(loadConst.createdAt).format(getCountryFormats().datetimeWithSeconds);
      return deliveredDetails;
    };

    const packedDeliveredLoadDetails = (loadConst, type='outload') => {
      var deliveredDetails = null;
      if (type === 'outload') {
        deliveredDetails = {
          'Date': get(loadConst, 'date') ? toDateFormat(loadConst.date) : '',
          'Time': get(loadConst, 'time') ? moment(loadConst.time, ["HH:mm"]).format("hh:mm A"): '',
          'Total Time': get(loadConst, 'totalLoadTime') ? get(loadConst, 'totalLoadTime') : EMPTY_VALUE,
          'Pickup Site': get(loadConst, 'handler.displayName'),
          'Pickup Storage': get(loadConst, 'siteName'),
          'NGR': get(loadConst, 'ngr.ngrNumber'),
          'Variety': get(loadConst, 'variety.name'),
          'Grade': getGradeName(loadConst),
          'Season': get(loadConst, 'season'),
          'Net Weight': loadConst.netWeight ? parseFloat(shouldUseEnteredWeights ? loadConst.netWeightLb || loadConst.netWeight : loadConst.netWeight).toFixed(2) + ` ${unit}` : EMPTY_VALUE,
        }
      }
      else {
        var containerTare = get(loadConst, 'splitWeights.containerTare');
        deliveredDetails = {
          'Date': get(loadConst, 'date') ? toDateFormat(loadConst.date) : '',
          'Time': get(loadConst, 'time') ? moment(loadConst.time, ["HH:mm"]).format("hh:mm A"): '',
          'Total Time': get(loadConst, 'totalLoadTime') ? get(loadConst, 'totalLoadTime') : EMPTY_VALUE,
          'Pack Site': get(loadConst, 'handler.displayName'),
          'Pack Container Name': get(loadConst, 'siteName'),
          'NGR': get(loadConst, 'ngr.ngrNumber'),
          'Variety': get(loadConst, 'variety.name'),
          'Grade': get(loadConst, 'grade.name'),
          'Season': get(loadConst, 'season'),
          'Container Tare': containerTare ? parseFloat(containerTare).toFixed(2) + ` ${unit}` : EMPTY_VALUE,
          'Gross Weight': loadConst.grossWeight ? parseFloat(shouldUseEnteredWeights ? loadConst.grossWeightLb || loadConst.grossWeight : loadConst.grossWeight).toFixed(2) + ` ${unit}` : EMPTY_VALUE,
          'Net Weight': loadConst.estimatedNetWeight ? parseFloat(shouldUseEnteredWeights ? loadConst.netWeightLb || loadConst.estimatedNetWeight : loadConst.estimatedNetWeight).toFixed(2) + ` ${unit}` : EMPTY_VALUE,
        }
      }
      if(get(movement,'commodity') && get(loadConst, 'specs')){
        deliveredDetails = {
          ...deliveredDetails,
          ...this.reducedSpecList(this.specList(movement, loadConst))
        };
      }
      if(loadConst.comment)
        deliveredDetails['Comment'] = loadConst.comment;
      deliveredDetails['Created From Device'] = get(loadConst, 'createdFromDevice') ? get(loadConst, 'createdFromDevice') : EMPTY_VALUE;
      deliveredDetails['Created At'] = moment(loadConst.createdAt).format(getCountryFormats().datetimeWithSeconds);
      return deliveredDetails;
    };

    return includes(['confirmed', 'in_progress', 'delivered', 'completed', 'manual_contract_complete_balanced',
                     'load_rejected', 'void'
    ], get(movement, 'status')) &&
           <Paper className="contract-details-section-container">
             <h2 onClick={this.toggleExpanded}>
               {movement?.isPackMovement ? 'Pack Details' : 'Load Details'}
               <span className="expand-icon">
                 {renderBasedOnExpandedFlag(expanded)}
               </span>
             </h2>
             {
               expanded && !packMovement &&
               <div className="section-details-container-2">
                {canViewPickup && pickupLoads.length > 0 ?
                <Grid container spacing={2}>
                  {pickupLoads.reduce((rows, load, index) => {
                    if (index % 2 === 0) {
                      rows.push([]);
                    }
                    rows[rows.length - 1].push(load);
                    return rows;
                  }, []).map((row, rowIndex) => (
                    <Grid container spacing={2} key={rowIndex}>
                      {row.map((load, columnIndex) => (
                        <Grid style={{paddingTop: '40px'}} item xs={12} sm={6} key={`${rowIndex}-${columnIndex}`}>
                          {this.getBrokerageDetailsSectionAsPerGrid(load ? deliveredLoadDetails(pickupConst, get(load, 'load'), get(load, 'chemicalApplications')) : null, 'OutloadConst', get(load, 'load'), load)}
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </Grid> :
                <>
                  {canViewPickup && this.getBrokerageDetailsSectionAsPerGrid(null, 'OutloadConst', outloadConst)}
                </>
                }
                {canViewDelivery && deliveryLoads.length > 0 ?
                  <Grid spacing={2}>
                    {deliveryLoads.reduce((rows, load, index) => {
                      if (index % 2 === 0) {
                        rows.push([]);
                      }
                      rows[rows.length - 1].push(load);
                      return rows;
                    }, []).map((row, rowIndex) => (
                      <Grid container spacing={2} key={rowIndex} style={{paddingLeft: '20px', marginTop: rowIndex == 0 && "-32px"}}>
                        {row.map((load, columnIndex) => (
                          <Grid style={{paddingTop: '40px'}} item xs={12} sm={6} key={`${rowIndex}-${columnIndex}`}>
                            {this.getBrokerageDetailsSectionAsPerGrid(load ? deliveredLoadDetails(deliveryConst, get(load, 'load'), get(load, 'chemicalApplications')) : null, 'InloadConst', get(load, 'load'), load)}
                          </Grid>
                        ))}
                      </Grid>
                    ))}
                  </Grid> :
                  <>
                    {canViewDelivery && this.getBrokerageDetailsSectionAsPerGrid(null, 'InloadConst', inloadConst)}
                  </>
                }
               </div>
             }
             {
               expanded && packMovement &&
               <div className="section-details-container">
                 {this.getBrokerageDetailsSectionAsPerGrid(outloadConst ? packedDeliveredLoadDetails(outloadConst) : null, 'OutloadConst', outloadConst)}
                 {outloadSplit ? this.getBrokerageDetailsSectionAsPerGrid(packedDeliveredLoadDetails(outloadSplit), 'OutloadSplit', outloadSplit) : null}
                 {this.getBrokerageDetailsSectionAsPerGrid(inloadConst ? packedDeliveredLoadDetails(inloadConst, 'InloadConst') : null, 'InloadConst', inloadConst)}
               </div>
             }
             {
               movement &&
               <InloadOutloadConstDrawer
                 type={OUTLOAD}
                 movement={movement}
                 isOpen={this.state.isOutloadConstSideDrawerOpened}
                 onClose={this.closeOutloadConstSideDrawer}
               />
             }
             {this.state.pickupLoads.map(pickupLoad => {
              const ngrEstablishment = this.getEstablishmentForFetchingNgr('freightPickup', pickupConst, pickupLoad.load);
              return (
                pickupLoad.openSideDrawer ?
                <SideDrawer
                  isOpen={pickupLoad.openSideDrawer}
                  title="Edit Outload"
                  size="big"
                  onClose={() => this.closeSideDrawerForLoad('outload', pickupLoad)}
                  app="load"
                >
                  <UpdateOutload
                    {...entityIdsForNgrs}
                    farmId={get(ngrEstablishment, 'establishmentType') === 'farm' ? get(ngrEstablishment, 'establishmentId') : null}
                    companyId={get(ngrEstablishment, 'establishmentType') === 'company' ? get(ngrEstablishment, 'establishmentId') : null}
                    ngrId={pickupLoad.load.ngrId}
                    outload={pickupLoad.load}
                    movement={movement}
                    closeDrawer={() => this.closeSideDrawerForLoad('outload', pickupLoad)}
                    isCreate={false}
                  />
                </SideDrawer>
                : null
              )
             })
             }
             <SideDrawer
               isOpen={this.state.isOutloadSplitSideDrawerOpened}
               title="Add Outload"
               size="big"
               onClose={this.closeOutloadSplitSideDrawer}
               app="load"
             >
               <InloadOutloadConstDrawer
                 type={SPLIT_OUTLOAD}
                 create
                 movement={movement}
                 isOpen={this.state.isOutloadSplitSideDrawerOpened}
                 onClose={this.closeOutloadSplitSideDrawer}
               />
             </SideDrawer>
             {
               movement &&
               <InloadOutloadConstDrawer
                 type={INLOAD}
                 movement={movement}
                 isOpen={this.state.isInloadConstSideDrawerOpened}
                 onClose={this.closeInloadConstSideDrawer}
               />
             }
             {this.state.deliveryLoads.map(deliveryLoad => {
              const ngrEstablishment = this.getEstablishmentForFetchingNgr('freightDelivery', deliveryConst, deliveryLoad.load);
              return (
                deliveryLoad.openSideDrawer ?
                <SideDrawer
                  isOpen={deliveryLoad.openSideDrawer}
                  title={movement?.isPackMovement ? "Edit Packing Details" : "Edit Inload"}
                  size="big"
                  onClose={() => this.closeSideDrawerForLoad('inload', deliveryLoad)}
                  app="load"
                >
                  <UpdateInload
                    {...entityIdsForNgrs}
                    farmId={get(ngrEstablishment, 'establishmentType') === 'farm' ? get(ngrEstablishment, 'establishmentId') : null}
                    companyId={get(ngrEstablishment, 'establishmentType') === 'company' ? get(ngrEstablishment, 'establishmentId') : null}
                    ngrId={deliveryLoad.load.ngrId}
                    inload={deliveryLoad.load}
                    movement={movement}
                    closeDrawer={() => this.closeSideDrawerForLoad('inload', deliveryLoad)}
                    isCreate={false}
                  />
                </SideDrawer>
                : null
              )
             })
             }
             <SideDrawer
               isOpen={this.state.isInloadSplitSideDrawerOpened}
               title={movement?.isPackMovement ? "Add Packing Details" : "Add Inload"}
               size="big"
               onClose={this.closeInloadSplitSideDrawer}
               app="load"
             >
               <InloadOutloadConstDrawer
                 type={SPLIT_INLOAD}
                 create
                 movement={movement}
                 isOpen={this.state.isInloadSplitSideDrawerOpened}
                 onClose={this.closeInloadSplitSideDrawer}
               />
             </SideDrawer>
             <RightSideDrawer
               isOpen={this.state.isDocketImageSideDrawerOpened}
               title={getCountryLabel('docket')}
               size="big"
               onClose={this.closeDocketImageSideDrawer}
             >
               <div style={{ whiteSpace: 'nowrap', overflowX: 'scroll', overflowY: 'scroll' }}>
                 <div className="col-sm-12">
                   {
                     !includes(this.state.preview.toLowerCase(), '.pdf') &&
                     <IconButton color="primary" onClick={this.rotateImage} size="large">
                       <RotateRight />
                     </IconButton>
                   }
                 </div>
                 <div className="col-sm-12">
                   {
                     includes(this.state.preview.toLowerCase(), '.pdf') ?
                     <PDFViewer file={this.state.preview}  /> :
                     <img id="docket-image-rendered" style={{ marginTop: '30px', transform: 'rotate(0deg)', marginLeft: '200px' }} src={this.state.preview} />
                   }
                 </div>
               </div>
             </RightSideDrawer>
             { voidableLoad && isVoidDialogOpen &&
              (<RejectionReasonDialog
                open={isVoidDialogOpen}
                onClose={() => this.closeVoidDialog()}
                title={`Void ${capitalize(voidableLoad.loadType)}`}
                value={requestReason}
                onChange={(event) => this.setRequestReason(event.target.value)}
                onChangeLoadSelection={(event) => this.onChangeLoadSelection(event.target.value)}
                loadsSelectorValue={loadsSelectorValue}
                onCancel={() => this.closeVoidDialog()}
                onReject={() => this.voidSelectedLoad(voidableLoad.loadId, this.state.requestReason, this.state.loadsSelectorValue)}
                placeholder='Enter you reason for void request'
                submitText='Submit'
                showLoadSelector={voidableLoad.loadType === 'inload' ? (inloadConst && inloadSplit) : (outloadConst && outloadSplit)}
                loadSelectorLabel={voidableLoad.loadType === 'inload' ? ['Void All Inloads', 'Void Selected Inload'] : ['Void All Outloads', 'Void Selected Outload']}
                required
              />)
             }
             {
               this.state.selectedLoadForMessages?.id &&
                 <Dialog open maxWidth='xl' fullWidth onClose={() => this.setState({selectedLoadForMessages: false})}>
                   <DialogTitle>
                     {startCase(this.state.selectedLoadForMessages.type)} SMS
                   </DialogTitle>
                   <DialogContent>
                     <LoadSMSTable messages={this.getLoadMessages(this.state.selectedLoadForMessages)}/>
                   </DialogContent>
                   <DialogActions>
                     <Button onClick={() => this.setState({selectedLoadForMessages: false})}>
                       Ok
                     </Button>
               </DialogActions>
                   </Dialog>
             }
           </Paper>;
  }
}

const mapStateToProps = state => ({
  user: state.main.user.user,
  token: state.main.user.token,
  voidableLoad: state.companies.freights.voidableLoad,
});

export default connect(mapStateToProps)(MovementDeliveredDetails);
