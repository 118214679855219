import React from 'react';

import { connect } from 'react-redux';
import StoragesTable from '../../containers/StoragesTable';
import CreateStorage from '../../containers/CreateStorage';
import UpdateStorage from '../../containers/UpdateStorage';
import FarmTransferAsset from '../../containers/FarmTransferAsset';
import { getStorages } from '../../actions/api/storages';
import {
  receiveStorages,
  clickAddStorageButton,
  cancelEditStorage
} from '../../actions/companies/storages';
import AddButton from '../common/AddButton';
import { setBreadcrumbs } from '../../actions/main';
import { isEqual, get, reject, cloneDeep, find } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import {
  getCompanyIdFromCurrentRoute, isSystemCompany, farmStocksPath, isCompanyEditable, isTransactionParticipated, isCurrentUserBelongsToCompany
} from '../../common/utils';
import { getCompanyDetails } from '../../actions/companies';
import { GROWER } from '../../common/constants';
import { farmSideDrawerForTransfer } from '../../actions/companies/farms';
import { showSideDrawerForMerge } from '../../actions/companies/index';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';

class ActiveStorages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawer: false,
      openMergeSideDrawer: false,
      mergeToStorage: {
        value: undefined,
        errors: [],
        validators: []
      }

    };
    this.onCloseSideDraw = this.onCloseSideDraw.bind(this);
    this.handleAddStorageButtonClick = this.handleAddStorageButtonClick.bind(this);
    this.closeEditSideDrawer = this.closeEditSideDrawer.bind(this);
    this.handleStorageChange = this.handleStorageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeMergeDrawer = this.closeMergeDrawer.bind(this);
  }

  componentDidMount() {
    this.getCompanyIfNeeded();
    this.setBreadcrumbs();
    this.props.getStorages(this.props.farmId);
    this.props.cancelMergeDrawer;
  }

  componentDidUpdate() {
    this.setBreadcrumbs();
  }

  getCompanyIfNeeded() {
    if (getCompanyIdFromCurrentRoute() && (!this.props.selectedCompany || this.props.selectedCompany.id !== parseInt(getCompanyIdFromCurrentRoute()))) {
      const companyId = getCompanyIdFromCurrentRoute();
      companyId && this.props.getCompany(companyId);
    }
  }

  entityLabel(plural) {
    let label = 'Site';
    if (this.isGrowerSite())
      label = 'Farm';

    if (plural)
      label += 's';

    return label;
  }

  setBreadcrumbs() {
    const countLabel = ` (${this.props.count})`;
    if (this.props.farmId && this.props.farmName) {
      const companyId = getCompanyIdFromCurrentRoute();
      const farmName = this.props.farmName;
      const farmId = this.props.farmId;
      let breadcrumbs = [];
      const entityLabelPlural = this.entityLabel(true);
      let mainEntityRoute = this.isGrowerSite() ?
        '/farms' :
        `/companies/${get(this.props.selectedFarm, 'companyId') || companyId}/company-sites?sites`;
      if (companyId && this.props.selectedCompany) {
        const parentRoute = '/companies/' + this.props.selectedCompany.id;
        const subRoute = this.isGrowerSite() ? '/farms' : '/company-sites?sites';
        breadcrumbs = [
          { text: 'Companies', route: '/companies' },
          { text: this.props.selectedCompany.name, route: parentRoute + '/details' },
          { text: entityLabelPlural, route: parentRoute + subRoute },
          { text: 'Storages' + countLabel },
        ];
        const stocksOption = { text: farmName, route: parentRoute + farmStocksPath(farmId) };
        const detailsOption = { text: farmName, route: parentRoute + '/farms/' + farmId + '/settings/details?details' };
        if (this.props.canActOnFarm && this.props.selectedFarm.isAssociated) {
          breadcrumbs.splice(breadcrumbs.length - 1, 0, stocksOption);
        } else {
          breadcrumbs.splice(breadcrumbs.length - 1, 0, detailsOption);
        }
      } else {
        if (this.props.canActOnFarm && (this.props.selectedFarm.isAssociated || isSystemCompany())) {
          breadcrumbs = [
            { text: entityLabelPlural, route: mainEntityRoute },
            { text: farmName, route: farmStocksPath(farmId) },
            { text: 'Settings', route: '/farms/' + farmId + '/settings/details?details' },
            { text: 'Storages' + countLabel }
          ];
        } else {
          breadcrumbs = [
            { text: entityLabelPlural, route: mainEntityRoute },
          ];

          breadcrumbs = breadcrumbs.concat([
            { text: 'Settings', route: '/farms/' + farmId + '/settings/details?details' },
            { text: 'Storages' + countLabel }
          ]);
        }
      }
      if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }

  }

  isGrowerSite() {
    const { selectedFarm, company } = this.props;
    if (selectedFarm)
      return get(selectedFarm, 'company.typeId') === GROWER;

    return get(company, 'typeId') === GROWER;
  }

  handleAddStorageButtonClick() {
    this.props.handleAddStorageButtonClick();
    this.setState({
      openSideDrawer: true,
    });
  }

  onCloseSideDraw() {
    this.setState({
      openSideDrawer: false,
    });
  }

  closeEditSideDrawer() {
    if (this.props.isUpdateFormDisplayed) {
      this.props.cancelEditStorage();
    }
  }
  componentWillUnmount() {
    this.props.cancelFarmTransfer(null, false);
    this.props.cancelMergeDrawer(null, false);
  };

  getStorages = () => {
    let storages = cloneDeep(this.props.siteStorages);
    storages.splice(storages.findIndex(item => item.id === get(this.props, 'selectedAsset.id')), 1);
    return storages;
  }

  getLabel = () => {
    let fromStorageName = get(this.props, 'selectedAsset.name');
    let toStorageName = "";
    if (this.state.mergeToStorage.value) {
      toStorageName = get(find(this.props.siteStorages, { id: this.state.mergeToStorage.value }), 'name');
    }
    return "Merge " + fromStorageName + " into " + toStorageName;
  }

  handleStorageChange(value) {
    const newState = { ...this.state };
    newState.mergeToStorage.value = value;
    newState.mergeToStorage.errors = [];
    this.setState(newState);
  }

  closeMergeDrawer() {
    const newState = { ...this.state };
    newState.mergeToStorage.value = undefined;
    this.setState(newState);
    this.props.cancelMergeDrawer();
  }

  handleSubmit() {
    let data = {};
    data['mergeFrom'] = get(this.props.selectedAsset, 'id');
    if (data['mergeFrom']) {
      APIService.storages()
        .appendToUrl(`${this.state.mergeToStorage.value}/merge/`)
        .post(data)
        .then(res => {
          if (get(res, 'alert')) alertifyjs.error(get(res, 'alert'))
          else alertifyjs.success('Storage Merged!', 3, () => window.location.reload())
        });
    }
  }

  render() {
    const isEditable = isCompanyEditable(this.props.selectedCompany);
    const isSubscribed = isTransactionParticipated(this.props.selectedCompany);
    const selectedCompanyId = this.props.selectedCompany?.id;
    return (
      <div>
        {
          this.props.canActOnStorage ?
            <AddButton label="Home Storage" onClick={this.handleAddStorageButtonClick} app="storage" />
            : null
        }
        {
          (!isSubscribed && !isCurrentUserBelongsToCompany(selectedCompanyId) && !this.props.canActOnStorage)
          ? <AddButton label="Add Storage" onClick={this.handleAddStorageButtonClick} app="storage" />
          : null
        }
        <StoragesTable canActOnStorage={this.props.canActOnStorage} canActOnFarm={this.props.canActOnFarm} />
        {this.props.isCreateFormDisplayed &&
          <SideDrawer
            isOpen={this.state.openSideDrawer}
            title={isSubscribed && !isCurrentUserBelongsToCompany(selectedCompanyId) ? "Add Home Storage" : "Add Storage"}
            size="small"
            onClose={this.onCloseSideDraw}
            app="storage"
          >
            <CreateStorage closeDrawer={this.onCloseSideDraw} farmId={this.props.farmId} companyId={get(this.props.selectedFarm, 'companyId')} />
          </SideDrawer>
        }
        {
          this.props.canActOnStorage ?
            <SideDrawer
              isOpen={this.props.isUpdateFormDisplayed}
              title="Edit Home Storage"
              size="small"
              onClose={this.closeEditSideDrawer}
              app="storage"
            >
              <UpdateStorage farmId={this.props.farmId} companyId={get(this.props.selectedFarm, 'companyId')} />
            </SideDrawer>
            : null
        }
        {
          this.props.canActOnFarm || isEditable ?
            <SideDrawer
              isOpen={this.props.isopenSideDrawer}
              title="Transfer"
              size="small"
              onClose={this.props.cancelFarmTransfer}
              app="farmField"
            >
              <FarmTransferAsset farmId={this.farmId} />
            </SideDrawer>
            : null
        }
        {this.props.openMergeSideDrawer &&
          <SideDrawer
            isOpen={this.props.openMergeSideDrawer}
            title="Merge"
            onClose={this.closeMergeDrawer}
          >
            <div style={{ paddingTop: "15px" }}>
              <CommonAutoSelect
                id='mergeToSite'
                items={this.getStorages()}
                label={this.getLabel()}
                value={this.state.mergeToStorage.value}
                onChange={this.handleStorageChange}
              />
            </div>
            {
              <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }}>
                <CommonButton
                  type="button"
                  label="Cancel"
                  default
                  onClick={this.closeMergeDrawer}
                  variant="contained"
                />
                <CommonButton
                  type="submit"
                  label="Merge"
                  primary
                  onClick={this.handleSubmit}
                  variant="contained"
                />
              </div>
            }
          </SideDrawer>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isopenSideDrawer: state.companies.farms.farmSideDrawer,
    openMergeSideDrawer: state.companies.companies.showMergeSideDrawer,
    isCreateFormDisplayed: state.companies.storages.isCreateFormDisplayed,
    isUpdateFormDisplayed: state.companies.storages.isUpdateFormDisplayed,
    breadcrumbs: state.main.breadcrumbs,
    selectedCompany: state.companies.companies.selectedCompany,
    selectedFarm: state.companies.farms.selectedFarm,
    siteStorages: state.companies.storages.items,
    count: get(reject(state.companies.storages.items, { isGate: true }), 'length', 0),
    selectedAsset: state.companies.companies.selectedAsset,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelFarmTransfer: () => dispatch(farmSideDrawerForTransfer(false)),
    cancelMergeDrawer: () => dispatch(showSideDrawerForMerge(false)),
    getStorages: (farmId) => dispatch(getStorages(farmId, receiveStorages, '?no_stocks')),
    handleAddStorageButtonClick: () => dispatch(clickAddStorageButton()),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    cancelEditStorage: () => dispatch(cancelEditStorage()),
    getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveStorages);
