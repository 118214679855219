const isWithinRange = (value, minValue, maxValue) => {
  const numValue = parseFloat(value);
  return !isNaN(numValue) && numValue >= minValue && numValue <= maxValue;
};

export const positiveDecimalFilter = (event, digitsAfterDecimal, maxValue=100) => {
  var charCode = (event.which) ? event.which : event.keyCode;
  var prevValue = event.target.value;
  var asciiCode = (charCode>57) ? (charCode-48) : charCode;
  var selectionStart = event.target.selectionStart;
  var dotIndex = prevValue.indexOf('.');
  var length = prevValue.length;
  const minValue = 0;

  if (
    !event.shiftKey && !event.altKey && (
      (charCode == 8 || charCode == 9) ||
      (charCode >= 37 && charCode <= 40) ||
      ((charCode == 190 || charCode == 110) && dotIndex == -1 && length-selectionStart <= digitsAfterDecimal) ||
      (((charCode >= 48 && charCode<= 57) || (charCode >= 96 && charCode <=105)) && (dotIndex == -1 || selectionStart <= dotIndex || length-dotIndex <= digitsAfterDecimal) && isWithinRange(prevValue.slice(0, selectionStart) + String.fromCharCode(asciiCode) + prevValue.slice(selectionStart), minValue, maxValue)) ||
      ((charCode == 67 || charCode == 86 || charCode == 88) && (event.ctrlKey==true || event.metaKey==true))
    )
  )
    {
      return true;
    }
    if(!event.shiftKey && event.keyCode !== 9) {
      event.preventDefault();
    }
};

export const decimalFilter = (event, digitsAfterDecimal, maxValue=100, minValue=-100) => {
  // allows negative also
  var charCode = (event.which) ? event.which : event.keyCode;
  var prevValue = event.target.value;
  var asciiCode = (charCode>57) ? (charCode-48) : charCode;
  var selectionStart = event.target.selectionStart;
  var dotIndex = prevValue.indexOf('.');
  var length = prevValue.length;
  if (
    !event.shiftKey && !event.altKey && (
      (charCode == 8 || charCode == 9 || charCode == 189) ||
      (charCode >= 37 && charCode <= 40) ||
      ((charCode == 190 || charCode == 110) && dotIndex == -1 && length-selectionStart <= digitsAfterDecimal) ||
      (((charCode >= 48 && charCode<= 57) || (charCode >= 96 && charCode <=105)) && (dotIndex == -1 || selectionStart <= dotIndex || length-dotIndex <= digitsAfterDecimal) && isWithinRange(prevValue.slice(0, selectionStart) + String.fromCharCode(asciiCode) + prevValue.slice(selectionStart), minValue, maxValue)) ||
      ((charCode == 67 || charCode == 86 || charCode == 88) && (event.ctrlKey==true || event.metaKey==true))
    )
  )
    {
      return true;
    }
    if(!event.shiftKey && event.keyCode !== 9) {
      event.preventDefault();
    }
};

export const digitsAndCharactersOnly = event => {
  var charCode = (event.which) ? event.which : event.keyCode;
  if (
    (charCode == 8 || charCode == 9) ||
    (charCode >= 37 && charCode <= 40) ||
    (charCode >= 48 && charCode<= 57) || (charCode >=65 && charCode<= 90 ) ||
    ((charCode == 67 || charCode == 86 || charCode == 88) && (event.ctrlKey==true || event.metaKey==true))
  )
    {
      return true;
    }
  event.preventDefault();
};

export const digitsOnlyFilter = (event) => {
  var charCode = (event.which) ? event.which : event.keyCode;
  if (
    (charCode == 8 || charCode == 9) ||
    (charCode >= 37 && charCode <= 40) ||
    (charCode >= 48 && charCode<= 57) ||
    (charCode >= 96 && charCode <=105) ||
    ((charCode == 67 || charCode == 86 || charCode == 88) && (event.ctrlKey==true || event.metaKey==true))
  )
    {
      return true;
    }
  event.preventDefault();
  };
