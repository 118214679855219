import React, { Component } from 'react'
import GenericTable from "../../components/GenericTable";
import { connect } from "react-redux";
import { FILTER_KEYS_TO_EXCLUDE, OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD, PREDEFINED_DATE_RANGE_FILTER_KEYS, SITE_LOADS_HEADERS, STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES } from "../../common/constants";
import { Button, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { getSiteLoads, getSiteLoadsUrl, receiveCompanySiteLoads } from '../../actions/companies/company-sites';
import includes from 'lodash/includes';
import SideDrawer from '../common/SideDrawer';
import APIService from '../../services/APIService';
import Filters from '../common/Filters';
import { forceStopLoader, isLoading, setDownloadBar } from '../../actions/main';
import {
  receiveTitleTransfer,
  getSelectedTitleTransfer,
  showHideTitleTransferSideDrawer,
  showViewTitleTransferSideDrawer
} from '../../actions/companies/contracts';
import StorageToStorageTransferDialog from './StorageToStorageTransferDialog';
import TitleTransferDetails from '../title-transfers/TitleTransferDetails';
import UpdateInload from '../../containers/UpdateInload';
import { isEqual, isEmpty, startCase, cloneDeep } from 'lodash';
import StockSwapDialog from './StockSwapDialog';
import UpdateOutload from '../../containers/UpdateOutload';
import UpdateStorageFilters from './UpdateStorageFilters';
import get from 'lodash/get';
import CommonListingButton from '../common/CommonListingButton';
import { attachCSVEventListener, defaultViewAction } from '../../common/utils';
import FiltersAppliedChip from '../common/FiltersAppliedChip';
import ManageStorageAndOwnershipStocks from './ManageStorageAndOwnershipStocks';

const SITE_LOADS_FILTER_KEYS_MAPPING = {
  'load_date_range': ['created_at__gte', 'created_at__lte'],
}

class SiteLoadsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawer: false,
      openTitleTransferSideDrawer: false,
      isEditLoadSideDrawerOpened: false,
      handleSiloToSiloTransfer: false,
      handleStocksSwap: false,
      openUpdateStorageFilter: false,
      manageStorageOwnershipSideDrawerOpened: false,
      load: null,
      loadType: null,
      movement: null,
      filters: {},
      filterValues: {
        freight_provider__id__in: [],
        commodity__id__in: [],
        planned_grade__id__in: [],
        season__in: [],
        load_date_range: '',
        created_at__gte: '',
        created_at__lte: '',
        load_type__in: [],
        stock_owner__in: [],
        load_source_type__in: [],
        ngr__ngr_number__in: []
      },
      csvData: [],
    }
    this.handleFilterSideDrawer = this.handleFilterSideDrawer.bind(this);
    this.handleFilterState = this.handleFilterState.bind(this);
    this.closeEditLoadSideDrawer = this.closeEditLoadSideDrawer.bind(this);
    this.toggleUpdateStorageDialog = this.toggleUpdateStorageDialog.bind(this);
  }

  componentDidMount() {
    this._attachCSVEventListener();
  }

  getColumns = () => {
    const columns = [...cloneDeep(SITE_LOADS_HEADERS)]
    if(this.props.unit) {
      const suffix = ` (${this.props.unit})`
      columns[12].header += suffix
      columns[13].header += suffix
    }
    return columns
  }

  componentDidUpdate(prevProps) {
    if(! isEqual(prevProps.filters, this.props.filters)) {
      this.setState({ filters: this.props.filters });
    }
  }

  _attachCSVEventListener() {
    attachCSVEventListener(
      'site-loads-csv-ready', 'Site Loads', this.onDownloadResponse
    );
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  onCloseDownloadResponse() {
    this.props.setDownloadBar(null, false, null);
  }

  handleFilterSideDrawer() {
    this.setState({ openSideDrawer: !this.state.openSideDrawer });
  }

  closeEditLoadSideDrawer() {
    this.setState({ isEditLoadSideDrawerOpened: false, handleStocksSwap: false,
                    load: null, movement: null, loadType: null, handleSiloToSiloTransfer: false, openUpdateStorageFilter: false, });
  }

  toggleUpdateStorageDialog() {
    this.setState({ isEditLoadSideDrawerOpened: false, handleStocksSwap: false,
                    load: null, movement: null, loadType: null, handleSiloToSiloTransfer: false });
  }

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters') {
        const { filters } = this.state;
        APIService.profiles()
          .filters()
          .post({ site_loads_filters: filters }, this.props.token)
          .then(res => {
            this.setState({filters: res?.filters?.site_loads_filters}, () => this.props.fetchSiteLoads(true))
          });
      }
    });
  };

  handleDefaultCellClick = load => {
    if (load.freightMovementId)
      window.open(`#/freights/movements/${load.freightMovementId}/details`);
    else if (load.titleTransferNumber) {
      this.setState({
        openTitleTransferSideDrawer: true
      }, () => {
        this.props.getSelectedTitleTransfer(load.titleTransferId, receiveTitleTransfer, false);
      });
    }
    else if(get(load, 'optionType') == -2)
      this.setState({
        openUpdateStorageFilter: true,
        load: load,
        loadType: load.type,
      });
    else if (get(load, 'optionType') == -3)
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: false,
        handleSiloToSiloTransfer: true,
        handleStocksSwap: false,
        openUpdateStorageFilter: false,
        manageStorageOwnershipSideDrawerOpened: false,
        load: load,
        loadType: load.subType,
      });
    else if (get(load, 'optionType') == -4) {
      if(! get(load, 'balance')) {
        const queryParams = {
          commodity_ids: get(load, 'commodityId'),
          ngr_id: get(load, 'ngrId'),
          grade_id: get(load, 'gradeId'),
          farm_company_id: get(load, 'farmCompanyId'),
          season: get(load, 'season')
        };
        APIService.loads().appendToUrl('stocks/tonnage/').get(null, null, queryParams).then(data => {
          load.balance = get(data, 'tonnage')
          this.setState({
            openTitleTransferSideDrawer: false,
            isEditLoadSideDrawerOpened: false,
            handleSiloToSiloTransfer: false,
            handleStocksSwap: true,
            openUpdateStorageFilter: false,
            manageStorageOwnershipSideDrawerOpened: false,
            load: load,
            loadType: load.subType,
          });
        });
      } else {
        this.setState({
          openTitleTransferSideDrawer: false,
          isEditLoadSideDrawerOpened: false,
          handleSiloToSiloTransfer: false,
          handleStocksSwap: true,
          openUpdateStorageFilter: false,
          manageStorageOwnershipSideDrawerOpened: false,
          load: load,
          loadType: load.subType,
        });
      }
    }
    else if (includes(STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES, get(load, 'optionType'))) {
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: false,
        openUpdateStorageFilter: false,
        manageStorageOwnershipSideDrawerOpened: true,
        load: load,
        loadType: load.subType,
      });
    }
    else
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: true,
        openUpdateStorageFilter: false,
        manageStorageOwnershipSideDrawerOpened: false,
        load: load,
        loadType: load.subType,
      });
  };


  closeSideDraw = () => {
    this.props.showHideTitleTransferSideDrawer(false);
    this.props.showViewTitleTransferSideDrawer(false);
    this.setState({ openTitleTransferSideDrawer: false, handleStocksSwap: false, openUpdateStorageFilter: false});
  };


  fetchCSVData = (params='', callback) => {
    this.props.setDownloadBar('Your Site Loads CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true, null);
    let url = `${this.props.currentUser.companyId}/company_sites/${this.props.selectedLocation}/loads/csv/?${params}`;
    APIService.companies().appendToUrl(url).get()
      .then(csvData => {
        csvData = csvData || [];
        this.setState(state => ({ ...state, csvData }));
      })
    if (callback) {
      callback();
    }
  }

  canExportCSV = () => {
    return this.props.selectedLocation && !isEqual(this.props.selectedLocation,'all')
  }

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(SITE_LOADS_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  render() {
    const { loadType, load } = this.state;
    return (
      <div>
        {this.canExportCSV() && (
          <CommonListingButton
            defaultHandler={() => this.fetchCSVData()}
            showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0))}
            optionMapper={[
              { name: 'Complete List', fx: callback => this.fetchCSVData('', callback) },
              { name: 'Filtered List', fx: callback => this.fetchCSVData('show_filters', callback) },
            ]}
            title='Download Contents of the table in a CSV'
            name='Export'
          />
        )}
        <Tooltip title='Apply filters' placement='top'>
          <Button
            variant="contained"
            type='button'
            color='primary'
            className='add-button'
            style={{ float: 'right', marginLeft: '10px' }}
            onClick={this.handleFilterSideDrawer}
          >
            <FilterListIcon style={{ paddingRight: '5px' }} />
            FILTERS{' '}
            {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
             ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
             : ''}
          </Button>
        </Tooltip>
        <div style={{float: 'right'}}>
              <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
            </div>

        <FiltersAppliedChip filters={this.state.filters} show style={{paddingRight: '30%'}} />
        <GenericTable
          {...this.props}
          columns={this.getColumns()}
          handleDefaultCellClick={this.handleDefaultCellClick}
        />
        {this.state.openSideDrawer && (
          <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilterSideDrawer()} app='filters'>
            <Filters
              isLoading={this.props.isLoading}
              forceStopLoader={this.props.forceStopLoader}
              handleFilterState={this.handleFilterState}
              filters={this.state.filters}
              filterValues={this.state.filterValues}
              isSiteLoadsFilter
            />
          </SideDrawer>
        )}
        {
          this.props.isViewTitleTransferSideDrawerOpened &&
            <SideDrawer
              isOpen={this.state.openTitleTransferSideDrawer}
              title="Title Transfer"
              onClose={this.closeSideDraw}
              size='big'>
              <TitleTransferDetails onClose={this.closeSideDraw} />
            </SideDrawer>
        }
        {this.state.handleSiloToSiloTransfer &&
         <StorageToStorageTransferDialog
           item={load}
           isEdit={true}
           isOpen={this.state.handleSiloToSiloTransfer}
           toggleDialog={this.closeEditLoadSideDrawer}
           isSiteLoadsView
         />
        }
        {load && this.state.handleStocksSwap &&
         <SideDrawer isOpen title="Stock Swap" size='small' onClose={this.toggleUpdateStorageDialog}>
           <StockSwapDialog
             isEdit
             token={this.props.token}
             item={load}
             user={this.props.user}
             toggleDialog={this.toggleUpdateStorageDialog}
           />
         </SideDrawer>
        }
        {this.state.openUpdateStorageFilter &&
         <UpdateStorageFilters
           isEdit={true}
           regradingStock={true}
           isSiteLoadsView={true}
           token={this.props.token}
           item={this.state.load}
           isOpen
           toggleDialog={this.closeSideDraw}
         />
        }
        {
          loadType === 'Inload' &&
            <SideDrawer
              isOpen={this.state.isEditLoadSideDrawerOpened}
              title={`Edit ${startCase(loadType)}`}
              size="big"
              onClose={this.closeEditLoadSideDrawer}
              app="load"
            >
              <UpdateInload
                companyId={this.state.load.companyId}
                farmId={this.state.load.farmId}
                storageId={this.state.load.storageId}
                inload={load}
                closeDrawer={this.closeEditLoadSideDrawer}
                showNgrField
                isCreate={false}
              />
            </SideDrawer>
        }
        {
          loadType === 'Outload' &&
            <SideDrawer
              isOpen={this.state.isEditLoadSideDrawerOpened}
              title={`Edit ${startCase(loadType)}`}
              size="big"
              onClose={this.closeEditLoadSideDrawer}
              app="load"
            >
              <UpdateOutload
                companyId={this.state.load.companyId}
                farmId={this.state.load.farmId}
                storageId={this.state.load.storageId}
                outload={load}
                closeDrawer={this.closeEditLoadSideDrawer}
                showNgrField
                isCreate={false}
              />
            </SideDrawer>
        }
        {this.state.manageStorageOwnershipSideDrawerOpened &&
        <SideDrawer
          isOpen={this.state.manageStorageOwnershipSideDrawerOpened}
          title={includes([OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD], load.optionType) ? "Stock Empty" : "Stock Update"}
          size='big'
          onClose={() => this.setState({manageStorageOwnershipSideDrawerOpened: false})}
        >
          <ManageStorageAndOwnershipStocks load={load} onClose={() => this.setState({manageStorageOwnershipSideDrawerOpened: false})}/>
        </SideDrawer>
        }
      </div>
  )
}
}

const mapStateToProps = state => {
  const token = state.main.user.token;
  return {
    currentUser: state.main.user.user,
    items: state.companies.companySites.siteLoads,
    globalSearch: true,
    order: 'desc',
    orderBy: 'createdAt',
    clearSearch: (searchItem) => receiveCompanySiteLoads(get(searchItem, 'results'), state.companies.companySites.selectedSiteId),
    paginationData: state.companies.companySites.paginationData,
    scrollToTopOnUpdate: false,
    token,
    isViewTitleTransferSideDrawerOpened: state.companies.contracts.isViewTitleTransferSideDrawerOpened,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    isLoading: (waitForComponent) => dispatch(isLoading(waitForComponent)),
    forceStopLoader: () => dispatch(forceStopLoader()),
    getSelectedTitleTransfer: (titleTransferId, actionCreator, stopLoader) => dispatch(getSelectedTitleTransfer(titleTransferId, actionCreator, stopLoader)),
    navigateTo: url => dispatch(getSiteLoads(url, true)),
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getSiteLoads(url, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      let url = dispatch(getSiteLoadsUrl(pageSize, page, searchText, orderBy, order));
      return url;
    },
    showHideTitleTransferSideDrawer: (flag) => dispatch(showHideTitleTransferSideDrawer(flag)),
    showViewTitleTransferSideDrawer: (flag) => dispatch(showViewTitleTransferSideDrawer(flag)),
    setDownloadBar: (message, flag, onClose) => dispatch(setDownloadBar(message, flag, onClose)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteLoadsTable)
