import React, { Component } from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';

import moment from 'moment';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonButton from '../common/CommonButton';
import { get, isEmpty, set, mapValues, has, find, isEqual, some, forEach, partialRight, map, pick, filter, includes } from 'lodash';
import { required, selected } from '../../common/validators';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import SeasonSelect from '../common/select/SeasonSelect';
import APIService from '../../services/APIService';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import CommonTimePicker from '../common/CommonTimePicker';
import { getTracks } from '../../actions/main/index';
import { positiveDecimalFilter } from '../../common/input-filters';
import {
  getDateTimeFromUTC,
  isShallowEqual,
  getAutoSelectFocusField,
  getCountryCurrency
} from '../../common/utils';
import { TAB } from './constants';
import { getFixtures } from '../../actions/companies/contracts';
import { COMMODITIES, MT_UNIT, PAYMENT_SCALES } from '../../common/constants';
import { Checkbox, FormControlLabel } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import CurrencyField from '../common/CurrencyField'
import { getCompanyCashPrices } from '../../actions/companies/cash-board';

class AddPriceDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            handlers: [],
            paymentScales: [],
            ngrs: [],
            companies:[],
            site: null,
            fields: {
                siteId: {
                    value: null,
                    validators: [required()],
                    errors: []
                },
                track: {
                    value: null,
                    validators: [],
                    errors: []
                },
                commodityId: {
                    value: null,
                    validators: [required()],
                    errors: []
                },
                gradeId: {
                    value: null,
                    validators: [required()],
                    errors: []
                },
                season: {
                    value: null,
                    validators: [required()],
                    errors: []
                },
                price: {
                    value: null,
                    validators: [required()],
                    errors: []
                },
                limit: {
                    value: null,
                    validators: [required()],
                    errors: []
                },
                paymentTermId: {
                    value: null,
                    validators: [required(), selected()],
                    errors: []
                },
                paymentScaleId: {
                    value: null,
                    validators: [required(), selected()],
                    errors: []
                },
                startDate: {
                    value: '',
                    validators: [required()],
                    errors: []
                },
                startTime: {
                  value: '',
                  validators: [required()],
                  errors: []
                },
              endDate: {
                value: '',
                validators: [required()],
                errors: []
              },
              endTime: {
                value: moment().endOf('day').format('HH:mm:ss'),
                validators: [required()],
                errors: []
              },
              buyer: {
                    companyId: {
                        value: null,
                        validators: [required(), selected()],
                        errors: [],
                    },
                    contactId: {
                        value: null,
                        validators: [],
                        errors: [],
                    },
                    ngrId: {
                        value: null,
                        validators: [required(), selected()],
                        errors: [],
                    },
                },
                sustainableCommodity: {
                    value: false,
                    validators: [],
                    errors: []
                },
            }

        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.setFieldErrors = this.setFieldErrors.bind(this);
        this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
        this.getFieldErrors = this.getFieldErrors.bind(this);
        this.fieldsOrder = [
            'siteId', 'track', 'commodityId', 'gradeId',
            'season', 'price', 'limit', 'paymentScaleId', 'paymentTermId', 'startDate',
            'startTime', 'endDate', 'endTime', 'buyer.companyId', 'buyer.ngrId'
        ];
        this.isBuyerApproved = [];
        this.fieldRef = {};
        this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    }

    componentDidMount() {
        const { dispatch } = this.props;
        Promise.all([
            this.getNgrs(),
            this.getCompanies(),
            dispatch(getFixtures()),
            dispatch(getTracks()),
        ]);
    }

    componentDidUpdate(prevProps, prevState) {
      const newState = { ...this.state };
      let _set = false
      if (!this.props.viewSelectedCashPrice && (this.props.editSelectedCashPrice || this.props.isCreate)) {
        _set = true
            const startDateTime = getDateTimeFromUTC(Date().toLocaleString());
            newState.fields.startDate.value = startDateTime.date;
            newState.fields.startTime.value = startDateTime.time;

        }
        if (!find(this.state.handlers, {id: get(this.props, 'selectedCashPrice.siteId')}) && (this.props.editSelectedCashPrice || this.props.viewSelectedCashPrice) && this.props.selectedCashPrice) {
            _set = true
            newState.site = { 'id': get(this.props, 'selectedCashPrice.siteId'), 'name': get(this.props, 'selectedCashPrice.siteName') };
            newState.fields.siteId.value = get(this.props, 'selectedCashPrice.siteId');
            newState.fields.track.value = get(this.props, 'selectedCashPrice.track');
            newState.fields.commodityId.value = get(this.props, 'selectedCashPrice.commodityId');
            newState.fields.gradeId.value = get(this.props, 'selectedCashPrice.gradeId');
            newState.fields.season.value = get(this.props, 'selectedCashPrice.season');
            newState.fields.price.value = get(this.props, 'selectedCashPrice.price');
            newState.fields.limit.value = get(this.props, 'selectedCashPrice.limit');
            newState.fields.paymentTermId.value = get(this.props, 'selectedCashPrice.paymentTerm.id');
            newState.fields.sustainableCommodity.value = get(this.props, 'selectedCashPrice.isCommoditySustainable');
            if (this.props.viewSelectedCashPrice) {
                const startDateTime = getDateTimeFromUTC(get(this.props, 'selectedCashPrice.startDateTime'));
                const endDateTime = getDateTimeFromUTC(get(this.props, 'selectedCashPrice.endDateTime'));
                newState.fields.startDate.value = startDateTime.date;
                newState.fields.startTime.value = startDateTime.time;
                newState.fields.endDate.value = endDateTime.date;
                newState.fields.endTime.value = endDateTime.time;
            }
            newState.fields.buyer.ngrId.value = get(this.props, 'selectedCashPrice.buyer.ngrId');
        }

      if (!isEqual(this.props.paymentScales, prevState.paymentScales)) {
        _set = true
            if (newState.fields.commodityId.value === COMMODITIES.CANOLA) {
                newState.paymentScales = this.props.paymentScales;
                newState.fields.paymentScaleId.value = get(this.props, 'selectedCashPrice.paymentScale');
                newState.fields.paymentScaleId.errors = [];
            } else {
                newState.paymentScales = [find(this.props.paymentScales, { id: PAYMENT_SCALES.FLAT })];
            }
        }

      if(_set && !isEqual(prevState, newState))
        this.setState(newState);
    }

    async getNgrs() {
        if (!this.props.viewSelectedCashPrice) {
            await APIService.companies(this.props.currentUser.companyId)
                .ngrs()
                .appendToUrl('minimal/')
                .get(this.props.userToken)
                .then(items => {
                    const newState = { ...this.state };
                    newState.ngrs = items;
                    newState.fields.buyer.companyId.value = this.props.currentUser.companyId;
                    newState.fields.buyer.contactId.value = this.props.currentUser.id;
                    if (this.props.isCreate)
                        newState.fields.paymentTermId.value = this.props.currentUser.company.paymentTermId;
                    this.setState(newState);
                });
            await this.defaultNgr(this.props.currentUser.companyId)
        }
    };

    async getCompanies() {
      APIService.companies().appendToUrl('directory/names/?excludeGroups=true').get().then(companies =>
          this.setState({companies: companies})
        );
      }

    setAllFieldsErrors() {
        forEach(this.state.fields, (value, key) => {
            this.setFieldErrors(key);
        });
        forEach(this.state.fields.buyer, (value, key) => {
            this.setFieldErrors(`buyer.${key}`);
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setAllFieldsErrors();

        let isFormInvalid = some(this.state.fields, field => {
            return field.errors.length > 0;
        }) || some(this.state.fields.buyer, field => {
            return field.errors.length > 0;
        });
        this.focusOnFirstErrorField();
        if (!isFormInvalid) {
            const utcStartDateTime = moment(this.state.fields.startDate.value + ' ' + this.state.fields.startTime.value).utc();
            const utcEndDateTime = moment(this.state.fields.endDate.value + ' ' + this.state.fields.endTime.value).utc();
            if (moment(utcStartDateTime).isAfter(utcEndDateTime)) {
                isFormInvalid = true;
                alertifyjs.error("End date should be greater than start date");
            };
            if (!isFormInvalid) {
                const data = mapValues(this.state.fields, 'value');
                const buyer = mapValues(this.state.fields.buyer, 'value');
                data.buyer = buyer;
                if (has(data, 'startDate') && has(data, 'startTime')) {
                    delete data['startDate'];
                    delete data['startTime'];
                    data.startDateTime = utcStartDateTime.format('YYYY-MM-DD HH:mm:ss');
                }
                if (has(data, 'endDate') && has(data, 'endTime')) {
                    delete data['endDate'];
                    delete data['endTime'];
                    data.endDateTime = utcEndDateTime.format('YYYY-MM-DD HH:mm:ss');
                }
                data.gradeId = get(data, 'gradeId.id', get(data, 'gradeId'));

                if (!this.props.editSelectedCashPrice) {
                    const site = find(this.state.handlers, handler => handler.id === this.state.fields.siteId.value)
                    if (includes([this.state.fields.buyer.companyId.value, site?.companyId], this.props.currentUser.companyId)) {
                        APIService.cash_board().appendToUrl('cash-prices/').request(
                            'POST', data, null
                        ).then(() => {
                            alertifyjs.success('Cash Price Created', 3);
                            this.props.onClose();
                            if (get(this.props, 'isAllCashPriceTab') != TAB.CLOSED_CASH_PRICE)
                                this.props.dispatch(getCompanyCashPrices('', true))
                            else
                                window.location.reload();
                        }).catch(res => {
                            if (!isEmpty(get(res, 'response.data.errors.0.cash_price_exists'))) {
                                alertifyjs.confirm('Are you sure?', get(this.props, 'isAllCashPriceTab') === TAB.CLOSED_CASH_PRICE ? 'New cash price will be generated. Are you sure?' : 'Current cash price will be marked complete and a new will be generated. Are you sure?',
                                    () => {
                                        data.mark_complete = get(res, 'response.data.errors[0].cash_price_id[0][0]');
                                        APIService.cash_board().appendToUrl('cash-prices/').request(
                                            'POST', data, null, { responseType: 'blob' }
                                        ).then(response => {
                                            if (response.errors) {
                                                alertifyjs.error(get(response, 'errors[0].cash_price_exists[0]', 'An Error Occurred'), 5);
                                            }
                                            else {
                                                alertifyjs.success('Cash Price Created', 5);
                                                this.props.onClose();
                                                if (get(this.props, 'isAllCashPriceTab') != TAB.CLOSED_CASH_PRICE)
                                                    this.props.dispatch(getCompanyCashPrices('', true))
                                                else
                                                    window.location.reload();
                                            }
                                        });
                                    },
                                    () => { });
                            } else
                                alertifyjs.error(get(res, 'response.data.errors', 'An Error Occurred'), 3);
                        });
                    }
                    else
                        alertifyjs.error('User should belong to either the site company or the Buyer company')
                }
                else if (this.props.editSelectedCashPrice && !(isShallowEqual(data.buyer.ngrId, this.props.selectedCashPrice.buyer.ngrId) ||
                    isShallowEqual(data.startDateTime, this.props.selectedCashPrice.startDateTime) ||
                    isShallowEqual(data.endDateTime, this.props.selectedCashPrice.endDateTime) ||
                    isShallowEqual(data.price, this.props.selectedCashPrice.price) ||
                    isShallowEqual(data.limit, this.props.selectedCashPrice.limit)
                )) {
                    alertifyjs.confirm('Are you sure?', get(this.props, 'isAllCashPriceTab') === TAB.CLOSED_CASH_PRICE ? 'New cash price will be generated. Are you sure?' : 'Current cash price will be marked complete and a new will be generated. Are you sure?',
                        () => {
                            data.mark_complete = this.props.selectedCashPrice.id;
                            APIService.cash_board().appendToUrl('cash-prices/').request(
                                'POST', data, null,
                            ).then(() => {
                                alertifyjs.success('Cash Price Created', 5);
                                this.props.onClose();
                                if (get(this.props, 'isAllCashPriceTab') != TAB.CLOSED_CASH_PRICE)
                                    this.props.dispatch(getCompanyCashPrices('', true))
                                else
                                    window.location.reload();
                            }).catch(res => {
                                if (!isEmpty(get(res, 'response.data.errors.0.cash_price_exists'))) {
                                    alertifyjs.confirm('Are you sure?', 'Similar active cash price exist. Active cash price will be marked complete and a new will be generated. Are you sure?',
                                        () => {
                                            data.mark_complete = get(res, 'response.data.errors[0].cash_price_id[0][0]');
                                            APIService.cash_board().appendToUrl('cash-prices/').request(
                                                'POST', data, null, { responseType: 'blob' }
                                            ).then(response => {
                                                if (response.errors) {
                                                    alertifyjs.error(get(response, 'errors[0].cash_price_exists[0]', 'An Error Occurred'), 5);
                                                }
                                                else {
                                                    alertifyjs.success('Cash Price Created', 5);
                                                    this.props.onClose();
                                                    if (get(this.props, 'isAllCashPriceTab') != TAB.CLOSED_CASH_PRICE)
                                                        this.props.dispatch(getCompanyCashPrices('', true))
                                                    else
                                                        window.location.reload();
                                                }
                                            });
                                        },
                                        () => { });
                                } else
                                    alertifyjs.error(get(res, 'response.data.errors', 'An Error Occurred'), 3);
                            });
                        },
                        () => { });
                }
            }
        }
    }
    async defaultNgr(value) {
        if (this.props.isCreate) {
            APIService.companies(value).appendToUrl('ngrs/tags/title_transfer/')
                .get()
                .then(response => {
                    const newState = {...this.state};
                    set(newState.fields, 'buyer.ngrId.value', get(response, 'ngrId'));
                    this.setState(newState);
                })
            }
    }

    focusOnFirstErrorField() {
        const nestedFields = ["siteId", "track", "season", "price", "limit", 'paymentScaleId', 'paymentTermId', "buyer.ngrId",
            "startDate", "startTime", "endDate", "endTime"
        ];
        const autoCompleteFields = ["commodityId", "gradeId"];

        for (let i = 0; i < this.fieldsOrder.length; i++) {
            const formField = this.fieldRef[this.fieldsOrder[i]];
            const field = this.state.fields[this.fieldsOrder[i]];
            if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
                if ((this.fieldsOrder[i] === "siteId" && this.state.fields.siteId.errors.length) ||
                    (this.fieldsOrder[i] === "track" && this.state.fields.track.errors.length) ||
                    (this.fieldsOrder[i] === 'paymentScaleId' && this.state.fields.paymentScaleId.errors.length) ||
                    (this.fieldsOrder[i] === 'paymentTermId' && this.state.fields.paymentTermId.errors.length) ||
                    (this.fieldsOrder[i] === "buyer.ngrId" && this.state.fields.buyer.ngrId.errors.length) ||
                    (this.fieldsOrder[i] === "buyer.companyId" && this.state.fields.buyer.companyId.errors.length) ||
                    (this.fieldsOrder[i] === "startDate" && this.state.fields.startDate.errors.length) ||
                    (this.fieldsOrder[i] === "startTime" && this.state.fields.startTime.errors.length) ||
                    (this.fieldsOrder[i] === "endDate" && this.state.fields.endDate.errors.length) ||
                    (this.fieldsOrder[i] === "endTime" && this.state.fields.endTime.errors.length)) {
                    getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
                    break;
                }
                if ((this.fieldsOrder[i] === "siteId" && this.state.fields.siteId.errors.length > 0) ||
                    (this.fieldsOrder[i] === "season" && this.state.fields.season.errors.length > 0) ||
                    (this.fieldsOrder[i] === "price" && this.state.fields.price.errors.length) ||
                    (this.fieldsOrder[i] === "limit" && this.state.fields.limit.errors.length)) {
                    formField.current.focus();
                    break;
                }
            } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
                if (field && field.errors.length > 0) {
                    getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
                    break;
                }
            } else if (field && field.errors.length > 0) {
                if (get(formField, 'current.node')) {
                    formField.current.node.previousSibling.focus();
                    break;
                } else {
                    const current = get(formField, 'current');
                    if (current)
                        current.focus();
                    break;
                }
            }
        }
    }

    async handleValueChange(value, id, item) {
        const newState = { ...this.state };
        set(newState.fields, `${id}.value`, value);
        if (id == 'commodityId' && !this.props.selectedCashPrice) {
            if (value === COMMODITIES.CANOLA) {
                newState.paymentScales = this.props.paymentScales;
                newState.fields.paymentScaleId.value = PAYMENT_SCALES.AOF_BASIC;
                newState.fields.paymentScaleId.errors = [];
            } else {
                newState.paymentScales = [find(this.props.paymentScales, { id: PAYMENT_SCALES.FLAT })];
            }
        }
        if (id == 'siteId') {
            this.isBuyerApproved = [];
            newState.site = item
            newState.handlers = uniqBy([...newState.handlers, item], 'id');
            if (value) {
                await APIService.farms(value)
                    .appendToUrl('is-approved-buyer/')
                    .get()
                    .then(res => {
                        if (res.reason)
                            this.isBuyerApproved = res.reason;
                        else
                            this.isBuyerApproved = [];
                    });
            }
        }
        if (id == 'buyer.companyId' && value) {
            this.defaultNgr(value)
            APIService.companies(value)
            .ngrs()
            .appendToUrl('minimal/')
            .get(this.props.userToken)
            .then(items => {
                const newState = { ...this.state };
                newState.ngrs = items;
                this.setState(newState);
            });
        }
        if(!isEmpty(this.state.ngrs))
            newState.ngrs = this.state.ngrs;
        this.setState(newState, () => this.setFieldErrors(id));
    }

    setFieldErrors(key) {
        const newState = { ...this.state };
        set(newState.fields, key + '.errors', this.getFieldErrors(key));
        this.setState(newState);
    }

    getFieldErrors(key) {
        let errors = [];
        const value = get(this.state.fields, `${key}.value`);
        const validators = get(this.state.fields, `${key}.validators`) || [];
        if (key == 'siteId') {
            if (this.isBuyerApproved.length == 0)
                errors = [];
            else
                errors.push(this.isBuyerApproved);
        }
        validators.forEach(validator => {
            if (validator.isInvalid(value)) {
                errors.push(validator.message);
            }
        });

        return errors;
    }

    handleFieldChange(event) {
        this.handleValueChange(event.target.value, event.target.id);
    }

  handleCheckboxClick = event => this.handleValueChange(event.target.checked, event.target.id);

  isCommoditySustainable() {
      if(this.state.fields.commodityId.value) {
        const selectedCommodity = filter(this.props.commodities, { id: this.state.fields.commodityId.value });
        if(!isEmpty(selectedCommodity))
            return get(selectedCommodity, '0.sustainable');
      }
  }

  render() {
    const priceUnit = find(this.props.commodities, { id: this.state.fields.commodityId.value })?.priceUnit || MT_UNIT;
    const user_company = {name: this.props.currentUser.company.displayName, id: this.props.currentUser.companyId, abn: this.props.currentUser.company.abn}
    const companies = this.state.companies.concat(user_company)
      const isSustainableCommodity = this.isCommoditySustainable();
        return (
            <form noValidate>
                <div className="cardForm cardForm--drawer">
                    <div className="cardForm-content row">
                        <div className='col-sm-12 form-wrap' ref={this.fieldRef['siteId']}>
                            <SiteAsyncAutocomplete
                                limitTags={2}
                                label="Site"
                                id="siteId"
                                onChange={item => this.handleValueChange(item?.id, 'siteId', item)}
                                selected={get(this.state, 'site', '')}
                                minLength={3}
                                variant="standard"
                                disabled={(this.props.editSelectedCashPrice || this.props.viewSelectedCashPrice) && this.props.selectedCashPrice}
                                popupIcon={((this.props.editSelectedCashPrice || this.props.viewSelectedCashPrice) && this.props.selectedCashPrice)? <i className="icon-lock-inline"></i> : undefined}
                                fullWidth
                                activeSitesOnly
                                addLabel
                                errorText={get(this.state.fields, 'siteId.errors[0]')}
                                farmId={(this.props.editSelectedCashPrice || this.props.viewSelectedCashPrice) ? get(this.props.selectedCashPrice, 'siteId') : null}
                            />
                        </div>
                        <div className='col-md-12 form-wrap'>
                            <CommonAutoSelect
                                id='track'
                                setRef={this.fieldRef['track']}
                                label='Port (Optional)'
                                errorText={get(this.state.fields.track, 'errors[0]')}
                                value={this.state.fields.track.value}
                                errorStyle={{ textAlign: 'left' }}
                                onChange={this.handleValueChange}
                                items={this.props.tracks}
                                disabled={this.props.viewSelectedCashPrice}
                            />
                        </div>
                        <div className='col-md-12 form-wrap'>
                            <CommodityAutoComplete
                                id="commodityId"
                                setRef={this.fieldRef["commodityId"]}
                                onChange={this.handleValueChange}
                                floatingLabelText="Commodity"
                                commodityId={this.state.fields.commodityId.value}
                                errorText={get(this.state, 'fields.commodityId.errors[0]', '')}
                                disabled={(this.props.editSelectedCashPrice || this.props.viewSelectedCashPrice) && this.props.selectedCashPrice}
                            />
                        </div>
                        <div className='col-md-12 form-wrap'>
                            <GradeAutoComplete
                                id="gradeId"
                                onChange={this.handleValueChange}
                                floatingLabelText="Grade"
                                setRef={this.fieldRef["gradeId"]}
                                commodityId={this.state.fields.commodityId.value}
                                gradeId={this.state.fields.gradeId.value}
                                season={this.state.fields.season.value}
                                dependsOnCommodity
                                dependsOnSeason
                                selectedGradeId={this.state.fields.gradeId.value}
                                errorText={get(this.state, 'fields.gradeId.errors[0]', '')}
                                disabled={(this.props.editSelectedCashPrice || this.props.viewSelectedCashPrice) && this.props.selectedCashPrice}
                            />
                        </div>
                        <div className='col-md-12 form-wrap'>
                            <SeasonSelect
                                id="season"
                                setRef={this.fieldRef["season"]}
                                onChange={this.handleValueChange}
                                season={this.state.fields.season.value}
                                errorText={get(this.state, 'fields.season.errors[0]', '')}
                                disabled={(this.props.editSelectedCashPrice || this.props.viewSelectedCashPrice) && this.props.selectedCashPrice}
                            />
                        </div>
                        <div className='col-md-12 form-wrap'>
                            <CurrencyField
                                error={!isEmpty(get(this.state.fields.price, 'errors[0]'))}
                                id='price'
                                label={`Price (per ${priceUnit})`}
                                value={this.state.fields.price.value || ''}
                                helperText={get(this.state.fields.price, 'errors[0]')}
                                inputRef={this.fieldRef['price']}
                                fullWidth
                                onChange={this.handleFieldChange}
                                maxValue={9999999.99}
                                disabled={this.props.viewSelectedContractBid}
                                variant="standard"
                                selectedCurrency={getCountryCurrency()}
                                disabledCurrency={true}
                            />
                        </div>
                        <div className='col-md-12 form-wrap'>
                            <TextField
                                id='limit'
                                label={'Limit'}
                                inputRef={this.fieldRef['limit']}
                                placeholder={'Limit'}
                                value={this.state.fields.limit.value || ''}
                                fullWidth
                                maxLength='1000000'
                                helperText={get(this.state.fields.limit, 'errors[0]')}
                                error={!isEmpty(get(this.state.fields.limit, 'errors[0]'))}
                                style={{ float: 'left' }}
                                onChange={this.handleFieldChange}
                                onKeyDown={event => positiveDecimalFilter(event, 2, 999999.99)}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                                        {priceUnit}
                                      </InputAdornment>
                                    ),
                                }}
                                disabled={this.props.viewSelectedCashPrice}
                                variant="standard" />
                        </div>
                        <div className='col-md-12 form-wrap'>
                            <CommonAutoSelect
                                id='paymentScaleId'
                                setRef={this.fieldRef['paymentScaleId']}
                                onChange={this.handleValueChange}
                                label='Payment Scale'
                                value={this.state.fields.paymentScaleId.value}
                                floatingLabelText='Payment Scale'
                                dataSourceConfig={{ text: 'name', value: 'id' }}
                                errorText={get(this.state, 'fields.paymentScaleId.errors[0]', '')}
                                items={this.state.paymentScales}
                                disabled={this.props.viewSelectedCashPrice && this.props.selectedCashPrice}
                            />
                        </div>
                        <div className='col-md-12 form-wrap'>
                            <CommonAutoSelect
                                id='paymentTermId'
                                setRef={this.fieldRef['paymentTermId']}
                                onChange={this.handleValueChange}
                                label='Payment Terms'
                                dataSourceConfig={{ text: 'name', value: 'id' }}
                                value={this.state.fields.paymentTermId.value}
                                errorText={get(this.state, 'fields.paymentTermId.errors[0]', '')}
                                items={this.props.paymentTerms}
                                disabled={this.props.viewSelectedCashPrice && this.props.selectedCashPrice}
                            />
                        </div>
                        {this.props.viewSelectedCashPrice && this.props.selectedCashPrice.buyer ?
                            <div className='col-md-12 form-wrap'>
                                <TextField
                                    id="buyer.companyId"
                                    label="Buyer"
                                    placeholder='Buyer'
                                    value={this.props.selectedCashPrice.buyer.companyName || ''}
                                    fullWidth
                                    disabled={this.props.viewSelectedCashPrice}
                                    variant="standard" />
                            </div> :
                            <div className="col-md-12 form-wrap">
                            <CommonAutoSelect
                                    items={companies || []}
                                    setRef={this.fieldRef["buyer.companyId"]}
                                    id="buyer.companyId"
                                    dataSourceConfig={{ text: 'name', value: 'id' }}
                                    label="Buyer"
                                    value={this.state.fields.buyer.companyId.value}
                                    errorText={get(this.state.fields.buyer.companyId, 'errors[0]')}
                                    onChange={this.handleValueChange}
                                    disabled={this.props.viewSelectedCashPrice}
                                />
                            </div>}
                        {this.props.viewSelectedCashPrice && this.props.selectedCashPrice.buyer.ngrNumber ?
                            <div className='col-md-12 form-wrap'>
                                <TextField
                                    id="buyer.ngrId"
                                    label="Buyer NGR"
                                    placeholder='Buyer NGR'
                                    value={this.props.selectedCashPrice.buyer.ngrNumber || ''}
                                    fullWidth
                                    disabled={this.props.viewSelectedCashPrice}
                                    variant="standard" />
                            </div> :
                            <div className="col-md-12 form-wrap">
                                <CommonAutoSelect
                                    items={this.state.ngrs || []}
                                    setRef={this.fieldRef["buyer.ngrId"]}
                                    id="buyer.ngrId"
                                    dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                                    label="Buyer NGR"
                                    value={this.state.fields.buyer.ngrId.value}
                                    errorText={get(this.state.fields.buyer.ngrId, 'errors[0]')}
                                    onChange={this.handleValueChange}
                                    disabled={this.props.viewSelectedCashPrice}
                                />
                            </div>}
                            <div className="col-md-12" style={{marginBottom: '20px'}}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            id="sustainableCommodity"
                                            checked={this.state.fields.sustainableCommodity.value}
                                            onChange={this.handleCheckboxClick}
                                            disabled={Boolean(this.props.viewSelectedCashPrice || this.props.editSelectedCashPrice || !isSustainableCommodity)}
                                        />
                                    }
                                    label="Sustainable Commodity"
                                />
                            </div>
                        <div className="col-md-12">
                            <div className="col-md-6 form-wrap no-left-padding">
                                <CommonDatePicker
                                    id="startDate"
                                    setRef={this.fieldRef["startDate"]}
                                    floatingLabelText="Start Date"
                                    onChange={this.handleValueChange}
                                    errorText={get(this.state.fields.startDate, 'errors[0]')}
                                    value={this.state.fields.startDate.value}
                                    style={{ float: 'right' }}
                                    disabled={true}
                                />
                            </div>
                            <div style={{ marginLeft: '0px' }} className="col-md-6 col-md-offset-2 form-wrap no-right-padding">
                                <CommonTimePicker
                                    id="startTime"
                                    setRef={this.fieldRef["startTime"]}
                                    floatingLabelText="Start Time"
                                    value={this.state.fields.startTime.value}
                                    onChange={this.handleValueChange}
                                    errorText={get(this.state.fields.startTime, 'errors[0]')}
                                    style={{ float: 'right' }}
                                    disabled={true}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="col-md-6 form-wrap no-left-padding">
                                <CommonDatePicker
                                    id="endDate"
                                    setRef={this.fieldRef["endDate"]}
                                    floatingLabelText="End Date"
                                    onChange={this.handleValueChange}
                                    errorText={get(this.state.fields.endDate, 'errors[0]')}
                                    disabled={this.props.viewSelectedCashPrice}
                                    value={this.state.fields.endDate.value}
                                    style={{ float: 'right' }}
                                    minDate={moment()}
                                />
                            </div>
                            <div style={{ marginLeft: '0px' }} className="col-md-6 col-md-offset-2 form-wrap no-right-padding">
                                <CommonTimePicker
                                    id="endTime"
                                    setRef={this.fieldRef["endTime"]}
                                    floatingLabelText="End Time"
                                    value={this.state.fields.endTime.value}
                                    onChange={this.handleValueChange}
                                    disabled={this.props.viewSelectedCashPrice}
                                    errorText={get(this.state.fields.endTime, 'errors[0]')}
                                    style={{ float: 'right' }}
                                />
                            </div>
                        </div>
                        <div className='col-sm-12 cardForm-action top15 padding-reset'>
                            <CommonButton type='button' variant='outlined' label='Close' default onClick={this.props.onClose} />
                            {!this.props.viewSelectedCashPrice &&
                                <CommonButton type='submit' primary variant='outlined' label='Save' default onClick={this.handleSubmit} />
                            }
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    var paymentTerms = [];
    var paymentScales = [];
    if (!isEmpty(state.companies.contracts.paymentTerms)) {
        paymentTerms = state.companies.contracts.paymentTerms;
    }
    if (!isEmpty(state.companies.contracts.paymentScales)) {
        paymentScales = map(state.companies.contracts.paymentScales, partialRight(pick, ['id', 'name']));
    }
    const tracks = state.main.tracks;
    return {
        token: state.main.user.token,
        varieties: state.master.varieties.items || [],
        currentUser: state.main.user.user,
        allCompanyParties: state.companies.companies.companyParties,
        paymentTerms: paymentTerms,
        paymentScales: paymentScales,
        tracks: tracks,
        selectedCashPrice: state.companies.cashBoard.selectedCashPrice,
        editSelectedCashPrice: state.companies.cashBoard.editSelectedCashPrice,
        viewSelectedCashPrice: state.companies.cashBoard.viewSelectedCashPrice,
        commodities: state.master.commodities.items,
    };
};

export default connect(mapStateToProps)(AddPriceDialog);
