import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { reject, isEqual } from 'lodash';
import APIService from '../../services/APIService';
import { setHeaderText, setBreadcrumbs, forceStopLoader } from '../../actions/main';
import '../common/subTab.scss';
import GenericTable from '../GenericTable';
import AddButton from '../common/AddButton';
import { isCompanyEditable, isTransactionParticipated, toDateFormat } from '../../common/utils';
import ShrinkageForm from './ShrinkageForm';
import alertifyjs from 'alertifyjs';

const SPECIAL_SHRINKAGE_COLUMNS = [
  {key: 'siteName', header: 'Site', className: 'xlarge'},
  {key: 'commodityName', header: 'Commodity', className: 'medium'},
  {key: 'customerName', header: 'Customer Name', className: 'xlarge'},
  {key: 'percentage', header: 'Shrinkage (in %)', className: 'small'},
  {key: 'startDate', header: 'Start Date', className: 'small', default: item => toDateFormat(new Date(item.startDate))},
  {key: 'endDate', header: 'End Date', className: 'small', default: item => item.endDate ? toDateFormat(new Date(item.endDate)) : '-'},
];

const STANDARD_SHRINKAGE_COLUMNS = [
  {key: 'siteName', header: 'Site', className: 'xlarge'},
  {key: 'commodityName', header: 'Commodity', className: 'large'},
  {key: 'percentage', header: 'Shrinkage (in %)', className: 'small'},
  {key: 'startDate', header: 'Start Date', className: 'small', default: item => toDateFormat(new Date(item.startDate))},
  {key: 'endDate', header: 'End Date', className: 'small', default: item => item.endDate ? toDateFormat(new Date(item.endDate)) : '-'},
];

const ACTION_OPTIONS = [
  {key: 'edit', text: 'Edit'},
  {key: 'delete', text: 'Delete'},
];

const STANDARD = 'standard';
const SPECIAL = 'special';

class CommonShrinkages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      selected: null,
    };
  }

  onCloseForm = refresh => {
    this.setState({openForm: false, selected: null}, () => {
      if(refresh)
        this.props.refreshData(this.props.type);
    });
  };

  onAdd = () => {
    this.setState({openForm: true});
  };

  onEditClick = item => {
    if (!item?.canEdit) {
      alertifyjs.alert(
        'Permission Denied',
        `<div><p>Cannot edit Shrinkages of past date or that has existing loads</p></div>`,
        () => {},
    );
    } else if(this.props.hasWriteAccess)
      this.setState({selected: item, openForm: true});
  };

  onOptionClick = (rowNum, key, shrinkageId, shrinkage) => {
    if(!shrinkageId)
      return;

    if(key === 'edit')
      this.onEditClick(shrinkage);

    if(key === 'delete') {
      if (!shrinkage?.canEdit) {
        alertifyjs.alert(
          'Permission Denied',
          `<div><p>Cannot delete Shrinkages of past date or that has existing loads</p></div>`,
          () => {},
        );
      } else
        this.props.onOptionClick(rowNum, key, shrinkageId, shrinkage);
    }
  };

  render() {
    const { openForm, selected } = this.state;
    const { data, columns, hasWriteAccess, type, companyId } = this.props;
    return (
      <div>
        {
          hasWriteAccess &&
          <AddButton label='Shrinkage' app='shrinkages' onClick={ this.onAdd } />
        }
        <GenericTable
          columns={columns}
          items={data}
          editColumnClass="xsmall"
          orderBy="siteName"
          optionsItems={hasWriteAccess ? ACTION_OPTIONS : undefined}
          handleOptionClick={this.onOptionClick}
          hasActions={hasWriteAccess}
        />
        {
          openForm &&
          <ShrinkageForm
            type={type}
            companyId={companyId}
            open={openForm}
            onClose={this.onCloseForm}
            selected={selected}
          />
        }
      </div>
    );
  }
}

class StandardShrinkages extends React.Component {
  render() {
    return (
      <CommonShrinkages {...this.props} columns={STANDARD_SHRINKAGE_COLUMNS} type={STANDARD} />
    );
  }
}

class SpecialShrinkages extends React.Component {
  render() {
    return (
      <CommonShrinkages {...this.props} columns={SPECIAL_SHRINKAGE_COLUMNS} type={SPECIAL} />
    );
  }
}

class Shrinkages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: STANDARD,
      standard: [],
      special: [],
      specialFetched: false,
      standardFetched: false,
    };
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    this.setHeaderAndBreadcrumbs();
    this.getShrinkageData();
  }

  setHeaderAndBreadcrumbs() {
    const company = this.getCompanyDetails();
    if(company) {
      const { dispatch } = this.props;
      dispatch(setHeaderText(company.name));
      dispatch(setBreadcrumbs(this.getBreadcrumbs()));
    }
  }

  getCompanyDetails() {
    const { companyId, user, selectedCompany } = this.props;
    if(user.companyId === companyId)
      return user.company;
    if(selectedCompany && companyId == selectedCompany.id)
      return selectedCompany;
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(this.getBreadcrumbs(), this.props.breadcrumbs))
      this.setHeaderAndBreadcrumbs();

    if(this.props.companyId !== prevProps.companyId)
      this.setState(
        {standard: [], special: [], specialFetched: false, standardFetched: false},
        this.getShrinkageData
      );
  }

  getShrinkageData() {
    const { companyId, dispatch } = this.props;
    const { tab } = this.state;
    if(!this.state[`${tab}Fetched`]) {
      APIService.farms().appendToUrl('shrinkages/')
                .get(null, null, {shrinkage_type: tab, site__company_id__in: companyId})
                .then(data => {
                  this.setState(
                    {[tab]: data, [`${tab}Fetched`]: true},
                    () => dispatch(forceStopLoader())
                  );
                });
    }
  }

  refreshData(type) {
    if(type == 'special')
      this.setState({special: [], specialFetched: false}, this.getShrinkageData);
    if(type == 'standard')
      this.setState({standard: [], standardFetched: false}, this.getShrinkageData);
  }

  deleteShrinkage(shrinkageId, isSpecial) {
    APIService.farms().appendToUrl(`shrinkages/${shrinkageId}/`).delete().then(() => {
      if(isSpecial)
        this.setState({special: reject(this.state.special, {id: shrinkageId})});
      else
        this.setState({standard: reject(this.state.standard, {id: shrinkageId})});
    });
  }

  getBreadcrumbs(company) {
    company = company || this.getCompanyDetails();
    if(company) {
      return [
        {text: 'Companies', route: '/companies'},
        {text: company.name, route: '/companies/' + company.id + '/details'},
        {text: 'Shrinkages'}
      ];
    }
  }

  onTabChanges = (event, value) => {
    this.setState({ tab: value === 0 ? STANDARD : SPECIAL }, this.getShrinkageData);
  };

  onOptionClick = (rowNum, key, shrinkageId, shrinkage) => {
    if(!shrinkageId)
      return;

    if(key === 'delete') {
      alertifyjs.confirm(
        'Confirm',
        `<div><p>Are you sure you want to delete this shrinkage?</p></div>`,
        () => this.deleteShrinkage(shrinkageId, Boolean(shrinkage.forCompanyId)),
        () => {}
      ).set('reverseButtons', true).set(
        'labels', {ok: 'Confirm', cancel: 'Cancel'}
      ).show()
    }
  };

  hasWriteAccess() {
    const { selectedCompany } = this.props;
    return !isTransactionParticipated(selectedCompany) || isCompanyEditable(selectedCompany)
  }


  render() {
    const { companyId } = this.props;
    const { tab, standard, special } = this.state;
    const hasWriteAccess = this.hasWriteAccess();
    return (
      <div className="subTab">
        <Tabs indicatorColor="primary" className="subTab-header" value={tab == STANDARD ? 0 : 1 } onChange={this.onTabChanges}>
          <Tab label="Standard" className={tab !== STANDARD ? 'unselected-subtab' : ''} />
          <Tab label="Special" className={tab !== SPECIAL ? 'unselected-subtab' : ''} />
        </Tabs>
        <div className="subTab-container">
          {
            tab === STANDARD &&
            <StandardShrinkages
              data={standard}
              onOptionClick={this.onOptionClick}
              hasWriteAccess={hasWriteAccess}
              companyId={companyId}
              refreshData={this.refreshData}
            />
          }
          {
            tab === SPECIAL &&
            <SpecialShrinkages
              data={special}
              onOptionClick={this.onOptionClick}
              hasWriteAccess={hasWriteAccess}
              companyId={companyId}
              refreshData={this.refreshData}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    selectedCompany: state.companies.companies.selectedCompany,
    breadcrumbs: state.main.breadcrumbs,
  };
};

export default connect(mapStateToProps)(Shrinkages);
