import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from "@mui/material/Button/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import CommonButton from "../common/CommonButton";

import ImageTile from "../common/ImageTile";
import GrowerCompany from "../common/icons/GrowerCompany";
import BrokerCompany from "../common/icons/BrokerCompany";
import LogisticsCompany from "../common/icons/LogisticsCompany";
import TraderCompany from "../common/icons/TraderCompany";
import {BROKER, GROWER, LOGISTIC, PRIMARY_COLOR_GREEN, TRADER, LIGHT_ACCENT_BLUE} from "../../common/constants";
import { getCountryLabel } from '../../common/utils';
import alertifyjs from "alertifyjs";
import APIService from "../../services/APIService";
import { loaded, forceStopLoader} from '../../actions/main';
import SignUpSuccess from "../profiles/SignUpSuccess";
import { transferCompanyAsset } from '../../actions/api/companies';
import { addCompanyNgr } from '../../actions/companies/company-ngrs';
import { AppContext } from '../main/LayoutContext';

const BUSINESS_TYPE_HEADER_1 = "AgriChain tailors your system features depending on your business type. " +
  "Select the business type below that most-closely aligns to your operation.";
const BUSINESS_TYPE_HEADER_2 = "If you run two operations under separate ABNs (i.e. grower and logistics) " +
      "you may need an AgriChain account for each business type.";
const SIGN_UP_COMPLETE_TEXT = "You have successfully signed up to the AgriChain platform. " +
      "Once the company admin has approved your request you will receive an email and be able to login";

const INACTIVE_COLOR = LIGHT_ACCENT_BLUE;


class BusinessType extends Component {
  static contextType = AppContext
  constructor(props){
    super(props);
    this.state = {
      typeId: undefined,
      isGrower: false,
      isBroker: false,
      isLogistics: false,
      isTrader: false,
      registerSuccess: false,
    };
  }

  componentDidMount() {
    this.copyValuesFromProps();
  }

  copyValuesFromProps = () => {
    this.activateTileFromTypeId(this.props.values.company.typeId);
  };

  activateTileFromTypeId = (typeId) => {
    const newState = {...this.state};
    newState.isGrower = false;
    newState.isBroker = false;
    newState.isLogistics = false;
    newState.isTrader = false;

    switch (typeId) {
      case GROWER:
        newState.isGrower = true;
        break;
      case BROKER:
        newState.isBroker = true;
        break;
      case LOGISTIC:
        newState.isLogistics = true;
        break;
      case TRADER:
        newState.isTrader = true;
        break;
    }
    newState.typeId = typeId;
    this.setState(newState, () => {this.props.submit(this.getSubmitData());});
  };

  getSubmitData = () => {
    const data = {...this.props.values, company: {...this.props.values.company, isSystemOwned: true, typeId: this.state.typeId}};
    delete data['confirmPassword'];
    delete data['isChecked'];
    return data
  };

  createNgrInCompany = companyId => {
    let ngrData = {
      "bankAccounts": [{
        "accountName": "",
        "accountNumber": "",
        "bsbNumber": "",
        "companyId": "",
        "isPrimary": true,
        "shareholderPercent": 100
      }],
      "isVerified": false,
      "ngrType": "single",
      "ngrNumber": this.props.ngrNumber,
      "shareAuthorityFile": [],
      "shareAuthorityUrl": []
    }
    APIService.companies(companyId)
      .ngrs()
      .post(ngrData)
      .then(item => {
        if (item?.id == null)
          this.props.dispatch(forceStopLoader());
        else {
          this.props.dispatch(addCompanyNgr(item));
          alertifyjs.success("NGR was successfully added.");
          this.props.dispatch(loaded());
        }
      });
      this.props.dispatch(addCompanyNgr(companyId, {ngr_number: this.props.ngrNumber}));
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const typeId = this.state.typeId;
    if (typeId){
      await this.registerEmployee(this.getSubmitData(), (companyId) => {
        if (this.props.transferNgrId && companyId)
          this.props.dispatch(transferCompanyAsset(companyId, {asset_id: this.props.transferNgrId, asset: 'ngr', include_parties: false}, false));
        else if (this.props.shouldCreateNgr && companyId && this.props.ngrNumber)
          this.createNgrInCompany(companyId)
      });
    } else {
      alertifyjs.alert("Business Type Selection", "Please select a Business Type.", () => {});
    }
  };

  registerEmployee = (data, callback=null)  => {
    return new Promise(resolve => {
      const profileAPI = APIService.profiles();
      profileAPI.appendToUrl('register');

      profileAPI.post(data).then(response => {
        this.props.dispatch(loaded());
        if(response.id){
          this.setState({registerSuccess: true}, () => {
            if (callback)
              callback(response?.company?.id);
            this.props.nextStep();
          });
        } else {
          if(response.errors?.businessName[0]) {
            alertifyjs.alert(
              'Warning',
              `<div>
                <p>The company you are trying to create has the same business name as an existing AgriChain user</pf>
                <p>Please contact <a href="mailto:support@agrichain.com" target='_blank' rel="noopener noreferer">support@agrichain.com</a> to sign up.</p>
              </div>`,
              () => {
                window.location.reload();
              })
          }
          else
            alertifyjs.error("Something went wrong!");
        }
        resolve();
      });
    });
  };


  navigateToLogin = () => {
    if(this.state.registerSuccess){
      window.location = '#';
    }
  };

  render () {
    const { isMobileDevice } = this.context
    return (
      <div>
        {
          this.state.registerSuccess ?
            <div id='registerSuccess'>
              <SignUpSuccess onClick={this.navigateToLogin} headerText="Sign Up Complete" contentText={SIGN_UP_COMPLETE_TEXT}/>
          </div> :
          <div className="sign-up-form-height">
          <div className='col-md-12 left-text-align tailor-margin'>
            <span className='tailors-text'>{BUSINESS_TYPE_HEADER_1}</span>
          </div>
          <div className='col-md-12 left-text-align tailor-margin'>
            <span className='tailors-text'>{BUSINESS_TYPE_HEADER_2.replace('ABN', getCountryLabel('abn'))}</span>
          </div>

            <div className={"col-md-12" + isMobileDevice ? '' : ' row'} style={isMobileDevice ? {margin: '22px 0 0 0'} : {}}>
            <div className='col-sm-3'>
              <div onClick={() => this.activateTileFromTypeId(GROWER)}>
                <ImageTile isActive={this.state.isGrower} inActiveColor={INACTIVE_COLOR} label={'Grower'}>
                  <GrowerCompany fill={this.state.isGrower ? PRIMARY_COLOR_GREEN : INACTIVE_COLOR}/>
                </ImageTile>
              </div>
              <div className='center-text-align margin-top-20' style={{fontSize: '13px'}}>
                <span className='bullet-points-heading'>This account type can manage</span>
              </div>
              <div className='left-text-align bullet_points'>
                <ul>
                  <li>Multiple farms, fields and employees</li>
                  <li>Commodity contracts</li>
                  <li>Freight contracts</li>
                  <li>Stocks, storages, inloading and outloading</li>
                  <li>Farm trucks and logistics operations</li>
                  <li>A link to your broker</li>
                </ul>
              </div>
            </div>
            <div className='col-sm-3'>
              <div onClick={() => this.activateTileFromTypeId(BROKER)}>
                <ImageTile isActive={this.state.isBroker} inActiveColor={INACTIVE_COLOR} label={'Broker'}>
                  <BrokerCompany fill={this.state.isBroker ? PRIMARY_COLOR_GREEN : INACTIVE_COLOR}/>
                </ImageTile>
              </div>
              <div className='center-text-align margin-top-20' style={{fontSize: '13px'}}>
                <span className='bullet-points-heading'>This account type can manage</span>
              </div>
              <div className='left-text-align bullet_points'>
                <ul>
                  <li>Links to multiple farms and growers</li>
                  <li>Your grower’s stocks, storages, inloading and outloading</li>
                  <li>Commodity contracts</li>
                  <li>Freight contracts</li>
                  <li>Logistics operations</li>
                </ul>
              </div>
            </div>
            <div className='col-sm-3'>
              <div onClick={() => this.activateTileFromTypeId(LOGISTIC)}>
                <ImageTile isActive={this.state.isLogistics} inActiveColor={INACTIVE_COLOR} label={'Logistics'}>
                  <LogisticsCompany fill={this.state.isLogistics ? PRIMARY_COLOR_GREEN : INACTIVE_COLOR}/>
                </ImageTile>
              </div>
              <div className='center-text-align margin-top-20' style={{fontSize: '13px'}}>
                <span className='bullet-points-heading'>This account type can manage</span>
              </div>
              <div className='left-text-align bullet_points'>
                <ul>
                  <li>Freight contracts</li>
                  <li>Logistics operations</li>
                  <li>Inloading and outloading</li>
                </ul>
              </div>
            </div>
            <div className='col-sm-3'>
              <div onClick={() => this.activateTileFromTypeId(TRADER)}>
                <ImageTile isActive={this.state.isTrader} inActiveColor={INACTIVE_COLOR} label={'Trader'}>
                  <TraderCompany fill={this.state.isTrader ? PRIMARY_COLOR_GREEN : INACTIVE_COLOR}/>
                </ImageTile>
              </div>
              <div className='center-text-align margin-top-20' style={{fontSize: '13px'}}>
                <span className='bullet-points-heading'>This account type can manage</span>
              </div>
              <div className='left-text-align bullet_points'>
                <ul>
                  <li>Multiple Sites</li>
                  <li>Commodity contracts</li>
                  <li>Freight contracts</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="signup-content-box--footer col-sm-12">
            <div className='col-sm-6 left-text-align padding-reset fixed-bottom' style={{zIndex: '100'}}>
              <Button
                variant="text"
                type="button"
                color="primary"
                style={{color: 'rgb(0, 0, 0)'}}
                onClick={this.props.previousStep}
              >
                <KeyboardArrowLeft style={{padding: '1px 0px'}}/>Back
              </Button>
            </div>
            <div className='col-sm-6 padding-reset fixed-bottom' style={{'textAlign': 'right', width: '97%'}}>
              <CommonButton
                type="submit"
                label="Finish"
                variant="contained"
                primary={true}
                className="login-button"
                onClick={this.handleSubmit}
                style={{width: '30%'}}
              />
            </div>
          </div>
        </div>
        }
      </div>
    );
  }
}

export default connect()(BusinessType);
