import React from 'react';

import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { EMPTY_VALUE, FREIGHT_CONTRACT_TYPE, PACK_ORDER_TYPE_ID } from '../../../common/constants';
import Tooltip from '../../../common/Tooltip';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { isStaff } from '../../../common/utils';
import CompanyAssignmentDialog from '../MovementDetails/CompanyAssignmentDialog';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return <KeyboardArrowUp style={{ fill: '#112c42', height: '20px', width: '20px' }} />;
  }
  return <KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} />;
};

class OrderCounterParties extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      open: false,
    };
  }

  toggleExpanded = () => this.setState(prevState => ({ expanded: !prevState.expanded }));

  handleCustomerUpdateOpen = () => this.setState({open: true});

  handleDialogClose = () => this.setState({open: false});

  getCounterPartiesSectionAsPerGrid = (payload, sectionName, isEditable=true) => {
    const parentOrderId = get(this.props.order, 'parentOrderId');
    const parentOrderIdentifier = get(this.props.order, 'parentOrder.identifier', EMPTY_VALUE);
    const canViewParentConditions = get(this.props.order, 'canViewParentConditions');
    return (
      <div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h4 className='section-title'>{sectionName}</h4>
        {
          isEditable &&
            <IconButton size='small' sx={{marginLeft: '15px'}} color='secondary' onClick={this.handleCustomerUpdateOpen}>
              <Edit fontSize="inherit"/>
            </IconButton>
        }
      </div>
        <ul>
          {Object.keys(payload).map(function(key, index) {
            return (
              <li key={index}>
                <Tooltip className='field-label ellipses' tooltipText={key} textContent={key} />
                {<Tooltip className='field-value ellipses' tooltipText={payload[key] || EMPTY_VALUE} textContent={payload[key] || EMPTY_VALUE} />}
              </li>
            );
          })}
          {parentOrderId && includes(['Assignor', 'Assignor (Customer)'], sectionName) && (
            <li>
              <Tooltip className='field-label ellipses' tooltipText={'Parent Order'} textContent={'Parent Order'} />
              {canViewParentConditions ?
              <a href={`/#/freights/orders/${parentOrderId}/order`} target='_blank' rel='noopener noreferrer'>
                <Tooltip className='field-value ellipses' tooltipText={parentOrderIdentifier} textContent={parentOrderIdentifier} />
              </a> :
                <Tooltip className='field-value ellipses' tooltipText={parentOrderIdentifier} textContent={parentOrderIdentifier} />
              }
            </li>
          )}
        </ul>
      </div>
    );
  };

  isCustomer = (deliveryOnus, party) => deliveryOnus && deliveryOnus.toLowerCase() === party;

  setUpParties = () => {
    const { order, isRequestOrder } = this.props;
    const parties = [];

    const isCallOnGrain = get(order, 'typeId') === FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN;
    const isPackOrder = get(order, 'typeId') === PACK_ORDER_TYPE_ID;
    let deliveryOnus = '';
    if (get(order, 'level') > 0) {
      if (get(order, 'parentOrder.id') > 0 && get(order, 'parentOrder.typeId') === FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN) {
        deliveryOnus = get(order, 'commodityContract.deliveryOnus', '');
      }
    } else {
      deliveryOnus = get(order, 'commodityContract.deliveryOnus', '');
    }

    if (!isCallOnGrain && (!isRequestOrder || (isRequestOrder && get(order, 'providerId')))) {
      parties.push({
        sectionName: isPackOrder ? 'Packing Provider' : 'Freight Provider',
        payload: {
          Name: get(order, 'provider.businessName', EMPTY_VALUE),
          Entity: get(order, 'provider.entityName'),
          Contact: get(order, 'assignTo.name', EMPTY_VALUE),
        },
      });
    }

    const customerPayload = {
      Name: get(order, 'customer.company.displayName', EMPTY_VALUE),
      Entity: get(order, 'customer.company.entityName'),
      NGR: get(order, 'customer.ngr.ngrNumber'),
      Contact: get(order, 'customer.contact.name', EMPTY_VALUE),
    };

    if (order && (!order.commodityContractId)) {
      parties.push({
        sectionName: 'Customer',
        payload: customerPayload,
      });
    }
    if (get(order, 'externalReferenceNumber') || get(order, 'parentOrder.externalReferenceNumber')) {
      parties.pop();
    }
    const sellerPayload = order &&
      ((order.commodityContract &&
      order.commodityContract.seller) || order.seller) && {
        Name: get(order, 'seller.company.displayName') ||  get(order, 'commodityContract.seller.company.displayName', EMPTY_VALUE),
        Entity: get(order, 'seller.company.entityName') || get(order, 'commodityContract.seller.company.entityName'),
        NGR: get(order, 'seller.ngr.ngrNumber') || get(order, 'commodityContract.seller.ngr.ngrNumber'),
        Contact: get(order, 'seller.contact.name') || get(order, 'commodityContract.seller.contact.name', EMPTY_VALUE),
      };

    const buyerPayload = order &&
      ((order.commodityContract &&
      order.commodityContract.buyer) || order.buyer ) && {
        Name: get(order, 'buyer.company.displayName') || get(order, 'commodityContract.buyer.company.displayName', EMPTY_VALUE),
        Entity: get(order, 'buyer.company.entityName') || get(order, 'commodityContract.buyer.company.entityName'),
        NGR: get(order, 'buyer.ngr.ngrNumber') ||  get(order, 'commodityContract.buyer.ngr.ngrNumber'),
        Contact: get(order, 'buyer.contact.name') || get(order, 'commodityContract.buyer.contact.name', EMPTY_VALUE),
      };
    const isBuyerCustomer = this.isCustomer(deliveryOnus, 'buyer') || get(order, 'buyer.companyId') === get(order, 'customer.companyId')
    const isSellerCustomer = this.isCustomer(deliveryOnus, 'seller') || get(order, 'seller.companyId') === get(order, 'customer.companyId')
    const sellerPartyLabel = order.canViewParties ? (isSellerCustomer ? 'Seller (Customer)' : 'Seller') : (isSellerCustomer ? 'Customer' : null)
    const buyerPartyLabel = order.canViewParties ? (isBuyerCustomer ? 'Buyer (Customer)' : 'Buyer') : (isBuyerCustomer ? 'Customer' : null)

    if (sellerPayload && sellerPartyLabel) {
      parties.push({
        sectionName: sellerPartyLabel,
        payload: sellerPayload,
      });
    }

    if (buyerPayload && buyerPartyLabel) {
      parties.push({
        sectionName: buyerPartyLabel,
        payload: buyerPayload,
      });
    }

    parties.push({
      sectionName: 'Assignor',
      payload: {
        Name: get(order, 'createdBy.company.businessName', EMPTY_VALUE),
        Entity: get(order, 'createdBy.company.entityName'),
        Contact: get(order, 'createdBy.name', EMPTY_VALUE),
      },
    });

    return parties;
  };

  render() {
    const { expanded } = this.state;
    const parties = this.setUpParties();

    return (
      <Paper className='order-details-section-container'>
        <h2 onClick={this.toggleExpanded}>
          Counterparties
          <span className='expand-icon'>{renderBasedOnExpandedFlag(expanded)}</span>
        </h2>
        {expanded && (
          <div className='section-details-container'>
            {map(parties, party => (
              <React.Fragment key={party.sectionName}>{this.getCounterPartiesSectionAsPerGrid(party.payload, party.sectionName, party.sectionName === 'Customer' && isStaff())}</React.Fragment>
            ))}
          </div>
        )}
        {
          this.state.open &&
          <CompanyAssignmentDialog
            onClose={this.handleDialogClose}
            open={this.state.open}
            orderId={get(this.props.order, 'id')}
            entityField='customerCompanyId'
            entityName='Customer'
            isOrderCustomer
          />
        }
      </Paper>
    );
  }
}

export default OrderCounterParties;
