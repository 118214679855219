import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { DialogTitleWithCloseIcon } from '../../common/DialogTitleWithCloseIcon';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CommonSelect from '../../common/select/CommonSelect';
import CommonDatePicker from '../../common/CommonDatePicker';
import CommonTimePicker from '../../common/CommonTimePicker';
import Typography from '@mui/material/Typography';
import SiteConnector from './SiteConnector';
import CommonTextField from '../../common/CommonTextField';
import APIService from '../../../services/APIService';
import { includes, forEach, get, set, has, uniqBy, find, every, orderBy, filter, map, cloneDeep, some } from 'lodash';
import PropTypes from 'prop-types';
import {
  SLOT_PLANNED, SLOT_BOOKED, SLOT_COMPLETED, SLOT_CANCELLED, SLOT_IN_PROGRESS, PRIMARY_COLOR_GREEN, BROWN, SLOT_DELAYED, CORNFLOWER_BLUE, MAUVE
} from '../../../common/constants';
import { isLoading } from "../../../actions/main";
import { raiseMovementAmendRequest } from '../../../actions/companies/freights';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { parseDateTime } from '../utils';
import { getCountryConfig, getCountryFormats, toDateTimeFormat, toPhoneFormat } from '../../../common/utils';
import alertify from 'alertifyjs';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { applyValidatorsOn, required } from '../../../common/validators';
import { IconButton } from '@mui/material';
import PinDropIcon from '@mui/icons-material/PinDrop';
import UpdateLocation from '../../locations/UpdateLocation';
import Create from '@mui/icons-material/Create';

const BORDER_COLORS = {
  planned: SLOT_PLANNED,
  confirmed: BROWN,
  open: SLOT_BOOKED,
  delayed: SLOT_DELAYED,
  in_progress: SLOT_IN_PROGRESS,
  'void': SLOT_CANCELLED,
  'rejected': SLOT_CANCELLED,
  delivered: CORNFLOWER_BLUE,
  completed: SLOT_COMPLETED,
  unsaved: 'rgba(0, 0, 0, 0.3)',
  invoiced: MAUVE
};


class MovementDetailsDialog extends Component {
  constructor(props) {
    super(props);
    let { allOrders, movement, movementTrucks, currentTruck, allTrucks } = props;
    movementTrucks = uniqBy(movementTrucks, 'id');
    let selectedOrder = find(allOrders, { id: movement?.orderId });
    let isUnsavedMovement = false;
    if(isEqual(movement?.status, 'unsaved')) {
      isUnsavedMovement = true;
    }
    this.state = {
      countryFormats: getCountryFormats(),
      isDialogOpen: true,
      today: new Date(),
      trucks: movementTrucks,
      currentTruck: currentTruck,
      allTrucks: allTrucks,
      allDrivers: [],
      draftUnaccountedTonnage: selectedOrder?.draftUnaccountedTonnage,
      currentTruckCapacity: currentTruck.grossWeight - currentTruck.tareWeight,
      movement: movement,
      isEditable: true,
      newOrderDraft: 0,
      currentOrder: selectedOrder,
      isUnsavedMovement: isUnsavedMovement,
      order: selectedOrder?.identifier,
      editableStatuses: [
        'planned',
        'confirmed'
      ],
      plannedTonnage: {
        value: movement?.plannedTonnage,
        error: ''
      },
      minimumTonnageRequired: 0.1,
      recommendedDeliverySlotText: [''],
      plannedTruckId: {
        value: movement?.plannedTruckId,
        errors: [],
        validators: [],
      },
      openConsignorFarmForm: false,
      openConsigneeFarmForm: false,
      driverId: {
        value: movement?.driverId,
        errors: [],
        validators: selectedOrder?.isPickupSiteSlotOrderBookingOn || selectedOrder?.isDeliverySiteSlotOrderBookingOn ? [required()] : [],
      },
      estimatedTimeInSeconds: 0,
      inloadSlotId: {
        value: movement?.inloadSlotId || undefined,
        errors: [],
        validators: [],
      },
      outloadSlotId: {
        value: movement?.outloadSlotId || undefined,
        errors: [],
        validators: [],
      },
      freightPickup: {
        pickupOrder: '',
        instructions: movement?.pickupInstructions || selectedOrder?.pickupInstructions,
        pickupAddress: selectedOrder?.consignorAddress.name,
        pickupSiteMobile: selectedOrder?.consignorHandlerMobile,
        date: '',
        timeStart: '',
        isTimeChanged: false,
        pickupShowTime: '',
        outloadDateTime: '',
        consignorName: movement?.consignorName,
        consignorId: movement?.consignorId,
        consignorHandlerId: movement?.consignorHandlerId,
        outloadGrade: '',
        outloadSeason: '',
        bookingNumber: movement?.pickupBookingNumber,
        isTimeValid: true
      },
      freightDelivery: {
        deliveryOrder: '',
        instructions: movement?.deliveryInstructions || selectedOrder?.deliveryInstructions,
        deliveryAddress: selectedOrder?.consigneeAddress.name,
        deliverySiteMobile: selectedOrder?.consigneeHandlerMobile,
        isTimeChanged: false,
        date: '',
        timeStart: '',
        deliveryShowTime: '',
        inloadDateTime: '',
        consigneeName: movement?.consigneeName,
        consigneeId: movement?.consigneeId,
        consigneeHandlerId: movement?.consigneeHandlerId,
        inloadGrade: '',
        inloadSeason: '',
        bookingNumber: movement?.deliveryBookingNumber,
        isTimeValid: true
      },
    };

    this.editMovement = null;

    this.closePopup = this.closePopup.bind(this);
    this.fetchRequiredSlots = this.fetchRequiredSlots.bind(this);
    this.updateMovement = this.updateMovement.bind(this);
    this.handleDeliveryChange = this.handleDeliveryChange.bind(this);
    this.handlePickupChange = this.handlePickupChange.bind(this);
    this.handleTonnageChange = this.handleTonnageChange.bind(this);
    this.validateTonnage = this.validateTonnage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setUpMovementDatetimeToUTC = this.setUpMovementDatetimeToUTC.bind(this);
    this.getPickupMinimumDate = this.getPickupMinimumDate.bind(this);
    this.saveForLater = this.saveForLater.bind(this);
    this.handleSlotChange = this.handleSlotChange.bind(this);
    this.setDistance = this.setDistance.bind(this);
    this.setDistanceAndTimeState = this.setDistanceAndTimeState.bind(this);
    this.resetDistanceAndTimeState = this.resetDistanceAndTimeState.bind(this);
    this.getRecommendedDeliverySlotText = this.getRecommendedDeliverySlotText.bind(this);
    this.fetchDistance = this.fetchDistance.bind(this);
    this.getIsFormValid = this.getIsFormValid.bind(this);
    this.raiseAmendRequest = this.props.raiseAmendRequest;
    this.isLoading = this.props.isLoading;

  }

  componentDidMount() {
    this.setCompanyDrivers();
    this.setMinimumTonnage();
    if(! this.state.isUnsavedMovement) {
      if (!includes(this.state.editableStatuses, this.state.movement.status))
      this.setState({ isEditable: false });
      this.fetchMovementDetails();
    } else {
      let newState = { ...this.state };
      newState.freightPickup.date = this.state.movement.startDate;
      newState.freightDelivery.date = this.state.movement.endDate;
      if (!find(this.state.allTrucks, {id: this.state.plannedTruckId.value})?.isActive)
        newState.plannedTruckId.errors = ["This truck has been archived. Please contact your Company Admin for more information."];
      if (get(this.state.movement, 'startTime')){
        newState.freightPickup.timeStart = moment(this.state.movement.startTime, 'h:mm A').format('HH:mm:ss');
      }
      if (get(this.state.movement, 'endTime')){
        newState.freightDelivery.timeStart = moment(this.state.movement.endTime, 'h:mm A').format('HH:mm:ss');
      }
      this.setState(newState, ()=> {
        this.fetchDistance().then(this.fetchRequiredSlots);
      });
    }
  }

  fetchRequiredSlots() {
    if (this.state.currentOrder?.isPickupSiteSlotOrderBookingOn)
      this.fetchSlots('freightPickup.consignorHandlerId', 'outload');
    if (this.state.currentOrder?.isDeliverySiteSlotOrderBookingOn)
      this.fetchSlots('freightDelivery.consigneeHandlerId', 'inload');
  }

  fetchMovementDetails = () => {
    APIService.freights().appendToUrl(`scheduler/movements/${this.state.movement.id}/`)
              .get()
              .then(movementDetails => {
                this.editMovement = movementDetails;
                if (movementDetails.pickupDateTime) {
                  let pickupDate = parseDateTime('date', movementDetails.pickupDateTime);
                  let pickupTime = '';
                  if (movementDetails.pickupShowTime) {
                    pickupTime = parseDateTime('time', movementDetails.pickupDateTime);
                    pickupTime = pickupTime.substring(0, pickupTime.length - 1);
                  }

                  this.setState(state => ({
                    ...state,
                    freightPickup: {
                      ...state.freightPickup,
                      date: pickupDate,
                      timeStart: pickupTime
                    }
                  }));
                };
                if (movementDetails.deliveryDateTime) {
                  let deliveryDate = parseDateTime('date', movementDetails.deliveryDateTime);
                  let deliveryTime = '';
                  if (movementDetails.deliveryShowTime) {
                    deliveryTime = parseDateTime('time', movementDetails.deliveryDateTime);
                    deliveryTime = deliveryTime.substring(0, deliveryTime.length - 1);
                  }
                  this.setState(state => ({
                    ...state,
                    freightDelivery: {
                      ...state.freightDelivery,
                      date: deliveryDate,
                      timeStart: deliveryTime,
                    }
                  }));
                } else if (movementDetails.pickupDateTime && (! movementDetails.deliveryDateTime)) {
                    this.setState(state => ({
                      ...state,
                      freightDelivery: {
                        ...state.freightDelivery,
                        date: parseDateTime('date', movementDetails.pickupDateTime)
                      }
                    }));
                  }

                this.setState(state => ({
                  ...state,
                  freightPickup: {
                    ...state.freightPickup,
                    pickupOrder: movementDetails.pickupOrderNumber,
                    instructions: movementDetails.pickupInstructions,
                    pickupAddress: movementDetails.pickupAddress,
                    pickupShowTime: movementDetails.pickupShowTime,
                    outloadDateTime: movementDetails.outloadDateTime,
                    outloadGrade: movementDetails.outloadGrade,
                    outloadSeason: movementDetails.outloadSeason,
                    bookingNumber: movementDetails.pickupBookingNumber,
                    consignorHandlerId: movementDetails.consignorHandlerId,
                    consignorId: movementDetails.consignorId,
                  },
                  freightDelivery: {
                    ...state.freightDelivery,
                    deliveryOrder: movementDetails.deliveryOrderNumber,
                    instructions: movementDetails.deliveryInstructions,
                    deliveryAddress: movementDetails.deliveryAddress,
                    deliveryShowTime: movementDetails.deliveryShowTime,
                    inloadDateTime: movementDetails.inloadDateTime,
                    inloadGrade: movementDetails.inloadGrade,
                    inloadSeason: movementDetails.inloadSeason,
                    bookingNumber: movementDetails.deliveryBookingNumber,
                    consigneeHandlerId: movementDetails.consigneeHandlerId,
                    consigneeId: movementDetails.consigneeId,
                  },
                  inloadSlotId: {
                    ...state.inloadSlotId,
                    value: movementDetails.inloadSlotId
                  },
                  outloadSlotId: {
                    ...state.outloadSlotId,
                    value: movementDetails.outloadSlotId
                  },
                  driverId: {
                    ...state.driverId,
                    value: movementDetails.driverId
                  }
                }), () => {
                  this.fetchRequiredSlots();
                  this.fetchDistance();
                });
              });
  }

  closePopup = () => {
    this.setState({ isDialogOpen: false });
    this.props.handleClosePopUp();
  }

  setCompanyDrivers() {
    const providerId = find(this.state.allTrucks, { id: this.state.plannedTruckId.value })?.companyId
    if(providerId)
      APIService.companies(providerId)
        .appendToUrl('employees/minimal/')
        .get()
        .then(drivers => this.setState({allDrivers: drivers}));
  }

  async setMinimumTonnage() {
    const { currentOrder, freightPickup, freightDelivery } = this.state;
    let minimumTonnageRequired = 0.1;
    const outloadSiteId = freightPickup.consignorId || get(currentOrder, 'freightPickup.consignorId');
    const inloadSiteId = freightDelivery.consigneeId || get(currentOrder, 'freightDelivery.consigneeId');
    const outloadResponse = outloadSiteId && currentOrder?.isPickupSiteSlotOrderBookingOn
      ? await APIService.companies(outloadSiteId)
          .appendToUrl('site-management/settings/')
          .get(this.props.userToken)
      : null;
    const inloadResponse = inloadSiteId && currentOrder?.isDeliverySiteSlotOrderBookingOn
      ? await APIService.companies(inloadSiteId)
          .appendToUrl('site-management/settings/')
          .get(this.props.userToken)
      : null;
    if (outloadResponse !== null) {
      minimumTonnageRequired = Math.max(minimumTonnageRequired, outloadResponse.minimumTonnage || 0);
    }
    if (inloadResponse !== null) {
      minimumTonnageRequired = Math.max(minimumTonnageRequired, inloadResponse.minimumTonnage || 0);
    }
    if (this.state.draftUnaccountedTonnage < minimumTonnageRequired) {
      minimumTonnageRequired = this.state.draftUnaccountedTonnage;
    }
    this.setState({ minimumTonnageRequired: minimumTonnageRequired });
  }

  handleChange = (field = '', event) => {
    if (isEqual(field, 'plannedTruckId')) {
      let newTruckDetails = find(this.state.allTrucks, { id: event });
      const updatedDetails = this.props.updateTruckTonnage(this.state.movement, get(newTruckDetails, 'id'));
      this.setState(state => ({
        ...state,
        plannedTruckId: {
          ...state.plannedTruckId,
          value: event,
          errors: newTruckDetails?.isActive ? [] : ["This truck has been archived. Please contact your Company Admin for more information."]
        },
        driverId: {
          ...state.driverId,
          value: ''
        },
        draftUnaccountedTonnage: updatedDetails.draftUnaccountedTonnage,
        plannedTonnage: {
          ...state.plannedTonnage,
          value: updatedDetails.newTonnage,
          error: ''
        },
      }), ()=> {
          this.validateTonnage(updatedDetails.newTonnage);
          this.setCompanyDrivers();
      });
    } else if (isEqual(field, 'pickupBooking')) {
      this.setState(state => ({
        ...state,
        freightPickup: {
          ...state.freightPickup,
          bookingNumber: event.target.value,
        },
      }));
    } else if (isEqual(field, 'deliveryBooking')) {
      this.setState(state => ({
        ...state,
        freightDelivery: {
          ...state.freightDelivery,
          bookingNumber: event.target.value,
        },
      }));
    } else if (isEqual(field, 'driverId')) {
      this.setState(state => ({
        ...state,
        driverId: {
          ...state.driverId,
          value: event,
        },
      }));
    }
  }

  handlePickupChange = (field, event) => {
    const { freightPickup, freightDelivery, outloadSlotId, inloadSlotId } = this.state;
    let isTimeValid = freightPickup.isTimeValid;
    if (event) {
      if (event.target) {
        event = event.target.value;
      }
      if(isEqual(event, 'Invalid date') && isEqual(field, 'timeStart')) {
        isTimeValid = false;
      } else if(! isEqual(event, 'Invalid date') && isEqual(field, 'timeStart')) {
        isTimeValid = true;
      }
      if(event === 'Invalid date' || event === 'Invalid time') {
        event = '';
        if(field === 'timeStart')
          freightPickup.showTime = false;
      }

      freightPickup[field] = event;
      if (field === 'timeStart') {
        freightPickup.isTimeChanged = true;
        freightPickup.isTimeValid = isTimeValid;
      }
      if(isEqual(field, 'date') && isEmpty(freightDelivery.date)) {
          freightDelivery.date = event;
          inloadSlotId.value = '';
      } else if(isEqual(field, 'date') && (moment(freightDelivery.date).isBefore(moment(freightPickup.date)))) {
          freightDelivery.date = event;
          inloadSlotId.value = '';
      }
      if(isEqual(field, 'date'))
        outloadSlotId.value = ''
      this.setState(state => ({
        ...state,
        freightPickup,
        outloadSlotId,
        inloadSlotId
      }), ()=> {
        if(field === 'date')
          this.fetchDistance().then(this.fetchRequiredSlots);
    });
    }
  }

  handleDeliveryChange = (field, event) => {
    const { freightDelivery, inloadSlotId } = this.state;
    let isTimeValid = freightDelivery.isTimeValid;
    if (event) {
      if (event.target) {
        event = event.target.value;
      }
      if(isEqual(event, 'Invalid date') && isEqual(field, 'timeStart')) {
        isTimeValid = false;
      } else if(! isEqual(event, 'Invalid date') && isEqual(field, 'timeStart')) {
        isTimeValid = true;
      }
      if(event === 'Invalid date' || event === 'Invalid time') {
        event = ''
        if(field === 'timeStart')
          freightDelivery.showTime = false
      }
      freightDelivery[field] = event;
      if (field === 'timeStart') {
        freightDelivery.isTimeChanged = true;
        freightDelivery.isTimeValid = isTimeValid;
      }
      if (field === 'date')
        inloadSlotId.value = ''
      this.setState(state => ({
        ...state,
        freightDelivery,
        inloadSlotId
      }), () => {
        if(field === 'date')
          this.fetchSlots('freightDelivery.consigneeHandlerId', 'inload');
      });
    }
  }

  handleTonnageChange = (event) => {
    let updatedTonnage = parseInt(event.target.value);
    if (this.validateTonnage(updatedTonnage)) {
      this.setState(state => ({
        ...state,
        plannedTonnage: {
          ...state.plannedTonnage,
          value: updatedTonnage,
          error: ''
        }
      }));
    }
  }

  validateTonnage = (newTonnage) => {
    if (newTonnage !== 0 && !newTonnage) {
      this.setState(state => ({
        ...state,
        plannedTonnage: {
          ...state.plannedTonnage,
          error: 'Tonnage is required'
        }
      }));
    }
    else if (newTonnage > (this.state.draftUnaccountedTonnage + this.state.movement.plannedTonnage)) {
      this.setState(state => ({
        ...state,
        plannedTonnage: {
          ...state.plannedTonnage,
          error: 'Tonnage is exceeding the draft tonnage'
        }
      }));
    } else if (newTonnage === 0) {
      this.setState(state => ({
        ...state,
        plannedTonnage: {
          ...state.plannedTonnage,
          error: `Tonnage can't be 0`
        }
      }));
    } else if (newTonnage < this.state.minimumTonnageRequired){
      this.setState(state => ({
        ...state,
        plannedTonnage: {
          ...state.plannedTonnage,
          error: `Tonnage can't be less than minimum tonnage required ${this.state.minimumTonnageRequired}`
        }
      }));
    } else {
      return true;
    }
  }

  timeStampToDateTime(dateTime) {
    let utcDateTime = moment(dateTime).utc().format('YYYY-MM-DD HH:mm:ss');
    let tmsx = moment(utcDateTime).format("X");
    let formattedDateTime = moment.unix(tmsx).format('YYYY-MM-DDTHH:mm:ss');
    return formattedDateTime;
  }

  async fetchDistance() {
    let consignorAddress = this.state.currentOrder.consignorAddress;
    let consigneeAddress = this.state.currentOrder.consigneeAddress;
    if (consignorAddress && consigneeAddress) {
      const consignorLat = consignorAddress.latitude;
      const consigneeLat = consigneeAddress.latitude;
      const consignorLng = consignorAddress.longitude;
      const consigneeLng = consigneeAddress.longitude;
      if (consignorLat && consigneeLat && consignorLng && consigneeLng) {
        let data = {
          'origin_latitude': consignorLat,
          'origin_longitude': consignorLng,
          'destination_latitude': consigneeLat,
          'destination_longitude': consigneeLng
        }
        let response = await APIService.farms().appendToUrl('geo-distance/').post(data);
        this.setDistance(response);
      }
    }
  }

  async getDrivers() {
    let drivers = []
    let driverCompanyId = null;
    let companyId = this.state.fields.subFreightProviderId.value || this.state.fields.freightProviderId.value;
    let selectedOrder = this.state.selectedOrder || this.getSelectedOrderFromIdentifier(this.state.selectedOrderIdentifier, this.state.orders);
    if (this.props.siteBooking && get(selectedOrder, 'providerId') && get(selectedOrder, 'providerId') !== this.props.currentUserCompanyId)
      companyId = this.props.currentUserCompanyId;
    if (this.state.fields.truckId.value) {
      let selectedTruck = get(this.state, 'selectedTruck') || find(this.props.trucks, {id: this.state.fields.truckId.value});
      if (!selectedTruck)
        selectedTruck = await APIService.trucks(this.state.fields.truckId.value).get();
      drivers = uniqBy(filter(this.props.drivers, { companyId: get(selectedTruck, 'companyId') }), 'id');
      driverCompanyId = get(selectedTruck, 'companyId');
    }
    else if(companyId) {
      drivers = uniqBy(filter(this.props.drivers || [], { companyId: companyId }), 'id')
      driverCompanyId = companyId;
    }
    else
      drivers = uniqBy(this.props.drivers, 'id');
    if ((!drivers || isEmpty(drivers)) && driverCompanyId)
      this.setCompanyDrivers(driverCompanyId);
    else
      this.setState({allDrivers: drivers});
    return drivers;
  }

  setDistance(response) {
    if (get(response, 'status') === 'OK') {
      const distanceInKms = parseFloat(response['distance'] ? response['distance']['value'] / 1000 : 0).toFixed(2);
      this.setDistanceAndTimeState(distanceInKms, response['duration']['text'], response['duration']['value']);
    } else {
      this.resetDistanceAndTimeState();
    }
  }

  resetDistanceAndTimeState() {
    setTimeout(() => {
      this.setState({
        totalDistance: '',
        totalDistanceLabel: '',
        estimatedTime: '',
        estimatedTimeInSeconds: 0,
      });
    }, 300);
  }

  setDistanceAndTimeState(distance, duration, durationValue) {
    setTimeout(() => {
      this.setState({
        totalDistance: distance,
        totalDistanceLabel: distance + ' ' + getCountryConfig()?.distanceUnit,
        estimatedTime: duration ? duration : '0',
        estimatedTimeInSeconds: durationValue,
      }, () => this.getRecommendedDeliverySlotText());
    }, 300);
  }

  estimatedDeliverySlotStartTime() {
    const { estimatedTimeInSeconds, outloadSlots, outloadSlotId } = this.state;
    if (estimatedTimeInSeconds && outloadSlotId.value && !isEmpty(outloadSlots)) {
      const outloadSlot = find(outloadSlots, { 'id': outloadSlotId.value });
      if (outloadSlot)
        return moment(outloadSlot.end).add(estimatedTimeInSeconds, 'seconds');
    }
  }

  async fetchSlotsForRecommendation(checkpointType, loadType, estimatedStartTime) {
    const siteId = get(this.state, `${checkpointType}`);
    let startDateTime = this.timeStampToDateTime(estimatedStartTime);
    let endDate = estimatedStartTime.endOf('day');
    let endDateTime = this.timeStampToDateTime(endDate);
    let recommendedSlot = '';

    if (every([siteId, startDateTime, endDateTime])) {
      const queryParams = { start: startDateTime, end: endDateTime, load_type: loadType, isRecommendation: true };
      if (this.state.currentOrder.providerId)
        queryParams['freight_provider_id'] = this.state.currentOrder.providerId;
      await APIService
        .company_sites(siteId)
        .appendToUrl('slots/planned/')
        .get(null, null, queryParams)
        .then(slot => {
          if (slot[0])
            recommendedSlot = slot[0].dateTimeDisplayText;
        });
    }
    return recommendedSlot || '';
  }

  getRecommendedDeliverySlotText() {
    const estimatedStartTime = this.estimatedDeliverySlotStartTime();
    if (estimatedStartTime) {
      let recommendedPromise = this.fetchSlotsForRecommendation('freightDelivery.consigneeHandlerId', 'inload', cloneDeep(estimatedStartTime));
      let recommendationText = [`(Estimated Arrival Time: ${estimatedStartTime.format(this.state.countryFormats.datetime)})`]
      recommendedPromise.then(res => {
        if(res)
          recommendationText = [`(Estimated Arrival Time: ${estimatedStartTime.format(this.state.countryFormats.datetime)},`,
          `Recommended Slot: ${res})`]
        this.setState({
          recommendedDeliverySlotText: recommendationText
        });
      }
      );
    }
    else {
      this.setState({ recommendedDeliverySlotText: [] });
    }
  }

  fetchSlots(checkpointType, loadType) {
    const baseEntity = this.state.currentOrder;
    const providerId = this.state.currentOrder.providerId
    const siteId = get(this.state, `${checkpointType}`) || get(this.state.currentOrder, `${checkpointType}`) ;
    let startDate = this.state.freightPickup.date;
    let endDate = this.state.freightDelivery.date;

    if (loadType === 'inload')
      startDate = endDate;

    if(!startDate)
      return

    let startDateTime = this.timeStampToDateTime(moment(startDate).startOf('day'));
    let dayEndDate = moment(startDate).endOf('day');
    let endDateTime = this.timeStampToDateTime(dayEndDate);
    if (every([siteId, startDateTime, endDateTime])) {
      const queryParams = { start: startDateTime, end: endDateTime, load_type: loadType };
      if(this.editMovement){
        const deliveryDate = parseDateTime('date', this.editMovement?.deliveryDateTime);
        const pickupDate = parseDateTime('date', this.editMovement?.pickupDateTime);
        const includeSlotId = (startDate === pickupDate && loadType === 'outload') || (endDate === deliveryDate && loadType === 'inload')
        if (includeSlotId)
          queryParams['include_slot_ids'] = this.editMovement[`${loadType}SlotId`];
      }
      if (providerId)
        queryParams['freight_provider_id'] = providerId;
      APIService
        .company_sites(siteId)
        .appendToUrl('slots/planned/')
        .get(null, null, queryParams)
        .then(slots => {
          const eligibleSlots = orderBy(filter(slots, slot => {
            if (slot.commodityId && slot.gradeId)
              return baseEntity.commodityId === slot.commodityId && baseEntity.plannedGradeId === slot.gradeId
            if (slot.commodityId)
              return baseEntity.commodityId === slot.commodityId && !includes(slot.excludedGradeIds, baseEntity.plannedGradeId)
            return !includes(slot.excludedGradeIds, baseEntity.plannedGradeId) && !includes(slot.excludedCommodityIds, baseEntity.commodityId)
          }), slot => new Date(slot.start));
          const newState = { ...this.state };
          if (loadType === 'inload') {
            newState.inloadSlots = eligibleSlots;
          } else {
            newState.outloadSlots = eligibleSlots;
          }
          this.setState(
            newState, () => {
              this.getRecommendedDeliverySlotText();
            }
          );
        });
    }
  }

  isDeliverySlotBeforePickupSlot = (newState) => {
    const outloadSlot = find(newState.outloadSlots, { id: newState.outloadSlotId.value });
    const inloadSlot = find(newState.inloadSlots, { id: newState.inloadSlotId.value });
    if (outloadSlot && inloadSlot){
      const deliverySlotStart = new Date(inloadSlot.start)
      const pickupSlotEnd = new Date(outloadSlot.end)
      if (deliverySlotStart <= pickupSlotEnd){
        newState['inloadSlotId'].errors = ["Delivery Slot time can't be before Pickup Slot"]
        return true;
      }
    }
    return false;
  }

  async handleSlotChange(value, id, slot) {
    const newState = { ...this.state };
    if(value)
      newState[id].value = value;
    let estimatedDeliverySlotStartTime = false;
    estimatedDeliverySlotStartTime = this.timeStampToDateTime(this.estimatedDeliverySlotStartTime()) + 'Z';
    if (id === 'inloadSlotId') {
      newState[id].errors = [];
      newState['inloadSlotUpdatedAt'] = slot?.updatedAt
      newState.freightDelivery['timeStart'] = get(slot, 'startTime');
      if (newState.freightPickup.timeStart && estimatedDeliverySlotStartTime && value && moment(get(slot, 'start')).isBefore(estimatedDeliverySlotStartTime))
        newState.warningText = 'Warning: Insufficient time to deliver within this slot';
      else
        newState.warningText = '';
    }
    if (id === 'outloadSlotId') {
      newState['outloadSlotUpdatedAt'] = slot?.updatedAt
      newState.freightPickup['timeStart'] = get(slot, 'startTime');
      if (newState.freightDelivery.timeStart && estimatedDeliverySlotStartTime &&
        newState.inloadSlotId.value && !isEmpty(newState.inloadSlots) && value) {
        const inloadSlot = find(newState.inloadSlots, { id: newState.inloadSlotId.value });
        if (moment(get(inloadSlot, 'start')).isBefore(estimatedDeliverySlotStartTime))
          newState.warningText = 'Warning: Insufficient time to deliver within this slot';
        else
          newState.warningText = '';
      }
      else
        newState.warningText = '';
    }
    this.setState(newState, this.fetchDistance);
  }

  navigateToMovement = () => {
    window.open(`/#/freights/movements/${this.state.movement.id}/details`);
  }

  navigateToOrder = () => {
    window.open(`/#/freights/orders/${this.state.movement.orderId}/order`);
  }

  navigateToSlot = (loadType) => {
    const { inloadSlotId, freightDelivery, freightPickup, outloadSlotId } = this.state;
    const currentUserCompanyId = this.props.main.user.user.companyId;
    const isOutloadSlotOfCurrentUser = parseInt(freightPickup.consignorId) === currentUserCompanyId;
    const isInloadSlotOfCurrentUser = freightDelivery.consigneeId === currentUserCompanyId;
    if(loadType === 'outload')
      isOutloadSlotOfCurrentUser ? window.open(`/#/site-management?slotId=${outloadSlotId.value}&endDate=${freightPickup.date}&siteId=${freightPickup.consignorHandlerId}`) : 
    window.open(`/#/site-bookings?companyId=${freightPickup.consignorId}&slotId=${outloadSlotId.value}&endDate=${freightPickup.date}&siteId=${freightPickup.consignorHandlerId}`)
    if(loadType === 'inload')
      isInloadSlotOfCurrentUser ? window.open(`/#/site-management?slotId=${inloadSlotId.value}&endDate=${freightDelivery.date}&siteId=${freightDelivery.consigneeHandlerId}`) :
      window.open(`/#/site-bookings?companyId=${freightDelivery.consigneeId}&slotId=${inloadSlotId.value}&endDate=${freightDelivery.date}&siteId=${freightDelivery.consigneeHandlerId}`)
  }

  isValueChanged = (field) => {
    if (field === 'plannedTonnage') {
      if(this.state.isUnsavedMovement)
        return ! isNil(this.state.plannedTonnage);
      return get(this.state, `${field}.value`) !== this.state.movement.plannedTonnage;
    }
    else if (field === 'freightPickup.date') {
      let pickupDate = '';
      if (this.editMovement && this.editMovement.pickupDateTime)
        pickupDate = parseDateTime('date', this.editMovement.pickupDateTime);
      return get(this.state, `${field}`) !== pickupDate;
    }
    else if (field === 'freightDelivery.date') {
      let deliveryDate = '';
      if (this.editMovement && this.editMovement.deliveryDateTime)
        deliveryDate = parseDateTime('date', this.editMovement.deliveryDateTime);
      return get(this.state, `${field}`) !== deliveryDate;
    }
    else if (field === 'freightPickup.timeStart') {
      let pickupTime = '';
      if (this.editMovement && this.editMovement.pickupDateTime && this.editMovement.pickupShowTime) {
        pickupTime = parseDateTime('time', this.editMovement.pickupDateTime);
        pickupTime = pickupTime.substring(0, pickupTime.length - 1);
      }
      return get(this.state, `${field}`) !== pickupTime;
    }
    else if (field === 'freightDelivery.timeStart') {
      let deliveryTime = '';
      if (this.editMovement && this.editMovement.deliveryDateTime && this.editMovement.deliveryShowTime) {
        deliveryTime = parseDateTime('time', this.editMovement.deliveryDateTime);
        deliveryTime = deliveryTime.substring(0, deliveryTime.length - 1);
      }
      return get(this.state, `${field}`) !== deliveryTime;
    }
    else if (this.editMovement && field === 'freightPickup.instructions') {
      return get(this.state, `${field}`) !== get(this.editMovement, 'pickupInstructions');
    }
    else if(this.state.isUnsavedMovement && isEqual(field, 'freightPickup.instructions') ) {
      return ! isEmpty(this.state.freightPickup.instructions);
    }
    else if (this.editMovement && field === 'freightDelivery.instructions') {
      return get(this.state, `${field}`) !== get(this.editMovement, 'deliveryInstructions');
    }
    else if(this.state.isUnsavedMovement && isEqual(field, 'freightDelivery.instructions')) {
      return ! isEmpty(this.state.freightDelivery.instructions);
    }
    else if (field === 'plannedTruckId') {
      if(this.state.isUnsavedMovement)
        return ! isNil(this.state.plannedTruckId.value);
      return get(this.state, `${field}.value`) !== this.state.movement.plannedTruckId;
    } else if (this.editMovement && field === 'freightPickup.bookingNumber') {
      return get(this.state, `${field}`) !== get(this.editMovement, 'pickupBookingNumber');
    } else if(this.state.isUnsavedMovement && isEqual(field, 'freightPickup.bookingNumber')) {
      return ! isEmpty(this.state.freightPickup.bookingNumber);
    } else if (this.editMovement && field === 'freightDelivery.bookingNumber') {
      return get(this.state, `${field}`) !== get(this.editMovement, 'deliveryBookingNumber');
    } else if(this.state.isUnsavedMovement && isEqual(field, 'freightDelivery.bookingNumber')) {
      return ! isEmpty(this.state.freightDelivery.bookingNumber);
    }
  };

  setUpMovementDatetimeToUTC = (movement) => {
    let newMovement = Object.assign({}, movement);
    if (has(movement, 'freightPickup.date') && has(movement, 'freightPickup.timeStart')) {
      const utcDateTime = moment(this.state.freightPickup.date + ' ' + this.state.freightPickup.timeStart).utc().format('YYYY-MM-DD HH:mm:ss');
      newMovement.freightPickup.dateTime = utcDateTime;
      newMovement.pickupSkipShowTime = false;
      delete newMovement.freightPickup.timeStart;
      delete newMovement.freightPickup.date;
    } else if (has(movement, 'freightPickup.timeStart')) {
      if(this.editMovement && this.editMovement.pickupShowTime && ! this.state.freightPickup.isTimeValid) {
        alertify.error("Please enter valid Time");
        return false;
      }
      if(this.state.isUnsavedMovement && isEmpty(this.state.freightPickup.date)) {
        alertify.error("Please enter valid Date & time");
        return false;
      }
      const utcDateTime = moment(this.state.freightPickup.date + ' ' + this.state.freightPickup.timeStart).utc().format('YYYY-MM-DD HH:mm:ss');
      newMovement.freightPickup.dateTime = utcDateTime;
      newMovement.pickupSkipShowTime = false;
      delete newMovement.freightPickup.timeStart;
    } else if (has(movement, 'freightPickup.date')) {
      let utcDateTime = '';
      if(this.editMovement) {
        let time = parseDateTime('time', this.editMovement.pickupDateTime);
        time = time.substring(0, time.length - 1);
        utcDateTime = moment(this.state.freightPickup.date + ' ' + time).utc().format('YYYY-MM-DD HH:mm:ss');
      } else {
        utcDateTime = moment(this.state.freightPickup.date).format('YYYY-MM-DD');
      }
      newMovement.freightPickup.dateTime = utcDateTime;
      if (this.state.freightPickup.isTimeChanged) {
        newMovement.pickupSkipShowTime = false;
      }
      delete newMovement.freightPickup.date;
    }
    if (has(movement, 'freightDelivery.date') && has(movement, 'freightDelivery.timeStart')) {
      const utcDateTime = moment(this.state.freightDelivery.date + ' ' + this.state.freightDelivery.timeStart).utc().format('YYYY-MM-DD HH:mm:ss');
      newMovement.freightDelivery.dateTime = utcDateTime;
      newMovement.deliverySkipShowTime = false;
      delete newMovement.freightDelivery.date;
      delete newMovement.freightDelivery.timeStart;
    } else if (has(movement, 'freightDelivery.timeStart')) {
      if(this.editMovement && this.editMovement.deliveryShowTime && ! this.state.freightDelivery.isTimeValid) {
        alertify.error("Please enter valid Time");
        return false;
      }
      if(this.state.isUnsavedMovement && isEmpty(this.state.freightDelivery.date)) {
        alertify.error("Please enter valid Date & time");
        return false;
      }
      let deliveryDate = this.state.freightDelivery.date ? this.state.freightDelivery.date : this.state.freightPickup.date;
      const utcDateTime = moment(deliveryDate + ' ' + this.state.freightDelivery.timeStart).utc().format('YYYY-MM-DD HH:mm:ss');
      newMovement.freightDelivery.dateTime = utcDateTime;
      newMovement.deliverySkipShowTime = false;
      delete newMovement.freightDelivery.timeStart;
    } else if (has(movement, 'freightDelivery.date')) {
      let utcDateTime = '';
      if(this.editMovement) {
        let time = this.editMovement.deliveryDateTime;
        if (time && this.editMovement.deliveryShowTime) {
          time = parseDateTime('time', time);
          time = time.substring(0, time.length - 1);
          utcDateTime = moment(movement.freightDelivery.date + ' ' + time).utc().format('YYYY-MM-DD HH:mm:ss');
        } else {
          utcDateTime = moment(movement.freightDelivery.date).format('YYYY-MM-DD');
        }
      } else {
        utcDateTime = moment(this.state.freightDelivery.date).format('YYYY-MM-DD');
      }

      newMovement.freightDelivery.dateTime = utcDateTime;

      if (this.state.freightDelivery.isTimeChanged) {
        newMovement.deliverySkipShowTime = false;
      }
      delete newMovement.freightDelivery.date;
    }

    if (!has(newMovement, 'pickupSkipShowTime')) {
      newMovement.pickupSkipShowTime = true;
      const freightPickupDatetime = get(newMovement, 'freightPickup.dateTime')
      if (freightPickupDatetime && freightPickupDatetime != 'Invalid date')
        newMovement.freightPickup.dateTime = newMovement.freightPickup.dateTime.split(' ')[0];
    }
    if (!has(newMovement, 'deliverySkipShowTime')) {
      newMovement.deliverySkipShowTime = true;
      const freightDeliveryDateTime = get(newMovement, 'freightDelivery.dateTime')
      if (freightDeliveryDateTime && freightDeliveryDateTime != 'Invalid date')
        newMovement.freightDelivery.dateTime = newMovement.freightDelivery.dateTime.split(' ')[0];
    }
    return newMovement;
  }

  getIsFormValid() {
    return !some([this.state.inloadSlotId, this.state.outloadSlotId, this.state.driverId], (field) => {
      return some(field.validators, (validator) => {
        return validator.isInvalid(field.value);
      });
    });
  }

  setSlotErrorsIfAny() {
    const newState = { ...this.state };
    const {currentOrder, outloadSlotId, inloadSlotId} = this.state;
    if (currentOrder?.isPickupSiteSlotOrderBookingOn && !outloadSlotId.value)
      newState.outloadSlotId.validators = [required()];
    if (currentOrder?.isDeliverySiteSlotOrderBookingOn && !inloadSlotId.value)
      newState.inloadSlotId.validators = [required()];
    applyValidatorsOn([newState.outloadSlotId, newState.inloadSlotId, newState.driverId])
    this.isDeliverySlotBeforePickupSlot(newState);
    this.setState(newState);
  }

  saveForLater() {
    let payload = {};
    let draftUnaccountedTonnage = this.state.draftUnaccountedTonnage;
    const newState = {...this.state};
    if (this.isDeliverySlotBeforePickupSlot(newState)){
      this.setState(newState);
      return;
    }
    forEach([
      'plannedTonnage', 'freightPickup.timeStart', 'freightDelivery.timeStart', 'freightPickup.date', 'freightDelivery.date', 'freightPickup.instructions', 'freightDelivery.instructions',
      'plannedTruckId', 'freightPickup.bookingNumber', 'freightDelivery.bookingNumber', 'outloadSlotId', 'inloadSlotId', 'driverId'
    ], field => {
      if (includes(['outloadSlotId', 'inloadSlotId', 'driverId', 'plannedTruckId'], field)) {
        set(payload, field, get(this.state, `${field}.value`));
      } else if (this.isValueChanged(field) && get(this.state, `${field}`)) {
          if (field === 'plannedTonnage') {
            draftUnaccountedTonnage = this.state.movement.plannedTonnage - this.state.plannedTonnage.value;
            draftUnaccountedTonnage = this.state.draftUnaccountedTonnage + draftUnaccountedTonnage;
            set(payload, field, get(this.state, `${field}.value`));
          }
          else
            set(payload, field, get(this.state, `${field}`));
      }
    });

    const updatedPayload = this.setUpMovementDatetimeToUTC(payload);
    const outloadSlotUpdatedAt = find(this.state.outloadSlots, { id: this.state.outloadSlotId.value})?.updatedAt;
    const inloadSlotUpdatedAt = find(this.state.inloadSlots, { id: this.state.inloadSlotId.value})?.updatedAt;
    set(updatedPayload, "outloadSlotUpdatedAt", outloadSlotUpdatedAt );
    set(updatedPayload, "inloadSlotUpdatedAt", inloadSlotUpdatedAt );
    this.props.saveForLater(this.state.movement, updatedPayload, this.state.currentOrder, draftUnaccountedTonnage);
  }

  updateMovement = () => {
    this.setSlotErrorsIfAny()
    if(!this.validateTonnage(this.state.plannedTonnage.value))
      return;
    if(!this.getIsFormValid() || this.state.plannedTruckId.errors.length > 0)
      return;
    let payload = {
      communication: { acceptanceRequired: false }
    };
    let draftUnaccountedTonnage = this.state.draftUnaccountedTonnage;
    forEach([
      'plannedTonnage', 'freightPickup.date', 'freightDelivery.date', 'freightPickup.timeStart',
      'freightDelivery.timeStart', 'freightPickup.instructions', 'freightDelivery.instructions',
      'freightPickup.bookingNumber', 'freightDelivery.bookingNumber'
    ], field => {
      if (this.isValueChanged(field)) {
        if (field === 'plannedTonnage') {
          draftUnaccountedTonnage = this.state.movement.plannedTonnage - this.state.plannedTonnage.value;
          draftUnaccountedTonnage = this.state.draftUnaccountedTonnage + draftUnaccountedTonnage;
          set(payload, field, get(this.state, `${field}.value`));
        } else {
          set(payload, field, get(this.state, `${field}`));
        }
      }
    });
    let updatedPayload = this.setUpMovementDatetimeToUTC(payload);
    set(updatedPayload, "driverId", this.state.driverId.value);
    if (this.isValueChanged("plannedTruckId"))
      set(updatedPayload, "plannedTruckId", this.state.plannedTruckId.value);
    set(updatedPayload, "outloadSlotId", this.state.outloadSlotId.value);
    set(updatedPayload, "inloadSlotId", this.state.inloadSlotId.value);
    const providerId = find(this.state.allTrucks, { id: this.state.plannedTruckId.value })?.companyId
    set(updatedPayload, "providerId", providerId);
    const outloadSlotUpdatedAt = find(this.state.outloadSlots, { id: this.state.outloadSlotId.value})?.updatedAt;
    const inloadSlotUpdatedAt = find(this.state.inloadSlots, { id: this.state.inloadSlotId.value})?.updatedAt;
    set(updatedPayload, "outloadSlotUpdatedAt", outloadSlotUpdatedAt );
    set(updatedPayload, "inloadSlotUpdatedAt", inloadSlotUpdatedAt );
    if (updatedPayload) {
      if(this.state.isUnsavedMovement) {
          this.isLoading('movementDetail');
          set(updatedPayload, "identifier", this.state.movement.identifier);
          set(updatedPayload, "orderId", this.state.currentOrder?.id);
          set(updatedPayload, "startDate", this.state.movement.startDate);
          set(updatedPayload, "createdAt", this.state.movement.createdAt);
          this.props.executeMovements([updatedPayload], this.state.currentOrder, draftUnaccountedTonnage, true);
      } else {
        this.isLoading('movementDetail');
        this.raiseAmendRequest(this.state.movement.id, updatedPayload, undefined, false);
        this.props.onUpdate(this.state.movement, updatedPayload, this.state.currentOrder, draftUnaccountedTonnage);
      }
    }
  }

  getPickupMinimumDate = () => {
    let orderStartDate = moment(this.state.currentOrder?.startDate);
    if(orderStartDate.isBefore(moment().format('YYYY-MM-DD')))
      return moment().format('YYYY-MM-DD');
    else
      return orderStartDate;
  }

  updateHandlers(checkpointType) {
    if (checkpointType === 'consignor' && this.state.freightPickup.consignorHandlerId) {
      APIService.farms(this.state.freightPickup.consignorHandlerId)
        .get()
        .then(item => {
          const newState = {...this.state};
          newState.freightPickup.pickupAddress = get(item, 'address.name');
          newState.freightPickup.pickupSiteMobile = get(item, 'mobile');
          this.setState(newState);
        });
    }
    else if (checkpointType === 'consignee' && this.state.freightDelivery.consigneeHandlerId) {
      APIService.farms(this.state.freightDelivery.consigneeHandlerId)
        .get()
        .then(item => {
          const newState = {...this.state};
          newState.freightDelivery.deliveryAddress = get(item, 'address.name');
          newState.freightDelivery.deliverySiteMobile = get(item, 'mobile');
          this.setState(newState);
        });
    }
  }

  render() {
    const inloadSlotLabel = (
      <span>
        Select Slot{' '}
        { !this.state.isUnsavedMovement && this.state.inloadSlotId.value &&
        <span style={{ fontWeight: '500', fontSize: '13px' }}>
          <a style={{ cursor: 'pointer' }} onClick={() => this.navigateToSlot('inload')}>
            (View Inload Slot)
          </a>
        </span>}
      </span>
    );
    const outloadSlotLabel = (
      <span>
        Select Slot{' '}
        { !this.state.isUnsavedMovement && this.state.outloadSlotId.value &&
        <span style={{ fontWeight: '500', fontSize: '13px' }}>
          <a style={{ cursor: 'pointer' }} onClick={() => this.navigateToSlot('outload')}>
            (View Outload Slot)
          </a>
        </span>}
      </span>
    );


    return (
      <Dialog
        open={this.state.isDialogOpen}
        onClose={this.closePopup}
        aria-labelledby="form-dialog-title"
        id="complete-dialog-open"
        maxWidth="xl">
        <DialogTitleWithCloseIcon
          onClose={this.closePopup}
          className={"movement-dialog-title"}
          closeButtonStyle={{ color: "#FFF" }}
          style={{ height: '58px', paddingTop: '12px', backgroundColor: includes(this.props.movementEnabledStatuses, 'invoiced') && get(this.state.movement, 'isFreightInvoiced') ? BORDER_COLORS['invoiced'] : BORDER_COLORS[this.state.movement.status] }}>
          Movement Details
        </DialogTitleWithCloseIcon>
        <DialogContent style={{ borderBottom: ' 1px solid #e6e6e6', padding: '0 0 24px' }}>
          <form>
            <div className='header-items'>
              <div className="dialog-header">
                Freight Movement
                <div className='dialog-header-content'>
                {this.state.movement.id ?
                  <a style={{ cursor: "pointer" }} onClick={this.navigateToMovement}>
                    {this.state.movement.identifier}
                  </a> : this.state.movement.identifier
                }
                </div>
              </div>
              <div className="dialog-header">
                Freight Order
                <div className='dialog-header-content'>
                  <a style={{ cursor: "pointer" }} onClick={this.navigateToOrder}>
                    {this.state.order}
                  </a>
                </div>
              </div>
              <div className="dialog-header">
                Pickup Order
                <div className='dialog-header-content'>
                  {this.state.freightPickup.pickupOrder ? this.state.freightPickup.pickupOrder : '-'}
                </div>
              </div>
              <div className="dialog-header">
                Delivery Order
                <div className='dialog-header-content'>
                  {this.state.freightDelivery.deliveryOrder ? this.state.freightDelivery.deliveryOrder : '-'}
                </div>
              </div>
            </div>
            <Divider />
            <div className='cardForm cardForm--drawer' style={{ padding: '0 24px 0' }}>
              <div className='cardForm-content row' style={{ marginTop: '10px' }}>
                <div className='col-sm-3 form-wrap'>
                  <CommonSelect
                    id='truck'
                    floatingLabelText="Truck"
                    name='plannedTruckId'
                    items={filter(this.state.trucks, truck => truck.isActive || truck.id === this.state.plannedTruckId.value)}
                    value={this.state.plannedTruckId.value}
                    onChange={event => this.handleChange('plannedTruckId', event)}
                    disabled={!this.state.isEditable}
                    errorText={this.state.plannedTruckId.errors[0]}
                  />
                </div>
                <div className='col-sm-3 form-wrap'>
                  <CommonSelect
                    id='driverId'
                    floatingLabelText="Driver"
                    name='driverId'
                    items={this.state.allDrivers}
                    value={this.state.driverId.value}
                    onChange={event => this.handleChange('driverId', event)}
                    disabled={!this.state.isEditable}
                    errorText={this.state.driverId.errors[0]}
                    dontAutoselectSingleItem
                  />
                </div>
                <div className='col-sm-3 form-wrap'>
                  <CommonTextField
                    type='number'
                    id='tonnage'
                    name='tonnage'
                    label='Tonnage'
                    placeholder='Tonnage'
                    onChange={this.handleTonnageChange}
                    helperText={this.state.plannedTonnage.error}
                    value={this.state.plannedTonnage.value}
                    disabled={!this.state.isEditable}
                  />
                </div>
              </div>
              <div className='cardForm-content row'>
                <div className='col-sm-3 form-wrap'>
                  <CommonTextField
                    id='commodity'
                    name='commodity'
                    label='Commodity'
                    placeholder='Commodity'
                    value={this.state.movement.commodity}
                    onChange={event => this.handleChange('', event)}
                    disabled
                  />
                </div>
                <div className='col-sm-3 form-wrap'>
                  <CommonTextField
                    id='grade'
                    name='grade'
                    label='Grade'
                    placeholder='Grade'
                    value={this.state.freightDelivery?.inloadGrade ? this.state.freightDelivery?.inloadGrade : this.state.freightPickup?.outloadGrade ? this.state.freightPickup?.outloadGrade : this.state.currentOrder?.plannedGrade}
                    onChange={event => this.handleChange('', event)}
                    disabled
                  />
                </div>
                <div className='col-sm-3 form-wrap'>
                  <CommonTextField
                    id='season'
                    name='season'
                    label='Season'
                    placeholder='Season'
                    value={this.state.freightDelivery?.inloadSeason ? this.state.freightDelivery?.inloadSeason : this.state.freightPickup?.outloadSeason ? this.state.freightPickup?.outloadSeason : this.state.currentOrder?.season}
                    onChange={event => this.handleChange('', event)}
                    disabled
                  />
                </div>
                <div className='col-sm-3 form-wrap'>
                  <CommonTextField
                    id='customer'
                    name='customer'
                    label='Customer'
                    placeholder='Customer'
                    value={this.state.movement.customer}
                    onChange={event => this.handleChange('', event)}
                    disabled
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div className='cardForm cardForm--drawer' style={{ padding: '0 24px 0' }}>
              <Typography variant="title" style={{ margin: '10px' }}>
                Movement Details
              </Typography>
              <div className='cardForm-content row'>
                <div style={{ width: '20%' }} className={this.state.freightPickup.outloadDateTime || this.state.freightDelivery.inloadDateTime ? 'col-sm-2 form-wrap' : 'col-sm-3 form-wrap'} >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ width: '4%', marginBottom: '65px' }}>
                      <SiteConnector height={'80px'} />
                    </div>
                    <div>
                      <div className='col-sm-12 form-wrap'>
                        <div style={{ fontSize: '12px' }}>
                          <div style={{ fontWeight: '500' }}>
                            {this.state.freightPickup.consignorName}
                          </div>
                          <div>
                            <div style={{display: 'block'}}>
                              {this.state.freightPickup.pickupAddress}
                              <IconButton
                                onClick={() => this.setState({openConsignorFarmForm: true})}
                                style={{padding: "0px"}}
                                size="small">
                                <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }}/>
                              </IconButton>
                            </div>
                            {this.state.freightPickup.pickupSiteMobile &&
                            <div style={{display: 'block'}}>
                              {toPhoneFormat(this.state.freightPickup.pickupSiteMobile)}
                              <IconButton
                                onClick={() => this.setState({openConsignorFarmForm: true})}
                                style={{padding: '0px'}}
                                size="small">
                                <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }}/>
                              </IconButton>
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 form-wrap'>
                        <div style={{ fontSize: '12px' }}>
                          <div style={{ fontWeight: '500' }}>
                            {this.state.freightDelivery.consigneeName}
                          </div>
                          <div>
                            <div style={{display: 'block'}}>
                              {this.state.freightDelivery.deliveryAddress}
                              <IconButton
                                onClick={() => this.setState({openConsigneeFarmForm: true})}
                                style={{padding: '0px', color: PRIMARY_COLOR_GREEN}}
                                size="small">
                                <PinDropIcon />
                              </IconButton>
                            </div>
                            {this.state.freightDelivery.deliverySiteMobile &&
                            <div style={{display: 'block'}}>
                              {toPhoneFormat(this.state.freightDelivery.deliverySiteMobile)}
                              <IconButton
                                onClick={() => this.setState({openConsigneeFarmForm: true})}
                                style={{padding: '0px'}}
                                size="small">
                                <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }}/>
                              </IconButton>
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: '11%' }} className='col-sm-2 form-wrap padding-reset'>
                  <div className='col-sm-12 form-wrap padding-reset'>
                    <CommonDatePicker
                      id='pickup_date'
                      floatingLabelText='Pick Up Date'
                      value={this.state.freightPickup.date}
                      disabled={!this.state.isEditable}
                      minDate={this.getPickupMinimumDate()}
                      maxDate={parseDateTime('date', this.state.currentOrder?.endDate)}
                      onChange={event => this.handlePickupChange('date', event)}
                    />
                  </div>
                  <div className='col-sm-12 form-wrap padding-reset'>
                    <CommonDatePicker
                      id='delivery_date'
                      floatingLabelText='Delivery Date'
                      value={this.state.freightDelivery.date}
                      disabled={!this.state.isEditable}
                      minDate={this.state.freightPickup.date || this.state.today}
                      maxDate={parseDateTime('date', this.state.currentOrder?.endDate)}
                      onChange={event => this.handleDeliveryChange('date', event)}
                    />
                  </div>
                </div>
                <div style={{ width: '15%' }} className='col-sm-2 form-wrap padding-reset'>
                  <div className='col-sm-12 form-wrap'>
                    {this.state.currentOrder?.isPickupSiteSlotOrderBookingOn ?
                      <React.Fragment>
                        <CommonSelect
                          id='outloadSlotId'
                          onChange={this.handleSlotChange}
                          errorText={this.state.outloadSlotId.errors[0]}
                          floatingLabelText={outloadSlotLabel}
                          selectConfig={{ text: 'name', value: 'id' }}
                          items={this.state.outloadSlots || []}
                          value={this.state.outloadSlotId.value}
                          dontAutoselectSingleItem
                        />
                      </React.Fragment> :
                      <CommonTimePicker
                        id="pickup_time"
                        floatingLabelText='Pick Up Time'
                        disabled={!this.state.isEditable}
                        value={this.state.freightPickup.timeStart}
                        onChange={event => this.handlePickupChange('timeStart', event)}
                      />}
                  </div>
                  <div className='col-sm-12 form-wrap'>
                    {this.state.currentOrder?.isDeliverySiteSlotOrderBookingOn ?
                    <React.Fragment>
                      <CommonSelect
                        id='inloadSlotId'
                        onChange={this.handleSlotChange}
                        errorText={this.state.inloadSlotId.errors[0] || this.state.warningText}
                        floatingLabelText={inloadSlotLabel}
                        selectConfig={{ text: 'name', value: 'id' }}
                        items={this.state.inloadSlots || []}
                        value={this.state.inloadSlotId.value}
                        dontAutoselectSingleItem
                      />
                      {get(this.state.recommendedDeliverySlotText, 'length') > 1 ? <br></br> : null}
                      {map(this.state.recommendedDeliverySlotText, (data, i) => (<span key={i} style={{ fontSize: '14px', fontWeight: '400' }}>{data}<br></br></span>))}
                    </React.Fragment>
                       :
                      <CommonTimePicker
                        id="delivery_time"
                        floatingLabelText='Delivery Time'
                        value={this.state.freightDelivery.timeStart}
                        disabled={!this.state.isEditable}
                        onChange={event => this.handleDeliveryChange('timeStart', event)}
                      />}
                  </div>
                </div>
                <div className='col-sm-2 form-wrap padding-reset'>
                    <div className='col-sm-12 form-wrap padding-reset'>
                      <CommonTextField
                        label='Actual Pickup'
                        floatingLabelText='Actual Pickup'
                        id='datetime'
                        name="actualPickup"
                        type="datetime"
                        value={this.state.freightPickup.outloadDateTime ? toDateTimeFormat(this.state.freightPickup.outloadDateTime) : ''}
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>
                    <div className='col-sm-12 form-wrap padding-reset'>
                      <CommonTextField
                        id='datetime'
                        label='Actual Delivery'
                        placeholder='Actual Delivery'
                        name="actualDelivery"
                        type="datetime"
                        value={this.state.freightDelivery.inloadDateTime ? toDateTimeFormat(this.state.freightDelivery.inloadDateTime) : ''}
                        onChange={this.handleChange}
                        disabled
                      />
                    </div>
                </div>

                <div style={{ width: '12%' }} className='col-sm-2 form-wrap padding-reset'>
                  <div className='col-sm-12 form-wrap'>
                    <CommonTextField
                      id='pickup_booking'
                      label='Booking Number'
                      placeholder='Pickup Booking Number'
                      name="pickupBooking"
                      value={this.state.freightPickup.bookingNumber}
                      onChange={event => this.handleChange('pickupBooking', event)}
                    />
                  </div>
                  <div className='col-sm-12 form-wrap'>
                    <CommonTextField
                      id='delivery_booking'
                      label='Booking Number'
                      placeholder='Delivery Booking Number'
                      name="deliveryBooking"
                      value={this.state.freightDelivery.bookingNumber}
                      onChange={event => this.handleChange('deliveryBooking', event)}
                    />
                  </div>
                </div>
                <div style={{ width: '24%' }} className='col-sm-2 form-wrap padding-reset'>
                  <div className='col-sm-12 form-wrap' style={{paddingLeft: '0px'}}>
                    <CommonTextField
                      id='pickup_comment'
                      label='Pickup Instructions'
                      placeholder='Pickup Instructions'
                      value={this.state.freightPickup.instructions}
                      onChange={event => this.handlePickupChange('instructions', event)}
                    />
                  </div>
                  <div className='col-sm-12 form-wrap' style={{paddingLeft: '0px'}}>
                    <CommonTextField
                      id='delivery_comment'
                      label='Delivery Instructions'
                      placeholder='Delivery Instructions'
                      onChange={event => this.handleDeliveryChange('instructions', event)}
                      value={this.state.freightDelivery.instructions}
                    />
                  </div>
                </div>
              </div>
            </div>
            {this.state.openConsignorFarmForm &&
              <UpdateLocation updateEntities={() => this.updateHandlers('consignor')} entityId={this.state.freightPickup.consignorHandlerId} entity='farm' onCloseDrawer={() => this.setState({openConsignorFarmForm: false})}/>
            }
            {this.state.openConsigneeFarmForm &&
              <UpdateLocation updateEntities={() => this.updateHandlers('consignee')} entityId={this.state.freightDelivery.consigneeHandlerId} entity='farm' onCloseDrawer={() => this.setState({openConsigneeFarmForm: false})}/>
            }
          </form>
        </DialogContent>
        <DialogActions>
        {this.state.isUnsavedMovement &&
          <Button disabled={this.state.plannedTonnage.error !== ''} type='button' onClick={this.saveForLater} variant="contained" color="primary" >
            Save for Later
          </Button>}
          <Button disabled={this.state.plannedTonnage.error !== ''} type='button' onClick={this.updateMovement} variant="contained" color="primary" >
            { this.state.isUnsavedMovement ? "Execute" : "Update" }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


const mapStateToProps = (state) => { return state; };

const mapDispatchToProps = dispatch => ({
  raiseAmendRequest: (movementId, data, successMessage, isMovementPage) => dispatch(raiseMovementAmendRequest(movementId, data, successMessage, isMovementPage)),
  isLoading: (component) => dispatch(isLoading(component))
});


MovementDetailsDialog.propTypes = {
  raiseAmendRequest: PropTypes.func,
  isLoading: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MovementDetailsDialog);
