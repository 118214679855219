import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  required,
  maxLength,
  minLength,
  phoneMobileRegex,
  emailRegex
} from '../../common/validators';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import includes from 'lodash/includes';
import get from 'lodash/get';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import trim from 'lodash/trim'
import CommonSelect from '../common/select/CommonSelect';
import { getRoles } from '../../actions/api/employees';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import APIService from '../../services/APIService';
import '../../common/forms.scss';
import {isSystemCompany, isTransactionParticipated, isCurrentUserCompanyAdmin, isCurrentUserBelongsToCompany} from '../../common/utils';
import {COMPANY_ADMIN, PASSWORDS_DO_NOT_MATCH, FARM_EMPLOYEE_ROLES, OFFICE_EMPLOYEE_ROLES, UNASSIGNED_EMPLOYEE_TYPE_ID} from '../../common/constants';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import PasswordValidIndicator from './PasswordValidIndicator';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import PhoneField from '../common/PhoneField';
import alertifyjs from 'alertifyjs';
import { positiveDecimalFilter } from '../../common/input-filters';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';

const ADMIN_CREATES_PASSWORD = 'adminCreatesPassword';
const USER_CREATES_PASSWORD = 'userCreatesPassword'

class EmployeeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      farms: [],
      showPasswordFields: false,
      isPasswordValid: false,
      showPassword: false,
      showConfirmPassword: false,
      passwordCreationType: this.props.selectedEmployee ? null : ADMIN_CREATES_PASSWORD,
      ssoSettingEnabled: false,
      generatingUsername: false,
      fields: {
        title: {
          value: '',
          validators: [],
          errors: []
        },
        firstName: {
          value: '',
          validators: [required(), maxLength(100)],
          errors: []
        },
        lastName: {
          value: '',
          validators: [required(), maxLength(100)],
          errors: []
        },
        typeId: {
          value: '',
          validators: [required()],
          errors: []
        },
        mobile: {
          value: '',
          validators: [phoneMobileRegex(null)],
          errors: []
        },
        email: {
          value: '',
          validators: [emailRegex(), maxLength(100)],
          errors: []
        },
        username: {
          value: '',
          validators: [required(), minLength(3)],
          errors: []
        },
        password: {
          value: '',
          validators: [required(), minLength(8)],
          errors: []
        },
        confirmPassword: {
          value: '',
          validators: [required(), minLength(8)],
          errors: []
        },
        passwordExpiryPeriod: {
          value: '',
          validators: [],
          errors: []
        },
        farmIds: {
          value: [],
          validators: [],
          errors: []
        },
      }
    };
    this.formRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.isPasswordValid = this.isPasswordValid.bind(this);
    this.setAllFieldsValuesBySelectedEmployee = this.setAllFieldsValuesBySelectedEmployee.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.cancelDrawer = this.cancelDrawer.bind(this);
    this.handleShowPasswordFields = this.handleShowPasswordFields.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleClickConfirmShowPassword = this.handleClickConfirmShowPassword.bind(this);
    this.setUsername = this.setUsername.bind(this);
    const { dispatch } = this.props;
    dispatch(getRoles());
    this.fetchAndSetFarms();
    this.formSubmitted = false;
  }

  isPasswordValid(valid) {
    if(valid !== this.state.isPasswordValid)
      this.setState({isPasswordValid: valid});
  }

  fetchAndSetFarms() {
    APIService.farms().get(this.props.token, undefined, '?company_id=' + this.props.companyId)
              .then(farms => this.setState(state => ({ ...state, farms })));
  }

  selectedCompanyRegisteredButNotSubscriber() {
    return get(this.props, 'selectedCompany.isRegistered') && !isTransactionParticipated(this.props.selectedCompany);
  }

  isCurrentUserCompanyAdminOrSystemCompany = () => (isCurrentUserBelongsToCompany(this.props.companyId) && isCurrentUserCompanyAdmin()) || isSystemCompany();

  componentDidMount() {
    this.setAllFieldsValuesBySelectedEmployee();

    if(isFunction(get(this, 'formRef.current.scrollIntoViewIfNeeded')))
      this.formRef.current.scrollIntoViewIfNeeded();

    if (this.props.selectedFarm !== null)
      this.setFieldValue('farmId', {id: this.props.selectedFarm});
    if ((isTransactionParticipated(get(this.props.currentUser, 'company')) || (this.props.fromContract && get(this.props.currentUser, 'companyId') != this.props.selectedCompany?.id)) && this.selectedCompanyRegisteredButNotSubscriber()) {
      const newState = {...this.state};
      newState.fields.typeId.value = UNASSIGNED_EMPLOYEE_TYPE_ID;
      this.setState(newState);
    }
    if(this.isCurrentUserCompanyAdminOrSystemCompany()) {
      APIService.companies(this.props.companyId).appendToUrl('sso-setting/web/').get().then(response => {
        if(get(response, 'id'))
          this.setState({showPasswordFields: false, ssoSettingEnabled: true})
        else
          this.setState({showPasswordFields: this.state.passwordCreationType != null, ssoSettingEnabled: false})
      })
    }
    if (this.props.selectedEmployee && this.props.selectedEmployee.typeId !== COMPANY_ADMIN) {
      APIService.profiles(this.props.selectedEmployee.id)
        .appendToUrl('farms/')
        .get()
        .then(response => {
          const newState = {...this.state};
          newState.fields.farmIds.value = response.map(resp => resp.id) || [];
          this.setState(newState)
        })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedEmployee) {
      if (this.props.selectedEmployee.id !== prevProps.selectedEmployee.id) {
        this.setAllFieldsValuesBySelectedEmployee();
      }
    }
    if(isFunction(get(this, 'formRef.current.scrollIntoViewIfNeeded')))
      this.formRef.current.scrollIntoViewIfNeeded();

    if(this.formSubmitted && Object.keys(this.props.serverErrors).length === 0 && this.props.responseReceived) {
      this.formSubmitted = false;
      if(this.props.closeDrawer) this.props.closeDrawer();
    }

    if(!this.canUpsertCredentials() && this.state.fields.username.validators.length > 1) {
      const newState = {...this.state};
      newState.fields.password.validators = [minLength(8)];
      newState.fields.confirmPassword.validators = [minLength(8)];
      newState.fields.username.validators = [minLength(3)];
      this.setState(newState);
    }
  }

  handleSelectChange(key, event, index, value) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [key]: {
          ...this.state.fields[key],
          value: value,
        },
      },
    }, () => {
      this.setFieldErrors(key);
    });
  }

  updateCredentialFields() {
    if(!this.canUpsertCredentials()) {
      const newState = {...this.state};
      newState.fields.username.validators = [];
      newState.fields.username.errors = [];
      this.setState(newState);
    }
  }
  ignorePasswordFields = () => this.state.passwordCreationType != ADMIN_CREATES_PASSWORD || this.state.ssoSettingEnabled || (this.props.selectedEmployee && this.state.passwordCreationType == null)

  onSubmitSuccess = () => {
    if(this.isCurrentUserCompanyAdminOrSystemCompany() && !this.state.ssoSettingEnabled && this.state.passwordCreationType != null) {
      let content = this.state.passwordCreationType === ADMIN_CREATES_PASSWORD ? "Please communicate the password to the user." : "An email containing link for resetting the password has been sent to the user.";
      alertifyjs.alert("Reminder",
        `<div><p>${content}</p></div>`,
        () => {}
      );
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.passwordCreationType == ADMIN_CREATES_PASSWORD && this.state.fields.password.value !== this.state.fields.confirmPassword.value) {
      const newState = {...this.state};
      newState.fields.confirmPassword.errors = [PASSWORDS_DO_NOT_MATCH];
      this.setState(newState);
    } else {
      this.updateCredentialFields();
      this.setAllFieldsErrors();
      if(this.state.showPasswordFields && !this.state.isPasswordValid)
        return;
      const isFormInvalid = some(this.state.fields, (field, key) => {
        return some(field.validators, (validator) => {
          if (includes(['password', 'confirmPassword'], key)  && this.ignorePasswordFields())
            return false;
          else {
            return validator.isInvalid(field.value);
          }
        });
      });
      const submitData = mapValues(this.state.fields, (field) => {
        return field.value;
      });

      delete submitData.confirmPassword;

      if (this.state.passwordCreationType == USER_CREATES_PASSWORD || this.state.passwordCreationType != ADMIN_CREATES_PASSWORD)
        delete submitData.password;

      if(includes(OFFICE_EMPLOYEE_ROLES, submitData.typeId)) {
        delete submitData.farmIds;
      }

      if(!this.canUpsertCredentials()) {
        delete submitData.username
        delete submitData.password;
      }

      submitData.userSetPassword = this.state.passwordCreationType === USER_CREATES_PASSWORD;

      if (!isFormInvalid) {
        if(!submitData.password)
          delete submitData.password;
        this.formSubmitted = true;
        if(this.props.selectedEmployee) {
          this.props.submit(this.props.companyId, this.props.selectedEmployee.id, submitData, this.onSubmitSuccess);
        } else {
          this.props.submit(this.props.companyId, submitData, this.props.establishmentDetails, this.props.getContacts, this.onSubmitSuccess);
        }
      }
    }
  }

  onBlur(event) {
    this.setFieldErrors(event.target.id);
    if(includes(['lastName', 'mobile', 'email'], event.target.id) && !this.props.selectedEmployee)
      this.setState({generatingUsername: true}, () => this.setUsername())
  }

  setUsername = () => {
    let queryParams = '';
    if (this.state.fields.mobile.value)
      queryParams += `&mobile=${this.state.fields.mobile.value}`;
    if (this.state.fields.email.value)
      queryParams += `&email=${this.state.fields.email.value}`;
    APIService.profiles()
    .appendToUrl(`generate-username/?first_name=${this.state.fields.firstName.value}&last_name=${this.state.fields.lastName.value}${queryParams}`)
    .get()
    .then(response => this.setFieldValue('username', response?.username));
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }
  handleSelectFieldChange(value, id) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [id]: {
          ...this.state.fields[id],
          value: value,
        },
      },
    }, () => {
      this.setFieldErrors(id);
    });
  }

  handleUpdateInput = (searchText, source, id) => {
    if(searchText){
      this.setFieldValue(id, {name: searchText});
    } else {
      this.setFieldValue(id, searchText);
    }
  };

  handleNewRequest = (data, id) => {
    this.setFieldValue(id, data);
  };

  setFieldValue(key, value) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          value: typeof value == 'object' ? value : trim(value),
        }
      }
    }), () =>{
      if (this.state.generatingUsername)
        this.setState({generatingUsername: false})
    });
  }

  getFieldErrors(key) {
    let errors = [];
    const value = (has(this.state.fields[key].value, 'name')) ? this.state.fields[key].value['name'] : this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    if (includes(['password', 'confirmPassword'], key)  && this.ignorePasswordFields())
      errors = [];

    if(key === 'confirmPassword' && this.state.passwordCreationType == ADMIN_CREATES_PASSWORD) {
      if (this.state.fields.password.value !== this.state.fields.confirmPassword.value) {
        errors = [PASSWORDS_DO_NOT_MATCH];
      } else if(this.props.selectedEmployee) {
        errors = [];
      }
    }

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }), this.checkPasswordsMatch);
  }

  checkPasswordsMatch() {
    const newState = {...this.state};
    if (newState.fields.password.value){
      if(newState.fields.password.value !== newState.fields.confirmPassword.value){
        newState.fields.confirmPassword.errors = [PASSWORDS_DO_NOT_MATCH];
      } else {
        newState.fields.confirmPassword.errors = [];
      }
      this.setState(newState);
    }
  }

  setAllFieldsValuesBySelectedEmployee() {
    if (this.props.selectedEmployee) {
      const newState = { ...this.state };
      forEach(newState.fields, (value, key) => {
        if (key !== 'farmIds')
          newState.fields[key].value = this.props.selectedEmployee[key];
      });
      newState.fields.password.validators = [minLength(8)];
      newState.fields.confirmPassword.validators = [minLength(8)];
      this.setState(newState);
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });

    this.setState(newState);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
      newState.fields[key].errors = value;
    });

    const canSeeCompanyAdminRole = isCurrentUserBelongsToCompany(this.props.companyId) && isCurrentUserCompanyAdmin()

    let roles = props.roles || newState.roles
    if(!canSeeCompanyAdminRole)
      roles = roles.slice(1)

    if (get(props, 'selectedEmployee.typeId') == COMPANY_ADMIN || !get(props, 'selectedCompany.isRegistered')){
      roles = props.roles;
    }
    if(get(this.props, '.selectedCompany.planType') === 'logistics_lite') {
      roles = filter(roles, (role) => role.name.indexOf("farm") === -1);
    }
    if(isSystemCompany())
      newState.roles = props.roles;
    else {
      roles = filter(roles, (role) => role.name !== "operator");
      newState.roles = roles;
    }

    if(props.selectedEmployee && isEmpty(props.serverErrors)) {
      newState.fields['typeId'].value = get(props, 'selectedEmployee.typeId');
    }
    this.setState(newState);
  }

  handleAutoCompleteChange = (data, id) => value => {
    const findData = value ?
                     data.find(e => e.id === (get(value, 'value') || value)) :
                     {id: null};
    this.handleNewRequest(findData, id);
  };
  handleShowPasswordFields = () => event => {
    const newState = {...this.state};
    newState.passwordCreationType = event.target.value;
    newState.showPasswordFields = event.target.value === ADMIN_CREATES_PASSWORD;
    if (newState.passwordCreationType  === USER_CREATES_PASSWORD)
      newState.fields.email.validators = [required(), ...this.state.fields.email.validators];
    else
      newState.fields.email.validators = [emailRegex(), maxLength(100)]
    this.setState(newState);
  };

  cancelDrawer(){
    this.props.closeDrawer();
  }

  isRegisteredCompany() {
    return get(this.props.selectedCompany, 'isRegistered');
  }

  canUpsertCredentials() {
    if(isSystemCompany())
      return true;
    else if (get(this.props, 'currentUser.typeId') === COMPANY_ADMIN && this.isRegisteredCompany())
      return includes(
        [get(this.props.selectedCompany, 'companyId'), get(this.props.selectedCompany, 'id')],
        get(this.props, 'currentUser.companyId')
      );
    else if (this.props.selectedEmployee)
      return get(this.props.selectedEmployee, 'id') === get(this.props.currentUser, 'id');
  }

  handleClickShowPassword() {
      this.setState(prevState => ({
        showPassword: !prevState.showPassword
      }));
  }

  handleClickConfirmShowPassword() {
    this.setState(prevState => ({
      showConfirmPassword: !prevState.showConfirmPassword
    }));
  }

  handleFarmsChange = (id, selectedItems) => {
    const newState = {...this.state};
    newState.fields.farmIds.value = !isEmpty(selectedItems) ? selectedItems.map(item => item.id) : [];
    this.setState(newState);
  }

  render() {
    const disableEmployeeRole = isTransactionParticipated(get(this.props.currentUser, 'company')) && this.selectedCompanyRegisteredButNotSubscriber() && this.state.fields.typeId.value === UNASSIGNED_EMPLOYEE_TYPE_ID;
    return (
      <div ref={this.formRef}>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="firstName"
                  label="First Name"
                  placeholder="Please enter"
                  value={this.state.fields.firstName.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={this.state.fields.firstName.errors[0]}
                  maxLength="100"
                  disabled={!this.props.canAccessAny}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="lastName"
                  label="Last Name"
                  placeholder="Please enter"
                  value={this.state.fields.lastName.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={this.state.fields.lastName.errors[0]}
                  maxLength="100"
                  disabled={!this.props.canAccessAny}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonSelect
                  id="typeId"
                  floatingLabelText="Select Role"
                  hintText="Please select Role"
                  value={this.state.fields.typeId.value}
                  onChange={this.handleSelectFieldChange}
                  errorText={this.state.fields.typeId.errors[0]}
                  selectConfig={{text: 'displayName', value: 'id'}}
                  disabled={!this.props.canAccessAny || disableEmployeeRole || (this.props.fromContract && get(this.props.currentUser, 'companyId') != this.props.selectedCompany?.id && this.selectedCompanyRegisteredButNotSubscriber())}
                  items={this.state.roles}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="title"
                  label="Job Title"
                  placeholder="Please enter Title (optional)"
                  value={this.state.fields.title.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  errorText={this.state.fields.title.errors[0]}
                  errorStyle={{textAlign: "left"}}
                  fullWidth
                  maxLength="100"
                  disabled={!this.props.canAccessAny}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <PhoneField
                  id="mobile"
                  label="Phone/Mobile"
                  value={this.state.fields.mobile.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={this.state.fields.mobile.errors[0]}
                  disabled={!this.props.canAccessAny}
                  countryCode={this.props.selectedCompany?.countryCode}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="email"
                  type="email"
                  label="Email"
                  placeholder="Please enter"
                  onBlur={this.onBlur}
                  value={this.state.fields.email.value}
                  onChange={this.handleFieldChange}
                  helperText={this.state.fields.email.errors[0]}
                  disabled={!this.props.canAccessAny}
                />
              </div>
              {
                this.canUpsertCredentials() &&
                <React.Fragment>
                  <div className="col-sm-12 form-wrap-70">
                    <CommonTextField
                      id="username"
                      type="username"
                      label="Username"
                      placeholder="Please enter"
                      onBlur={this.onBlur}
                      value={this.state.fields.username.value}
                      onChange={this.handleFieldChange}
                      helperText={this.state.fields.username.errors[0]}
                      disabled={!this.props.canAccessAny}
                      InputProps={{
                        endAdornment: (
                          <React.Fragment>
                            {this.state.generatingUsername ? <CircularProgress color="inherit" size={20} /> : null}
                          </React.Fragment>
                        ),
                      }}
                    />
                  </div>
                    {!this.state.ssoSettingEnabled && isSystemCompany() && (
                      <div className="col-sm-12 form-wrap-70">
                        <CommonTextField
                          id="passwordExpiryPeriod"
                          label="Password Expiry"
                          placeholder="Please enter password expiry (Optional)"
                          onChange={this.handleFieldChange}
                          value={this.state.fields.passwordExpiryPeriod.value}
                          maxLength="3"
                          onKeyDown={event => positiveDecimalFilter(event, 2, 2160)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                                Days
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    )}
                  {
                  !this.state.ssoSettingEnabled &&
                  <React.Fragment>
                    <div className="col-sm-12 form-wrap-70" style={{minHeight: '50px'}}>
                    <FormControlLabel
                      control={<Checkbox color="primary" checked={this.state.passwordCreationType === ADMIN_CREATES_PASSWORD} onChange={this.handleShowPasswordFields()}/>}
                      label={this.props.selectedEmployee ? "Update Password" : "Create Password"}
                      value={ADMIN_CREATES_PASSWORD}
                    />
                    </div>
                    <div className="col-sm-12 form-wrap-70" style={{minHeight: '50px'}}>
                    <FormControlLabel
                      control={<Checkbox color="primary" checked={this.state.passwordCreationType === USER_CREATES_PASSWORD} onChange={this.handleShowPasswordFields()}/>}
                      label= {this.props.selectedEmployee ? "Send Reset Password Email" :"Let User Create Password"}
                      value={USER_CREATES_PASSWORD}
                      disabled={!this.props.selectedCompany?.isRegistered}
                    />
                    </div>
                    {
                      this.state.passwordCreationType === USER_CREATES_PASSWORD &&
                      <div className="col-sm-12" style={{fontSize: '13px', fontWeight: 350}}>
                        <span>A link to set the password will be sent to the email provided. Please ensure that a unique email that is accessible only to the user is entered with this option.</span>
                      </div>
                    }
                    {
                      this.state.showPasswordFields &&
                      <div>
                        <div className="col-sm-12 form-wrap-70">
                          <CommonTextField
                            id="password"
                            label="Password"
                            placeholder="Please enter"
                            value={this.state.fields.password.value}
                            onChange={this.handleFieldChange}
                            onBlur={this.onBlur}
                            helperText={this.state.fields.password.errors[0]}
                            type={this.state.showPassword ? 'text' : 'password'}
                            disabled={!this.props.canAccessAny}
                            InputProps={{
                              endAdornment:(
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    size="large">
                                  {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                        <div className="col-sm-12 form-wrap-70">
                          <CommonTextField
                            id="confirmPassword"
                            label="Confirm Password"
                            placeholder="Please enter"
                            value={this.state.fields.confirmPassword.value}
                            onChange={this.handleFieldChange}
                            onBlur={this.onBlur}
                            helperText={this.state.fields.confirmPassword.errors[0]}
                            type={this.state.showConfirmPassword ? 'text' : 'password'}
                            disabled={!this.props.canAccessAny}
                            InputProps={{
                              endAdornment:(
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickConfirmShowPassword}
                                    size="large">
                                  {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                        <div className="col-sm-12 form-wrap-70">
                          <PasswordValidIndicator
                            mainContainerStyle={{fontSize: '12px', marginLeft: '0px', display: 'inline'}}
                            indicatorContainerStyle={{marginTop: '2px', marginBottom: '15px'}}
                            password={this.state.fields.password.value}
                            onChange={this.isPasswordValid}
                          />
                        </div>
                      </div>
                    }
                  </React.Fragment>
                  }
                </React.Fragment>
              }
              { this.props.companyId && includes(FARM_EMPLOYEE_ROLES, this.state.fields.typeId.value) ?
                <div className="col-sm-12 form-wrap-70">
                  <CommonMultiSelect
                    id="farmIds"
                    label="Farms/Sites"
                    selectedItems={this.state.fields.farmIds.value}
                    onChange={this.handleFarmsChange}
                    items={this.state.farms.map(e => ({name: e.name, id: e.id}))}
                    displayField='name'
                    variant='standard'
                  />
                </div> : ''
              }
              {this.props.canAccessAny ?
               <div className="col-sm-12 cardForm-action top15 padding-reset">
                 <CommonButton
                   type="button"
                   variant="contained"
                   label="Cancel"
                   default
                   onClick={ this.props.selectedEmployee ? this.props.cancelEdit: this.cancelDrawer}
                 />
                 <CommonButton
                   type="submit"
                   variant="contained"
                   label="Save"
                   primary
                 />
               </div> : ''
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}

EmployeeForm.defaultProps = {
  selectedFarm: null,
};

const mapStateToProps = (state, props) => {
  var roles = state.profiles.roles.roles;
  return {
    currentUser: state.main.user.user,
    token: state.main.user.token,
    roles: roles,
    serverErrors: state.companySettings.employees.serverErrors,
    responseReceived: state.companySettings.employees.responseReceived,
    selectedCompany: props.selectedCompany || state.companies.companies.selectedCompany
  };
};

export default connect(mapStateToProps)(EmployeeForm);
